import IUserDetails from "../Interfaces/UserDetails";

const getInitials = (user: IUserDetails | null) => {
  let initials = "";
  if (user?.first_name && user?.last_name) {
    initials = `${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`;
  } else if (user?.username) {
    initials = `${user?.username.charAt(0)}`;
  }
  return initials;
};

type Props = {
  user: IUserDetails | null;
  children?: JSX.Element;
};

const ProfilePicture = (props: Props) => {
  const { user } = props;
  const profilePicture = user?.profile_picture;
  let initials;
  if (props.user) {
    initials = getInitials(user);
  }

  return (
    <figure className="avatar">
      <span className="text-uppercase">
        {!initials ? <i className="las la-user"></i> : initials}
      </span>
      {profilePicture && <img alt="profile" src={profilePicture} />}
      {props.children}
    </figure>
  );
};

export default ProfilePicture;
