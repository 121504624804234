import { useEffect, useState } from "react";
import Marquee from "react-text-marquee";
import UserService from "../../../../../../services/UserService";
import ITournamentYuniteTeam from "../../../../../Interfaces/TournamentYuniteTeam";
import IUserDetails from "../../../../../Interfaces/UserDetails";
import ITournament from "../../../../../Interfaces/Tournament";

type Props = {
  playerId?: string | null | undefined;
  epicId?: string;
  tournament?: ITournament;
  teams?: ITournamentYuniteTeam[];
  hideLabel?: boolean;
  hideCheckin?: boolean;
  children?: React.ReactNode
};
const PlayPlayUser = (props: Props) => {
  const { playerId, epicId, teams, hideLabel, hideCheckin, tournament } = props;
  const [userProfile, setUserProfile] = useState<IUserDetails>();
  const [loading, setLoading] = useState(false);

  let confirmed: ITournamentYuniteTeam | undefined = undefined;
  if (!hideLabel) {
    confirmed = teams?.find((team) =>
      team.players.find((player) => player.epicId === userProfile?.epicId)
    );
  }

  const isCheckinPresent = (tournament?.checkin === undefined || tournament.checkin)

  const removeSuffix = (name:string) => {
    return name.replace(/_playandplay/g, "");
  }

  useEffect(() => {
    setLoading(true);
    if (playerId && playerId.length >= 24) {
      UserService.getUser(playerId)
        .then((Ruser) => {
          setUserProfile(Ruser);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }

    if (epicId) {
      UserService.getUserByEpicId(epicId).then((Ruser) => {
        setUserProfile(Ruser);
        setLoading(false);
      });
    }

    return () => {
      setUserProfile(undefined);
      setLoading(false);
    };
  }, [playerId, epicId]);
  return (
    <>
      <div className="player-wrapper">
        {!userProfile?.profile_picture && (
          <img
            src="https://play-play.com/images/default_avatar.jpg"
            alt="player-avatar"
          />
        )}
        {userProfile?.profile_picture && (
          <img src={userProfile.profile_picture} alt="player-avatar" />
        )}
      </div>

      <div className="text-label flex-centered">
          <Marquee text={removeSuffix(userProfile?.username || "")} />
      </div>

      {!hideCheckin && playerId && tournament?.confirmedMembers?.includes(playerId) && isCheckinPresent && (
        <div className=" text-label flex-centered">
          <div className="skp-chip status-completed skp-text-small">
            Check-in: <i className="las la-check-circle"></i>
            </div>
        </div>
      )}
      
      {!hideCheckin && playerId && !tournament?.confirmedMembers?.includes(playerId) && isCheckinPresent && (
        <div className=" text-label flex-centered">
          <div className="skp-chip status-Rejected skp-text-small">
            Check-in: <i className="las la-times-circle"></i>
          </div>
        </div>
      )}

      {props.children}

      {!hideLabel && epicId && (
        <div className="text-label flex-centered">
          <div>
            {loading && (
              <span className="skp-chip skp-text-small">Loading...</span>
            )}
            {!loading && confirmed && (
              <span className="skp-chip status-completed skp-text-small">
                Confirmed
              </span>
            )}
            {!loading && !confirmed && (
              <span className="skp-chip status-awaiting skp-text-small">
                <Marquee text="Awaiting"></Marquee>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PlayPlayUser;
