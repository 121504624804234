import { useParams } from "react-router";

import { Scrollbars } from "react-custom-scrollbars";

import { Link } from "react-router-dom";
import HostingTab from "./MyTournamentsTabs/HostingTab";
import CurrentlyPlayingTab from "./MyTournamentsTabs/CurrentlyPlayingTab";
import { useTranslation } from "react-i18next";

const MyTournaments = () => {
  const { t } = useTranslation()
  const params = useParams<{id: string, tab: string}>()
  const ownerId = params.id;
  const currentTab = params.tab;

  const tabs = [
    { name: t('tabs.hosting'), url: "hosting" },
    { name: t('tabs.currentlyPlaying'), url: "currently-playing" }
  ];

  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-full-page">
          <div className="containers">
            <div className="columns">
              <div className="column col-12 text-center">
                <h4 className="skp-heading skp-primary-blue">{t('user.config.myTournaments')}</h4>
              </div>
            </div>
            {/* TABS HEADER */}
            <div className="container tabs-header">
              <div className="columns">
                <div className="column col-12">
                  <ul className="tab skp-text-light">
                    {tabs.map((tab) => (
                      <li
                        key={tab.name}
                        className={
                          currentTab === tab.url
                            ? "tab-item active"
                            : "tab-item"
                        }
                      >
                        <Link
                          to={`/users/${ownerId}/my-tournaments/${tab.url}`}
                          className={currentTab === tab.url ? "active" : ""}
                        >
                          {tab.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* END TABS HEADER */}
            {/* TABS BODY */}
            <div className="container tabs-body">
              {ownerId && currentTab === tabs[0].url && <HostingTab userId={ownerId} path="my-tournaments/hosting" />}
              {ownerId && currentTab === tabs[1].url && (
                <CurrentlyPlayingTab userId={ownerId} path="my-tournaments/currently-playing" />
              )}
            </div>
            {/* END TABS BODY */}
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default MyTournaments;
