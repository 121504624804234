import React, { useContext } from "react";
import Countdown from "react-countdown";
import { Scrollbars } from "react-custom-scrollbars";
import { UserContext } from "../../../Context/UserContext";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import TournamentJoinButtons from "../TournamentSinglePages/TournamentJoinButtons";

type Props = {
  tournament: ITournament
  memberStatus: ITournamentMemberStatus
  onReloadTournament: (tournament?:ITournament) => void
}

const YuniteStatusPanel = (props:Props) => {
  const {user} = useContext(UserContext);
  const { tournament, memberStatus } = props;

  let status = tournament?.status;
  let upcomingTournament = status === 0;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;
  const today = new Date();
  const registration = new Date(tournament?.end_registration);
  const registrationClosed = registration < today || !upcomingTournament;
  const checkin = new Date(tournament?.end_checkin);
  const checkinClosed = checkin < today || !upcomingTournament;
  const startRegistration = new Date(tournament?.start_registration || "")
  const registrationStarted = startRegistration < today || !upcomingTournament

  const goToDiscord = () => {
    window.open("https://discord.com/invite/agPSsaDyTj", "_blank");
  };

  const goToVerificaYunite = () => {
    window.open("https://discord.gg/cjHbn2GZD2", "_blank");
  };

  return (
    <div className="skp-right-sidebar">
      <Scrollbars>
        <div className="container p-2">
          <div className="columns p-2">
            <div className="column col-12">
              <h4 className="skp-text-primary">Tournament Status</h4>
              {!onGoingTournament && !completedTournament && (
                <span
                  className={`skp-chip skp-text-xxsmall text-uppercase ${
                    registrationClosed ? "status-closed" : "status-1"
                  }`}
                >
                  {registrationClosed
                    ? "Registration Closed"
                    : "Registration Open"}
                </span>
              )}
              {upcomingTournament && (
                <span
                  className={`skp-chip skp-text-xxsmall text-uppercase status-upcoming`}
                >
                  Upcoming
                </span>
              )}
              {onGoingTournament && (
                <span className="skp-chip  skp-text-xxsmall text-uppercase status-ongoing">
                  Ongoing
                </span>
              )}
              {completedTournament && (
                <span className="skp-chip  skp-text-xxsmall text-uppercase status-completed">
                  Completed
                </span>
              )}
            </div>
          </div>
          <div className="columns p-2">
            <div className="column col-12">
              <h5 className="skp-text-primary">1. Register on Discord</h5>
              <ol className="skp-text-small skp-text-light">
                <li>
                  Accedere al server Discord di SkillPro con lo stesso account
                  registrato su Skillpro{" "}
                  {user?.discordId && (
                    <span className="skp-text-primary">
                      {user?.discordId}
                    </span>
                  )}
                  <div className="space-divider-5"></div>
                  <button
                    className="skp-btn skp-btn-round skp-btn-primary"
                    onClick={goToDiscord}
                  >
                    Go to Discord
                  </button>
                  <div className="space-divider-5"></div>
                </li>
                <li>
                  Verificarsi con Yunite nella sezione “verifica-yunite” la
                  verifica deve essere fatta con lo stesso account EPIC
                  {user?.epicId && (
                    <span className="skp-text-xxsmall">
                      ({user?.epicId})
                    </span>
                  )}{" "}
                  registrato sul sito di Skillpro (se vi siete già verificati su
                  Yunite nel server saltare questo passaggio)
                  <div className="space-divider-5"></div>
                  <button
                    className="skp-btn skp-btn-round skp-btn-primary"
                    onClick={goToVerificaYunite}
                  >
                    Go to #verifica-yunite
                  </button>
                </li>
              </ol>
            </div>
            <div className="space-divider-20"></div>
            <div className="column col-12">
              <h5 className="skp-text-primary">
                2. Registration{" "}
                <span className="skp-text-small">
                  <Countdown date={new Date(tournament?.end_registration)} />
                </span>
              </h5>
              <div className="skp-text-label skp-text-light">
                Registration is open, sign up now.
              </div>
              <div className="space-divider-5"></div>
              <div className="skp-text-light skp-text-small">
                {tournament?.members.length}{" "}
                <span className="skp-text-accent">Registered</span> -{" "}
                {tournament?.maxPartecipants} slots
              </div>
              <div className="space-divider-5"></div>
              <TournamentJoinButtons
                      tournament={tournament}
                      memberStatus={memberStatus}
                      registrationStarted={registrationStarted}
                      registrationClosed={registrationClosed}
                      checkinClosed={checkinClosed}
                      onReloadTournament={props.onReloadTournament}
                    />
            </div>
            <div className="space-divider-20"></div>
            <div className="column col-12">
              <h5 className="skp-text-primary">3. Confirm on Discord</h5>
              <ul className="skp-text-small skp-text-light">
                <li>
                  ATTENZIONE! Per poter partecipare al torneo attendi il periodo
                  di Check-In (vai nella scheda Overview per vedere quando
                  inizia il periodo di Check In dell’evento) e conferma la tua
                  presenza sul Discord di Skillpro prima che inizi l’evento.
                  Completa i seguenti passaggi per effettuare il Check In:
                </li>
                <ol>
                  <li>
                    Una volta iniziato il periodo di Check In, accedi al Discord
                    di Skillpro e clicca sulla categoria dell’evento (la
                    categoria ha lo stesso nome del torneo presente su
                    Skillpro).
                  </li>
                  <li>
                    Clicca su “registrazione-team/check-in” e successivamente
                    sulla “T” del messaggio di Yunite
                  </li>
                  <li>
                    Segui la procedura che arriva in DM su Discord (basta
                    cliccare il pulsante “+” nel messaggio che arriva
                    direttamente da Yunite).
                  </li>
                </ol>
              </ul>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default YuniteStatusPanel;
