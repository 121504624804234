import Scrollbars from "react-custom-scrollbars";
import Footer from "../../UiLibrary/Footer";
const CookiesPolicy = () => {
  return (
    <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
      <div className="skp-full-page">
        <div className="containers">
          <div className="columns">
            <div className="column col-12">
              <h2 className="skp-text-primary">Cookies Policy</h2>
            </div>
          </div>
          <div className="columns skp-text-light">
            <div className="column col-12">
              <dl>
                <dd>
                  <b className="skp-text-primary text-uppercase">
                    Introduzione
                  </b>
                  <br></br>
                  La presente informativa sui cookie (di seguito “Informativa
                  Cookie”) ha lo scopo specifico di illustrare i tipi, le
                  modalità di utilizzo nonché di fornire indicazioni circa le
                  azioni per rifiutare o eliminare, se lo si desidera, i cookie
                  presenti sul presente sito web (di seguito, il “Sito”). Il
                  Titolare del trattamento dei dati è Evotech, con sede in via
                  della scienza, 50 - 41122 Modena (MO) ITALY, partiva iva n.
                  IT03934490362, (di seguito “Evotech” ovvero il “Titolare”) e
                  tratta unicamente le informazioni anonime degli utenti (di
                  seguito “Utente”, al singolare, o “Utenti” al plurale)
                  raccolti con i cookie attraverso il Sito, conformemente al
                  Regolamento UE 2016/679 (di seguito anche “GDPR”) e alla
                  normativa, anche nazionale, in materia di protezione dei dati
                  personali per tempo applicabile (“Normativa Privacy”).
                </dd>
              </dl>
              <dl>
                <dd>
                  <b className="skp-text-primary text-uppercase">
                    Cosa sono i cookie{" "}
                  </b>
                  <br></br>
                  Il Sito fa uso della tecnologia cookie. I cookie sono piccoli
                  file di testo che i siti visitati inviano al terminale o
                  device dell’utente (computer, tablet, smartphone, notebook),
                  dove vengono memorizzati, per poi essere ritrasmessi agli
                  stessi siti alla visita successiva. Di seguito, sono elencate
                  ed illustrate le tipologie di cookie presenti sul Sito, con
                  una descrizione delle finalità legate all’utilizzo degli
                  stessi. Tipologie di cookie utilizzati dal Sito I cookies
                  possono essere tecnici, analitici e di profilazione. In
                  particolare, i cookie presenti sul Sito sono:
                </dd>
                <dd>
                  {" "}
                  a) Cookie tecnici I cookie di questo tipo sono necessari per
                  assicurare il corretto funzionamento di alcune aree del Sito.
                  Tali cookie possono essere suddivisi in: cookie di sessione,
                  che garantiscono la normale navigazione e fruizione del sito
                  web (permettendo, ad esempio, di autenticarsi per accedere ad
                  aree riservate). Essi non vengono utilizzati per scopi
                  ulteriori e sono normalmente installati direttamente dal
                  titolare o gestore del sito web (c.d. “cookie proprietari”);
                  nel caso specifico, i cookie di questa categoria vengono
                  sempre inviati dal Sito. cookie persistenti che rimangono
                  memorizzati sul disco rigido del device dell’Utente fino alla
                  loro scadenza o cancellazione da parte degli utenti
                  visitatori. Tramite i cookie persistenti, gli Utenti
                  visitatori che accedono al Sito (o eventuali altri Utenti che
                  impiegano il medesimo computer) vengono automaticamente
                  riconosciuti a ogni visita. I cookie persistenti soddisfano
                  molte funzionalità nell’interesse dei navigatori (come, per
                  esempio, l’uso della lingua di navigazione). Gli Utenti
                  visitatori possono impostare il browser del proprio computer o
                  device in modo tale che esso accetti/rifiuti tutti i cookie o
                  visualizzi un avviso ogni qual volta viene proposto un cookie,
                  al fine di poter valutare se accettarlo o meno. L’Utente è
                  abilitato, comunque, a modificare la configurazione
                  predefinita (di default) e disabilitare i cookie (cioè
                  bloccarli in via definitiva), impostando il livello di
                  protezione più elevato. Le predette tipologie di cookie (di
                  sessione e persistenti) possono a loro volta essere: di “prima
                  parte” (o “proprietari”) quando sono gestiti direttamente dal
                  proprietario e/o responsabile del sito web; nel caso specifico
                  da Evotech; ovvero di “terze parti” quando i cookie sono
                  predisposti e gestiti da responsabili estranei al sito web
                  visitato dall’utente.
                </dd>
                <dd>
                  <b className="skp-text-primary">
                    Elenco dei cookies tecnici:
                  </b>
                  <br></br>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Durata</th>
                        <th>Finalità</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>AUTH_SESSION_ID</td>
                        <td>Sessione</td>
                        <td>ID delle sessione di autenticazione corrente.</td>
                      </tr>
                      <tr>
                        <td>KC_RESTART</td>
                        <td>Sessione</td>
                        <td>Cookie tecnico da Keycloak.</td>
                      </tr>
                      <tr>
                        <td>KC_START</td>
                        <td>Sessione</td>
                        <td>Cookie tecnico da Keycloak.</td>
                      </tr>
                      <tr>
                        <td>KEYCLOAK_IDENTITY</td>
                        <td></td>
                        <td>ID dell'utente </td>
                      </tr>
                      <tr>
                        <td>KEYCLOAK_LOCALE</td>
                        <td>Sessione</td>
                        <td>Language of the beta.skillpro.it interface.</td>
                      </tr>
                      <tr>
                        <td>KEYCLOAK_REMEMBER _ME</td>
                        <td>1 Giorno</td>
                        <td>Cookie tecnico da Keycloak.</td>
                      </tr>
                      <tr>
                        <td>KEYCLOAK_SESSION</td>
                        <td>1 Giorno</td>
                        <td>
                          ID della sessione corrente del browser su beta.skillpro.it.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </dd>
                {/* <dd>
                  <br></br>
                  b) Cookie analitici di terze parti I cookie analitici di terze
                  parti sono cookie impostati da un sito web diverso da quello
                  che si sta attualmente visitando. Questa tipologia di cookie
                  ricade sotto la diretta ed esclusiva responsabilità della
                  stessa terza parte. Pertanto, l’Utente è invitato a consultare
                  le informazioni sulla privacy e sull’utilizzo di cookie di
                  terze parti direttamente sui siti dei rispettivi gestori. In
                  particolare, il Sito utilizza i seguenti cookie analitici di
                  terze parti: “Google Analytics”: sono cookie utilizzati per
                  raccogliere e analizzare informazioni statistiche sugli
                  accessi/le visite al Sito. Questo tipo di cookie raccoglie
                  informazioni in forma anonima, che non consentono
                  l’identificazione personale degli utenti, sull’attività di
                  questi ultimi all’interno del sito, sul modo in cui sono
                  arrivati al sito e sulle pagine da essi visitate. Evotech
                  utilizza tali informazioni messe a disposizione da Google
                  Analytics per analisi statistiche, per migliorare il Sito e
                  semplificarne l’utilizzo, oltre che per monitorarne il
                  corretto funzionamento. Ulteriori informazioni sulla privacy e
                  sul loro uso sono reperibili direttamente sul sito web di
                  Google Analytics cliccando sul seguente link:
                  http://www.google.com/intl/it_ALL/analytics/learn/privacy.html
                  Precisamente si tratta di:
                  <ul>
                    <li>
                      <b>Cookie</b>: _ga
                    </li>
                    <li>
                      <b>Tipo</b>: analitycs
                    </li>
                    <li>
                      <b>Finalità</b>: necessario al servizio di terze parti
                      Google analytics
                    </li>
                    <li>
                      <b>Durata</b>: A fine sessione
                    </li>
                  </ul>
                  Il Sito adotta strumenti che riducono il potere identificativo
                  dei cookies, come “gat._anonymizelp();” per garantire
                  l’anonimizzazione degli indirizzi IP e dei dati raccolti dai
                  cookie (c.d. “IP-masking”). Il Sito non utilizza i cookie di
                  profilazione, vale a dire cookie volti a creare profili
                  relativi all’Utente, generalmente utilizzati al fine di
                  inviare messaggi pubblicitari in linea con le preferenze
                  manifestate dall’utente nell’ambito della navigazione in rete.
                </dd> */}

                <dd>
                  <b className="skp-text-primary text-uppercase">Finalità </b>
                  <br></br>I cookies tecnici usati dal Sito servono a effettuare
                  e facilitare la navigazione dell’Utente e a fornire e a
                  permettere all’Utente di fruire dei servizi del Sito. I
                  cookies analitici usati dal Sito servono ad analizzare e
                  monitorare in modo anonimo il modo in cui l’Utente utilizza il
                  Sito (ad es. n. accessi e pagine viste), a fini statistici e
                  per permetterci di apportare modifiche migliorative al Sito in
                  termini di funzionamento e navigazione.
                </dd>
                <dd>
                  <b className="skp-text-primary text-uppercase">
                    Consenso ai cookie{" "}
                  </b>
                  <br></br>Il consenso ai cookie utilizzati dal Sito può essere
                  espresso dall’Utente, conformemente alla normativa vigente,
                  mediante specifiche configurazioni del browser e di programmi
                  informatici o di dispositivi che siano di facile e chiara
                  utilizzabilità per l’Utente stesso. Si segnala che l’Utente
                  autorizza l’utilizzo dei cookies proseguendo nella navigazione
                  sul Sito, dopo aver letto il Banner. Il conferimento dei dati
                  è facoltativo. Si fa tuttavia presente che il
                  rifiuto/disattivazione dei cookies potrà impedire di
                  utilizzare alcune funzionalità del Sito. E’ possibile
                  modificare le preferenze relative ai cookie in qualsiasi
                  momento, secondo le specifiche procedure di seguito descritte.
                  È anche possibile disabilitare in qualsiasi momento i cookie
                  dal browser, ma questa operazione potrebbe impedire all’Utente
                  di utilizzare alcune parti del Sito. Per quanto concerne i
                  cookie di terze parti, questi ultimi ricadono sotto la diretta
                  ed esclusiva responsabilità del gestore terzo. Pertanto,
                  l’Utente è invitato a consultare le informazioni sulla privacy
                  e sull’utilizzo di cookie di terze parti direttamente sui siti
                  dei rispettivi gestori indicati nella presente Informativa.
                </dd>
                <dd>
                  <b className="skp-text-primary text-uppercase">
                    Come controllare i cookie?{" "}
                  </b>
                  <br></br>È possibile controllare i cookie modificando le
                  preferenze del browser Internet utilizzato. È possibile
                  accettare tutti i cookie, solo alcuni, oppure rifiutarli
                  tutti. Nel caso in cui l’Utente decida di bloccare tutti i
                  cookie (anche quelli tecnici), potrebbe essere impossibile
                  accedere ad aree del sito o utilizzare i servizi offerti.
                </dd>
                <dd>
                  <b className="skp-text-primary text-uppercase">
                    Come è possibile disattivare i cookie?
                  </b>
                  <br></br>
                  Ogni browser Web consente di limitare ed eliminare i cookie.
                  Di seguito riportiamo alcune informazioni prettamente
                  indicative sulla procedura necessaria per disattivare i
                  cookie, a seconda del browser utilizzato dall’Utente.
                </dd>
                <dd>
                  <span className="skp-text-primary">
                    Internet Explorer 8.0+:
                  </span>
                  <br></br>
                  Fare clic su “Strumenti” nella barra dei menù e selezionare
                  “Opzioni Internet”; <br />
                  Fare clic sulla scheda “Privacy” nella parte superiore; <br />{" "}
                  Trascinare il dispositivo di scorrimento fino a “Blocca tutti
                  i cookie”; <br />{" "}
                  <span className="skp-text-primary">
                    Internet Explorer 4.0+:
                  </span>{" "}
                  <br />
                  Fare clic su “Strumenti” nella barra dei menù Selezionare
                  “Opzioni”; <br /> Fare clic sulla scheda “Privacy”; <br />{" "}
                  Selezionare la casella “Attiva l’opzione anti-tracciamento dei
                  dati personali”
                  <br />
                  <span className="skp-text-primary">Chrome:</span> <br />
                  Fare clic sul “Menù chrome” nella barra degli strumenti del
                  browser; <br />
                  Selezionare “Impostazioni”; <br /> Fare clic su “Mostra
                  impostazioni avanzate”; <br /> Nella sezione Privacy, fare
                  clic sul pulsante “Impostazioni contenuti”; <br /> Nella
                  sezione “Cookie” selezionare “Ignora le eccezioni e blocca
                  l’impostazione dei cookie di terze parti”
                  <br />
                  <span className="skp-text-primary">Safari:</span> <br />
                  Fare clic sul menu Safari e selezionare “Preferenze”; <br />{" "}
                  Fare clic sulla scheda “Sicurezza”; <br /> Nella sezione
                  “Accetta cookie” specificare se accettare i cookie sempre, mai
                  o solo dai siti visitati. Per una spiegazione delle diverse
                  opzioni fare clic sul pulsante Guida a forma di punto
                  interrogativo; <br /> Se Safari è impostato per bloccare i
                  cookie, potrebbe essere necessario accettarli temporaneamente
                  per l’apertura della pagina; <br />
                  Ripetere quindi i passaggi indicati sopra e selezionare
                  “Sempre”; <br /> Una volta terminato di utilizzare la guida,
                  disattivare nuovamente i cookie e cancellarli.
                </dd>
                <dd>
                  Per una panoramica dei browser più comuni e per ulteriori
                  informazioni sui cookie e il loro controllo è possibile
                  visitare il sito in lingua inglese: www.allaboutcookies.org.
                </dd>
                <dd>
                  <b className="skp-text-primary text-uppercase">Accesso </b>
                  <br></br>Le informazioni anonime raccolte dai cookies
                  analitici usati dal Sito potranno essere resi accessibili
                  esclusivamente per le finalità di cui sopra a: dipendenti e
                  collaboratori del Titolare o delle società collegate,
                  controllate, affiliate, nella loro qualità di incaricati e/o
                  responsabili interni del trattamento e/o amministratori di
                  sistema; società terze o altri soggetti, persone, società,
                  associazioni o studi professionali che prestino servizi ed
                  attività in outsourcing per conto del Titolare, nella loro
                  qualità di responsabili del trattamento (a titolo indicativo,
                  fornitori, tecnici addetti all’assistenza hardware e software,
                  istituti di credito, studi professionali, etc.).
                </dd>
                <dd>
                  <b className="skp-text-primary text-uppercase">
                    Diritti degli utenti{" "}
                  </b>
                  <br></br>
                  <ol>
                    <li>
                      Ove applicabile, l’Utente avrà la facoltà di esercitare i
                      propri diritti nei confronti del Titolare del trattamento,
                      ai sensi dell’art. 15 GDPR e precisamente i diritti di:
                      ottenere da parte del Titolare la conferma dell’esistenza
                      o meno di dati personali che lo riguardano, anche se non
                      ancora registrati, e la loro comunicazione in forma
                      intelligibile;
                    </li>
                    <li>
                      ottenere la conferma: a) dell’origine dei dati personali;
                      b) delle finalità e modalità del trattamento; c) della
                      logica applicata in caso di trattamento effettuato con
                      l’ausilio di strumenti automatizzati; d) degli estremi
                      identificativi del titolare, dei responsabili e del
                      rappresentante designato ai sensi dell'art. 3, comma 1,
                      GDPR; e) dei soggetti o delle categorie di soggetti ai
                      quali i dati personali possono essere comunicati o che
                      possono venirne a conoscenza in qualità di rappresentante
                      designato nel territorio dello Stato, di responsabili o
                      incaricati;
                    </li>
                    <li>
                      ottenere: a) l’aggiornamento, la rettifica ovvero, quando
                      vi ha interesse, l’integrazione dei dati; b) la
                      cancellazione, la trasformazione in forma anonima o il
                      blocco dei dati trattati in violazione di legge, compresi
                      quelli di cui non è necessaria la conservazione in
                      relazione agli scopi per i quali i dati sono stati
                      raccolti o successivamente trattati; c) l’attestazione che
                      le operazioni di cui alle lettere a) e b) sono state
                      portate a conoscenza, anche per quanto riguarda il loro
                      contenuto, di coloro ai quali i dati sono stati comunicati
                      o diffusi, eccettuato il caso in cui tale adempimento si
                      riveli impossibile o comporti un impiego di mezzi
                      manifestamente sproporzionato rispetto al diritto
                      tutelato;
                    </li>
                    <li>
                      opporsi, in tutto o in parte: a) per motivi legittimi al
                      trattamento dei dati personali che lo riguardano, ancorché
                      pertinenti allo scopo della raccolta; b) al trattamento di
                      dati personali che lo riguardano a fini di invio di
                      materiale pubblicitario o di vendita diretta o per il
                      compimento di ricerche di mercato o di comunicazione
                      commerciale.
                    </li>
                  </ol>
                  <br />
                  Ove applicabile, l’Utente ha altresì i diritti di cui agli
                  artt. 16-21 GDPR (Diritto di rettifica, diritto all’oblio,
                  diritto di limitazione di trattamento, diritto alla
                  portabilità dei dati contrattuali e grezzi di navigazione,
                  diritto di opposizione), nonché il diritto di reclamo
                  all’Autorità Garante. <br />
                  L’utente può in qualsiasi momento esercitare i diritti di cui
                  sopra, contattando il Titolare via e-mail all’'indirizzo.{" "}
                  <br /> La presente informativa è stata aggiornata il
                  18/01/2022.
                </dd>
              </dl>
            </div>
          </div>
          <div className="space-divider"></div>
          <Footer />
          <div className="space-divider-20"></div>
        </div>
      </div>
    </Scrollbars>
  );
};

export default CookiesPolicy;
