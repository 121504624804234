
import TournamentTile from "../../UiLibrary/TournamentTile";
import { Scrollbars } from "react-custom-scrollbars";
import {
  useLocalStorage
} from "@rehooks/local-storage";
import TeamTile from "../../UiLibrary/TeamTile";
import GameTile from "../../UiLibrary/GameTile";
import { useTranslation } from "react-i18next";

const Favourites = () => {
  const [getFav] = useLocalStorage("favourites", {
    teams: [],
    tournaments: [],
    games: [],
  });
  const { t } = useTranslation()

  return (
    <>
      <Scrollbars renderView={(props) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-full-page">
          <div className="container">
            <div className="columns">
              <div className="column col-12 text-center">
              <h4 className="skp-primary-pink skp-heading ">{t('common.favourites')}</h4>
              </div>
            </div>
            {/* Tournament Filters */}
            {/* <TournamentFilters onRunQuery={runQuery} /> */}
            <div className="space-divider-20"></div>
            <div className="columns">
              <div className="column col-12">
                <h4 className="skp-text-primary">{t('common.tournaments')}</h4>
              </div>
            </div>
            <div className="columns">
              {/* Tiles */}
              {getFav.tournaments.length > 0 &&
                getFav.tournaments.map((tournament) => (
                  <div className="column col-3 col-xs-12" key={tournament.id}>
                    <TournamentTile tournament={tournament}></TournamentTile>
                  </div>
                ))}
              {getFav.tournaments.length === 0 && (
                <div className="column col-12">
                  <h6 className="skp-text-primary">{t('home.noTournaments')}</h6>
                </div>
              )}
            </div>

            {/* Teams */}
            <div className="space-divider-20"></div>
            <div className="columns">
              <div className="column col-12">
                <h4 className="skp-text-primary">{t('common.teams')}</h4>
              </div>
            </div>
            <div className="columns">
              {/* Tiles */}
              {getFav.teams.length > 0 &&
                getFav.teams.map((team) => (
                  <div className="column col-2 col-xs-12" key={team.id}>
                    <TeamTile team={team}></TeamTile>
                  </div>
                ))}
              {getFav.teams.length === 0 && (
                <div className="column col-12">
                  <h6 className="skp-text-primary">{t('home.noTeams')}</h6>
                </div>
              )}
            </div>
            {/* END Teams */}

            {/* Games */}
            <div className="space-divider-20"></div>
            <div className="columns">
              <div className="column col-12">
                <h4 className="skp-text-primary">{t('common.games')}</h4>
              </div>
            </div>
            <div className="columns">
              {/* Tiles */}
              {getFav.games.length > 0 &&
                getFav.games.map((game) => (
                  <div className="column col-2 col-xs-12" key={game.id}>
                    <GameTile game={game} />
                  </div>
                ))}
              {getFav.games.length === 0 && (
                <div className="column col-12">
                  <h6 className="skp-text-primary">{t('home.noGames')}</h6>
                </div>
              )}
            </div>
            {/* END Games */}
          </div>
          <div className="space-divider"></div>
        </div>
      </Scrollbars>
    </>
  );
};

export default Favourites;
