import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import DayOneRoundOne from "./DayOneRoundOne";
import DayOneRoundRip from "./DayOneRoundRip";
import "./we-arena.scss";
import DayTwoRoundOne from "./DayTwoRoundOne";
import DayThreeRoundOne from "./DayThreeRoundOne";
import Modal from "../../../UiLibrary/Modal";
import WeArenaSingleMatch from "./WeArenaSingleMatch";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const WeArenaTournament = () => {
  const [day, setDay] = useState("day1");
  const [showSettings, setShowSettings] = useState(false);
  const [zoom, setZoom] = useState<number>(1);
  const [translateX, setTranslateX] = useState<number>(0);
  const [translateY, setTranslateY] = useState<number>(0);
  const [selected, setSelected] = useState("round1");
  const [showMatchModal, setShowMatchModal] = useState<{
    show: boolean;
    match?: any;
  }>({ show: false });

  const openMatchModal = (match: any) => {
    setShowMatchModal({ show: true, match: match });
  };

  const handle = useFullScreenHandle();

  const showFullScreen = () => {
    handle.enter();
    setShowSettings(false);
  };

  const zoomInOut = (zoomPercentage: number) => {
    setZoom(zoom + zoomPercentage);
    setTranslateX(translateX + (zoomPercentage * 50 * 10))
    setTranslateY(translateY + (zoomPercentage * 50 * 10))
  };

  const setDayandZoom = (day: string) => {
    switch (day) {
      case "day1":
        setDay(day);
        setZoom(1);
        setTranslateX(0)
        setTranslateY(0)
        break;
      case "day2":
        setDay(day);
        setZoom(0.7);
        setTranslateX(0)
        setTranslateY(0)
        break;
      case "day3":
        setDay(day);
        setZoom(1);
        setTranslateX(0)
        setTranslateY(0)
        break;
      default:
        setDay(day);
        setZoom(1);
        setTranslateX(0)
    }
  };

  return (
    <>
      <FullScreen handle={handle}>
        <Scrollbars className="main-container">
        <div className="wearena-event-wrapper"></div>

        <div className="settings-tab text-right">
          <button
            className="skp-btn skp-btn-circle"
            onClick={() => setShowSettings(!showSettings)}
          >
            <i className="skp-icon-settings"></i>
          </button>
          {showSettings && (
            <dl className="settings-tab-dropdown skp-text-primary">
              <dd>
                Fullscreen{" "}
                <button
                  className="skp-btn skp-btn-circle ml-2"
                  onClick={showFullScreen}
                >
                  <i className="las la-expand"></i>
                </button>
              </dd>
              <dd>
                Zoom In{" "}
                <button
                  className="skp-btn skp-btn-circle ml-2"
                  onClick={() => zoomInOut(0.1)}
                  disabled={zoom === 2}
                >
                  <i className="las la-plus"></i>
                </button>
              </dd>
              <dd>
                Zoom Out{" "}
                <button
                  className="skp-btn skp-btn-circle ml-2"
                  onClick={() => zoomInOut(-0.1)}
                  disabled={zoom === 0.5000000000000001}
                >
                  <i className="las la-minus"></i>
                </button>
              </dd>
            </dl>
          )}
        </div>

        <div className="container" style={{ position: "relative", zIndex: 1 }}>
          <div className="columns">
            <div className="column col-2 col-xs-12">
              <div className="logo-placeholder main-logo">
                <img
                  alt="esport-serie-c"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/logo_esport_serie-c.png"
                ></img>
              </div>
              <div className="logo-placeholder main-logo">
                <img alt="lega-pro" src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo_LegaPro_Esteso_colore-su-nero_RGB.png" />
              </div>
              <div className="logo-placeholder main-logo">
                <img
                  alt="wearena-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/WeArena_Logo_Negative_RGB.png"
                ></img>
              </div>
            </div>
            <div className="column col-8 col-xs-12 text-center text-center-xs bracket-container">
              <div className="space-divider-20"></div>
              <h5
                className={`m-2 d-inline-block large-link ${
                  day === "day1" && "active"
                }`}
                onClick={() => setDayandZoom("day1")}
              >
                Giorno 1
              </h5>
              <h5
                className={`m-2 d-inline-block large-link ${
                  day === "day2" && "active"
                }`}
                onClick={() => setDayandZoom("day2")}
              >
                Giorno 2
              </h5>
              <h5
                className={`m-2 d-inline-block large-link ${
                  day === "day3" && "active"
                }`}
                onClick={() => setDayandZoom("day3")}
              >
                Giorno 3
              </h5>

              <Scrollbars className="day-scrollbar">
                {day === "day1" && (
                  <div
                    className="column col-12 flex-centered"
                    style={{ height: "100%", transform: `scale(${zoom}) translateY(${translateY}px)` }}
                  >
                    {selected === "round1" && (
                      <DayOneRoundOne
                        onOpenMatch={openMatchModal}
                      ></DayOneRoundOne>
                    )}

                    {/* {selected === "roundRip" && (
                    <DayOneRoundRip
                      onOpenMatch={openMatchModal}
                    ></DayOneRoundRip>
                  )} */}
                  </div>
                )}

                {day === "day2" && (
                  <div
                    className="column col-12 flex-centered"
                    style={{ height: "100%", transform: `scale(${zoom}) translateY(${translateY}px)` }}
                  >
                    <DayTwoRoundOne
                      onOpenMatch={openMatchModal}
                    ></DayTwoRoundOne>
                  </div>
                )}

                {day === "day3" && (
                  <div
                    className="column col-12 flex-centered"
                    style={{ height: "100%", transform: `scale(${zoom}) translateX(${translateX}px)` }}
                  >
                    <DayThreeRoundOne
                      onOpenMatch={openMatchModal}
                    ></DayThreeRoundOne>
                  </div>
                )}
              </Scrollbars>
            </div>
            <div className="column col-2 col-xs-12">
              <div className="space-divider"></div>
              <div
                className="logo-placeholder"
                style={{ backgroundColor: "rgba(255,255,255,0.9)", width: "50%", margin: "0 auto" }}
              >
                <img
                  alt="millionaire-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo%20Millionaire%20CORRETTO.png"
                ></img>
              </div>
              <div
                className="logo-placeholder"
                style={{ width: "50%", margin: "-20px auto -30px" }}
              >
                <img
                  alt="bsq-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/bsq_security_logo-(1).png"
                ></img>
              </div>
              <div className="logo-placeholder" style={{ width: "70%", margin: "0px auto" }}>
                <img
                  alt="pchunter-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo%20PCHunter.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div className="logo-placeholder">
                <img
                  alt="beebad-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo%20Beebad.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div className="logo-placeholder" style={{ width: "70%", margin: "0px auto" }}>
                <img
                  alt="billytacos-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo-BillyTacos.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div
                className="logo-placeholder"
                style={{ width: "35%", margin: "0px auto" }}
              >
                <img
                  alt="gotgame-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/GotGame_Logo_White.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div
                className="logo-placeholder" style={{ width: "40%", margin: "0px auto" }}
              >
                <img
                  alt="gotgame-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/logoCLI.png" 
                ></img>
              </div>
            </div>
            {/* {day === "day1" && ( 
            <div className="column col-12 text-left text-center-xs">
              <h6
                className={`m-2 d-inline-block small-link ${
                  selected === "round1" && "active"
                }`}
                onClick={() => setSelected("round1")}
              >
                Round 1
              </h6>
              <h6
                className={`m-2 d-inline-block small-link ${
                  selected === "roundRip" && "active"
                }`}
                onClick={() => setSelected("roundRip")}
              >
                Ripescaggio
              </h6>
            </div>
          )} */}
          </div>
        </div>

        <Modal
          show={showMatchModal.show}
          onClose={() => setShowMatchModal({ show: false })}
          title={showMatchModal.match?.title}
        >
          <>
            {showMatchModal.match?.title === "Estrazione" && (
              <div className="skp-text-primary container">
                <div className="columns">
                  <div className="space-divider-20"></div>
                  <div className="column col-12">
                    <h5>Ripescaggio</h5>
                  </div>
                  <div className="space-divider-20"></div>
                  <div className="column col-12">
                    Il sesto club che accederà agli ottavi di finale sarà scelto
                    con le seguenti modalità:{" "}
                    <ol>
                      <li>
                        la squadra che avrà minor differenza reti all'interno di
                        questo turno;
                      </li>
                      <li>numero di goal fatti;</li>
                      <li>club che ha subito meno goal</li>
                      <li>
                        sorteggiare la squadra qualificata tramite lancio della
                        moneta.
                      </li>
                    </ol>
                  </div>
                </div>

                <div className="space-divider-20"></div>
              </div>
            )}
            {showMatchModal.match?.title !== "Estrazione" && (
              <WeArenaSingleMatch
                match={showMatchModal.match}
              ></WeArenaSingleMatch>
            )}
          </>
        </Modal>
        </Scrollbars>
      </FullScreen>
    </>
  );
};

export default WeArenaTournament;
