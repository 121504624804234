import { useContext, useState } from "react";
import UserProfile from "../../../UiLibrary/UserProfile";
import ReactTimeAgo from "react-time-ago";
import Moment from "moment";
import TournamentService from "../../../../services/TournamentService";
import TextEditor from "../../../UiLibrary/RichTextEditor";
import { toast } from "react-toastify";
import ITournament from "../../../Interfaces/Tournament";
import IUserDetails from "../../../Interfaces/UserDetails";
import ITournamentReport from "../../../Interfaces/TournamentReport";
import { UserContext } from "../../../Context/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined
  disableTextEditor?: boolean
  onReloadTournament: (tournament?: ITournament) => void;
}

const AnnouncementsTab = (props:Props) => {
  const {user} = useContext(UserContext)
  const { t } = useTranslation()
  const { tournament, disableTextEditor } = props;
  const [loading, setLoading] = useState(false);

  const addAnnouncement = (value:string) => {
    if(!user?.id || !tournament) {
      return
    }
    setLoading(true);
    const announcements:Array<ITournamentReport> = [...tournament?.announcements];
    announcements.push({
      messages: value,
      timestamp: new Date(),
      playerId: user?.id,
    });
    const data = { announcements: announcements };
    TournamentService.updateTournament(data, tournament?.id)
      .then((tournament) => {
        toast.info(t('toast.announcementAddedSuccess'));
        setLoading(false);
        props.onReloadTournament(tournament);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const removeAnnouncement = (announcement:ITournamentReport) => {
    if(!tournament) {
      return
    }
    const announcements = [
      ...tournament?.announcements.filter(
        (item:ITournamentReport) => item.messages !== announcement.messages
      ),
    ];
    const data = { announcements: announcements };
    TournamentService.updateTournament(data, tournament?.id)
      .then((tournament) => {
        toast.info(t('toast.announcementDeleteSuccess'));
        setLoading(false);
        props.onReloadTournament(tournament);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="columns">
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h5 className="skp-text-primary">{t('tabs.announcements')}</h5>
      </div>

      {!disableTextEditor && (
        <div className="column col-12">
          <TextEditor
            onSave={addAnnouncement}
            initialValue={""}
            isSaving={loading}
            hideDeleteAll={true}
          />
        </div>
      )}

      <div className="column col-12">
        <dl className="bordered-list">
          {tournament?.announcements
            .slice(0)
            .reverse()
            .map((report:ITournamentReport, index:Number) => (
              <dd className="columns" key={"dd"+index}>
                <div className="column col-12">
                  <div className="columns">
                    <div className="column col-12">
                      <UserProfile userId={report.playerId}>
                        {(report.playerId === tournament?.owner ||
                          tournament?.admins?.includes(report.playerId)) && (
                          <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                            Admin
                          </span>
                        )}{" "}
                        <span className="skp-text-light skp-text-small">
                          {report?.timestamp && <ReactTimeAgo date={new Date(report?.timestamp)} />} {" - "}
                          {Moment(report?.timestamp).format("MMM DD, hh:mm A")}
                        </span>
                        {/* Remove Announcement */}
                        {!disableTextEditor && (
                          <span
                            className="skp-text-xlarge skp-text-light"
                            onClick={() => removeAnnouncement(report)}
                          >
                            <i className="la la-trash"></i>
                          </span>
                        )}
                        {/* END Remove Announcement */}
                      </UserProfile>
                    </div>
                  </div>
                </div>
                <div
                  className="column col-12 skp-text-light skp-text-default"
                  style={{ padding: "0 0 0 75px" }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: report.messages,
                    }}
                  ></span>
                </div>
              </dd>
            ))}
        </dl>
      </div>
    </div>
  );
};

export default AnnouncementsTab;
