import React, { useState } from 'react'
import ReactPlayer from "react-player";

const ResponsivePlayer = (props) => {
    const { url } = props;
    const [show, setShow] = useState(true);
    const onError = (event) => {
      setShow(false)
    }

    return (
      <div className="skp-player-wrapper">
        {show && <ReactPlayer
          onError={onError}
          className="skp-react-player"
          url={url}
          width="100%"
          height="100%"
        />}
      </div>
    );
}

export default ResponsivePlayer
