import React from "react";
import Marquee from "react-text-marquee";
import YuniteTournamentPlatform from "../../../UiLibrary/YuniteTournamentPlatform";

function YuniteOverviewSide(props) {
  const { tournament } = props;

  return (
    <>
      <div className="space-divider-5"></div>
      <h5 className="skp-text-primary">Platforms</h5>
      <div className="platforms-wrapper skp-text-primary">
        {tournament?.matchTemplate &&
          tournament?.matchTemplate[0].allowedPlatforms.map((platform) => (
            <span key={platform} className="tooltip" data-tooltip={platform}>
              <YuniteTournamentPlatform platform={platform} />
            </span>
          ))}
      </div>
      
      <div className="space-divider-20"></div>
      <h5 className="skp-text-primary">Registrazione dati partita</h5>
      <div className="skp-text-light skp-text-default">
        <dl>
          <dd>
            È OBBLIGATORIO per questo Torneo seguire uno dei due metodi di
            acquisizione dati proposti di seguito:
          </dd>
          <ol>
            <li>
              Scaricare il Client di Yunite tramite il link
              <a
                className="skp-link"
                href="https://yunite.xyz/client"
                target="_blank"
                rel="noreferrer"
              >
                https://yunite.xyz/client
              </a>{" "}
              e seguire la spiegazione per scaricarlo e avviarlo. Il Client
              dovrà rimanere attivo durante tutta la competizione per fare in
              modo che Yunite registri automaticamente i vostri risultati per
              ogni partita disputata senza dover inviare i replay.
            </li>
            <li>
              Se non vuoi scaricare il Client di Yunite dovrai caricare per ogni
              match disputato il replay della partita tramite il seguente link:{" "}
              <a
                className="skp-link"
                href="https://yunite.xyz/replays"
                target="_blank"
                rel="noreferrer"
              >
                https://yunite.xyz/replays
              </a>{" "}
              ATTENZIONE: nel caso di torneo in DUO, TRIO o SQUAD i replay
              devono essere caricati da un solo membro del team.
            </li>
          </ol>
        </dl>
      </div>

      
      <div className="space-divider-20"></div>
      <h5 className="skp-text-primary">Players</h5>
      {/* Players Box */}
      <div className="columns players-box border-box p-2">
        {tournament?.members && (
          <div className="column col-4 text-center">
            <span className="skp-text-xxsmall skp-text-light">
              <Marquee text="Registered" />
            </span>
            <div className="skp-text-primary">{tournament?.members.length}</div>
          </div>
        )}
        <div
          className={
            tournament?.members
              ? "column text-center col-4"
              : "column text-center col-6"
          }
        >
          <span className="skp-text-xxsmall skp-text-light">
            <Marquee text="Min Partecipants" />
          </span>
          <div className="skp-text-primary">{tournament?.consensusMin}</div>
        </div>
        <div
          className={
            tournament?.members
              ? "column text-center col-4"
              : "column text-center col-6"
          }
        >
          <span className="skp-text-xxsmall skp-text-light">
            <Marquee text="Max Partecipants" />
          </span>
          <div className="skp-text-primary">
            {tournament?.matchTemplate &&
              tournament?.matchTemplate[0].maxPlayers}
          </div>
        </div>
      </div>
      {/* END Players Box */}
      <div className="space-divider-20"></div>
      <h5 className="skp-text-primary">Format</h5>
      <div className="columns">
        <div className="column col-12">
          <div className="skp-text-primary skp-text-small">
            Game: {tournament?.game}
          </div>
        </div>
        <div className="space-divider-20"></div>
        <div className="column col-12">
          <div className="skp-text-primary skp-text-small">
            Type:{" "}
            {tournament?.matchTemplate && tournament?.matchTemplate[0].type}
          </div>
        </div>

        {tournament?.check_in && (
          <div className="column col-12">
            <div className="space-divider-20"></div>
            <div className="skp-text-primary skp-text-small">
              Check-in:{" "}
              <Marquee text={`${tournament?.check_in} minutes before start`} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default YuniteOverviewSide;
