
import GameTile from "../../UiLibrary/GameTile";
import { Scrollbars } from "react-custom-scrollbars";
import GAMES from "../../../constants-games";
import { useTranslation } from "react-i18next";

const Games = () => {
  const { t } = useTranslation()
  const games = GAMES;

  const gamesList = Object.keys(games).map((game) => (
    <div className="column col-2 col-xs-6 column-slider" key={games[game].NAME}>
      <GameTile game={games[game]}></GameTile>
    </div>
  ));
  
  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-full-page">
          <div className="containers">
            <div className="columns">
              <div className="column col-12 text-center">
              <h4 className="skp-primary-blue skp-heading ">{t('common.games')}</h4>
              </div>
            </div>
            <div className="space-divider-20"></div>
            <div className="columns">
              {/* Tiles */}
              {gamesList}
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default Games;
