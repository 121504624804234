import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { useParams } from "react-router";
import UserService from "../../../services/UserService";
import ProfilePicture from "../../UiLibrary/ProfilePicture";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import UploadButton from "../../UiLibrary/UploadButton/UploadButton";
import UploadImageButton from "../../UiLibrary/UploadImageButton";
import ImageService from "../../../services/ImageService";
import { toast } from "react-toastify";
import KeyCloackService from "../../../services/KeyCloackService";
import OverviewTab from "./UserTabs/OverviewTab";
import HostingTab from "../Tournaments/MyTournaments/MyTournamentsTabs/HostingTab";
import CurrentlyPlayingTab from "../Tournaments/MyTournaments/MyTournamentsTabs/CurrentlyPlayingTab";
import MyTeamsTab from "../Teams/TeamTabs/MyTeamsTab";
import IUserDetails from "../../Interfaces/UserDetails";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import GAMES from "../../../constants-games";
import ClashRoyaleStatsTab from "./UserTabs/ClashRoyaleStatsTab";
import { useTranslation } from "react-i18next";

const User = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation()
  const [userInfo, setUserInfo] = useState<IUserDetails>();
  const params = useParams<{ id: string; tab: string }>();
  const userId = params.id;
  const currentTab = params.tab;

  const tabs = [
    { name: t('tabs.overview'), url: "overview" },
    { name: t('tabs.hosting'), url: "hosting" },
    { name: t('tabs.currentlyPlaying'), url: "playing" },
    { name: t('tabs.myTeams'), url: "teams" },
    { name: t('tabs.clashRoyaleStats'), url: "clash-royale-stats" },
  ];

  useEffect(() => {
    if (!currentTab) {
      navigate(`/users/${userId}/overview`);
    }
    if( userId && userId?.length >= 24) {
      UserService.getUser(userId, true).then((Ruser) => {
        setUserInfo(Ruser);
      });
    }
  }, [userId, currentTab, navigate]);

  const reloadUser = () => {
    if( userId && userId?.length >= 24) {
      UserService.getUser(userId, true).then((Ruser) => {
        setUserInfo(Ruser);
      });
    }
  };

  const removeEmailString = (username: string | undefined) => {
    if (username?.lastIndexOf("@") !== -1) {
      username = username?.substring(0, username?.lastIndexOf("@"));
    }
    return username;
  };

  const uploadPicture = async (image: IFileBase64Image, type: string) => {
    setLoading(true);
    let dataToPass: any = {};
    const imageResponse = await ImageService.uploadImage(image);
    const { data } = await imageResponse;
    dataToPass[type] = ImageService.generatePublicUrl(data);
    UserService.updateUserInfo(dataToPass, userId)
      .then((user) => {
        setUserInfo(user);
        setUser(user);

        toast.success(t('toast.userUpdatedSuccess'));
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const filterClashRoyaleTabs = () => {
    return (
      userInfo?.gameAccounts?.find(
        (account) => account?.game === GAMES.CLASH_ROYALE.ENUM
      )?.id === undefined
    );
  };


  return (
    <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
      {userId === 'undefined' && (
        <div className="columns">
          <div className="space-divider"></div>
          <div className="column col-12 flex-centered skp-text-light">
            <h3>{t('user.config.notSkillproUser')}</h3>
          </div>
          <div className="column col-12 flex-centered">
            <button className="skp-btn skp-btn-primary skp-text-small" onClick={() => navigate(-1)} >{t('user.config.back')}</button>
          </div>
        </div>
      )}
      {userId !== 'undefined' && <div className="skp-page-with-image-banner">
        {user?.id === userInfo?.id && (
          <UploadButton
            type="banner"
            loading={loading}
            onUpdateImage={(image) => uploadPicture(image, "profile_banner")}
          >
            <button className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default">
              <i className="las la-camera"></i> {t('component.uploadBanner')}
            </button>
          </UploadButton>
        )}
        {/* IMAGE BANNER */}
        <div className="skp-image-banner">
          {userInfo?.profile_banner && (
            <img alt="profile-banner" src={userInfo?.profile_banner} />
          )}
          <div className="transparent-black"></div>
        </div>
        {/* END IMAGE BANNER */}
        {/* PAGE TOP */}
        <div className="container page-top">
          {/* Page Top */}
          <div className="columns page-top">
            <div className="column col-auto profile-picture text-center col-xs-12 text-center-xs">
              {userInfo && (
                <ProfilePicture user={userInfo}>
                  <>
                    {user?.id === userInfo?.id && (
                      <UploadButton
                        type="logo"
                        loading={loading}
                        onUpdateImage={(image: any) =>
                          uploadPicture(image, "profile_picture")
                        }
                      >
                        <UploadImageButton />
                      </UploadButton>
                    )}
                  </>
                </ProfilePicture>
              )}
            </div>
            <div className="column col-auto col-xs-12 text-center-xs">
                <div className="column col-12">
                <div className="skp-text-label skp-text-light">
                  {t('user.config.user')} 
                </div>
                <div className="skp-text-primary" style={{fontSize:32}}>
                  {userInfo?.nickname ? userInfo?.nickname : removeEmailString(userInfo?.username || "")}
                </div>
                <div className="skp-text-primary skp-text-default">
                  @{removeEmailString(userInfo?.username || "")}
                </div>
              </div>
                  {KeyCloackService.isLoggedIn() &&
                    user?.id === userInfo?.id && (
                      <Link
                        to={`/users/${userId}/settings/usersettings`}
                        className="skp-link skp-text-small"
                      >
                        {t('common.settings')} <i className="las la-cog"></i>
                      </Link>
                    )}
                </div>
                {/* <div className="column col-12">
                  <div className="space-divider-20"></div>
                  <div className="columns skp-text-primary">
                    <div className="column col-4">0 Played</div>
                    <div className="column col-4">0% Win Rate</div>
                    <div className="column col-4">0 Reputation</div>
                  </div>
                </div> */}
          </div>
        </div>
        {/* END PAGE TOP */}
        <div className="space-divider-5"></div>
        {/* TABS HEADER */}
        <div className="container tabs-header">
          <div className="columns">
            <div className="column col-12">
              <ul className="tab skp-text-light">
                {tabs
                  .filter((tab) =>
                    filterClashRoyaleTabs()
                      ? tab.url !== "clash-royale-stats"
                      : tab
                  )
                  .map((tab) => (
                    <li
                      key={tab.name}
                      className={
                        currentTab === tab.url ? "tab-item active" : "tab-item"
                      }
                    >
                      <Link
                        to={`/users/${userId}/${tab.url}`}
                        className={currentTab === tab.url ? "active" : ""}
                      >
                        {tab.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        {/* END TABS HEADER */}
        {/* TABS BODY */}
        <div className="container tabs-body">
          {currentTab === tabs[0].url && (
            <OverviewTab
              userInfo={userInfo}
              user={user}
              reloadUser={reloadUser}
            />
          )}
          {currentTab === tabs[1].url && (
            <HostingTab userId={userId!} path="hosting" />
          )}
          {currentTab === tabs[2].url && (
            <CurrentlyPlayingTab userId={userId!} path="playing" />
          )}
          {currentTab === tabs[3].url && (
            <>
              <div className="columns">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h5 className="skp-text-primary">{t('tabs.myTeams')} </h5>
                </div>
              </div>
              <MyTeamsTab />
            </>
          )}
          {currentTab === tabs[4].url && (
            <>
              <div className="columns">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h5 className="skp-text-primary">{t('tabs.clashRoyaleStats')}</h5>
                </div>
              </div>
              <ClashRoyaleStatsTab
                userId={userInfo?.id}
                playerTag={
                  userInfo?.gameAccounts?.find(
                    (account) => account?.game === GAMES.CLASH_ROYALE.ENUM
                  )?.id
                }
              ></ClashRoyaleStatsTab>
            </>
          )}
        </div>
        {/* END TABS BODY */}
      </div>}
    </Scrollbars>
  );
};

export default User;
