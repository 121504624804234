
import constant from "../constants";
import { setupCache } from "axios-cache-adapter";
import HttpService from "./HttpService";
import ITournamentApexRespawnMatch from "../components/Interfaces/TournamentApexRespawnMatch";
import ITournamentApexRespawnLeaderboard from "../components/Interfaces/TournamentApexRespawnLeaderboard";
import ITournamentApexRespawnTeam from "../components/Interfaces/TournamentApexRespawnTeam";


const cache = setupCache({
  maxAge: 5 * 60 * 1000,
});

const _axios = HttpService.getAxiosClient();


const getMatches = (tournamentId:string, token:string, lobbyId:string, gameNumber:number | string):Promise<Array<ITournamentApexRespawnMatch>> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies/${lobbyId}/tokens/${token}/matches?game=${gameNumber}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data;
  });
}

const getMatchTeams = (tournamentId:string, token:string, lobbyId:string, gameNumber:number | string):Promise<Array<ITournamentApexRespawnTeam>> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies/${lobbyId}/tokens/${token}/teams?game=${gameNumber}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data;
  });
}

const getMatchLeaderboard = (tournamentId:string, token:string, lobbyId:string, gameNumber:number | string):Promise<Array<ITournamentApexRespawnLeaderboard>> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies/${lobbyId}/tokens/${token}/leaderboard?game=${gameNumber}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data;
  });
}

const getLobbyLeaderboard = (tournamentId:string, lobbyId:string):Promise<Array<ITournamentApexRespawnLeaderboard>> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies/${lobbyId}/leaderboard`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data;
  });
}

const addLobby = (tournamentId:string, data:any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies`;
  return _axios({
    method: "post",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const updateLobby = (tournamentId:string, lobbyId:string, data:any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies/${lobbyId}`
  return _axios({
    method: "put",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const deleteLobby = (tournamentId:string, lobbyId:string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/respawn/lobbies/${lobbyId}`
  return _axios({
    method: "delete",
    url: url,
  }).then((response) => {
    return response;
  });
}

const seedLobbies = (tournamentId:string, numberOfLobbies:number) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/matches`;
  return _axios({
    method: "post",
    url: url,
    data: {lobbies: numberOfLobbies}
  }).then((response) => {
    return response;
  });
}


const ApexTournamentService = {
  getMatches,
  getMatchTeams,
  getMatchLeaderboard,
  getLobbyLeaderboard,
  addLobby,
  updateLobby,
  deleteLobby,
  seedLobbies
};

export default ApexTournamentService