import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { useParams } from "react-router";
import { Navigate, Link, useNavigate } from "react-router-dom";
import ProfilePicture from "../../UiLibrary/ProfilePicture";
import UploadButton from "../../UiLibrary/UploadButton/UploadButton";
import "./UserSettiings.scss";
import UserSettingsForm from "./Forms/UserSettingsForm";
import PersonalInformationForm from "./Forms/PersonalnformationForm";
import PasswordForm from "./Forms/PasswordForm";
import UploadImageButton from "../../UiLibrary/UploadImageButton";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";
import ImageService from "../../../services/ImageService";
import Modal from "../../UiLibrary/Modal";
import KeyCloackService from "../../../services/KeyCloackService";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import { useTranslation } from "react-i18next";

const UserSettings = () => {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation()
  const params = useParams<{tab:string}>()
  const currentTab = params.tab;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();

  const tabs = [
    { name: t('user.config.userSettings'), url: "usersettings" },
    { name: t('user.config.personalInformation'), url: "personalinformation" },
    { name: t('user.config.password'), url: "password" },
  ];

  const uploadPicture = (image:IFileBase64Image, type:string) => {
    setLoading(true);
    ImageService.uploadImage(image).then((response) => {
      let data = {} as any;
      data[type] = ImageService.generatePublicUrl(response.data);
      UserService.updateUserInfo(data, user?.id)
        .then((RUser) => {
          setUser(RUser);

          toast.success(t('toast.userUpdatedSuccess'));
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
    });
  };

  const deleteUser = () => {
    if(!user?.id) {
      return
    }
    setLoading(true);
    UserService.deleteUser(user.id).then(() => {
      KeyCloackService.doLogout().then(() => {
        navigate("/home");
      });
      toast.success(t('toast.userDeletedSuccess'));
    });
  };

  const removeEmailString = (username:string) => {
    if (username?.lastIndexOf("@") !== -1) {
      username = username?.substring(0, username?.lastIndexOf("@"));
    }
    return username;
  };

  return (
    <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
          <div className="skp-page-with-image-banner">
            <UploadButton
              type="banner"
              loading={loading}
              onUpdateImage={(image) => uploadPicture(image, "profile_banner")}
            >
              <button className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default">
                <i className="las la-camera"></i> {t('component.uploadBanner')}
              </button>
            </UploadButton>

            {/* IMAGE BANNER */}
            <div className="skp-image-banner">
              {user?.profile_banner && (
                <img alt="profile-banner" src={user?.profile_banner} />
              )}
              <div className="transparent-black"></div>
            </div>
            {/* END IMAGE BANNER */}
            <div className="container user-settings-page page-top">
              <div className="columns">
                <div className="column col-9 col-xs-12 text-center-xs">
                  <div className="columns">
                    <div className="space-divider-40 show-xs"></div>
                    <div className="column col-auto col-xs-12">
                      {user && <ProfilePicture user={user}>
                        <UploadButton
                          type="logo"
                          loading={loading}
                          onUpdateImage={(image:any) =>
                            uploadPicture(image, "profile_picture")
                          }
                        >
                          <UploadImageButton />
                        </UploadButton>
                      </ProfilePicture>}
                    </div>
                    <div className="column col-auto col-xs-12">
                  <div className="column col-12">
                    <div className="skp-text-label skp-text-light">
                      {t('user.config.userSettings')}
                    </div>
                    <div className="skp-text-primary" style={{fontSize:32}}>
                      {user?.nickname ? user.nickname : removeEmailString(user?.username || "")}
                    </div>
                    <div className="skp-text-primary skp-text-default">
                      @{removeEmailString(user?.username || "")}
                    </div>
                    
                      </div>

                      <Link
                        to={`/users/${user?.id}/overview`}
                        className="skp-link skp-text-small"
                      >
                        {t('user.config.viewUserProfile')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="column col-3 col-xs-12 text-right text-center-xs">
                  <button
                    className="skp-btn skp-btn-alert"
                    onClick={() => setShowModal(true)}
                  >
                    {t('user.config.deleteMyAccount')}
                  </button>
                </div>
              </div>
              </div>
              <div className="space-divider"></div>
              
              <div className="container tabs-header">
                {/* Tabs Header */}
              <div className="columns skp-text-light">
                <div className="column col-12">
                  <ul className="tab">
                    {tabs.map((tab) => (
                      <li
                        key={tab.name}
                        className={
                          currentTab === tab.url ? "tab-item active" : "tab-item"
                        }
                      >
                        <Link
                          to={`/users/${user?.id}/settings/${tab.url}`}
                          className={currentTab === tab.url ? "active" : ""}
                        >
                          {tab.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              </div>

              {/* Tabs Body */}
              <div className="container tabs-body">
              <div className="columns">
                {currentTab === tabs[0].url && <UserSettingsForm />}
                {currentTab === tabs[1].url && <PersonalInformationForm />}
                {currentTab === tabs[2].url && <PasswordForm />}
              </div>
              <div className="space-divider"></div>
            </div>
          </div>

          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
            onConfirm={deleteUser}
            size="medium"
            title={t('user.config.deleteMyAccount')}
            confirmation={t('common.delete')}
            loading={loading}
          >
            <div className="container">
              <div className="space-divider-20"></div>
              <div className="columns skp-text-primary">
                <div className="column col-12 text-center">
                  {t('user.config.deleteAccountConfirm')}
                </div>
              </div>
              <div className="space-divider"></div>
            </div>
          </Modal>
        </Scrollbars>
  );
};

export default UserSettings;
