import { useEffect, useState } from "react";
import SupercellTournamentService from "../../../../services/SupercellTournamentService";
import "./clash-royale-tab.scss";

type Props = {
  userId: string | undefined;
  playerTag: string | undefined;
};

const ClashRoyaleStatsTab = (props: Props) => {
  const { userId, playerTag } = props;
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userId && playerTag) {
      setLoading(true);
      SupercellTournamentService.getPlayerStats(userId, playerTag).then(
        (Rdata) => {
          console.log(Rdata);
          setData(Rdata);
          setLoading(false);
        }
      );
    }
  }, [userId, playerTag]);

  return (
    <div className="clash-royale-tab">
      {loading && (
        <>
          <div className="space-divider"></div>
          <div className="column col-12 mt-2 mb-2">
            <div className="loading"></div>
          </div>
        </>
      )}
      {!loading && (
        <div className="columns">
          <div className="column col-12 skp-text-light">
            <h3 className="skp-text-primary">
              <span className="text-bold">{data?.name}</span>{" "}
              <span className="skp-text-small">{data?.tag}</span>
            </h3>
          </div>
          <div className="column col-12 skp-text-light">
            <h6>CLAN: {data?.clan?.name}</h6>
          </div>
          <div className="column col-12 skp-text-light">
            <h6>
              {data?.trophies}/{data?.bestTrophies} PB{" "}
              <i
                className="las la-trophy"
                style={{
                  fontSize: "20px",
                  color: "#DAAF37",
                }}
              ></i>{" "}
              {data?.arena?.name}
            </h6>
          </div>
          <div className="space-divider-20"></div>
          <div className="column col-6 col-xs-12">
            <div className="columns skp-text-primary">
              <div className="column col-12 text-bold">Battle Stats</div>
              <div className="space-divider-20"></div>
              <div className="column col-8">Wins</div>
              <div className="column col-4">{data?.wins}</div>
              <div className="space-divider-20"></div>
              <div className="column col-8">Losses</div>
              <div className="column col-4">{data?.losses}</div>
            </div>
          </div>
          <div className="space-divider-20 show-xs"></div>
          <div className="column col-6 col-xs-12">
            <div className="columns skp-text-primary">
              <div className="column col-12 text-bold">Challange Stats</div>
              <div className="space-divider-20"></div>
              <div className="column col-8">Max wins</div>
              <div className="column col-4">{data?.challengeMaxWins}</div>
              <div className="space-divider-20"></div>
              <div className="column col-8">Cards won</div>
              <div className="column col-4">{data?.challengeCardsWon}</div>
            </div>
          </div>

          <div className="space-divider-20"></div>
          <div className="column col-12 text-bold skp-text-primary">
            Current Favourite Card
          </div>
          <div className="space-divider-20"></div>
          <div className="column col-12">
            <div className="columns skp-text-primary">
              <div className="column col-1 col-xs-3 card-holder flex-centered">
                <img
                  src={data?.currentFavouriteCard?.iconUrls.medium}
                  alt="card"
                />
              </div>
            </div>
          </div>

          <div className="space-divider-20"></div>
          <div className="column col-12 text-bold skp-text-primary">
            Current Deck
          </div>
          <div className="space-divider-20"></div>
          <div className="column col-12">
            <div className="columns skp-text-primary">
              {data?.currentDeck.map((card: any) => (
                <div className="column col-1 col-xs-2 card-holder flex-centered">
                  <img src={card.iconUrls.medium} alt="card" key={card.id} />
                  <sup className="card-level text-bold">
                    Lvl {card.level}/{card.maxLevel}
                  </sup>
                </div>
              ))}
            </div>
          </div>

          <div className="space-divider-20"></div>
          <div className="column col-12 text-bold skp-text-primary">Cards</div>
          <div className="space-divider-20"></div>
          <div className="column col-12">
            <div className="columns skp-text-primary">
              {data?.cards.map((card: any) => (
                <div className="column col-1 col-xs-2 card-holder flex-centered">
                  <img src={card.iconUrls.medium} alt="card" key={card.id} />
                  <sup className="card-level text-bold">
                    Lvl {card.level}/{card.maxLevel}
                  </sup>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClashRoyaleStatsTab;
