import { useContext, useEffect, useState } from 'react'
import RenderOnAuthenticated from '../KeyCloack/RenderOnAuthenticated'
import RenderOnAnonymous from '../KeyCloack/RenderOnAnonymus'
import ProfileDropdown from '../UiLibrary/ProfileDropdown'
import KeyCloackService from '../../services/KeyCloackService'
import ActionButtons from './ActionButtons/ActionButton'
import { RegistrationModalContext } from '../Context/RegistrationModalContext'
import { Link } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import NotificationsButton from './NotificationsButton/NotificationsButton'
import { UserContext } from '../Context/UserContext'
import SiteAdminDropdown from './SiteAdminDropdown'
import MessagesButton from './MessagesButton/MessagesButton'
import Modal from '../UiLibrary/Modal'
import { useTranslation } from 'react-i18next'
import UserInfoModal from '../Modals/UserModal/UserInfoModal'

const Header = () => {
    
    const {setShowSignUpModal} = useContext(RegistrationModalContext);
    const { user } = useContext(UserContext);
    const { t, i18n } = useTranslation()
    const [showUserInfoModal, setShowUserInfoModal] = useState(false)
    const canAssignRole = user?.roles ? user.roles.includes("SiteOwner") : false;
    const isTester = user?.roles ? user.roles.includes("Tester") : false;

    const onSignUp = () => {
      if (isMobile) {
        KeyCloackService.showLoginModal(t('header.loginSignUp'));
      } else {
        setShowSignUpModal({ show: true, type: "signup" })
      }
    }

    const onCloseModal = () => {
      setShowUserInfoModal(false);
    }

    useEffect(()=>{
      if (user) {
        i18n.changeLanguage(user.language)
        if( 
          (!user.birth_date || user.birth_date.length < 2) ||
          (!user.country)
        ) {
          setShowUserInfoModal(true)
        }
      }
    },[])

    return (
      <header className="navbar">
        <section className="navbar-section">
          <Link to="/home">
            <div className="logo-skillpro"></div>
          </Link>
        </section>
        <RenderOnAnonymous>
          <section className="navbar-section right-side login-buttons-section">
            <button
              className="skp-btn skp-btn-primary hide-xs"
              onClick={() => KeyCloackService.doLogin()}
            >
              {t('header.login')}
            </button>
            <button
              className="skp-btn skp-btn-secondary"
              onClick={() => onSignUp()}
            >
              {t('header.SignUp')}
            </button>
          </section>
        </RenderOnAnonymous>

        <RenderOnAuthenticated>
          <section className="navbar-section right-side">
            {canAssignRole && (
              <SiteAdminDropdown />
            )}
            {isTester && (
              <MessagesButton />
            )}
            <NotificationsButton />
            <ActionButtons />
            <div className="vertical-space-divider"></div>
            <ProfileDropdown></ProfileDropdown>
          </section>
        </RenderOnAuthenticated>

        <Modal
          show={showUserInfoModal}
          onClose={() => setShowUserInfoModal(false)}
          title={t('user.userInfoModal.title')}
          size="large"
          overflow
          hideClose
        >
          <>
            <div className="logo-skillpro" style={{position: "absolute", top: "14px"}}></div>
            <UserInfoModal closeModal={onCloseModal}/>
          </>
        </Modal>
    
      </header>
    );
}

export default Header