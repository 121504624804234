import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TournamentService from "../../../../services/TournamentService";
import ITournament from "../../../Interfaces/Tournament";
import CheckinTournamentModal from "../../../Modals/CheckinTournamentModal/CheckinTournamentModal";
import Modal from "../../../UiLibrary/Modal";
import IUserDetails from "../../../Interfaces/UserDetails";
import UserService from "../../../../services/UserService";

type Props = {
  tournament: ITournament;
  playerId: string;
  registrationClosed: boolean;
  removeState: boolean;
  checkinClosed: boolean;
  onReloadTournament: (tournament?: ITournament) => void;
};

/*
 * Questo bottone si occupa di gestire lo stato dell'utente all'interno del torneo, è un bottone
 * disponibile solo per l'admin o l'owner del torneo e consente di effettuare il checkin o il checkout
 * del player e di rimuoverlo dal torneo, ovviamente questo solo nel caso in cui il torneo non sia già
 * nello stato di ongoing o completed
 */
const TournamentUserStateButton = (props: Props) => {
  const { tournament, playerId, removeState, registrationClosed, checkinClosed } = props;
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<IUserDetails>();
  
  const [tournamentCheckin, setTournamentCheckin] = useState(
      tournament && tournament?.members?.includes(playerId) &&
      registrationClosed &&
      !tournament?.confirmedMembers?.includes(playerId) && 
      tournament.status === 0 
  )
  const [tournamentCheckout, setTournamenteCheckout] = useState(
      registrationClosed && 
      tournament.status === 0 && 
      tournament?.confirmedMembers?.includes(playerId)
  )
  
  let modalSize = tournament?.yuniteId ? "large" : "small";
  if (tournament?.type !== "1vs1") {
    modalSize = "";
  }

  useEffect(()=>{
    if (playerId) {
      setLoading(true);
      UserService.getUser(playerId).then((Ruser) => {
        setUserProfile(Ruser);
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    }
  },[playerId])

  const checkinTournament = (
    members: string | Array<string> | undefined,
    data: any
  ) => {
    if (!tournament) {
      return;
    }
    const isAdmin = "true"

    setLoading(true);
    TournamentService.checkinTournament(tournament?.id, playerId, data, isAdmin)
      .then((response) => {
        toast.success("Tournament checkin successfully");
        setShowCheckinModal(false);
        //props.onReloadTournament();
        setTournamentCheckin(!tournamentCheckin)
        setTournamenteCheckout(!tournamentCheckout)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const checkoutTournament = () => {
    setLoading(true);
    const data = {isAdmin: true}
    TournamentService.checkoutTournament(tournament?.id, playerId, data )
      .then(() => {
        toast.success("Tournament checkout successfully");
        setShowCheckoutModal(false);
        setTournamentCheckin(!tournamentCheckin)
        setTournamenteCheckout(!tournamentCheckout)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const leaveTournament = () => {
    setLoading(true);
    const data = {isAdmin: true}
    if ( tournament?.confirmedMembers?.includes(playerId) ) {
      checkoutTournament()
    }
    TournamentService.unSubscribeTournament(tournament?.id, playerId, data)
      .then(() => {
        toast.success("Tournament unsubscribed successfully");
        props.onReloadTournament();
        setShowLeaveModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const checkinTournamentAsSingle = (data: any) => {
    checkinTournament(playerId, data);
  };

  const doCheckinTournament = (show: boolean) => {
    setShowCheckinModal(true);
  };

  return (
    <>
      {/* In questo caso devo mostrare il tasto per rimuovere il player*/}
      {removeState && (
        <>
          {tournament && tournament?.members?.includes(playerId) && 
            tournament.status === 0 && (
              <button
                className="skp-btn skp-btn-primary skp-text-small"
                onClick={() => setShowLeaveModal(true)}
              >
                Remove Player
              </button>
          )}
        </>
      )}
      {!removeState && !tournament?.yuniteId && (
        <>
          { tournamentCheckin && (
              <button
                className="skp-btn skp-btn-primary skp-text-small"
                onClick={() => doCheckinTournament(true)}
              >
                Tournament Check-in
              </button>
            )}

            { tournamentCheckout &&(
              <>
                <button
                  className="skp-btn skp-btn-alert skp-text-small"
                  onClick={() => setShowCheckoutModal(true)}
                >
                  Tournament check-out{" "}
                </button>
              </>
            )}
        </>
      )}

      <Modal
        show={showCheckinModal}
        onClose={() => setShowCheckinModal(false)}
        size={modalSize}
        title="Tournament Check-in"
      >
        <>
          { tournament?.playerType === 0 && (
            <CheckinTournamentModal
              onConfirm={checkinTournamentAsSingle}
              loading={loading}
              tournament={tournament}
              selectUser={userProfile}
            />
          )}
        </>
      </Modal>

      <Modal
        show={showLeaveModal}
        onClose={() => setShowLeaveModal(false)}
        onConfirm={leaveTournament}
        loading={loading}
        confirmation="Leave Tournament"
        size="small"
        title="Leave Tournament"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider"></div>
            <div className="column col-12 text-center skp-text-primary">
              <span>
                Are you sure want to leave tournament{" "}
                <strong>{tournament?.name}</strong> ?
              </span>
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showCheckoutModal}
        onClose={() => setShowCheckoutModal(false)}
        onConfirm={checkoutTournament}
        loading={loading}
        confirmation="Tournament Check-out"
        size="small"
        title="Tournament Check-out"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider"></div>
            <div className="column col-12 text-center skp-text-primary">
              <span>
                Are you sure want to remove player from tournament{" "}
                <strong>{tournament?.name}</strong> ?
              </span>
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TournamentUserStateButton;
