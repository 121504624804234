// Contatti con i quali sto conversando
// Indice della conversazione


import { ReactNode, createContext, useContext, useState } from "react";
import IUserDetails from "../Interfaces/UserDetails";
import IChat from "../Interfaces/Chat/Chat";
import IMessage from "../Interfaces/Chat/Message";

export type ChatContent = {
    /* Contatti con i quali sto chattando */
    contacts: IUserDetails[] | undefined;
    setContacts: (contact: IUserDetails[] | undefined) => void;

    /* Id della chat attiva al momento */
    chatId: string | undefined;
    setChatId: (chatId: string | undefined) => void;

    /* Lista delle chat da mostrare sulla sidebar */
    chatList: IChat[] | undefined;
    setChatList: (chatList: IChat[] | undefined) => void;

    /* Numero di chat caricate nella sidebar */
    numChatList: number;
    setNumChatList: (numChatList: number) => void

    /* Variabile che triggera il refresh dei messaggi*/
    refresh: number;
    setRefresh: (refresh: number) => void;

    /* Variabile per il reply del messaggio */
    replyMessage: IMessage | undefined;
    setReplyMessage: (replyMessage: IMessage | undefined) => void;
};

export const ChatContext = createContext<ChatContent>({
    contacts: undefined, // set a default value
    setContacts: () => {},
    chatId: undefined,
    setChatId: () => {},
    chatList: undefined,
    setChatList: () => {},
    numChatList: 10,
    setNumChatList: () => {},
    refresh: 0,
    setRefresh: () => {},
    replyMessage: undefined,
    setReplyMessage: () => {}
});

type ChatProviderProps = {
    children: ReactNode
}

const ChatProvider = ({children}: ChatProviderProps) => { 
    const [contacts, setContacts] = useState<IUserDetails[]>()
    const [chatId, setChatId] = useState<string>()
    const [chatList, setChatList] = useState<IChat[]>();
    const [numChatList, setNumChatList] = useState<number>(10)
    const [refresh, setRefresh] = useState<number>(0)
    const [replyMessage, setReplyMessage] = useState<IMessage>()

	return(
		<ChatContext.Provider 
            value={{
                contacts, 
                setContacts, 
                chatId, 
                setChatId, 
                chatList, 
                setChatList,
                numChatList,
                setNumChatList,
                refresh,
                setRefresh,
                replyMessage, 
                setReplyMessage
            }}>
			{children}
		</ChatContext.Provider>
	)
}

export default ChatProvider;