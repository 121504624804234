import { ReactNode, createContext, useState } from "react";
import { INotificationElement } from "../Interfaces/notification.interface";

export type NotificationContent = {
    tournamentNotificationId: string | undefined;
    setTournamentNotificationId: (tournamentNotificationId:string | undefined) => void;
    tournamentNotification: INotificationElement | undefined;
    setTournamentNotification: (tournamentNotification: INotificationElement | undefined) => void;
};

export const NotificationContext = createContext<NotificationContent>({
    tournamentNotificationId: undefined,
    setTournamentNotificationId: () => {},
    tournamentNotification: undefined,
    setTournamentNotification: () => {}
});

type NotificationProviderProps = {
    children: ReactNode
}

const NotificationProvider = ({children}: NotificationProviderProps) => { 
    const [tournamentNotificationId, setTournamentNotificationId] = useState<string>()
    const [tournamentNotification, setTournamentNotification] = useState<INotificationElement>()

	return(
		<NotificationContext.Provider 
            value={{
                tournamentNotificationId,
                setTournamentNotificationId,
                tournamentNotification, 
                setTournamentNotification
            }}>
			{children}
		</NotificationContext.Provider>
	)
}

export default NotificationProvider;