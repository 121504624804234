import Marquee from 'react-text-marquee';
import { Link } from "react-router-dom"; 
import AddToFavourite from './AddToFavourite';

const GameTile = props => {
    const { disableLink, game, hideFav, hideName } = props;
    return (
      <div className="game-tile">
      {disableLink && (
        <div className="game-tile-image">
          {game?.IMAGE_URL && <img alt={game?.NAME} src={game?.IMAGE_URL} />}
        </div>
      )}
      {!disableLink && (
        <Link to={!disableLink ? `/games/${game?.LABEL}` : "#"}>
          <div className="game-tile-image">
            {game?.IMAGE_URL && <img alt={game?.NAME} src={game?.IMAGE_URL} />}
          </div>
        </Link>
      )}
        {!hideName && <div className="columns">
          <div className="column col-12 text-center">
          {disableLink && (
            <span className="skp-text-default skp-text-primary">
              <Marquee text={game?.NAME} />
            </span>
          )}
          {!disableLink && (
            <Link to={!disableLink ? `/games/${game?.LABEL}` : "#"}>
              <span className="skp-text-default skp-text-primary">
                <Marquee text={game?.NAME} />
              </span>
            </Link>
          )}
            {!hideFav && <AddToFavourite game={game} />}
          </div>
        </div>}
      </div>
    );        
}

export default GameTile