import MatchLevel from "../../enums/MatchLevel";
import IMatchTournamentSeed from "../Interfaces/MatchTournamentSeed";
import ITournamentBracketRound from "../Interfaces/TournamentBracketRound";

interface ITournamentBracketRounds {
  [key: string] : ITournamentBracketRound[]
  winners: ITournamentBracketRound[];
  loosers: ITournamentBracketRound[];
}

interface Matches {
  title: string,
  seeds: IMatchTournamentSeed[]
}

const groupBy =
  (key: string) =>
  (array: Array<any>): { [key: string]: Array<IMatchTournamentSeed> } =>
    array?.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

const generate = (
  matches: Array<IMatchTournamentSeed>
):ITournamentBracketRounds  => {
    let findFinalIndex;

    let roundsAndMatches:Matches[] = [];
    const groupByRound = groupBy("round");

    const rounds = groupByRound(matches);
    for (let key in rounds) {
      const matches = { title: key, seeds: rounds[key] };
      roundsAndMatches.push(matches);
    }
    
    let findThirdPlaceIndex = -1;
    if (matches && matches[0] && matches[0].matchLevel !== undefined && matches[0].matchLevel >= 0 ) {
      findThirdPlaceIndex = roundsAndMatches.findIndex(
        (round) => round.seeds.some(level => level.matchLevel === MatchLevel.SINGLE_ELIMINATION_THIRD_FORTH_PLACE))

      if (findThirdPlaceIndex && findThirdPlaceIndex !== -1) {
        findFinalIndex = findThirdPlaceIndex;
      } else {
        findFinalIndex = roundsAndMatches.findIndex(
          (round) => round.seeds.some(level => level.matchLevel === MatchLevel.SINGLE_ELIMINATION_FINAL))
        if (findFinalIndex === -1 ) {
          findFinalIndex = roundsAndMatches.findIndex(
            (round) => round.seeds.some(level => level.matchLevel === MatchLevel.DOUBLE_ELIMINATION_RESET_GRAND_FINAL))
        }
        if (findFinalIndex === -1 ) {
          findFinalIndex = roundsAndMatches.findIndex(
            (round) => round.seeds.some(level => level.matchLevel === MatchLevel.DOUBLE_ELIMINATION_GRAND_FINAL))
        }
      }
    } else {
      findThirdPlaceIndex = roundsAndMatches.findIndex(
      (round) => round.title === "Third Fourth Place"
    );
  findFinalIndex = findThirdPlaceIndex && findThirdPlaceIndex !== -1 ? findThirdPlaceIndex : roundsAndMatches.findIndex(
    (round) => round.title === "Final"
  );
    }

  if (findFinalIndex !== -1) {
    const loosersRounds = roundsAndMatches.slice(findFinalIndex + 1);
    const winnersRounds = roundsAndMatches.slice(0, findFinalIndex + 1);
    return { winners: winnersRounds, loosers: loosersRounds };
  } else {
    return { winners: roundsAndMatches, loosers: [] };
  }
};

const GenerateBracketRounds = {
  generate,
};

export default GenerateBracketRounds;
