import React, { useState, useEffect, useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import TournamentService from "../../../services/TournamentService";
import qs from "query-string";
import TournamentTile from "../../UiLibrary/TournamentTile";
import TournamentFilters from "../Tournaments/TournamentFilters/TournamentFilters";
import GAMES from "../../../constants-games";
import IGame from "../../Interfaces/Game";
import ITournament from "../../Interfaces/Tournament";
import { UserContext } from "../../Context/UserContext";

const GameSingle = () => {
  const params = useParams<{label: string}>();
  const { user } = useContext(UserContext);
  const gameLabel = params.label;
  const games = GAMES;
  const [game, setGame] = useState<IGame>();

  const location = useLocation();
  const [tournaments, setTournamentList] = useState<Array<ITournament>>([]);
  const navigate = useNavigate();
  const pathName = `/games/${game?.LABEL}`;


  const runQuery = (params:any) => {
    navigate({
      pathname: pathName,
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    let queryParam = qs.parse(location.search);
    for (let game in games) {
      if (games[game].LABEL === gameLabel) {
        setGame(games[game]);
        queryParam.game = games[game].ENUM;
      }
    }
    const isTester = user && user?.roles && user.roles.includes("Tester") ? true : false
    TournamentService.getTournaments(queryParam, isTester, user?.id).then((tournaments) => {
      setTournamentList(tournaments);
    });
    return () => {
      setGame(undefined);
    };
  }, [games, gameLabel, location]);

  return (
    <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
      <div className="skp-page-with-image-banner">
        {/* IMAGE BANNER */}

        <div
          className={`skp-image-banner tournament-image-banner ${game?.ENUM}`}
        >
          <div className="transparent-black"></div>
        </div>
        {/* END IMAGE BANNER */}

        {/* PAGE TOP */}
        <div className="container page-top">
          <div className="columns">
            <div className="column col-8 col-xs-12 text-center-xs">
              <span className="skp-text-label skp-text-light">GAME</span>{" "}
              <br></br>
              <h1 className="skp-text-primary skp-heading-no-margin">
                {game?.NAME}
              </h1>
            </div>

            <div
              className={`column col-4 col-xs-12 text-right text-center-xs`}
            ></div>
          </div>
          <div className="space-divider-20"></div>
          <div className="container">
            {/* Tournament Filters */}
            <TournamentFilters onRunQuery={runQuery} singleSelect={true} />
            <div className="space-divider-20"></div>
            <div className="columns">
              {/* Tiles */}
              {tournaments.length > 0 &&
                tournaments.map((tournament) => (
                  <div className="column col-3 col-xs-12" key={tournament.id}>
                    <TournamentTile tournament={tournament}></TournamentTile>
                  </div>
                ))}
              {tournaments.length === 0 && (
                <div className="column col-12">
                  <h6 className="skp-text-primary">No tournaments yet!</h6>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* END PAGE TOP */}
      </div>
    </Scrollbars>
  );
};

export default GameSingle;
