import { Scrollbars } from "react-custom-scrollbars";
import MyPendingTeamsTab from "./TeamTabs/MyPendingTeamsTab";
import MyTeamsTab from "./TeamTabs/MyTeamsTab";
import { useTranslation } from "react-i18next";

const MyTeams = () => {
  const { t } = useTranslation()
  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-full-page">
          <div className="columns">
           

            <div className="column col-12 col-xs-12">
              <div className="container">
                <div className="columns">
                  <div className="column col-12 text-center">
                    <h4 className="skp-heading skp-primary-pink">{t('tabs.myTeams')}</h4>
                  </div>
                </div>

                <MyTeamsTab />
              </div>
            </div>

            <div className="space-divider-40"></div>

            <div className="column col-12 col-xs-12">
              <div className="container">
                <div className="columns">
                  <div className="column col-12 text-center">
                    <h4 className="skp-heading skp-primary-blue">
                      {t('user.config.pendingInvitations')}
                    </h4>
                  </div>
                </div>

                <MyPendingTeamsTab />
              </div>
            </div>
          </div>

          <div className="space-divider-40"></div>
        </div>
      </Scrollbars>
    </>
  );
};

export default MyTeams;
