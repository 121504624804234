import ProfileApex from "../../../../../UiLibrary/ProfileApex";
import Moment from "moment";
import ReactTimeAgo from "react-time-ago";
import ITournament from "../../../../../Interfaces/Tournament";
import ITournamentApexRespawnMatchPlayerResults from "../../../../../Interfaces/TournamentApexRespawnMatchPlayerResults";
import ITournamentApexRespawnMatch from "../../../../../Interfaces/TournamentApexRespawnMatch";

type Props = {
  match: ITournamentApexRespawnMatch
  tournament: ITournament
}

const groupArrayOfObjects = (list:Array<ITournamentApexRespawnMatchPlayerResults>, key:string):{[key:string]: Array<ITournamentApexRespawnMatchPlayerResults>} => {
  return list.reduce(function (rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const ApexMatchDetailModal = (props:Props) => {
  const { match, tournament } = props;
  const teams = groupArrayOfObjects(match?.player_results, "teamNum");
  
  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12 text-center">
          <span className=" skp-text-small skp-text-light">
            <ReactTimeAgo date={new Date(match.match_start * 1000)} />
            {" - "}
            {Moment(match.match_start * 1000).format(
              "MMM DD YYYY, hh:mm A"
            )}{" "}
          </span>
        </div>
        <div className="space-divider-20"></div>
        <div className="column col-12 text-center-xs mb-2">
          <h5 className="skp-text-primary">Match Leaderboard</h5>
        </div>

        <div className="column col-12 mt-2">
          <div className="columns skp-text-light">
            <div className="column col-2 tooltip" data-tooltip="Placement">
              #
            </div>
            <div className="column col-3">Team n.</div>
            <div className="column col-6">Team Name</div>
            <div className="column col-1"></div>
          </div>
          <div className="space-divider-20"></div>
          {teams &&
            Object.keys(teams)
              .sort(
                (a, b) => teams[a][0].teamPlacement - teams[b][0].teamPlacement
              )
              .map((team:string, index) => (
                <div className="skp-card skp-card-hover p-2">
                  <div
                    className="accordion mt-2"
                    key={`placement-${teams[team][0]?.teamPlacement}${index}`}
                  >
                    <input
                      type="checkbox"
                      id={`match-${`placement-${teams[team][0]?.teamPlacement}`}${index}`}
                      name="accordion-checkbox"
                      hidden
                    />
                    <label
                      className="accordion-header"
                      htmlFor={`match-${`placement-${teams[team][0]?.teamPlacement}`}${index}`}
                    >
                      <div className="columns skp-text-primary">
                        <div className="column col-2">
                          {teams[team][0]?.teamPlacement}
                        </div>
                        <div className="column col-3">TEAM {Number(team) - 1}</div>
                        <div className="column col-6">
                          {teams[team][0]?.teamName}
                        </div>
                        <div className="column col-1 skp-text-primary text-right">
                          <i className="las la-angle-right"></i>
                        </div>
                      </div>
                    </label>
                    <div className="accordion-body">
                      <>
                        {teams[team]?.map((player) => (
                          <div
                            className="column col-12"
                            key={player.teamPlacement}
                          >
                            <div className="card skp-card mt-2">
                              <div className="card-body skp-text-default skp-text-primary">
                                <div className="columns">
                                  <div className="column col-2 col-xs-12">
                                    <div
                                      className={`apex-character-image character-${player.characterName}`}
                                    ></div>
                                    <div className="text-center skp-text-label text-uppercase mt-2">
                                      {player.characterName}
                                    </div>
                                  </div>
                                  <div className="column col-4 col-xs-12 text-center-xs">
                                    <ProfileApex
                                      apexUsername={player.playerName}
                                      tournament={tournament}
                                    />
                                    <div className="space-divider hide-xs"></div>
                                    <div className="card-subtitle skp-text-light skp-text-label mt-2">
                                      Platform: {player.hardware}
                                    </div>
                                  </div>
                                  <div className="column col-3 col-xs-12 text-left text-center-xs mt-2">
                                    <div className="skp-text-primary">
                                      KILLS: {player.kills}
                                    </div>
                                    <div className="skp-text-primary">
                                      HITS: {player.kills}
                                    </div>
                                    <div className="skp-text-primary">
                                      SHOTS: {player.shots}
                                    </div>
                                    <div className="skp-text-primary">
                                      HEADSHOTS: {player.headshots}
                                    </div>
                                  </div>
                                  <div className="column col-3 col-xs-12 text-left text-center-xs">
                                    <div className="skp-text-primary">
                                      DAMAGE: {player.damageDealt}
                                    </div>
                                    <div className="skp-text-primary">
                                      ASSISTS: {player.assists}
                                    </div>
                                    <div className="skp-text-primary">
                                      KNOCKDOWNS: {player.knockdowns}
                                    </div>
                                    <div className="skp-text-primary">
                                      SURVIVAL TIME: {player.survivalTime}s
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer"></div>
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default ApexMatchDetailModal;
