import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import TournamentService from "../../../services/TournamentService";
import ITournament from "../../Interfaces/Tournament";
import IUserDetails from "../../Interfaces/UserDetails";
import TournamentImageBanner from "../TournamentImageBanner";
import UserProfile from "../UserProfile";
import Match from "./Match";
import MatchService from "../../../services/MatchService";
import { useTranslation } from "react-i18next";

type Props = {
  tournamentId: string
  userInfo: IUserDetails
  matchId: string
}

const TournamentMatch = (props:Props) => {
  const { tournamentId, userInfo, matchId } = props;
  const { t } = useTranslation()
  const [tournament, setTournament] = useState<ITournament>();

  useEffect(() => {
    TournamentService.getCacheTournament(tournamentId)
      .then((Rtournament) => {
        setTournament(Rtournament);
      })
      .catch((err) => {
        if(err.response?.data?.statusCode === 404) {
          /*
           * Se ho 404 vuol dire che il torneo non esiste o è stato cancellato,
           * quindi lo rimuovo dal player
           */
          MatchService.deleteMatch(
            matchId
          ).catch((err) => {
            console.log("cannot deleted " + matchId)
          })
        }
      });
    return () => {
      setTournament(undefined);
    };
  }, [tournamentId]);

  return (
    <>
        <div className="tournament-matches skp-card">
          <div className=""></div>
          <div
            className="flex-centered skp-text-light p-2 skp-text-default"
            style={{ justifyContent: "left" }}
          >
            <UserProfile userId={userInfo?.id}></UserProfile>{" "}
            <span className="m-2">{t('user.config.playedTournament')}</span>
            <Link
              to={`/tournaments/${tournament?.id}`}
              className="skp-link-primary"
            >
              {tournament?.name}
            </Link>
            <span className="m-2">
              {tournament && (
                <ReactTimeAgo date={Date.parse(tournament?.start_tournament)} />
              )}
            </span>
          </div>

          {tournament && <div className="tournament-matches-match-container">
            <TournamentImageBanner tournament={tournament} />
            <Match
              matchId={matchId}
              showStatus={true}
              tournament={tournament}
            />
          </div>}
        </div>
    </>
  );
};

export default TournamentMatch;
