import { Link } from "react-router-dom"
import Marquee from 'react-text-marquee';
import IUserDetails from "../Interfaces/UserDetails"
import { useContext, useState } from "react";
import { UserContext } from "../Context/UserContext";
import SocialService from "../../services/SocialService";


type Props = {
    tileUser: IUserDetails,
    isFollow: boolean
}
  
const UserTile = ({tileUser, isFollow}:Props) => {
    const { user } = useContext(UserContext)
    const [error, setError] = useState(false)
    const [isFollowed, setIsFollowed] = useState(isFollow)
    const initials = tileUser?.username?.charAt(0);

    const followUnfollow = () => {
        if( user ) {
            SocialService.followUser(user?.id!, tileUser.id!).then((response) => {
                console.log(response)
                setIsFollowed(!isFollowed)
            })
        }
    }

    return (
        <div className="team-tile">
            <Link to={`/users/${tileUser.id}/overview`}>
                <div className="team-tile-image">
                    {(!tileUser?.profile_picture || error) && (
                        <span className="initials flex-centered text-uppercase">
                            {!initials ? <i className="las la-user"></i> : initials}
                        </span>
                    )}
                    {tileUser?.profile_picture && !error && (
                        <img
                            alt={tileUser.username}
                            src={tileUser?.profile_picture}
                            onError={() => setError(true)}
                        />
                    )}
                </div>
            </Link>
            <div className="columns">
                <div className="column col-12 text-center">
                    <Link to={`/users/${tileUser.id}/overview`}>
                        <span className="skp-text-default skp-text-primary">
                            <Marquee text={tileUser.username} />
                        </span>
                    </Link>
                </div>

                <div className="column col-12 text-center">
                    {/*team.verified && (
                        <span className="verified-icon tooltip" data-tooltip="Verified">
                            <i className="la la-check-circle"></i>
                        </span>
                    )*/}
                    <span style={{cursor: "pointer"}}>
                        <span className="tooltip tooltip-bottom" data-tooltip={!isFollowed ? "Follow User" : "Unfollow User"}>
                            <i className={`${isFollowed ? "skp-icon-like_full" : "skp-icon-like"} skp-text-quinternary`} onClick={followUnfollow}></i>
                        </span>
                    </span>
                    
                </div>  
            </div>
        </div>
    )
}

export default UserTile