import { useEffect, useState } from "react";
import Marquee from "react-text-marquee";
import UserService from "../../services/UserService";
import ITournamentYuniteTeam from "../Interfaces/TournamentYuniteTeam";

type Props = {
  teams: ITournamentYuniteTeam[]
  playerId: string
}

const YuniteConfirmedLabel = (props:Props) => {
  const { teams, playerId } = props;
  const [epicId, setEpicId] = useState<string>();
  const [ loading, setLoading ] = useState(false);

  const confirmed = teams?.find((team) => team.players.find((player) => player.epicId === epicId));
  const currentPlayer = confirmed?.players.find((player) => player.epicId === epicId);

  useEffect(() => {
    setLoading(true)
    if( playerId && playerId?.length >= 24) {
    UserService.getUser(playerId).then((Ruser) => {
      const epicId = Ruser.epicId;
      setEpicId(epicId);
      setLoading(false)
    });
    }

    return(() => {
      setLoading(false);
      setEpicId(undefined);
    })
  }, [playerId]);

  

  return (
    <div>
      {loading && <span className="skp-chip skp-text-small">
          Loading...
        </span>}
        {!loading && confirmed && <span className="skp-chip status-completed skp-text-small">
          Confirmed
        </span>}
        {!loading && !confirmed && <span className="skp-chip status-awaiting skp-text-small">
          <Marquee text="Awaiting Confirmation"></Marquee>
          
        </span>}
    </div>
  );
};

export default YuniteConfirmedLabel;
