import React, { useState } from 'react'
import FileBase64 from "react-file-base64";
import { toast } from 'react-toastify';
import IFileBase64Image from '../../Interfaces/FileBase64Image';
import './upload-image-box.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  sizeLimit?: number
  onUpload: (image: IFileBase64Image) => void
  onRemovePreview: () => void
}

const UploadImageBox = (props:Props) => {
    const {sizeLimit} = props;
    const { t } = useTranslation()
    const [imagePreview, setImagePreview] = useState<IFileBase64Image>();
    const maxLimit = sizeLimit || 20971520;

    const getImagePreview = (image:IFileBase64Image) => {
        if (image.file.size > maxLimit) {
          toast.error(t('toast.imageTooBig', {limit:maxLimit / 1024 / 1024}));
          return;
        }
        setImagePreview(image);
        props.onUpload(image); 
    }

    const removeImagePreview = (e:any) => {
      e.preventDefault();
      setImagePreview(undefined);
      props.onRemovePreview();
    };

    return (
      <div className="upload-image-box">
        <label className="file-base64-label-wrapper">
          <FileBase64 multiple={false} onDone={getImagePreview.bind(this)} />
          <div className="file-upload-text skp-text-light text-center">
            <div className="space-divider-20"></div>
            <h2 style={{margin: 0}}>
              <i className="las la-cloud-upload-alt"></i>
            </h2>
            <span className="skp-text-xxsmall">{t('messages.dropFile')}</span>
          </div>

          {imagePreview?.file && <div className="file-image-preview">
            <img alt="thumb-preview" src={imagePreview?.base64} />
            <span
              className="reset-image skp-text-primary"
              onClick={removeImagePreview}
            >
              <i className="las la-times-circle"></i>
            </span>
          </div>}
        </label>
      </div>
    );
}

export default UploadImageBox
