import { useState } from "react";
import ILobbyGame from "../../../../Interfaces/LobbyGame";
import ITournament from "../../../../Interfaces/Tournament";
import ITournamentLobby from "../../../../Interfaces/TournamentLobby";
import Moment from "moment";

type Props = {
    tournament: ITournament
    game: ILobbyGame
    lobby: ITournamentLobby
}
  
const LobbyGameDetailModal = (props:Props) => {
    const { tournament, game, lobby } = props;
    const [leaderboard, setLeaderboard] = useState(game?.leaderboards)
    const [leaderboardError, setShowLeaderboardError] = useState();
    const [loading, setLoading] = useState(false);

    return (
        <div className="container">
          {game && (
            <div className="columns skp-text-light">
                <div className="column col-6">
                <div className="column col-12 skp-text-small">Room id:</div>
                <div className="column col-12">
                    <h6 className="skp-text-primary">{game && game.room_id ? game.room_id : ""}</h6>
                </div>
                <div className="column col-12 skp-text-small">Room name:</div>
                <div className="column col-12">
                    <h6 className="skp-text-primary">{game.room_name}</h6>
                </div>
                </div>
                <div className="column col-6">
                <div className="column col-12 skp-text-small">Start Date:</div>
                <div className="column col-12">
                    <h6 className="skp-text-primary">{Moment(game.start_game).format("MMM DD, hh:mm A")}{" "}</h6>
                </div>
                <div className="column col-12 skp-text-small">End Date:</div>
                <div className="column col-12">
                    <h6 className="skp-text-primary">{Moment(game.end_game).format("MMM DD, hh:mm A")}{" "}</h6>
                </div>
                </div>
                <div className="column col-6">
                {game.map_name && game.map_name !== "" &&(
                    <>
                    <div className="column col-12 skp-text-small">Map:</div>
                    <div className="column col-12">
                        <h6 className="skp-text-primary">{game.map_name}</h6>
                    </div>
                    </>
                )}  
                </div>
            </div>
        )}
            <div className="space-divider-20"></div>
                {/* Leaderboard */}
                <div className="columns">
                    <div className="column col-12 col-xs-12 skp-text-light">
                    <h5 className="skp-text-primary">Leaderboard</h5>
                    {loading && (
                        <>
                        <div className="column col-12 mt-2 mb-2">
                            <div className="loading"></div>
                        </div>
                        </>
                    )}
                    {leaderboardError && !loading && (
                        <>
                        <div className="columns">
                            <div className="column col-12 mt-2 mb-2">
                            <h6 className="skp-text-light">{leaderboardError}</h6>
                            <div className="space-divider-20"></div>
                            </div>
                        </div>
                        </>
                    )}
        
                    {!leaderboardError && !loading && (
                        <>
                        <div className="columns m-2">
                            <div className="column col-1 col-xs-2 mb-2">#</div>
                            <div className="column col-2 mb-2 hide-xs">Team n.</div>
                            <div className="column col-4 col-xs-6 mb-2">Team name</div>
                            <div
                                className="column col-1 mb-2 tooltip text-right hide-xs"
                                data-tooltip="Kills"
                            >
                                Kill
                            </div>
                            <div
                                className="column col-1 mb-2 tooltip text-right hide-xs"
                                data-tooltip="Placement Points"
                            >
                                PP
                            </div>
                            <div
                                className="column col-2 mb-2 tooltip text-right"
                                data-tooltip="Score"
                            >
                                Score
                            </div>
                        </div>
                        <div className="columns skp-text-primary m-2">
                            {leaderboard &&
                            leaderboard
                                .sort((a, b) => a.placement - b.placement)
                                .map((team, index) => (
                                <div className="columns col-12" key={index}>
                                    <div className="column col-1 col-xs-2 mb-2">
                                        {team.placement}
                                    </div>
                                    <div className="column col-2 mb-2 hide-xs">
                                        {Number(team.teamNum)}
                                    </div>
                                    <div className="column col-5 col-xs-6 mb-2">
                                        {team.teamName}
                                    </div>
                                    <div className="column col-1 mb-2 text-right hide-xs">
                                        {team.eliminationScore}
                                    </div>
                                    <div className="column col-1 mb-2 text-right hide-xs">
                                        {team.placementScore}
                                    </div>
                                    <div className="column col-2 mb-2 text-right">
                                        {team.placementScore + team.eliminationScore}
                                    </div>
                                </div>
                                ))}
                        </div>
                        </>
                    )}
                    </div>
                </div>
            </div>
            
      );
    };
    
export default LobbyGameDetailModal;