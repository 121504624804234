import { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import ChatPageSidebar from "./ChatPageSidebar";
import ChatPageConversation from "./ChatPageConversation";
import ChatProvider from "../../Context/ChatContext";
import ChatWidget from "../../UiLibrary/ChatWidget/ChatWidget";

const ChatsPage = () => {
    const { user, setUser } = useContext(UserContext);
          
    return (
        <ChatProvider>
            <div className="skp-page-with-sidebar">
                {/* SIDEBAR: Lista delle chat aperte */}
                <ChatPageSidebar></ChatPageSidebar>
                {/* MAIN: Qui ci va la pagina della conversazione */}
                <ChatPageConversation></ChatPageConversation>
                {/* CHATBOT <ChatWidget></ChatWidget>*/}
            </div>
        </ChatProvider>
    );
}

export default ChatsPage