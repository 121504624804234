import { useNavigate } from "react-router-dom";
import KeyCloackService from "../../../services/KeyCloackService"
import LocalStorageService from "../../../services/LocalStorageService";
import { useEffect } from "react";
const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        LocalStorageService.removeKey("skp-token")
        LocalStorageService.removeKey("skp-token-refresh")
        LocalStorageService.removeKey("skp-token-sub")
        
        if(KeyCloackService.isLoggedIn()) {
            KeyCloackService.doLogout();
        } else {
            navigate("/home");
        }
    },[])
    
    
    return (
        <>
        <div className="space-divider"></div>
        <div className="flex-centered">
            
            <div className="loading"></div>
        </div>
        </>
        
    )
}

export default Logout