import React from 'react'
import ITeam from '../../../Interfaces/Team';
import ResponsivePlayer from '../../../UiLibrary/ResponsivePlayer';

type Props = {
  team: ITeam
}

const OverviewTab = (props:Props) => {
    const {team} = props;
    
    return (
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <div className="columns">
          <div className="column col-7 col-xs-12">
            <h5 className="skp-text-primary">Information</h5>
            <div
              className="skp-text-light p-1"
              dangerouslySetInnerHTML={{
                __html: team?.description || "",
              }}
            ></div>
          </div>
          <div className='space-divider-20 show-xs'></div>
          <div className="column col-5 col-xs-12">
            <h5 className="skp-text-primary">Contact</h5>
            <div className="skp-text-light">Email: {team?.contact_mail}</div>
            <div className="space-divider-20"></div>
            {team?.location && (
              <>
                <h5 className="skp-text-primary">Location</h5>
                <div className="skp-text-light">{team?.location}</div>
                <div className="space-divider-20"></div>
              </>
            )}
            {team?.website && (
              <>
                <h5 className="skp-text-primary">Links</h5>
                <div className="skp-text-light">
                  <a className="skp-link-primary" href={team?.website}>
                    Website
                  </a>
                </div>
              </>
            )}
            <div className="space-divider-20"></div>
            {team?.video_url && (
              <>
                <h5 className="skp-text-primary">Team Video</h5>
                <ResponsivePlayer url={team?.video_url} />
              </>
            )}
          </div>
        </div>
      </div>
    );
}

export default OverviewTab
