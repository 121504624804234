import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./NewsCard.css"; 
import INews from "../../../../Interfaces/News";
import { UserContext } from "../../../../Context/UserContext";
import { Link } from "react-router-dom";
import CreateNewsTab from "./CreateNewsTab";
import Modal from "../../../../UiLibrary/Modal";

interface Props {
  news: INews,
  loading: boolean;
  onSave: (id: string, updatedNews: INews) => void;
  onDelete: (id: string) => void;
}

const NewsCard = ({ news, loading, onSave, onDelete }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isAdmin = true
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    onDelete(news.id!);
    setShowDeleteModal(false); // Chiudi la modale dopo la cancellazione
  };

  const handleSave = (updatedNews: INews) => {
    onSave(news.id!, updatedNews);
    setIsEditing(false);  // Chiude la modalità di editing dopo il salvataggio
  };

  const truncateText = (text, maxLength) => {
    const trimmedString = text.length > maxLength ? text.substr(0, maxLength - 3) + "..." : text;
    return trimmedString;
  };

  return (
    <div className="news-card">
      <img src={news.imageUrl} alt={news.title} className="news-image" />
      <div className="news-content">
        <h4 className="news-title skp-text-primary">{news.title}</h4>
        <div
            className="news-text skp-text-light"
            dangerouslySetInnerHTML={{
              __html: truncateText(news.text, 200),
            }}
        ></div>
        
        <div className="columns news-align-date">
          <Link to={`/news/${news.id}`} className="news-link">
            {t("common.readMore")}
          </Link>
          
          <p className="skp-text-light">
            {new Date(news.date).toLocaleDateString()}
          </p>
        </div>
        {isAdmin && (
          <div className="edit-delete-icons">
            <i className="skp-icon-settings skp-text-light edit-icon" onClick={handleEditClick}></i>
            <i className="las la-trash skp-text-danger delete-icon" onClick={handleDeleteClick}></i>
          </div>
        )}

      </div>

      <Modal
        show={isEditing}
        onConfirm={() => setIsEditing(false)}
        title={t('news.editTitle')}
        onClose={() => setIsEditing(!isEditing)}
      >
        <CreateNewsTab
          loading={loading}
          news={news}  // Passa i dettagli della news corrente
          onSave={handleSave}
        />
      </Modal>

      {/* Modale per la conferma di eliminazione */}
      <Modal
        show={showDeleteModal}
        title={t('news.confirmDeleteTitle')}
        onClose={() => setShowDeleteModal(false)}
        size="small"
        confirmation={t('common.delete')}
        onConfirm={confirmDelete}
        loading={loading}
      >
        <>
          <div className="space-divider-20"></div>
          <div className="skp-text-primary text-center">
            {t('news.confirmDeleteMessage')}{" "}
            <span className="skp-text-accent">{news.title}</span>?
          </div>
          <div className="space-divider-20"></div>
        </>
      </Modal>

    </div>
  );
};

export default NewsCard;
