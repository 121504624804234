import { useEffect, useState } from "react";
import YuniteTournamentService from "../../../../../services/YuniteTournamentService";
import YunitePlayer from "../../../../UiLibrary/YunitePlayer";
import InfiniteScroll from "react-infinite-scroll-component";
import ITournament from "../../../../Interfaces/Tournament";
import PlayPlayUser from "./UserComponents/PlayPlayUser";

type Props = {
  tournament: ITournament;
  matchId: string;
};
const YuniteMatchLeaderboard = (props: Props) => {
  const { tournament, matchId } = props;
  const [matchSession, setMatchSession] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    const id = tournament?.yuniteId ? tournament?.yuniteId : tournament?.id;
    YuniteTournamentService.getCacheMatch(id, matchId)
      .then((response) => {
        setMatchSession(response);
        const index = response.length >= 20 ? 20 : response.length;
        let items:any = [];
        for (let i = 0; i < index; i++) {
          items.push(response[i]);
        }
        setPaginatedItems(items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, [matchId, tournament]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginatedItems([
        ...paginatedItems,
        matchSession[paginatedItems.length],
      ]);
    }, 100);
  };

  return (
    <>
      <div className="container">
        <div className="columns">
          <div className="column col-12">
            <div className="container play-table-view">
              <div className="columns pt-2 pb-2 column-gray">
                <div className="column col-1 flex-centered">#</div>
                <div className="column col-9 col-xs-10">Team</div>
                <div className="column col-1 flex-centered hide-xs">Wins</div>
                <div className="column col-1 flex-centered">Score</div>
              </div>

              {!loading && (error || paginatedItems.length === 0) && (
                <div className="container">
                  <div className="columns skp-text-light text-center">
                    <div className="space-divider"></div>
                    <div className="column col-12">
                      <h1>
                        <i className="las la-trophy"></i>
                      </h1>
                    </div>
                    <div className="column col-12">
                      <h5>No Results</h5>
                    </div>
                    <div className="column col-12">
                      The leaderboard is empty
                    </div>
                  </div>
                </div>
              )}
              {loading && <div className="loading"></div>}

              {!loading && (
                <InfiniteScroll
                  dataLength={paginatedItems.length}
                  scrollableTarget="hover-scroll"
                  next={fetchMoreData}
                  hasMore={matchSession.length > paginatedItems.length}
                  style={{ overflow: "hidden" }}
                  loader={
                    <div>
                      <div className="loading"></div>
                    </div>
                  }
                  scrollThreshold={"100px"}
                  endMessage={
                    <>
                      {paginatedItems.length >= 20 && (
                        <>
                          <p
                            className="text-center skp-text-light m-2"
                            style={{ textAlign: "center" }}
                          >
                            ...
                          </p>
                        </>
                      )}
                    </>
                  }
                >
                  {paginatedItems &&
                    paginatedItems.map((row, index) => (
                      <div
                        className="columns skp-text-primary bordered-list p-2"
                        key={`row-${index}`}
                      >
                        <div className="column col-1 flex-centered">
                          {row?.placement}
                        </div>
                        <div className="column col-9 col-xs-10 leaderboard-user">
                          <div className="columns">
                            <PlayPlayUser
                              epicId={row?.team?.players[0].epicId}
                              hideLabel
                            />
                          </div>
                        </div>
                        <div className="column col-1 flex-centered hide-xs">
                          {row?.kills}
                        </div>
                        <div className="column col-1 flex-centered">
                          {row?.score}
                        </div>
                      </div>
                    ))}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YuniteMatchLeaderboard;
