import React, { useContext, useEffect, useState } from "react";
import TournamentFilters from "../../TournamentFilters/TournamentFilters";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import TournamentService from "../../../../../services/TournamentService";
import TournamentTile from "../../../../UiLibrary/TournamentTile";
import ITournament from "../../../../Interfaces/Tournament";
import { UserContext } from "../../../../Context/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
  userId: string
  path: string
}

const CurrentlyPlayingTab = (props:Props) => {
  const { userId, path } = props;
  const { t } = useTranslation()
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [tournaments, setTournamentList] = useState<Array<ITournament>>([]);
  const navigate = useNavigate();
  const pathName = `/users/${userId}/${path}`;

  const runQuery = (params:any) => {
    navigate({
      pathname: pathName,
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    let queryParam = qs.parse(location.search);
    queryParam.members = userId;
    const isTester = user && user?.roles && user.roles.includes("Tester") ? true : false
    TournamentService.getTournaments(queryParam, isTester, user?.id).then((tournaments) => {
      setTournamentList(tournaments);
    });
  }, [location, userId]);

  return (
    <>
      <div className="space-divider-20"></div>
      {/* Tournament Filters */}
      <TournamentFilters onRunQuery={runQuery} singleSelect={true} />
      <div className="space-divider-20"></div>
      <div className="columns">
        {/* Tiles */}
        {tournaments.length > 0 &&
          tournaments.map((tournament) => (
            <div className="column col-3 col-xs-12" key={tournament.id}>
              <TournamentTile tournament={tournament}></TournamentTile>
            </div>
          ))}
        {tournaments.length === 0 && (
          <div className="column col-12">
            <h6 className="skp-text-primary">{t('home.noTournaments')}</h6>
          </div>
        )}
      </div>
      <div className="space-divider"></div>
    </>
  );
};

export default CurrentlyPlayingTab;
