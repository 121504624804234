import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import TournamentService from "../../../../services/TournamentService";
import ITournament from "../../../Interfaces/Tournament";
import "./play-play.scss";
import GAMES from "../../../../constants-games";
import { UserContext } from "../../../Context/UserContext";
import UserService from "../../../../services/UserService";
import ErrorLabel from "../../../Forms/ErrorLabel";
import PlayPlayTeamUser from "./Components/UserComponents/PlayPlayTeamUser";
import Scrollbars from "react-custom-scrollbars";
import Modal from "./Components/Modal";
import LoadingButton from "../../../UiLibrary/LoadingButton/LoadingButton";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface ITeamMember {
  username: string | undefined;
  uid: string | undefined;
}

const PlayPlayTeamsRegistration = () => {
  const { user, setUser } = useContext(UserContext);
  const [tournament, setTournament] = useState<ITournament>();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  let query = useQuery();
  let { tournamentId } = useParams<{ tournamentId: string }>();
  const teamNameInput = useRef<HTMLInputElement>(null);
  const usernameInput = useRef<HTMLInputElement>(null);
  const uidInput = useRef<HTMLInputElement>(null);
  const [members, setMembers] = useState<Array<ITeamMember>>([]);

  const authKey = query.get("authKey");
  const [availableSlotsNumber, setAvailableSlotsNumber] = useState(0)

  const [showModal, setShowModal] = useState<{
    show: boolean;
    index?: number | undefined;
  }>({show:false, index:0})

  /* 
   * Recupero il torneo in base al tournamentId passato a parametro
   */
  const loadTournament = useCallback(
    (tournament?: ITournament) => {
      if (tournament) {
        setTournament(tournament);
      } else {
        setLoading(true);
        TournamentService.getTournament(tournamentId!).then((tournament) => {
          setTournament(tournament);
          setAvailableSlotsNumber(tournament?.reserves
            ? Number(tournament?.teamSize) + tournament?.reserves
            : Number(tournament?.teamSize) * 2);
          setLoading(false);
        });
      }
    },
    [tournamentId]
  );

  useEffect(() => {
    loadTournament();

    if(authKey){
      UserService.getUserByAuthKey(authKey).then(response => {
        setUser(response)
      })
    }
    const userMember = {
                        username: user?.username,
                        uid: "uid"
                      }
    setMembers([ // with a new array
      ...members, userMember ])

    return () => {
      setUser(null)
    };
  }, [loadTournament, setUser, authKey]);

  const addMember = (index:number, username:string, uid:string ) => {
    const userMember = {
      username: username,
      uid: uid
    }

    setMembers([...members, userMember])
  }

  const nameBlurData = () => {
    if (teamNameInput?.current?.value.trim() === "") {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  };

  
  return (
    <>
      {(tournament?.game && GAMES[tournament?.game]?.CONTROL_TYPE === 'lobby') && (
        <div className="container">
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-users"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>Create Team</h5>
            </div>
            <div className="space-divider-20"></div>
            <div className="column col-12">
            <div className="columns">
							<div className="column col-4"></div>
              <div className="column col-4 col-xs-12">
                <div
                  className={`skp-form-control ${
                    !isValid ? "skp-form-control-error" : ""
                  }`}
                >
                  <h6 className="skp-text-primary">
                    Team Name
                  </h6>
                  <input
                    id="teamName"
                    name="teamName"
                    ref={teamNameInput}
                    placeholder="Choose a team name"
                    onBlur={nameBlurData}
                    onChange={nameBlurData}
                    className="skp-pp-test"
                  />
                  {!isValid && <ErrorLabel>Please add a team name</ErrorLabel>}
                  <div className="space-divider-20"></div>

                  <h6 className="skp-text-primary">
                    {tournament?.teamSize}vs{tournament?.teamSize} Lineup
                  </h6>

                  <Scrollbars style={{ width: "100%",height: "calc(100vh - 350px)"}}>
                    <dl className="columns bordered-list">
                      {Array(availableSlotsNumber).fill(true).map((elementInArray, index) => (
                        <div className="column col-12" key={index}>
                          {members[index] && (
                            <dd
                              key={index}
                              className={"column col-12 hideHover"}
                            >
                              <div className="columns">
                                <div className="column col-11">
                                  <PlayPlayTeamUser
                                    username={members[index].username}
                                  >
                                    {index === 0 && (
                                      <span className="skp-chip status-captain skp-text-small">
                                        Leader
                                      </span>
                                    )}
                                  </PlayPlayTeamUser>
                                </div>
                              </div>
                            </dd>
                          )}
                          {!members[index] && (
                            <dd
                              key={index}
                              className={"column col-12"}
                              onClick={()=>setShowModal({show:true, index:index})}
                            >
                              <div className="columns">
                                <div className="column col-11">
                                  <PlayPlayTeamUser>
                                    <span className="skp-text-small skp-text-light">
                                      Click to insert a member!
                                    </span>           
                                  </PlayPlayTeamUser>
                                </div>
                              </div>
                            </dd>
                          )}
                          {index === (Number(tournament?.teamSize) - 1) && (
                            <dd className="hideHover">
                              <h6 className="skp-text-primary">
                                {tournament?.teamSize}vs{tournament?.teamSize} Reserve Team
                              </h6>
                            </dd>
                          )}
                        </div>
                      ))}
                    </dl>
                  </Scrollbars>
                </div>
              </div>  
            </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showModal.show}
        size="small"
        onClose={() => setShowModal({ show: false })}
        title={"Add Player"}
      >
        <>
          <div className="columns">
            <span className="column col-4 skp-text-small">
              Player Name:
            </span>
            <input
              id="playeName"
              name="playeName"
              ref={usernameInput}
              placeholder="Insert player nickname"
              onBlur={nameBlurData}
              onChange={nameBlurData}
              className="column col-7 skp-text-small skp-text-light"
            />
          </div>
          <div className="space-divider-10"></div>
          <div className="columns">
            <span className="column col-4 skp-text-small">
              PUBG MOBILE Id:
            </span>
            <input
              id="playerUID"
              name="playerUID"
              ref={uidInput}
              placeholder="Insert PUBG id"
              onBlur={nameBlurData}
              onChange={nameBlurData}
              className="column col-7 skp-text-small skp-text-light"
            />
          </div>
          <div className="space-divider-20"></div>
          <div className="container">
            <div className="columns">
              <div className="column col-12 text-right">
                <button
                  className="skp-btn skp-btn-primary"
                  disabled={loading}
                  onClick={()=>addMember(showModal.index!, usernameInput.current?.value!, teamNameInput.current?.value! )}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default PlayPlayTeamsRegistration;
