import React from 'react'
import ResponsivePlayer from '../../../UiLibrary/ResponsivePlayer';
import IBrand from '../../../Interfaces/Brand';
import { useTranslation } from 'react-i18next';
import SocialIcon from '../../../UiLibrary/SocialIcon';

type Props = {
    brand: IBrand
}

const OverviewTab = (props:Props) => {
    const {brand} = props;
    const { t } = useTranslation()
    
    return (
        <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 col-xs-12 p-2">
                <div className="columns">
                    <div className="skp-card column col-12 p-2">
                        <h4 className="skp-text-primary">{t('titles.information')}</h4>
                        <h5 className="skp-text-primary">{brand.name}</h5>
                        <div
                            className="skp-text-light p-1"
                            dangerouslySetInnerHTML={{
                                __html: brand?.description || "",
                            }}
                        ></div>
                    </div>
                </div>
            </div>
            <div className="column col-4 col-xs-12 p-2">
                <div className="columns">

                    <div className="skp-card column col-12 p-2">
                        {brand?.website && (
                            <>
                                <h5 className="skp-text-primary">{t('brands.create.website')}</h5>
                                <a
                                    href={brand?.website}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="skp-link-hover"
                                >
                                    {brand?.website}
                                </a>
                            </>
                        )}
                    </div>

                    <div className="skp-card column col-12 p-2">
                        <h5 className="skp-text-primary">{t('brands.config.contact')}</h5>
                        <div className="skp-text-light">
                            {t('brands.create.email')}: {" "}
                            <a
                                href={`mailto:${brand?.contactMail}`}
                                target="_blank"
                                rel="noreferrer"
                                className="skp-link-hover"
                            >
                                {brand?.contactMail}
                            </a>
                        </div>
                    </div>
                    
                    <div className="skp-card column col-12 p-2">
                        {brand?.location && (
                            <>
                                <h5 className="skp-text-primary">{t('brands.config.location')}</h5>
                                <div className="skp-text-light">{brand?.location}</div>
                            </>
                        )}
                    </div>

                    {brand?.socialNetworks && (
                        <div className="skp-card column col-12 p-2">
                        <h5 className="skp-text-primary">{t('user.config.social')}</h5>
                        {brand?.socialNetworks.map((account) => (
                            <a
                                href={account.url}
                                target="_blank"
                                rel="noreferrer"
                                className="skp-link-hover"
                            >
                            <h4
                                className="flex-centered"
                                style={{ justifyContent: "left" }}
                            >
                                <SocialIcon platform={account.platform} />
                                <span className="skp-text-small ml-1">
                                    {account.platform}
                                </span>
                            </h4>
                            </a>
                        ))}
                        </div>
                    )}
                    
        
                </div>
                <div className='space-divider-20 show-xs'></div>
            </div>
            <div className="column col-8 col-xs-12 text-center skp-text-light p-2">
                {brand?.videoUrl && (
                    <ResponsivePlayer url={brand?.videoUrl} />
                )}
            </div>
        </div>
    );
}

export default OverviewTab
