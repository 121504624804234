import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import { useRef } from "react";
import * as Yup from "yup";
import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import ErrorLabel from "../../Forms/ErrorLabel";

const validationSchema = Yup.object({
  playerId: Yup.string().required(),
  score: Yup.number().required("Required"),
  wins: Yup.number().required("Required")
});

interface FormModel {
  playerId: string;    
  score: number;
  wins: number;
}

const LeaderboardRow = (props:any) => {
  const { player, tournament, isEdit, reportScore } = props;
  
  const initialValues: FormModel = {
    playerId: player.playerId,
    score: player.score,
    wins: player.wins
  };

  const formikRef = useRef<FormikProps<FormModel>>(null);

  const onSubmit = (values: FormModel) => {
    reportScore(formikRef.current?.values);
  };

  return (
    <>
      <div className="column col-4">
        <UserProfileWithDetailsModal
          userId={player.playerId}
          tournament={tournament}/>
      </div>
      <div className="column col-4 skp-text-primary">{player.teamName}</div>
      {isEdit && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={formikRef}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, isValid }) => (
            <>
              <div className="column col-2">
                <Form>
                  <div className={errors.score && touched.score
                      ? "skp-form-control skp-form-control-error"
                      : "skp-form-control"}>
                    <Field
                      className="text-right"
                      name="score"
                      id="score"
                      onKeyUp={(e:any) => {
                        if (isValid) 
                          onSubmit(e)
                      }}
                    />
                  </div>
                  <ErrorMessage name={`score`} component={ErrorLabel} />
                </Form>
              </div>
            
              <div className="column col-2">
                <Form>
                  <div className={errors.wins && touched.wins
                      ? "skp-form-control skp-form-control-error"
                      : "skp-form-control"}>
                    <Field
                      className="text-right skp-form-control"
                      name="wins"
                      id="wins"
                      onKeyUp={(e:any) => {
                        if (isValid) 
                          onSubmit(e)
                      }}
                    />
                    <ErrorMessage name={`wins`} component={ErrorLabel} />
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      )}
      {!isEdit && (
        <>
          <div className="column col-2 text-right skp-text-primary">{player.score}</div>
          <div className="column col-2 text-right skp-text-primary">{player.wins}</div>
        </>
      )}
    </>
  );
};

export default LeaderboardRow;
