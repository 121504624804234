import { useEffect, useState } from "react";
import MatchService from "../../../../services/MatchService";
import TournamentMatch from "../../../UiLibrary/MatchComponents/TournamentMatch";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import SocialIcon from "../../../UiLibrary/SocialIcon";
import ResponsivePlayer from "../../../UiLibrary/ResponsivePlayer";
import GameTile from "../../../UiLibrary/GameTile";
import GameAccountsModal from "../../../Modals/GameAccountsModal/GameAccountsModal";
import Modal from "../../../UiLibrary/Modal";
import IUserDetails from "../../../Interfaces/UserDetails";
import SocialNetwork from "../../../Interfaces/SocialNetwork";
import GAMES from "../../../../constants-games";
import { useTranslation } from "react-i18next";

type Props = {
  userInfo: IUserDetails | undefined;
  user: IUserDetails | null;
  reloadUser: () => void;
};

const OverviewTab = (props: Props) => {
  const { userInfo, user } = props;
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [paginatedItems, setPaginatedItems] = useState<Array<any>>([]);
  const [matches, setMatches] = useState([]);
  const [streams, setStreams] = useState<Array<SocialNetwork>>([]);
  const [showGameAccountModal, setShowGameAccountModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (userInfo?.id) {
      MatchService.getUserMatches(userInfo?.id)
        .then((response) => {
          const reversedMatches = response.data.data.matches.slice(0).reverse();
          setMatches(reversedMatches);
          let items:any = [];
          const index =
            response.data.data.matches.length >= 8
              ? 8
              : response.data.data.matches.length;
          for (let i = 0; i < index; i++) {
            items.push(reversedMatches[i]);
          }
          setPaginatedItems(items);
          setLoading(false);
        })
        .catch((error) => {
          setPaginatedItems([]);
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
    setStreams(
      userInfo?.social_networks
        ? userInfo?.social_networks.filter(
            (element: SocialNetwork) =>
              element.platform === "TWITCH" || element.platform === "YOUTUBE"
          )
        : []
    );
  }, [userInfo]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginatedItems([...paginatedItems, matches[paginatedItems.length]]);
    }, 100);
  };

  const closeGameAccountModal = () => {
    props.reloadUser();
    setShowGameAccountModal(false);
  };

  return (
    <div className="columns">
      <div className="space-divider-20"></div>
      <div className="column col-4 col-xs-12 p-2">
        <div className="columns">
          <div className="skp-card column col-12 p-2">
            <h4 className="skp-text-primary">{t('user.config.about')}</h4>
            <h5 className="skp-text-primary">{t('user.config.biography')}</h5>
            <p className="skp-text-light skp-text-default">
              {userInfo?.biography}
            </p>
          </div>

          <div className="skp-card column col-12 p-2">
            <h5 className="skp-text-primary d-inline-block">{t('user.config.gameAccounts')}</h5>
            {userInfo?.id === user?.id && (
              <span
                className="skp-link-hover skp-text-default ml-2"
                onClick={() => setShowGameAccountModal(true)}
              >
                {t('common.edit')} <i className="las la-pen"></i>
              </span>
            )}
            {userInfo?.gameAccounts?.map((account, index) => (
              <div className="columns" key={index}>
                <div className="column col-2 flex-centered">
                  <div style={{ width: 50 }}>
                    {account.game && (
                      <GameTile
                        game={GAMES[account.game]}
                        disableLink
                        hideName
                      />
                    )}
                  </div>
                </div>
                <div className="column col-10">
                  <div key={account + index.toString()} className="m-2">
                    {account.game && (
                      <h6 className="skp-text-light skp-text-label text-uppercase">
                        <span>{GAMES[account.game].NAME}</span>
                      </h6>
                    )}
                    <div className="skp-text-primary skp-text-large mb-2">
                      <span className="mr-2">{account?.username}</span>
                    </div>
                    <div
                      className="skp-text-primary skp-text-small flex-centered"
                      style={{ justifyContent: "left" }}
                    >
                      <i
                        className={`la-${account?.console} tooltip`}
                        style={{ fontSize: 16 }}
                        data-tooltip={account?.console}
                      ></i>
                      <span className="ml-1">{account?.console}</span>
                    </div>
                    {account.id && (
                      <>
                        <div className="m-2"></div>
                        <h6 className="skp-text-light skp-text-small">{t('user.config.id')}</h6>
                        <div className="skp-text-primary skp-text-default">
                          {account.id}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="skp-card column col-12 p-2">
            <h5 className="skp-text-primary d-inline-block">
              {t('user.config.yuniteVerification')}
            </h5>
            {userInfo?.epicId && (
              <div>
                <h6 className="skp-text-light skp-text-small">{t('user.config.epicId')}</h6>
                <div className="skp-text-primary skp-text-default">
                  {userInfo?.epicId}
                </div>
                <div className="space-divider-20"></div>
              </div>
            )}
            {userInfo?.discordId && (
              <div>
                <h6 className="skp-text-light skp-text-small">{t('user.config.discordId')}</h6>
                <div className="skp-text-primary skp-text-default">
                  {userInfo?.discordId}
                </div>
                <div className="space-divider-20"></div>
              </div>
            )}
          </div>
          {userInfo?.social_networks && (
            <div className="skp-card column col-12 p-2">
              <h5 className="skp-text-primary">{t('user.config.social')}</h5>
              {userInfo?.social_networks.map((account) => (
                <a
                  href={account.url}
                  target="_blank"
                  rel="noreferrer"
                  className="skp-link-hover"
                >
                  <h4
                    className="flex-centered"
                    style={{ justifyContent: "left" }}
                  >
                    <SocialIcon platform={account.platform} />
                    <span className="skp-text-small ml-1">
                      {account.platform}
                    </span>
                  </h4>
                </a>
              ))}
            </div>
          )}
          {streams?.length > 0 && (
          <div className="skp-card column col-12 p-2">
            <div className="space-divider-20"></div>
            <h5 className="skp-text-primary">{t('user.config.stream')}</h5>
            {streams.map((stream) => (
              <div className="mt-2 mb-2">
                <ResponsivePlayer url={stream.url} />
              </div>
            ))}
          </div>
        )}
        </div>
      </div>
      {/* END LEFT SIDE */}

      <div className="column col-8 col-xs-12 text-center skp-text-light">
        {loading && <div>{t('user.config.stream')}{t('user.config.loading')}</div>}
        <div className="space-divider-20"></div>
        {!loading && paginatedItems.length === 0 && (
          <>
            <h1>
              <i className="las la-dice-d6"></i>
            </h1>
            {userInfo?.username} {t('user.config.notPlayedGames')}
          </>
        )}
        {!loading && paginatedItems.length > 0 && (
          <InfiniteScroll
            dataLength={paginatedItems.length}
            scrollableTarget="scrollableDiv"
            next={fetchMoreData}
            hasMore={matches.length > paginatedItems.length}
            style={{ overflow: "hidden" }}
            loader={
              <div>
                <div className="loading"></div>
              </div>
            }
            scrollThreshold={"100px"}
            endMessage={
              <>
                {paginatedItems.length >= 10 && (
                  <>
                    <p
                      className="text-center skp-text-light m-2"
                      style={{ textAlign: "center" }}
                    >
                      {t('common.end')}
                    </p>
                  </>
                )}
              </>
            }
          >
            <div className="columns">
              {userInfo &&
                paginatedItems.map((item) => (
                  <div key={`match-${item.id}`} className="column col-12">
                    <TournamentMatch
                      tournamentId={item.tournamentId}
                      userInfo={userInfo}
                      matchId={item.id}
                    ></TournamentMatch>
                  </div>
                ))}
            </div>
          </InfiniteScroll>
        )}
      </div>

      <Modal
        title={t('user.config.editGameAccounts')}
        show={showGameAccountModal}
        onClose={closeGameAccountModal}
      >
        <GameAccountsModal />
      </Modal>
    </div>
  );
};

export default OverviewTab;
