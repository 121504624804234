
import TextEditor from "../../../../UiLibrary/RichTextEditor";

const TeamInfo = (props) => {
  const {loading, team } = props

  const updateTeam = (value) => {
    const data = {
      description: value,
    };
    props.onSave(data);
  };

  return (
    <div className="container">
        <div className="space-divider-20"></div>
      <div className="columns">
        <div className="column-12">
            <h5 className="skp-text-primary">Description</h5>
        </div>
        <div className="column col-12">
          <TextEditor
            onSave={updateTeam}
            initialValue={team?.description}
            isSaving={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamInfo;
