import * as yup from "yup";

interface IValidation {
  type: string;
  params: Array<any>
} 

interface IConfig {
  id: string
  validationType: string,
  validations: Array<IValidation>
}

export const createYupSchema = (schema:any, config:IConfig) => {
  const { id, validationType, validations = [] } = config;

  const yep = yup as any
  
  if (!yep[validationType]) {
    return schema;
  }

  let validator = yep[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    if (params[0].ref) {
      params[0] = yup.ref(params[0].ref);
    }
    if (params[2] === "odd") {
      params[2] = (value:number) => value % 2;
    }
    if (params[2] === "checkApex") {
      params[2] = (value:number, context:any) => {
        return value <= 60 / context.parent.teamSize ? true : context.createError({
          message: `Must be equal o less than ${60 / context.parent.teamSize}`,
          path: 'maxPartecipants', // Fieldname
        });
      };
    }
    validator = validator[type](...params);

  });
  schema[id] = validator;
  return schema;
};
