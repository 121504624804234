import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApexTournamentService from "../../../../../services/ApexTournamentService";
import { UserContext } from "../../../../Context/UserContext";
import IApexLobbyGameToken from "../../../../Interfaces/ApexLobbyGameToken";
import ITournament from "../../../../Interfaces/Tournament";
import ITournamentApexLobby from "../../../../Interfaces/TournamentApexLobby";
import Modal from "../../../../UiLibrary/Modal";
import CreateEditApexGameModal from "./Modals/CreateEditApexGameModal";
import ApexGameCard from "./Components/ApexGameCard";

type Props = {
  tournament: ITournament
  lobby: ITournamentApexLobby
  onReloadTournament: (tournament?:ITournament) => void
}

const ApexGameTab = ({ lobby, tournament, ...props }:Props) => {
  const {user} = useContext(UserContext)
  const [games, setGames] = useState<Array<IApexLobbyGameToken>>([]);
  const [loading, setLoading] = useState(false);

  let apexTokens = lobby.tokens;

  let initialValuesEmpty:IApexLobbyGameToken = {
    gameNumber: apexTokens ? apexTokens?.length + 1 : 1,
    api: "",
    admin: "",
    player: "",
    start_game: new Date(),
    end_game: new Date()
  };

  const [showAddGameModal, setShowAddGameModal] = useState<{show: boolean, initialValues?: IApexLobbyGameToken, title?: string}>({
    show: false,
    initialValues: initialValuesEmpty,
    title: "Add Game",
  });

  const isAdmin =
    tournament?.owner === user?.id ||
    tournament?.admins?.includes(user?.id!);
  const isMember = lobby?.teams && lobby.teams.some((team) => team.members.find((member) => member.userId === user?.id));


  useEffect(() => {
    setGames(apexTokens ? apexTokens : []);

    return () => {
      setGames([]);
    }
  }, [apexTokens]);

  const editGame = (e:any, index:number) => {
    e.stopPropagation();
    e.preventDefault();
    if(!apexTokens) {
      return 
    }

    setShowAddGameModal({
      show: true,
      initialValues: apexTokens[index],
      title: "Edit Game",
    });
  };

  const onRemoveGame = (e:any, index:number) => {
    setLoading(true);
    let apexTokensCopy:Array<IApexLobbyGameToken>;
    e.stopPropagation();
    e.preventDefault();
    const gameIndex = apexTokens?.findIndex(
      (element) => element.gameNumber === index
    );
    if (gameIndex && gameIndex > -1) {
      apexTokensCopy = [...apexTokens as Array<IApexLobbyGameToken>];
      apexTokensCopy.splice(gameIndex, 1);
      apexTokensCopy.forEach((element, index) => {
        element.gameNumber = index + 1;
      });

      let { id, ...newLobby } = lobby;
      newLobby.tokens = apexTokensCopy;
      const dataToPass = newLobby;

      ApexTournamentService.updateLobby(tournament?.id, lobby.id, dataToPass)
        .then(() => {
          toast.info("Game removed successfully!");
          setShowAddGameModal({ show: false });
          setLoading(false);
          props.onReloadTournament();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const onAddGame = (data:any) => {
    setLoading(true);
    let apexTokensCopy:IApexLobbyGameToken[] = [];

    if(apexTokens) {
      const gameIndex = apexTokens.findIndex(
        (element) => element.gameNumber === data.gameNumber
      );
  
      if (gameIndex > -1) {
        apexTokensCopy = [...apexTokens];
        apexTokensCopy[gameIndex] = data;
      } else {
        apexTokensCopy = [...apexTokens];
        apexTokensCopy.push(data);
      }
    } else {
      apexTokensCopy.push(data);
    }
    

    let { id, ...newLobby } = lobby;
    newLobby.tokens = apexTokensCopy;
    const dataToPass = newLobby;

    ApexTournamentService.updateLobby(tournament?.id, lobby.id, dataToPass)
      .then((response) => {
        toast.info("Game saved successfully!");
        setShowAddGameModal({ show: false });
        setLoading(false);
        props.onReloadTournament();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      {/* <div className="column col-12">
        <h5 className="skp-text-primary">Games</h5>
      </div> */}

      {!loading && (
        <>
          {games?.length === 0 && (
            <>
              <div className="column col-12 skp-text-light text-center">
                <h1>
                  <i className="las la-chess"></i>
                </h1>
              </div>
              <div className="column col-12 skp-text-light text-center">
                <h5>No games</h5>
              </div>
              <div className="column col-12 skp-text-light text-center">
                There are no games available yet.
              </div>

              {isAdmin && (
                <>
                  <div className="space-divider-20"></div>
                  <div className="column col-12 flex-centered">
                    <span
                      onClick={() =>
                        setShowAddGameModal({
                          show: true,
                          initialValues: initialValuesEmpty,
                          title: "Add Game",
                        })
                      }
                      className="skp-link-primary"
                    >
                      <i className="las la-plus-circle"></i> Add new game
                    </span>
                  </div>
                  <div className="space-divider"></div>
                </>
              )}
            </>
          )}

          {games?.length > 0 && (
            <>
              {isAdmin && (
                <div className="column col-4 col-xs-12">
                  <div
                    className="card skp-card skp-card-hover flex-centered"
                    style={{ height: 250 }}
                    onClick={() =>
                      setShowAddGameModal({
                        show: true,
                        initialValues: initialValuesEmpty,
                        title: "Add Game",
                      })
                    }
                  >
                    <h2 className="skp-link-primary">
                      <i className="las la-plus-circle"></i>
                    </h2>
                    <span className="skp-link-primary">Add new game</span>
                  </div>
                </div>
              )}
              {games.map((game, index) => (
                <ApexGameCard
                  tournament={tournament}
                  game={game}
                  index={index}
                  isAdmin={isAdmin}
                  isMember={isMember}
                  editGame={editGame}
                  lobby={lobby}
                />
              ))}
            </>
          )}
        </>
      )}

      {/* Modal to Edit or Add Game */}
      <Modal
        title={showAddGameModal.title}
        show={showAddGameModal.show}
        onClose={() =>
          setShowAddGameModal({
            show: false,
            initialValues: initialValuesEmpty,
          })
        }
        size="medium"
      >
        <CreateEditApexGameModal
          initialValues={showAddGameModal.initialValues}
          loading={loading}
          title={showAddGameModal.title || ""}
          onRemoveGame={onRemoveGame}
          onAddGame={onAddGame}
        />
      </Modal>
    </>
  );
};

export default ApexGameTab;
