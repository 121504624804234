import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import TeamService from "../../../services/TeamService";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Modal from "../../UiLibrary/Modal";
import UploadImageBox from "../../UiLibrary/UploadImageBox/UploadImageBox";
import ImageService from "../../../services/ImageService";
import { toast } from "react-toastify";
import UploadImageButton from "../../UiLibrary/UploadImageButton";
import { UserContext } from "../../Context/UserContext";
import LoginOrRegisterModal from "../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../constants";
import KeyCloackService from "../../../services/KeyCloackService";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import { useTranslation } from "react-i18next";
import IBrand from "../../Interfaces/Brand";
import TeamBrandLogo from "../../UiLibrary/TeamBrandLogo";
import ChatTab from "../Chats/ChatTab/ChatTab";
import CompetitionsTab from "./BrandTabs/CompetitionsTab";
import MembersTab from "./BrandTabs/MembersTab";
import OverviewTab from "./BrandTabs/OverviewTab";
import NewsTab from "./BrandTabs/News/NewsTab";

const BrandPage = () => {
    let navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { t } = useTranslation()
    const [brandInfo, setBrandInfo] = useState<IBrand>();
    const [showUploadModal, setShowUploadModal] = useState<{
        show: boolean;
        type?: string | undefined;
    }>({
        show: false,
        type: undefined,
    });
    const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
    const [loading, setLoading] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const params = useParams<{ id: string; tab: string; slug: string }>();
    const brandSlug = params.slug;
    const brandId = params.id;
    const currentTab = params.tab;

    const tabs = [
        { name: t('tabs.overview'), url: "overview" },
        { name: t('tabs.news'), url: "news" },
        { name: t('tabs.members'), url: "members" },
        { name: t('tabs.competitions'), url: "competitions" },
        { name: t('tabs.ranking'), url: "ranking" },
        { name: t('tabs.chat'), url: "chat" }
    ];

    const [imageError, setImageError] = useState(false);

    const isMemberAlready = brandInfo?.members.includes(user?.id || "");
    const isPendingMember =
    brandInfo?.pendings?.findIndex((member) => member.id === user?.id) !== -1;

    if (isPendingMember) {
    }

    const memberStatus = brandInfo?.pendings?.find(
        (member) => member.id === user?.id
    )?.status;
  
    useEffect(() => {
        if (!currentTab) {
            navigate(`/brands/${brandSlug}/overview`);
        }
        TeamService.getBrand(brandSlug!).then((response) => {
            setBrandInfo(response.data);
        });
    }, [brandSlug, currentTab, navigate]);

    const joinBrandUrl = `/brands/join-brand/${brandSlug}/`;

    const uploadImage = (image: any) => {
        setImageToUpload(image);
    };

    const updateImage = (type: string | null | undefined) => {
        if (type === null || type === undefined || !brandInfo || !imageToUpload) {
            return;
        }
        setLoading(true);
        ImageService.uploadImage(imageToUpload).then((response) => {
        let data = {} as any;
        data[type] = ImageService.generatePublicUrl(response.data);
        TeamService.updateBrand(brandInfo.id, data)
            .then((Rteam) => {
                toast.success(t('toast.brandUpdatedSuccess'));
                setLoading(false);
                setBrandInfo(Rteam);
                setShowUploadModal({ show: false });
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
                setLoading(false);
                setShowUploadModal({ show: false });
            });
        });
    };

    const leaveBrand = () => {
        if (!user?.id) {
            return;
        }
        setLoading(true);
        TeamService.removeMemberFromBrand(brandSlug!, [user.id]).then((Rbrand) => {
            setLoading(false);
            setBrandInfo(Rbrand);
            toast.success(t('toast.leftBrandSuccess'));
        }).catch((error) => {
            toast.error(error?.response?.data?.message);
            setLoading(false);
        });
    };

    const joinBrand = () => {
        if (!user?.id) {
            return;
        }
        setLoading(true);
        TeamService.inviteMemberInBrand(brandSlug!, user.id).then((Rbrand) => {toast.success(t('toast.brandJoinWait'));
            setBrandInfo(Rbrand);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
        });
    };

    const acceptBrand = () => {
        if (!user?.id) {
            return;
        }
        setLoading(true);
        
        TeamService.acceptMemberInBrand(brandSlug!, user.id).then((Rteam) => {
            toast.success(t('toast.brandJoinWait'));
            setBrandInfo(Rteam);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
        });
    };
 
    return (
        <>
            <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
                <div className="skp-page-with-image-banner">
                    {/* IMAGE BANNER */}
                    {user?.id === brandInfo?.owner && (
                        <button
                            onClick={() => setShowUploadModal({ show: true, type: "banner" })}
                            className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default"
                        >
                            <i className="las la-camera"></i> {t('component.uploadBanner')}
                        </button>
                    )}
                    <div className="skp-image-banner">
                        {brandInfo && brandInfo?.banner && !imageError && (
                            <img
                                alt="profile-banner"
                                src={brandInfo?.banner}
                                onError={() => setImageError(true)}
                            />
                        )}
                        <div className="transparent-black"></div>
                    </div>
                    {/* END IMAGE BANNER */}
                    {/* PAGE TOP */}
                    <div className="container page-top">
                        {/* Page Top */}
                        <div className="columns page-top">
                            <div className="column col-3 profile-picture text-center col-xs-12 text-center-xs">
                                {brandInfo && (
                                    <TeamBrandLogo team={brandInfo}>
                                        <>
                                            {user?.id === brandInfo?.owner && (
                                                <UploadImageButton onClick={() => setShowUploadModal({ show: true, type: "logo" })}/>
                                            )}
                                        </>
                                    </TeamBrandLogo>
                                )}
                            </div>
                            <div className="column col-9 col-xs-12 text-center-xs">
                                <div className="columns">
                                    <div className="column col-12">
                                        <span className="skp-text-label skp-text-light"></span>{" "}
                                        <br></br>
                                        <h1 className="skp-text-primary skp-heading-no-margin">
                                            {brandInfo?.name}
                                        </h1>
                                        {KeyCloackService.isLoggedIn() && user?.id === brandInfo?.owner && (
                                            <Link
                                                to={`/brands/${brandSlug}/settings/about`}
                                                className="skp-link skp-text-small"
                                            >
                                                {t('common.settings')} <i className="las la-cog"></i>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="column col-12">
                                        <div className="space-divider-20"></div>
                                        <div className="columns skp-text-primary">
                                            <div className="column col-12">
                                                {brandInfo?.members && (
                                                    <>
                                                        {t('brands.config.member',{count:brandInfo?.members.length})}
                                                    </>
                                                )} {" - "}
                                                <Link to={joinBrandUrl}>
                                                    {t('brands.main.join', {brand:brandInfo?.name})}
                                                </Link>
                                            </div>
                                            <div className="space-divider-20"></div>
                                            <div className="column col-12 text-right text-center-xs">
                                                {isMemberAlready && brandInfo?.owner !== user?.id && (
                                                <button
                                                    disabled={loading}
                                                    className="skp-btn skp-btn-alert"
                                                    onClick={() => leaveBrand()}
                                                >
                                                    {t('brands.main.leaveBrand')}
                                                </button>
                                                )}
                                                {!isMemberAlready && !isPendingMember && brandInfo?.owner !== user?.id && (
                                                <button
                                                    disabled={loading}
                                                    className="skp-btn skp-btn-primary"
                                                    onClick={() => joinBrand()}
                                                >
                                                    {t('brands.main.join', {brand:brandInfo?.name})}
                                                </button>
                                                )}
                                                {!isMemberAlready && isPendingMember && memberStatus === 1 &&
                                                brandInfo?.owner !== user?.id && (
                                                <button
                                                    disabled={loading}
                                                    className="skp-btn skp-btn-ternary"
                                                    onClick={() => acceptBrand()}
                                                >
                                                    {t('brands.main.acceptInvitation')}
                                                </button>
                                                )}
                                                {brandInfo?.owner === user?.id && (
                                                <Link to={`/brands/${brandSlug}/settings/pendings`} className="skp-chip status-Pending skp-text-large">
                                                    <span>
                                                    {t('brands.main.pendingInvitation', {count:brandInfo?.pendings?.length})}
                                                    </span>
                                                </Link>
                                                )}
                                                {isPendingMember && memberStatus === 0 && (
                                                <span className="skp-chip status-Pending skp-text-large">
                                                    {t('brands.main.pendingUnderReview')}
                                                </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END PAGE TOP */}
                    <div className="space-divider-5"></div>
                    {/* TABS HEADER */}
                    <div className="container tabs-header">
                        <div className="columns">
                            <div className="column col-12">
                                <ul className="tab skp-text-light">
                                    {tabs.map((tab) => (
                                        <li
                                            key={tab.name}
                                            className={ currentTab === tab.url ? "tab-item active" : "tab-item"}
                                        >
                                            <Link
                                                to={`/brands/${brandSlug}/${tab.url}`}
                                                    className={`${currentTab === tab.url && "active"}`}
                                            >
                                                {tab.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* END TABS HEADER */}
                    {/* TABS BODY */}
                    <div className="container tabs-body">
                        
                            {currentTab === tabs[0].url && brandInfo && (
                                <OverviewTab brand={brandInfo} />
                            )}
                            {currentTab === tabs[1].url && brandInfo && (
                                <NewsTab></NewsTab>
                            )}  
                            {currentTab === tabs[2].url && (
                                <MembersTab
                                    brand={brandInfo}
                                    setBrand={(brand: IBrand) => setBrandInfo(brand)}
                                    doLogin={() => setShowLoginModal(true)}
                                />
                            )}
                            {currentTab === tabs[3].url  && (
                                <CompetitionsTab brandId={brandInfo?.id}></CompetitionsTab>
                            )}
                            {currentTab === tabs[5].url && (
                                <ChatTab
                                    tournament={undefined}
                                    isAdmin={false}
                                ></ChatTab>
                            )}
                        
                    </div>
                    {/* END TABS BODY */}
                </div>
            </Scrollbars>

            <Modal
                show={showUploadModal.show}
                title={t('titles.uploadImage')}
                size="large"
                onClose={() => {
                    setShowUploadModal({ show: false });
                }}
            >
                <div className="container">
                    {showUploadModal.type === "banner" && (
                        <div className="columns">
                            <div className="space-divider-20"></div>
                                <div
                                    className="column col-12"
                                    style={{ height: 250, border: "2px dashed var(--gray-1)" }}
                                >
                                    <UploadImageBox
                                        onUpload={uploadImage}
                                        onRemovePreview={() => setImageToUpload(undefined)}
                                    />
                                </div>
                                <div className="space-divider-20"></div>
                                <div className="column col-12 text-right">
                                <button
                                    onClick={() => updateImage(showUploadModal.type)}
                                    disabled={!imageToUpload?.file || loading}
                                    className="skp-btn skp-btn-primary"
                                >
                                    {loading ? <span className="loading"></span> : t('common.upload')}
                                </button>
                            </div>
                        </div>
                    )}
                    {showUploadModal.type === "logo" && (
                        <div className="columns">
                            <div className="space-divider-20"></div>
                            <div className="column col-12 text-center">
                                <div className="upload-logo-wrapper">
                                    <UploadImageBox
                                        onUpload={uploadImage}
                                        onRemovePreview={() => setImageToUpload(undefined)}
                                    />
                                </div>
                            </div>
                            <div className="space-divider-20"></div>
                            <div className="column col-12 text-right">
                                <button
                                    onClick={() => updateImage(showUploadModal.type)}
                                    disabled={!imageToUpload?.file || loading}
                                    className="skp-btn skp-btn-primary"
                                >
                                    {loading ? <span className="loading"></span> : t('common.upload')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>

            <Modal
                show={showLoginModal}
                size="small"
                onClose={() => setShowLoginModal(false)}
            >
            <LoginOrRegisterModal
                message={constant.MESSAGES.LOGIN.TEAM_PLAYERS}
                onClose={() => setShowLoginModal(false)}
            />
        </Modal>
    </>
  );
};

export default BrandPage;