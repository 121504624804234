import { useContext, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ChatTabFooter from "./ChatTabFooter";
import { useTranslation } from "react-i18next";
import ITournament from "../../../Interfaces/Tournament";
import { UserContext } from "../../../Context/UserContext";
import IMessage from "../../../Interfaces/Chat/Message";
import IUserDetails from "../../../Interfaces/UserDetails";
import { EventsContext } from "../../../Context/EventsContext";
import ChatService from "../../../../services/ChatService";
import { EndMessage, ReplyMessage, TextMessage, Timeline } from "../../../UiLibrary/SocialComponents/MessageTypes/MessageTypes";
import UserService from "../../../../services/UserService";
import { debounce } from "lodash";

type Props = {
    tournament: ITournament | undefined,
    isAdmin: boolean
}

const ChatTab = (props:Props) => {
    const { tournament, isAdmin } = props;
    const { t } = useTranslation()
    const { user } = useContext(UserContext);
    const scrollbars = useRef<any>(null);
    const [chatId, setChatId] = useState<string>()
    const [chatHistory, setChatHistory] = useState<IMessage[]>([])
    const [replyMsg, setReplyMessage] = useState<IMessage>()
    const [contactList, setContactList] = useState<Array<string| undefined>>([user?.id])
    const [contacts, setContacts] = useState<Array<IUserDetails | null>>([user])
    const [canReadChat, setCanReadChat] = useState(false)
    const [error, setError] = useState<string | null>(null);
    const [pageIndex, setPageIndex] = useState(1);  // Stato per la pagina corrente
    const [hasMore, setHasMore] = useState(true);  // Stato per determinare se ci sono più messaggi da caricare

    const MESSAGES_PER_PAGE = 10;  // Numero di messaggi da caricare per pagina

    const handleNewMessage = debounce((updatedMessage, contactList, updateContacts, setChatHistory, scrolldown) => {
        if (updatedMessage) {
            if (updatedMessage.userid && !contactList.includes(updatedMessage.userid)) {
                updateContacts([updatedMessage]);
            }
    
            setChatHistory((prevChatHistory) => {
                const existingMessageIndex = prevChatHistory.findIndex(msg => msg.id === updatedMessage.id);
    
                if (existingMessageIndex > -1) {
                    // Aggiorna il messaggio esistente con il nuovo contenuto
                    const updatedHistory = [...prevChatHistory];
                    updatedHistory[existingMessageIndex] = updatedMessage;
                    return updatedHistory;
                }
                // Se non trova il messaggio, lo aggiunge
                return [...prevChatHistory, updatedMessage];
            });
            scrolldown();
        }
    }, 300); // Debounce di 300 ms

    const scrolldown = () => {
        scrollbars.current?.scrollToBottom()
    }

    const mustRefresh = () => {
       // setRefresh(refresh+1)
    }

    const replyMessage = (message:IMessage | undefined) => {
        setReplyMessage(message)
    }

    const updateContacts = (chatMessages: IMessage[]) => {
        const newUserIds = chatMessages
            .map((msg) => msg.userid)
            .filter((userid) => userid && !contactList.includes(userid));

        if (newUserIds.length > 0) {
            Promise.all(newUserIds.map((userid) => UserService.getUser(userid)))
                .then((newUsers) => {
                    setContacts((prevContacts) => [...prevContacts, ...newUsers]);
                    setContactList((prevContactList) => [...prevContactList, ...newUserIds]);
                })
                .catch((error) => {
                    console.error("Errore durante l'aggiornamento dei contatti:", error);
                });
        }
    };

    const loadMoreMessages = () => {
        if (chatId && hasMore) {
            // Aggiorniamo l'indice della pagina prima di effettuare la chiamata API
            setPageIndex(prevPage => {
                const nextPage = prevPage + 1;
    
                // Ora facciamo la chiamata API con il nuovo indice di pagina
                ChatService.getMessageHistory(chatId, { userid: user!.id, pageIndex: nextPage, pageSize: MESSAGES_PER_PAGE })
                    .then((newMessages) => {
                        if (newMessages.length < MESSAGES_PER_PAGE) {
                            setHasMore(false);  // Non ci sono più messaggi da caricare
                        }
                        updateContacts(newMessages);
                        setChatHistory(prevHistory => {
                            const updatedHistory = [...newMessages, ...prevHistory];
                            setTimeout(() => {
                                scrolldown(); // Chiama lo scroll dopo aver aggiornato la chat
                            }, 100); 
                            return updatedHistory;
                        });
                    })
                    .catch((error) => {
                        console.error("Errore durante il caricamento dei messaggi:", error);
                    });
    
                // Restituiamo il nuovo indice di pagina
                return nextPage;
            });
        }
    };

    const { socket, isConnected } = useContext(EventsContext);

    useEffect(() => {
        if (tournament?.id &&
            ((user?.id && (tournament.members.includes(user.id) || tournament.owner === user.id || tournament.admins.includes(user.id)))
                || isAdmin)) {
            setCanReadChat(true);

            ChatService.getChatByTournamentId(tournament.id, user!.id!)
                .then((resp) => {
                    if (resp.id) {
                        setChatId(resp.id);

                        ChatService.getMessageHistory(resp.id, { userid: user!.id, pageIndex: 1, pageSize: MESSAGES_PER_PAGE })
                            .then((rchat) => {
                                if (rchat.length < MESSAGES_PER_PAGE) {
                                    setHasMore(false);  // Non ci sono più messaggi da caricare
                                }
                                updateContacts(rchat);
                                setChatHistory((prevHistory) => {
                                    const newHistory = [...prevHistory, ...rchat];
                                    return newHistory;
                                });
                            })
                            .catch((error) => {
                                console.error("Errore durante il recupero della cronologia dei messaggi:", error);
                            });
                    }
                })
                .catch((error) => {
                    console.error("Errore durante il recupero della chat:", error);
                });
        } else {
            setCanReadChat(false);
        }
    }, [tournament?.id, user?.id]);

    useEffect(() => {
        // Scrolla verso il basso quando la cronologia della chat cambia
        scrolldown();
    }, [chatHistory]);

    useEffect(() => {
        if (isConnected && socket && chatId && canReadChat) {
            socket.on(chatId, (updatedMessage) => handleNewMessage(updatedMessage, contactList, updateContacts, setChatHistory, scrolldown));

            return () => {
                socket.off(chatId, (updatedMessage) => handleNewMessage(updatedMessage, contactList, updateContacts, setChatHistory, scrolldown));
            };
        }
    }, [chatId, canReadChat, contactList, isConnected, socket]);
  
    return (
        <div className="chat-tab column col-12">
            <div className="column col-12 skp-page-with-sidebar-main-content" style={{ height: "100%" }}>
                <div className="skp-chat-conversation-page">
                    {canReadChat && (
                        <div className="skp-chat-conversation-body">
                            <Scrollbars ref={scrollbars}
                                renderTrackVertical={
                                    ({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: '#292c33', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px', width: '5px' }} />
                                }
                                renderThumbVertical={
                                    ({ style, ...props }) => <div {...props} style={{ ...style, width: '3px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)', backgroundColor: '#959aa2' }} />
                                }>
                                    {hasMore && (
                                    <div className="load-more-messages">
                                        <button onClick={loadMoreMessages} className="skp-btn skp-btn-link">
                                            {t('messages.loadMore')}
                                        </button>
                                    </div>
                                )}
                                {chatHistory.length > 0 ? (
                                    chatHistory.map((el, index) => {
                                        switch (el.type) {
                                            case "divider":
                                                return <Timeline key={index} msg={el} />;
                                            case "text":
                                                return (
                                                    <TextMessage
                                                        key={index}
                                                        user={user}
                                                        mustRefresh={mustRefresh}
                                                        replyMessage={replyMessage}
                                                        contacts={contacts}
                                                        msg={el}
                                                        isDeleted={false}
                                                        tournament={tournament}
                                                    />
                                                );
                                            case "reply":
                                                return (
                                                    <ReplyMessage
                                                        key={index}
                                                        user={user}
                                                        mustRefresh={mustRefresh}
                                                        replyMessage={replyMessage}
                                                        contacts={contacts}
                                                        msg={el}
                                                        isDeleted={false}
                                                        tournament={tournament}
                                                    />
                                                );
                                            default:
                                                return null;
                                        }
                                    })
                                ) : (
                                    <div className="skp-info-message-box">
                                        <h4 className="skp-text-primary">{t('messages.noMessageSentYet')}</h4>
                                    </div>
                                )}
                                {error && (
                                    <div className="skp-error-message-box">
                                        <h4 className="skp-text-danger">{error}</h4>
                                    </div>
                                )}
                            </Scrollbars>
                        </div>
                    )}
                    {canReadChat && (
                        <ChatTabFooter
                            tournament={tournament}
                            isAdmin={isAdmin}
                            chatId={chatId}
                            setChatId={setChatId}
                            replyMsg={replyMsg}
                            replyMessage={replyMessage}
                            contacts={contacts}
                            setChatHistory={setChatHistory}
                        ></ChatTabFooter>
                    )}
                    {!canReadChat && (
                        <div className="skp-info-message-box">
                            <h4 className="skp-text-primary">{t('messages.onlyRegisteredCanSend')}</h4>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatTab;