import constant from "../constants";
import  { setupCache } from "axios-cache-adapter";
import HttpService from "./HttpService";
import qs from "query-string";
import IRegisterUserData from "../components/Interfaces/RegisterUserData";
import GameAccount from "../components/Interfaces/GameAccount";
import IUserDetails from "../components/Interfaces/UserDetails";
 
interface IUserData {
  discordId?: string
  epicId?: string
  profile_banner?: string
  profile_picture?: string
  gameAccounts?: Array<GameAccount>
  first_name?: string;
  last_name?: string;
  birth_date?: Date | null;
  street_name?: string;
  additional_address_info?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  phone_number?: string;
  roles?: Array<string>;
}

interface IUserPwdData {
  oldPassword?: string
  newPassword?: string
  confirmPassword?: string
}



let _axios = HttpService.getAxiosClient();

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: { query: false }
});

const getAllUsers = (queryParam?:any):Promise<Array<IUserDetails>> => {
  let url = `${constant.API_URL}/users`;
  if (queryParam) {
    url += `?${qs.stringify(queryParam)}`;
  }

  return _axios.get(url).then((res) => {
    return res.data.data.users;
  });
};

const getUser = async(userId:string, disableCache?:boolean):Promise<IUserDetails> => {
    const url = `${constant.API_URL}/users/${userId}`;
    return _axios({
      method: "get",
      url: url,
      adapter: !disableCache ? cache?.adapter : undefined,
    }).then((response) => {
      return response.data.data;
    });
}

const getUserByEpicId = async (epicId:string):Promise<IUserDetails> => {
  const url = `${constant.API_URL}/users?epicId=${epicId}&limit=1`;
  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data.users[0];
  });
};

const getUserByName = async (username:string, limit?:number ):Promise<Array<IUserDetails>> => {
  const url = `${constant.API_URL}/users?username=${username}&limit=${limit ? limit : 50}`;
  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data.users;
  });
};

const getUserByEmail = (email:string) => {
  const url = `${constant.API_URL}/users?email=${email}&limit=1`;
  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
}

const getUserByApexId = async (apexId:string) => {
  const url = `${constant.API_URL}/users?apexId=${apexId}`;
  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
};

const getUserLogin = async(sub:string):Promise<IUserDetails> => {
    let url = `${constant.API_URL}/users/login?sub=${sub}`;
    return _axios({
      method: "post",
      url: url,
    }).then((response) => {
      return response.data.data.user
    });
}

const getUserByAuthKey = async (authKey: string):Promise<IUserDetails> => {
  const url = `${constant.API_URL}/users?authKey=${authKey}`;
  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data.users[0];
  });
}

const registerUser = async(data:IRegisterUserData, token:string | undefined) => {
    const url = `${constant.API_URL}/users?g-recaptcha-response=${token}`;
    return _axios({
      method: "post",
      url: url,
      data: data,
    }).then((response) => {
      return response;
    });
}

const updateUserInfo = (data:IUserData, userId:string | undefined):Promise<IUserDetails> => {
    const url = `${constant.API_URL}/users/${userId}`;
    return _axios({
      method: "put",
      url: url,
      data: data,
    }).then((response) => {
      return response.data.data;
    });
}

const updateAdminUserInfo = (data:IUserData, userId:string | undefined):Promise<IUserDetails> => {
  const url = `${constant.API_URL}/users/${userId}/admin`;
  return _axios({
    method: "put",
    url: url,
    data: data,
  }).then((response) => {
    return response.data.data;
  });
}

const resetUserPassword = (data:IUserPwdData, userId:string) => {
    const url = `${constant.API_URL}/users/${userId}/reset-password?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}&confirmPassword=${data.confirmPassword}`;
    return _axios({
      method: "put",
      url: url
    }).then((response) => {
      return response;
    });
}

const deleteUser = async(userId:string) => {
  const url = `${constant.API_URL}/users/${userId}`;
  return _axios({
    method: "delete",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
}

const addUserConsent = (data, userId) => {
  let url = `${constant.API_URL}/users/consent/${userId}`;
  return _axios({
    method: "post",
    url: url,
    data: data
  }).then((response) => {
    return response.data.data
  }).catch((error)=>{
    
  });
}

const getUserConsent = (userId) => {
  let url = `${constant.API_URL}/users/consent/${userId}`;
  return _axios({
    method: "get",
    url: url
  }).then((response) => {
    return response.data;
  });
}

const UserService = {
  addUserConsent,
  getUserConsent,
  getAllUsers,
  getUser,
  getUserByName,
  getUserByEmail,
  getUserByEpicId,
  getUserByApexId,
  getUserLogin,
  getUserByAuthKey,
  registerUser,
  updateUserInfo,
  updateAdminUserInfo,
  resetUserPassword,
  deleteUser
};

export default UserService; 