import React, { useState, useEffect, useContext } from "react";
import Match from "../Match";
import MatchDetailsModal from "../MatchDetailsModal/MatchDetailsModal";
import Modal from "../../Modal";
import MatchService from "../../../../../../../services/MatchService";
import LoadingPlaceholders from "../../../../../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITournament from "../../../../../../Interfaces/Tournament";
import IMatch from "../../../../../../Interfaces/Match";
import IMatchTournamentSeed from "../../../../../../Interfaces/MatchTournamentSeed";
import { TournamentMatchesContext } from "../../../../../../Context/TournamentMatchesContext";
import MatchResultService from "../../../../../../../services/MatchResultService";
import EMatchStatus from "../../../../../../../enums/MatchStatus";

type Props = {
  tournament: ITournament | undefined;
  match: IMatchTournamentSeed;
};

const MultiMatchDetailsModal = (props: Props) => {
  const { match, tournament } = props;
  const {tournamentMatches} = useContext(TournamentMatchesContext)
  const [showModal, setShowModal] = useState<{
    show: boolean;
    match?: any;
    matchId?: string;
    id?: number;
  }>({
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState<Array<IMatch>>([]);
  const multipleMatchStatus = MatchResultService.getMultipleMatchStatus(tournamentMatches?.matches, match);
  const multipleMatchResult = MatchResultService.getMultipleMatchWinner(tournamentMatches?.matches, match);

  useEffect(() => {
    MatchService.getMatches(match?.matchesId).then((Rmatches) => {
      setMatches(Rmatches);
      setLoading(false);
    });
    return () => {
      setMatches([]);
    };
  }, [match]);

  const reloadMatchList = () => {
    setShowModal({ show: false });
    setLoading(true);
    MatchService.getMatches(match?.matchesId).then((Rmatches) => {
      setMatches(Rmatches);
      setLoading(false);
    });
    return () => {
      setMatches([]);
    };
  };

  const openSingleMatch = (matchId: string, index: number) => {
    if (!matchId || index === undefined) {
      return;
    }
    setShowModal({
      show: true,
      matchId: matchId,
      match: match,
      id: index,
    });
  };

  return (
    <div className="container">
      <div className="columns">
      <div className="column col-12 text-center">
          <div className={`skp-chip status-${multipleMatchStatus}`}>
            {EMatchStatus[multipleMatchStatus]}
          </div>
        </div>

        <div className="column col-12 text-center skp-text-light">
          Best of {match.matchesId.length}
        </div>

        <div className="column col-12">
          <div className="columns">
            <div className="column col-5 flex-centered">{multipleMatchResult.teamHomeScores.length}</div>
            <div className="column col-2 flex-centered">:</div>
            <div className="column col-5 flex-centered">{multipleMatchResult.teamAwayScores.length}</div>
          </div>
        </div>

        <div className="column col-12">
          {!showModal.show && (
            <dl className="bordered-list">
              {loading && (
                <LoadingPlaceholders numberOfItems={tournament?.matchType}>
                  <dd className="row-loading" style={{ height: "102px" }}></dd>
                </LoadingPlaceholders>
              )}
              {!loading &&
                tournament &&
                match?.matchesId.map((matchId, index) => (
                  <dd
                    key={matchId}
                    onClick={() => openSingleMatch(matchId, index)}
                    className={
                      (matches[index - 1] && matches[index - 1].status !== 3) ||
                      (match?.winner &&
                        matches[index] &&
                        (matches[index].status === 0 ||
                          matches[index].status === 4))
                        ? "disabled"
                        : ""
                    }
                  >
                    <Match
                      matchId={matchId}
                      showStatus={true}
                      gameId={index}
                      tournament={tournament}
                    />
                  </dd>
                ))}
            </dl>
          )}
        </div>
      </div>

      <Modal
        show={showModal.show}
        onClose={() => reloadMatchList()}
        title={`Game ${showModal?.id ? showModal.id + 1 : ""}`}
      >
        <>
          {/* Single Match */}
          {showModal.matchId && tournament && (
            <MatchDetailsModal
              tournament={tournament}
              matchId={showModal.matchId}
            />
          )}
        </>
      </Modal>
    </div>
  );
};

export default MultiMatchDetailsModal;
