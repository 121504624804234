import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="columns skp-text-light skp-text-small">
            <div className="column col-12 text-right">
              &#169; 2021 - 2023 SKILLPRO -{" "}
              <Link to={"/privacy-policy"} className="skp-link-primary">
                Privacy Policy
              </Link>{" "}
              /{" "}
              <Link to={"/terms-and-conditions"} className="skp-link-primary">
                Terms & Conditions
              </Link>{" "}
              /{" "}
              <Link to={"/cookies-policy"} className="skp-link-primary">
                Cookies Policy
              </Link>
            </div>
          </div>
    )
}

export default Footer