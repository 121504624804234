import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import IUserDetails from "../../Interfaces/UserDetails";
import { useTranslation } from "react-i18next";

type Props = {
  setUserList: (users: IUserDetails[]) => void;
  searchUser: (event: any) => void;
  reset?: number
};

const UserSearchInput = (props: Props) => {
  const { reset } = props
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState("");

  useEffect(()=>{
    setSearchText("")
  },[reset])

  const searchUser = (event: any) => {
    setSearchText(event.target.value);
    props.searchUser(event)
  };

  return (
    <div className="skp-form-control">
      <label className="skp-text-primary skp-text-small">{t('modals.searchUsers')}</label>
      <DebounceInput
        value={searchText}
        type="text"
        minLength={1}
        debounceTimeout={500}
        placeholder={t('modals.searchByUsername')}
        onChange={(e) => searchUser(e)}
      />
    </div>
  );
};

export default UserSearchInput;
