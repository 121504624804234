import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./warzone2vs2.scss";
import Modal from "../../../../UiLibrary/Modal";
import WeArenaSingleMatch from "../../WeArena/WeArenaSingleMatch";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import WarZone2vs2Bracket from "./WarZone2vs2Bracket";

const WarZone2vs2 = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [zoom, setZoom] = useState<number>(1);
  const [translateX, setTranslateX] = useState<number>(0);
  const [translateY, setTranslateY] = useState<number>(0);
  const [showMatchModal, setShowMatchModal] = useState<{
    show: boolean;
    match?: any;
  }>({ show: false });

  const openMatchModal = (match: any) => {
    setShowMatchModal({ show: true, match: match });
  };

  const handle = useFullScreenHandle();

  const showFullScreen = () => {
    handle.enter();
    setShowSettings(false);
  };

  const zoomInOut = (zoomPercentage: number) => {
    setZoom(zoom + zoomPercentage);
    setTranslateX(translateX + zoomPercentage * 50 * 10);
    setTranslateY(translateY + zoomPercentage * 50 * 10);
  };

  return (
    <>
      <FullScreen handle={handle}>
        <Scrollbars className="main-container">
          <div className="milanocomics-event-wrapper"></div>

          <div className="settings-tab text-right">
            <button
              className="skp-btn skp-btn-circle"
              onClick={() => setShowSettings(!showSettings)}
            >
              <i className="skp-icon-settings"></i>
            </button>
            {showSettings && (
              <dl className="settings-tab-dropdown skp-text-primary">
                <dd>
                  Fullscreen{" "}
                  <button
                    className="skp-btn skp-btn-circle ml-2"
                    onClick={showFullScreen}
                  >
                    <i className="las la-expand"></i>
                  </button>
                </dd>
                <dd>
                  Zoom In{" "}
                  <button
                    className="skp-btn skp-btn-circle ml-2"
                    onClick={() => zoomInOut(0.1)}
                    disabled={zoom === 2}
                  >
                    <i className="las la-plus"></i>
                  </button>
                </dd>
                <dd>
                  Zoom Out{" "}
                  <button
                    className="skp-btn skp-btn-circle ml-2"
                    onClick={() => zoomInOut(-0.1)}
                    disabled={zoom === 0.5000000000000001}
                  >
                    <i className="las la-minus"></i>
                  </button>
                </dd>
              </dl>
            )}
          </div>

          <div
            className="container"
            style={{ position: "relative", zIndex: 1 }}
          >
            <div className="columns">
              <div className="column col-2 col-xs-12">
                <div className="space-divider"></div>
                <div className="logo-placeholder main-logo">
                  <img
                    alt="milano-comics-game"
                    src="https://storage.googleapis.com/skillpro-bucket/Milano%20Comics%20-%20logo%202.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                >
                  <img
                    alt="eli-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/eli.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "50%", margin: "0px auto" }}
                >
                  <img
                    alt="leone-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/leone.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                >
                  <img
                    alt="dsyre-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/dsyre.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                >
                  <img
                    alt="ncontrol-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/ncontrol.png"
                  ></img>
                </div>
              </div>
              <div className="column col-8 col-xs-12 text-center text-center-xs bracket-container">
                <div className="space-divider-20"></div>
                <h2 className="skp-text-primary">WarZone 2vs2</h2>

                <Scrollbars className="day-scrollbar">
                  <div
                    className="column col-12 flex-centered"
                    style={{
                      height: "100%",
                      transform: `scale(${zoom}) translateX(${translateX}px)`,
                    }}
                  >
                    <WarZone2vs2Bracket
                      onOpenMatch={openMatchModal}
                    ></WarZone2vs2Bracket>
                  </div>
                </Scrollbars>
              </div>
              <div className="column col-2 col-xs-12">
                <div className="space-divider"></div>

                <div className="logo-placeholder">
                  <img
                    alt="pchunter-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/Octo-PCH_LOGO-V_C.png"
                  ></img>
                </div>
                <div className="logo-placeholder">
                  <img
                    alt="beebad-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo%20Beebad.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="retrogame-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/retrograme.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto"}}
                >
                  <img
                    alt="kink-esport-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/king-esport.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="volandia-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/volandia.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="foto-nerd-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/foto-nerd.png"
                  ></img>
                </div>
              </div>
            </div>
          </div>

          
        </Scrollbars>
      </FullScreen>
    </>
  );
};

export default WarZone2vs2;
