import { setupCache } from "axios-cache-adapter";
import HttpService from "./HttpService";
import constant from "../constants";
import IChat from "../components/Interfaces/Chat/Chat";
import qs from 'query-string';
import IMessage from "../components/Interfaces/Chat/Message";

const cache = setupCache({
    maxAge: 3 * 60 * 1000,
    exclude: { query: false }
});

interface IMessageQueryParams {
    userid?: string;
    pageIndex?: number;
    pageSize?: number;
    [key: string]: any; // Per eventuali altri parametri opzionali
}
  
let _axios = HttpService.getAxiosClient();

const getChatList = (userId: string, queryParam?: any):Promise<Array<IChat>> => {
    let url = `${constant.API_URL}/chats/getListByUser/${userId}`;
    if (queryParam) {
        url += `?${qs.stringify(queryParam)}`
    }
  
    return _axios({
        method: "get",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
};

const getChatListByUser = (userId: string):Promise<Array<IChat>> => {
    let url = `${constant.API_URL}/chats/getChatListStatusByUser/${userId}`;
    return _axios({
        method: "get",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
};

const getChatById = (chatId: string, userId: string): Promise<IChat> => {
    const url = `${constant.API_URL}/chats/getChatById/${chatId}/${userId}`;
  
    return _axios({
        method: "get",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
}

const getChatByTournamentId = (tournamentId: string, userId: string): Promise<IChat> => {
    const url = `${constant.API_URL}/chats/getChatByTournamentId/${tournamentId}/${userId}`;
  
    return _axios({
        method: "get",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
}

const getMessageHistory = async (chatId: string, queryParam?: IMessageQueryParams): Promise<Array<IMessage>> => {
    try {
        let url = `${constant.API_URL}/chats/getMessagesByChat/${chatId}`;
        if (queryParam) {
            url += `?${qs.stringify(queryParam)}`;
        }

        const response = await _axios.get(url);
        return response.data.data;
    } catch (error) {
        console.error("Errore durante il recupero della cronologia dei messaggi:", error);
        throw error; // Rilancia l'errore per la gestione a livello superiore
    }
};

const countMessageByTournament = (tournamentId:string): Promise<number> => {
    let url = `${constant.API_URL}/chats/countMessageByTournament/${tournamentId}`;
    return _axios({
        method: "get",
        url: url
    }).then((response) => {
        return response.data.data;
    });
}

const createChat = (data: any) => {
    const url = `${constant.API_URL}/chats`;
    return _axios({
        method: "post",
        url: url,
        data: data,
    }).then((response) => {
        return response.data.data;
    });
}

const createTournamentChat = (tournamentId: string) => {
    const url = `${constant.API_URL}/chats/createChatByTournamentId/${tournamentId}`;
    return _axios({
        method: "post",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
}

const sendMessage = (data: IMessage) => {
    const url = `${constant.API_URL}/chats/message`;
    return _axios({
        method: "post",
        url: url,
        data: data,
    }).then((response) => {
        return response.data.data;
    });
}

const pinConversation = (chatId: string, userId: string) => {
    const url = `${constant.API_URL}/chats/pinConversation/${chatId}/${userId}`;
    return _axios({
        method: "patch",
        url: url
    }).then((response) => {
        return response.data.data;
    });
}

const updateReactions = (messageId: string, data: any):Promise<IMessage> => {
    const url = `${constant.API_URL}/chats/message/updateReactions/${messageId}`;
    return _axios({
        method: "patch",
        url: url,
        data: data,
    }).then((response) => {
        return response.data.data;
    });
}

const deleteChat = (chatId:string, userId: string) => {
    const url = `${constant.API_URL}/chats/${chatId}/${userId}`;
    return _axios({
        method: "delete",
        url: url
    }).then((response) => {
        return response;
    });
}

const deleteMessage = (messageId:string) => {
    const url = `${constant.API_URL}/chats/message/${messageId}`;
    return _axios({
        method: "delete",
        url: url
    }).then((response) => {
        return response;
    });
}

const deleteTournamentMessage = (messageId:string) => {
    const url = `${constant.API_URL}/chats/tournamentMessage/${messageId}`;
    return _axios({
        method: "delete",
        url: url
    }).then((response) => {
        return response;
    });
}

const translate = (text: IMessage, target: string) => {
    const url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyDRnGFDgGlYh9RE6yvJ-pSkp0OUnuApy_Y`;

    const data = {
        q: text.text,
        target: target
    }

    return _axios({
        method: "post",
        url: url,
        data: data,
    }).then((response) => {
        return response.data.data;
    });
}

const sendChabot = (data: any) => {
    const url = `http://localhost:3001/chatbot/chatCompletion`;
    return _axios({
        method: "post",
        url: url,
        data: data,
    }).then((response) => {
        return response;
    });
}

    
const ChatService = {
    getChatList,
    getChatListByUser,
    getChatById,
    getChatByTournamentId,
    getMessageHistory,
    countMessageByTournament,
    createChat,
    createTournamentChat,
    sendMessage,
    pinConversation,
    updateReactions,
    deleteChat,
    deleteMessage,
    deleteTournamentMessage,
    translate,
    sendChabot
};
  
export default ChatService;