import React from "react";
import { Formik, Form } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import * as Yup from "yup";
import DateService from "../../../../services/DateService";
import ITournament from "../../../Interfaces/Tournament";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament
  loading: boolean | undefined
  onSubmit: (data: any) => void
}

interface FormModel {
  start_tournament?: Date | string,
  end_tournament?: Date | string,
  check_in?: number,
  check_in_end?: number,
  start_registration?: Date | string,
  end_registration?: Date | string,
  start_checkin?: Date | string,
  end_checkin?: Date | string
}

function Schedule(props:Props) {
  const { loading, tournament } = props;
  const { t } = useTranslation()

  const validationSchema = Yup.object({
    start_tournament: Yup.date().required(t('yup.fieldRequired')).nullable(),
    start_registration: Yup.date().required(t('yup.fieldRequired')).nullable(),
    end_tournament: Yup.date().required(t('yup.fieldRequired')).nullable(),
    check_in: Yup.number()
      .min(30, t('yup.chekInPeriodMin'))
      .required("Required"),
    check_in_end: Yup.number()
      .min(10, t('yup.chekInPeriodEnd'))
      .required("Required"),
  });

  const onSubmit = (values:FormModel) => {
    const data = {
      start_tournament: values.start_tournament,
      end_tournament: values.end_tournament,
      check_in: values.check_in,
      check_in_end: values.check_in_end,
      start_registration: values.start_registration,
      end_registration: new Date(new Date(values?.start_tournament || "").valueOf() - (values?.check_in || 0) * 60000),
      start_checkin: new Date(new Date(values?.start_tournament || "").valueOf() - (values?.check_in || 0) * 60000),
      end_checkin: new Date(new Date(values?.start_tournament || "").valueOf() - (values?.check_in_end || 0) * 60000),
    };
      props.onSubmit(data);
  }

  const initialValues:FormModel = {
    start_tournament: new Date(tournament?.start_tournament),
    end_tournament: new Date(tournament?.end_tournament),
    start_registration: new Date(tournament?.start_registration),
    check_in: tournament?.check_in ? tournament?.check_in : DateService.substractDatesAndGetMinutes(tournament?.start_tournament, tournament?.end_registration),
    check_in_end: tournament?.check_in_end ? tournament?.check_in_end : DateService.substractDatesAndGetMinutes(tournament?.start_tournament, tournament?.end_registration)
  };

  return (
    <div className="container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ isValid }) => (
          <Form>
            <div className="column col-12">
              <h5 className="skp-text-primary">{t('tournament.config.dateTime')}</h5>
              {/* Date */}
              <FormikControl
                control="date"
                label={t('tournament.config.startTournament')}
                name="start_tournament"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
                disabled={tournament.yuniteId !== undefined}
              />
              <div className="space-divider-20"></div>
              <FormikControl
                control="date"
                label={t('tournament.config.endTournament')}
                name="end_tournament"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
                disabled={tournament.yuniteId !== undefined}
              />
              <div className="space-divider-20"></div>
              <FormikControl
                control="date"
                label={t('tournament.config.startRegistration')}
                name="start_registration"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
                disabled={tournament.yuniteId !== undefined}
              />
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">{t('tournament.config.timers')}</h5>
              <FormikControl
                control="input"
                type="number"
                label={t('tournament.config.checkInStart')}
                name="check_in"
              />
               <FormikControl
                control="input"
                type="number"
                label={t('tournament.config.checkInEnd')}
                name="check_in_end"
              />
              
            </div>
            <div className="space-divider-20"></div>
            <div className="column col-12 text-right">
              <button
                type="submit"
                disabled={loading || !isValid}
                className="skp-btn skp-btn-primary"
              >
                {loading ? <span className="loading"></span> : t('common.save')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Schedule;
