import React, { useEffect, useState } from 'react'
import MatchService from '../../../../../../../services/MatchService';
import IMatch from '../../../../../../Interfaces/Match';


type Props = {
  matchId: string
  match: IMatch | undefined
  timeInterval?: number
  onSetMatch: (match: IMatch | undefined) => void

}

interface IButtonState {
  show: boolean
  message: string
  match: IMatch | undefined
}

const MatchRefreshButton = ({match, matchId, timeInterval, onSetMatch}:Props) => {

    const [buttonState, setbuttonState] = useState<IButtonState>({show: false, message: "", match: undefined})

    const refreshMatch = () => {
        onSetMatch(buttonState.match);
        setbuttonState({ show: false, message: "", match: undefined });
    }

    useEffect(() => {
      const updateInterval = setInterval(() => {
        if(match?.status !== 3) {
          MatchService.getSingleMatch(matchId).then((matchResponse) => {
            if (!match?.result.reports && (matchResponse.result.reports && matchResponse.result.reports?.length > 0)) {
              setbuttonState({
                match: matchResponse,
                show: true,
                message: `${
                  matchResponse.result.reports.length
                } New reports`,
              });
            }
              if (
                match?.result.reports &&
                match?.result.reports.length !== matchResponse.result.reports.length
              ) {
                setbuttonState({
                  match: matchResponse,
                  show: true,
                  message: `${
                    matchResponse.result.reports.length - match?.result.reports.length
                  } New reports`,
                });
              }
            if (
              match?.result.scores[0].playerMedia?.length !==
              matchResponse.result.scores[0].playerMedia?.length || match?.result.scores[1].playerMedia?.length !==
              matchResponse.result.scores[1].playerMedia?.length
            ) {
              setbuttonState({
                match: matchResponse, 
                show: true,
                message: `New screenshot uploaded`,
              });
            }
            if (match?.result.scores[0].score !== matchResponse.result.scores[0].score) {
              setbuttonState({
                match: matchResponse,
                show: true,
                message: `New score uploaded`,
              });
            }
            if (match?.result.scores[1].score !== matchResponse.result.scores[1].score) {
              setbuttonState({
                match: matchResponse,
                show: true,
                message: `New score uploaded`,
              });
            }
            if (match?.status !== matchResponse.status) {
              setbuttonState({
                match: matchResponse,
                show: true,
                message: `Status updated`,
              });
            }
          });
        }
        
      }, timeInterval || 20000);
      return () => {
        clearInterval(updateInterval);
      };
    }, [matchId, match, timeInterval]);


    return (
      <div className="columns">
        {buttonState.show && <div className="column col-12 text-center skp-banner skp-banner-quaternary" onClick={refreshMatch}>
          <span className="skp-text-small skp-text-quaternary">
            {buttonState.message}. Click here to refresh the match!
          </span>
        </div>}
        <div className="space-divider-20"></div>
      </div>
    );
}

export default MatchRefreshButton
