import { Navigate, Link } from "react-router-dom";
import EGames from "../../../../enums/Games";
import TTournament from "../../../../types/TTournament";
import GeneralSettings from "../TournamentSettingsTabs/GeneralSettings";
import TournamentSettingsInformation from "../TournamentSettingsTabs/TournamentSettingsInformation";
import Schedule from "../TournamentSettingsTabs/Schedule";
import Appearance from "../TournamentSettingsTabs/Appearance";
import AnnouncementsTab from "../TournamentSettingsTabs/AnnouncementsTab";
import PointSystemTab from "../TournamentSettingsTabs/PointSystemTab";
import { useTranslation } from "react-i18next";

const TournamentSettings = (props: TTournament) => {
  const { tournament, urlParams, loadingTournament } = props;
  const { t } = useTranslation()

  const tournamentId = urlParams?.id;
  const currentTab = urlParams?.tab;

  const tabs = [
    { name: t('tabs.general'), url: "general" },
    { name: t('tabs.information'), url: "information" },
    { name: t('tabs.rules'), url: "rules" },
    { name: t('tabs.schedule'), url: "schedule" },
    { name: t('tabs.appearance'), url: "appearance" },
    { name: t('tabs.announcements'), url: "announcements" },
    { name: t('tabs.pointSystem'), url: "point-system" },
    { name: t('tabs.generic'), url: "generic"}
  ];

  return (
        <>
          {/* TABS HEADER */}
          <div className="container tabs-header skp-text-light">
            <div className="columns tabs-header">
              <div className="column col-12">
                <ul className="tab">
                  {tabs
                    .filter((tab) => {
                      // Logica per il tab con url 'generic'
                      if (tab.url === "generic") {
                        return tournament?.custom === true;
                      }

                      // Logica per altri tab
                      return tournament?.game === EGames.APEX_LEGENDS ||
                        (tournament?.tournamentType && tournament?.tournamentType === "lobby")
                        ? true
                        : tab.url !== "point-system";
                    })
                    .map((tab) => (
                      <li
                        key={tab.name}
                        className={currentTab === tab.url ? "tab-item active" : "tab-item"}
                      >
                        <Link
                          to={`/tournaments/${tournamentId}/settings/${tab.url}`}
                          className={currentTab === tab.url ? "active" : ""}
                        >
                          {tab.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="space-divider-20"></div>
          </div>
          {/* END TABS HEADER */}
          {/* TABS BODY */}
          <div className="container tabs-body">
            <div className="columns">
              {/* General Tab */}
              {tournament && currentTab === tabs[0].url && (
                <GeneralSettings
                  onSubmit={props.updateTournament}
                  loading={loadingTournament}
                  tournament={tournament}
                  loadTournament={props.loadTournament}
                />
              )}
              {/* Information Tab */}
              {tournament && currentTab === tabs[1].url && (
                <TournamentSettingsInformation
                  tournament={tournament}
                  keyValue="description"
                />
              )}
              {/* Rules Tab */}
              {tournament && currentTab === tabs[2].url && (
                <TournamentSettingsInformation
                  tournament={tournament}
                  keyValue="rules"
                />
              )}
              {/* Schedule Tab */}
              {tournament && currentTab === tabs[3].url && (
                <Schedule
                  tournament={tournament}
                  onSubmit={props.updateTournament}
                  loading={loadingTournament}
                />
              )}
              {/* Appearance Tab */}
              {tournament && currentTab === tabs[4].url && (
                <Appearance
                  tournament={tournament}
                  onSubmit={props.updateTournament}
                  loading={loadingTournament}
                />
              )}
              {/* Rules Tab */}
              {tournament && currentTab === tabs[5].url && (
                <AnnouncementsTab
                  tournament={tournament}
                  onReloadTournament={props.loadTournament}
                ></AnnouncementsTab>
              )}
              {/* System Point */}
              {tournament &&
                currentTab === tabs[6].url &&
                (tournament?.game === EGames.APEX_LEGENDS || (tournament?.tournamentType && tournament?.tournamentType === "lobby")) && (
                  <PointSystemTab
                    tournament={tournament}
                    onSubmit={props.updateTournament}
                  ></PointSystemTab>
                )}
              {/* Generic Info only for custom */}
                {tournament && currentTab === tabs[7].url && (
                <TournamentSettingsInformation
                  tournament={tournament}
                />
              )}
            </div>

            {/* END TABS BODY */}
          </div>
    </>
  );
};

export default TournamentSettings;
