import React from "react";
import CheckboxGroup from "./CheckboxGroup";
import Input from "./Input";
import RadioButtons from "./RadioButtons";
import Select from "./Select";
import TextArea from "./TextArea";
import Switch from "./Switch";
import DatePicker from "./DatePicker";
import IDropdownOption from "../Interfaces/DropdownOption"
import { FormikErrors, FormikTouched, FormikHandlers } from "formik";

type Props = {
  control: string
  label: string
  name: string
  placeholder?: string
  options?: Array<IDropdownOption>
  disabled?: boolean
  type?: string
  dateFormat?: string
  showTimeSelect?: boolean
  errors?: FormikErrors<any>
  touched?: FormikTouched<any>
  isValid?: boolean
  rows?: number | string
}

const FormikControl = (props:Props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <TextArea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "switch":
      return <Switch {...rest} />;
    case 'date':
        return <DatePicker {...rest} />
    default:
      return null;
  }
}

export default FormikControl;
