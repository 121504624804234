import React, { useEffect, useState, useRef } from "react";
import UserProfile from "../../UiLibrary/UserProfile";
import { toast } from "react-toastify";
import UserGameAccount from "../../UiLibrary/User/UserGameAccount";
import ErrorLabel from "../../Forms/ErrorLabel";
import Modal from "../../UiLibrary/Modal";
import AddSingleGameAccountModal from "../AddGameAccountModal/AddSingleGameAccountModal";
import GameTile from "../../UiLibrary/GameTile";
import { Link } from "react-router-dom";
import ITournament from "../../Interfaces/Tournament";
import IGameAccount from "../../Interfaces/GameAccount";
import GAMES from "../../../constants-games";
import IUserDetails from "../../Interfaces/UserDetails";
import TeamColumn from "../../UiLibrary/Team/TeamColumn";
import UserSearchDropdown from "../../UiLibrary/User/UserSearchDropdown";

type Props = {
  tournament: ITournament;
  loading: boolean;
  user: IUserDetails | null;
  onConfirm: (members: Array<string>, data: any) => void
};

const JoinTournamentWithTeam = (props:Props) => {
  const { tournament, loading, user } = props;
  const [members, setMembers] = useState<Array<string>>([]);
  const [passwordType, setPasswordType] = useState("password");
  const [body, setBody] = useState<{
    partyName: string | undefined;
    gameAccounts: Array<{gameAccount:IGameAccount, userId:string}>;
  }>({ partyName: undefined, gameAccounts: [] });
  const [isValid, setIsValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [gameAccounts, setGameAccounts] = useState<Array<IGameAccount>>([]);

  let game = GAMES[tournament?.game].ENUM;

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const teamNameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (members.length <= 0) {
      addMember(user?.id!, user!)
    }
  },[])

  useEffect(() => {
    if (user?.gameAccounts && user?.gameAccounts?.length > 0) {
      const gameAccount = user?.gameAccounts?.filter(
        (account: IGameAccount) =>
          tournament?.console.includes(account.console || "") &&
          account.game === tournament?.game
      );
      const gameAccounts = [...gameAccount];

      setGameAccounts(gameAccounts);
    }
    return () => {};
  }, [user, tournament]);
  
  const inviteUser = (theUser: IUserDetails) => {
    addMember(theUser.id!, theUser)
    setShowAddPlayerModal(false)
  };

  const addMember = (member: string, user: IUserDetails) => {
    const gameAccount = user.gameAccounts?.filter(
      (account: IGameAccount) =>
        tournament?.console.includes(account.console || "") &&
        account.game === GAMES[game].ENUM
    );

    let newBody = { ...body };

    if (members.includes(member)) {
      const filtered = members.filter(function (value) {
        return value !== member;
      });
      const filteredGameAccounts = newBody.gameAccounts.filter(
        (account: {gameAccount:IGameAccount, userId:string}) => account.userId !== member
      );
      newBody.gameAccounts = filteredGameAccounts;
      setMembers(filtered);
    } else {
      if (members.length < availableSlotsNumber) {
        if (user?.id && gameAccount) {
          setMembers([...members, member]);

          newBody.gameAccounts.push({
            userId: user.id,
            gameAccount: gameAccount[0]
          });
        }
      } else {
        toast.error("No more slots available");
      }
    }

    setBody(newBody);
  };

  const removeMember = (e:React.MouseEvent , member:string) => {
    e.preventDefault();
    let newBody = { ...body };
    const filtered = members.filter(function (value, index, arr) {
      return value !== member;
    });
    const filteredGameAccounts = newBody.gameAccounts.filter(
      (account) => account.userId !== member
    );
    newBody.gameAccounts = filteredGameAccounts;
    setMembers(filtered);
    setBody(newBody);
  };

  const changeAccount = (selectedAccount:IGameAccount, user: IUserDetails) => {
    let newBody = { ...body };
    let newAccount = newBody.gameAccounts.find(
      (account) => account.userId === user.id
    );
    if(newAccount){
      newAccount.gameAccount = selectedAccount;
    }
    
    setBody(newBody);
  };

  const onConfirm = () => {
    let data:{partyName: string | undefined, password?:string, gameAccounts:Array<{gameAccount:IGameAccount, userId:string}>} = body;
    data.password = passwordInputRef?.current?.value;
    data.partyName = teamNameInput?.current?.value;
    props.onConfirm(members, data);
  };

  const nameBlurData = () => {
    if (teamNameInput?.current?.value.trim() === "") {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  };

  const availableSlotsNumber = tournament?.reserves
    ? Number(tournament?.teamSize) + tournament?.reserves
    : Number(tournament?.teamSize) * 2;

  const availableSlots = [...Array(availableSlotsNumber)].map(
    (elementInArray, index) => (
      <div className="column col-12" key={index}>
        <dd
          key={index}
          className={`column col-12 ${!members[index] }`}
        >
          <div className="columns">
            <div className="column col-11">
              {members[index] && (
                <UserProfile userId={members[index]}>
                  <UserGameAccount
                    userId={members[index]}
                    showGameAccount={{
                      console: tournament?.console,
                      game: game,
                      selected: body?.gameAccounts?.find(
                        (account) => account?.userId === members[index]
                      )?.gameAccount,
                    }}
                    onChangeAccount={changeAccount}
                  />
                  {index === 0 && (
                    <span className="skp-chip status-captain skp-text-small">
                      Leader
                    </span>
                  )}
                </UserProfile>
              )}
              {!members[index] && (
                <UserProfile userId={0} >
                  <span className="skp-text-small skp-text-light" onClick={() => setShowAddPlayerModal(true)}>
                    Click to choose a member!
                  </span>
                </UserProfile>
              )}
            </div>
            <div className="column col-1 flex-centered">
              {members[index] && index != 0 &&(
                <div className="form-group">
                  <label
                    className="form-checkbox"
                    onClick={(e) => removeMember(e, members[index])}
                  >
                    <input type="checkbox" checked readOnly />
                    <i className="form-icon"></i>
                  </label>
                </div>
              )}
            </div>
          </div>
        </dd>
        {index === (Number(tournament?.teamSize) - 1) && (
          <dd>
            <h6 className="skp-text-primary">
              {tournament?.teamSize}vs{tournament?.teamSize} Reserve Team
            </h6>
          </dd>
        )}
      </div>
    )
  );

  return (
    <>
      {gameAccounts?.length > 0 && (
        <>
          <div className="container">
            <div className="space-divider-20"></div>
            <div className="columns">
              {/* Left Column */}
              <div className="column col-6 col-xs-12">
                <TeamColumn onAddMember={addMember} tournament={tournament} game={game} members={members} />
              </div>

              <div className="column col-1 hide-xs"></div>

              {/* Right Column */}
              <div className="column col-5 col-xs-12">
                <div
                  className={`skp-form-control ${
                    !isValid ? "skp-form-control-error" : ""
                  }`}
                >
                  <label
                    htmlFor="teamName"
                    className="skp-text-small skp-text-primary"
                  >
                    Team Name
                  </label>
                  <input
                    id="teamName"
                    name="teamName"
                    ref={teamNameInput}
                    placeholder="Choose a team name"
                    onBlur={nameBlurData}
                    onChange={nameBlurData}
                  />
                  {!isValid && <ErrorLabel>Please add a team name</ErrorLabel>}
                </div>

                <div className="space-divider-20"></div>

                <h6 className="skp-text-primary">
                  {tournament?.teamSize}vs{tournament?.teamSize} Lineup
                </h6>

                <dl className="columns bordered-list">{availableSlots}</dl>
              </div>
            </div>

            <div className="space-divider"></div>

            <div className="columns flex-centered">
              <div className="column col-6 col-xs-12">
                {tournament?.open === false && (
                  <div className="skp-form-control">
                    <label className="skp-text-label skp-text-light">
                      Please provide a password to join.
                    </label>
                    <input ref={passwordInputRef} type={passwordType} />
                    <div className="icon-wrapper">
                      {passwordType === "password" && (
                        <i
                          className="lar la-eye skp-text-light"
                          onClick={() => setPasswordType("text")}
                        ></i>
                      )}
                      {passwordType === "text" && (
                        <i
                          className="lar la-eye-slash skp-text-light"
                          onClick={() => setPasswordType("password")}
                        ></i>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="column col-6 col-xs-12 text-right">
                <button
                  className="skp-btn skp-btn-primary"
                  onClick={onConfirm}
                  disabled={
                    loading || Number(members.length) < Number(tournament?.teamSize) || !isValid
                  }
                >
                  {loading ? (
                    <span className="loading"></span>
                  ) : (
                    "Join Tournament"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {gameAccounts?.length === 0 && (
        <>
          <div className="container text-center">
            <div className="space-divider-20"></div>
            <div className="columns">
              <div className="column col-12">
                <h5 className="skp-text-primary">
                  Please add a game account to start
                </h5>
                <div className="space-divider-20"></div>
                <div
                  className="skp-link-primary skp-text-large"
                  onClick={() => setShowModal(true)}
                >
                  <i
                    className="las la-plus-circle"
                    style={{ fontSize: 20 }}
                  ></i>{" "}
                  Add an account for {GAMES[tournament?.game].NAME}
                </div>
              </div>
              <div className="column col-12 flex-centered">
                <div
                  style={{ width: "20%" }}
                  onClick={() => setShowModal(true)}
                >
                  <GameTile
                    game={GAMES[game]}
                    disableLink
                    hideName
                  ></GameTile>
                </div>
              </div>
              <div className="space-divider"></div>
              <div className="column col-12">
                <div className="skp-text-label skp-text-light">
                  To manage your game accounts go to the{" "}
                  <Link
                    className="skp-link"
                    to={`/users/${user?.id}/settings/usersettings`}
                  >
                    User Setting's page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Add account modal */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Add Game Account"
        size="large"
      >
        <AddSingleGameAccountModal
          tournament={tournament}
          closeModal={() => setShowModal(false)}
        ></AddSingleGameAccountModal>
      </Modal>

      <Modal
        size="small"
        title="Add Player"
        show={showAddPlayerModal}
        onClose={() => setShowAddPlayerModal(false)}
      >
        <UserSearchDropdown setUser={inviteUser} />
      </Modal>
    </>
  );
};

export default JoinTournamentWithTeam;
