import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Scrollbars } from "react-custom-scrollbars";
import ITournament from "../../../Interfaces/Tournament";
import SeedTournamentModal from "../../../Modals/SeedTournamentModal/SeedTournamentModal";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament,
  memberStatus: ITournamentMemberStatus
  onReloadTournament: (tournament?: ITournament) => void
  updateStatus: (status: number) => void | undefined
  deleteTournament: () => void
}

const BracketsControlPanel = (props:Props) => {
  const { tournament, memberStatus } = props;
  const { t } = useTranslation()
  let navigate = useNavigate();
  
  const tournamentId = useParams<{id:string}>().id;
  const goToSchedule = () => {
   navigate(`/tournaments/${tournamentId}/settings/schedule`);
  };

  let status = tournament?.status;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;

  const hasMatches = tournament?.matches?.length > 0;

  const today = new Date();
  const registration = new Date(tournament?.end_registration);
  const registrationClosed = registration < today;
  const checkin = new Date(tournament?.end_checkin);
  const checkinClosed = checkin < today

  useEffect(()=>{
    status = tournament?.status;
    onGoingTournament = status === 1;
    completedTournament = status === 2;
  },[tournament])

  return (
    <div className="skp-right-sidebar">
      <Scrollbars>
        <div className="container p-2">
          <div className="columns p-2">
            <div className="column col-12">
              <h4 className="skp-text-primary">{t('sidePanel.controlPanel')}</h4>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.runControlPanel')}
              </div>
            </div>
          </div>

          <div className="columns p-2">
            <div className="column col-12">
              <h5
                className={
                  onGoingTournament || completedTournament
                    ? "column col-12 skp-text-light"
                    : "column col-12 skp-text-primary"
                }
              >
                {t('sidePanel.scheduleTournament')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.scheduleTournamentDesc')}
              </div>
              <div className="space-divider-10"></div>
              <button
                disabled={onGoingTournament || completedTournament}
                className="skp-btn skp-btn-round skp-btn-primary"
                onClick={goToSchedule}
              >
                {t('sidePanel.schedule')}
              </button>
            </div>

            <div className="column col-12">
              <div className="space-divider-20"></div>
              <h5
                className={
                  !registrationClosed ||
                  !checkinClosed ||
                  onGoingTournament ||
                  completedTournament ||
                  hasMatches 
                    ? "skp-text-light"
                    : "skp-text-primary"
                }
              >
                {t('sidePanel.seedTournament')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.registrationClosedMessage')}
              </div>
              <div className="space-divider-10"></div>
              <SeedTournamentModal userStatus={memberStatus} tournament={tournament} onReloadTournament={props.onReloadTournament} />
            </div>

            <div className="column col-12">
              <div className="space-divider-20"></div>
              <h5
                className={
                  hasMatches && !onGoingTournament && !completedTournament
                    ? "skp-text-primary"
                    : "skp-text-light"
                }
              >
                3. {t('sidePanel.startTournament')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('messages.tournamentOngoing')}
              </div>
              <div className="space-divider-10"></div>
              <button
                disabled={
                  onGoingTournament ||
                  completedTournament ||
                  tournament?.members.length === 0 ||
                  !hasMatches
                }
                className="skp-btn skp-btn-round skp-btn-primary"
                onClick={() => props.updateStatus(1)}
              >
                {t('status.start')}
              </button>
            </div>

            <div className="column col-12">
              <div className="space-divider-20"></div>
              <h5
                className={
                  onGoingTournament ? "skp-text-primary" : "skp-text-light"
                }
              >
                4. {t('sidePanel.completeTournament')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.tournamentCompleted')}
              </div>
              <div className="space-divider-10"></div>
              <button
                disabled={
                  !onGoingTournament ||
                  tournament?.members.length === 0 ||
                  !hasMatches ||
                  !registrationClosed
                }
                className="skp-btn skp-btn-round skp-btn-primary"
                onClick={() => props.updateStatus(2)}
              >
                {t('status.complete')}
              </button>
            </div>

            <div className="space-divider"></div>

            <div className="column col-12">
              {
                <button
                  className="skp-btn skp-btn-alert-border"
                  onClick={props.deleteTournament}
                >
                  {t('sidePanel.deleteTournament')}
                </button>
              }
            </div>
          </div>
        </div>
      </Scrollbars>
      
    </div>
  );
};

export default BracketsControlPanel;
