import React, { useEffect, useState, useContext } from "react";
import { SeedTeam, SeedItem } from "react-brackets";
import { TournamentMatchesContext } from "../../../../../Context/TournamentMatchesContext";
import MatchTeamName from "../../../../../UiLibrary/MatchComponents/MatchTeamName";
import IMatchTournamentSeed from "../../../../../Interfaces/MatchTournamentSeed";
import IMatch from "../../../../../Interfaces/Match";
// import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import PlayPlayUser from "../UserComponents/PlayPlayUser";
import MatchResultService from "../../../../../../services/MatchResultService";

type Props = {
  matchId: string;
  seed: IMatchTournamentSeed;
  visible: boolean;
};

function SingleMatch(props: Props) {
  const { matchId, seed, visible } = props;

  const [match, setMatch] = useState<IMatch>();
  const [winner, setWinner] = useState<string>();
  const [multipleMatchResult, setMultipleMatchResult] = useState<{winner: string | undefined, teamHomeScores: number[], teamAwayScores:number[]}>()

  const [alreadyLoaded, setLoaded] = useState(false);
  const { tournamentMatches } = useContext(TournamentMatchesContext);

  const multipleMatch = seed?.matchesId?.length > 1;

  const mainPlayerHome = match?.result?.scores[0].playerResults
    ? match?.result?.scores[0]?.playerResults[0]?.playerId
    : null;
  const mainPlayerAway = match?.result?.scores[1].playerResults
    ? match?.result?.scores[1]?.playerResults[0]?.playerId
    : null;

  const playerHomeWinner =
    match?.result?.scores[0] &&
    match?.result?.scores[0].score > match?.result?.scores[1].score;
  const playerAwayWinner =
    match?.result?.scores[0] &&
    match?.result?.scores[0].score < match?.result?.scores[1].score;

  useEffect(() => {
    const foundMatch = tournamentMatches?.matches?.find((match) => {
      return match.id === matchId;
    });
    
    setWinner(MatchResultService.getMultipleMatchWinner(tournamentMatches?.matches, seed).winner);
    setMultipleMatchResult(MatchResultService.getMultipleMatchWinner(tournamentMatches?.matches, seed));
 
    if (!alreadyLoaded && visible) {
      setMatch(foundMatch);
    }

    if (foundMatch && visible) {
      setLoaded(true);
    }
  }, [matchId, alreadyLoaded, tournamentMatches, visible, seed]);

  return (
    <SeedItem className="skp-brackets-seed-item">
      {match && (
        <div className="skp-brackets-seed-item-team">
          <SeedTeam className={`skp-brackets-seed-item-team-home`}>
            {/* {KeyCloackService.isLoggedIn() && ( */}
              <>
                {tournamentMatches?.tournament?.playerType === 0 && (
                  <>
                    <div className="user-profile">
                      <PlayPlayUser playerId={mainPlayerHome} hideCheckin={true}></PlayPlayUser>
                      {mainPlayerHome === winner && (<span className="skp-text-xxsmall skp-text-ternary">
                      <img
                        alt="logo-winner"
                        style={{ width: 24, marginRight: 4, marginLeft: -4 }}
                        src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                      />
                        </span>
                      )}
                    </div>
                    
                  </>
                )}
                {tournamentMatches?.tournament?.playerType === 1 && (
                  <MatchTeamName
                    teamId={match?.result?.scores[0].teamId}
                    tournament={tournamentMatches?.tournament}
                    hideTeamLabel={true}
                  ></MatchTeamName>
                )}
              </>
            {/* )} */}

            {/* {!KeyCloackService.isLoggedIn() && (
              <div className="user-profile">
                <figure className="avatar">
                  <span className="text-uppercase">
                    <i className="las la-user"></i>
                  </span>
                </figure>
              </div>
            )} */}
            {!multipleMatch && (
              <div className={playerHomeWinner ? "skp-text-quaternary" : "player-score"}>
                {match?.result?.scores[0].score}
              </div>
            )}
            {multipleMatch && (
              <div>
                <span className="player-score">{multipleMatchResult?.teamHomeScores.length}</span>
              </div>
            )}
          </SeedTeam>

          <SeedTeam className={`skp-brackets-seed-item-team-away`}>
            {/* {KeyCloackService.isLoggedIn() && ( */}
              <>
                {tournamentMatches?.tournament?.playerType === 0 && (
                  <>
                    <div className="user-profile">
                      <PlayPlayUser playerId={mainPlayerAway} hideCheckin={true}></PlayPlayUser>
                      {mainPlayerAway === winner && (<span className="skp-text-xxsmall skp-text-ternary">
                      <img
                        alt="logo-winner"
                        style={{ width: 24, marginRight: 4, marginLeft: -4 }}
                        src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                      />
                        </span>
                      )}
                    </div>
                    
                  </>
                )}
                {tournamentMatches?.tournament?.playerType === 1 && (
                  <MatchTeamName
                    teamId={match?.result?.scores[1].teamId}
                    tournament={tournamentMatches?.tournament}
                    hideTeamLabel={true}
                  ></MatchTeamName>
                )}
              </>
            {/* )} */}
            {/* {!KeyCloackService.isLoggedIn() && (
              <div className="user-profile">
                <figure className="avatar">
                  <span className="text-uppercase">
                    <i className="las la-user"></i>
                  </span>
                </figure>
              </div>
            )} */}
            {!multipleMatch && (
              <div className={playerAwayWinner ? "skp-text-quaternary" : "player-score"}>
                {match?.result?.scores[1].score}
              </div>
            )}
            
            {multipleMatch && (
              <div>
                <span className="player-score">{multipleMatchResult?.teamAwayScores.length}</span>
              </div>
            )}
          </SeedTeam>
        </div>
      )}
      {!match && (
        <div className="skp-brackets-seed-item-team">
          <SeedTeam className="skp-brackets-seed-item-team-home">
            {/* <UserProfileWithDetailsModal
              userId={undefined}
              tournament={tournamentMatches?.tournament}
            /> */}
            <PlayPlayUser playerId={undefined} hideCheckin={true}></PlayPlayUser>
          </SeedTeam>
          <SeedTeam className="skp-brackets-seed-item-team-away">
            {/* <UserProfileWithDetailsModal
              userId={undefined}
              tournament={tournamentMatches?.tournament}
            /> */}
            <PlayPlayUser playerId={undefined} hideCheckin={true}></PlayPlayUser>
          </SeedTeam>
        </div>
      )}
    </SeedItem>
  );
}

export default SingleMatch;
