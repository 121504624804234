import { useState, useEffect } from "react";
import UserService from "../../services/UserService";

const ProfileName = (props) => {
    const { owner } = props
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        if(owner) {
            UserService.getUser(owner).then(Ruser => {
                setUserInfo(Ruser);
            })
        }
    },[owner]);
    
    return (
        <span className="skp-text-primary"><strong>{userInfo?.username}</strong> </span>
    )
}

export default ProfileName