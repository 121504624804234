import { useContext, useEffect, useState } from "react"
import ITournament from "../../../../Interfaces/Tournament"
import ITournamentLobby from "../../../../Interfaces/TournamentLobby"
import ILobbyGame from "../../../../Interfaces/LobbyGame"
import LobbyGameCard from "../Components/LobbyGameCard"
import { UserContext } from "../../../../Context/UserContext"

type Props = {
    tournament: ITournament
    lobby: ITournamentLobby
}
  
const LobbyGameTab = ({ lobby, tournament, ...props }:Props) => {
    const [games, setGames] = useState<Array<ILobbyGame>>([]);
    const { user, setUser } = useContext(UserContext);
    
    let lobbyGames = lobby.games;
    const isMember = lobby?.teams && lobby.teams.some((team) => team.members.find((member) => member.userId === user?.id));
    const isAdmin =
        tournament?.owner === user?.id ||
        tournament?.admins?.includes(user?.id!);
  
    useEffect(() => {
        setGames(lobbyGames ? lobbyGames : []);
    
        return () => {
          setGames([]);
        }
    }, [lobbyGames]);

    return (
        <>
            {games?.length === 0 && (
                <>
                    <div className="column col-12 skp-text-light text-center">
                        <h1>
                            <i className="las la-chess"></i>
                        </h1>
                    </div>
                    <div className="column col-12 skp-text-light text-center">
                        <h5>No games</h5>
                    </div>
                    <div className="column col-12 skp-text-light text-center">
                        There are no games available yet.
                    </div>
                </>
            )}
            {games?.length > 0 && (
                <>
                    <div className="columns">
                        {games.map((game, index) => (
                            <LobbyGameCard
                                game={game}
                                index={index}
                                tournament={tournament}
                                lobby={lobby}
                                isMember={isMember}
                                isAdmin={isAdmin}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    )
};

export default LobbyGameTab;
