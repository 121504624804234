import React, { useContext, useState } from "react";
import UserProfile from "../../../UiLibrary/UserProfile";
import UploadImageBox from "../../../UiLibrary/UploadImageBox/UploadImageBox";
import Modal from "../../../UiLibrary/Modal";
import ImageService from "../../../../services/ImageService";
import MatchService from "../../../../services/MatchService";
import { toast } from "react-toastify";
import ReactTimeAgo from "react-time-ago";
import LoadingButton from "../../../UiLibrary/LoadingButton/LoadingButton";
import Moment from "moment";
import IMatch from "../../../Interfaces/Match";
import ITournament from "../../../Interfaces/Tournament";
import { UserContext } from "../../../Context/UserContext";
import IFileBase64Image from "../../../Interfaces/FileBase64Image";
import { useTranslation } from "react-i18next";

type Props = {
  match: IMatch | undefined
  tournament: ITournament
  matchId: string
  canEditMatch: boolean
  canWatchInfo: boolean
  onSetMatch: (match: IMatch) => void
  isPlayerHome: boolean
  isPlayerAway: boolean
}

interface IScreenshotModal {
  show: boolean
  imgSrc?: string | undefined
  playerId?: string | undefined
  mediaId?: string | undefined
  timestamp?: string | undefined
}

const ScreenshotsTab = ({ match, canEditMatch, canWatchInfo, matchId, tournament, onSetMatch, isPlayerHome, isPlayerAway }:Props) => {
  const {user} = useContext(UserContext);
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [screenshot, setScreenshot] = useState<IFileBase64Image>();
  const [showUploadScreenshot, setShowUploadScreenshot] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [showScreenShotModal, setShowScreenShotModal] = useState<IScreenshotModal | undefined>({
    show: false,
    imgSrc: undefined,
    playerId: "1",
    mediaId: undefined,
    timestamp: undefined
  });

  let teamId:string | undefined

  if (isPlayerHome) {
    teamId = match?.result?.scores[0].teamId;
  } else if (isPlayerAway) {
    teamId = match?.result?.scores[1].teamId;
  }
  const isTournamentAdmin: boolean = tournament?.admins?.includes(user?.id!);
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

  const canUploadScreenshot =
    (isPlayerAway || isPlayerHome || isAdmin) &&
    (match?.status === 1 || match?.status === 2); 

  const emptyPlayerMedia =
    (!match?.result?.scores[0].playerMedia ||
      match?.result?.scores[0].playerMedia.length === 0) &&
    (!match?.result?.scores[1].playerMedia ||
      match?.result?.scores[1].playerMedia.length === 0);

  const emptyAdminMedia = !match?.adminMedia || match?.adminMedia.length === 0

  const uploadScreenshot = () => {
    if(!screenshot) {
      return
    }

    setImageUploading(true);
    ImageService.uploadImage(screenshot).then((response) => {
      const data = {
        mediaUrl: ImageService.generatePublicUrl(response.data),
      };

      if (isAdmin && !isPlayerAway && !isPlayerHome ) {
        MatchService.updateAdminScreenshot(matchId, user?.id, data)
          .then((response) => {
            onSetMatch(response.data.data);
            setShowUploadScreenshot(false);
            setImageUploading(false);
            setScreenshot(undefined);
          })
          .catch((error) => {
            setImageUploading(false);
            setShowUploadScreenshot(false);
            toast.error(t('admin.errorUploadingImage'));
            setScreenshot(undefined);
          });
      } else {
        MatchService.updateMatchScoreMedia(matchId, teamId, user?.id, data)
          .then((response) => {
            onSetMatch(response.data.data);
            setShowUploadScreenshot(false);
            setImageUploading(false);
            setScreenshot(undefined);
          })
          .catch((error) => {
            setImageUploading(false);
            setShowUploadScreenshot(false);
            toast.error(t('admin.errorUploadingImage'));
            setScreenshot(undefined);
          });
        }
      });
  };

  const deleteMedia = () => {
    setLoading(true);
    
    MatchService.deleteMedia(
      matchId,
      showScreenShotModal?.playerId,
      showScreenShotModal?.mediaId
    )
      .then((response) => {
        onSetMatch(response.data.data);
        setLoading(false);
        setShowScreenShotModal({ show: false });
        toast.info(t('admin.screenshotRemovedSuccess'));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="columns tabs-body">
      {/* Header */}
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h5 className="skp-text-primary">{t('admin.resultScreenshots')}</h5>
        {canUploadScreenshot && (
          <a
            onClick={() => setShowUploadScreenshot(!showUploadScreenshot)}
            className="skp-text-accent skp-text-default"
          >
            {!showUploadScreenshot ? t('admin.uploadScreenshot') : t('admin.seeScreenshots')}
          </a>
        )}
      </div>
      {/* Body */}
      {canWatchInfo && (
        <>
          {/* No screenshots */}
          <div className="column col-12 text-center">
            <div className="space-divider-20"></div>
            {emptyPlayerMedia && emptyAdminMedia && !showUploadScreenshot && (
              <div className="skp-text-light">
                <h2>
                  <i className="lar la-image"></i>
                </h2>
                <span>{t('admin.noScreenshotsUploaded')}</span>
              </div>
            )}
          </div>
          {/* Screenshots and upload */}
          <div className="column col-12">
            {!showUploadScreenshot && !emptyAdminMedia && 
              match.adminMedia.map((media, index) => (
                <>
                {media.mediaUrl && (
                  <div className="uploaded-image-wrapper">
                    <UserProfile
                      userId={media.playerId}
                    >
                      {media.playerId === tournament?.owner ? (
                        <span className="skp-chip skp-text-xxsmall status-captain">
                          {t('common.owner')}
                        </span>
                      ) : (
                        <span className="skp-chip skp-text-xxsmall status-captain">
                          {t('common.admin')}
                        </span>
                      )}
                    </UserProfile>
                    <div className="m-2"></div>
                    <div className="file-base64-label-wrapper no-border">
                      <div
                        className="file-image-preview"
                        onClick={() =>
                          setShowScreenShotModal({
                            show: true,
                            imgSrc: media.mediaUrl,
                            playerId: media.playerId,
                            mediaId: media.id,
                            timestamp: media.timestamp,
                          })
                        }
                      >
                        <img alt={`s-${index}`} src={media?.mediaUrl} />
                      </div>
                    </div>

                    <div className="skp-text-small skp-text-light">
                      {Moment(media?.timestamp).format("MMM DD, hh:mm A")}{" "}
                      <br></br>{" "}
                      {media?.timestamp && <span className="skp-text-xxsmall">
                        <ReactTimeAgo date={media.timestamp} />
                      </span>}
                    </div>
                    <div className="m-2"></div>
                  </div>
                )}
                </>
              )
            )}
            {!showUploadScreenshot &&
              match?.result?.scores.map((score, index) => {
                return (
                  <div key={index} className="float-left">
                    {score.playerMedia &&
                      score.playerMedia.map((media) => (
                        <>
                          {media.mediaUrl && (
                            <div className="uploaded-image-wrapper">
                              <UserProfile
                                userId={score.playerResults[0].playerId}
                              />
                              <div className="m-2"></div>
                              <div className="file-base64-label-wrapper no-border">
                                <div
                                  className="file-image-preview"
                                  onClick={() =>
                                    setShowScreenShotModal({
                                      show: true,
                                      imgSrc: media.mediaUrl,
                                      playerId: media.playerId,
                                      mediaId: media.id,
                                      timestamp: media.timestamp,
                                    })
                                  }
                                >
                                  <img alt={`s-${index}`} src={media?.mediaUrl} />
                                </div>
                              </div>

                              <div className="skp-text-small skp-text-light">
                                {Moment(media?.timestamp).format("MMM DD, hh:mm A")}{" "}
                                <br></br>{" "}
                                {media?.timestamp && <span className="skp-text-xxsmall">
                                  <ReactTimeAgo date={media.timestamp} />
                                </span>}
                              </div>
                              <div className="m-2"></div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                );
              })}

            {showUploadScreenshot && canUploadScreenshot && (
              <div className="file-base64-label-wrapper">
                <UploadImageBox
                  onUpload={(image) => setScreenshot(image)}
                  onRemovePreview={() => setScreenshot(undefined)}
                />
              </div>
            )}
          </div>
        </>
      )}
      {!canWatchInfo && (
        <div className="column col-12">
          <div className="columns">
            <div className="column col-12">
              <div className="columns skp-text-light text-center">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h1>
                    <i className="las la-angry"></i>
                  </h1>
                </div>
                <div className="column col-12">
                  {t('messages.notAllowedSeeMatch')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUploadScreenshot && canEditMatch && (
        <div className="column col-12">
          <div className="space-divider-5"></div>
          <button
            disabled={!screenshot || imageUploading}
            onClick={uploadScreenshot}
            className="skp-btn skp-btn-primary"
          >
            {imageUploading ? (
              <span className="loading"></span>
            ) : (
              t('admin.uploadScreenshot')
            )}
          </button>
        </div>
      )}

      <Modal
        show={showScreenShotModal?.show}
        onClose={() => setShowScreenShotModal({ show: false })}
        size="large"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 flex-centered">
              <div
                className="screenshot-preview"
                style={{
                  "background-image": `url(${showScreenShotModal?.imgSrc})`,
                } as React.CSSProperties}
              />
            </div>
          </div>
          <div className="space-divider-20"></div>
          <div className="columns">
            <div className="column col-7 col-xs-12">
              <UserProfile userId={showScreenShotModal?.playerId}>
                <span className="skp-text-light skp-text-small">
                  {showScreenShotModal?.timestamp && <ReactTimeAgo date={showScreenShotModal.timestamp} />} {" - "}
                  {Moment(showScreenShotModal?.timestamp).format("MMM DD, hh:mm A")}
                </span>
              </UserProfile>
            </div>
            {canEditMatch && match?.status !== 3 && (
            <div
              className="column col-5 flex-centered col-xs-12"
              style={{ justifyContent: "right" }}
            >
              {(showScreenShotModal?.playerId === user?.id ||
                tournament?.owner === user?.id) && (
                <LoadingButton
                  className="skp-btn skp-btn-alert"
                  label={t('admin.deleteMedia')}
                  onBtnClick={deleteMedia}
                  loading={loading}
                  disabled={loading}
                />
              )}
            </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScreenshotsTab;
