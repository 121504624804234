import { useState, useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import * as Yup from "yup";
import UserService from "../../../../services/UserService";
import ErrorLabel from "../../../Forms/ErrorLabel";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  first_name: Yup.string().nullable(),
  last_name: Yup.string().nullable(),
  birth_date: Yup.date().nullable(),
  street_name: Yup.string().nullable(),
  additional_address_info: Yup.string().nullable(),
  zip_code: Yup.string().nullable(),
  city: Yup.string().nullable(),
  country: Yup.string().nullable(),
  phone_number: Yup.string().nullable(),
});

type FormModel = {
  first_name?: string;
  last_name?: string;
  birth_date?: Date | null;
  street_name?: string;
  additional_address_info?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  phone_number?: string;
};

const PersonalInformationForm = () => {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);

  const initialValues: FormModel = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    birth_date: user?.birth_date ? new Date(user?.birth_date) : null,
    street_name: user?.street_name,
    additional_address_info: user?.additional_address_info,
    zip_code: user?.zip_code,
    city: user?.city,
    country: user?.country,
    phone_number: user?.phone_number,
  };

  const onSubmit = (values: FormModel) => {
    setLoading(true);
    const userId = user?.id;
    UserService.updateUserInfo(values, userId)
      .then((user) => {
        setLoading(false);
        setUser(user);
        toast.success(t('toast.userInfoUpdate'));
      })
      .catch((error) => {
        toast.error(t('toast.errorUpdateUserInformation'));
        setLoading(false);
      });
  };

  return (
    <div className="column col-12">
      <div className="space-divider-20"></div>
      {user && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <h6 className="skp-text-light text-bold">{t('user.config.fullname')}</h6>
              <div className="columns">
                <div className="column col-6">
                  {/* First Name */}
                  <div
                    className={
                      errors.first_name && touched.first_name
                        ? "skp-form-control skp-form-control-error"
                        : "skp-form-control"
                    }
                  >
                    <label
                      className="skp-text-light skp-text-input-label"
                      htmlFor="first_name"
                    >
                      {t('user.config.firstname')}
                    </label>
                    <Field type="text" id="first_name" name="first_name" />
                    <ErrorMessage name="first_name" component={ErrorLabel} />
                  </div>
                </div>
                <div className="column col-6">
                  {/* Last Name */}
                  <div
                    className={
                      errors.last_name && touched.last_name
                        ? "skp-form-control skp-form-control-error"
                        : "skp-form-control"
                    }
                  >
                    <label
                      className="skp-text-light skp-text-input-label"
                      htmlFor="last_name"
                    >
                      {t('user.config.lastname')}
                    </label>
                    <Field type="text" id="last_name" name="last_name" />
                    <ErrorMessage name="last_name" component={ErrorLabel} />
                  </div>
                </div>
              </div>

              <div className="space-divider-20"></div>
              <h6 className="skp-text-light text-bold">{t('user.config.birthDate')}</h6>
              {/* Birth Date */}
              <div
                className={
                  errors.birth_date && touched.birth_date
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="birth_date"
                >
                  {t('user.config.birthDate')}
                </label>
                <Field name="birth_date">
                  {({ form, field }:FieldProps) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    return (
                      <DatePicker
                        id="birth_date"
                        name="birth_date"
                        dateFormat="dd/MM/yyyy"
                        selected={value}
                        onChange={(date) => setFieldValue("birth_date", date)}
                      />
                    );
                  }}
                </Field>
                <ErrorMessage name="birth_date" component={ErrorLabel} />
              </div>

              <div className="space-divider-20"></div>
              <h6 className="skp-text-light text-bold">{t('user.config.address')}</h6>
              {/* Street Name */}
              <div
                className={
                  errors.street_name && touched.street_name
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="street_name"
                >
                  {t('user.config.street')}
                </label>
                <Field type="text" id="street_name" name="street_name" />
                <ErrorMessage name="street_name" component={ErrorLabel} />
              </div>

              {/* Additional Address Info */}
              <div
                className={
                  errors.additional_address_info &&
                  touched.additional_address_info
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="additional_address_info"
                >
                  {t('user.config.additionalAddress')}
                </label>
                <Field
                  type="text"
                  id="additional_address_info"
                  name="additional_address_info"
                />
                <ErrorMessage
                  name="additional_address_info"
                  component={ErrorLabel}
                />
              </div>

              <div className="columns">
                <div className="column col-2">
                  {/* Zip Code */}
                  <div
                    className={
                      errors.zip_code && touched.zip_code
                        ? "skp-form-control skp-form-control-error"
                        : "skp-form-control"
                    }
                  >
                    <label
                      className="skp-text-light skp-text-input-label"
                      htmlFor="zip_code"
                    >
                      {t('user.config.zipCode')}
                    </label>
                    <Field type="text" id="zip_code" name="zip_code" />
                    <ErrorMessage name="zip_code" component={ErrorLabel} />
                  </div>
                </div>
                <div className="column col-10">
                  {/* City */}
                  <div
                    className={
                      errors.city && touched.city
                        ? "skp-form-control skp-form-control-error"
                        : "skp-form-control"
                    }
                  >
                    <label
                      className="skp-text-light skp-text-input-label"
                      htmlFor="city"
                    >
                      {t('user.config.city')}
                    </label>
                    <Field type="text" id="city" name="city" />
                    <ErrorMessage name="city" component={ErrorLabel} />
                  </div>
                </div>
              </div>

              {/* City */}
              <div
                className={
                  errors.country && touched.country
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="country"
                >
                  {t('user.config.country')}
                </label>
                <Field type="text" id="country" name="country" />
                <ErrorMessage name="country" component={ErrorLabel} />
              </div>

              <div className="space-divider-20"></div>
              <h6 className="skp-text-light text-bold">{t('user.config.contact')}</h6>
              {/* Phone Number */}
              <div
                className={
                  errors.phone_number && touched.phone_number
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="phone_number"
                >
                  {t('user.config.phoneNumber')}
                </label>
                <Field type="text" id="phone_number" name="phone_number" />
                <ErrorMessage name="phone_number" component={ErrorLabel} />
              </div>

              <div className="space-divider"></div>
              <button
                type="submit"
                disabled={!isValid || loading}
                className="skp-btn skp-btn-primary"
              >
                {loading ? <span className="loading"></span> : t('common.save')}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default PersonalInformationForm;
