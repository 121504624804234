import { useState } from "react";
import ITournamentGroupLeaderboard from "../../../Interfaces/TournamentGroupLeaderboard";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentGroup from "../../../Interfaces/TournamentGroup";
import Modal from "../../../UiLibrary/Modal";
import IMatchTournamentSeed from "../../../Interfaces/MatchTournamentSeed";
import MatchesTab from "./MatchesTab";
import TournamentGroupDetails from "../../../Modals/TournamentGroupDetails/TournamentGroupDetails";
import TournamentService from "../../../../services/TournamentService";
import { toast } from "react-toastify";
import UserProfileWithDetailsModal from "../../../UiLibrary/User/UserProfileWithDetailsModal";
import MatchTeamName from "../../../UiLibrary/MatchComponents/MatchTeamName";
import EditTournamentGroupMondal from "../../../Modals/EditTournamentGroupModal/EditTournamentGroupMondal";

type Props = {
  tournament: ITournament;
  onSetMatch: (matchSeed: IMatchTournamentSeed) => void;
  isAdmin?: boolean;
  loadTournament: (tournament: ITournament | undefined) => void;
  showMatchDetailModal?: boolean;
};

const GroupsTab = (props: Props) => {
  const { tournament, isAdmin, showMatchDetailModal } = props;
  const [loading, setLoading] = useState(false);
  const [showMatchesModal, setShowMatchesModal] = useState<{
    show: boolean;
    group?: ITournamentGroup;
    matches?: IMatchTournamentSeed[];
  }>({ show: false });

  const [showEditGroupModal, setShowEditGroupModal] = useState<{
    show: boolean;
    group?: ITournamentGroup;
  }>({ show: false });


  const showDetailsModal = (group: ITournamentGroup) => {
    const matchesByGroup = tournament.matches.filter(
      (match: IMatchTournamentSeed) => match.groupId === group.id
    );
    setShowMatchesModal({ show: true, group: group, matches: matchesByGroup });
  };

  const editGroup = (e: any, group: ITournamentGroup) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEditGroupModal({ show: true, group: group });
  };

  const saveGroup = (group: {
    name: string;
    pointSystem: {
      pointsPerWin: number;
      pointsPerLose: number;
      pointsPerDraw: number;
    };
  }) => {
    if (!showEditGroupModal.group) {
      return;
    }
    setLoading(true);
    TournamentService.editGroup(tournament.id, showEditGroupModal.group.id, {
      name: group.name,
      pointSystem: {
        pointsPerWin: group.pointSystem.pointsPerWin,
        pointsPerLose: group.pointSystem.pointsPerLose,
        pointsPerDraw: group.pointSystem.pointsPerDraw
      }
    })
      .then((RTournament) => {
        setLoading(false);
        toast.success("Group Saved Successfully");
        
        if (!showEditGroupModal.group) {
          return;
        }
        TournamentService.reloadGroupLeaderboard(tournament.id, showEditGroupModal.group.id).then(response => {
          
          setShowEditGroupModal({ show: false });
          response.id = response._id
          props.loadTournament(response);
        }).catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
        
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <div className="container">
        <div className="space-divider-20"></div>
        <div className="columns">
          <div className="column col-12">
            <h5 className="skp-text-primary">Groups</h5>
          </div>
        </div>
        <div className="space-divider-20"></div>
        {!showMatchesModal.show && (
          <div className="columns">
            {tournament.groups?.map((group: ITournamentGroup) => (
              <div className="column col-12" key={group?.id}>
                <div className="skp-card skp-card-hover">
                  <div className="accordion pt-2 pl-2 pr-2">
                    <input
                      type="checkbox"
                      id={`accordion-group-${group?.id}`}
                      name={`group-${group?.id}`}
                      hidden
                    />
                    <label
                      className="accordion-header"
                      htmlFor={`accordion-group-${group?.id}`}
                    >
                      <div className="columns skp-text-primary pt-2">
                        <div className="column col-10">
                          <h5 className="">{group?.name}</h5>
                        </div>
                        <div className="column col-2 skp-text-primary text-right">
                          {isAdmin && (
                            <span
                              className="icon-holder p-2 skp-link-hover"
                              onClick={(e) => editGroup(e, group)}
                            >
                              <i className="las la-pen"></i>
                            </span>
                          )}
                          <span className="icon-holder p-2">
                            <i className="las la-angle-right"></i>
                          </span>
                        </div>
                      </div>
                    </label>
                    <div className="accordion-body">
                      <dl
                        className="skp-text-light"
                        style={{ marginTop: "-20px" }}
                      >
                        <dd
                          className="columns skp-text-small"
                          style={{ padding: "10px" }}
                        >
                          <div className="column col-4">
                            <span
                              className="skp-link-hover"
                              onClick={() => showDetailsModal(group)}
                            >
                              Show Details
                            </span>
                          </div>
                          <div className="column col-2 text-center">W</div>
                          <div className="column col-2 text-center">D</div>
                          <div className="column col-2 text-center">L</div>
                          <div className="column col-2 text-center">PTS</div>
                        </dd>
                      </dl>
                      <dl className="bordered-list skp-text-light">
                        {group?.leaderboard?.map(
                          (team: ITournamentGroupLeaderboard) => (
                            <dd
                              className="columns skp-text-small"
                              key={team.teamId + "-" + group?.id}
                            >
                              <div className="column col-4 skp-text-default">
                                {/* <span>{findTeam(team.teamId)?.partyName}</span> */}
                                {tournament?.playerType === 0 && (
                                  <UserProfileWithDetailsModal
                                    userId={team.teamId}
                                    tournament={tournament}
                                  ></UserProfileWithDetailsModal>
                                )}
                                {/* Team Name */}
                                {tournament?.playerType === 1 && (
                                  <MatchTeamName
                                    tournament={tournament}
                                    teamId={team.teamId}
                                    hideTeamLabel
                                  />
                                )}
                                {/* End Team Name */}
                              </div>
                              <div className="column col-2 flex-centered">
                                {team.win}
                              </div>
                              <div className="column col-2 flex-centered">
                                {team.draws}
                              </div>
                              <div className="column col-2 flex-centered">
                                {team.defeats}
                              </div>
                              <div className="column col-2 flex-centered">
                                <strong>{team.points}</strong>
                              </div>
                            </dd>
                          )
                        )}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        title={`${showMatchesModal.group?.name}`}
        show={showMatchesModal.show}
        onClose={() => setShowMatchesModal({ show: false })}
      >
        <>
          <TournamentGroupDetails />
          {showMatchesModal.matches && !showMatchDetailModal && (
            <MatchesTab
              matches={showMatchesModal.matches}
              tournament={tournament}
              onLogin={() => {}}
              onSetMatch={(match) => props.onSetMatch(match)}
            />
          )}
        </>
      </Modal>

      <Modal
        title={`Edit ${showEditGroupModal.group?.name}`}
        show={showEditGroupModal.show}
        size="small"
        onClose={() => setShowEditGroupModal({ show: false })}
        loading={loading}
      >
        <>
          {showEditGroupModal.group && (
            <EditTournamentGroupMondal group={showEditGroupModal.group} onSubmit={saveGroup} loading={loading}
             />
          )}
        </>
      </Modal>
    </>
  );
};

export default GroupsTab;
