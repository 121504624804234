import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import { useParams } from "react-router";
import { useNavigate, Link } from "react-router-dom";
import TournamentService from "../../../services/TournamentService";
import Modal from "../../UiLibrary/Modal";
import TournamentImageBanner from "../../UiLibrary/TournamentImageBanner";
import ReactTimeAgo from "react-time-ago";
import "./tournament.scss";
import LoginOrRegisterModal from "../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import YuniteTournamentService from "../../../services/YuniteTournamentService";
import constant from "../../../constants";
import { toast } from "react-toastify";
import TeamsTab from "./YuniteTournamentTabs/TeamsTab";
import LeaderboardTab from "./YuniteTournamentTabs/LeaderboardTab";
import YuniteImportButton from "../../UiLibrary/YuniteImportButton";
import YuniteMatches from "./YuniteTournamentTabs/YuniteMatches";
import ImportYuniteModal from "../../Modals/ImportYuniteModal/ImportYuniteModal";
import YuniteOverviewTab from "./YuniteTournamentTabs/YuniteOverviewTab";
import Scrollbars from "react-custom-scrollbars";
import ITournamentYunite from "../../Interfaces/TournamentYunite";
import GAMES from "../../../constants-games";

const YuniteTournamentSingle = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  const [tournament, setTournament] = useState<ITournamentYunite>();
  const [time, setTime] = useState({ date: new Date(), hours: 0, minutes: "" });
  const [loading, setLoading] = useState(false);

  const tabs = [
    { name: "Overview", url: "overview" },
    { name: "Teams", url: "teams" },
    { name: "Leaderboard", url: "leaderboard" },
    { name: "Matches", url: "matches" },
  ];

  const params = useParams<{ tab: string; id: string }>();
  const currentTab = params.tab;
  const tournamentId = params.id;

  useEffect(() => {
    if (!currentTab) {
      navigate(`/yunite-tournaments/${tournamentId}/overview`);
    }

      YuniteTournamentService.getTournament(tournamentId!).then((response) => {
        let data = response;
        data.game = GAMES.FORTNITE.NAME;
        const date = new Date(data.startDate);
        setTime({
          date: date,
          hours: date.getHours(),
          minutes: (date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
        });
        setTournament(data);
      });
  }, [tournamentId, navigate, currentTab]);

  const importTournament = (data: any) => {
    setLoading(true);

    TournamentService.createTournament(data)
      .then((tournament) => {
        setLoading(false);
        toast.success("Tournament imported successfully");
        setShowModal(false);
        navigate(`/tournaments/${tournament.id}/overview`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="skp-page-with-sidebar skp-page-with-image-banner">
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-page-with-image-banner">
          {/* PAGE CONTENT */}
          <div className="skp-page-with-sidebar-main-content">
            {/* IMAGE BANNER */}
            <div
              className={`skp-image-banner tournament-image-banner Fortnite`}
            >

              <div className="transparent-black"></div>
            </div>
            {/* PAGE TOP */}
            <div className="container page-top">
              <div className="columns">
                <div className="column col-8 col-xs-12 text-center-xs">
                  <span className="skp-text-label skp-text-light">
                    YUNITE TOURNAMENT
                  </span>{" "}
                  <br></br>
                  <h1 className="skp-text-primary skp-heading-no-margin">
                    {tournament?.name}
                  </h1>
                  {tournament && (
                    <div className="skp-text-light">
                      <ReactTimeAgo date={new Date(time.date)} /> - {time.hours}:
                      {time.minutes}
                    </div>
                  )}
                </div>
                <div className="column col-4 col-xs-12 text-center-xs text-right">
                  <div className="space-divider-20 show-xs"></div>
                  <YuniteImportButton
                    tournamentId={tournamentId}
                    importTournament={() => setShowModal(true)}
                  />
                </div>
              </div>
              <div className="space-divider-20"></div>
            </div>
            {/* END PAGE TOP */}
            {/* TABS HEADER */}
            <div className="container tabs-header skp-text-light">
              <div className="columns">
                <div className="column col-12">
                  <ul className="tab">
                    {tabs.map((tab) => (
                      <li
                        key={tab.name}
                        className={
                          currentTab === tab.url
                            ? "tab-item active"
                            : "tab-item"
                        }
                      >
                        <Link
                          to={`/yunite-tournaments/${tournamentId}/${tab.url}`}
                          className={currentTab === tab.url ? "active" : ""}
                        >
                          {tab.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* END TABS HEADER */}
            {/* TABS BODY */}
            <div className="container tabs-body">
              <div className="columns">
                {currentTab === tabs[0].url && (
                  <YuniteOverviewTab tournament={tournament} />
                )}
                {currentTab === tabs[1].url && (
                  <TeamsTab tournament={tournament} />
                )}
                {currentTab === tabs[2].url && (
                  <LeaderboardTab yuniteId={tournament?.id} />
                )}
                {currentTab === tabs[3].url && (
                  <YuniteMatches tournament={tournament} />
                )}
              </div>
            </div>
            {/* END TABS BODY */}
          </div>
        </div>
      </Scrollbars>
      {/* MODALS */}
      {user && tournament && (
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          size="medium"
          title="Import Yunite Tournament"
        >
          <ImportYuniteModal
            tournament={tournament}
            loading={loading}
            user={user}
            onImport={importTournament}
          />
        </Modal>
      )}

      {!user && (
        <Modal
          show={showModal}
          size="small"
          onClose={() => setShowModal(false)}
        >
          <LoginOrRegisterModal
            message={constant.MESSAGES.LOGIN.IMPORT_TOURNAMENT}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default YuniteTournamentSingle;
