import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import GAMES from "../../../constants-games";
import TournamentService from "../../../services/TournamentService";
import { UserContext } from "../../Context/UserContext";
import ITournament from "../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../Interfaces/TournamentMemberStatus";
import ITournamentTeam from "../../Interfaces/TournamentTeam";
import LoadingButton from "../../UiLibrary/LoadingButton/LoadingButton";
import Modal from "../../UiLibrary/Modal";
import UserProfileWithDetailsModal from "../../UiLibrary/User/UserProfileWithDetailsModal";
import AddDiscordIdModal from "../AddDiscordIdModal/AddDiscordIdModal";
import IUserDetails from "../../Interfaces/UserDetails";
import IGameAccount from "../../Interfaces/GameAccount";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import AddSingleGameAccountModal from "../AddGameAccountModal/AddSingleGameAccountModal";
import UserService from "../../../services/UserService";

type Props = {
  tournament: ITournament;
  memberStatus: ITournamentMemberStatus;
  selectUser?: IUserDetails;
  onReloadTournament: (tournament?: ITournament) => void;
};

interface IDisplayValues {
  id: string | undefined;
  game: string | undefined;
  console: string | undefined;
  username: string | undefined;
  displayName?: string;
}

const ReviewInvitationModal = (props: Props) => {
  const { tournament, memberStatus, selectUser } = props;
  let { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [gameAccountNeeded, setGameAccountNeeded] = useState(true);
  const [selectedUser, setSelectedUser] = useState(selectUser ? selectUser : user)
  const [displayValues, setDisplayValues] = useState<Array<IDisplayValues>>([]);
  const [selectedValue, setSelectedValue] = useState<Array<IDisplayValues>>([]);
  const [gameAccount, setGameAccount] = useState<IGameAccount>();

  useEffect(() => {
    setGameAccountNeeded(tournament?.gameAccountNeeded)
    
    if (selectedUser?.gameAccounts && selectedUser?.gameAccounts?.length > 0) {
      const gameAccount = selectedUser?.gameAccounts?.filter(
        (account: IGameAccount) =>
          tournament?.console.includes(account.console || "") &&
          account.game === tournament?.game
      );
      const displayValues: Array<IDisplayValues> = [...gameAccount];
      displayValues.forEach(
        (account) =>
          (account.displayName = `USERNAME: ${account.username} - ID: ${account.id} - PLATFORM: ${account.console}`)
      );
      setDisplayValues(displayValues);
      setSelectedValue([displayValues[0]]);

      setGameAccount(gameAccount[0]);
    
    }
  }, [tournament, selectedUser]);

  const onSelect = (event: any) => {
    let { displayName, ...newData } = event[0];
    setGameAccount(newData);
  };

  const onRemove = () => {
    setGameAccount(undefined);
  };

  const onCloseModal = () => {
    if(selectedUser?.id && selectedUser?.id.length >= 24) {
      UserService.getUser(selectedUser.id, true).then(uResponse => {
        setSelectedUser(uResponse)
        if(uResponse.id === user?.id) {
          setUser(uResponse)
        }
      })
    } 
    setShowAddModal(false);
  }

  const removeMember = (
    memberId: string,
    team: ITournamentTeam
  ): { members: string[]; team: ITournamentTeam } => {
    const teamCopy = { ...team };
    const teamMemberIndex = teamCopy.members.findIndex(
      (m) => m.userId === memberId
    );
    teamCopy.members.splice(teamMemberIndex, 1);

    const memberIndex = tournament.members.findIndex((id) => id === memberId);
    const membersCopy = [...tournament.members];
    membersCopy.splice(memberIndex, 1);

    return { members: membersCopy, team: team };
  };

  const declineInvitation = () => {
    if (!user?.id || !memberStatus.team) {
      return;
    }
    const data = removeMember(user?.id, memberStatus.team);
    let teams = [...(tournament?.teams as ITournamentTeam[])];
    let teamIndex = teams.findIndex((t) => data.team.partyId === t.partyId);
    teams[teamIndex] = data.team;

    TournamentService.updateTournament(
      { members: data.members, teams: teams },
      tournament?.id
    ).then((Rtournament) => {
      setLoading(false);
      props.onReloadTournament(Rtournament);
      toast.info("User removed from members list");
    });
  };

  const submitInvitation = (status: number, callback?: () => void) => {
    if (!tournament ||
      user?.id === undefined ||
      memberStatus.team?.partyId === undefined ) {
      return;
    }

    setLoading(true);
    TournamentService.changeTeamMemberStatus(
      tournament?.id,
      memberStatus.team?.partyId,
      user?.id,
      status,
      gameAccount
    )
      .then((Rtournament) => {
        toast.info("Tournament invitation reviewed successfully!");
        props.onReloadTournament(Rtournament);
        setLoading(false);
        if (callback) {
          callback();
        }
        setShowModal(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <button
        className="skp-btn skp-btn-ternary"
        onClick={() => setShowModal(true)}
      >
        Review Invitation
      </button>

      <Modal
        size="large"
        title="Review Invitation"
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <>
          {user && !user?.discordId && GAMES[tournament.game].REQUIRE_DISCORD && (
            <>
              <AddDiscordIdModal />
            </>
          )}
          {user &&
            (!GAMES[tournament.game].REQUIRE_DISCORD || user?.discordId) && (
              <div className="container">
                <div className="columns">
                  <div className="space-divider-20"></div>
                  <div className="column col-12 skp-text-primary flex-centered mb-2">
                    <UserProfileWithDetailsModal
                      userId={memberStatus.team?.leader}
                      tournament={tournament}
                    ></UserProfileWithDetailsModal>
                  </div>
                  <div className="column col-12 skp-text-primary flex-centered mt-2">
                    invites you to join team: {" "}{memberStatus.team?.partyName}
                  </div>
                </div>

                {gameAccountNeeded && (
                  <>
                    <div className="space-divider-20"></div>
                    <div className="column col-12 col-xs-12">
                      <h6 className="skp-text-light text-uppercase flex-centered">
                        Select game account to proceed
                      </h6>
                      {displayValues.length > 0 && (
                        <div className="skp-form-control flex-centered">
                          <Multiselect
                            options={displayValues} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            displayValue="displayName" // Property name to display in the dropdown options
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            singleSelect={true}
                            closeIcon="cancel"
                            placeholder="Select a game account"
                            avoidHighlightFirstOption={true}
                          />
                        </div>
                      )}
                      {displayValues.length === 0 && (
                        <div>
                          <div
                            className="skp-link-primary skp-text-default flex-centered"
                            onClick={() => setShowAddModal(true)}
                          >
                            <i className="las la-plus-circle"></i> Add an account for{" "}
                            {GAMES[tournament?.game].NAME}
                          </div>
                          <div className="space-divider-20"></div>
                        </div>
                      )}
                      <p className="skp-text-label skp-text-light">
                        To manage your game accounts go to the{" "}
                        <Link
                          className="skp-link"
                          to={`/users/${selectedUser?.id}/settings/usersettings`}
                        >
                          User Setting's page
                        </Link>
                      </p>
                    </div>
                    <div className="column col-2 hide-xs"></div>
                  </>
                )}

                <div className="columns">
                  <div className="space-divider"></div>
                  <div className="column col-6">
                    <LoadingButton
                      label="Decline Invitation"
                      className="skp-btn skp-btn-alert skp-text-small"
                      disabled={loading}
                      onBtnClick={() => submitInvitation(2, declineInvitation)}
                    />
                  </div>
                  <div className="column col-6 text-right">
                    <LoadingButton
                      label="Accept Invitation"
                      className="skp-btn skp-btn-ternary skp-text-small"
                      disabled={loading || !gameAccount}
                      onBtnClick={() => submitInvitation(1)}
                    />
                  </div>
                </div>
              </div>
            )}
        </>
      </Modal>
      {/* Add account modal */}
      <Modal
        show={showAddModal}
        onClose={() => setShowAddModal(false)}
        title="Add Game Account"
        size="large"
      >
        <>
          {selectedUser && (
            <AddSingleGameAccountModal
              tournament={tournament}
              selectUser={selectedUser}
              closeModal={onCloseModal}
            ></AddSingleGameAccountModal>
            )}
        </>
      </Modal>
    </>
  );
};

export default ReviewInvitationModal;
