import "./notifications-button-wrapper.scss";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";

const NotificationsButton = () => {

  return (
    <div className="action-buttons-wrapper">
      <div className="notification-dropdown-wrapper">
        
        <NotificationDropdown />
      </div>
    </div>
  );
};

export default NotificationsButton;
