import IMatch from "../components/Interfaces/Match";
import IMatchTournamentSeed from "../components/Interfaces/MatchTournamentSeed";
import { INotificationElement } from "../components/Interfaces/notification.interface";
import EMatchStatus from "../enums/MatchStatus";

const getMultipleMatchWinner = (
  tournamentMatches: IMatch[] | undefined,
  seed: IMatchTournamentSeed
): {
  winner: string | undefined;
  teamHomeScores: number[];
  teamAwayScores: number[];
} => {
  const matchesInfo = {
    matches: tournamentMatches?.filter(
      (item) => seed?.matchesId?.indexOf(item.id) !== -1
    ),
    seed,
  };
  const teamHomeScores: number[] = [];
  const teamAwayScores: number[] = [];
  let winner: string | undefined = "";
  matchesInfo.matches?.forEach((match) => {
    if(match.status === 3) {
      const teamHomeScore = match.result.scores[0].score;
      const teamAwayScore = match.result.scores[1].score;
      if (teamHomeScore > teamAwayScore) {
        teamHomeScores.push(teamHomeScore);
      } else if (teamHomeScore < teamAwayScore) {
        teamAwayScores.push(teamAwayScore);
      }
    }
  });

  if (teamHomeScores.length > teamAwayScores.length) {
    winner = matchesInfo.matches?.[0]?.result.scores[0].teamId;
  } else if (teamHomeScores.length < teamAwayScores.length) {
    winner = matchesInfo.matches?.[0]?.result.scores[1].teamId;
  }
  
  return { winner, teamHomeScores, teamAwayScores };
};

const getMultipleMatchWinnerNoSeed = (
  tournamentMatches: IMatch[]
): {
  winner: string | undefined;
  teamHomeScores: number[];
  teamAwayScores: number[];
} => {
  const teamHomeScores: number[] = [];
  const teamAwayScores: number[] = [];
  let winner: string | undefined = undefined;
  tournamentMatches?.forEach((match) => {
    if(match.status === 3) {
      const teamHomeScore = match.result.scores[0].score;
      const teamAwayScore = match.result.scores[1].score;
      if (teamHomeScore > teamAwayScore) {
        teamHomeScores.push(teamHomeScore);
      } else if (teamHomeScore < teamAwayScore) {
        teamAwayScores.push(teamAwayScore);
      }
    }
  });

  if (teamHomeScores.length > teamAwayScores.length && teamHomeScores.length > tournamentMatches?.length/2) {
    winner = tournamentMatches?.[0]?.result.scores[0].teamId;
  } else if (teamHomeScores.length < teamAwayScores.length && teamAwayScores.length > tournamentMatches?.length/2) {
    winner = tournamentMatches?.[0]?.result.scores[1].teamId;
  }
  
  return { winner, teamHomeScores, teamAwayScores };
};

const getMultipleMatchStatus = (
  tournamentMatches: IMatch[] | undefined,
  seed: IMatchTournamentSeed
): number => {
  let status = 0;
  const matchesStatus: number[] = [];
  const matchesInfo = {
    matches: tournamentMatches?.filter(
      (item) => seed?.matchesId?.indexOf(item.id) !== -1
    ),
    seed,
  };
  matchesInfo.matches?.forEach((match) => {
    // console.log(EMatchStatus[match.status])
    matchesStatus.push(match.status);
  });
  
  if(matchesStatus.every((val, i, arr) => val === 3 && val === arr[0])){
    status = 3
  } else if (matchesStatus.includes(1)) {
    status = 1
  } else if (matchesStatus.includes(2)) {
    status = 2
  } else if (matchesStatus.includes(3)) {
    status = 1
  }


  return status
};

const getMultipleMatchStatusNoSeed = (
  tournamentMatches: IMatch[] | undefined,
): number => {
  let status = 0;
  const matchesStatus: number[] = [];

  tournamentMatches?.forEach((match) => {
    // console.log(EMatchStatus[match.status])
    matchesStatus.push(match.status);
  });
  
  if(matchesStatus.every((val, i, arr) => val === 3 && val === arr[0])){
    status = 3
  } else if (matchesStatus.includes(1)) {
    status = 1
  } else if (matchesStatus.includes(2)) {
    status = 2
  } else if (matchesStatus.includes(3)) {
    status = 1
  }
  return status
};

const getMultipleMatchReportsNoSeed = (
  tournamentMatches: IMatch[] | undefined,
  theNotification: INotificationElement | undefined
): number => {
  let status = 0;
  
  tournamentMatches?.forEach((match) => {
    // console.log(EMatchStatus[match.status])
    if (match.result.reports) {
      let readed = 0
      if (theNotification) {
        const nMatch = theNotification.matches?.find((m) => m.matchId === match.id)
          readed = nMatch ? nMatch.reports : 0
        }
      status += (match.result.reports.length-readed)
    }
  });
  return status
};

const getMatchsReports = (
  tournamentMatches: IMatch[] | undefined,
  theNotification: INotificationElement | undefined
): Array<number> => {

  let status:Array<number> = [];
  
  tournamentMatches?.forEach((match) => {
    // console.log(EMatchStatus[match.status])
    if (match.result.reports) {
      let readed = 0
      if (theNotification) {
        const nMatch = theNotification.matches?.find((m) => m.matchId === match.id)
          readed = nMatch ? nMatch.reports : 0
        }
      status.push( match.result.reports.length-readed)
    }
  });
  return status
};

const MatchResultService = {
  getMultipleMatchWinner,
  getMultipleMatchStatus,
  getMultipleMatchStatusNoSeed,
  getMultipleMatchWinnerNoSeed,
  getMultipleMatchReportsNoSeed,
  getMatchsReports
};

export default MatchResultService;
