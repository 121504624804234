import React, { useEffect, useState } from "react";
import KeyCloackService from "../../services/KeyCloackService";
import UserService from "../../services/UserService";
import UserProfile from "./UserProfile";
import UserProfileWithDetailsModal from "./User/UserProfileWithDetailsModal";
import IUserDetails from "../Interfaces/UserDetails";
import ITournament from "../Interfaces/Tournament";

type Props = {
  player: any;
  tournament: ITournament
}

function YunitePlayer(props:Props) {
  const { player, tournament } = props;
  const [user, setUser] = useState<IUserDetails>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (KeyCloackService.isLoggedIn()) {
      setLoading(true);
      UserService.getUserByEpicId(player?.epicId).then((Ruser) => {
        setUser(Ruser);
        setLoading(false);
      });
    }

    return () => {
      setUser(undefined);
      setLoading(false);
    };
  }, [player]);

  return (
    <>
      {loading && <UserProfile></UserProfile>}
      {user && !loading && (
        <UserProfileWithDetailsModal userId={user?.id} tournament={tournament} />
      )}
      {!user && !loading && (
        <div className="user-profile">
          <figure className="avatar">
            <span className="text-uppercase">
              <i className="las la-user"></i>
            </span>
          </figure>
          <span className="skp-text-primary p-2">Epic Id not found!</span>
        </div>
      )}

    </>
  );
}

export default YunitePlayer;
