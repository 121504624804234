import { useEffect, useState } from "react";
import Marquee from "react-text-marquee";
import UserService from "../../../../../../services/UserService";
import ITournamentYuniteTeam from "../../../../../Interfaces/TournamentYuniteTeam";
import IUserDetails from "../../../../../Interfaces/UserDetails";
import ITournament from "../../../../../Interfaces/Tournament";

type Props = {
  username?: string | null | undefined;
  playerId?: string | undefined;
  children?: React.ReactNode
};
const PlayPlayTeamUser = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<IUserDetails>();
  const { username, playerId } = props;

  const removeSuffix = (name:string) => {
    return name.replace(/_playandplay/g, "");
  }

  useEffect(() => {
    setLoading(true);
    if (playerId && playerId.length >= 24) {
      UserService.getUser(playerId)
        .then((Ruser) => {
          setUserProfile(Ruser);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }

    return () => {
      setUserProfile(undefined);
      setLoading(false);
    };
  }, [playerId]);

  return ( 
    <div className="user-profile">
      
      <div className="small-player-wrapper">
        {userProfile && !userProfile?.profile_picture && (
          <img
            src="https://play-play.com/images/default_avatar.jpg"
            alt="player-avatar"
          />
        )}

        {userProfile && userProfile?.profile_picture && (
          <img src={userProfile.profile_picture} alt="player-avatar" />
        )}

        {!userProfile && username && (
          <img
            src="https://play-play.com/images/default_avatar.jpg"
            alt="player-avatar"
          />
        )}
       
        {!userProfile && !username && (
          <figure className="avatar">
          <span className="text-uppercase">
            <i className="las la-user"></i>
          </span>
          </figure>
        )}
      </div>

      <div className="text-label" style={{ margin: 10 }}>
        {username && (
          <Marquee text={`${username ? removeSuffix(username) : ""}`} />
        )}
        {userProfile && (
          <Marquee text={removeSuffix(userProfile?.username || "")} />
        )}
      </div>

      {props.children}
    </div>
  );
};

export default PlayPlayTeamUser;
