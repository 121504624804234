import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Bracket } from 'react-brackets';
import CustomSeed from '../../../UiLibrary/CustomSeed';
import TournamentService from '../../../../services/TournamentService';
import GenerateBracketRounds from '../../../Hooks/GenerateBracketRounds';
import ITournament from '../../../Interfaces/Tournament';
import IMatchTournamentSeed from '../../../Interfaces/MatchTournamentSeed';
import { UserContext } from '../../../Context/UserContext';
import { useTranslation } from 'react-i18next';
import './fullscreen-bracket.scss'; // Puoi definire uno stile personalizzato per il fullscreen
import { ITournamentMatches, TournamentMatchesContext } from '../../../Context/TournamentMatchesContext';
import MatchService from '../../../../services/MatchService';
import Draggable from "react-draggable";
import EventBus from "react-native-event-bus";
import Modal from '../../../UiLibrary/Modal';
import MatchDetailsModal from '../../../Modals/MatchDetailsModal/MatchDetailsModal';
import MultiMatchDetailsModal from '../../../Modals/MultiMatchDetailsModal/MultiMatchDetailsModal';

const FullScreenBracket = () => {
    const { id } = useParams<{ id: string }>(); // Ottieni l'ID del torneo dalla URL
    const { user } = useContext(UserContext); // Ottieni il contesto utente
    const { t } = useTranslation(); // Traduzioni
    const [tournament, setTournament] = useState<ITournament | null>(null); // Stato per il torneo
    const [rounds, setRounds] = useState<{ winners: any[], loosers: any[] }>({ winners: [], loosers: [] }); // Stato per i rounds
    const [activeRounds, setActiveRounds] = useState('winners'); // Gestione tab attiva
    const [tournamentMatches, setTournamentMatches] = useState<ITournamentMatches>();
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [showMatchModal, setShowMatchModal] = useState<{
        show: boolean;
        match: IMatchTournamentSeed | undefined;
    }>({
        show: false,
        match: undefined,
    });

    const providerTournamentMatches = useMemo(
        () => ({ tournamentMatches, setTournamentMatches }),
        [tournamentMatches, setTournamentMatches]
    );

    const viewDetails = useCallback(
        (data:any) => {
            setShowMatchModal({ show: true, match: data })
        },
        []
    );

    useEffect(() => {
        const viewDetailsListener = EventBus.getInstance().addListener(
            "SHOW_MATCH_DETAILS",
            (data: any) => {
                viewDetails(data);
            }
        );

        return () => {
            EventBus.getInstance().removeListener(viewDetailsListener);
        };
    }, [viewDetails]);

    const updateNotification = (newNotification) => {
        MatchService.setNotification(newNotification).then((notification) => {
            console.log("update")
        })
    }

    // Effettua il fetch dei dati del torneo quando il componente si monta
    useEffect(() => {
        const fetchTournamentData = async () => {
        try {
            const tournamentData = await TournamentService.getTournament(id!);
            setTournament(tournamentData);
            setRounds(GenerateBracketRounds.generate(tournamentData.matches));

            MatchService.getTournamentMatches(
                tournamentData?.id,
                (tournamentData?.maxPartecipants + 1) * 2 * 9,
                true
            ).then((matches) => {
                setTournamentMatches({ matches: matches, tournament: tournamentData });
            });
        } catch (error) {
            console.error('Error fetching tournament data:', error);
        }
        };

        fetchTournamentData();
    }, [id]);

 
    // Funzione per cambiare il tab tra Winners e Losers
    const changeRounds = (value: string) => {
        setActiveRounds(value);
    };

    // Verifica se l'utente è admin del torneo
    const isAdmin = (tournament?.owner === user?.id) || (user?.roles && user.roles.includes('TournamentAdmin'));

    // Gestore del click sulla configurazione di un round
    const onRoundClick = (e, roundIndex, bracket) => {
        e.preventDefault();
        e.stopPropagation();
        // Funzione per aprire la configurazione del round
    };

    const resetPosition = () => {
        setPosition({ x: 0, y: 0 }); // Reset alla posizione iniziale
    };

    const handleDrag = (e, data) => {
        setPosition({ x: data.x, y: data.y });
    };

    const closeMatchModal = () => {
        setShowMatchModal({ show: false, match: undefined });
    };


    // UI del bracket fullscreen
    return (
        <TournamentMatchesContext.Provider value={providerTournamentMatches}>
            <div className="fullscreen-bracket-container">
                {tournament && rounds.winners.length > 0 && (
                    <div className="bracket-content">
                        <div className="bracket-header">
                            {rounds.loosers.length > 0 && <div className="columns tabs-header">
                                <div className="column col-12">
                                    <ul className="tab skp-text-light">
                                        <li
                                            className={`tab-item pointer ${
                                                activeRounds === "winners" ? "active" : ""
                                            }`}
                                        >
                                            <a onClick={() => changeRounds("winners")}>
                                                Winners
                                            </a>
                                        </li>
                                        <li
                                            className={`tab-item pointer ${
                                                activeRounds === "loosers" ? "active" : ""
                                            }`}
                                        >
                                            <a onClick={() => changeRounds("loosers")}>
                                                Losers
                                            </a>
                                        </li>
                                        <li className="tab-item push-right skp-text-primary pointer">
                                            <a onClick={() => resetPosition()}>
                                                {t('bracket.resetBracketPosition')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>}
                            {!(rounds.loosers.length > 0) && <div className="columns tabs-header">
                                <div className="column col-12">
                                    <ul className="tab skp-text-light">
                                        <li className="tab-item push-right skp-text-primary pointer">
                                            <a onClick={() => resetPosition()}>
                                                {t('bracket.resetBracketPosition')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>}
                        </div>
                        <Draggable
                            position={position}
                            onDrag={handleDrag}
                        >
                            <div className="bracket-body">
                                {activeRounds === 'winners' && rounds.winners.length > 0 && (
                                    <Bracket
                                        rounds={rounds.winners}
                                        renderSeedComponent={(props) => (
                                            <CustomSeed {...props} isFullScreen={true} />
                                        )}
                                        roundTitleComponent={(title: string, roundIndex: number) => (
                                            <div>
                                                <h5 className="skp-text-primary text-center">
                                                    {title} {isAdmin && (
                                                        <i
                                                            className="las la-cog"
                                                            onClick={(e) => onRoundClick(e, roundIndex, 'winners')}
                                                        ></i>
                                                    )}
                                                </h5>
                                                <div className="skp-text-small skp-text-light text-center">
                                                    {t('bracket.bestOf', { count: rounds.winners[roundIndex]?.seeds[0]?.matchesId.length })}
                                                </div>
                                            </div>
                                        )}
                                    />
                                )}
                                {activeRounds === 'loosers' && rounds.loosers.length > 0 && (
                                    <Bracket
                                        rounds={rounds.loosers}
                                        renderSeedComponent={(props) => (
                                            <CustomSeed {...props} isFullScreen={true} />
                                        )}
                                        roundTitleComponent={(title: string, roundIndex: number) => (
                                            <div>
                                                <h5 className="skp-text-primary text-center">
                                                    {title} {isAdmin && (
                                                        <i
                                                            className="las la-cog"
                                                            onClick={(e) => onRoundClick(e, roundIndex, 'loosers')}
                                                        ></i>
                                                    )}
                                                </h5>
                                                <div className="skp-text-small skp-text-light text-center">
                                                    {t('bracket.bestOf', { count: rounds.loosers[roundIndex]?.seeds[0]?.matchesId.length })}
                                                </div>
                                            </div>
                                        )}
                                    />
                                )}
                            </div>
                        </Draggable>
                    </div>
                )}
            </div>
            <Modal
                show={showMatchModal.show}
                onClose={() => closeMatchModal()}
                title={`Match ${showMatchModal.match?.id}`}
            >
                <>
                    {/* Single Match */}
                    {tournament &&
                    showMatchModal.match?.matchesId &&
                    showMatchModal.match?.matchesId?.length === 1 && (
                        <MatchDetailsModal
                            tournament={tournament}
                            matchId={showMatchModal.match?.matchesId[0]}
                            closeModal={closeMatchModal}
                            onReloadTournament={undefined}
                            updateNotification={updateNotification}
                        />
                    )}
                    {/* Multi Match */}
                    {showMatchModal.match?.matchesId &&
                    showMatchModal.match?.matchesId?.length > 1 && (
                        <MultiMatchDetailsModal
                            match={showMatchModal.match}
                            tournament={tournament!}
                            updateNotification={updateNotification}
                        />
                    )}
                </>
            </Modal>
        </TournamentMatchesContext.Provider>
    );
};

export default FullScreenBracket;
