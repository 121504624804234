import "./chat.scss"
import { useContext, useEffect, useRef, useState } from "react";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import { ChatContext } from "../../Context/ChatContext";
import ChatService from "../../../services/ChatService";
import { UserContext } from "../../Context/UserContext";
import IMessage from "../../Interfaces/Chat/Message";
import Modal from "../../UiLibrary/Modal";
import UploadImageBox from "../../UiLibrary/UploadImageBox/UploadImageBox";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import ImageService from "../../../services/ImageService";
import imageCompression from 'browser-image-compression';

type Props = {
};

const ChatPageFooter = (props: Props) => {
    const { contacts, chatId, setChatId, setChatList, chatList, refresh, setRefresh, replyMessage, setReplyMessage } = useContext(ChatContext)
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [openPicker, setOpenPicker] = useState(false)
    const [show, setShow] = useState(false);
    const [theMessage, setMessage] = useState("")
    const [showModal, setShowModal] = useState(false);
    
    const messageInput = useRef<HTMLInputElement>(null);

    const [showImageModal, setShowImageModal] = useState(false);
    const [theImage, setImage] = useState<IFileBase64Image>();
    const [compressImage, setCompressImage] = useState<IFileBase64Image>();
    const [imageUploading, setImageUploading] = useState(false);

    const selectedChat = chatList?.find((chat) => chat.id === chatId)

    useEffect(()=>{

        const compressImage = async (imageFile) => {
            try {
                setCompressImage(undefined)
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1024,
                    useWebWorker: true,
                }

                const compressedFile = await imageCompression(imageFile, options);

                setCompressImage({
                    file:compressedFile,
                    name:theImage?.name!,
                    base64:theImage?.base64!
                })
            } catch (error) {
                console.log(error);
            }
          }
        
          if (theImage) {
            const imageFile = theImage.file
            // call the function
            compressImage(imageFile)
                // make sure to catch any error
                .catch(console.error);;
        }
    },[theImage])

    useEffect(()=>{
        messageInput.current?.focus()
    },[replyMessage])
  
    const handleRotate = () => {
        setShow(!show);
    }

    const rotate = show ? "rotate(45deg)" : "rotate(0)"

    const addEmoji = (e) => {
        setMessage(theMessage+e.native);
        setOpenPicker(!openPicker)
        messageInput.current?.focus()
    }

    const sendMessage = () => {
        setLoading(true)

        if (theMessage && theMessage.length > 0) {
            if (!chatId) {
                // Non ho aperto nessuna chat, creo una chat prima di inviare il messaggio
                const data = {
                    attendees: [{id: user?.id, unread:0, pinned: false},{id:contacts![0].id, unread:0, pinned: false}],
                    name:""
                }
        
                ChatService.createChat(data).then((resp) => {
                    const newMessage: IMessage = {
                        chatId: resp.chatid,
                        type: "text",
                        text: theMessage,
                        userid: user?.id!
                    } 

                    if (replyMessage) {
                        newMessage.type = "reply"
                        newMessage.reply = replyMessage.text
                        newMessage.replyid = replyMessage.userid
                        newMessage.replytype = replyMessage.type
                    }
        
                    ChatService.sendMessage(newMessage).then((resp2) => {
                        ChatService.getChatById(resp.chatid, user?.id!).then((chatRest) => {
                            if (chatList) {
                                setChatList([...chatList, chatRest])
                            } else {
                                setChatList([chatRest])
                            }
                            setRefresh(refresh+1)
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    });

                    setChatId(resp.chatid)
                })
                .catch((error) => {
                    console.log(error)
                });
            } else {
                /* 
                 * Invio il messaggio
                 */
                const newMessage: IMessage = {
                    chatId: chatId!,
                    type: "text",
                    text: theMessage,
                    userid: user?.id!
                } 
                if (replyMessage) {
                    newMessage.type = "reply"
                    newMessage.reply = replyMessage.text
                    newMessage.replyid = replyMessage.userid
                    newMessage.replytype = replyMessage.type
                }

                ChatService.sendMessage(newMessage).then((resp) => {
                    setRefresh(refresh+1)
                })
                .catch((error) => {
                    console.log(error)
                });
            }
            
        }
        setReplyMessage(undefined)
        setMessage("")
        //console.log("invio il messaggio " + values.theMessage)
        setLoading(false)
    };

    const uploadImage = () => {
        if (!compressImage) {
            return
        }
        
        setImageUploading(true);
        ImageService.uploadImage(compressImage).then((response) => {
            if (response !== undefined) {
                const newMessage: IMessage = {
                    chatId: chatId!,
                    type: "image",
                    text: ImageService.generatePublicUrl(response?.data),
                    userid: user?.id!
                } 

                ChatService.sendMessage(newMessage).then((resp) => {
                    setImageUploading(false);
                    setImage(undefined)
                    setCompressImage(undefined)
                    setShowImageModal(false)
                    setRefresh(refresh+1)
                })
                .catch((error) => {
                    setImageUploading(false);
                    setImage(undefined)
                    setCompressImage(undefined)
                    setShowImageModal(false)
                    console.log(error)
                });
            }
        });
    };

    return (
        <>
            {contacts && replyMessage && ( 
                <div className="skp-chat-reply-conversation-footer">
                    <div className="skp-msg-balloon-replyed" style={{display:"flex"}}>
                        <div className="skp-msg-reply-balloon-replyed-content">
                            <div className="skp-text-default skp-msg-reply-username-footer">
                                {replyMessage.userid === user?.id ? "You" : contacts? contacts[0]?.username : "loading..."}
                            </div>
                            <div className="skp-text-small skp-text-light skp-reply-text-footer">
                                {replyMessage.type !== 'image' && replyMessage.text}
                                {replyMessage.type === 'image' && (
                                    <div>
                                        <i className="las la-camera" style={{fontSize: "16px"}}></i> 
                                        Photo
                                        <img className="skp-reply-image" src={replyMessage.text}></img>
                                    </div>
                                )} 

                                
                            </div>
                        </div>
                        <div
                            className="skp-delete-reply-button float-right skp-text-light"
                            onClick={() => setReplyMessage(undefined)}
                        >
                            <i className="las la-times-circle skp-delete-reply-icon"></i>
                        </div>
                    </div>
                </div>
            )}
            <div className="skp-chat-conversation-footer">
                {contacts && !selectedChat?.isDeleted && ( 
                    <div className="skp-form-control" style={{display:"flex"}}>
                        <div className="skp-text-light skp-message-inline-menu-icon">
                            <Menu
                                transition
                                menuButton={<MenuButton className="skp-btn skp-btn-icon skp-add-element-button"
                                        style={{ transformOrigin: 'center', transform: rotate, transition: "all 0.1s linear" }} onClick={handleRotate}
                                    ><i className="skp-message-icon las la-plus"></i></MenuButton>}
                                direction={"top"}
                                align={"start"}
                                position={"anchor"}
                                viewScroll={"close"}
                                arrow={true}
                                theming="dark"
                                >
                                <MenuItem onClick={() => setShowImageModal(true)}><i className="las la-image skp-menuitem-icon"></i>Image</MenuItem>
                                <MenuItem onClick={() => setShowModal(true)}><i className="lar la-sticky-note skp-menuitem-icon"></i>Sticker</MenuItem>
                                <MenuItem onClick={() => setShowModal(true)}><i className="lar la-heart skp-menuitem-icon"></i>Emote</MenuItem>
                            </Menu>
                        </div>
                        <div className="skp-chat-message-form-control">
                            <input
                                ref={messageInput}
                                name="theMessage"
                                placeholder="Write a message..."
                                value={theMessage}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                        sendMessage()
                                    }}
                            />
                        </div>
                        {openPicker && (
                            <div className="emoji-container">
                                <Picker data={data} onEmojiSelect={(e) => addEmoji(e) } theme="dark"/>
                            </div>  
                        )}
                        <div
                            className="skp-emoji-picker-button float-right"
                            onClick={() => setOpenPicker(!openPicker)}
                        >
                            {String.fromCodePoint(0x1f600)}
                        </div>
                        <button
                            disabled={loading}
                            className="skp-chat-message-form-button skp-btn-primary float-right"
                            onClick={sendMessage}
                        >
                            <i className="las la-paper-plane"></i>
                        </button>
                    </div>
                )}
            </div>
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                title="Available Soon"
                size="small"
            >
                <div style={{textAlign: "center"}} className="skp-text-light">
                    The function will be available soon
                </div>
            </Modal>

            <Modal
                show={showImageModal}
                onClose={() => setShowImageModal(false)}
                title="Add image or gif"
                size="small"
            >
                <div className="skp-image-upload">
                    <div className="file-base64-label-wrapper">
                        <UploadImageBox
                        sizeLimit={5*1024*1024}
                        onUpload={(image) => setImage(image)}
                        onRemovePreview={() => setImage(undefined)}
                        />
                    </div>
                    <button
                        disabled={!compressImage || imageUploading}
                        onClick={uploadImage}
                        className="skp-btn skp-btn-primary"
                    >
                        {imageUploading ? (
                        <span className="loading"></span>
                        ) : (
                            "Add Image"
                        )}
                    </button>
                </div>
            </Modal>
        </>
    );
};
  
export default ChatPageFooter;