import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Scrollbars } from "react-custom-scrollbars";
import { DebounceInput } from "react-debounce-input";
import ChatCard from "../../UiLibrary/SocialComponents/ChatCard/ChatCard";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import Modal from "../../UiLibrary/Modal";
import UserSearchDropdown from "../../UiLibrary/User/UserSearchDropdown";
import IUserDetails from "../../Interfaces/UserDetails";
import UserProfile from "../../UiLibrary/UserProfile";
import { UserContext } from "../../Context/UserContext";
import { ChatContext } from "../../Context/ChatContext";
import ChatService from "../../../services/ChatService";

const ChatPageSidebar = () => {
    const { user, setUser } = useContext(UserContext);
    const { setContacts, setChatId, chatList, setChatList, refresh } = useContext(ChatContext);
    const [showSideBar, setShowSideBar] = useState(false);
    const [searchText, setSearchText] = useState();
    const [selectedUser, setSelectedUser] = useState<IUserDetails>();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    /* qui recupero la chat list */
    useEffect(() => {
        setLoading(true);

        if (user && user.id) {
            ChatService.getChatListByUser(user?.id)
            .then((resp) => {
                console.log(resp)
                setChatList(resp);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
        }

        return () => {
            setChatList([]);
            setLoading(false);
        };
    }, [refresh]);

    const [showChatModal, setShowChatModal] = useState<{
        show: boolean;
        group: boolean;
    }>({
        show: false,
        group: false,
    });

    const closeModal = () => {
        setShowChatModal({ show: false, group: false });
        setSelectedUser(undefined)
    };

    const chooseUser = (theUser: IUserDetails) => {
        setSelectedUser(theUser)
    };

    const toggleSideBar = () => {
        setShowSideBar(!showSideBar);
    };

    const startChat = () => {
        console.log( "start chat with "+selectedUser?.username)

        setContacts([selectedUser!])
        setChatId(undefined)

        closeModal()
    }

    const searchUser = (event: any) => {
        /*setSearchText(event.target.value);
        props.searchUser(event)*/
    };

    return (
        <div
            className={`skp-page-with-sidebar-left-side ${
            isMobile && !showSideBar && "skp-page-with-sidebar-side-hidden"
            }`}
        >
            <div
                className="skp-sidebar-close-icon show-xs skp-text-light"
                onClick={toggleSideBar}
            >
                <i className="las la-times"></i>
            </div>
            <div className="skp-left-sidebar">
                <div className="container p-2">
                    <div className="columns p-2">
                        <div className="column col-12">
                            <div style={{display:"flex"}}>
                                <div className="column col-4">
                                    <h4 className="skp-text-primary">Chats</h4> 
                                </div>
                                <div className="column col-8">
                                    <div className="skp-text-light skp-message-inline-menu-icon">
                                        <Menu
                                            transition
                                            menuButton={<MenuButton className="skp-btn skp-btn-icon">
                                                <i className="skp-new-chat-icon las la-edit"></i>
                                                </MenuButton>}
                                            direction={"top"}
                                            align={"start"}
                                            position={"anchor"}
                                            viewScroll={"close"}
                                            arrow={true}
                                            theming="dark"
                                            >
                                            <MenuItem onClick={() => setShowChatModal({ show: true, group: false })}><i className="las la-comments skp-menuitem-icon"></i>New Chat</MenuItem>
                                            <MenuItem onClick={() => setShowModal(true)}><i className="las la-users skp-menuitem-icon"></i>New Group</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </div>

                            <div className="skp-form-control" style={{margin: '0px -3px 0px -3px'}}>
                                <DebounceInput
                                    value={searchText}
                                    type="text"
                                    minLength={1}
                                    debounceTimeout={500}
                                    placeholder="Search..."
                                    onChange={(e) => searchUser(e)}
                                />
                            </div>
                            <div className="space-divider-10"></div>
                            <div className="space-border" style={{margin:"0 -8px 0 -15px"}}></div>
                        </div>
                    </div>
                </div>
                <Scrollbars 
                    renderTrackVertical={
                        ({style, ...props}) => <div {...props} style={{...style, backgroundColor: '#292c33', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px', width: '5px'}}/>
                    }
                    renderThumbVertical={
                        ({style, ...props}) => <div {...props} style={{...style, width: '3px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)', backgroundColor: '#959aa2'}}/>
                }>
                    <div className="container p-2">
                        <div className="columns p-2">
                            <div className="column col-12">
                                <div className="column p-2 skp-text-primary skp-text-default" >
                                    Pinned
                                </div>
                                {chatList && chatList.filter((el:any)=>el.pinned).map((el:any)=>{
                                    return (<div key={el.id}>
                                        <ChatCard {...el}></ChatCard>
                                        <div className="single-spacer"></div>
                                    </div>)
                                })}
                                
                                <div className="column p-2 skp-text-primary skp-text-default" >
                                    All Chats
                                </div>
                                {chatList && chatList.filter((el:any)=>!el.pinned).map((el:any)=>{
                                    return (<div key={el.id}>
                                        <ChatCard {...el}></ChatCard>
                                        <div className="single-spacer"></div>
                                    </div>)
                                })}
                                
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            </div>
            
            <Modal
                size="small"
                title="New Chat"
                show={showChatModal.show}
                onClose={() => closeModal()}
            >
                <>
                    <UserSearchDropdown setUser={chooseUser} hideStatus={true}/>
                    {selectedUser && (
                        <>
                            <div className="columns">
                                <div className="space-divider-20"></div>
                                <div className="column col-12 skp-text-primary skp-text-small flex-centered">
                                    <span>Start Conversation With:</span>
                                </div>
                                <div className="space-divider-10"></div>
                            
                                <div className="column col-10">
                                    <UserProfile user={selectedUser}></UserProfile>
                                </div>
                                <div className="column col-1 flex-centered">
                                    <button
                                        className="skp-btn skp-btn-icon"
                                        onClick={() => setSelectedUser(undefined)}
                                    >
                                        <i className="las la-trash skp-chat-header-button-icon"></i>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="space-divider"></div>
                    <div className="modal-submit-internal">
                        <button
                            type="submit"
                            disabled={!selectedUser}
                            className="skp-btn skp-btn-primary"
                            onClick={() => startChat()}
                        >
                            Start Chat
                        </button>
                    </div>
                </>
            </Modal>
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                title="Available Soon"
                size="small"
            >
                <div style={{textAlign: "center"}} className="skp-text-light">
                    The function will be available soon
                </div>
            </Modal>
        </div>
    );
};
  
export default ChatPageSidebar;