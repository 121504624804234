import "./messages-button-wrapper.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MessagesButton = () => {

  const [pendings, setPendings] = useState(["Messaggio1", "Messaggio2"]);

  const navigate = useNavigate();

  const goToChatPage = () => {
    navigate(`chat`);
  };

  return (
    <div className="action-buttons-wrapper">
      <div className="message-dropdown-wrapper">
        <button
          className="skp-btn skp-btn-circle mr-2 ml-2"
          onClick={() => goToChatPage()}
        >
          {pendings.length > 0 && (
            <sup className="flex-centered">{pendings.length}</sup>
          )}
          <i className="las la-comments"></i>
        </button>
      </div>
    </div>
  );
};

export default MessagesButton;
