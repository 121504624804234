import { useEffect, useMemo, useState } from 'react';
import LocalStorageService from './services/LocalStorageService';
import KeyCloackService from './services/KeyCloackService';
import IUserDetails from './components/Interfaces/UserDetails';
import ISignUpModal from './components/Interfaces/SignUpModal';
import UserService from './services/UserService';
import EventBus from "react-native-event-bus";
import { RegistrationModalContext } from './components/Context/RegistrationModalContext';
import NotificationTopBar from './components/UiLibrary/NotificationTopBar';
import { UserContext } from './components/Context/UserContext';
import Header from './components/Header/Header';
import LeftNav from './components/LeftNav/LeftNav';
import Modal from './components/UiLibrary/Modal';
import RegistrationForm from './components/Forms/RegistrationForm';
import LoginOrRegisterModal from './components/Modals/LoginOrRegisterModal/LoginOrRegisterModal';
import CookieBanner from './components/UiLibrary/CookieBanner/CookieBanner';
import RoutesRouter from './Routes';
import { ToastContainer } from 'react-toastify';
import { EventsProvider } from './components/Context/EventsContext';  // Modificato
import NotificationProvider from './components/Context/NotificationContext';
import { useTranslation } from 'react-i18next';

function App() {
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUserDetails | null>(null);
  const providerUser = useMemo(() => ({ user, setUser }), [user, setUser]);
  const [showSignUpModal, setShowSignUpModal] = useState<ISignUpModal>({
    show: false,
  });

  const providerSignUpModal = useMemo(
    () => ({ showSignUpModal, setShowSignUpModal }),
    [showSignUpModal, setShowSignUpModal]
  );
  const [showLoginOrRegisterModal, setShowLoginOrRegisterModal] = useState<{
    message: string | null;
    show: boolean;
  }>({
    show: false,
    message: null,
  });

  const tokenSub = LocalStorageService.getWithExpiry("skp-token-sub");
  const token = LocalStorageService.getWithExpiry("skp-token");
  const refreshToken = LocalStorageService.getWithExpiry("skp-token-refresh");

  const [gdprAccepted, setGdprAccepted] = useState(LocalStorageService.getWithExpiry("skp-gdpr-accepted"));

  const playplayPage = (window.location.pathname.includes("/play-play/"));
  const { i18n } = useTranslation();

  useEffect(() => {
    if (token && refreshToken && tokenSub) {
      KeyCloackService.initTokenKeycloack(token, refreshToken);
      UserService.getUserLogin(tokenSub)
        .then((user) => {
          setUser(user);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(
            `${error?.response?.data?.message}: System cannot provide login authentication`
          );
          setShowMessageBar(true);
          setLoading(false);
        });
    } else {
      KeyCloackService.initKeycloak()
        .then((response: any) => {
          if (response.tokenParsed && response.tokenParsed.sub) {
            UserService.getUserLogin(response.tokenParsed.sub)
              .then((user) => {
                setUser(user);
                setLoading(false);
              })
              .catch((error) => {
                setErrorMessage(
                  `${error?.response?.data?.message}: System cannot provide login authentication`
                );
                setShowMessageBar(true);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          const errorMsg = error?.error
            ? error.error
            : "There is a problem with login provider!";
          setErrorMessage(errorMsg);
          setShowMessageBar(true);
        });
    }

    const eventShowLoginModal = EventBus.getInstance().addListener(
      "SHOW_LOGIN_MODAL",
      (data: any) => {
        setShowLoginOrRegisterModal({ show: true, message: data.message });
      }
    );

    return () => {
      EventBus.getInstance().removeListener(eventShowLoginModal);
    };
  }, [tokenSub, token, refreshToken]);

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.language);
    } else {
      i18n.changeLanguage(navigator.language);
    }
  }, [user, i18n]);

  const acceptCookies = () => {
    setGdprAccepted(true);
    LocalStorageService.setWithExpiry("skp-gdpr-accepted", "true", 10000000);
  };

  return (
    <div className={`App ${playplayPage ? "play-play" : ""}`}>
      <RegistrationModalContext.Provider value={providerSignUpModal}>
        <NotificationTopBar
          show={showMessageBar}
          message={errorMessage}
          onClose={() => setShowMessageBar(false)}
        />
        {loading && <div className="app-loading"></div>}
        <UserContext.Provider value={providerUser}>
          <EventsProvider>
            <NotificationProvider>
              {!loading && (
                <div className="app-container">
                  <Header />
                  <LeftNav />
                  <section className="main-section skp-bg-image">
                    <RoutesRouter />
                  </section>
                </div>
              )}
              <Modal
                show={showSignUpModal?.show}
                onClose={() => setShowSignUpModal({ show: false })}
                title="Sign Up"
                size="small"
              >
                {showSignUpModal?.type === "signup" ? (
                  <RegistrationForm
                    onFormSubmit={() => {}}
                    onClose={() => setShowSignUpModal({ show: false })}
                  />
                ):undefined}
              </Modal>
            </NotificationProvider>
          </EventsProvider>
        </UserContext.Provider>
        <ToastContainer theme="dark" autoClose={10000} hideProgressBar={true} />

        <Modal
          show={showLoginOrRegisterModal.show}
          size="small"
          onClose={() => window.location.reload()}
        >
          <LoginOrRegisterModal
            message={showLoginOrRegisterModal.message}
            onClose={() =>
              setShowLoginOrRegisterModal({ show: false, message: null })
            }
          />
        </Modal>

        {!gdprAccepted && <CookieBanner onAccept={acceptCookies} />}
      </RegistrationModalContext.Provider>
    </div>
  );
}

export default App;
