import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import INews from "../../../../Interfaces/News";
import NewsService from "../../../../../services/NewsService";
import LoadingPlaceholders from "../../../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import "./NewsPage.css"; 

const NewsPage = () => {
    const { id } = useParams<{ id: string }>(); // Per ottenere l'id dalla URL
    const [news, setNews] = useState<INews | null>(null);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        NewsService.getNewsById(id!).then((Rnews) => {
            console.log(Rnews)
            setNews(Rnews);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            // Gestisci errori (es. mostra un messaggio che la news non è stata trovata)
        });
    }, [id]);

    const handleBackClick = () => {
        navigate(-1); // Torna alla pagina precedente
    };

    return (
        <>
            <div className="skp-full-page news-page-container">
                <div className="news-page-content">
                    <button onClick={handleBackClick} className="back-button">
                        {t('common.back')}
                    </button>
                    {loading && (
                        <div className="columns">
                            <LoadingPlaceholders numberOfItems={1}>
                                <div className="column col-12">
                                    <div className={`news-loading-placeholder`}>
                                        <div className={`news-loading-title`}></div>
                                        <div className={`news-loading-content`}></div>
                                    </div>
                                </div>
                            </LoadingPlaceholders>
                        </div>
                    )}
                    {!loading && news && (
                        <div className="columns">
                            <div className="column col-12 text-center">
                                <h4 className="skp-primary-pink skp-heading">{news.title}</h4>
                                <p className="skp-text-light">{new Date(news.date).toLocaleDateString()}</p>
                                <img src={news.imageUrl} alt={news.title} className="news-full-image" />
                                <div
                                    className="skp-text-primary news-full-content"
                                    dangerouslySetInnerHTML={{ __html: news.text }}
                                ></div>
                            </div>
                        </div>
                    )}
                    {!loading && !news && (
                        <div className="columns">
                            <div className="column col-12 text-center">
                                <h6 className="skp-text-primary">{t('modals.noNewsFound')}</h6>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default NewsPage;
