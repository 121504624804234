import React, { useEffect, useState, useCallback } from "react";
import YuniteTournamentService from "../../../../../services/YuniteTournamentService";
import InfiniteScroll from "react-infinite-scroll-component";
import UserService from "../../../../../services/UserService";
import ITournament from "../../../../Interfaces/Tournament";
import Scrollbars from "react-custom-scrollbars";
import PlayPlayUser from "../Components/UserComponents/PlayPlayUser";

type Props = {
  yuniteId: string | undefined;
  tournament?: ITournament;
  allowNoLogin?: boolean;
};

function LeaderboardTab(props: Props) {
  const { yuniteId, tournament, allowNoLogin } = props;
  const [teams, setTeams] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>();
  const [paginatedItems, setPaginatedItems] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const loadLeaderboard = useCallback(() => {
    if (!yuniteId) {
      return;
    }
    setLoading(true);
    YuniteTournamentService.getCacheLeaderboard(yuniteId)

      .then((response) => {
        if (Object.keys(response).length === 0) {
          setTeams([]);
        } else {
          setTeams(response);
        }

        const index = response.length >= 30 ? 30 : response.length;
        let items:any = [];
        for (let i = 0; i < index; i++) {
          items.push(response[i]);
        }
        setPaginatedItems(items);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, [yuniteId]);

  useEffect(() => {
    loadLeaderboard();

    return () => {
      setPaginatedItems([]);
      setTeams([]);
      setSearchText(undefined);
      setLoading(false);
    };
  }, [loadLeaderboard]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginatedItems([...paginatedItems, teams[paginatedItems.length]]);
    }, 100);
  };

  const searchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      loadLeaderboard();
      return;
    }
    UserService.getUserByName(event.target.value).then((users) => {
      const usersByUsername = users;
      const findUserByEpicId = teams.filter((placement) =>
        placement.users.find((user: any) =>
          usersByUsername.find(
            (filteredUser) => filteredUser.epicId === user.epicId
          )
        )
      );

      if (findUserByEpicId.length === 0) {
        loadLeaderboard();
        return;
      }
      setTeams(findUserByEpicId);
      setPaginatedItems(findUserByEpicId);
    });
  };

  return (
    <>
      <div className="container">
        {/* LOADING */}
        {loading && (
          <>
            <div className="columns">
              <div
                className="column col-12 flex-centered"
                style={{ height: "80vh" }}
              >
                <div className="loading"></div>
              </div>
            </div>
          </>
        )}
        {/* END LOADING */}

        {!loading && teams?.length > 0 && (
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12">
              <div className="container play-table-view">
                <div className="columns pt-2 pb-2 column-gray">
                  <div className="column col-1 flex-centered">#</div>
                  <div className="column col-7 col-xs-9">Team</div>
                  <div className="column col-1 flex-centered hide-xs">
                    Games
                  </div>
                  <div className="column col-1 flex-centered hide-xs">Wins</div>
                  <div className="column col-2 flex-centered">Score</div>
                </div>

                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "calc(100vh - 110px)",
                  }}
                >
                  <Scrollbars
                    renderView={(props:any) => (
                      <div {...props} id="scrollableDiv1" />
                    )}
                  >
                    <InfiniteScroll
                      dataLength={paginatedItems.length}
                      scrollableTarget="scrollableDiv1"
                      next={fetchMoreData}
                      hasMore={teams.length > paginatedItems.length}
                      style={{ overflow: "hidden" }}
                      loader={
                        <div>
                          <div className="loading"></div>
                        </div>
                      }
                      scrollThreshold={"100px"}
                      endMessage={
                        <>
                          {paginatedItems.length >= 10 && (
                            <>
                              <p
                                className="text-center skp-text-light m-2"
                                style={{ textAlign: "center" }}
                              >
                                END
                              </p>
                            </>
                          )}
                        </>
                      }
                    >
                      {paginatedItems?.map((team) => (
                        <div
                          className="columns skp-text-primary bordered-list p-2"
                          key={team?.teamId}
                        >
                          <div className="column col-1 flex-centered">
                            {team?.placement}
                          </div>
                          <div className="column col-7 col-xs-9 leaderboard-user">
                            <div className="columns">
                              {teams && team.users[0] && (
                                <PlayPlayUser
                                  hideCheckin={true}
                                  epicId={team.users[0].epicId}
                                  teams={teams}
                                  hideLabel
                                ></PlayPlayUser>
                              )}
                            </div>
                          </div>
                          <div className="column col-1 flex-centered hide-xs">
                            {team?.games}
                          </div>
                          <div className="column col-1 flex-centered hide-xs">
                            {team?.wins}
                          </div>
                          <div className="column col-2 flex-centered">
                            {team?.score}
                          </div>
                        </div>
                      ))}
                    </InfiniteScroll>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* No Leaderboard */}
        {!loading && (teams?.length === 0 || error) && (
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-trophy"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>No Results</h5>
            </div>
            <div className="column col-12">The leaderboard is empty</div>
          </div>
        )}
      </div>
    </>
  );
}

export default LeaderboardTab;
