import { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import constant from "../../../constants";
import Modal from "../Modal";
import KeyCloackService from "../../../services/KeyCloackService";
import UserService from "../../../services/UserService";

const UserGameAccount = (props) => {
  const { user, userId, showGameAccount } = props;
  const [userGameAccount, setUserGameAccount] = useState();
  const [showGameAccountsModal, setShowGameAccountModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
      setLoading(true)
    if (KeyCloackService.isLoggedIn() && userId && userId?.length >= 24) {
      UserService.getUser(userId)
        .then((rUser) => {
          setUserProfile(rUser);
          setLoading(false);
          const gameAccount = rUser.gameAccounts?.filter(
            (account) =>
              showGameAccount?.console.includes(account.console) &&
              account.game === constant.GAME_ACCOUNTS_LABELS[showGameAccount?.game]
          );

          setUserGameAccount(gameAccount);

          if (gameAccount) {
            setSelectedValue([gameAccount[0]]);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }

    if (KeyCloackService.isLoggedIn() && user) {
        setUserProfile(user)
        setLoading(false);
          const gameAccount = user.gameAccounts?.filter(
            (account) =>
              showGameAccount?.console.includes(account.console) &&
              account.game === constant.GAME_ACCOUNTS_LABELS[showGameAccount?.game]
          );

          setUserGameAccount(gameAccount);

          if (gameAccount && !showGameAccount.selected) {
            setSelectedValue([gameAccount[0]]);
          } 
    }

    return () => {
      setUserGameAccount();
      setSelectedValue();
    };
  }, [showGameAccount, userId, user]);

  const onSelect = (selection) => {
    setSelectedValue(selection)
    
  }

  const onConfirm = () => {
    props.onChangeAccount(selectedValue[0], user ? user : userProfile)
    setShowGameAccountModal(false);
  }



  return (
    <>
      {userGameAccount?.length > 0 && (
        <div
          className="skp-chip skp-text-small"
          onClick={() => !showGameAccount.disableClick ? setShowGameAccountModal(true) : null}
        >   
            {selectedValue && !showGameAccount.selected && <>
                <i className={`la-${selectedValue[0].console}`} style={{fontSize: 20}}></i>
                <span className="ml-1">{selectedValue[0].username}</span>
            </>}

            {showGameAccount.selected && <>
                <i className={`la-${showGameAccount.selected.console}`} style={{fontSize: 20}}></i>
                <span className="ml-1">{showGameAccount.selected.username}</span>
            </>}
            
          
          
        </div>
      )}
      <Modal
        show={showGameAccountsModal}
        title="Game Accounts"
        onClose={() => setShowGameAccountModal(false)}
        onConfirm={() => onConfirm()}
        confirmation="Change Account"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12"></div>

            <div className="column col-2"></div>
            <div className="column col-8">
              <h6 className="skp-text-light text-uppercase">
                Select {showGameAccount?.game} game account
              </h6>
              <div className="skp-form-control">
                <Multiselect
                  options={userGameAccount} // Options to display in the dropdown
                  selectedValues={selectedValue} // Preselected value to persist in dropdown
                  displayValue="username" // Property name to display in the dropdown options
                  onSelect={onSelect} // Function will trigger on select event
                  // onRemove={onRemove} // Function will trigger on remove event
                  singleSelect={true}
                  closeIcon="cancel"
                  placeholder="Select a game account"
                  avoidHighlightFirstOption={true}
                />
              </div>
            </div>
            <div className="column col-2"></div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserGameAccount;
