import React, { useState, useEffect } from "react";
import {  useLocalStorage } from "@rehooks/local-storage";
import ITournament from "../Interfaces/Tournament";
import ITeam from "../Interfaces/Team";
import IGame from "../Interfaces/Game";

type Props = {
  tournament?: ITournament
  team?: ITeam
  game?: IGame
}

interface IFavourites {
  teams: ITeam[]
  tournaments: ITournament[]
  games: IGame[]
}

const AddToFavourite = (props:Props) => {
    const { tournament, team, game } = props;
    const [checked, setChecked] = useState(false)
    const [getFav, setFav] = useLocalStorage("favourites", {teams: [], tournaments: [], games: []} as IFavourites);

    useEffect(() => {
        let alreadyExist;
        if(tournament) {
          alreadyExist = getFav.tournaments.some(
            (favourite) => favourite["id"] === tournament?.id
          );
        }
        if(team) {
          alreadyExist = getFav.teams.some(
            (favourite) => favourite["id"] === team?.id
          );
        }
        if (game) {
          alreadyExist = getFav.games.some(
            (favourite) => favourite["id"] === game?.id
          );
        }
        
        if (alreadyExist) {
          setChecked(true);
        }
        return () => {
            setChecked(false);
        }
    }, [getFav, tournament, team, game])
    

    const addToFavourite = () => {
        let tournaments = [...getFav.tournaments as ITournament[]];
        let teams = [...getFav.teams as ITeam[]];
        let games = [...getFav.games as IGame[]]

        if(tournament) {
          if (checked) {
            tournaments = tournaments.filter(
              (item) => item.id !== tournament?.id
            );
          } else {
            tournaments.push(tournament);
          }
        }

        if (team) {
          if (checked) {
            teams = teams.filter(
              (item) => item.id !== team?.id
            );
          } else {
            teams.push(team);
          }
        }

        if (game) {
          if (checked) {
            games = games.filter((item) => item.id !== game?.id);
          } else {
            games.push(game);
          }
        }
        
        setFav({tournaments: tournaments, teams: teams,  games: games})
    };

    return (
      <span style={{cursor: "pointer"}}>
        <span className="tooltip tooltip-bottom" data-tooltip={!checked ? "Add to Favourites" : "Remove from Favourites"}>
        <i className={`${checked ? "skp-icon-like_full" : "skp-icon-like"} skp-text-quinternary`} onClick={addToFavourite}></i>
        </span>
        
      </span>
    );
}

export default AddToFavourite
