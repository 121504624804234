import { useContext, useEffect, useState } from "react";
import LoadingPlaceholders from "../../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import TeamTile from "../../../UiLibrary/TeamTile";
import TeamService from "../../../../services/TeamService";
import ITeam from "../../../Interfaces/Team";
import { UserContext } from "../../../Context/UserContext";
import { useTranslation } from "react-i18next";

const MyPendingTeamsTab = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const [pendings, setPendings] = useState<Array<ITeam>>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    
    
    if (!user?.id) {
      return;
    }
    setLoading(true);
    TeamService.getTeamInvitations(user.id).then((Rteams) => {
      setLoading(false);
      setPendings(Rteams)
    });
    return () => {
      setPendings([]);
      setLoading(false);
    };
  }, [user]);

  return (
    <>
      <div className="space-divider-20"></div>
      {loading && (
        <div className="columns">
          <LoadingPlaceholders numberOfItems={6}>
            <div className="column col-2 col-xs-12">
              <div className={`team-tile team-tile-loading`}>
                <div className={`team-tile-image`}>
                  <div className={`default-tile`}></div>
                </div>
              </div>
            </div>
          </LoadingPlaceholders>
        </div>
      )}
      {!loading && (
        <div className="columns">
          {/* Tiles */}

          <>
            {pendings.length > 0 &&
              pendings.map((team) => (
                <div className="column col-2 col-xs-12" key={team.id}>
                  <TeamTile team={team}></TeamTile>
                </div>
              ))}
            {pendings.length === 0 && (
              <div className="column col-12 flex-centered">
                <h6 className="skp-text-primary">{t('user.config.noTeamInvitations')}</h6>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default MyPendingTeamsTab;
