import { useEffect, useState } from "react";
import GAMES from "../../../constants-games";
import KeyCloackService from "../../../services/KeyCloackService";
import UserService from "../../../services/UserService";
import IGameAccount from "../../Interfaces/GameAccount";
import ITournament from "../../Interfaces/Tournament";
import IUserDetails from "../../Interfaces/UserDetails";
import UserGameAccount from "../User/UserGameAccount";
import UserProfile from "../UserProfile";

type Props = {
    member: string;
    members?: Array<string>;
    tournament: ITournament;
    game: string;
    removeCheckbox?: boolean
    onAddMember: (member: string, user: IUserDetails) => void;
  };
  
  const TeamRow = (props: Props) => {
    const { member, members, tournament, game, removeCheckbox } = props;
    const [user, setUser] = useState<IUserDetails>();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
  
    useEffect(() => {
      setLoading(true);
      if (KeyCloackService.isLoggedIn() && member && member?.length >= 24) {
        UserService.getUser(member, true)
          .then((Ruser) => {
            setUser(Ruser);
            setLoading(false);
            const gameAccount = Ruser.gameAccounts?.filter(
              (account: IGameAccount) =>
                tournament?.console.includes(account.console || "") &&
                account.game === GAMES[game].ENUM
            );
  
            //setDisabled(!gameAccount || gameAccount.length === 0);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
  
      return () => {
        setUser(undefined);
      };
    }, [member, game, tournament]);
  
    return (
      <>
        {!loading && user && (
          <dd
            key={member}
            onClick={() => props.onAddMember(member, user)}
            className={`column col-12 ${disabled || (members?.includes(member) && removeCheckbox) ? "disabled" : ""}`}
          >
            <div className="columns">
              <div className="column col-11">
                <UserProfile user={user}>
                  <UserGameAccount
                    user={user}
                    showGameAccount={{
                      console: tournament?.console,
                      game: game,
                      disableClick: true,
                    }}
                  />
                </UserProfile>
              </div>
              <div className="column col-1 flex-centered">
                {members && !removeCheckbox && <div className="form-group">
                  <label
                    className="form-checkbox"
                    onClick={(e) => e.preventDefault()}
                  >
                    <input
                      type="checkbox"
                      checked={members.includes(member)}
                      readOnly
                    />
                    <i className="form-icon"></i>
                  </label>
                </div>}
              </div>
            </div>
          </dd>
        )}
      </>
    );
  };

  export default TeamRow