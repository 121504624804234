import axios from "axios";
import KeyCloackService from "./KeyCloackService";

const HttpMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (KeyCloackService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${KeyCloackService.getToken()}`;
        return Promise.resolve(config);
      };
      return KeyCloackService.updateToken(cb);
    } else {
      return Promise.resolve(config);
    }   
    
  });
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
