enum MatchLevel {
  STANDARD = 0,
  SINGLE_ELIMINATIO_SEMI_FINAL = 1,
  SINGLE_ELIMINATION_FINAL = 2,
  SINGLE_ELIMINATION_THIRD_FORTH_PLACE = 3,
  DOUBLE_ELIMINATION_FINAL = 4,
  DOUBLE_ELIMINATION_GRAND_FINAL = 5,
  DOUBLE_ELIMINATION_RESET_GRAND_FINAL = 6,
  LOSER_FINAL = 7,
  DOUBLE_BRACKET = 8,
  DOUBLE_BRACKET_FINAL = 9,
}
export default MatchLevel