
const NotificationTopBar = (props) => {
    if(!props.show) {
        return null
    }
    
    return (
        <div className="skp-notification-top-bar">
            <p><i className="las la-exclamation-triangle"></i> {props.message}
                <span className="skp-notification-top-bar-close-icon" onClick={props.onClose}>
                    <i className="las la-times"></i>
                </span>
            </p>
        </div>
    )
}

export default NotificationTopBar