import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import './tournament-filter.scss';
import { useTranslation } from "react-i18next";

const TournamentFilters = (props) => {  
    const { singleSelect } = props
    const location = useLocation();
    const { t } = useTranslation()
    const [params, setParams] = useState(qs.parse(location.search));
    const [selectedValue, setSelectedValue] = useState([]);

    const initialOptions = [
      {name: t("status.upcoming"), value: "0"},
      {name: t("status.ongoing"), value: "1"},
      {name: t("status.completed"), value: "2"},
  ]

    useEffect(() => {
        let statusArray = [];
      if (params?.status) {
        initialOptions.forEach((option) => {
          if (option.value === params.status) {
              statusArray.push(option);
            
          }
          if(params.status.length > 1){
              params.status.forEach((state) => {
                if(option.value === state) {
                    statusArray.push(option);
                }
              })
          }
        });
        setSelectedValue(statusArray);
        
      }
    }, [params]);

    

    const onSelect = (options) => {
        buildQuery(options);
    }

    const onRemove = (options) => {
        buildQuery(options)
    };

    const buildQuery = (options) => {
      let status = [];
      let newParams = params
      options.forEach((option) => {
        status.push(option.value);
      });
      newParams.status = status
      setParams(newParams);
      props.onRunQuery(params)
    }

    return (
      <div className="columns">
        <div className="column col-3 col-xs-12">
          <div className="skp-form-control">
            <label className="skp-text-primary skp-text-small">{t('common.status')}</label>
            <Multiselect
              options={initialOptions} // Options to display in the dropdown
              selectedValues={selectedValue} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
              singleSelect={singleSelect || false}
              emptyRecordMsg={t('component.allOptionsSelected')}
              closeIcon={t('common.cancel')}
              placeholder={t('component.selectStatus')}
              avoidHighlightFirstOption={true}
            />
          </div>
        </div>
      </div>
    );
}

export default TournamentFilters
