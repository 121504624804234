import UserProfile from "../../UiLibrary/UserProfile";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "./chat.scss"
import { useContext, useState } from "react";
import { ChatContext } from "../../Context/ChatContext";
import ChatService from "../../../services/ChatService";
import { UserContext } from "../../Context/UserContext";
import Modal from "../../UiLibrary/Modal";
import { useNavigate } from "react-router-dom";


const ChatPageHeader = () => {
    const { contacts, chatId, chatList, refresh, setRefresh } = useContext(ChatContext);
    const { user } = useContext(UserContext)
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const selectedChat = chatList?.find((chat) => chat.id === chatId)
    console.log(selectedChat)

    const pinConversation = () => {
        ChatService.pinConversation(chatId!, user?.id!).then((chatRest) => {
            setRefresh(refresh+1)
        })
        .catch((error) => {
            console.log(error)
        });
    }

    const deleteConversation = () => {
        ChatService.deleteChat(chatId!, user?.id!).then((chatRest) => {
            setRefresh(refresh+1)
        })
        .catch((error) => {
            console.log(error)
        });
    }

    const onUserClick = (e, contact) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/users/${contact.id}/overview`);
    }
    
    return (
        <div className="skp-chat-conversation-header">
            <UserProfile user={contacts ? contacts[0]:undefined} onUserNameClick={(e) => onUserClick(e, contacts ? contacts[0] : undefined)}>
                {!contacts && (
                    <div className="skp-text-primary">no contact selected</div>
                )}    
            </UserProfile> 
            { contacts && (
                <div className="skp-chat-conversation-header-buttons">
                    {!selectedChat?.isDeleted && (
                        <button
                            className="skp-btn skp-btn-icon"
                            onClick={() => setShowModal(true)}
                        >
                            <i className="las la-video skp-chat-header-button-icon"></i>
                        </button>
                    )}
                    {!selectedChat?.isDeleted && (
                        <button
                            disabled={selectedChat?.isDeleted}
                            className="skp-btn skp-btn-icon"
                            onClick={() => setShowModal(true)}
                        >
                            <i className="las la-phone skp-chat-header-button-icon"></i>
                        </button>
                    )}
                    <button
                        className="skp-btn skp-btn-icon"
                        onClick={() => setShowModal(true)}
                    >
                        <i className="las la-search skp-chat-header-button-icon"></i>
                    </button>
                    <div className="skp-chat-header-buttons-space-divider"></div>
                    <div className="skp-text-light skp-header-inline-menu-icon">
                        <Menu
                            transition
                            menuButton={<MenuButton className="skp-btn skp-btn-icon">
                                <i className="las la-angle-down skp-chat-header-button-icon"></i>
                                </MenuButton>}
                            direction={"bottom"}
                            align={"end"}
                            position={"anchor"}
                            viewScroll={"close"}
                            arrow={true}
                            theming="dark"
                            > {/*la-eraser*/}
                            <MenuItem onClick={pinConversation}>
                                {selectedChat && selectedChat.pinned===true ? 
                                    <><i className="las la-eraser skp-menuitem-icon"></i> Unpin conversation</>: 
                                    <><i className="las la-thumbtack skp-menuitem-icon"></i> Pin conversation</>}
                                </MenuItem>
                            <MenuItem onClick={() => setShowModal(true)}><i className="las la-ban skp-menuitem-icon"></i>Block user</MenuItem>
                            <MenuItem onClick={deleteConversation}><i className="las la-trash skp-menuitem-icon"></i>Delete</MenuItem>
                        </Menu>
                    </div>
                </div>
            )}
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                title="Available Soon"
                size="small"
            >
                <div style={{textAlign: "center"}} className="skp-text-light">
                    The function will be available soon
                </div>
            </Modal>
        </div>
        
    );
};
  
export default ChatPageHeader;