import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TournamentService from "../../../services/TournamentService";
import ITournament from "../../Interfaces/Tournament";
import GenerateBracketRounds from "../../Hooks/GenerateBracketRounds";
import IMatchTournamentSeed from "../../Interfaces/MatchTournamentSeed";
import MatchCard from "../../UiLibrary/AdministrationComponents/MatchCard";
import Scrollbars from "react-custom-scrollbars";
import Modal from "../../UiLibrary/Modal";
import MatchDetailsModal from "../../Modals/MatchDetailsModal/MatchDetailsModal";
import MultiMatchDetailsModal from "../../Modals/MultiMatchDetailsModal/MultiMatchDetailsModal";
import UserSearchDropdown from "../../UiLibrary/User/UserSearchDropdown";
import IUserDetails from "../../Interfaces/UserDetails";
import { toast } from "react-toastify";
import MultiMatchCard from "../../UiLibrary/AdministrationComponents/MultiMatchCard";
import MatchService from "../../../services/MatchService";
import { UserContext } from "../../Context/UserContext";
import { EventsContext } from "../../Context/EventsContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { INotificationElement } from "../../Interfaces/notification.interface";
import { useTranslation } from "react-i18next";
import { debounce } from 'lodash';

const TournamentAdmin = () => {
  const params = useParams<{ tab: string; id: string; path?: string }>();
  const tournamentId = params.id;
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  const { 
    setTournamentNotificationId,
    tournamentNotification,
    setTournamentNotification
  } = useContext(NotificationContext);
  const [tournament, setTournament] = useState<ITournament>();
  const [loading, setLoading] = useState(false);
  const [activeRounds, setActiveRounds] = useState("winners");
  const [selRound, setSelRound] = useState(0)
  const [showCompleted, setShowCompleted] = useState(true);
  const [activeRound, setActiveRound] = useState<{
    title: string;
    seeds: IMatchTournamentSeed[];
  }>();
  const [roundWinnerNotification, setRoundWinnerNotification] = useState<Array<{
    notCounter: number,
    waitCounter: number
  }>>([])
  const [roundLooserNotification, setRoundLooserNotification] = useState<Array<{
    notCounter: number,
    waitCounter: number
  }>>([])
  const [paginatedItems, setPaginatedItems] = useState<IMatchTournamentSeed[]>(
    []
  );
  const [showMatchModal, setShowMatchModal] = useState<{
    show: boolean;
    match: IMatchTournamentSeed | undefined;
  }>({
    show: false,
    match: undefined,
  });
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const inviteUser = (searchUser: IUserDetails) => {
      if (!tournament) {
        return;
      }
      if (searchUser.id ) {
        setLoading(true);
        TournamentService.addAdmin(tournament.id, [searchUser.id])
          .then((tournament) => {
            toast.success(t('admin.newAdminAdded'));
            setLoading(false);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
            setLoading(false);
          });
      }
    setShowAddAdminModal(false);
    //setShowJoinModal({ show: true, user: user });
  };

  const { socket, isConnected } = useContext(EventsContext);

  const handleSocketEvent = debounce(() => {
    if (!tournamentId || !user || !isConnected || !socket) return;

    MatchService.getNotification(tournamentId, user.id!).then((notification) => {
      setTournamentNotification(notification.data.data);
      updateRoundNotification(notification.data.data);
    });
  }, 300); // Ritarda l'esecuzione di 300 ms

  useEffect(() => { 
    console.log("dentro useeffect 94")
    if (!tournamentId || !user || !isConnected || !socket) return;
  
    // Prima richiesta per ottenere le notifiche all'inizializzazione
    MatchService.getNotification(tournamentId, user.id!).then((notification) => {
      setTournamentNotificationId(tournamentId);
      setTournamentNotification(notification.data.data);
      updateRoundNotification(notification.data.data);
    });
  
    socket.on("n-" + tournamentId, handleSocketEvent);
  
    // Cleanup dei listener al dismount o cambio di dipendenze
    return () => {
      socket.off("n-" + tournamentId, handleSocketEvent);
    };
  }, [tournamentId, user, isConnected, socket]);

  useEffect(() => { 
    console.log( "dentro use effect 113")
    if (tournament && tournament.id && user && user.id ) {
      MatchService.getNotification(tournament!.id, user!.id!).then((notification) => {
        setTournamentNotificationId(tournament!.id)
        setTournamentNotification(notification.data.data)
      })
    }
    updateRoundNotification()
  },[tournament])

  const updateNotification = (newNotification) => {
    MatchService.setNotification(newNotification).then((notification) => {
      setTournamentNotification(notification)
      updateRoundNotification(notification)
    })
  }

  const updateRoundNotification = (theNotifications?: INotificationElement | undefined) =>  {
    const userNotification = theNotifications ? theNotifications : tournamentNotification
    if (rounds.winners.length > 0) {
      // Creiamo un array di Promesse per le chiamate asincrone
      const promises = rounds.winners.map(async (element) => {
        let idsList: string[] = [];
        for (let index = 0; index < element.seeds.length; index++) {
          idsList = idsList.concat(element.seeds[index].matchesId);
        }

        const response = await MatchService.getMatchesEvents(idsList);

        let readed = 0;
        if (userNotification && userNotification.matches) {
          readed = userNotification.matches
            .filter(match => idsList.includes(match.matchId))
            .reduce((sum, match) => sum + match.reports, 0);
        }

        return {
          notCounter: response.totalReports - readed,
          waitCounter: response.countWaitModeration
        };
      });

      // Attendere che tutte le promesse siano completate
      Promise.all(promises).then(results => {
        // Impostare lo stato con i risultati
        setRoundWinnerNotification(results);
      });
    }
    if (rounds.loosers.length > 0) {
      // Creiamo un array di Promesse per le chiamate asincrone
      const promises = rounds.loosers.map(async (element) => {
        let idsList: string[] = [];
        for (let index = 0; index < element.seeds.length; index++) {
          idsList = idsList.concat(element.seeds[index].matchesId);
        }

        const response = await MatchService.getMatchesEvents(idsList);

        let readed = 0;
        if (userNotification && userNotification.matches) {
          readed = userNotification.matches
            .filter(match => idsList.includes(match.matchId))
            .reduce((sum, match) => sum + match.reports, 0);
        }

        return {
          notCounter: response.totalReports - readed,
          waitCounter: response.countWaitModeration
        };
      });

      // Attendere che tutte le promesse siano completate
      Promise.all(promises).then(results => {
        // Impostare lo stato con i risultati
        setRoundLooserNotification(results);
      });
    }
  }


  /*const delay = 20000;
  let timer1:NodeJS.Timer ;*/

  const rounds = GenerateBracketRounds.generate(tournament?.matches);

  const updateTournament = () => {
    setTournament(undefined)
    loadTournament()
  }

  const loadTournament = useCallback(
    (tournament?: ITournament) => {
      if (tournament) {
        setTournament(tournament);
      } else {
        setLoading(true);
        TournamentService.getTournament(tournamentId!).then((tournament) => {
          setTournament(tournament);
          setLoading(false);
          const rounds = GenerateBracketRounds.generate(tournament?.matches);
        });
      }
    },
    [tournamentId]
  );

  const selectRound = useCallback(
    (roundId) => {
      setSelRound(roundId)
      const rounds = GenerateBracketRounds.generate(tournament?.matches);
      setPaginatedItems([]);
      if (!tournament) {
        return;
      }

      if (rounds.loosers.length === 0) {
        setActiveRounds("winners");
      }

      setTimeout(() => {
        const round = rounds[activeRounds][roundId];

        let items:IMatchTournamentSeed[] = [];
        //const index = round?.seeds?.length >= 10 ? 10 : round?.seeds?.length; // questo per la paginazione
        const index = round?.seeds?.length
        for (let i = 0; i < index; i++) {
          items.push(round.seeds[i]);
        }
        setPaginatedItems(items);
        setActiveRound(round);
      }, 10);

      const itemObject = { selectedRound: roundId };
      localStorage.setItem(tournament?.id, JSON.stringify(itemObject));
    },
    [tournament, activeRounds]
  );

  useEffect(() => {
    console.log( "dentro use effect 252")
    loadTournament();
  }, [loadTournament, tournamentId]);

  const closeMatchModal = () => {
      setShowMatchModal({ show: false, match: undefined });
  };

  return (
    <div className="skp-full-page">
      <div className="container">
        <div className="columns">
          <div className="column col-12 text-center">
            <Link
              to={`/tournaments/${tournamentId}/overview`}
              className="skp-link skp-text-small"
            >
              <button className="skp-btn skp-btn-secondary">
                {t('tournament.viewTournament')}
              </button>
            </Link>
            
              <button 
                style={{ marginLeft: 5, marginTop: 10 }}
                className="skp-btn skp-btn-secondary skp-text-small"
                onClick={() => setShowCompleted(!showCompleted)}
              >
                {showCompleted ? t('admin.hideCompletedMatches') : t('admin.showAllMatches') }
              </button>

              <button 
                style={{ marginLeft: 5, marginTop: 10 }}
                className="skp-btn skp-btn-secondary skp-text-small"
                onClick={() => setShowAddAdminModal(true)}
              >
                {t('players.addAdmin')}
              </button>
              
          </div>
        </div>
        {/* No matches */}
        {(tournament?.matches?.length === 0 || rounds.winners.length === 0) && (
          <div className="columns skp-text-light text-center">
            <div className="space-divider-10"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-chess"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>{t('matches.noMatches')}</h5>
            </div>
            <div className="column col-12">
              {t('matches.tournamentNoSeed')}
            </div>
          </div>
        )}

        {rounds.loosers.length > 0 && (
          <div className="columns tabs-header">
            <div className="column col-12">
              <ul className="tab tab-block skp-text-light">
                <li
                  className={`tab-item ${
                    activeRounds === "winners" ? "active" : ""
                  }`}
                >
                  <a onClick={() => setActiveRounds("winners")}>Winners</a>
                </li>
                <li
                  className={`tab-item ${
                    activeRounds === "loosers" ? "active" : ""
                  }`}
                >
                  <a onClick={() => setActiveRounds("loosers")}>Losers</a>
                </li>
              </ul>
            </div>
          </div>
        )}

        {activeRounds === "winners" && tournament?.matches.length > 0 && rounds && (
          <>
            <div className="columns">
              <div className="space-divider-10"></div>
              <div className="column col-12 text-center">
                {rounds.winners.map((round, index) => (
                  <button
                    key={round.title}
                    style={{ marginRight: 5, marginTop: 10 }}
                    className={`skp-btn ${
                      activeRound?.title === round.title
                        ? "skp-btn-primary"
                        : "skp-btn-secondary"
                    } skp-text-small`}
                    onClick={() => selectRound(index)}
                  >
                    {round.title}
                    {roundWinnerNotification && roundWinnerNotification[index] && (
                      <>
                        <a className={
                            roundWinnerNotification[index].notCounter > 0 ? "badge column" : ""
                          }
                          data-badge={Number(roundWinnerNotification[index].notCounter || 0)}></a>
                        <a className={
                            roundWinnerNotification[index].waitCounter > 0 ? "badge column" : ""
                          }
                          data-badge={roundWinnerNotification[index].waitCounter > 0 ? "!" : ""}></a>
                      </>
                    )}
                  </button>
                ))}
              </div>
              <div className="space-divider-20"></div>
            </div>
            
            <Scrollbars style={{width: "100%", height: "calc(100vh - 220px)"}} key={0} renderView={(props:any) => <div {...props} key={1} className="viewOverflowXHidden"/>}>
              <div className="columns">
                {tournament && paginatedItems.map((seed, index) => (
                  <div
                    className="column col-3 col-xs-12"
                    key={index}
                  >
                    {seed?.matchesId?.length === 1 && (
                      
                      <MatchCard
                        key={index}
                        showCompleted={showCompleted}
                        seed={seed}
                        seedId={seed.id}
                        matchId={seed.matchesId[0]}
                        onSetMatch={(match: IMatchTournamentSeed) => 
                          setShowMatchModal({ show: true, match: match })
                        }
                      />
                    )}
                    {seed?.matchesId?.length > 1 && (
                      <MultiMatchCard
                        key={index}
                        showCompleted={showCompleted}
                        seed={seed}
                        seedId={seed.id}
                        matchId={seed.matchesId[0]}
                        onSetMatch={(match: IMatchTournamentSeed) => 
                          setShowMatchModal({ show: true, match: match })
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            </Scrollbars>

            <div className="space-divider-40"></div>
          </>
        )}

        {activeRounds === "loosers" && tournament?.matches.length > 0 && rounds && (
          <>
            <div className="columns">
              <div className="space-divider-10"></div>
              <div className="column col-12 text-center">
                {rounds.loosers.map((round, index) => (
                  <button
                    key={round.title}
                    style={{ marginRight: 5, marginTop: 10 }}
                    className={`skp-btn ${
                      activeRound?.title === round.title
                        ? "skp-btn-primary"
                        : "skp-btn-secondary"
                    } skp-text-small`}
                    onClick={() => selectRound(index)}
                  >
                    {round.title} 
                    {roundLooserNotification && roundLooserNotification[index] && (
                      <a
                            className={
                              roundLooserNotification[index].notCounter > 0 ? "badge column" : ""
                            }
                            data-badge={Number(roundLooserNotification[index].notCounter || 0)}
                      ></a>)}
                  </button>
                ))}
              </div>
              <div className="space-divider-20"></div>
            </div>
            
            <Scrollbars style={{width: "100%", height: "calc(100vh - 220px)"}} key={0} renderView={props => <div {...props} className="viewOverflowXHidden"/>}>
              <div className="columns">
                {tournament && paginatedItems.map((seed, index) => (
                  <>
                    {seed?.matchesId?.length === 1 && (
                      <div
                        className="column col-3 col-xs-12"
                        key={index}
                      >
                        <MatchCard
                          key={index}
                          showCompleted={showCompleted}
                          seed={seed}
                          seedId={seed.id}
                          matchId={seed.matchesId[0]}
                          onSetMatch={(match: IMatchTournamentSeed) => 
                            setShowMatchModal({ show: true, match: match })
                          }
                        />
                      </div>
                    )}
                    {seed?.matchesId?.length > 1 && (
                      <div
                        className="column col-3 col-xs-12"
                        key={index}
                      >
                        <MultiMatchCard
                          key={index}
                          showCompleted={showCompleted}
                          seed={seed}
                          seedId={seed.id}
                          matchId={seed.matchesId[0]}
                          onSetMatch={(match: IMatchTournamentSeed) => 
                            setShowMatchModal({ show: true, match: match })
                          }
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </Scrollbars>

            <div className="space-divider-40"></div>
          </>
        )}
      </div>

      <Modal
        size="small"
        title={t('players.addAdmin')}
        show={showAddAdminModal}
        onClose={() => setShowAddAdminModal(false)}
      >
        <UserSearchDropdown setUser={inviteUser} />
      </Modal>

      <Modal
          show={showMatchModal.show}
          onClose={() => closeMatchModal()}
          title={`Match ${showMatchModal.match?.id}`}
        >
          <>
            {/* Single Match */}
            {tournament &&
              showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length === 1 && (
                <MatchDetailsModal
                  tournament={tournament}
                  matchId={showMatchModal.match?.matchesId[0]}
                  updateTournament={updateTournament}
                  closeModal={closeMatchModal}
                  updateNotification={updateNotification}
                />
              )}
            {/* Multi Match */}
            {showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length > 1 && (
                <MultiMatchDetailsModal
                  match={showMatchModal.match}
                  tournament={tournament}
                  updateNotification={updateNotification}
                />
              )}
          </>
        </Modal>
      </div>
  );
};

export default TournamentAdmin;
