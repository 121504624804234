import { useContext, useEffect, useState } from "react";
import TeamService from "../../../services/TeamService";
import { Scrollbars } from "react-custom-scrollbars";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITeam from "../../Interfaces/Team";
import { DebounceInput } from "react-debounce-input";
import UserService from "../../../services/UserService";
import IUserDetails from "../../Interfaces/UserDetails";
import UserTile from "../../UiLibrary/UserTile";
import { UserContext } from "../../Context/UserContext";
import SocialService from "../../../services/SocialService";
import { useTranslation } from "react-i18next";

const Users = () => {
  const [teams, setTeams] = useState<Array<ITeam>>([]);
  const { t } = useTranslation()
  const [users, setUsers] = useState<Array<IUserDetails>>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const [follows, setFollows] = useState<Array<string>>([])
  const { user } = useContext(UserContext)

  useEffect(() => {
    setLoading(true);
    UserService.getAllUsers({limit:24}).then((response) => {
      setUsers(response)
      
    });
    if ( user ) {
        SocialService.getFollowing(user?.id!).then((response) => {
            setFollows(response.follows)
        })
    }
    setLoading(false);
  }, []);

  const searchTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setLoading(true);
    TeamService.getAllTeams({ name: event.target.value }).then((Rteams) => {
      setTeams(Rteams);
      setLoading(false);
    });
  };

  return (
    <>
        <div className="skp-full-page">
          <div className="container">
            <div className="columns">
              <div className="column col-12 text-center">
                <h4 className="skp-primary-pink skp-heading">{t('common.users')}</h4>
              </div>
            </div>
            <div className="space-divider-20"></div>
            <div className="columns">
              <div className="column col-12 skp-primary-blue text-uppercase skp-text-xlarge">
                <strong>{t('common.filters')}</strong>
              </div>
              <div className="column col-4 col-xs-12">
                <div className="skp-form-control">
                  <label className="skp-text-primary skp-text-small">
                    {t('component.searchTeam')}
                  </label>
                  <DebounceInput
                    value={searchText}
                    type="text"
                    minLength={1}
                    debounceTimeout={500}
                    placeholder={t('modals.searchByUser')}
                    onChange={(e) => searchTeam(e)}
                  />
                </div>
              </div>
              <div className="space-divider-20"></div>
            </div>
            {loading && (
              <div className="columns">
                <LoadingPlaceholders numberOfItems={6}>
                  <div className="column col-2 col-xs-6">
                    <div className={`team-tile team-tile-loading`}>
                      <div className={`team-tile-image`}>
                        <div className={`default-tile`}></div>
                      </div>
                    </div>
                  </div>
                </LoadingPlaceholders>
              </div>
            )}
            {!loading && ( 
              <>
              <Scrollbars style={{width: "100%", height: "calc(100vh - 320px)"}} renderView={(props:any) => <div {...props} className="viewOverflowXHidden"/>}>
                <div className="columns">
                  {/* Tiles */}
                  <>
                    {users.length > 0 &&
                      users.map((theUser) => (
                        <>
                          <div className="column col-xs-1 show-xs" key={"inner"+theUser.id}></div>
                          <div className="column col-2 col-xs-4" key={theUser.id}>
                            <UserTile tileUser={theUser} isFollow={follows.includes(theUser.id!)}></UserTile>
                          </div>
                          <div className="column col-xs-1 show-xs"></div>
                        </>
                      ))}
                    {users.length === 0 && (
                      <div className="column col-12">
                        <h6 className="skp-text-primary">{t('user.noUsersAdd')}</h6>
                      </div>
                    )}
                  </>
                </div>
                </Scrollbars>
              </>
            )}
          </div>
        </div>
      
    </>
  );
};

export default Users;
