import React from 'react'
import ControlPanel from './ControlPanel'
import YuniteTournamentService from '../../../../services/YuniteTournamentService';
import { toast } from 'react-toastify';
import ITournament from '../../../Interfaces/Tournament';

type Props = {
  tournament: ITournament
  onReloadTournament: (tournament: ITournament) => void
  updateStatus: (status: number) => void | undefined
  deleteTournament: () => void
}

const YuniteControlPanel = (props:Props) => {
    const { tournament } = props

    const updateYuniteTournament = () => {
      if(!tournament.yuniteId) {
        return
      }
      YuniteTournamentService.updateYuniteTournament(
        tournament.yuniteId,
        tournament.id
      ).then((response) => {
        props.onReloadTournament(response.data.data);
        toast.success("Tournament Updated");
      });
    };

    return (
      <>
        <ControlPanel
          {...props}
          updateStatus={props.updateStatus}
          deleteTournament={props.deleteTournament}
        >
          <div className="column col-12">
            <button
              className="skp-btn skp-btn-primary"
              onClick={updateYuniteTournament}
            >
              Update Tournament
            </button>
            <div className="space-divider-20"></div>
          </div>
        </ControlPanel>
      </>
    );
}

export default YuniteControlPanel
