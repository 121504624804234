type Props = {
  platform: string
}

const SocialIcon = ({platform}:Props) => {
  
    switch (platform) {
      case "TWITTER":
        return (
          <span>
            <i className="lab la-twitter"></i>
          </span>
        );
      case "FACEBOOK":
        return (
          <span>
            <i className="lab la-facebook"></i>
          </span>
        );
      case "TWITCH":
        return (
          <span>
            <i className="lab la-twitch"></i>
          </span>
        );
        case "YOUTUBE":
        return (
          <span>
            <i className="lab la-youtube"></i>
          </span>
        );
        case "INSTAGRAM":
        return (
          <span>
            <i className="lab la-instagram"></i>
          </span>
        );
      default:
        return <span>{platform}</span>;
    }
  };

  export default SocialIcon;