import { useContext, useState } from "react";
import { Form, Field, ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import KeyCloackService from "../../services/KeyCloackService";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import "./RegistrationForm.scss";
import ErrorLabel from "./ErrorLabel";
import { Link } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import IRegisterUserData from "../Interfaces/RegisterUserData";
import { UserContext } from "../Context/UserContext";
import LocalStorageService from "../../services/LocalStorageService";
import ProfilePicture from "../UiLibrary/ProfilePicture";
import UploadImageButton from "../UiLibrary/UploadImageButton";
import UploadButton from "../UiLibrary/UploadButton/UploadButton";
import IFileBase64Image from "../Interfaces/FileBase64Image";
import ImageService from "../../services/ImageService";

const initialValues: IRegisterUserData = {
  username: "",
  email: "",
  password: "",
  recaptcha: "pippo",
};

const validationSchema = Yup.object({
  username: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  //recaptcha: Yup.string().required(),
});

type Props = {
  onFormSubmit: (data: IRegisterUserData) => void;
  onClose: () => void;
};

const RegistrationForm = (props: Props) => {
  const { user, setUser } = useContext(UserContext);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [userInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: IRegisterUserData) => {
    let { recaptcha, ...data } = values;
    setLoading(true);

    try {
      const RregisterUser = await UserService.registerUser(
        data,
        values.recaptcha
      );
      if (RregisterUser.data.status === "SUCCESS") {
        const dataR = await KeyCloackService.getAuthToken(
          values.username,
          values.password
        );
        const { access_token, refresh_token } = dataR.data;
        KeyCloackService.getAuthUserInfo(access_token).then((res) => {
          KeyCloackService.initTokenKeycloack(access_token, refresh_token).then(
            () => {
              UserService.getUserLogin(res.data.sub)
                .then((user) => {
                  setUser(user);
                  setLoading(false);
                  LocalStorageService.setWithExpiry(
                    "skp-token",
                    access_token,
                    180000
                  );
                  LocalStorageService.setWithExpiry(
                    "skp-token-refresh",
                    refresh_token,
                    180000
                  );
                  LocalStorageService.setWithExpiry(
                    "skp-token-sub",
                    res.data.sub,
                    180000
                  );
                  // props.onClose();
                  setRegistrationSuccess(true);
                })
                .catch((error) => {
                  setLoading(false);
                });
            }
          );
        });
      }
    } catch (e: any) {
      toast.error(e.response?.data?.message);
      setLoading(false);
    }
    setLoading(false)
    setRegistrationSuccess(true);

    // UserService.registerUser(data, values.recaptcha)
    //   .then((response) => {
    //     // if (response.data.status === "SUCCESS") {
    //     //   setRegistrationSuccess(true);
    //     //   setUserInfo(response.data.data);
    //     //   setLoading(false);
    //     // }
    //     // KeyCloackService.doLogin();
    //   })
    //   .catch((error) => {
    //     toast.error(error.response?.data?.message);
    //     setLoading(false);
    //   });
    // TODO: Enable line below when fixed
    // props.onFormSubmit(values);
  };

  const uploadPicture = async (image: IFileBase64Image, type: string) => {
    setLoading(true);
    let dataToPass: any = {};
    const imageResponse = await ImageService.uploadImage(image);
    const { data } = await imageResponse;
    dataToPass[type] = ImageService.generatePublicUrl(data);
    UserService.updateUserInfo(dataToPass, user?.id)
      .then((user) => {
        setUser(user);

        toast.success("User updated successfully");
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className="registration-form">
      {/* <a href={KeyCloackService.loginWithGoogle()}>Gmail</a> */}
      {!registrationSuccess && (
        <div>
          <div className="text-center skp-text-light">
            Have an account already?{" "}
            <span
              onClick={() => KeyCloackService.doLogin()}
              className="skp-link"
            >
              Log in
            </span>
          </div>
          <div className="space-divider-20"></div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isValid, setFieldValue }) => (
              <Form>
                {/* Username */}
                <div
                  className={
                    errors.username && touched.username
                      ? "skp-form-control skp-form-control-error"
                      : "skp-form-control"
                  }
                >
                  <label
                    className="skp-text-light skp-text-input-label"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <Field type="text" id="username" name="username" />
                  <ErrorMessage name="username" component={ErrorLabel} />
                </div>
                {/* Password */}
                <div
                  className={
                    errors.password && touched.password
                      ? "skp-form-control skp-form-control-error"
                      : "skp-form-control"
                  }
                >
                  <label
                    className="skp-text-light skp-text-input-label"
                    htmlFor="password"
                  >
                    Password{" "}
                    <sup
                      className="tooltip"
                      data-tooltip="Special characters allowed @ $ ! % * # ? & "
                    >
                      <i className="las la-question-circle"></i>
                    </sup>
                  </label>
                  <Field type="password" id="password" name="password" />
                  <ErrorMessage name="password" component={ErrorLabel} />
                </div>
                {/* Email */}
                <div
                  className={
                    errors.email && touched.email
                      ? "skp-form-control skp-form-control-error"
                      : "skp-form-control"
                  }
                >
                  <label
                    className="skp-text-light skp-text-input-label"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component={ErrorLabel} />
                </div>

                {/*<div className="space-divider-20"></div>
                <Recaptcha
                  sitekey="6Lc8Mw4eAAAAAJSuaadoWXSFijy91DgshxnDTnhf"
                  theme="dark"
                  render="explicit"
                  verifyCallback={(response: any) => {
                    setFieldValue("recaptcha", response);
                  }}
                  onloadCallback={() => {}}
                />*/}

                <div className="space-divider-20"></div>
                {/* <div className="skp-text-alert">
                  Sorry, we are having some temporary server issues. Please come
                  back later...
                </div> */}

                <button
                  type="submit"
                  disabled={loading || !isValid}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Sign Up"}
                </button>
                <span className="skp-text-xxsmall skp-text-light">
                  By clicking "Sign Up" you agree to Skillpro's{" "}
                  <Link
                    className="skp-link"
                    to="/terms-and-conditions"
                    onClick={props.onClose}
                  >
                    Term of use
                  </Link>{" "}
                  and{" "}
                  <Link
                    className="skp-link"
                    to="/privacy-policy"
                    onClick={props.onClose}
                  >
                    Privacy Policy
                  </Link>
                </span>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {registrationSuccess && (
        <>
          <div className="container">
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <h5 className="skp-text-primary">Welcome to Skillpro!</h5>
                <h6 className="skp-text-light">You can start uploading your profile picture!</h6>
              </div>
              <div className="column col-12 flex-centered text-center">
                <ProfilePicture user={user}>
                  <>
                    <UploadButton
                      type="logo"
                      loading={loading}
                      onUpdateImage={(image) =>
                        uploadPicture(image, "profile_picture")
                      }
                    >
                      <UploadImageButton />
                    </UploadButton>
                  </>
                </ProfilePicture>
              </div>
            </div>
          </div>
        </>
        //     <div className="text-center skp-text-light">
        //       <div className="space-divider-20"></div>
        //       <h5 className="skp-text-primary">Check your email to continue</h5>
        //       <div className="space-divider-20"></div>
        //       <div>
        //         {/* Hi <span className="skp-text-primary">{userInfo?.username}</span>! */}
        //       </div>
        //       <div>Your account has been created.</div>
        //       <div className="space-divider-20"></div>

        //       <div className="skp-text-default">
        //         We sent instructions on how to confirm your account to the email you
        //         used to set up your Skillpro Account.{" "}
        //         <span className="skp-text-primary">
        //           Check your spam folder if you don't see it within the next few
        //           minutes.
        //         </span>
        //       </div>
        //     </div>
      )}
    </div>
  );
};

export default RegistrationForm;
