import { Link } from "react-router-dom";
import GAMES from "../../../constants-games";
import GameTile from "../../UiLibrary/GameTile";
import { useTranslation } from "react-i18next";

const games = GAMES;

const gamesList = Object.keys(games).filter((game) => games[game].ENUM !== "CLASH_OF_CLANS").map((game) => (
  <div className="column col-2 col-xs-5 column-slider" key={games[game].NAME}>
    <GameTile game={games[game]}></GameTile>
  </div>
));

const BrowseGames = () => {
  const { t } = useTranslation()
  
  return (
    <div className="columns">
      <div className="column col-12">
        {/* Heading */}
        <div className="columns">
          <div className="column col-12 text-center">
            <h4 className="skp-heading skp-primary-blue">{t('home.browseGames')}</h4>
          </div>
          <div className="column col-12 text-right hide-xs" style={{ marginTop: -30 }}>
            <Link to="/games" className="skp-link-home">
              {t('home.viewAll')}
            </Link>
          </div>
        </div>
        {/* Tiles */}
        <div className="columns columns-slider">
          <div className="space-divider-20"></div>
          {gamesList}
        </div>
      </div>
    </div>
  );
};

export default BrowseGames;
