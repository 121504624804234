import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import KeyCloackService from "../../../../../services/KeyCloackService";
import { UserContext } from "../../../../Context/UserContext";
import FormikControl from "../../../../Formik/FormikControl";
import ITournament from "../../../../Interfaces/Tournament";
import Modal from "../../../../UiLibrary/Modal";
import * as Yup from "yup";
import SupercellTournamentService from "../../../../../services/SupercellTournamentService";
import ISupercellPlayer from "../../../../Interfaces/SupercellPlayer";
import InfiniteScroll from "react-infinite-scroll-component";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Link } from "react-router-dom";
import UserProfileWithDetailsModal from "../../../../UiLibrary/User/UserProfileWithDetailsModal";
import ClashRoyaleStatsTab from "../../../Users/UserTabs/ClashRoyaleStatsTab";

type Props = {
  tournament: ITournament | undefined;
  onReloadTournament: (tournament?: ITournament) => void;
};
const LeaderBoardTabs = (props: Props) => {
  const { tournament } = props;
  const { user } = useContext(UserContext);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allPlayers, setAllPlayers] = useState<ISupercellPlayer[]>([]);
  const [paginatedPlayers, setPaginatedPlayers] = useState<ISupercellPlayer[]>(
    []
  );
  const [showPlayersStatsModal, setShowPlayersStatsModal] = useState<{
    player?: ISupercellPlayer;
    show: boolean;
  }>({ show: false });

  const tournamentAdmin = tournament?.admins?.includes(user?.id!);
  const isAdmin = ((tournament?.owner === user?.id || tournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

/*  const isAdmin =
    KeyCloackService.hasRole(["Tournament Admin"]) &&
    (tournament?.owner === user?.id || tournamentAdmin);*/

  const initialValues = {
    clashRoyaleLobby: {
      tag: tournament?.clashRoyaleLobby?.tag,
      password: tournament?.clashRoyaleLobby?.password,
    },
  };

  useEffect(() => {
    if (tournament?.id && tournament?.clashRoyaleLobby?.tag) {
      setLoading(true);
      SupercellTournamentService.getPlayers(
        tournament?.id,
        tournament?.clashRoyaleLobby?.tag
      )
        .then((data) => {
          setAllPlayers(data);
          let items: Array<ISupercellPlayer> = [];
          const index = data && data.length >= 10 ? 10 : data?.length;
          if (index) {
            for (let i = 0; i < index; i++) {
              if (data[i]) {
                items.push(data[i]);
              }
            }
          }

          setPaginatedPlayers(items);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [tournament]);

  const onSubmit = (values: any) => {
    updateTournamentStatus(values);
    // props.onSubmit(values);
  };

  const validationSchema = Yup.object({
    clashRoyaleLobby: Yup.object().shape({
      tag: Yup.string().required("Required!"),
      password: Yup.string(),
    }),
  });

  const updateTournamentStatus = (dataToPass: {
    clashRoyaleLobby: { tag: string; password?: string };
  }) => {
    if (tournament?.id) {
      setLoading(true);
      SupercellTournamentService.generatePlayers(
        tournament.id,
        dataToPass.clashRoyaleLobby.tag,
        dataToPass.clashRoyaleLobby.password
      )
        .then((Rtournament) => {
          Rtournament.id = Rtournament._id;
          props.onReloadTournament(Rtournament);
          toast.success(`Tournament players generated!`);
          setLoading(false);
          setShowAddTagModal(false);
        })
        .catch((error) => {
          toast.error(`Wrong tournament tag: ${error.response?.data.message}`);
          setLoading(false);
        });
    }
  };

  const getPlayerStats = (
    event: React.MouseEvent,
    player: ISupercellPlayer
  ) => {
    event.preventDefault();
    if (!user?.id) {
      return;
    }
    setShowPlayersStatsModal({ player, show: true });
  };

  const isTournamentAdmin = (playerId: string) => {
    return tournament?.admins?.includes(playerId);
  };

  const fetchMoreData = () => {
    if (tournament) {
      setTimeout(() => {
        setPaginatedPlayers([
          ...paginatedPlayers,
          allPlayers[paginatedPlayers.length],
        ]);
      }, 100);
    }
  };

  return (
    <>
      <div className="players-tab container tab-with-table">
        {isAdmin &&
          tournament?.playerType === 0 &&
          tournament?.status === 0 && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <h6
                  className="skp-link-hover m-2"
                  onClick={() => setShowAddTagModal(true)}
                >
                  <i className="las la-plus-circle"></i> Add Tournament Tag
                </h6>
              </div>
            </div>
          )}

        {loading && (
          <>
            <div className="space-divider"></div>
            <div className="column col-12 mt-2 mb-2">
              <div className="loading"></div>
            </div>
          </>
        )}

        {!loading && allPlayers && allPlayers?.length > 0 && (
          <div className="columns">
            {KeyCloackService.isLoggedIn() && (
              <div className="column col-12">
                <div className="space-divider-20"></div>
                <h5 className="skp-text-primary">Leaderboard</h5>

                <dl>
                  <InfiniteScroll
                    dataLength={paginatedPlayers.length}
                    scrollableTarget="scrollableDiv"
                    next={fetchMoreData}
                    hasMore={allPlayers.length > paginatedPlayers.length}
                    style={{ overflow: "hidden" }}
                    loader={
                      <dd>
                        <div className="loading"></div>
                      </dd>
                    }
                    scrollThreshold={0.1}
                    endMessage={
                      <>
                        {paginatedPlayers.length >= 10 && (
                          <>
                            <p
                              className="text-center skp-text-light m-2"
                              style={{ textAlign: "center" }}
                            >
                              END
                            </p>
                          </>
                        )}
                      </>
                    }
                  >
                    <TransitionGroup className="todo-list">
                      {tournament?.members &&
                        paginatedPlayers.map((player) => (
                          <CSSTransition
                            key={`players-${player.tag}`}
                            timeout={300}
                            classNames="item"
                          >
                            <dd
                              key={player.tag}
                              className="columns flex-centered"
                            >
                              <div className="column col-1 col-xs-1 text-center">
                                <span className="skp-text-primary">
                                  {player.rank}
                                </span>
                              </div>
                              <div className="column col-7 col-xs-10">
                                <Link
                                  to={`/users/${player.skillProUser?.userId}/clash-royale-stats`}
                                >
                                  <span
                                    onClick={($event) =>
                                      getPlayerStats($event, player)
                                    }
                                  >
                                    <UserProfileWithDetailsModal
                                      userId={player.skillProUser?.userId}
                                      tournament={tournament}
                                    >
                                      {!player.skillProUser?.userId && (
                                        <span className="skp-chip skp-text-small status-awaiting mr-2 ml-2">
                                          NOT SKILLPRO
                                        </span>
                                      )}
                                      <span className="skp-chip skp-text-small status-1 mr-2">
                                        {player.name}
                                      </span>
                                      <span className="skp-chip skp-text-xxsmall status-primary hide-xs">
                                        {player.tag}
                                      </span>
                                    </UserProfileWithDetailsModal>
                                  </span>
                                </Link>
                              </div>
                              <div className="column col-4 col-xs-1 text-right">
                                <span className="skp-text-primary">
                                  {player.score}
                                </span>
                              </div>
                            </dd>
                          </CSSTransition>
                        ))}
                    </TransitionGroup>
                  </InfiniteScroll>
                </dl>
              </div>
            )}
            <div className="space-divider-40"></div>
            {/* Login button */}
            {!KeyCloackService.isLoggedIn() && (
              <div className="column col-12 text-center">
                <div className="skp-text-light">
                  You must log in to see Players information.
                </div>
                <div className="space-divider-20"></div>
                {/* <button className="skp-btn skp-btn-primary">Login</button> */}
              </div>
            )}
          </div>
        )}
        {!loading && allPlayers?.length === 0 && (
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-user-friends"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>No players</h5>
            </div>
            <div className="column col-12">
              There are currently no Players registered for this Tournament.
            </div>
          </div>
        )}
      </div>

      <Modal
        size="large"
        title="Add Tournament Tag"
        show={showAddTagModal}
        onClose={() => setShowAddTagModal(false)}
      >
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched, isValid, setFieldValue }) => (
              <Form>
                <div className="container">
                  <h6 className="skp-text-light text-center">
                    Update Clash Royale tournament's tag
                  </h6>
                  <div className="columns skp-text-primary">
                    <div className="space-divider-20"></div>

                    <div className="column col-12">
                      <div className="columns">
                        <div className="column col-12">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Tournament Tag"
                            name="clashRoyaleLobby.tag"
                            placeholder="#2YPV9UC0"
                          ></FormikControl>
                        </div>
                        <div className="space-divider-20"></div>
                        <div className="column col-12">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Tournament Password"
                            name="clashRoyaleLobby.password"
                            placeholder=""
                          ></FormikControl>
                        </div>
                      </div>
                    </div>

                    <div className="space-divider"></div>

                    <div className="column col-12 text-right">
                      <button
                        type="submit"
                        className="skp-btn skp-btn-primary"
                        disabled={!isValid || loading}
                      >
                        Update Tournament
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </Modal>

      <Modal
        show={showPlayersStatsModal.show}
        title="Clash Royale Stats"
        onClose={() => setShowPlayersStatsModal({ show: false })}
      >
        <>
          {user?.id && showPlayersStatsModal?.player?.tag && (
            <ClashRoyaleStatsTab
              userId={user?.id}
              playerTag={showPlayersStatsModal?.player?.tag}
            ></ClashRoyaleStatsTab>
          )}
        </>
      </Modal>
    </>
  );
};

export default LeaderBoardTabs;
