import { useEffect, useState } from "react";
import "../TournamentTabs/tournaments-tab.scss";
import KeyCloackService from "../../../../services/KeyCloackService";
import { Link } from "react-router-dom";
import YuniteTournamentService from "../../../../services/YuniteTournamentService";
import InfiniteScroll from "react-infinite-scroll-component";
import YuniteConfirmedLabel from "../../../UiLibrary/YuniteConfirmedLabel";
import UserProfileWithDetailsModal from "../../../UiLibrary/User/UserProfileWithDetailsModal";
import ITournamentYunite from "../../../Interfaces/TournamentYunite";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentYuniteTeam from "../../../Interfaces/TournamentYuniteTeam";

type Props = {
  tournament: ITournament | undefined
  onLogin: () => void
}

const PlayerTab = (props:Props) => {
  const { tournament } = props;
  const [teams, setTeams] = useState<Array<ITournamentYuniteTeam>>([]);
  const [paginatedItems, setPaginatedItems] = useState<Array<string>>([]);

  useEffect(() => {
    if(!tournament) {
      return
    }
    if (tournament?.yuniteId) {
      YuniteTournamentService.getCacheTeams(tournament?.yuniteId).then(
        (response) => {
          setTeams(response);
        }
      );
    }

    let items:any = [];
    const index =
      tournament?.members.length >= 10 ? 10 : tournament?.members.length;
    for (let i = 0; i < index; i++) {
      items.push(tournament?.members[i]);
    }
    setPaginatedItems(items);

    return () => {
      setPaginatedItems([]);
      setTeams([]);
    };
  }, [tournament]);

  const fetchMoreData = () => {
    if(!tournament) {
      return
    }
    setTimeout(() => {
      setPaginatedItems([
        ...paginatedItems,
        tournament.members[paginatedItems.length],
      ]);
    }, 100);
  };

  return (
    <div className="players-tab container tab-with-table">
      <div className="space-divider-20"></div>
      <div className="columns">
        <div className="column col-4 col-xs-12">
          <h5 className="skp-text-primary">Registered on Skillpro</h5>
          <dl className="skp-text-small skp-text-light">
            <dd>
              In questa sezione sono elencati tutti i nomi degli iscritti al
              torneo.{" "}
            </dd>
            <dd>
              ATTENZIONE! Per poter partecipare al torneo attendi il periodo di
              Check-In (vai nella scheda Overview per vedere quando inizia il
              periodo di Check In dell’evento) e conferma la tua presenza sul
              Discord di Skillpro prima che inizi l’evento.{" "}
            </dd>
            <dd>Completa i seguenti passaggi per effettuare il Check In: </dd>
            <ol className="skp-text-small skp-text-light">
              <li>
                Una volta iniziato il periodo di Check In, accedi al Discord di
                Skillpro e clicca sulla categoria dell’evento (la categoria ha
                lo stesso nome del torneo presente su Skillpro).
              </li>
              <li>
                Clicca su “registrazione-team” e successivamente sulla “T” del
                messaggio di Yunite
              </li>
              <li>
                Segui la procedura che arriva in DM su Discord (basta cliccare
                il pulsante “+” nel messaggio che arriva direttamente da
                Yunite).
              </li>
            </ol>
          </dl>
        </div>
        <div className="column col-8 col-xs-12">
          <h5 className="skp-text-primary">Players</h5>
          {tournament && tournament?.members?.length > 0 && (
            <div className="columns">
              <div className="column col-12">
                <InfiniteScroll
                  dataLength={paginatedItems.length}
                  scrollableTarget="scrollableDiv"
                  next={fetchMoreData}
                  hasMore={tournament?.members.length > paginatedItems.length}
                  style={{ overflow: "hidden" }}
                  loader={
                    <dd>
                      <div className="loading"></div>
                    </dd>
                  }
                  scrollThreshold={"100px"}
                  endMessage={
                    <>
                      {paginatedItems.length >= 10 && (
                        <>
                          <p
                            className="text-center skp-text-light m-2"
                            style={{ textAlign: "center" }}
                          >
                            END
                          </p>
                        </>
                      )}
                    </>
                  }
                >
                  <dl>
                    {paginatedItems &&
                      paginatedItems.map((playerId) => (
                        <dd key={playerId} className="columns flex-centered">
                          <div className="column col-12">
                            <Link to={`/users/${playerId}/overview`}>
                              <UserProfileWithDetailsModal userId={playerId} tournament={tournament}>
                                {tournament?.yuniteId &&
                                  KeyCloackService.isLoggedIn() && (
                                    <YuniteConfirmedLabel
                                      teams={teams}
                                      playerId={playerId}
                                    />
                                  )}
                              </UserProfileWithDetailsModal>
                            </Link>
                          </div>
                        </dd>
                      ))}
                  </dl>
                </InfiniteScroll>
              </div>
              <div className="space-divider-40"></div>
              {/* Login button */}
              {!KeyCloackService.isLoggedIn() && (
                <div className="column col-12 text-center">
                  <div className="skp-text-light">
                    You must log in to see Players information.
                  </div>
                  <div className="space-divider-20"></div>
                  <button
                    className="skp-btn skp-btn-primary"
                    onClick={props.onLogin}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          )}
          {tournament?.members?.length === 0 && (
            <div className="columns skp-text-light text-center">
              <div className="space-divider"></div>
              <div className="column col-12">
                <h1>
                  <i className="las la-user-friends"></i>
                </h1>
              </div>
              <div className="column col-12">
                <h5>No players</h5>
              </div>
              <div className="column col-12">
                There are currently no Players registered for this Tournament.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerTab;
