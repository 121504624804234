import { useContext, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Dropdown from "../../UiLibrary/Dropdown";
import { UserContext } from "../../Context/UserContext";
import TeamService from "../../../services/TeamService";
import ITeam from "../../Interfaces/Team";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {};

const NotificationDropdown = (props: Props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation()
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [pendings, setPendings] = useState<Array<ITeam>>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    setLoading(true);
    TeamService.getTeamInvitations(user.id).then((Rteams) => {
      setLoading(false);
      setPendings(Rteams);
    });
    return () => {
      setPendings([]);
      setLoading(false);
    };
  }, [user]);

  const goToTeamPage = (teamId: string) => {
    navigate(`/teams/${teamId}/overview`);
    setShow(false);
  };

  const acceptInvitation = (team: ITeam) => {
    if (!user?.id) {
      return;
    }
    TeamService.acceptMember(team.id, user.id).then((response) => {
      toast.success(t('toast.teamJoinSuccess'))
      navigate(`/teams/${team.id}/overview`);
      setShow(false);

      TeamService.getTeamInvitations(user.id!).then((Rteams) => {
        setPendings(Rteams);
      });
    });
  };

  const declineInvitation = (team: ITeam) => {
    if (!user?.id) {
      return;
    }
    TeamService.declineMember(team.id, user.id).then((response) => {
      setShow(false);
      toast.success(t('toast.teamDeclined'))

      TeamService.getTeamInvitations(user.id!).then((Rteams) => {
        setPendings(Rteams);
      });
    });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setShow(false)}>
      <button
        className="skp-btn skp-btn-circle mr-2 ml-2"
        onClick={() => setShow(true)}
      >
        {pendings.length > 0 && (
          <sup className="flex-centered">{pendings.length}</sup>
        )}
        <i className="skp-icon-notifications"></i>
      </button>
      <Dropdown show={show}>
        <div
          className="close-icon show-xs skp-text-light"
          onClick={() => setShow(false)}
        >
          <i className="las la-times"></i>
        </div>
        <h5 className="skp-text-primary mb-2">{t('header.notifications')}</h5>

        {pendings.length === 0 && <div className="skp-text-light flex-centered" style={{height: 100}}>{t('header.noNotifications')}</div>}

        {pendings.length > 0 && (
          <>
            <h6 className="skp-text-light skp-text-small text-uppercase mt-2">
              {t('header.teamInvitations')}
            </h6>
            <ul className="container">
              {pendings.map((team) => (
                <li className="columns">
                  <span className="column col-3">
                    <span className="icon-wrapper">
                      {!team.logo && <i className="skp-icon-teams"></i>}
                      {team?.logo && <img src={team.logo}></img>}
                    </span>
                  </span>
                  <span
                    className="column col-6"
                    onClick={() => goToTeamPage(team.id)}
                  >
                    <div className="skp-text-primary">{team.name}</div>
                    <div className="skp-text-light skp-text-small">
                      {t('header.joinTeam')}
                    </div>
                  </span>
                  <span className="column col-3 flex-centered skp-text-xlarge">
                    <span
                      className="skp-text-ternary m-2 tooltip"
                      data-tooltip="Accept Invitation"
                      onClick={() => acceptInvitation(team)}
                    >
                      <i className="las la-check-circle"></i>
                    </span>

                    <span
                      className="skp-text-quaternary m-2 tooltip"
                      data-tooltip="Decline Invitation"
                      onClick={() => declineInvitation(team)}
                    >
                      <i className="las la-ban"></i>
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          </>
        )}
      </Dropdown>
    </OutsideClickHandler>
  );
};

export default NotificationDropdown;
