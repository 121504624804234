import React, { useEffect, useState } from 'react'
import ITeam from '../Interfaces/Team';
import IBrand from '../Interfaces/Brand';
import { useTranslation } from 'react-i18next';

type Props = {
	team?: ITeam | undefined,
	brand?: IBrand | undefined,
	children?: JSX.Element
}

const TeamBrandLogo = ({team, brand, children}:Props) => {
	const { t } = useTranslation()
	const initials = team? team.name.charAt(0) : brand?.name.charAt(0)
	const [imageError, setImageError] = useState(false);

	return (
	<figure className="avatar p-relative">
		{((team && !team.logo) || (brand && !brand.logo) || imageError) && 
			<span className="text-uppercase">
				{!initials ? <i className="las la-user"></i> : initials}
			</span>
		}
		
		{((team && team.logo) || (brand && brand.logo)) && !imageError && (
			<img
				alt={team? t('teams.config.altImage') : t('brands.config.altImage')}
				src={team? team.logo : brand?.logo}
				onError={() => setImageError(true)}
			/>
		)}
		{children}
	</figure>
	);
}

export default TeamBrandLogo
