import { useState } from "react";
import ITournament from "../../../../../Interfaces/Tournament";
import Modal from "../../../../../UiLibrary/Modal";
import Moment from "moment";
import ILobbyGame from "../../../../../Interfaces/LobbyGame";
import ITournamentLobby from "../../../../../Interfaces/TournamentLobby";
import GAMES from "../../../../../../constants-games";
import LobbyGameDetailModal from "../Modals/LobbyGameDetailModal";
import AdminLeaderboardModal from "../Modals/AdminLeaderboardModal";

type Props = {
  tournament: ITournament;
  game: ILobbyGame;
  index: number;
  isAdmin: boolean;
  isMember?: boolean;
  lobby: ITournamentLobby;
  editGame: (e: any, index: number) => void;
  onReloadTournament: (tournament?: ITournament) => void;
};

const LobbyGameCard = (props: Props) => {
  const { tournament, game, index, isAdmin, isMember, lobby } = props;
  const [loadingMatch, setLoadingMatch] = useState(false);
  const [showGameModal, setShowGameModal] = useState<{
    show: boolean;
    index?: number | undefined;
  }>({
    show: false,
  });

  const [showAdminLeaderboardModal, setShowAdminLeaderboardModal] = useState<{show: boolean, title?: string}>({
    show: false,
    title: "Admin Leaderboard",
  });
  
  const nowDate = new Date();
  const gameCompleted = new Date(game.end_game) < nowDate;
  
  const onGameClick = (match: ILobbyGame) => {
    setShowGameModal({
        show: true,
        index: game?.gameNumber,
    });
  };

  const adminGame = (e:any, index:number) => {
    e.stopPropagation();
    e.preventDefault();
    
    setShowAdminLeaderboardModal({
      show: true,
      title: "Admin Leaderboard",
    });
  };

  return (
    <>
      <div
        className="column col-4 col-xs-12"
        key={`game-${index}${game.gameNumber}`}
        onClick={() => onGameClick(game)}
      >
        <div
          className="card skp-card skp-card-hover"
          style={{
            cursor: "pointer",
            minHeight: 250,
            position: "relative",
          }}
        >
          <div className="card-header">
            <div className="columns">
              <div className="column col-12">
                <h6 className="skp-text-primary text-uppercase">
                  Game {game.gameNumber} 
                  {game.map_name && game.map_name && (
                    " - MAP: " + game.map_name
                  )}
                </h6>
              </div>
              <div className="column col-6 flex-centered">
                {loadingMatch && <div className="loading"></div>}
              </div>
            </div>
          </div>
          <div className="card-image flex-centered" style={{ position: "absolute", top: 25 }}>
            <img
              style={{ height: "100%" }}
              className="img-responsive"
              alt="game-logo"
              src={GAMES[tournament?.game]?.LOGO_URL}
            />
          </div>
          <div className="card-body" style={{ position: "relative" }}>
            {!isAdmin && isMember && game.player_password && (
              <div className="columns skp-text-light flex-centered">
                <>
                  <div className="column col-12 skp-text-small">Start Date:</div>
                  <div className="column col-12">
                    <h6 className="skp-text-primary">{Moment(game.start_game).format("MMM DD, hh:mm A")}{" "}</h6>
                  </div>
                </>
                {game.room_id && game.room_name && (
                  <>
                    <div className="column col-12 skp-text-small">Room ID - Room Name:</div>
                    <div className="column col-12">
                      <h6 className="skp-text-primary">{game.room_id}{" - "}{game.room_name}</h6>
                    </div>
                  </>
                )}
                {!gameCompleted && (
                  <>
                    <div className="column col-12">Game Password</div>
                    <div className="column col-12">
                      <h5 className="skp-text-primary">{game.player_password}</h5>
                    </div>
                  </>
                )}
                {gameCompleted && (
                  <>
                    <div className="column col-12">
                      <h6 className="skp-text-primary skp-text-ternary">
                        <i className="las la-check-circle"></i> Completed
                      </h6>
                    </div>
                  </>
                )}
              </div>
            )}
            {isAdmin && (
              <div className="columns">
                <div className="column col-12">
                  <span className="skp-text-light skp-text-small">
                    Player Password:{" "}
                    <h6 className="skp-text-primary">{game.player_password}</h6>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer" style={{ position: "relative", zIndex: 1 }}>
            {isAdmin && (
              <div className="columns">
                <div className="column col-12 text-center">
                  <button
                    onClick={(e) => props.editGame(e, index)}
                    className="column col-4 skp-btn skp-btn-secondary mr-1"
                  >
                    Edit
                  </button>
                  <button
                    onClick={(e) => adminGame(e, index)}
                    className="column col-4 skp-btn skp-btn-primary ml-1"
                  >
                    Admin
                  </button>
                </div>
              </div>
            )}
            {!isAdmin && (
              <span className="skp-link-primary skp-text-small">
                Click to see game details
              </span>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showGameModal.show}
        title={`Game ${showGameModal.index}`}
        onClose={() => setShowGameModal({ show: false })}
      >
        <LobbyGameDetailModal
          tournament={tournament}
          lobby={lobby}
          game={game}
          isAdmin={isAdmin}
        />
      </Modal>

      <Modal
        title={showAdminLeaderboardModal.title}
        show={showAdminLeaderboardModal.show}
        onClose={() =>
          setShowAdminLeaderboardModal({
            show: false
          })
        }
      >
        <AdminLeaderboardModal
          tournament={tournament}
          lobby={lobby}
          game={game}
          onReloadTournament={props.onReloadTournament}
        />
      </Modal>
    </>
  );
};

export default LobbyGameCard;
