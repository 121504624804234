import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import OutsideClickHandler from "react-outside-click-handler";
import ProfilePicture from "./ProfilePicture";
import { UserContext } from "../Context/UserContext";
import { useTranslation } from "react-i18next";

const ProfileDropdown = () => {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation()

  return (
    <OutsideClickHandler onOutsideClick={() => setShow(false)}>
      <div className="profile-dropdown">
        <span onClick={() => setShow(!show)}>
          {user && <ProfilePicture user={user} />}
          <i className="las la-angle-down hide-xs"></i>
        </span>
        <Dropdown show={show}>
          <div
            className="close-icon show-xs skp-text-light"
            onClick={() => setShow(false)}
          >
            <i className="las la-times"></i>
          </div>
          <ul>
            <li onClick={() => setShow(false)}>
              <Link to={`/users/${user?.id}/overview`}>
                {user && <ProfilePicture user={user}></ProfilePicture>}
                {user?.username}
              </Link>
            </li>
            <li className="list-divider">&nbsp;</li>
            <li onClick={() => setShow(false)}>
              <Link to={`/users/${user?.id}/settings/usersettings`}>
                <i className="skp-icon-settings"></i> {t('header.settings')}
              </Link>
            </li>
            <li className="list-divider">&nbsp;</li>
            <li onClick={() => setShow(false)}>
              <Link to={`/users/${user?.id}/my-tournaments/hosting`}>
                <i className="skp-icon-tournaments"></i> {t('header.tournament')}
              </Link>
            </li>
            <li className="list-divider">&nbsp;</li>
            <li onClick={() => setShow(false)}>
              <Link to={`/users/${user?.id}/my-teams`}>
                <i className="skp-icon-teams"></i> {t('header.team')}
              </Link>
            </li>
            <li className="list-divider">&nbsp;</li>
            <li onClick={() => setShow(false)}>
              <Link to={`/users/${user?.id}/payments`}>
              <i className="skp-icon-payments"></i> {t('header.payments')}
              </Link>
            </li>
            <li className="list-divider">&nbsp;</li>
            <li>
              <Link to={`/logout`}>
                <i className="skp-icon-log_out"></i> {t('common.logout')}
              </Link>
            </li>
          </ul>
        </Dropdown>
      </div>
    </OutsideClickHandler>
  );
};

export default ProfileDropdown;
