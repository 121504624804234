import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";
import SocialIcon from "../SocialIcon";
import UserTournamentGameAccount from "../User/UserTournamentGameAccount";
import UserProfile from "../UserProfile";

const SingleMatchUserTooltip = (props) => {
  const { tournament, userId } = props;
  const [user, setUser] = useState();

  useEffect(() => {
    if( userId && userId?.length >= 24) {
    UserService.getUser(userId).then((rUser) => {
      setUser(rUser);
    });
    }
  });

  return (
    <div className="container user-profile-modal">
      {/* IMAGE BANNER */}
      <div className="skp-image-banner" style={{ height: 100}}>
            {user?.profile_banner && (
              <img alt="profile-banner" src={user?.profile_banner} />
            )}
            <div className="transparent-black"></div>
          </div>
          {/* END IMAGE BANNER */}
      <div className="columns text-center">
        <div className="column col-12 m-2">
          <Link to={`/users/${userId}/overview`}>
            <UserProfile user={user} />
          </Link>
        </div>
        <div className="column col-12 m-2 skp-text-small skp-text-light">
          {tournament?.game} game account
        </div>
        <div className="column col-12">
          <UserTournamentGameAccount tournament={tournament} userId={userId} />
        </div>
        {user?.social_networks.length > 0 && <>
          <div className="column col-12 m-2 skp-text-small skp-text-light">
            <div className="space-divider-20"></div>
            Social Accounts
          </div>
          <div className="column col-12">
            {user?.social_networks.map((account) => (
              <a
                href={account.url}
                target="_blank"
                rel="noreferrer"
                className="skp-link-hover d-inline-block ml-2 mr-2"
              >
                <h4>
                  <SocialIcon platform={account.platform} />
                </h4>
              </a>
            ))}
          </div>
        </>}
      </div>
    </div>
  );
};

export default SingleMatchUserTooltip;
