import { useState } from "react";
import ETeamPlayerRole from "../../../enums/TeamPlayerRole";
import ETeamPlayerStatus from "../../../enums/TeamPlayerStatus";
import ITournament from "../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../Interfaces/TournamentMemberStatus";
import Modal from "../../UiLibrary/Modal";
import UserProfileWithDetailsModal from "../../UiLibrary/User/UserProfileWithDetailsModal";

type Props = {
  tournament: ITournament;
  userStatus: ITournamentMemberStatus;
};
const TournamentTeamIssues = (props: Props) => {
  const { userStatus, tournament } = props;
  const [showModal, setShowModal] = useState(false);

  const teamErrors = tournament?.teams.filter(
    (team) => team.members.length < tournament?.teamSize
  );

  const teamStatusErrors = tournament?.teams.filter((team) =>
    team.members.find((member) => Number(member.status) === 0)
  );

  return (
    <>
      {userStatus.isAdmin && (teamErrors.length > 0 || teamStatusErrors.length > 0) && (
        <>
          <div className="columns mt-2 mb-2">
            <div className="column col-12 text-right">
              <button
                className="skp-btn skp-btn-alert"
                onClick={() => setShowModal(true)}
              >
                <i className="las la-exclamation-triangle"></i> Team Issues
              </button>
            </div>
          </div>

          <Modal
            show={showModal}
            title="Team Issues"
            onClose={() => setShowModal(false)}
          >
            <>
              <div className="container">
                <div className="columns">
                  <div className="space-divider-20"></div>
                  <div className="column col-12">
                    <h5 className="skp-text-primary mt-2 mb-2">
                      No enough members
                    </h5>
                  </div>
                  <div className="column col-12">
                    <dl className="bordered-list">
                      {tournament &&
                        teamErrors.length > 0 &&
                        teamErrors.map((team, index) => (
                          <dd key={`team-errors-${index}`} className="columns">
                            <div className="column col-6 skp-text-primary">
                              {!team.partyName && <h5>Team</h5>}
                              {team.partyName && (
                                <h5>
                                  <div className="skp-text-label skp-text-light text-uppercase">
                                    Team{" "}
                                  </div>{" "}
                                  {team.partyName}
                                </h5>
                              )}
                            </div>
                            <div
                              className="column col-6 flex-centered text-warning"
                              style={{ justifyContent: "right" }}
                            >
                              <div className="skp-text-small">
                                <i className="las la-exclamation-triangle"></i>
                                Team has {team.members.length} member
                                {team.members.length > 1 && <>s</>},{" "}
                                {tournament.teamSize} required
                              </div>
                            </div>
                          </dd>
                        ))}
                    </dl>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="columns">
                  <div className="space-divider-20"></div>
                  <div className="column col-12">
                    <h5 className="skp-text-primary mb-2 mt-2">
                      Pending members
                    </h5>
                  </div>
                  <div className="column col-12">
                    <dl>
                    {tournament &&
                      teamStatusErrors.length > 0 &&
                      teamStatusErrors.map((team, index) => (
                        <dd key={`team-errors-${index}`} className="columns">
                          <div className="column col-8 skp-text-primary">
                            {!team.partyName && <h5>Team</h5>}
                            {team.partyName && (
                              <h5>
                                <div className="skp-text-label skp-text-light text-uppercase">
                                  Team{" "}
                                </div>{" "}
                                {team.partyName}
                              </h5>
                            )}
                          </div>
                          <div
                            className="column col-4 flex-centered"
                            style={{ justifyContent: "right" }}
                          ></div>
                          <div className="column col-12">
                            <dl className="bordered-list">
                              {team.members
                                .filter((member) => Number(member.status) === 0)
                                .map((member, index) => (
                                  <dd
                                    className="columns"
                                    key={member + index.toString()}
                                  >
                                    <div className="column col-8">
                                      <UserProfileWithDetailsModal
                                        userId={member.userId}
                                        tournament={tournament}
                                      >
                                        <span
                                          className={`skp-chip status-${
                                            ETeamPlayerRole[member.role]
                                          } skp-text-small`}
                                        >
                                          {ETeamPlayerRole[member.role]}
                                        </span>

                                        {member.userId === team.leader && (
                                          <span className="skp-chip status-captain skp-text-small">
                                            Leader
                                          </span>
                                        )}
                                      </UserProfileWithDetailsModal>
                                    </div>
                                    <div
                                      className="column col-4 flex-centered"
                                      style={{ justifyContent: "right" }}
                                    >
                                      <span
                                        className={`skp-chip status-${
                                          ETeamPlayerStatus[member.status]
                                        } skp-text-small`}
                                      >
                                        {ETeamPlayerStatus[member.status]}
                                      </span>
                                    </div>
                                  </dd>
                                ))}
                            </dl>
                          </div>
                        </dd>
                      ))}
                      </dl>
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default TournamentTeamIssues;
