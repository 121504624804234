import { useState } from "react";
import ApexTournamentService from "../../../../../../services/ApexTournamentService";
import IApexLobbyGameToken from "../../../../../Interfaces/ApexLobbyGameToken";
import ITournament from "../../../../../Interfaces/Tournament";
import ITournamentApexLobby from "../../../../../Interfaces/TournamentApexLobby";
import ITournamentApexRespawnMatch from "../../../../../Interfaces/TournamentApexRespawnMatch";
import Modal from "../../../../../UiLibrary/Modal";
import Moment from "moment";
import ApexGameDetailModal from "../Modals/ApexGameDetailModal";
import ApexMatchDetailModal from "../Modals/ApexMatchDetailModal";

type Props = {
  tournament: ITournament;
  game: IApexLobbyGameToken;
  index: number;
  isAdmin: boolean;
  isMember?: boolean;
  lobby: ITournamentApexLobby;
  editGame: (e: any, index: number) => void;
};

const ApexGameCard = (props: Props) => {
  const { tournament, game, index, isAdmin, isMember, lobby } = props;
  const [loadingMatch, setLoadingMatch] = useState(false);
  const [showGameModal, setShowGameModal] = useState<{
    show: boolean;
    gameMatches?: Array<ITournamentApexRespawnMatch> | undefined;
    index?: number | undefined;
  }>({
    show: false,
    gameMatches: undefined,
  });
  const [showLeaderboardModal, setShowLeaderboardModal] = useState<{
    show: boolean;
    match?: ITournamentApexRespawnMatch | undefined;
  }>({
    show: false,
    match: undefined,
  });

  const nowDate = new Date();
  const gameCompleted = new Date(game.end_game) < nowDate;


  const onGameClick = (match: IApexLobbyGameToken) => {
    const matchLobby = lobby?.matches?.find((element) =>
      Object.keys(element).includes(match?.api!)
    );

    if (match?.api && matchLobby && matchLobby[match.api].length > 0) {
      if (matchLobby[match?.api]) {
        setLoadingMatch(false);
        setShowGameModal({
          show: true,
          gameMatches: matchLobby[match?.api],
          index: game?.gameNumber,
        });
      }
    } else {
      if (!match?.api) {
        return;
      }
      setLoadingMatch(true);
      ApexTournamentService.getMatches(
        tournament?.id,
        match?.api,
        lobby.id,
        match?.gameNumber
      )
        .then((Rmatches) => {
          setLoadingMatch(false);
          setShowGameModal({
            show: true,
            gameMatches: Rmatches,
            index: game?.gameNumber,
          });
        })
        .catch((error) => {
          setLoadingMatch(false);
        });
    }
  };

  return (
    <>
      <div
        className="column col-4 col-xs-12"
        key={`game-${index}${game.gameNumber}`}
        onClick={() => onGameClick(game)}
      >
        <div
          className="card skp-card skp-card-hover"
          style={{
            cursor: "pointer",
            minHeight: 250,
            position: "relative",
          }}
        >
          <div className="card-header">
            <div className="columns">
              <div className="column col-6">
                <h6 className="skp-text-primary text-uppercase">
                  Game {game.gameNumber}
                </h6>
              </div>
              <div className="column col-6 flex-centered">
                {loadingMatch && <div className="loading"></div>}
              </div>
            </div>
          </div>
          <div className="card-image flex-centered" style={{ position: "absolute", top: 25 }}>
            <img
              style={{ height: "100%" }}
              className="img-responsive"
              alt="apex-logo"
              src="https://1000marche.net/wp-content/uploads/2021/10/Apex-Legends-logo.png"
            />
          </div>
          <div className="card-body" style={{ position: "relative" }}>
            {!isAdmin && isMember && game.player && (
              <div className="columns skp-text-light flex-centered">
                <>
                    <div className="column col-12 skp-text-small">Start Date:</div>
                    <div className="column col-12">
                      <h6 className="skp-text-primary">{Moment(game.start_game).format("MMM DD, hh:mm A")}{" "}</h6>
                    </div>
                    <div className="column col-12 skp-text-small">End Date:</div>
                    <div className="column col-12">
                      <h6 className="skp-text-primary">{Moment(game.end_game).format("MMM DD, hh:mm A")}{" "}</h6>
                    </div>
                  </>
                {!gameCompleted && (
                  <>
                    <div className="column col-12">Game Password</div>
                    <div className="column col-12">
                      <h5 className="skp-text-primary">{game.player}</h5>
                    </div>
                  </>
                )}
                {gameCompleted && (
                  <>
                    <div className="column col-12">
                      <h6 className="skp-text-primary skp-text-ternary">
                        <i className="las la-check-circle"></i> Completed
                      </h6>
                    </div>
                  </>
                )}
              </div>
            )}
            {isAdmin && (
              <div className="columns">
                <div className="column col-12">
                  <span className="skp-text-light skp-text-small">
                    Player Password:{" "}
                    <h6 className="skp-text-primary">{game.player}</h6>
                  </span>
                </div>
                <div className="column col-12">
                  <span className="skp-text-light skp-text-small">
                    Admin Password:{" "}
                    <h6 className="skp-text-primary">{game.admin}</h6>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer" style={{ position: "relative", zIndex: 1 }}>
            {isAdmin && (
              <span
                className="skp-link-hover skp-text-small"
                onClick={(e) => props.editGame(e, index)}
              >
                Click to edit game details
              </span>
            )}
            {!isAdmin && (
              <span className="skp-link-primary skp-text-small">
                Click to see game details
              </span>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showGameModal.show}
        title={`Game ${showGameModal.index}`}
        onClose={() => setShowGameModal({ show: false })}
      >
        <ApexGameDetailModal
          tournament={tournament}
          lobby={lobby}
          game={game}
          matches={showGameModal.gameMatches}
          onShowLeaderBoard={(match: ITournamentApexRespawnMatch) =>
            setShowLeaderboardModal({ match: match, show: true })
          }
        />
      </Modal>

      <Modal
        show={showLeaderboardModal.show}
        title="Match Details"
        onClose={() => setShowLeaderboardModal({ show: false })}
      >
        {showLeaderboardModal.match && (
          <ApexMatchDetailModal
            match={showLeaderboardModal.match}
            tournament={tournament}
          />
        )}
      </Modal>
    </>
  );
};

export default ApexGameCard;
