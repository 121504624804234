import React from 'react'
import useImportedStatus from '../Hooks/UseImportedStatus';

function YuniteImportButton(props) {
    const { tournamentId } = props
    const imported = useImportedStatus(tournamentId);

    return (
      <div>
        {!imported && (
          <button
            className="skp-btn skp-btn-primary"
            onClick={props.importTournament}
          >
            Import Tournament
          </button>
        )}
      </div>
    );
}

export default YuniteImportButton
