import IGame from "../../../Interfaces/Game";
import IUserDetails from "../../../Interfaces/UserDetails";
import CreateTournamentForm from "./CreateTournamentForm";

type Props = {
  game: IGame
  user: IUserDetails | null
  onCreate: (tournamentId:string) => void
}

const TournamentControl = (props:Props) => {
  const { game, user } = props;

  return (
    <CreateTournamentForm game={game} user={user} onCreate={props.onCreate} />
  ); 
}

export default TournamentControl;
