import GAMES from '../../../constants-games';
import ITournament from '../../Interfaces/Tournament';
import ITournamentMemberStatus from '../../Interfaces/TournamentMemberStatus';
import TournamentWithApex from './TournamentSinglePages/TournamentWithApex';
import TournamentWithBrackets from './TournamentSinglePages/TournamentWithBrackets';
import TournamentWithYunite from './TournamentSinglePages/TournamentWithYunite';
import TournamentSettings from './TournamentSinglePages/TournamentSettings';
import IFileBase64Image from '../../Interfaces/FileBase64Image';
import TournamentWithClashRoyale from './TournamentSinglePages/TournamentWithClashRoyale';
import TournamentWithLobby from './TournamentSinglePages/TournamentWithLobby';
import TournamentCustom from './TournamentSinglePages/TournamentCustom';
import TournamentCustomBrand from './CustomTournamentPage/TournamentCustomBrand';

type FormModel = {
  name?: string;
  teamSize?: number | string;
  type?: string;
  reserves?: number | undefined;
  privateCheck?: Array<string>;
  password?: string | null;
  console?: Array<string>;
  playerType?: number;
  open?: boolean;
};

type Props  = {
  tournament: ITournament | undefined
  userStatus: ITournamentMemberStatus
  isSettingsPage: boolean
  urlParams: any
  loadTournament: (tournament: ITournament | undefined) => void
  updateTournament: (data: FormModel, imageFile?:IFileBase64Image, type?:string) => void
}

const TournamentPageControl = (props: Props) => {
  const { tournament, isSettingsPage, urlParams } = props;

  if(!tournament) {
    return null
  }

  if(isSettingsPage) {
    return <TournamentSettings {...props} urlParams={urlParams} updateTournament={props.updateTournament} loadTournament={props.loadTournament} />
  }
  
  if (tournament?.custom) {
    if (tournament?.game === "LEAGUE_OF_LEGENDS")
      return <TournamentCustomBrand {...props} loadTournament={props.loadTournament} />
    else
      return <TournamentCustom {...props} loadTournament={props.loadTournament} />
  } else if(tournament?.game && GAMES[tournament?.game]?.CONTROL_TYPE === 'bracket' && !tournament?.yuniteId ) {
    return <TournamentWithBrackets {...props} loadTournament={props.loadTournament} />
  }  else if (tournament?.game === GAMES.FORTNITE.ENUM && tournament?.platform === "SKILLPRO") {
    return <TournamentWithBrackets {...props} loadTournament={props.loadTournament} />;
  } else if (tournament?.yuniteId) {
    return <TournamentWithYunite {...props} />;
  } else if (tournament?.game === GAMES.APEX_LEGENDS.ENUM) {
    return <TournamentWithApex {...props} loadTournament={props.loadTournament} />;
  } else if (tournament?.game === GAMES.CLASH_ROYALE.ENUM) {
    return <TournamentWithClashRoyale {...props} loadTournament={props.loadTournament} />;
  } else if (tournament?.game && GAMES[tournament?.game]?.CONTROL_TYPE === 'lobby') {
    return <TournamentWithLobby {...props} loadTournament={props.loadTournament} />;
  } 
  
  else return null;
}

export default TournamentPageControl;