type Props = {
    children?: React.ReactNode
}
const TextError = (props:Props) => {
    return (
        <span className="skp-text-label skp-text-alert error-message">
            {props.children}
        </span>
    )
}

export default TextError