import React, { useEffect, useState, useContext } from "react";
import { Navigate } from 'react-router-dom';
import { useParams } from "react-router";
import TeamService from "../../../../services/TeamService";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
// import OverviewTab from "./TeamTabs/OverviewTab";
import MembersTab from "../TeamTabs/MembersTab";
import PendingsTab from "./TeamSettingsTabs/PendingsTab";
import Modal from "../../../UiLibrary/Modal";
import UploadImageBox from "../../../UiLibrary/UploadImageBox/UploadImageBox";
import ImageService from "../../../../services/ImageService";
import { toast } from "react-toastify";
import UploadImageButton from "../../../UiLibrary/UploadImageButton";
import { UserContext } from "../../../Context/UserContext";

import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import AboutTab from "./TeamSettingsTabs/AboutTab";
import TeamInfo from "./TeamSettingsTabs/TeamInfo";
import ITeam from "../../../Interfaces/Team";
import IFileBase64Image from "../../../Interfaces/FileBase64Image";
import TeamBrandLogo from "../../../UiLibrary/TeamBrandLogo";

const TeamSettings = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [teamInfo, setTeamInfo] = useState<ITeam>();
  const [showUploadModal, setShowUploadModal] = useState<{
    show: boolean;
    type?: string | undefined;
  }>({
    show: false,
    type: undefined,
  });
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let params = useParams<{ id: string; tab: string }>();
  const teamId = params.id;

  const currentTab = params.tab;
  const tabs = [
    { name: "About", url: "about" },
    { name: "Information", url: "info" },
    { name: "Members", url: "members" },
    { name: "Pending Invitations", url: "pendings" },
  ];

  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (!currentTab) {
      navigate(`/teams/${teamId}/settings/about`);
    }
      TeamService.getTeam(teamId!).then((response) => {
        setTeamInfo(response.data);
      });
  }, [teamId, currentTab, navigate]);

  const joinTeamUrl = `/teams/join-team/${teamId}`;

  const uploadImage = (image: any) => {
    setImageToUpload(image);
  };

  const updateImage = (type: string | undefined) => {
    if (!type || !teamInfo || !imageToUpload) {
      return;
    }
    setLoading(true);
    ImageService.uploadImage(imageToUpload).then((response) => {
      let data = {} as any;
      data[type] = ImageService.generatePublicUrl(response.data);
      TeamService.updateTeam(teamInfo.id, data).then((Rteam) => {
        toast.success("Team updated successfully");
        setLoading(false);
        setTeamInfo(Rteam);
        setShowUploadModal({ show: false });
      });
    });
  };

  const deleteTeam = () => {
    setLoading(true);
    TeamService.deleteTeam(teamId!)
      .then(() => {
        toast.info("Team deleted successfully");
        navigate("/teams");
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setShowDeleteModal(false);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const updateTeam = (data: any) => {
    setLoading(true);
    TeamService.updateTeam(teamId!, data)
      .then((Rteam) => {
        setLoading(false);
        setTeamInfo(Rteam);
        toast.info("Team updated successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
          <div className="skp-page-with-image-banner">
            {/* IMAGE BANNER */}
            {user?.id === teamInfo?.owner && (
              <button
                onClick={() => setShowUploadModal({ show: true, type: "banner" })}
                className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default"
              >
                <i className="las la-camera"></i> Upload Banner
              </button>
            )}
            <div className="skp-image-banner">
              {teamInfo && teamInfo?.banner && !imageError && (
                <img
                  alt="profile-banner"
                  src={teamInfo?.banner}
                  onError={() => setImageError(true)}
                />
              )}
              <div className="transparent-black"></div>
            </div>
            {/* END IMAGE BANNER */}
            {/* PAGE TOP */}
            <div className="container page-top">
              {/* Page Top */}
              <div className="columns page-top">
                <div className="column col-3 profile-picture text-center col-xs-12 text-center-xs">
                  <TeamBrandLogo team={teamInfo}>
                    <>
                      {user?.id === teamInfo?.owner && (
                        <UploadImageButton
                          onClick={() =>
                            setShowUploadModal({ show: true, type: "logo" })
                          }
                        />
                      )}
                    </>
                  </TeamBrandLogo>
                </div>
                <div className="column col-9 col-xs-12 text-center-xs">
                  <div className="columns">
                    <div className="column col-12">
                      <span className="skp-text-label skp-text-light">
                        TEAM SETTINGS
                      </span>{" "}
                      <br></br>
                      <h1 className="skp-text-primary skp-heading-no-margin">
                        {teamInfo?.name}
                      </h1>
                      <Link
                        to={`/teams/${teamInfo?.id}/overview`}
                        className="skp-link skp-text-small"
                      >
                        <i className="las la-arrow-left"></i> View Team
                      </Link>
                    </div>
                    <div className="column col-12">
                      <div className="space-divider-20"></div>
                      <div className="columns skp-text-primary">
                        <div className="column col-8">
                          {teamInfo?.members.length} Member
                          {teamInfo && teamInfo?.members.length > 1 && (
                            <span>s</span>
                          )}
                          {" - "}
                          <Link to={joinTeamUrl}>Join Team</Link>
                        </div>
                        <div className="column col-4 text-right">
                          <button
                            className="skp-btn skp-btn-alert"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            Delete Team
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END PAGE TOP */}
            <div className="space-divider-5"></div>
            {/* TABS HEADER */}
            <div className="container tabs-header">
              <div className="columns">
                <div className="column col-12">
                  <ul className="tab skp-text-light">
                    {tabs.map((tab) => (
                      <li
                        key={tab.name}
                        className={
                          currentTab === tab.url ? "tab-item active" : "tab-item"
                        }
                      >
                        <Link
                          to={`/teams/${teamId}/settings/${tab.url}`}
                          className={`${
                            tab.url === tabs[3].url &&
                            teamInfo?.pendings &&
                            teamInfo?.pendings?.length > 0 &&
                            "badge"
                          } ${currentTab === tab.url && "active"}`}
                          data-badge={teamInfo?.pendings?.length}
                        >
                          {tab.name}{" "}
                          {tab.url === tabs[2].url && (
                            <>({teamInfo?.members?.length})</>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* END TABS HEADER */}
            {/* TABS BODY */}
            <div className="container tabs-body">
              {currentTab === tabs[0].url && (
                <AboutTab
                  team={teamInfo}
                  user={user}
                  onSave={updateTeam}
                  loading={loading}
                />
              )}
              {currentTab === tabs[1].url && (
                <TeamInfo
                  team={teamInfo}
                  user={user}
                  onSave={updateTeam}
                  loading={loading}
                />
              )}
              {currentTab === tabs[2].url && (
                <MembersTab
                  team={teamInfo}
                  setTeam={(team: ITeam) => setTeamInfo(team)}
                  doLogin={() => setShowLoginModal(true)}
                />
              )}

              {currentTab === tabs[3].url && (
                <PendingsTab
                  team={teamInfo}
                  setTeam={(team: ITeam) => setTeamInfo(team)}
                  doLogin={() => setShowLoginModal(true)}
                />
              )}
            </div>
            {/* END TABS BODY */}
          </div>
        </Scrollbars>
      <Modal
        show={showUploadModal.show}
        title="Upload Image"
        size="large"
        onClose={() => {
          setShowUploadModal({ show: false });
        }}
      >
        <div className="container">
          {showUploadModal.type === "banner" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div
                className="column col-12"
                style={{ height: 250, border: "2px dashed var(--gray-1)" }}
              >
                <UploadImageBox onUpload={uploadImage} onRemovePreview={() => setImageToUpload(undefined)} />
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage(showUploadModal.type)}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Upload"}
                </button>
              </div>
            </div>
          )}

          {showUploadModal.type === "logo" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12 text-center">
                <div className="upload-logo-wrapper">
                  <UploadImageBox onUpload={uploadImage} onRemovePreview={() => setImageToUpload(undefined)} />
                </div>
              </div>

              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage(showUploadModal.type)}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Upload"}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        show={showLoginModal}
        size="small"
        onClose={() => setShowLoginModal(false)}
      >
        <LoginOrRegisterModal
          message={constant.MESSAGES.LOGIN.TEAM_PLAYERS}
          onClose={() => setShowLoginModal(false)}
        />
      </Modal>

      <Modal
        show={showDeleteModal}
        title="Delete Team"
        onClose={() => setShowDeleteModal(false)}
        size="small"
        confirmation="Delete"
        onConfirm={deleteTeam}
        loading={loading}
      >
        <>
          <div className="space-divider-20"></div>
          <div className="skp-text-primary text-center">
            Do you want to delete team{" "}
            <span className="skp-text-accent">{teamInfo?.name}</span> ?
          </div>
          <div className="space-divider-20"></div>
        </>
      </Modal>
    </>
  );
};

export default TeamSettings;
