import { useState } from "react";
import GAMES from "../../../constants-games";
import IGameAccount from "../../Interfaces/GameAccount";
import ITournament from "../../Interfaces/Tournament";
import ITournamentTeam from "../../Interfaces/TournamentTeam";
import ITournamentTeamMember from "../../Interfaces/TournamentTeamMember";
import IUserDetails from "../../Interfaces/UserDetails";
import Modal from "../../UiLibrary/Modal";
import TeamColumn from "../../UiLibrary/Team/TeamColumn";

type Props = {
    tournament: ITournament
    team: ITournamentTeam
    onAddMember: (member:ITournamentTeamMember) => void
};
const AddTournamentTeamPlayerModal = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const {tournament, team} = props;
  const game = GAMES[tournament?.game].ENUM;

  let memberIds:string[] = [];

  const addMember = (memberId: string, user: IUserDetails) => {
      
      const gameAccount = user.gameAccounts?.find(
        (account: IGameAccount) =>
          tournament?.console.includes(account.console || "") &&
          account.game === GAMES[game].ENUM
      );

      if(!gameAccount) {
          return
      }

      const memberToExport:ITournamentTeamMember = {
          userId: memberId,
          gameAccount: gameAccount,
          role: 0,
          status: 0
      }

      props.onAddMember(memberToExport)
      setShowModal(false);
  }

  team.members.forEach(member => {
    memberIds.push(member.userId)
  })



  return (
    <>
      <span onClick={() => setShowModal(true)}>
        <i className="las la-plus"></i>
        Add player
      </span>

      <Modal
        size="small"
        title="Add player"
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <>
        <TeamColumn tournament={tournament} members={tournament.members.concat(memberIds)} game={game} onAddMember={addMember} removeCheckbox />
        </>
      </Modal>
    </>
  );
};

export default AddTournamentTeamPlayerModal;
