import { useEffect, useRef, useState } from "react";
import ITournament from "../../../../../Interfaces/Tournament";
import ILobbyGame from "../../../../../Interfaces/LobbyGame";
import ITournamentLobby from "../../../../../Interfaces/TournamentLobby";
import AdminLeaderboardRow from "../../../../../UiLibrary/Lobbies/AdminLeaderboardRow";
import ITournamentLobbyLeaderboard from "../../../../../Interfaces/TournamentLobbyLeaderboard";
import Dropdown from "../../../../../UiLibrary/Dropdown";
import OutsideClickHandler from "react-outside-click-handler";
import ITournamentTeam from "../../../../../Interfaces/TournamentTeam";
import LobbyTeamsTypeahead from "../../../../../UiLibrary/Typeahead/LobbyTeamsTypeAhead";
import TournamentService from "../../../../../../services/TournamentService";
import { toast } from "react-toastify";

type Props = {
  tournament: ITournament
  game: ILobbyGame
  lobby: ITournamentLobby
	onReloadTournament: (tournament?: ITournament) => void;
}

const AdminLeaderboardlModal = (props:Props) => {
  const { tournament, game, lobby } = props;

	const [teams, setTeams] = useState(lobby.teams)
	const [showTypeahead, setShowTypeahead] = useState(false);
	const [canSave, setCanSave] = useState(true)

	const [leaderboard, setLeaderboard] = useState<Array<ITournamentLobbyLeaderboard>|undefined>();

	useEffect(() => {
		if (game.leaderboards && game.leaderboards.length > 0) {
			setLeaderboard(game.leaderboards)
		} else {
			setLeaderboard(new Array(teams?.length).fill(undefined))
			setCanSave(false)
		}
	}, [])

	const [loading, setLoading] = useState(false);

	const dragItem = useRef<any>(null);
	const dragOverItem = useRef<any>(null);

	// Gestisco il sort della lista
	const handleSort = () => {
		if( leaderboard ) {
			// duplico gli item dei team
			let _leaderboard = [...leaderboard]

			// rimuovo e salvo il content del dragged item
			const draggedItemContent = _leaderboard.splice(dragItem.current, 1)[0]

			// switch delle posizioni
			_leaderboard.splice(dragOverItem.current, 0, draggedItemContent)

			// reset delle ref
			dragItem.current = null
			dragOverItem.current = null

			// aggiorno l'array
			setLeaderboard(_leaderboard)
		}
	}
    
	const addTeamToLeaderboard = (team:ITournamentTeam) => {
		
		setShowTypeahead(false)
		const idx = teams? teams.findIndex(t => { return t.partyName === team.partyName}):-1
		const firstValidPosition = leaderboard ? leaderboard?.indexOf(undefined!) : 0
		
		const leaderboardElement:ITournamentLobbyLeaderboard = {
			teamNum: idx+1,
			teamName: team.partyName,
			placement: 0,
			kills: 0,
			placementScore: 0,
			eliminationScore: 0,
			score: 0,
			wins: 0,
		}		

		if( leaderboard ) {
			// duplico gli item dei team
			let _leaderboard = [...leaderboard]

			_leaderboard.splice(firstValidPosition, 1, leaderboardElement)

			setLeaderboard(_leaderboard)
		}
		if (firstValidPosition+1 === leaderboard?.length) {
			setCanSave(true)
		}

	}

	const saveLeaderboard = async () => {
		setLoading(true);
		const newLeaderboardState = leaderboard?.map((obj, index) => {
			return {...obj, placement: index+1}
		})
		setLeaderboard(newLeaderboardState)

    TournamentService.updateGameLeaderboard(tournament?.id, lobby.id, game.gameNumber.toString() ,newLeaderboardState)
			.then(() => {
				toast.info("Leaderboard update successfully!");
				//setShowAddGameModal({ show: false });
				setLoading(false);
				props.onReloadTournament();
			})
			.catch((error) => {
				toast.error(error?.response?.data?.message);
				setLoading(false);
		});
  };

	const reportKill = async (values: any) => {
		const newLeaderboardState = leaderboard?.map(obj => {
			if (obj.teamName === values.teamName) {
				return {...obj, kills: values.teamKill}
			}
			return obj
		})
		setLeaderboard(newLeaderboardState)
  };

	let secondColumnStart = 0
	if (leaderboard) {
			secondColumnStart = leaderboard.length >= 12 ? Math.floor(leaderboard.length/2) : leaderboard.length
	}
	const doubleColumns = secondColumnStart !== leaderboard?.length

  return (
    <div className="container">
			<div className="columns">
			<div className="column col-3 text-center">
			<h5
				className="skp-link-hover d-inline-block"
				onClick={() => setShowTypeahead(true)}
			>
				<span className="mr-1">
					<i className="las la-plus-circle"></i>
				</span>
				<span className="text-uppercase skp-text-small">Add Team</span>
			</h5>
			<Dropdown show={showTypeahead}>
				<OutsideClickHandler onOutsideClick={() => setShowTypeahead(false)}>
					<LobbyTeamsTypeahead
						lobby={lobby}
						toHide={leaderboard}
						onSelect={(team:ITournamentTeam) => addTeamToLeaderboard(team)}
						hideEmptySelection
						hideTeam
					/>
				</OutsideClickHandler>
			</Dropdown>
			</div>
        <div className="column col-8 text-center">
					<button
						className="skp-btn skp-btn-primary"
						disabled={!canSave}
						onClick={saveLeaderboard}
					>
						Save Leaderboard
					</button>
				</div>
			</div>
      <div className="space-divider-20"></div>
			<div className="columns">
				<div className="column col-12 col-xs-12 skp-text-light">
					<h5 className="skp-text-primary">Teams</h5>
					{loading && (
						<>
							<div className="column col-12 mt-2 mb-2">
								<div className="loading"></div>
							</div>
						</>
					)}

					{!loading && (
						<>
							<div className="columns">
								<div className="column col-1 col-xs-2 mb-2">Pos.</div>
								<div className="column col-4 col-xs-6 mb-2">Team name</div>
								<div className="column col-1 mb-2">Kill</div>
								{ doubleColumns && (
									<>
										<div className="column col-1 col-xs-2 mb-2">Pos.</div>
										<div className="column col-4 col-xs-6 mb-2">Team name</div>
										<div className="column col-1 mb-2">Kill</div>
									</>
								)}
							</div>
							<div className="row">
								<div className="columns">
									<div className="column col-6 skp-text-primary">
										{ leaderboard && leaderboard.slice(0, secondColumnStart).map((team, index) => (
											<div className="columns col-12" key={"list"+index}>
												<div className="column col-2 col-xs-2 mb-2">
													{index+1}
												</div>
												{team && (
													<div 
														key={index}
														draggable
														onDragStart={(e) => dragItem.current=index}
														onDragEnter={(e) => dragOverItem.current=index}
														onDragEnd={handleSort}
														onDragOver={(e) => e.preventDefault()}
														className="columns col-10 skp-btn-secondary"
													>
														<AdminLeaderboardRow
															team={team}
															reportKill={reportKill}
														/>
													</div>
												)}
											</div>
										))}
									</div>
									<div className="column col-6 skp-text-primary">
										{ leaderboard && leaderboard.slice(secondColumnStart).map((team, index) => (
											<div className="columns col-12" key={"list"+index}>
												<div className="column col-2 col-xs-2 mb-2">
													{index+1+secondColumnStart}
												</div>
												{team && (
													<div 
														key={index}
														draggable
														onDragStart={(e) => dragItem.current=index+secondColumnStart}
														onDragEnter={(e) => dragOverItem.current=index+secondColumnStart}
														onDragEnd={handleSort}
														onDragOver={(e) => e.preventDefault()}
														className="columns col-10 skp-btn-secondary"
													>
														<AdminLeaderboardRow
															team={team}
															reportKill={reportKill}
														/>
													</div>
												)}
											</div>
										))}
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>  
  );
};

export default AdminLeaderboardlModal;
