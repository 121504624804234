import { Form, Formik } from "formik";
import React, { useState, useContext } from "react";
import Modal from "../../UiLibrary/Modal";
import * as Yup from "yup";
import FormikControl from "../../Formik/FormikControl";
import axios from "axios";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
import Recaptcha from "react-recaptcha";
import IUserDetails from "../../Interfaces/UserDetails";

type Props = {
  children: JSX.Element;
};

type FormModel = {
  name: string;
  email: string;
  message: string;
  recaptcha: string;
};

const HelpSupportModal = (props: Props) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };

  const initialValues: FormModel = {
    name: user ? `${user?.first_name} ${user?.last_name}` : "",
    email: user?.email || "",
    message: "",
    recaptcha: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required("Required!").nullable(),
    message: Yup.string().required("Required!").nullable(),
    name: Yup.string().required("Required!").nullable(),
    recaptcha: Yup.string().required(),
  });

  const handleSubmit = (event: any) => {
    sendMessage(event);
  };
  const GOOGLE_FORM_NAME_ID = "entry.1191797514";
  const GOOGLE_FORM_MESSAGE_ID = "entry.1495653804";
  const GOOGLE_FORM_EMAIL_ID = "entry.728478689";
  const GOOGLE_FORM_ACTION_URL =
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdYLQmb7URtQkUtIBEXdibYXb7I7HczX5KDMWMjwCakCsZZcA/formResponse";
  // const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

  const sendMessage = (data: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(GOOGLE_FORM_MESSAGE_ID, data.message);
    formData.append(GOOGLE_FORM_EMAIL_ID, data.email);
    formData.append(GOOGLE_FORM_NAME_ID, data.name);
    axios
      .post(GOOGLE_FORM_ACTION_URL, formData)
      .then(() => {
        toast.info("Support request sent successfully!");
        setLoading(false);
        setShowModal(false);
      })
      .catch((error) => {
        // setLoading(false);
        // toast.error(
        //   "Error sending support request! Please write an email to help@skillpro.it"
        // );
        toast.info("Support request sent successfully!");
        setLoading(false);
        setShowModal(false);
      });
  };

  return (
    <div>
      {React.cloneElement(props.children, { onClick: handleClick })}

      <Modal
        title="Help and Support"
        show={showModal}
        onClose={() => setShowModal(false)}
        size="default"
      >
        <>
          <div className="space-divider-20"></div>
          <div className="columns">
            <div className="column col-2 hide-xs"></div>
            <div className="column col-8 col-xs-12 skp-text-light">
              <h5 className="skp-text-primary">Help</h5>
              For any help or enquires about Skillpro's platform write an email
              to <b className="skp-text-primary">help@skillpro.it</b>
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">Support</h5>
              If you need help or wish to contact the support, this is the right
              place to do so. But please remember the following:
              <ul className="skp-text-small">
                <li>Include all important information.</li>
                <li>But keep it short!</li>
                <li>Use complete sentences and use punctuation!</li>
              </ul>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ errors, touched, isValid, setFieldValue }) => (
                  <Form>
                    <FormikControl control="input" label="Name" name="name" />
                    <FormikControl control="input" label="Email" name="email" />
                    <FormikControl
                      control="textarea"
                      label="Message"
                      name="message"
                      rows="8"
                    />
                    <Recaptcha
                      sitekey="6Lc8Mw4eAAAAAJSuaadoWXSFijy91DgshxnDTnhf"
                      theme="dark"
                      render="explicit"
                      verifyCallback={(response:any) => {
                        setFieldValue("recaptcha", response);
                      }}
                      onloadCallback={() => {}}
                    />

                    <button
                      className="skp-btn skp-btn-primary float-right"
                      type="submit"
                      disabled={!isValid || loading}
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="column col-2 hide-xs"></div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default HelpSupportModal;
