import { Fragment, useEffect, useState } from "react";
import TeamService from "../../../services/TeamService";
import { Scrollbars } from "react-custom-scrollbars";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import IBrand from "../../Interfaces/Brand";
import BrandTile from "../../UiLibrary/BrandTile";

const Brands = () => {
    const [brands, setBrands] = useState<Array<IBrand>>([]);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>();

    useEffect(() => {
        setLoading(true);
        TeamService.getAllBrands({limit:150}).then((Rbrands) => {
            setBrands(Rbrands);
            setLoading(false);
        });
    }, []);

    const searchTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        setLoading(true);
        TeamService.getAllBrands({ name: event.target.value }).then((Rbrands) => {
            setBrands(Rbrands);
            setLoading(false);
        });
    };

    return (
        <>
            <div className="skp-full-page">
                <div className="container">
                    <div className="columns">
                        <div className="column col-12 text-center">
                            <h4 className="skp-primary-pink skp-heading">{t('common.brands')}</h4>
                        </div>
                    </div>
                    <div className="space-divider-20"></div>
                    <div className="columns">
                        <div className="column col-12 skp-primary-blue text-uppercase skp-text-xlarge">
                            <strong>{t('common.filters')}</strong>
                        </div>
                        <div className="column col-4 col-xs-12">
                            <div className="skp-form-control">
                                <label className="skp-text-primary skp-text-small">
                                    {t('component.searchTeam')}
                                </label>
                                <DebounceInput
                                    value={searchText}
                                    type="text"
                                    minLength={1}
                                    debounceTimeout={500}
                                    placeholder={t('component.searchByName')}
                                    onChange={(e) => searchTeam(e)}
                                />
                            </div>
                        </div>
                        <div className="space-divider-20"></div>
                    </div>
                    {loading && (
                        <div className="columns">
                            <LoadingPlaceholders numberOfItems={6}>
                                <div className="column col-2 col-xs-6">
                                    <div className={`team-tile team-tile-loading`}>
                                        <div className={`team-tile-image`}>
                                            <div className={`default-tile`}></div>
                                        </div>
                                    </div>
                                </div>
                            </LoadingPlaceholders>
                        </div>
                    )}
                    {!loading && (
                        <>
                            <Scrollbars style={{width: "100%", height: "calc(100vh - 320px)"}} renderView={(props:any) => <div {...props} className="viewOverflowXHidden"/>}>
                                <div className="columns">
                                    {/* Tiles */}
                                    <>
                                        {brands.length > 0 && brands.map((brand, index) => (
                                            <Fragment key={index}>
                                                <div className="column col-xs-1 show-xs"></div>
                                                <div className="column col-2 col-xs-4" key={brand.id}>
                                                    <BrandTile brand={brand}></BrandTile>
                                                </div>
                                                <div className="column col-xs-1 show-xs"></div>
                                            </Fragment>
                                        ))}
                                        {brands.length === 0 && (
                                            <div className="column col-12">
                                                <h6 className="skp-text-primary">{t('modals.noBrandsAdd')}</h6>
                                            </div>
                                        )}
                                    </>
                                </div>
                            </Scrollbars>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Brands;
