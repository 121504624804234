import { RenderSeedProps, Seed, SingleLineSeed } from "react-brackets";
import SingleMatch from "./SingleMatch";
import EventBus from "react-native-event-bus";
import MatchStatus from "../../../../../UiLibrary/MatchComponents/MatchStatus";
import VisibilitySensor from "react-visibility-sensor";
import { useState, useContext } from "react";
import { TournamentMatchesContext } from "../../../../../Context/TournamentMatchesContext";
import GenerateBracketRounds from "../../../../../Hooks/GenerateBracketRounds";
import MatchResultService from "../../../../../../services/MatchResultService";
import EMatchStatus from "../../../../../../enums/MatchStatus";

const viewDetails = (details:any) => {
  EventBus.getInstance().fireEvent("SHOW_MATCH_DETAILS", {
    ...details,
  });
};

const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }:any) => {
  const [visible, setVisible] = useState(false);
  const {tournamentMatches} = useContext(TournamentMatchesContext);
  const rounds = GenerateBracketRounds.generate(tournamentMatches?.tournament?.matches);
  let selectedRound;

  if(!rounds.winners.find((round) => round.title === seed.round)) {
    selectedRound = rounds.loosers
  } else {
    selectedRound = rounds.winners
  }

  let isLineConnector = false;

  if(selectedRound && selectedRound.length > 0) {
    isLineConnector = selectedRound[roundIndex]?.seeds.length === selectedRound[roundIndex + 1]?.seeds.length;
  }

  const Wrapper = isLineConnector ? SingleLineSeed : Seed;
  const multipleMatch = seed?.matchesId?.length > 1
  const multipleMatchStatus = MatchResultService.getMultipleMatchStatus(tournamentMatches?.matches, seed);

return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{top:10}}
      delayedCall={true}
      onChange={(isVisible:any) => {

        setVisible(isVisible);
        
      }}
    >
      <Wrapper
        mobileBreakpoint={breakpoint}
        style={{ fontSize: 12 }}
        className="skp-brackets-seed"
      >
        <div className="skp-text-label float-left mb-1 skp-text-light" id={`seed-${seed.id}`}>
          Match {seed.id}
        </div>

        <SingleMatch
          matchId={seed?.matchesId ? seed?.matchesId[0] : seed?.matchId}
          seed={seed}
          visible={visible}
        />
        <div className="columns col-gapless skp-brackets-seed-details skp-text-label">
          
          <div className="column col-5 col-gapless text-left skp-text-light">
            {seed?.matchesId && seed?.matchesId?.length === 1 && (
              <MatchStatus matchId={seed?.matchesId[0]} />
            )}
            {multipleMatch && (
              <div className="skp-brackets-seed-status text-left mb-1">
                <span className={`skp-chip status-${multipleMatchStatus}`}>
                  {EMatchStatus[multipleMatchStatus]}
                </span>
              </div>
            )}
          </div>
          
          <div className="column col-7 text-right">
            <span
              className="skp-link-primary"
              onClick={() => viewDetails(seed)}
            >
              View Details
            </span>
          </div>
        </div>
      </Wrapper>
    </VisibilitySensor>
  );
};

export default CustomSeed;
