import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import TournamentService from "../../../../services/TournamentService";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { createYupSchema } from "../../../Formik/YupSchemaCreator";
import axios from "axios";
import GAMES from "../../../../constants-games";
import IUserDetails from "../../../Interfaces/UserDetails";
import IGame from "../../../Interfaces/Game";
import { useTranslation } from "react-i18next";

type Props = {
  user: IUserDetails | null;
  game: IGame;
  onCreate: (tournamentId: string) => void;
};

type TournamentFormModel = {
  [key: string]: any;
  name?: string;
  type?: string;
  start_tournament?: Date;
  end_tournament?: Date;
  start_registration?: Date;
  end_registration?: Date;
  start_checkin?: Date;
  end_checkin?: Date;
  check_in?: number;
  check_in_end?: number;
  minPartecipants?: number;
  maxPartecipants?: number;
  matchType?: number;
  teamSize?: number;
  console?: Array<string>;
  owner?: string;
  game?: string;
  cardLevelLimit?: number;
  maxDefeats?: number;
  playerType?: number;
  isTest?: boolean;
  hideTournament?: boolean;
};

const CreateTournamentForm = (props: Props) => {
  const { user, game } = props;
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [gameInstance, setGameInstance] = useState([]);
  const formikRef = useRef<FormikProps<TournamentFormModel>>(null);

  useEffect(() => {
    axios
      .get(`/create-instances/${GAMES[game.ENUM].CREATE_INSTANCE}`)
      .then((response) => {
        setGameInstance(response.data);
      });
    return () => {
      setGameInstance([]);
    };
  }, [game]);

  const onSubmit = (values: TournamentFormModel) => {
    if (!user?.id) {
      return;
    }

    values.check_in = values.check_in ? values.check_in : 45;
    values.check_in_end = values.check_in_end ? values.check_in_end : 10;
    const gAN = values.gameAccountNeeded ? ( values.gameAccountNeeded > 0 ? true : false) : true

    setLoading(true);

    const data: TournamentFormModel = {
      owner: user.id,
      name: values.name,
      game: game.ENUM,
      tournamentType: GAMES[game.ENUM].CONTROL_TYPE || 'bracket',
      playerType: Number(values.teamSize) > 1 ? 1 : 0,
      teamSize: Number(values.teamSize),
      start_tournament: values.start_tournament,
      end_tournament: values.end_tournament ? values.end_tournament : values.start_tournament,
      start_registration: values.start_registration ? values.start_registration : new Date(),
      end_registration: new Date(
        new Date(values.start_tournament || "").valueOf() -
          values?.check_in * 60000
      ),
      start_checkin: new Date(
        new Date(values.start_tournament || "").valueOf() -
          values?.check_in * 60000
      ),
      end_checkin: new Date(
        new Date(values.start_tournament || "").valueOf() -
          values?.check_in_end * 60000
      ),
      check_in: values.check_in,
      check_in_end: values.check_in_end,
      minPartecipants: Number(values.minPartecipants) || 1,
      maxPartecipants: Number(values.maxPartecipants),
      status: 0,
      platform: GAMES[game.ENUM].PLATFORM || "SKILLPRO",
      open: formikRef.current?.values?.privateCheck?.length > 0 ? false : true,
      isTest: formikRef.current?.values?.testCheck?.length > 0 ? true : false,
      hideTournament: formikRef.current?.values?.hideCheck?.length > 0 ? true : false,
      console: values.console,
      gameAccountNeeded: gAN
    };

    if (values.maxDefeats) {
      data.maxDefeats = Number(values.maxDefeats);
    }
    if (values.cardLevelLimit) {
      data.cardLevelLimit = Number(values.cardLevelLimit);
    }
    if (values.matchType) {
      data.matchType = Number(values.matchType);
    }
    if (values.tournamentPassword) {
      data.password = values.tournamentPassword;
    }
    if (values.paypalClientId) {
      data.paypalClientId = values.paypalClientId;
    }
    if (values.price) {
      data.price = Number(values.price);
    }

    

    TournamentService.createTournament(data)
      .then((tournament) => {
        toast.success(t('toast.tournamentCreatedSuccess'));
        setLoading(false);
        props.onCreate(tournament.id);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const initialValues: TournamentFormModel = { check_in: 0,  };

  gameInstance?.forEach((item: any) => {
    if (item.control === "date") {
      const date = new Date();
      if (item.id !== "start_registration") {
      initialValues[item.id] =
        item.initialValue || new Date(date.setDate(date.getDate() + 7));
    } else {
        initialValues[item.id] =
          item.initialValue || date;
      }
    } else {
      initialValues[item.id] = item.initialValue || "";
    }
  });
  initialValues.privateCheck = [];
  initialValues.testCheck = [];
  initialValues.price = undefined;
  initialValues.paypalClientId = undefined;

  const yupSchema = gameInstance?.reduce(createYupSchema, {});
  const validationSchema = Yup.object().shape(yupSchema);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
      innerRef={formikRef}
    >
      {({ errors, touched, isValid }) => (
        <Form>
          {/* Name */}
          <div className="columns">
            {gameInstance?.map((element: any) => (
              <div className="column col-6" key={element.id}>
                <FormikControl
                  control={element.control}
                  type={element.type}
                  label={t('game.label.'+element.label)}
                  name={element.id}
                  placeholder={t('game.placeholder.'+element.placeholder)}
                  {...((element.control === "select" ||
                    element.control === "checkbox") && {
                    options: element.options,
                  })}
                  {...(element.control === "date" && {
                    dateFormat: "dd/MM/yyyy h:mm aa",
                    showTimeSelect: true,
                  })}
                  errors={errors}
                  touched={touched}
                  isValid={isValid}
                />
              </div>
            ))}
          </div>
          <div className="space-divider-20"></div>
          <div className="columns">
            <div className="column col-12">
              <h6 className="skp-text-primary">
                {t('tournament.create.step3')}
              </h6>
              <div className="columns">
                <div className="column col-8">
                  <FormikControl
                    control="input"
                    name="paypalClientId"
                    label="Paypal APP Client ID"
                  />
                </div>
                <div className="column col-4">
                  <FormikControl
                    control="input"
                    name="price"
                    type="number"
                    label={t('tournament.config.price')}
                  />
                </div>
              </div>
            </div>
            <div className="space-divider-20"></div>
            <div className="column col-12 skp-text-light">
              <h6 className="skp-text-primary">{t('tournament.create.step4')}</h6>
              <div className="columns">
                <div className="column col-4">
                  <FormikControl
                    control="checkbox"
                    name="privateCheck"
                    options={[{ key: t('tournament.config.privateTournament'), value: "private" }]}
                    label={t('tournament.config.privateTournament')}
                  />
                </div>
                {formikRef.current?.values?.privateCheck?.length > 0 && (
                  <div className="column col-8">
                    <FormikControl
                      control="input"
                      type="password"
                      name="tournamentPassword"
                      label={t('tournament.config.tournamentPassword')}
                      disabled={
                        formikRef.current?.values?.privateCheck?.length === 0
                      }
                    />
                  </div>
                )}
              </div>
                <div className="columns">
                  <div className="column col-4">
                    <FormikControl
                      data-tooltip-id="hide-info-tooltip" data-tooltip-content="Hello to you too!"
                      control="checkbox"
                      name="hideCheck"
                      options={[{ key: t('tournament.config.hideTournament'), value: "hideTournament" }]}
                      label={t('tournament.config.hideTournament')}
                    />
                  </div>
                </div>
              </div>

            {user?.roles?.includes('Tester') && (
              <>
                <div className="space-divider-20"></div>
                <div className="column col-12 skp-text-light">
                  <h6 className="skp-text-primary">{t('tournament.create.step5')}</h6>
                  <div className="columns">
                    <div className="column col-4">
                      <FormikControl
                        control="checkbox"
                        name="testCheck"
                        options={[{ key: t('tournament.config.testTournament'), value: "isTest" }]}
                        label={t('tournament.config.testTournament')}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="space-divider"></div>
          <div className="modal-submit-internal">
            <button
              type="submit"
              disabled={!isValid || loading}
              className="skp-btn skp-btn-primary"
            >
              {loading ? <span className="loading"></span> : t('common.create')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTournamentForm;
