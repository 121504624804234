import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./modal.scss";

type Props = {
  show?: boolean;
  loading?: boolean;
  size?: string;
  title?: string;
  confirmation?: string;
  cancel?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  children?: JSX.Element;
};

const Modal = (props: Props) => {
  const { show, size, title, cancel, confirmation, loading } = props;
//   if (!show) {
//     return null;
//   }

  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{enter: 0, exit: 300}}>
      <div
        className="skp-modal-tab-overlay"
        id="skpModalTabOverlay"
        onClick={props.onClose}
      >
        <div
          className={`skp-modal-tab skp-modal-tab-${size}`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Modal Content */}
          <div className="skp-modal-tab-content">
            <div
              className="skp-modal-tab-close-icon skp-text-light"
              onClick={props.onClose}
              style={{ zIndex: 9 }}
            >
              <i className="las la-times"></i>
            </div>
            {/* Modal Header */}
            <div className="skp-modal-tab-header">
              <h4 className="skp-text-primary">{title}</h4>
            </div>
            {/* Modal Body */}
            <div className="skp-modal-tab-body">{props.children}</div>
            {/* Modal Footer */}
            <div className="skp-modal-tab-footer">
              {cancel && (
                <button
                  className="skp-btn skp-btn-secondary"
                  onClick={props.onClose}
                >
                  {cancel}
                </button>
              )}
              {confirmation && (
                <button
                  className="skp-btn skp-btn-primary"
                  disabled={loading}
                  onClick={props.onConfirm}
                >
                  {props.loading ? (
                    <span className="loading"></span>
                  ) : (
                    `${confirmation}`
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,

    document.getElementById("root") as HTMLElement
  );
};

export default Modal;
