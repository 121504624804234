import PopularTeams from "./PopularTeams";
import UpcomingTournaments from "./UpcomingTournaments";
import BrowseGames from "./BrowseGames";
import "./home.scss";
import YuniteTournaments from "./YuniteTournaments";
import { Scrollbars } from "react-custom-scrollbars";
import RenderOnRole from "../../KeyCloack/RenderOnRole";
// import Carousel from "../../UiLibrary/Carousel/Carousel";
import Footer from "../../UiLibrary/Footer";
import { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import HomepageCarousel from "./HomepageCarousel";
import { EmblaOptionsType } from 'embla-carousel'

const Home = () => {

  const { user } = useContext(UserContext);
  const SLIDE_COUNT = 5
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

  return (
    <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
      {/*<HomepageCarousel slides={SLIDES}></HomepageCarousel>*/}
      <div className="skp-full-page">
        {/* <Carousel /> */}
        {/* <div className="space-divider-40"></div> */}
        

        <div className="container">
          <div className="space-divider-20 show-xs"></div>

          <UpcomingTournaments></UpcomingTournaments>

          <RenderOnRole roles={["Yunite Tournament Admin"]}>
            <>
              <div className="space-divider"></div>
              <YuniteTournaments></YuniteTournaments>
            </>
          </RenderOnRole>

          <div className="space-divider"></div>

          <PopularTeams></PopularTeams>

          <div className="space-divider"></div>

          <BrowseGames></BrowseGames>

          <div className="space-divider"></div>
          <Footer />
          <div className="space-divider-20"></div>
        </div>
      </div>
    </Scrollbars>
  );
};

export default Home;
