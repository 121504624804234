import React from 'react'
import YuniteOverviewSide from './YuniteOverviewSide';

function YuniteOverviewTab(props) {
    const { tournament } = props;

    return (
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <div className="columns">
          <div className="column col-8 col-xs-12">
            <div
              className="skp-text-primary"
              dangerouslySetInnerHTML={{
                __html: tournament?.description,
              }}
            ></div>
          </div>
          <div className="column col-4 col-xs-12">
            <YuniteOverviewSide tournament={tournament} />
          </div>
        </div>
      </div>
    );
}

export default YuniteOverviewTab
