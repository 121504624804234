import UserProfile from "../../../../UiLibrary/UserProfile";
import KeyCloackService from "../../../../../services/KeyCloackService";
import { Link } from "react-router-dom";
import TeamService from "../../../../../services/TeamService";
import { toast } from "react-toastify";
import ITeam from "../../../../Interfaces/Team";
import { useContext, useState } from "react";
import { UserContext } from "../../../../Context/UserContext";
import UserSearchDropdown from "../../../../UiLibrary/User/UserSearchDropdown";
import IUserDetails from "../../../../Interfaces/UserDetails";
import { AnimatedList } from "react-animated-list";

type Props = {
  team: ITeam | undefined;
  doLogin: () => void;
  setTeam: (team: ITeam) => void;
};

const PendingsTab = (props: Props) => {
  const { team } = props;
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const confirmMember = (memberId: string) => {
    if (!team?.id || !user?.id) {
      return;
    }
    TeamService.acceptMember(team.id, memberId)
      .then((tResponse) => {
        toast.success("Member Accepted Successfully");
        props.setTeam(tResponse);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const inviteUser = (member: IUserDetails) => {
    if (!team?.id || !member?.id) {
      return;
    }
    TeamService.inviteMember(team.id, member.id)
      .then((tResponse) => {
        toast.success("Member Invited Successfully");
        props.setTeam(tResponse);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const deletePending = (memberId: string) => {
    if (!team?.id) {
      return;
    }
    setLoading(true);
    TeamService.deletePending(team.id, memberId)
      .then((tResponse) => {
        setLoading(false);
        toast.success("Pending Deleted Successfully");
        props.setTeam(tResponse);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="players-tab container tab-with-table">
      <div className="columns">
        <div className="space-divider"></div>
        <div className="column col-4">
          <h5 className="skp-text-primary">Invite People</h5>
          <UserSearchDropdown setUser={inviteUser} />
        </div>

        <div className="column col-8">
          {team?.pendings && team?.pendings.length > 0 && (
            <div className="columns">
              <div className="column col-12">
                <h5 className="skp-text-primary">To Review</h5>

                <div className="bordered-map">
                  <AnimatedList animation={"zoom"}>
                    {team?.pendings &&
                      team?.pendings
                        .filter((member) => member.status === 0)
                        .map((player, index) => (
                          <div
                            key={`pendings-waiting-${player.id}-${index}`}
                            className="columns flex-centered show-on-hover"
                          >
                            <div className="column col-8">
                              <Link to={`/users/${player.id}/overview`}>
                                <UserProfile userId={player.id}>
                                  {team?.owner === player.id && (
                                    <span className="skp-chip status-captain skp-text-xxsmall">
                                      Leader
                                    </span>
                                  )}
                                </UserProfile>
                              </Link>
                            </div>
                            <div className="column col-4 text-right">
                              {user?.id === team?.owner && (
                                <button
                                  className="skp-btn skp-btn-primary m-2"
                                  onClick={() => confirmMember(player.id)}
                                >
                                  Accept Member
                                </button>
                              )}

                              {user?.id === team?.owner && (
                                <span
                                  className="skp-link-hover skp-text-small m-2"
                                  onClick={() => deletePending(player.id)}
                                >
                                  {!loading && <i className="las la-trash"></i>}
                                  {loading && <span className="loading"></span>}
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                  </AnimatedList>
                </div>
              </div>
              <div className="column col-12">
                <h5 className="skp-text-primary">Waiting Confirmation</h5>

                <div className="bordered-map">
                  <AnimatedList animation={"grow"}>
                    {team?.pendings &&
                      team?.pendings
                        .filter((member) => member.status === 1)
                        .map((player, index) => (
                          <div
                            key={`${player.id}-${index}`}
                            className="columns flex-centered show-on-hover"
                          >
                            <div className="column col-7">
                              <Link to={`/users/${player.id}/overview`}>
                                <UserProfile userId={player.id}>
                                  {team?.owner === player.id && (
                                    <span className="skp-chip status-captain skp-text-xxsmall">
                                      Leader
                                    </span>
                                  )}
                                </UserProfile>
                              </Link>
                            </div>
                            <div className="column col-5 text-right flex-centered">
                              <span className="skp-text-quaternary skp-text-small m-2">
                                Waiting member confirmation
                              </span>

                              {user?.id === team?.owner && (
                                <span
                                  className="skp-link-hover skp-text-small m-2"
                                  onClick={() => deletePending(player.id)}
                                >
                                  {!loading && <i className="las la-trash"></i>}
                                  {loading && <span className="loading"></span>}
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                  </AnimatedList>
                </div>
              </div>
              <div className="space-divider-40"></div>
              {/* Login button */}
              {!KeyCloackService.isLoggedIn() && (
                <div className="column col-12 text-center">
                  <div className="skp-text-light">
                    You must log in to see Players information.
                  </div>
                  <div className="space-divider-20"></div>
                  <button
                    className="skp-btn skp-btn-primary"
                    onClick={props.doLogin}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          )}
          {team?.pendings?.length === 0 && (
            <div className="columns skp-text-light text-center">
              <div className="space-divider"></div>
              <div className="column col-12">
                <h1>
                  <i className="las la-user-friends"></i>
                </h1>
              </div>
              <div className="column col-12">
                <h5>No players</h5>
              </div>
              <div className="column col-12">
                There are currently no Players registered for this Tournament.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingsTab;
