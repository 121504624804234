import React from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
import TextError from "./TextError";
import IDropdownOption from "../Interfaces/DropdownOption";


type Props = {
  label?: string;
  name?: string;
  options?: Array<IDropdownOption>;
};

function CheckboxGroup(props: Props) {
  const { label, name, options, ...rest } = props;

  if (!name || !options) {
    return null;
  }

  return (
    <div className="skp-form-control form-group">
      <label htmlFor={name} className="skp-text-light skp-text-small">
        {label}
      </label>
      {options && (
        <Field id={name} name={name} {...rest}>
          {({ field }: FieldProps) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <label
                    htmlFor={option.value.toString()}
                    className="form-checkbox skp-text-light skp-text-default"
                  >
                    <input
                      type="checkbox"
                      id={option.value.toString()}
                      {...field}
                      value={option.value}
                      checked={field?.value?.includes(option.value)}
                    />
                    <i className="form-icon"></i> {option.key}
                  </label>
                </React.Fragment>
              );
            });
          }}
        </Field>
      )}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default CheckboxGroup;
