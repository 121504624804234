import { useState } from "react";
import ILobbyGame from "../../../../Interfaces/LobbyGame";
import Modal from "./Modal";
import LobbyGameDetailModal from "../Tabs/LobbyGameDetailModal";
import ITournamentLobby from "../../../../Interfaces/TournamentLobby";
import ITournament from "../../../../Interfaces/Tournament";

type Props = {
    tournament: ITournament;
    game: ILobbyGame;
    index: number;
    lobby: ITournamentLobby;
    isAdmin: boolean;
    isMember?: boolean;
}

const LobbyGameCard = (props: Props) => {
    const { game, index, tournament, lobby, isAdmin, isMember, } = props;
    const [showGameModal, setShowGameModal] = useState<{
        show: boolean;
        index?: number | undefined;
        game?: ILobbyGame | undefined
    }>({
        show: false,
    });

    const onGameClick = (game: ILobbyGame) => {
        setShowGameModal({
            show: true,
            index: game?.gameNumber,
            game: game
        });
    };

    const nowDate = new Date();
    const gameCompleted = new Date(game.end_game) < nowDate;

    return (
        <div
            className="column col-3 col-xs-12"
            key={index}
        >
            <div className="play-game-card" onClick={()=>onGameClick(game)}>
                <h6 className="skp-text-primary text-uppercase">
                    Game {game.gameNumber} 
                    {game.map_name && game.map_name && (
                    " - MAP: " + game.map_name
                    )}
                </h6>
                {!gameCompleted && isMember && game.player_password !== "" && (
                    <>
                        <div className="column col-12">Game Password</div>
                        <div className="column col-12">
                        <h5 className="skp-text-primary">{game.player_password}</h5>
                        </div>
                    </>
                )}
                {gameCompleted && (
                  <>
                    <div className="column col-12">
                      <h6 className="skp-text-primary skp-text-ternary">
                        <i className="las la-check-circle"></i> Completed
                      </h6>
                    </div>
                  </>
                )}
            </div>
            <Modal
                show={showGameModal.show}
                title={`Game ${showGameModal.index}`}
                onClose={() => setShowGameModal({ show: false })}
            >
                <LobbyGameDetailModal
                    tournament={tournament}
                    lobby={lobby}
                    game={showGameModal.game!}
                />
            </Modal>
        </div>
    );
};

export default LobbyGameCard;