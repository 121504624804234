import { useEffect, useState } from "react";
import UserProfile from "./UserProfile";
import UserTournamentGameAccount from "./User/UserTournamentGameAccount";
import ITournamentTeam from "../Interfaces/TournamentTeam";
import ITournamentTeamMember from "../Interfaces/TournamentTeamMember";
import ITournament from "../Interfaces/Tournament";

type Props = {
  apexUsername: string
  tournament: ITournament
}

const ProfileApex = (props:Props) => {
  const { apexUsername, tournament } = props;
  const [user, setUser] = useState<ITournamentTeamMember>();

  useEffect(() => {
    const userTeam = tournament?.teams?.find((team:ITournamentTeam) => 
      team?.members?.find((member) => member.gameAccount.username === apexUsername )
    );

    const findUser = userTeam?.members.find((member:ITournamentTeamMember) => member.gameAccount.username === apexUsername)
    setUser(findUser);
    
  }, [apexUsername, tournament]);

  return <>
    {user && <UserProfile userId={user?.userId} >
      <UserTournamentGameAccount tournament={tournament} userId={user?.userId}/>
    </UserProfile>}
    {!user && <UserProfile>
      <span className="skp-chip ml-2">{apexUsername}</span>
    </UserProfile>}
  
  </>;
};

export default ProfileApex;
