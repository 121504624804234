import { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import UserProfile from "../UserProfile";

const UserByEmail = (props) => {
  const { email } = props;
  const [user, setUser] = useState();

  useEffect(() => {
    UserService.getUserByEmail(email).then((response) => {
      setUser(response.data.data.users[0])
    });
  }, [email]);

  return (
    <>
      {user?.username && <UserProfile user={user}></UserProfile>}
      {!user && email}
    </>
  );
};

export default UserByEmail;
