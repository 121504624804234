type TeamProps = {
  team: {
    profilePicture: string;
    name: string;
    score: string;
  };
  size?: string;
  hidePicture?: boolean;
  hideScore?: boolean;
  hideTruncate?: boolean;
};

const truncateTeamName = (text: string) => {
  if (!text) {
    return;
  }
  if (text.replace(" ", "").length < 18) {
    return text;
  } else {
    return text.substring(0, 14) + "...";
  }
};

const TeamInfo = (props: TeamProps) => {
  const { team, hidePicture, hideScore, size, hideTruncate } = props;
  let width = 31;
  let height = 31;
  let xText = 35;
  let yText = 23;
  let xScore = 190;
  let yScore = 24;

  if (size === "large" || size === "xlarge") {
    width = 54;
    height = 54;
    xText = 60;
    yText = 35;
    xScore = 320;
    yScore = 35;
  }
  return (
    <>
      {!hidePicture && (
        <image
          transform="translate(2,2)"
          clipPath={`url(${
            size === "xlarge" ? "#clipCircleDouble" : "#clipCircle"
          })`}
          href={
            team?.profilePicture ||
            "https://images.vogue.it/users/my/avatar.jpg"
          }
          height={width}
          width={height}
        ></image>
      )}
      {size !== "xlarge" && (
        <text
          transform={`translate(${hidePicture ? 0 : xText} ${yText})`}
          className={size === "large" ? "skp-text-default" : "skp-text-small"}
        >
          {hideTruncate ? team?.name : truncateTeamName(team?.name)}
        </text>
      )}
      {size === "xlarge" && (
        <text
          transform={`translate(${hidePicture ? 0 : xText} ${yText})`}
          className="skp-text-xxlarge"
        >
          {hideTruncate ? team?.name : truncateTeamName(team?.name)}
        </text>
      )}
      {!hideScore && (
        <text
          transform={`translate(${xScore} ${yScore})`}
          className={size === "large" ? "skp-text-xlarge" : "skp-text-large"}
        >
          {team?.score}
        </text>
      )}
    </>
  );
};

export default TeamInfo;
