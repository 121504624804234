import React, { useContext, useRef, useState } from "react";
import Countdown from "react-countdown";
import ReactTimeAgo from "react-time-ago";
import { Scrollbars } from "react-custom-scrollbars";
import Moment from "moment";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import Marquee from "react-text-marquee";
import constant from "../../../../constants";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import ITournament from "../../../Interfaces/Tournament";
import TournamentJoinButtons from "../TournamentSinglePages/TournamentJoinButtons";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../Context/UserContext";

type Props = {
  tournament: ITournament
  memberStatus: ITournamentMemberStatus
  onReloadTournament: (tournament?:ITournament) => void
}

const BracketsStatusPanel = (props:Props) => {
  const { tournament, memberStatus } = props;
  const { user } = useContext(UserContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  let status = tournament?.status;
  let upcomingTournament = status === 0;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;
  const divToPrint = useRef<HTMLInputElement>(null);

  const today = new Date();
  const registration = new Date(tournament?.end_registration);
  const checkin = new Date(tournament?.end_checkin);
  const registrationClosed = registration < today || !upcomingTournament;
  const checkinClosed = checkin < today || !upcomingTournament;
  const startRegistration = new Date(tournament?.start_registration || "")
  const registrationStarted = startRegistration < today || !upcomingTournament

  const isCheckinPresent = (tournament?.checkin === undefined || tournament.checkin)

  const PrintDiv = (element:any) => {
    setLoading(true);
    extractImage(element)
    setLoading(false);
  }

  const extractImage = async (element:any) => {
    
    const onClone = (clonedDoc:any) => {
      clonedDoc.getElementById("imageContainerTitle").style.display = "block";
      clonedDoc.getElementById("imageContainer").style.textAlign = "center";
      clonedDoc.getElementById("imageContainerSubTitle").style.display = "block";
    };
    const options = { onclone: onClone };
    const canvas = await html2canvas(element, options);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, `skillpro-join-qr-${tournament?.name}`);
  };

  const downloadImage = (blob:any, fileName:string) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.setAttribute("style", "display:none;");
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  return (
    <div className="skp-right-sidebar">
      <Scrollbars>
        <div className="container p-2">
          <div className="columns p-2">
            <div className="column col-12">
              <h4 className="skp-text-primary">{t('sidePanel.tournamentStatus')}</h4>
              {!onGoingTournament && !completedTournament && !registrationStarted && (
                <span
                  className={`skp-chip skp-text-xxsmall text-uppercase ${
                    "status-awaiting"
                  }`}
                >
                  {t('sidePanel.registrationSoon')}                
                </span>
              )}
              {!onGoingTournament && !completedTournament && registrationStarted && (
                <span
                  className={`skp-chip skp-text-xxsmall text-uppercase ${
                    registrationClosed ? "status-closed" : "status-1"
                  }`}
                >
                  {registrationClosed
                    ? t('tournament.registrationClosed')
                    : t('tournament.registrationOpen')}
                </span>
              )}
              {!onGoingTournament && !completedTournament && registrationClosed && isCheckinPresent &&(
                <span
                  className={`skp-chip skp-text-xxsmall text-uppercase ${
                    checkinClosed ? "status-closed" : "status-1"
                  }`}
                >
                  { checkinClosed
                    ? t('tournament.checkInClosed')
                    : t('tournament.checkInOpen')}
                </span>
              )}
              
              {upcomingTournament && (
                <span
                  className={`skp-chip skp-text-xxsmall text-uppercase status-upcoming`}
                >
                  {t('status.upcoming')}
                </span>
              )}
              {onGoingTournament && (
                <span className="skp-chip  skp-text-xxsmall text-uppercase status-ongoing">
                  {t('status.ongoing')}
                </span>
              )}
              {completedTournament && (
                <span className="skp-chip  skp-text-xxsmall text-uppercase status-completed">
                  {t('status.completed')}
                </span>
              )}
            </div>
          </div>
          <div className="columns p-2">
            <div className="column col-12">
              <h5
                className={
                  !registrationClosed ? "skp-text-accent" : "skp-text-light"
                }
              >
                {t('sidePanel.registration')} {" "}
                { !registrationStarted && (
                  <>
                    {t('tournament.startsIn')}{" "}
                    <span className="skp-text-small">
                      <Countdown date={new Date(tournament?.start_registration)} />
                    </span>
                  </>
                )}
                { !registrationClosed && registrationStarted && (
                <span className="skp-text-small">
                  <Countdown date={new Date(tournament?.end_registration)} />
                  </span>)
                }
              </h5>

              { !registrationStarted && (
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.registrationWillOpenSoon')} 
              </div>
              )}
              
              {!registrationClosed && registrationStarted && (
                <>
                  <div className="skp-text-label skp-text-light">
                    {t('sidePanel.registrationOpen')} 
                  </div>
                <div className="mt-2">
                <div ref={divToPrint} id="imageContainer">
                  <h3
                    id="imageContainerTitle"
                    style={{ display: "none" }}
                    className="text-center mt-2 text-truncate"
                  >
                    <div className="skp-text-label m-2">{t('common.joinTournament')}</div>

                    <Marquee text={tournament?.name} />
                  </h3>
                  <QRCode
                    value={`${constant.APP_URL}/tournaments/${tournament?.id}/join`}
                    size={120}
                    level={"L"}
                  ></QRCode>
                  <h5
                    id="imageContainerSubTitle"
                    style={{ display: "none" }}
                    className="text-center mt-2 text-truncate"
                  >
                    <div className="skp-text-label m-2">Powered by</div>

                    SKILLPRO
                  </h5>
                </div>
                
                {!loading && <div onClick={() => PrintDiv(divToPrint.current)} className="skp-link-hover text-uppercase skp-text-label">Download QR</div>}
                {loading && <div className="loading"></div>}
              </div>
              <div className="skp-text-light skp-text-small mt-2">
                {user ? tournament?.members.length : "" }{" "}
                <span className="skp-text-light">{t('sidePanel.registered')}</span> -{" "}
                {tournament?.maxPartecipants} {t('sidePanel.slots')}
              </div>
              <div className="space-divider-10"></div>
              <TournamentJoinButtons
                      tournament={tournament}
                      memberStatus={memberStatus}
                      registrationStarted={registrationStarted}
                      registrationClosed={registrationClosed}
                      checkinClosed={checkinClosed}
                      onReloadTournament={props.onReloadTournament}
                    />
                </>
              )}

              {registrationClosed && (
                <>
                  <div className="skp-text-label skp-text-light">
                    {t('sidePanel.registrationIsClosed')}
                  </div>
                  <div className="skp-text-light skp-text-small mt-2">
                    {user ? tournament?.members.length :"#"}{" "}
                  <span className="skp-text-light">{t('sidePanel.registered')}</span> -{" "}
                    {tournament?.maxPartecipants} {t('sidePanel.slots')}
                  </div>
                </>
              )}
            </div>
            { isCheckinPresent && (
              <>
                <div className="space-divider-20"></div>
            <div className="column col-12">
                <h5
                    className={
                      registrationClosed && !checkinClosed ? "skp-text-accent" : "skp-text-light"
                    }
                  >
                    {t('sidePanel.checkin')} {" "}
                    { registrationClosed && !checkinClosed && tournament.end_checkin && (
                    <span className="skp-text-small">
                      <Countdown date={new Date(tournament?.end_checkin)} />
                    </span>)}
                  </h5>

                  { !registrationClosed && (
                      <div className="skp-text-label skp-text-light">
                        {t('sidePanel.checkinWillOpenSoon')}
                      </div>
                    )
                  }
                  
                  { registrationClosed && !checkinClosed && (
                    <>
                      <div className="skp-text-label skp-text-light">
                        {t('sidePanel.confirmCheckin')}
                      </div>
                      <div className="skp-text-light skp-text-small mt-2">
                        {user ? tournament?.confirmedMembers.filter((player)=>player.length > 1).length : "#"}{" "}
                      <span className="skp-text-light">{t('sidePanel.checkedIn')} </span> -{" "}
                        {user ? tournament?.members.length: "#"} {t('sidePanel.registered')}
                      </div>
                      <div className="space-divider-10"></div>
                      <TournamentJoinButtons
                          tournament={tournament}
                          memberStatus={memberStatus}
                          registrationStarted={registrationStarted}
                          registrationClosed={registrationClosed}
                          checkinClosed={checkinClosed}
                          onReloadTournament={props.onReloadTournament}
                        />
                      </>
                  )}
                  
                  { registrationClosed && checkinClosed && (
                    <>
                      <div className="skp-text-label skp-text-light">
                        {t('sidePanel.checkInClosed')}
                      </div>
                      <div className="skp-text-light skp-text-small mt-2">
                        {user ? tournament?.confirmedMembers.filter((player)=>player.length > 1).length : "#"}{" "}
                        <span className="skp-text-light">{t('sidePanel.checkedIn')} </span> -{" "}
                        {user ? tournament?.members.length : "#"} {t('sidePanel.registered')}
                      </div>
                    </>
                    )
                  }
                </div> 
              </>
            )}

            {/* SEEDING */}
            <div className="space-divider-20"></div>
            <div className="column col-12">
              <h5
                className={
                  registrationClosed &&
                  checkinClosed &&
                  !onGoingTournament &&
                  !completedTournament
                    ? "skp-text-accent"
                    : "skp-text-light"
                }
              >
                {isCheckinPresent? 3 : 2 }{t('sidePanel.seeding')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.seedingMessage')}
              </div>
            </div>

            {/* START */}
            <div className="space-divider-20"></div>
            <div className="column col-12">
              <h5
                className={
                  onGoingTournament ? "skp-text-accent" : "skp-text-light"
                }
              >
                {isCheckinPresent? 4 : 3 }{t('sidePanel.start')}{" "}
                <span className="skp-text-small">
                  {tournament && tournament.start_tournament && (
                    <ReactTimeAgo date={new Date(tournament?.start_tournament)} />
                  )}{" "}
                  -{" "}
                  {Moment(tournament?.start_tournament).format(
                    "MMM DD, hh:mm A"
                  )}{" "}
                </span>
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.tournamentStarted')}
              </div>
            </div>
            <div className="space-divider-20"></div>
            <div className="column col-12">
              <h5
                className={
                  completedTournament ? "skp-text-primary" : "skp-text-light"
                }
              >
                {isCheckinPresent? 5 : 4 }{t('sidePanel.completed')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.tournamentEnded')}
              </div>
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default BracketsStatusPanel;
