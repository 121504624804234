import { useContext, useEffect, useState } from "react";
import TournamentTile from "../../UiLibrary/TournamentTile";
import { Link } from "react-router-dom";
import TournamentService from "../../../services/TournamentService";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITournament from "../../Interfaces/Tournament";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";

const UpcomingTournaments = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  const itemsToDisplay = 4;
  const [loading, setLoading] = useState(false);

  const [tournaments, setTournamentList] = useState<Array<ITournament>>([]);

  useEffect(() => {
    setLoading(true);
    const isTester = user && user?.roles && user.roles.includes("Tester") ? true : false
    const queryParam = {limit: itemsToDisplay, status: 0, "sort[order]":"asc", "sort[key]":"start_tournament"}
    TournamentService.getTournaments(queryParam, isTester, user?.id).then(
      (tournaments) => {
        setLoading(false);
        setTournamentList(tournaments);
      }
    );
  }, []);

  return (
    <>
      <div className="columns">
        <div className="column col-12">
          {/* Heading */}
          <div className="columns">
            <div className="column col-12 col-xs-12 text-center">
              <h4 className="skp-heading skp-primary-blue">
                {t('home.upcomingTournaments')}
              </h4>
            </div>
            <div
              className="column col-12 text-right hide-xs"
              style={{ marginTop: -30 }}
            >
              <Link to="/tournaments?status=0" className="skp-link-home">
                {t('home.viewAll')}
              </Link>
            </div>
          </div>

          {/* <RenderOnAuthenticated> */}
          {/* Tiles */}
          {loading && (
            <div className="columns columns-slider">
              <LoadingPlaceholders numberOfItems={4}>
                <div className="column col-3 col-xs-9 column-slider">
                  <div className={`tournament-tile tournament-tile-loading`}>
                    <div className={`tournament-tile-image`}>
                      <div className={`default-tile`}></div>
                    </div>

                    <div className={`tournament-tile-content columns`}>
                      <div className="column col-12">&nbsp;</div>
                      <div className="column col-12">&nbsp;</div>
                      <div className="column col-12">&nbsp;</div>
                      <div className="column col-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </LoadingPlaceholders>
            </div>
          )}
          {!loading && (
            <div className="columns columns-slider">
              <div className="space-divider-20"></div>
              <TransitionGroup component={null}>
                {tournaments?.length > 0 &&
                  tournaments?.map((tournament) => (
                    <CSSTransition
                      key={`tournament-${tournament.id}`}
                      timeout={300}
                      classNames="zoom"
                    >
                      <div className="column col-3 col-xs-9 column-slider">
                        <TournamentTile
                          tournament={tournament}
                        ></TournamentTile>
                      </div>
                    </CSSTransition>
                  ))}
              </TransitionGroup>
              {tournaments?.length === 0 && (
                <div className="column col-12">
                  <h6 className="skp-text-primary">{t('home.noTournaments')}</h6>
                </div>
              )}
            </div>
          )}
          {/* </RenderOnAuthenticated> */}
        </div>
      </div>
    </>
  );
};

export default UpcomingTournaments;
