
import constant from "../constants";
import { setupCache } from "axios-cache-adapter";
import HttpService from "./HttpService";
import LocalStorageService from "./LocalStorageService";
import KeyCloackService from "./KeyCloackService";

const _axios = HttpService.getAxiosClient();


const cache = setupCache({
  maxAge: 60 * 60 * 1000,
});

const getTournament = (yuniteTournamentId: string) => {
  let expireTime = 180000;
  if(KeyCloackService.hasRole(["Yunite Tournament Admin"])) {
    expireTime = 60000;
  }
  if(!LocalStorageService.getWithExpiry("tournament-"+yuniteTournamentId)){
    return getCacheTournament(yuniteTournamentId).then((response) => {
      LocalStorageService.setWithExpiry("tournament-"+yuniteTournamentId, response.data.data, expireTime)
      return response.data.data;
    })
  } else {
    return new Promise(resolve => {
      resolve(LocalStorageService.getWithExpiry("tournament-"+yuniteTournamentId))
    });
    
    
  }
}

const getCacheTournament = async(yuniteTournamentId: string) => {
  const url = `${constant.API_URL}/tournaments/${yuniteTournamentId}/yunite`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
}

const getTournaments = async() => {
    const url = `${constant.API_URL}/tournaments/yunite`;

    return _axios({
      method: "get",
      url: url,
      adapter: cache?.adapter,
    }).then((response) => {
      return response;
    });
}

const getCacheTournaments = () => {
  let expireTime = 180000;
  if(KeyCloackService.hasRole(["Yunite Tournament Admin"])) {
    expireTime = 60000;
  }
  if(!LocalStorageService.getWithExpiry("yuniteTournaments")){
    return getTournaments().then((response) => {
      LocalStorageService.setWithExpiry("yuniteTournaments", response.data.data, expireTime)
      return response.data.data.tournaments;
    })
  } else {
    return new Promise(resolve => {
      resolve(LocalStorageService.getWithExpiry("yuniteTournaments").tournaments)
    });
    
    
  }
}

const getMatches = (tournamentId: string) => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}/yunite/matches`;

    return _axios({
      method: "get",
      url: url,
      adapter: cache?.adapter,
    }).then((response) => {
      return response;
    });
}

const getCacheMatches = (tournamentId: string) => {
  let expireTime = 180000;
  if(KeyCloackService.hasRole(["Yunite Tournament Admin"])) {
    expireTime = 60000;
  }
  if(!LocalStorageService.getWithExpiry(tournamentId)){
    return getMatches(tournamentId).then((response) => {
      LocalStorageService.setWithExpiry(tournamentId, response.data.data, expireTime)
      return response.data.data;
    })
  } else {
    return new Promise(resolve => {
      resolve(LocalStorageService.getWithExpiry(tournamentId))
    });
    
    
  }
}

const getMatch = (tournamentId: string, matchId: string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/yunite/matches/${matchId}`;

  return _axios({
      method: "get",
      url: url,
      adapter: cache?.adapter,
    }).then((response) => {
      return response;
    });
};

const getCacheMatch = (tournamentId: string, matchId: string) => {
  let expireTime = 180000;
  if(KeyCloackService.hasRole(["Yunite Tournament Admin"])) {
    expireTime = 60000;
  }
  if(!LocalStorageService.getWithExpiry(tournamentId+matchId)){
    return getMatch(tournamentId, matchId).then((response) => {
      LocalStorageService.setWithExpiry(tournamentId+matchId, response.data.data, expireTime)
      return response.data.data;
    })
  } else {
    return new Promise(resolve => {
      resolve(LocalStorageService.getWithExpiry(tournamentId+matchId))
    });
    
    
  }
}

const getLeaderboard = (tournamentId: string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/yunite/leaderboard`;

  return _axios({
      method: "get",
      url: url,
      adapter: cache?.adapter,
    }).then((response) => {
      return response;
    });
};

const getCacheLeaderboard = (tournamentId: string) => {
  let expireTime = 180000;
  if(KeyCloackService.hasRole(["Yunite Tournament Admin"])) {
    expireTime = 60000;
  }
  if(!LocalStorageService.getWithExpiry(`leaderboard-${tournamentId}`)){
    return getLeaderboard(tournamentId).then((response) => {
      LocalStorageService.setWithExpiry(`leaderboard-${tournamentId}`, response.data.data, expireTime)
      return response.data.data;
    })
  } else {
    return new Promise(resolve => {
      resolve(LocalStorageService.getWithExpiry(`leaderboard-${tournamentId}`))
    });
    
    
  }
}

const getTeams = (tournamentId: string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/yunite/teams`;

  return _axios({
      method: "get",
      url: url,
      adapter: cache?.adapter,
    }).then((response) => {
      return response;
    });
};

const getCacheTeams = (tournamentId: string) => {
  let expireTime = 180000;
  if(KeyCloackService.hasRole(["Yunite Tournament Admin"])) {
    expireTime = 60000;
  }
  if(!LocalStorageService.getWithExpiry(`teams-${tournamentId}`)){
    return getTeams(tournamentId).then((response) => {
      LocalStorageService.setWithExpiry(`teams-${tournamentId}`, response.data.data, expireTime)
      return response.data.data;
    })
  } else {
    return new Promise(resolve => {
      resolve(LocalStorageService.getWithExpiry(`teams-${tournamentId}`))
    });
    
    
  }
}


const subscribeTournament = (tournamentId: string, userId: string) => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}/register?userID=${userId}`;
    return _axios({
      method: "post",
      url: url
    }).then((response) => {
      return response;
    });
}

const unSubscribeTournament = (tournamentId: string, userId: string) => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}/register?userID=${userId}`;
    return _axios({
      method: "put",
      url: url
    }).then((response) => {
      return response;
    });
}

const updateYuniteTournament = (yuniteTournamentId: string, tournamentId: string) => {
  const url = `${constant.API_URL}/tournaments/${yuniteTournamentId}/yunite`;
  return _axios({
    method: "put",
    url: url,
    data: { tournamentId: tournamentId },
  }).then((response) => {
    return response;
  });
}

const getQueueSize = (queueSize:number) => {
  switch (queueSize) {
    case 1:
        return "Solo";
    case 2:
        return "Duo";
    case 3:
        return "Trio";
    default:
        return;
        
  }
};


const YuniteTournamentService = {
  getTournament,
  getTournaments,
  getCacheTournaments,
  getMatches,
  getCacheMatches,
  getMatch,
  getCacheMatch,
  getLeaderboard,
  getCacheLeaderboard,
  getTeams,
  getCacheTeams,
  updateYuniteTournament,
  subscribeTournament,
  unSubscribeTournament,
  getQueueSize,
};

export default YuniteTournamentService