import { useContext, useEffect, useRef, useState } from "react";
import { EndMessage, MediaMessage, ReplyMessage, TextMessage, Timeline } from "../../UiLibrary/SocialComponents/MessageTypes/MessageTypes";
import { Scrollbars } from "react-custom-scrollbars";
import { UserContext } from "../../Context/UserContext";
import { ChatContext } from "../../Context/ChatContext";
import ChatService from "../../../services/ChatService";
import IMessage from "../../Interfaces/Chat/Message";
import { EventsContext } from "../../Context/EventsContext";
import { debounce } from "lodash";
 
const ChatPageMessages = () => {
    const { user, setUser } = useContext(UserContext);
    const { contacts, chatId, refresh, setRefresh, setReplyMessage, chatList } = useContext(ChatContext)
    const [chatHistory, setChatHistory] = useState<IMessage[]>([])
    const [contactList, setContactList] = useState<Array<string| undefined>>([user?.id])
    const scrollbars = useRef<any>(null);
    const selectedChat = chatList?.find((chat) => chat.id === chatId)

    const { socket, isConnected } = useContext(EventsContext);

    const handleSocketEvent = debounce((chatId, user, setChatHistory, scrolldown) => {
        const queryParam = { userid: user?.id };
        ChatService.getMessageHistory(chatId!, queryParam)
          .then((rchat) => {
            const endMessage: IMessage = {
              chatId: "",
              type: "end",
              userid: "",
              text: ""
            };
            rchat.push(endMessage);
            setChatHistory(rchat);
            scrolldown();
          })
          .catch((error) => {
            console.error("Errore durante il recupero della cronologia dei messaggi:", error);
          });
      }, 300); // Ritarda l'esecuzione di 300 ms

    useEffect(()=>{
        if (chatId) {
            const queryParam = {userid: user?.id}
            ChatService.getMessageHistory(chatId!, queryParam)
            .then((rchat) => {
                const chat:IMessage = {
                    chatId :"",
                    type :"end",
                    userid : "",
                    text : ""
                }

                rchat.push(chat)
                setChatHistory(rchat);
                scrolldown()
//                setLoading(false);
            })
            .catch((error) => {
//                setLoading(false);
            });
        } else {
            setChatHistory([])
        }
        scrolldown()
    }, [chatId, refresh])

    useEffect(() => {
        if (isConnected && socket && chatId) {
            socket.on(chatId, () => handleSocketEvent(chatId, user, setChatHistory, scrolldown));

            return () => {
                socket.off(chatId, () => handleSocketEvent(chatId, user, setChatHistory, scrolldown));
            };
        }
    }, [chatId, isConnected, socket, user]);

    const mustRefresh = () => {
        //setRefresh(refresh+1)
    }

    const replyMessage = (message:IMessage) => {
        setReplyMessage(message)
    }

    const scrolldown = () => {
        scrollbars.current?.scrollToBottom()
    }

    return (
        <div className="skp-chat-conversation-body">
            <Scrollbars ref={scrollbars}
                renderTrackVertical={
                    ({style, ...props}) => <div {...props} style={{...style, backgroundColor: '#292c33', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px', width: '5px'}}/>
                }
                renderThumbVertical={
                    ({style, ...props}) => <div {...props} style={{...style, width: '3px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)', backgroundColor: '#959aa2'}}/>
            }>
                {chatHistory && chatHistory.length > 0 && chatHistory.map((el, index) => {
                    switch (el.type) {
                        case "divider":
                            return <Timeline key={index} msg={el}></Timeline>
                        case "text":
                            return <TextMessage key={index} user={user} mustRefresh={mustRefresh} replyMessage={replyMessage} contacts={contacts}  msg={el} isDeleted={selectedChat?.isDeleted} tournament={undefined}></TextMessage>
                        case "reply":
                            return <ReplyMessage key={index} user={user} mustRefresh={mustRefresh} replyMessage={replyMessage} contacts={contacts} msg={el} isDeleted={selectedChat?.isDeleted} tournament={undefined}></ReplyMessage>
                        case "image":
                            return <MediaMessage key={index} user={user} mustRefresh={mustRefresh} replyMessage={replyMessage} contacts={contacts} msg={el} isDeleted={selectedChat?.isDeleted}></MediaMessage>
                        /*case "doc":
                            return <Timeline key={index} msg={el}></Timeline>
                        case "link": //Not Yet Implemented
                            return <Timeline key={index} msg={el}></Timeline>
                            */
                        default:
                            return <EndMessage key={index} scrolldown={scrolldown}></EndMessage>
                    }
                })}
                {!chatHistory || chatHistory.length === 0 && (
                    <div className="skp-info-message-box">
                        <h4 className="skp-text-primary">No Message Sent Yet</h4> 
                    </div>
                )}
            </Scrollbars>
        </div>
    );
};
  
export default ChatPageMessages;