import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./NewsTab.css"; // Stile CSS per il layout delle news
import NewsCard from "./NewsCard";
import INews from "../../../../Interfaces/News";
import NewsService from "../../../../../services/NewsService";


const NewsTab = () => {
  	const { t } = useTranslation();
  	const [newsList, setNewsList] = useState<INews[]>([]);
	  const [loading, setLoading] = useState(false);

	useEffect(() => {
    	NewsService.getAllNews().then((Rnews) => {
			console.log(Rnews)
      		setNewsList(Rnews);
      		//setLoading(false);
  		});
  	}, []);

	const handleSave = (id: string, updatedNews: INews) => {
        setLoading(true);
        NewsService.updateNews(id, updatedNews).then(() => {
            setNewsList((prevNewsList) =>
                prevNewsList.map((news) =>
                    news.id === updatedNews.id ? updatedNews : news
                )
            );
        }).finally(() => setLoading(false));
    };

	const handleDelete = (id: string) => {
        setLoading(true);
        NewsService.deleteNews(id)
            .then(() => {
                setNewsList((prevNewsList) =>
                    prevNewsList.filter(news => news.id !== id)
                );
            })
            .finally(() => setLoading(false));
    };

  	return (
    	<div className="container">
      		<div className="space-divider-20"></div>
      		<div className="columns">
        		<div className="column col-12">
          			<h5 className="skp-text-primary">{t("tabs.news")}</h5>
        		</div>
      		</div>
      		<div className="news-grid">
        		{newsList.length > 0 && newsList.map((newsItem) => (
          			<NewsCard 
						key={newsItem.id} 
						news={newsItem} 
						loading={loading}
                        onSave={handleSave}
						onDelete={handleDelete}
					/>
        		))}
        		{newsList.length === 0 && (
					<div className="column col-12">
                    	<h6 className="skp-text-primary">{t('modals.noNews')}</h6>
					</div>
				)}
      		</div>
    	</div>
  	);
};

export default NewsTab;
