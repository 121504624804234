import { useTranslation } from "react-i18next";
import GameAccount from "../../Interfaces/GameAccount";
import SocialNetwork from "../../Interfaces/SocialNetwork";
import GameAccountsFormFields from "./GameAccountsFormFields";

type FormModel = {
  username?: string;
  email?: string;
  biography?: string;
  discordId?: string;
  epicId?: string;
  gameAccounts?: Array<GameAccount>;
  social_networks?: Array<SocialNetwork>;
}

type Props = {
  values: FormModel
  gameAccounts: Array<GameAccount>
  addGameAccount: () => void
  removeGameAccount: (event: React.MouseEvent<HTMLElement>, index: number) => void
}

const GameAccountsControl = ({ values, gameAccounts, ...props}:Props) => {
  const { t } = useTranslation()
  
  return (
    <>
      <div className="space-divider-20"></div>
      <h5 className="skp-text-primary">{t('user.config.gameAccounts')}</h5>
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h6 className="skp-link-primary" onClick={props.addGameAccount}>
          {" "}
          <i className="las la-plus-circle skp-text-xxlarge"></i> {t('players.addGameAccount')}{" "}
        </h6>
      </div>

      <GameAccountsFormFields gameAccounts={gameAccounts} values={values} removeGameAccount={props.removeGameAccount}/>
    </>
  );
};

export default GameAccountsControl;
