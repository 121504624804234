import React from 'react'
import Scrollbars from 'react-custom-scrollbars';
import {isMobile} from 'react-device-detect';

const EpicidTooltip = () => {
    return (
      <Scrollbars style={{ height: isMobile ? 200 : 400 }}>
      <div className="skp-text-small text-left">
        <h6 className="text-center">Locating your Epic Account ID</h6>
        <ol>
          <li>Go to www.epicgames.com.</li>
          <li>Sign into your Epic Games account.</li>
          <li>Hover over your Epic Games account name and click on Account.</li>
          <li>
            Your account ID is listed at the top of the Personal Details page.
            <img
              alt="epic-id"
              style={{ width: "100%" }}
              src="https://cdn.elev.io/file/uploads/23tkX1gOVj2tN-DzzSy4RVFLA1UTAlp2Qd37Kw1pMLk/puzS5pjXtSxeIc4ztqdYdsLOz0_Dwuxh1REWSS4QTik/1583433462740-Ymg.png"
            />
          </li>
        </ol>

        <h6 className="text-center">
          Locating your Epic Account ID in Fortnite
        </h6>

        <ol>
          <li>Select settings.</li>
          <li>
            Select the account page.
            <img
              alt="account-page"
              style={{ width: "100%" }}
              src="https://cdn.elev.io/file/uploads/23tkX1gOVj2tN-DzzSy4RVFLA1UTAlp2Qd37Kw1pMLk/IK8Am3i1qWxxI1KCaIKATBNX0l6fmrMA_KR0Jk3CAzI/1583430587021-BLo.png"
            />
          </li>
          <li>
            Your Epic Account ID can be found under the Account Info.
            <img
              alt="account-info"
              style={{ width: "100%" }}
              src="https://cdn.elev.io/file/uploads/23tkX1gOVj2tN-DzzSy4RVFLA1UTAlp2Qd37Kw1pMLk/FIdWYNJ1-G9xkeKwS6GiYX-b_SW06_Jl9jDdzm6wvJQ/1583430595069-eXg.png"
            />
          </li>
        </ol>
      </div>
      </Scrollbars>
    );
}

export default EpicidTooltip
