import UserProfile from "../../../../UiLibrary/UserProfile";
import KeyCloackService from "../../../../../services/KeyCloackService";
import { Link } from "react-router-dom";
import TeamService from "../../../../../services/TeamService";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { UserContext } from "../../../../Context/UserContext";
import UserSearchDropdown from "../../../../UiLibrary/User/UserSearchDropdown";
import IUserDetails from "../../../../Interfaces/UserDetails";
import { AnimatedList } from "react-animated-list";
import { useTranslation } from "react-i18next";
import IBrand from "../../../../Interfaces/Brand";

type Props = {
  brand: IBrand | undefined;
  doLogin: () => void;
  setBrand: (brand: IBrand) => void;
};

const PendingsTab = (props: Props) => {
  const { brand } = props;
  const { t } = useTranslation()
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const isBrandAdmin: boolean | undefined = brand?.admins?.includes(user?.id!);
  const isAdmin = ((brand?.owner === user?.id || isBrandAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

  const confirmMember = (memberId: string) => {
    if (!brand?.id || !user?.id) {
      return;
    }
    TeamService.acceptMemberInBrand(brand.slug, memberId)
      .then((tResponse) => {
        toast.success(t('brands.members.memberAcceptedSuccess'));
        props.setBrand(tResponse);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const inviteUser = (member: IUserDetails) => {
    if (!brand?.id || !member?.id) {
      return;
    }
    TeamService.inviteMemberInBrand(brand.slug, member.id)
      .then((tResponse) => {
        toast.success(t('brands.members.memberInvitedSuccess'));
        props.setBrand(tResponse);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const deletePending = (memberId: string) => {
    if (!brand?.id) {
      return;
    }
    setLoading(true);
    TeamService.deletePendingInBrand(brand.slug, memberId)
      .then((tResponse) => {
        setLoading(false);
        toast.success(t('brands.members.pendingDeletedSuccess'));
        props.setBrand(tResponse);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="players-tab container tab-with-table">
      <div className="columns">
        <div className="space-divider"></div>
        <div className="column col-4">
          <h5 className="skp-text-primary">{t('brands.members.invitePlayers')}</h5>
          <UserSearchDropdown setUser={inviteUser} />
        </div>

        <div className="column col-8">
          {brand?.pendings && brand?.pendings.length > 0 && (
            <div className="columns">
              <div className="column col-12">
                <h5 className="skp-text-primary">{t('brands.members.toReview')}</h5>
                <div className="bordered-map">
                  <AnimatedList animation={"zoom"}>
                    {brand?.pendings && 
                    brand?.pendings.filter((member) => member.status === 0).map((player, index) => (
                      <div
                        key={`pendings-waiting-${player.id}-${index}`}
                        className="columns flex-centered show-on-hover"
                      >
                        <div className="column col-8">
                          <Link to={`/users/${player.id}/overview`}>
                            <UserProfile userId={player.id}>
                              {isAdmin && (
                                <span className="skp-chip status-captain skp-text-xxsmall">
                                  {t('common.admin')}
                                </span>
                              )}
                            </UserProfile>
                          </Link>
                        </div>
                        <div className="column col-4 text-right">
                          {isAdmin && (
                            <button
                              className="skp-btn skp-btn-primary m-2"
                              onClick={() => confirmMember(player.id)}
                            >
                              {t('brands.members.acceptMember')}
                            </button>
                          )}
                          {isAdmin && (
                            <span
                              className="skp-link-hover skp-text-small m-2"
                              onClick={() => deletePending(player.id)}
                            >
                              {!loading && <i className="las la-trash"></i>}
                              {loading && <span className="loading"></span>}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </AnimatedList>
                </div>
              </div>
              <div className="column col-12">
                <h5 className="skp-text-primary">{t('brands.members.waitingConfirmation')}</h5>
                <div className="bordered-map">
                  <AnimatedList animation={"grow"}>
                    {brand?.pendings &&
                    brand?.pendings.filter((member) => member.status === 1).map((player, index) => (
                      <div
                        key={`${player.id}-${index}`}
                        className="columns flex-centered show-on-hover"
                      >
                        <div className="column col-7">
                          <Link to={`/users/${player.id}/overview`}>
                            <UserProfile userId={player.id}>
                              {(brand?.owner === player.id || brand?.admins?.includes(player.id) ) && (
                                <span className="skp-chip status-captain skp-text-xxsmall">
                                  {t('common.admin')}
                                </span>
                              )}
                            </UserProfile>
                          </Link>
                        </div>
                        <div className="column col-5 text-right flex-centered">
                          <span className="skp-text-quaternary skp-text-small m-2">
                            {t('brands.members.waitingMemberConfirmation')}
                          </span>

                          {isAdmin && (
                            <span
                              className="skp-link-hover skp-text-small m-2"
                              onClick={() => deletePending(player.id)}
                            >
                              {!loading && <i className="las la-trash"></i>}
                              {loading && <span className="loading"></span>}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </AnimatedList>
                </div>
              </div>
              <div className="space-divider-40"></div>
              {/* Login button */}
              {!KeyCloackService.isLoggedIn() && (
                <div className="column col-12 text-center">
                  <div className="skp-text-light">
                    {t('players.mustLogin')}
                  </div>
                  <div className="space-divider-20"></div>
                  <button
                    className="skp-btn skp-btn-primary"
                    onClick={props.doLogin}
                  >
                    {t('common.login')}
                  </button>
                </div>
              )}
            </div>
          )}
          {brand?.pendings?.length === 0 && (
            <div className="columns skp-text-light text-center">
              <div className="space-divider"></div>
              <div className="column col-12">
                <h1>
                  <i className="las la-user-friends"></i>
                </h1>
              </div>
              <div className="column col-12">
                <h5>{t('players.noPlayers')}</h5>
              </div>
              <div className="column col-12">
                {t('brands.members.noPlayerRegistered')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingsTab;
