import { useContext, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import constant from "../../../../../constants";
import ApexTournamentService from "../../../../../services/ApexTournamentService";
import KeyCloackService from "../../../../../services/KeyCloackService";
import { UserContext } from "../../../../Context/UserContext";
import ITournament from "../../../../Interfaces/Tournament";
import ITournamentApexLobby from "../../../../Interfaces/TournamentApexLobby";
import ITournamentTeam from "../../../../Interfaces/TournamentTeam";
import Dropdown from "../../../../UiLibrary/Dropdown";
import Modal from "../../../../UiLibrary/Modal";
import TournamentTeamsTypeahead from "../../../../UiLibrary/Typeahead/TournamentTeamsTypeAhead";
import UserProfileWithDetailsModal from "../../../../UiLibrary/User/UserProfileWithDetailsModal";

type Props = {
  lobby: ITournamentApexLobby
  tournament: ITournament
  onReloadTournament: (tournament?:ITournament) => void
}

type TModal = {show: boolean, team?: ITournamentTeam | undefined}

const ApexLobbyTeams = ({ lobby, tournament, ...props }:Props) => {
  const {user} = useContext(UserContext)
  const isTournamentAdmin = tournament?.admins?.includes(user?.id!);
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

/*  const isAdmin =
    KeyCloackService.hasRole(["Tournament Admin"]) &&
    (tournament?.owner === user?.id || isTournamentAdmin);*/
  const [showTypeahead, setShowTypeahead] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState<TModal>({
    show: false,
    team: undefined,
  });
  const [showAddModal, setShowAddModal] = useState<TModal>({
    show: false,
    team: undefined,
  });
  const [loading, setLoading] = useState(false);

  const deleteTeam = (team:ITournamentTeam | undefined) => {
    if(!lobby?.teams || !team) {
      return
    }
    setLoading(true);
    const teamIndex = lobby.teams.findIndex(
      (el) => el.partyId === team.partyId
    );

    let lobbyTeams = [...lobby.teams as Array<ITournamentTeam>];
    lobbyTeams.splice(teamIndex, 1);

    const data = {
      name: lobby?.name,
      tokens: lobby?.tokens,
      matches: lobby?.matches,
      teams: lobbyTeams,
      leaderboards: lobby?.leaderboards,
      winner: lobby?.winner,
    };

    ApexTournamentService.updateLobby(tournament?.id, lobby?.id, data).then(
      (response) => {
        setLoading(false);
        props.onReloadTournament();
        setShowRemoveModal({ show: false });
      }
    );
  };

  const setTeam = (team:ITournamentTeam) => {
    setShowAddModal({ show: true, team: team });
  };

  const addTeam = (team:ITournamentTeam | undefined) => {
    if(!team) {
      return
    }
    
    setLoading(true);

    let lobbyTeams = [...lobby.teams as Array<ITournamentTeam>];
    lobbyTeams.push(team);

    const data = {
      name: lobby?.name,
      tokens: lobby?.tokens,
      matches: lobby?.matches,
      teams: lobbyTeams,
      leaderboards: lobby?.leaderboards,
      winner: lobby?.winner,
    };

    ApexTournamentService.updateLobby(tournament?.id, lobby?.id, data).then(
      (response) => {
        setLoading(false);
        props.onReloadTournament();
        setShowAddModal({ show: false });
      }
    );
  };

  return (
    <>
      {isAdmin && (
        <div className="column col-12">
          <h5
            className="skp-link-hover d-inline-block"
            onClick={() => setShowTypeahead(true)}
          >
            <span className="mr-1">
              <i className="las la-plus-circle"></i>
            </span>
            <span className="text-uppercase skp-text-small">Add Team</span>
          </h5>

          <Dropdown show={showTypeahead}>
            <OutsideClickHandler onOutsideClick={() => setShowTypeahead(false)}>
              <TournamentTeamsTypeahead
                tournament={tournament}
                onSelect={(team:ITournamentTeam) => setTeam(team)}
                hideEmptySelection
              />
            </OutsideClickHandler>
          </Dropdown>
          <div className="space-divider-20"></div>
        </div>
      )}

      <div className="column col-12">
        {lobby.teams &&
          lobby.teams.length > 0 &&
          lobby.teams.map((team, index) => (
            <div key={`team-${index}`} className="skp-card skp-card-hover">
              <div className="accordion p-2">
                <input
                  type="checkbox"
                  id={`accordion-teams-${lobby.name}-id-${index}`}
                  name={`accordion-teams-${lobby.name}`}
                  hidden
                />
                <label
                  className="accordion-header"
                  htmlFor={`accordion-teams-${lobby?.name}-id-${index}`}
                >
                  <div className="columns skp-text-primary">
                    <div className="column col-11">
                      {!team.partyName && <h5>Team {index}</h5>}
                      {team.partyName && (
                        <h5 className="mt-2">
                          <div className="skp-text-label skp-text-light text-uppercase">
                            Team
                          </div>{" "}
                          <span className="border-box p-2 mt-2 d-inline-block">
                            {index + 1}
                          </span>
                          <span className="ml-2">{team.partyName}</span>
                          {isAdmin && (
                            <span
                              className="skp-link-hover skp-text-label text-uppercase float-right"
                              onClick={() =>
                                setShowRemoveModal({ show: true, team: team })
                              }
                            >
                              remove Team
                            </span>
                          )}
                        </h5>
                      )}
                    </div>
                    <div className="column col-1 skp-text-primary text-right">
                      <i className="las la-angle-right"></i>
                    </div>
                  </div>
                </label>
                <div className="accordion-body">
                  <dl className="bordered-list">
                    {team.members.map((member, index) => (
                      <dd className="columns" key={member + index.toString()}>
                        <div className="column col-10">
                          <UserProfileWithDetailsModal
                            userId={member.userId}
                            tournament={tournament}
                          >
                            {member.userId === team.leader && (
                              <span className="skp-chip status-captain skp-text-small">
                                Leader
                              </span>
                            )}

                            {member.userId !== team.leader && (
                              <span className="skp-chip status-1 skp-text-small">
                                {constant.TEAM_USER_ROLE[member.role]}
                              </span>
                            )}
                          </UserProfileWithDetailsModal>
                        </div>
                      </dd>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          ))}
        {(lobby?.teams?.length === 0 || !lobby.teams) && (
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 skp-text-light text-center">
              <h1>
                <i className="las la-users"></i>
              </h1>
            </div>
            <div className="column col-12 skp-text-light text-center">
              <h5>No teams</h5>
            </div>
            <div className="column col-12 skp-text-light text-center">
              There are no teams for this lobby.
            </div>
            <div className="space-divider"></div>
          </div>
        )}
      </div>

      <Modal
        title="Remove Team"
        show={showRemoveModal.show}
        onClose={() => setShowRemoveModal({ show: false })}
        size="medium"
        confirmation="Remove Team"
        onConfirm={() => deleteTeam(showRemoveModal.team)}
        loading={loading}
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 flex-centered skp-text-primary">
              Are you sure you want to remove "{showRemoveModal.team?.partyName}
              " ?
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Add Team"
        show={showAddModal.show}
        onClose={() => setShowAddModal({ show: false })}
        size="medium"
        confirmation="Add Team"
        onConfirm={() => addTeam(showAddModal.team)}
        loading={loading}
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 flex-centered skp-text-primary">
              Are you sure you want to add "{showAddModal.team?.partyName}" to "
              {lobby?.name}"?
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApexLobbyTeams;
