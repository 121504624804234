import { useState } from "react";
import ITournament from "../../../../Interfaces/Tournament";
import LobbyGameTab from "./LobbyGameTab";
import LobbyLeaderboardTab from "./LobbyLeaderboardTab";
import LobbyTeamsTab from "./LobbyTeamsTab";

type Props = {
    tournament: ITournament;
    lobby: any;
};

const SingleLobby = ({ lobby, tournament, ...props }: Props) => {
    const [tabsController, setTabController] = useState({ tab: "games" });

    return (
        <>
            <div className="p-2">
                <div className="play-skp-bg m-2">
                    <label className="accordion-header" htmlFor={lobby.name + lobby.id}>
                        <div className="columns skp-text-primary">
                            <div className="column col-11">
                                <h6 className="text-uppercase mb-2">{lobby.name}</h6>{lobby.map_name && lobby.map_name !== "" ? 
                                    "Map: " + lobby.map_name : " "}
                            </div>
                        </div>
                    </label>
                    <div className="column col-12">
                        <ul className="tab skp-text-light">
                            <li
                            className={`tab-item ${
                                tabsController.tab === "games" && "active"
                            }`}
                            >
                            <a onClick={() => setTabController({ tab: "games" })}>
                                Games
                            </a>
                            </li>
                            <li
                            className={`tab-item ${
                                tabsController.tab === "leaderboard" && "active"
                            }`}
                            >
                            <a onClick={() => setTabController({ tab: "leaderboard" })}>
                                Leaderboard
                            </a>
                            </li>
                            <li
                            className={`tab-item ${
                                tabsController.tab === "teams" && "active"
                            }`}
                            >
                            <a onClick={() => setTabController({ tab: "teams" })}>
                                Teams
                            </a>
                            </li>
                        </ul>
                    </div>
                    {tabsController.tab === "games" && (
                        <LobbyGameTab
                            lobby={lobby}
                            tournament={tournament}
                        />
                    )}
                    {tabsController.tab === "leaderboard" && (
                        <LobbyLeaderboardTab tournament={tournament} lobby={lobby} />
                    )}
                    {tabsController.tab === "teams" && (
                        <LobbyTeamsTab
                            lobby={lobby}
                            tournament={tournament}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default SingleLobby;