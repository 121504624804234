import axios from "axios";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./nintendo-style.scss";
import Marquee from "react-text-marquee";
import { useParams } from "react-router-dom";
import TournamentService from "../../../services/TournamentService";
import AnimatedLoader from "../../UiLibrary/Loaders/AnimatedLoader";
import { toast } from "react-toastify";


const lobbyRows = [1, 10, 19, 28];

const NintendoTournament = () => {
  const [loading, setLoading] = useState(true);
  const sheetId = useParams<{spreadsheetId:string}>().spreadsheetId;
  const [groupByTwoColumns, setGroupByTwoColumns] = useState<Array<Array<Array<string>>>>([]);

  useEffect(() => {
    if(!sheetId) {
      return;
    }
    
    setLoading(true);

    TournamentService.getSpreadsheet(sheetId, "Foglio1").then(Rvalues => {
      const columns = Rvalues.reduce(
        (accumulator:any[], currentValue:any, currentIndex:number, array:any[]) => {
          if (currentIndex % 2 === 0)
            accumulator.push(array.slice(currentIndex, currentIndex + 2));
          return accumulator;
        },
        []
      );

      setGroupByTwoColumns(columns);

      setLoading(false);
    }).catch(error => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    });
    
  }, [sheetId]);

  return (
    <Scrollbars renderView={(props:any) => <div {...props} className="wearena-online-event-wrapper" />}>
      {loading && <div className="flex-centered" style={{width: "100%", height: "100%"}}><AnimatedLoader /></div>}
      {!loading && <div className="nintendo-online-event">
        <div className="nintendo-online-event-side show-xs">
          <div className="side-content"></div>
        </div>
        <div className="mario-kart-logo hide-xs"></div>
        <div className="nintendo-online-event-content column-xs-12">
          <div className="space-divider-20"></div>
          <div className="space-divider show-xs m-2"></div>
          <div
            className="columns columns-slider"
            style={{ flexWrap: "nowrap" }}
          >
            {groupByTwoColumns?.map((mainColumn, mainColumnIndex) => (
              <div
                key={`column-${mainColumnIndex}`}
                className={`main-column column column-slider col-xs-12 text-center-xs col-${
                  12 / groupByTwoColumns?.length
                }`}
              >
                {/* Title */}
                <div className="text-center main-column-heading skp-text-primary">
                  {mainColumn[0][0]}
                </div>
                {/* END Title */}

                <div className="columns sub-columns">
                  {mainColumn?.map((column, columnIndex:number) => (
                    <div
                      key={`column-${columnIndex}`}
                      className={`column col-auto column-slider text-center-xs`}
                    >
                      <dl className="sheet-list skp-text-primary">
                        {column.map((element:string, index:number) => (
                          <dd
                            key={`column-${columnIndex}-${element}-${index}`}
                            className={`${index === 0 && "row-title"} ${
                              lobbyRows.includes(index) && "row-lobby"
                            } ${index === column.length - 1 && "row-last"}`}
                          >
                            
                                <div className="skp-text-primary p-relative">
                                  <span
                                    className={`icon-placement ${
                                      element === "1" && "star"
                                    } ${element === "2" && "flower"} ${
                                      element === "3" && "mushroom"
                                    }`}
                                  >
                                    <Marquee text={element} />
                                  </span>
                                </div>
                              
                          </dd>
                        ))}
                      </dl>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
    </Scrollbars>
  );
};

export default NintendoTournament;
