import { useEffect, useState } from "react";
import TournamentService from "../../../../services/TournamentService";

type Props = {
  match: any;
};

const WeArenaSingleMatch = (props: Props) => {
  const { match } = props;
  const sheetId = "1efbW737jYRgYx_dhZ5DXQY4n1vLjME0qzp5ZEVqeHSo";
  const [columns, setColumns] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!match) {
      return
    }
    setLoading(true);
    TournamentService.getSpreadsheet(sheetId, match.title).then((res) => {
      setColumns(res);
      setLoading(false);
    }).catch(error => {
        setLoading(false);
        setColumns([])
    })
  }, [match]);

  return (
    <>
      {loading && <div className="column col-12 flex-centered" style={{height:200}}><div className="loading" /></div>}
      <div className="container">
        <div className="columns skp-text-primary">
            {!loading && columns.length === 0 && <div className="column col-12 flex-centered" style={{height:200}}>No matches yet!</div>}
          <div className="column col-6">
            {!loading &&
              columns &&
              columns[0]?.map((score: string, index:number) => (
                <>
                  {/* <dl>{value.map((test: any) => ( */}
                  <>
                  <dd style={{ height: 80 }} className={`columns ${Number(score) > Number(columns[1][index])
                        ? "skp-text-quaternary"
                        : ""}`}>
                      <div className="column col-6 flex-centered">
                        <img
                          className="mr-2"
                          style={{
                            width: 30,
                            height: 30,
                            objectFit: "cover",
                            borderRadius: 50,
                          }}
                          src={match?.teamA?.profilePicture || "https://images.vogue.it/users/my/avatar.jpg"}
                          alt="team-a-profile"
                        />
                        {match?.teamA?.name}
                      </div>
                      <div className="column col-6 flex-centered">{score}</div>
                    </dd>
                  </>
                  {/* ))}</dl> */}
                </>
              ))}
          </div>
          <div className="column col-6">
            {!loading &&
              columns &&
              columns[1]?.map((score: string, index:number) => (
                <>
                  {/* <dl>{value.map((test: any) => ( */}
                  <>
                    <dd style={{ height: 80 }} className={`columns ${Number(score) > Number(columns[0][index])
                        ? "skp-text-quaternary"
                        : ""}`}>
                      <div className="column col-6 flex-centered">{score}</div>
                      <div className="column col-6 flex-centered">
                        {match?.teamB?.name}
                        <img
                          className="ml-2"
                          style={{
                            width: 30,
                            height: 30,
                            objectFit: "cover",
                            borderRadius: 50,
                          }}
                          src={match?.teamB?.profilePicture || "https://images.vogue.it/users/my/avatar.jpg"}
                          alt="team-a-profile"
                        />
                      </div>
                    </dd>
                  </>
                  {/* ))}</dl> */}
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WeArenaSingleMatch;
