import GAMES from "../../../constants-games";
import ITournament from "../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../Interfaces/TournamentMemberStatus";
import BracketsControlPanel from "./TournamentControlPanels/BracketsControlPanel";
import ControlPanel from "./TournamentControlPanels/ControlPanel";
import YuniteControlPanel from "./TournamentControlPanels/YuniteControlPanel";
import BracketsStatusPanel from "./TournamentStatusPanels/BracketsStatusPanel";
import DefaultStatusPanel from "./TournamentStatusPanels/DefaultStatusPanel";
import YuniteStatusPanel from "./TournamentStatusPanels/YuniteStatusPanel";

type Props = {
  tournament: ITournament
  isSettings: boolean
  memberStatus: ITournamentMemberStatus
  updateStatus: (status: number) => void
  deleteTournament: () => void
  onReloadTournament: (tournament?:ITournament) => void
  joinTournament?: () => void
}

const TournamentSideControl = (props:Props) => {
  const { tournament, isSettings, memberStatus } = props;


  if(isSettings) {
      if (GAMES[tournament?.game]?.CONTROL_TYPE === "bracket" && !tournament?.yuniteId) {
        return (
          <BracketsControlPanel
            {...props}
            memberStatus={memberStatus}
            updateStatus={props.updateStatus}
            deleteTournament={props.deleteTournament}
            onReloadTournament={props.onReloadTournament}
          />
        );
      } 
      else if (tournament?.yuniteId) {
        return (
          <YuniteControlPanel
            {...props}
            updateStatus={props.updateStatus}
            deleteTournament={props.deleteTournament}
            onReloadTournament={props.onReloadTournament}
          />
        );
      } else {
        return (
          <ControlPanel
            {...props}
            updateStatus={props.updateStatus}
            deleteTournament={props.deleteTournament}
          />
        );
      }
  }
  if(tournament?.game && GAMES[tournament?.game]?.CONTROL_TYPE === 'bracket' ) {
    return <BracketsStatusPanel {...props} />;
  }  else if ((tournament?.game === GAMES.FORTNITE.ENUM && !tournament?.yuniteId)) {
    return <BracketsStatusPanel {...props} />;;
  } else if (tournament?.yuniteId) {
    return <YuniteStatusPanel {...props} />;
  } else {
    return (
      <DefaultStatusPanel {...props} />
    );
  }
};

export default TournamentSideControl;
