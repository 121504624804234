import React, { useEffect, useState, useCallback } from "react";
import YuniteTournamentService from "../../../../services/YuniteTournamentService";
import YunitePlayer from "../../../UiLibrary/YunitePlayer";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingPlaceholders from "../../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import UserService from "../../../../services/UserService";
import { DebounceInput } from "react-debounce-input";
import ITournament from "../../../Interfaces/Tournament";
import RenderOnAnonymous from "../../../KeyCloack/RenderOnAnonymus";
import RenderOnAuthenticated from "../../../KeyCloack/RenderOnAuthenticated";
import Modal from "../../../UiLibrary/Modal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import { useTranslation } from "react-i18next";

type Props = {
  yuniteId: string | undefined;
  tournament?: ITournament;
};

function LeaderboardTab(props: Props) {
  const { yuniteId, tournament } = props;
  const { t } = useTranslation()
  const [teams, setTeams] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>();
  const [paginatedItems, setPaginatedItems] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const loadLeaderboard = useCallback(() => {
    if (!yuniteId) {
      return;
    }
    setLoading(true);
    YuniteTournamentService.getCacheLeaderboard(yuniteId)
      

      .then((response) => {
        if (Object.keys(response).length === 0) {
          setTeams([]);
        } else {
          setTeams(response);
        }

        const index = response.length >= 10 ? 10 : response.length;
        let items:any = [];
        for (let i = 0; i < index; i++) {
          items.push(response[i]);
        }
        setPaginatedItems(items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, [yuniteId]);

  useEffect(() => {
    loadLeaderboard();

    return () => {
      setPaginatedItems([]);
      setTeams([]);
      setSearchText(undefined);
      setLoading(false);
    };
  }, [loadLeaderboard]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginatedItems([...paginatedItems, teams[paginatedItems.length]]);
    }, 100);
  };

  const searchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      loadLeaderboard();
      return;
    }
    UserService.getUserByName(event.target.value).then((users) => {
      const usersByUsername = users;
      const findUserByEpicId = teams.filter((placement) =>
        placement.users.find((user: any) =>
          usersByUsername.find(
            (filteredUser) => filteredUser.epicId === user.epicId
          )
        )
      );

      if (findUserByEpicId.length === 0) {
        loadLeaderboard();
        return;
      }
      setTeams(findUserByEpicId);
      setPaginatedItems(findUserByEpicId);
    });
  };

  return (
    <>
      <RenderOnAnonymous>
        <>
          <div className="container">
            <div className="space-divider"></div>
            <div className="columns">
              <div className="column col-12 text-center">
                <div className="skp-text-light">
                  {t('players.mustLogin')}
                </div>
                <div className="space-divider-20"></div>
                <button
                  className="skp-btn skp-btn-primary"
                  onClick={() => setShowLoginModal(true)}
                >
                  {t('common.login')}
                </button>
              </div>
            </div>
          </div>
          <Modal
            show={showLoginModal}
            size="small"
            onClose={() => setShowLoginModal(false)}
          >
            <LoginOrRegisterModal
              message={constant.MESSAGES.LOGIN.TEAM_PLAYERS}
              onClose={() => setShowLoginModal(false)}
            />
          </Modal>
        </>
      </RenderOnAnonymous>

      <RenderOnAuthenticated>
        <div className="container">
          {/* LOADING */}
          {loading && (
            <>
              <div className="columns">
                <div className="column col-12">
                  <div className="skp-table-flex">
                    <div className="skp-table-flex-row skp-text-light">
                      <div className="skp-table-flex-cell"></div>
                      <div className="skp-table-flex-cell">{t('common.team')}</div>
                      <div className="skp-table-flex-cell text-center">
                        {t('common.games')}
                      </div>
                      <div className="skp-table-flex-cell text-center">
                        {t('common.wins')}
                      </div>
                      <div className="skp-table-flex-cell text-center">
                        {t('results.score')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoadingPlaceholders numberOfItems={10}>
                <div className="columns">
                  <div className="column col-12">
                    <div className="skp-table-flex">
                      <div className="skp-table-flex-row skp-table-flex-row-loading"></div>
                    </div>
                  </div>
                </div>
              </LoadingPlaceholders>
            </>
          )}
          {/* END LOADING */}

          {!loading && teams?.length > 0 && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-6 col-xs-12">
                <div className="skp-form-control">
                  <label className="skp-text-primary skp-text-small">
                    {t('user.search.searchUserInLeaderboard')}
                  </label>
                  <DebounceInput
                    value={searchText}
                    type="text"
                    minLength={1}
                    debounceTimeout={500}
                    placeholder={t('modals.searchByUser')}
                    onChange={(e) => searchUser(e)}
                  />
                </div>
              </div>
              <div className="column col-6 text-right skp-text-small hide-xs"></div>
              <div className="column col-12">
                <div className="container">
                  <div className="columns skp-text-light m-2">
                    <div className="column col-1 flex-centered">#</div>
                    <div className="column col-8 col-xs-10">{t('common.team')}</div>
                    <div className="column col-1 flex-centered hide-xs">
                      {t('common.games')}
                    </div>
                    <div className="column col-1 flex-centered hide-xs">
                      {t('common.wins')}
                    </div>
                    <div className="column col-1 flex-centered">{t('results.score')}</div>
                  </div>
                  <InfiniteScroll
                    dataLength={paginatedItems.length}
                    scrollableTarget="scrollableDiv"
                    next={fetchMoreData}
                    hasMore={teams.length > paginatedItems.length}
                    style={{ overflow: "hidden" }}
                    loader={
                      <div>
                        <div className="loading"></div>
                      </div>
                    }
                    scrollThreshold={"100px"}
                    endMessage={
                      <>
                        {paginatedItems.length >= 10 && (
                          <>
                            <p
                              className="text-center skp-text-light m-2"
                              style={{ textAlign: "center" }}
                            >
                              {t('common.end')}
                            </p>
                          </>
                        )}
                      </>
                    }
                  >
                    {paginatedItems?.map((team) => (
                      <div
                        className="columns skp-text-primary m-2 bordered-list pt-2"
                        key={team?.teamId}
                      >
                        <div className="column col-1 flex-centered">
                          {team?.placement}
                        </div>
                        <div className="column col-8 col-xs-10">
                          <div className="columns">
                            {team && tournament &&
                              team.users.map((user: any) => (
                                <div
                                  className={`column col-${
                                    12 / team.users.length
                                  }`}
                                >
                                  <YunitePlayer
                                    player={user}
                                    tournament={tournament}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="column col-1 flex-centered hide-xs">
                          {team?.games}
                        </div>
                        <div className="column col-1 flex-centered hide-xs">
                          {team?.wins}
                        </div>
                        <div className="column col-1 flex-centered">
                          {team?.score}
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          )}

          {/* No Leaderboard */}
          {!loading && (teams?.length === 0 || error) && (
            <div className="columns skp-text-light text-center">
              <div className="space-divider"></div>
              <div className="column col-12">
                <h1>
                  <i className="las la-trophy"></i>
                </h1>
              </div>
              <div className="column col-12">
                <h5>{t('results.noResults')}</h5>
              </div>
              <div className="column col-12">{t('user.search.leaderboardEmpty')}</div>
            </div>
          )}
        </div>
      </RenderOnAuthenticated>
    </>
  );
}

export default LeaderboardTab;
