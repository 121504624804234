import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TournamentMatchesContext } from "../../../Context/TournamentMatchesContext";
import MatchTeamName from "../../../UiLibrary/MatchComponents/MatchTeamName";
import UserProfileWithDetailsModal from "../../../UiLibrary/User/UserProfileWithDetailsModal";
import "./tournaments-tab.scss";
import MatchLevel from "../../../../enums/MatchLevel";
import { useTranslation } from "react-i18next";

const ResultsTab = (props) => {
  const { tournament } = props;
  const { t } = useTranslation()
  const { tournamentMatches } = useContext(TournamentMatchesContext);
  const [winners, setWinners] = useState([]);
  const [teams, setTeams] = useState([])

  useEffect(() => {
    let isResetGrandFinal = false;
    let final
    let resetGrandFinal
    let thirdPlace
    let tournamentFirstPlace;
    let tournamentSecondPlace;
    let tournamentThirdPlace;
    let tournamentFourthPlace;

    if (tournament && tournament.custom && tournament.leaderboard.length > 0) {
      thirdPlace = false;
      final = false;

      const team1 = {id: tournament.teams[0].partyId, score: 0}
      const team2 = {id: tournament.teams[1].partyId, score: 0}

      for (const playerElem of tournament.leaderboard) {
        if (playerElem.teamId === team1.id) {
          team1.score += Number(playerElem.score)
        }
        if (playerElem.teamId === team2.id) {
          team2.score += Number(playerElem.score)
        }
      }
    
      tournamentFirstPlace = team1.id
      tournamentSecondPlace = team2.id
      if( team1.score >= team2.score ) { 
        tournamentFirstPlace = team1.id
        tournamentSecondPlace = team2.id
        setTeams([team1, team2])
      }
      else { 
        tournamentFirstPlace = team2.id
        tournamentSecondPlace = team1.id
        setTeams([team2, team1])
      }
    }

    if (tournament && tournament?.matches && tournament.matches[0] &&  tournament.matches[0] !== undefined && tournament.matches[0].matchLevel >= 0) {
      final = tournament?.matches.find((match) => match.matchLevel === MatchLevel.SINGLE_ELIMINATION_FINAL);
      if (!final) {
        resetGrandFinal = tournament?.matches.find((match) => match.matchLevel === MatchLevel.DOUBLE_ELIMINATION_RESET_GRAND_FINAL);
        final = tournament?.matches.find((match) => match.matchLevel === MatchLevel.DOUBLE_ELIMINATION_GRAND_FINAL);
        isResetGrandFinal = true
      } 
      thirdPlace = tournament?.matches.find((match) => match.matchLevel === MatchLevel.SINGLE_ELIMINATION_THIRD_FORTH_PLACE);
    } else {
      final = tournament?.matches.find((match) => match.round === "Final");
      thirdPlace = tournament?.matches.find((match) => match.round === "Third Fourth Place");
    }

    if (isResetGrandFinal && resetGrandFinal) {
      /*
       * DoubleElimination, controllo se ho il reset gran final valorizzato o meno, se lo è
       * assegno a final il valore di resetGrandFinal
       */
      const finalMatch = tournamentMatches?.matches?.find((match) => {
        return match.id === resetGrandFinal.matchesId[0];
      });
      if (finalMatch?.result.scores[0].teamId && finalMatch?.result.scores[0].teamId.length >= 24 && 
        finalMatch?.result.scores[1].teamId && finalMatch?.result.scores[1].teamId.length >= 24 ) {
        final = resetGrandFinal
      }
    }
    if (final) {
      const finalMatch = tournamentMatches?.matches?.find((match) => {
        return match.id === final.matchesId[0];
      });
      const finalTeamHomeScore = finalMatch?.result.scores[0].score || 0;
      const finalTeamAwayScore = finalMatch?.result.scores[1].score || 0;
      tournamentFirstPlace =
        finalTeamHomeScore > finalTeamAwayScore
          ? finalMatch?.result.scores[0].teamId
          : finalMatch?.result.scores[1].teamId;
      tournamentSecondPlace =
        finalTeamHomeScore > finalTeamAwayScore
          ? finalMatch?.result.scores[1].teamId
          : finalMatch?.result.scores[0].teamId;
    } 

    if (thirdPlace) {
      const thirdPlaceMatch = tournamentMatches?.matches?.find((match) => {
        return match.id === thirdPlace.matchesId[0];
      });

      const thirdPlaceTeamHomeScore =
        thirdPlaceMatch?.result.scores[0].score || 0;
      const thirdPlaceTeamAwayScore =
        thirdPlaceMatch?.result.scores[1].score || 0;

      tournamentThirdPlace =
        thirdPlaceTeamHomeScore > thirdPlaceTeamAwayScore
          ? thirdPlaceMatch?.result.scores[0].teamId
          : thirdPlaceMatch?.result.scores[1].teamId;
      tournamentFourthPlace =
        thirdPlaceTeamHomeScore > thirdPlaceTeamAwayScore
          ? thirdPlaceMatch?.result.scores[1].teamId
          : thirdPlaceMatch?.result.scores[0].teamId;
    }

    setWinners([
      tournamentFirstPlace,
      tournamentSecondPlace,
      tournamentThirdPlace,
      tournamentFourthPlace,
    ]);

  }, [tournament, tournamentMatches]);

  return (
    <div className="results-tab container">
      {(winners.length === 0 || tournament?.status !== 2) && (
        <div className="columns skp-text-light text-center">
          <div className="space-divider"></div>
          <div className="column col-12">
            <h1>
              <i className="las la-trophy"></i>
            </h1>
          </div>
          <div className="column col-12">
            <h5>{t('results.noResults')}</h5>
          </div>
          <div className="column col-12">
            {t('results.noResultsDesc')}
          </div>
        </div>
      )}

      {winners.length > 0 && tournament?.status === 2 && (
        <div className="column col-12">
          <div className="space-divider-20"></div>
          <h5 className="skp-text-primary">{t('results.winners')}</h5>
          <dl className="bordered-list">
            {/* First place */}
            <dd className="columns flex-centered">
              <div className="column col-1">
                <i
                  className="las la-trophy"
                  style={{ fontSize: 30, color: "#DAAF37" }}
                ></i>
              </div>
              <div className="column col-1 skp-text-primary">{t('results.1st')}</div>
              {teams.length > 0 ? 
                <>
                  <div className="column col-6">
                    {tournament.playerType === 1 && (
                      <MatchTeamName
                        teamId={winners[0]}
                        tournament={tournament}
                      ></MatchTeamName>
                    )}
                  </div>
                  <div className="column col-4 skp-text-primary">{t('results.score')}: {teams.find((y)=> y.id === winners[0]).score} pt</div>
                </>
                :
                <div className="column col-10">
                  {tournament.playerType === 1 && (
                    <MatchTeamName
                      teamId={winners[0]}
                      tournament={tournament}
                    ></MatchTeamName>
                  )}
                  {tournament.playerType === 0 && (
                    <Link to={`/users/${winners[0]}/overview`}>
                      <UserProfileWithDetailsModal userId={winners[0]} tournament={tournament}/>
                  </Link>
                  )}
                </div>
              }
            </dd>
            {/* Second place */}
            <dd className="columns flex-centered">
              <div className="column col-1">
                <i
                  className="las la-trophy"
                  style={{ fontSize: 30, color: "#C0C0C0" }}
                ></i>
              </div>
              <div className="column col-1 skp-text-primary">{t('results.2nd')}</div>
              {teams.length > 0 ? 
                <>
                  <div className="column col-6">
                    {tournament.playerType === 1 && (
                      <MatchTeamName
                        teamId={winners[1]}
                        tournament={tournament}
                      ></MatchTeamName>
                    )}
                  </div>
                  <div className="column col-4 skp-text-primary">{t('results.score')}: {teams.find((y)=> y.id === winners[1]).score} pt</div>
                </>
                :
                <div className="column col-10">
                  {tournament.playerType === 1 && (
                    <MatchTeamName
                      teamId={winners[1]}
                      tournament={tournament}
                    ></MatchTeamName>
                  )}
                  {tournament.playerType === 0 && (
                    <Link to={`/users/${winners[1]}/overview`}>
                      <UserProfileWithDetailsModal userId={winners[1]} tournament={tournament}/>
                    </Link>
                  )}
                </div>
              }
            </dd>
            {/* Third place */}
            {winners && winners[2] && (<>
              <dd className="columns flex-centered">
              <div className="column col-1">
                <i
                  className="las la-trophy"
                  style={{ fontSize: 30, color: "#cd7f32" }}
                ></i>
              </div>
              <div className="column col-1 skp-text-primary">{t('results.3rd')}</div>
              <div className="column col-10">
                {tournament.playerType === 1 && (
                  <MatchTeamName
                    teamId={winners[2]}
                    tournament={tournament}
                  ></MatchTeamName>
                )}
                {tournament.playerType === 0 && (
                  <Link to={`/users/${winners[2]}/overview`}>
                  <UserProfileWithDetailsModal userId={winners[2]} tournament={tournament}/>
                </Link>
                )}
              </div>
            </dd>
            </>)}
            
            {/* Fourth place */}
            {winners && winners[3] && (
              <dd className="columns flex-centered">
              <div className="column col-1"></div>
              <div className="column col-1 skp-text-primary">{t('results.4th')}</div>
              <div className="column col-10">
                {tournament.playerType === 1 && (
                  <MatchTeamName
                    teamId={winners[3]}
                    tournament={tournament}
                  ></MatchTeamName>
                )}
                {tournament.playerType === 0 && (
                  <Link to={`/users/${winners[3]}/overview`}>
                  <UserProfileWithDetailsModal userId={winners[3]} tournament={tournament}/>
                </Link>
                )}
              </div>
            </dd>
            )}
            
          </dl>
        </div>
      )}
    </div>
  );
};

export default ResultsTab;
