import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import MatchTeamName from "../MatchComponents/MatchTeamName";
import { useTranslation } from "react-i18next";

const LobbyTeamsTypeahead = (props) => {
  const { lobby, toHide, hideEmptySelection, hideTeam } = props;
  const { t } = useTranslation()
  const [teams, setTeams] = useState(lobby?.teams);
  const [searchText] = useState();
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setLoading(true);
    setTimeout(() => {
      const teamsCopy = [...lobby?.teams];
      if (e.target.value === "") {
        setTeams(lobby?.teams);
        setLoading(false)
        return;
      }
      setTeams(
        teamsCopy.filter((team) =>
          team.partyName.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setLoading(false)
    },200);
  };

  useEffect(() => {
    if (hideTeam && toHide) {
      const theTeams = teams.filter((el)=>toHide.some((t)=>{
        return t !== undefined ? t.teamName === el.partyName : false
      }))
      setTeams(teams.filter((el)=>!theTeams.includes(el)))
    }
  },[])

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <div className="skp-form-control">
            <DebounceInput
              value={searchText}
              type="text"
              minLength={1}
              debounceTimeout={500}
              placeholder={t('teams.searchTeamName')}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>

        <div className="column col-12">
          <h1 className="skp-text-label skp-text-light">
            {t('teams.typeToSearchTeam')}
          </h1>
          {loading && <div className="loading"></div>}
          <dl>
            {!hideEmptySelection && <dd className="skp-link-primary" onClick={() => props.onSelect(undefined)}>{t('teams.setEmptyTeam')}</dd>}
          {!loading &&
            teams?.filter((el, index) => index < 4).map((team,ind) => (
              
                <dd onClick={() => props.onSelect(team)} key={ind}>
                  <MatchTeamName
                    key={ind}
                    tournament={lobby}
                    teamId={team.partyId}
                    hideTeamLabel={true}
                    hideNameClick
                  />
                </dd>
              
            ))}
            </dl>
          <div className="space-divider-20"></div>
        </div>
      </div>
    </div>
  );
};

export default LobbyTeamsTypeahead;
