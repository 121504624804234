import React, { useEffect, useState} from 'react'
import YuniteTournamentService from '../../../../services/YuniteTournamentService';
import Modal from '../../../UiLibrary/Modal';
import YuniteMatchLeaderboard from './YuniteMatchLeaderboard';

const YuniteMatches = (props) => {
  const [showLeaderboardModal, setShowLeaderboardModal] = useState({show: false, sessionId: undefined});
    const { tournament } = props;
    const [matches, setMatches] = useState([])
    const [loading, setLoading] =  useState(false);
    useEffect(() => {
      setLoading(true);
      const id = tournament?.yuniteId ? tournament?.yuniteId : tournament?.id;
      YuniteTournamentService.getCacheMatches(id).then((response) => {
        if (response) {
          if (Object.keys(response).length === 0) {
            setMatches([]);
          } else {
            setMatches(response);
          }
          
          setLoading(false)
        }
      }).catch(error => {
        setMatches([]);
        setLoading(false)
      })

      return(() => {
        setMatches();
      })
    }, [tournament]);
    return (
      <div className="container">
        {!loading && matches?.length === 0  && (
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-chess"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>No matches</h5>
            </div>
            <div className="column col-12">
              There are no matches available yet.
            </div>
          </div>
        )}
        {!loading && matches?.length > 0 && (
          <div className="columns">
            <div className="column col-12">
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">Match List</h5>
              <div className="space-divider-20"></div>
            </div>
            {matches.map((match,index) => (
              <div key={index} className="column col-4 col-xs-12 text-center-xs">
                <div className="card skp-card skp-card-hover">
                  <div className="card-header">
                    <div className="card-title skp-text-default skp-text-primary">
                      {match.status}
                    </div>
                    <div className="card-subtitle skp-text-light skp-text-label">
                      {new Date(match.timestamp).toLocaleString()}
                    </div>
                  </div>
                  <div className="card-body skp-text-default skp-text-primary">
                    Players: {match.players}
                  </div>
                  <div className="card-footer">
                    <button
                      onClick={() =>
                        setShowLeaderboardModal({
                          show: true,
                          sessionId: match.sessionId,
                        })
                      }
                      className="skp-btn skp-btn-secondary skp-text-small"
                    >
                      Leaderboard
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <Modal
          show={showLeaderboardModal.show}
          title="Match Leaderboard"
          onClose={() => setShowLeaderboardModal(false)}
        >
          <YuniteMatchLeaderboard
            tournament={tournament}
            matchId={showLeaderboardModal.sessionId}
          ></YuniteMatchLeaderboard>
        </Modal>
      </div>
    );
}

export default YuniteMatches
