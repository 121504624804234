import React, { useState } from 'react'
import Modal from '../Modal';
import UploadImageBox from '../UploadImageBox/UploadImageBox';
import IFileBase64Image from '../../Interfaces/FileBase64Image'

type Props = {
  loading: boolean
  type: string
  onUpdateImage: (image: IFileBase64Image) => void
  children: JSX.Element
}

const UploadButton = (props:Props) => {
  const { loading, type } = props;
  const [showUploadModal, setShowUploadModal] = useState({show: false});
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
    
    const handleClick = () => {
        setShowUploadModal({ show: true });
    }

    const updateImage = () => {
      if(!imageToUpload) {
        return
      }
      props.onUpdateImage(imageToUpload);
      setShowUploadModal({ show: false });
    };

    const uploadImage = (image:IFileBase64Image) => {
      setImageToUpload(image);
    };

    return (
      <>
        <div>
          {React.cloneElement(props.children, { onClick: handleClick })}
        </div>

        <Modal
          show={showUploadModal.show}
          title={`Upload ${type}`}
          size="large"
          onClose={() => {
            setShowUploadModal({ show: false });
          }}
        >
          <div className="container">
            <div className="columns">
              <div className="space-divider-20"></div>
              {type === "banner" && <div
                className="column col-12"
                style={{ height: 250, border: "2px dashed var(--gray-1)" }}
              >
                 <UploadImageBox onUpload={uploadImage} onRemovePreview={() => setImageToUpload(undefined)} />
              </div>}

              {type === "logo" && (
                <div className="column col-12 text-center">
                  <div className="upload-logo-wrapper">
                    <UploadImageBox onUpload={uploadImage} onRemovePreview={() => setImageToUpload(undefined)}  />
                  </div>
                </div>
              )}

              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage()}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Upload"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
}

export default UploadButton
