import React from "react";
import YunitePlayer from "./YunitePlayer";

function YuniteTeam(props) {
  const { team, index, tournament } = props;

  return (
    <>
      {team?.players.length > 1 && (
        <div
          className="skp-text-light mb-2 skp-card"
          style={{
            border: team?.players.length > 1 ? "1px solid var(--gray-1)" : null,
            borderRadius: 8,
          }}
        >
          <div className="mt-2 ml-2 p-2">Team {index + 1}</div>
          <div>
            <div className="columns m-2">
              {team?.players.map((player, index) => (
                <div
                  key={`player-${player.epicId}${index}`}
                  className={`column col-${12 / team?.players?.length}`}
                >
                  <YunitePlayer player={player} tournament={tournament} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {team?.players.length === 1 && (
        <dl>
          {team?.players.map((player, index) => (
            <dd key={`player-${player.epicId}${index}`}>
              <YunitePlayer player={player} tournament={tournament} />
            </dd>
          ))}
        </dl>
      )}
    </>
  );
}

export default YuniteTeam;
