import { Formik, Form, Field, FormikProps } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";

interface FormModel {
  teamKill: number | null;
  teamName: string;
}

const AdminLeaderboardRow = (props:any) => {
  const { team } = props;

  let initialValues: FormModel = {
    teamKill: 0,
    teamName: team.teamName
  };

  const validationField = (field: string) => {
    return Yup.number()
      .required()
      .notOneOf([Yup.ref(field)]);
  };

  const validationSchema = Yup.object({
    teamKill: validationField("teamKill"),
  });

  const formikRef = useRef<FormikProps<FormModel>>(null);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "teamKill",
        Number(team.kills)
          ? Number(team.kills)
          : 0
      );
    }
  }, [team]);

  const onSubmit = (values: FormModel) => {
    if (formikRef.current?.values.teamKill)
      props.reportKill(formikRef.current?.values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <div className="columns col-12 ml-1">
          <div className="column col-10 text-left">
            {team.teamName}
          </div>
          <div className="column col-2">
            <Form>
              <div className="skp-row-form-control">
                <Field
                  name="teamKill"
                  id="teamKill"
                  onKeyUp={onSubmit}
                />
              </div>
            </Form>
          </div>
        </div>
      </Formik>
    </>
  );
};

export default AdminLeaderboardRow;
