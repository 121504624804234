import { useState } from "react";
import { toast } from "react-toastify";
import TournamentService from "../../../services/TournamentService";
import ITournament from "../../Interfaces/Tournament";
import IUserDetails from "../../Interfaces/UserDetails";
import JoinTournamentModal from "../../Modals/JoinTournamentModal/JoinTournamentModal";
import Modal from "../Modal";
import UserSearchDropdown from "../User/UserSearchDropdown";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined;
  onReloadTournament: (tournament?: ITournament) => void;
};

const AddPlayerButton = (props: Props) => {
  const { tournament } = props;
  const { t } = useTranslation()
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState<{
    show: boolean;
    user?: IUserDetails;
  }>({ show: false });

  const [loading, setLoading] = useState(false);

  const inviteUser = (user: IUserDetails) => {
    
    setShowJoinModal({ show: true, user: user });
  };

  const subscribeTournamentAsSingle = (data: any) => {
    
    if(!tournament || !showJoinModal?.user?.id) {
        return;
    }
    const isAdmin = "true"
    setLoading(true)
    TournamentService.subscribeTournament(tournament.id, [showJoinModal.user.id], data, isAdmin).then(response => {
        setLoading(false);
        props.onReloadTournament();
        setShowJoinModal({show: false});
    }).catch(error => {
        setLoading(false);
        toast.error(error.response.data.message);
        setShowJoinModal({show: false});
    })
  };

  return (
    <>
      <h6
        className="skp-link-hover m-2"
        onClick={() => setShowAddPlayerModal(true)}
      >
        <i className="las la-plus-circle"></i> {t('players.addPlayer')}
      </h6>

      <Modal
        size="small"
        title={t('players.addPlayer')}
        show={showAddPlayerModal}
        onClose={() => setShowAddPlayerModal(false)}
      >
        <UserSearchDropdown setUser={inviteUser} />
      </Modal>

      <Modal
        title={t('tournament.join.joinTournament')}
        show={showJoinModal.show}
        onClose={() => setShowJoinModal({ show: false })}
      >
        <>
          {tournament?.playerType === 0 && (
            <JoinTournamentModal
              onConfirm={subscribeTournamentAsSingle}
              loading={loading}
              tournament={tournament}
              selectUser={showJoinModal.user}
            />
          )}
        </>
      </Modal>
    </>
  );
};

export default AddPlayerButton;
