import constant from "../constants";
import HttpService from "./HttpService";

const _axios = HttpService.getAxiosClient();

const getOrderByUserId = (userId: string) => {
  const url = `${constant.API_URL}/shop/tournamentOrders/users/${userId}`;

  return _axios({
    method: "get",
    url: url,
  }).then((response) => {
    return response;
  });
};

const createOrder = (tournamentId: string, orderId:string, userId:string) => {
  const url = `${constant.API_URL}/shop/tournamentOrders/tournaments/${tournamentId}`;

  return _axios({
    method: "post",
    url: url,
    data: {
      tournamentOrder: {
        orderId: orderId,
        tournamentId: tournamentId,
        userId: userId,
      },
    },
  }).then((response) => {
    return response;
  });
};

const ShopService = {
  getOrderByUserId,
  createOrder
};

export default ShopService;
