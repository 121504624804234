import { useState, useEffect, useContext } from "react";
import { TournamentMatchesContext } from "../Context/TournamentMatchesContext";

const useMatchStatus = (matchId:string) => {
  const [status, setStatus] = useState<number>();
  const {tournamentMatches} = useContext(TournamentMatchesContext)
  useEffect(() => {
    const foundMatch = tournamentMatches?.matches?.find(match => {
      return match.id === matchId
    })

    if(foundMatch) {
      setStatus(foundMatch.status)
    }
    
  }, [matchId, tournamentMatches]);
  return status;
};

export default useMatchStatus;
