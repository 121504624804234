import Moment from "moment";
import { useEffect, useState } from "react";
import ApexTournamentService from "../../../../../../services/ApexTournamentService";
import IApexLobbyGameToken from "../../../../../Interfaces/ApexLobbyGameToken";
import ITournament from "../../../../../Interfaces/Tournament";
import ITournamentApexLobby from "../../../../../Interfaces/TournamentApexLobby";
import ITournamentApexRespawnLeaderboard from "../../../../../Interfaces/TournamentApexRespawnLeaderboard";
import ITournamentApexRespawnMatch from "../../../../../Interfaces/TournamentApexRespawnMatch";
import Modal from "../../../../../UiLibrary/Modal";
import ProfileApex from "../../../../../UiLibrary/ProfileApex";

type Props = {
  matches?: Array<ITournamentApexRespawnMatch>
  tournament: ITournament
  game?: IApexLobbyGameToken
  lobby: ITournamentApexLobby
  onShowLeaderBoard: (match: ITournamentApexRespawnMatch) => void
}

const ApexGameDetailModal = (props:Props) => {
  const { matches, tournament, game, lobby } = props;
  const [leaderboard, setLeaderboard] = useState<Array<ITournamentApexRespawnLeaderboard>>([]);
  const [teams, setTeams] = useState<Array<any>>([]);
  const [leaderboardError, setShowLeaderboardError] = useState();
  const [loading, setLoading] = useState(false);
  const [tabsController, setTabController] = useState({ tab: "teams" });
  const [showTeamModal, setShowTeamModal] = useState<{team?: any | undefined, show: boolean}>({
    team: undefined,
    show: false,
  });

  useEffect(() => {
    if(!game?.api) {
      return
    }
    setLoading(true);

    const leaderboard = lobby?.leaderboards?.find((element) =>
      Object.keys(element).includes(game.api!)
    );

    if (leaderboard && leaderboard[game.api]) {
      setLoading(false);
      setLeaderboard(leaderboard[game.api]);
    } else {
      ApexTournamentService.getMatchLeaderboard(tournament?.id, game.api, lobby.id, game.gameNumber )
        .then((RmatchLeaderboards) => {
          setLoading(false);
          setLeaderboard(RmatchLeaderboards);
        })
        .catch((error) => {
          setLoading(false);
          setLeaderboard([]);
          setShowLeaderboardError(error.response.data.message);
        });
    }

    ApexTournamentService.getMatchTeams(tournament?.id, game.api, lobby.id, game.gameNumber)
      .then((Rteams) => {
        setTeams(Rteams);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [tournament, game, lobby]);

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <ul className="tab skp-text-light">
            <li
              className={`tab-item ${
                tabsController.tab === "teams" && "active"
              }`}
            >
              <a onClick={() => setTabController({ tab: "teams" })}>Teams</a>
            </li>
            <li
              className={`tab-item ${
                tabsController.tab === "matches" && "active"
              }`}
            >
              <a onClick={() => setTabController({ tab: "matches" })}>
                Matches
              </a>
            </li>
            <li
              className={`tab-item ${
                tabsController.tab === "leaderboard" && "active"
              }`}
            >
              <a onClick={() => setTabController({ tab: "leaderboard" })}>
                Leaderboard
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="space-divider-20"></div>

      {tabsController.tab === "matches" && (
        <>
          {/* Matches */}
          <div className="columns">
            <div className="column col-12 col-xs-12">
              <h5 className="skp-text-primary">Matches</h5>
              <div className="columns">
                {matches?.length === 0 && (
                  <div className="column col-12 mt-2">
                    <h6 className="skp-text-light">
                      No matches at the moment!
                    </h6>
                    <div className="space-divider"></div>
                  </div>
                )}
                {matches?.map((match, index) => (
                  <div
                    className="column col-12"
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => props.onShowLeaderBoard(match)}
                  >
                    <div className="card skp-card skp-card-hover">
                      <div className="card-footer">
                        <div className="columns">
                          <div className="column col-12">
                            <h5 className="skp-text-primary">
                              <i className="las la-calendar"></i>{" "}
                              <span className=" skp-text-large">
                                {Moment(match.match_start * 1000).format(
                                  "MMM DD, hh:mm A"
                                )}{" "}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      {tabsController.tab === "leaderboard" && (
        <>
          {/* Leaderboard */}
          <div className="columns">
            <div className="column col-12 col-xs-12 skp-text-light">
              <h5 className="skp-text-primary">Leaderboard</h5>
              {loading && (
                <>
                  <div className="column col-12 mt-2 mb-2">
                    <div className="loading"></div>
                  </div>
                </>
              )}
              {leaderboardError && !loading && (
                <>
                  <div className="columns">
                    <div className="column col-12 mt-2 mb-2">
                      <h6 className="skp-text-light">{leaderboardError}</h6>
                      <div className="space-divider-20"></div>
                    </div>
                  </div>
                </>
              )}

              {!leaderboardError && !loading && (
                <>
                  <div className="columns m-2">
                    <div className="column col-1 col-xs-2 mb-2">#</div>
                    <div className="column col-2 mb-2">Team n.</div>
                    <div className="column col-4 col-xs-6 mb-2">Team name</div>
                    <div
                      className="column col-2 mb-2 tooltip text-right"
                      data-tooltip="Score"
                    >
                      Score
                    </div>
                    <div
                      className="column col-1 mb-2 tooltip text-right hide-xs"
                      data-tooltip="Wins"
                    >
                      W
                    </div>
                    <div
                      className="column col-1 mb-2 tooltip text-right hide-xs"
                      data-tooltip="Kills"
                    >
                      K
                    </div>
                    <div
                      className="column col-1 mb-2 tooltip text-right hide-xs"
                      data-tooltip="Damage"
                    >
                      D
                    </div>
                  </div>
                  <div className="columns skp-text-primary m-2">
                    {leaderboard &&
                      leaderboard
                        .sort((a, b) => a.placement - b.placement)
                        .map((team) => (
                          <>
                            <div className="column col-1 col-xs-2 mb-2">
                              {team.placement}
                            </div>
                            <div className="column col-2 mb-2">
                              {Number(team.teamNum - 1)}
                            </div>
                            <div className="column col-4 col-xs-6 mb-2">
                              {team.teamName}
                            </div>
                            <div className="column col-2 mb-2 text-right">
                              {team.placementScore + team.eliminationScore}
                            </div>
                            <div className="column col-1 mb-2 text-right hide-xs">
                              {team.wins}
                            </div>
                            <div className="column col-1 mb-2 text-right hide-xs">
                              {team.kills}
                            </div>
                            <div className="column col-1 mb-2 text-right hide-xs">
                              {team.damageDealt}
                            </div>
                          </>
                        ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {tabsController.tab === "teams" && (
        <>
          {/* Teams */}
          <div className="columns">
            <div className="column col-8 col-xs-12">
              <h5 className="skp-text-primary">Teams</h5>
              {loading && (
                <>
                  <div className="column col-12 mt-2 mb-2">
                    <div className="loading"></div>
                  </div>
                </>
              )}
              {!loading && (
                <dl>
                  <dd className="columns skp-text-light">
                    <div className="column col-4">Team Number</div>
                    <div className="column col-8">Team Name</div>
                  </dd>
                  {teams
                    ?.sort((a, b) => a.teamNum - b.teamNum)
                    .map((team) => (
                      <dd
                        className="columns skp-text-primary"
                        onClick={() =>
                          setShowTeamModal({ team: team, show: true })
                        }
                      >
                        <div className="column col-4">{Number(team.teamNum - 1)}</div>
                        <div className="column col-8">
                          <span className="skp-link-hover">
                            {team.teamName}
                          </span>
                        </div>
                      </dd>
                    ))}
                </dl>
              )}
            </div>
          </div>

          <Modal
            title={showTeamModal?.team?.teamName}
            show={showTeamModal.show}
            onClose={() => setShowTeamModal({ show: false })}
            size="small"
          >
            <div className="container">
              <div className="columns">
                <div className="column col-12">
                  <dl>
                    {showTeamModal.team?.players?.map((player:any) => (
                      <dd>
                        <ProfileApex
                          apexUsername={player}
                          tournament={tournament}
                        />
                      </dd>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ApexGameDetailModal;
