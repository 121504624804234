import GameTile from "../../UiLibrary/GameTile";
import FormikControl from "../../Formik/FormikControl";
import GAMES from "../../../constants-games";
import GameAccount from "../../Interfaces/GameAccount";
import SocialNetwork from "../../Interfaces/SocialNetwork";
import { useTranslation } from "react-i18next";

type FormModel = {
  username?: string;
  email?: string;
  biography?: string;
  discordId?: string;
  epicId?: string;
  gameAccounts?: Array<GameAccount>;
  social_networks?: Array<SocialNetwork>;
};

type Props = {
  gameAccounts: Array<GameAccount>;
  values: FormModel;
  removeGameAccount: (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => void;
};

const GameAccountsFormFields = ({ gameAccounts, values, ...props }: Props) => {
  const { t } = useTranslation()

  const gameOptions = [
    { key: t('common.selectValue'), value: "" },
    { key: "Fortnite", value: GAMES.FORTNITE.ENUM },
    { key: "Fifa22", value: GAMES.FIFA22.ENUM },
    { key: "EAFC24", value: GAMES.EAFC24.ENUM },
    { key: "Pokemon Unite", value: GAMES.POKEMON_UNITE.ENUM },
    { key: "Apex Legends", value: GAMES.APEX_LEGENDS.ENUM },
    { key: "Clash of Clans", value: GAMES.CLASH_OF_CLANS.ENUM },
    { key: "Valorant", value: GAMES.VALORANT.ENUM },
    { key: "Yu-Gi-Oh! Master Duel", value: GAMES.YUGIOH_MASTER_DUEL.ENUM },
    { key: "Clash Royale", value: GAMES.CLASH_ROYALE.ENUM },
    { key: "Clash Royale Bracket", value: GAMES.CLASH_ROYALE_WITH_BRACKET.ENUM },
    { key: "League of legends", value: GAMES.LEAGUE_OF_LEGENDS.ENUM },
    { key: "Marvel Snap", value: GAMES.MARVEL_SNAP.ENUM },
    { key: "PUBG MOBILE", value: GAMES.PUBG_MOBILE.ENUM },
  ];
  
  const consoleOptions = [
    { key: t('common.selectValue'), value: "" },
    { key: "PC", value: "PC" },
    { key: "Xbox One", value: "XBOX_ONE" },
    { key: "Xbox Series X", value: "XBOX_SERIES_X" },
    { key: "PS4", value: "PS4" },
    { key: "PS5", value: "PS5" },
    { key: "Switch", value: "SWITCH" },
    { key: "Mobile", value: "MOBILE" },
  ];

  return (
    <>
      {gameAccounts?.map((account, index) => (
        <div
          className="columns"
          key={`gameaccount-${index}`}
          style={{ height: 102 }}
        >
          <div className="column col-1 flex-centered">
            <div style={{ width: 40 }}>
              {values?.gameAccounts &&
                values?.gameAccounts[index]?.game !== undefined && (
                  <GameTile
                    game={GAMES[values?.gameAccounts[index].game as any]}
                    disableLink
                    hideName
                  />
                )}
            </div>
          </div>
          <div className="column col-3">
            <div className="skp-form-control">
              <FormikControl
                control="input"
                label={t('user.config.username')}
                name={`gameAccounts[${index}].username`}
              ></FormikControl>
            </div>
          </div>
          <div className="column col-3">
            <div className="skp-form-control">
              <FormikControl
                control="input"
                label={t('user.config.id')}
                name={`gameAccounts[${index}].id`}
              ></FormikControl>
            </div>
          </div>
          <div className="column col-2">
            <div className="skp-form-control">
              <FormikControl
                control="select"
                label={t('common.game')}
                name={`gameAccounts[${index}].game`}
                options={gameOptions}
              ></FormikControl>
            </div>
          </div>
          <div className="column col-2">
            <div className="skp-form-control">
              <FormikControl
                control="select"
                label={t('tournament.config.platforms')}
                name={`gameAccounts[${index}].console`}
                options={consoleOptions}
              ></FormikControl>
            </div>
          </div>

          <div className="column col-1 flex-centered">
            <button
              className="skp-btn skp-btn-transparent skp-text-xlarge"
              onClick={(event) => props.removeGameAccount(event, index)}
            >
              <i className="las la-trash"></i>
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default GameAccountsFormFields;
