import { useState, useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserService from "../../../../services/UserService";
import ErrorLabel from "../../../Forms/ErrorLabel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  oldPassword: Yup.string().required("Current password is required").nullable(),
  newPassword: Yup.string()
    .required("Please enter your new password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .nullable(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), ''], "Passwords must match")
    .required("Please confirm your new password")
    .nullable(),
});

interface FormModel {
  oldPassword: string
  newPassword: string
  confirmPassword: string 
}

const PasswordForm = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const initialValues: FormModel = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = (values: FormModel) => {
    const userId = user?.id;
    if (!userId) {
      return;
    }
    setLoading(true);
    UserService.resetUserPassword(values, userId)
      .then((response) => {
        setLoading(false);
        toast.success("User Password changed");
      })
      .catch((error) => {
        toast.error("Change password failed");
        setLoading(false);
      });
  };

  return (
    <div className="column col-12">
      <div className="space-divider-20"></div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            {/* Current Password */}
            <div
              className={
                errors.oldPassword && touched.oldPassword
                  ? "skp-form-control skp-form-control-error"
                  : "skp-form-control"
              }
            >
              <label
                className="skp-text-light skp-text-input-label"
                htmlFor="oldPassword"
              >
                {t('user.config.currentPassword')}
              </label>
              <Field type="password" id="oldPassword" name="oldPassword" />
              <ErrorMessage name="oldPassword" component={ErrorLabel} />
            </div>
            {/* New Password */}
            <div
              className={
                errors.newPassword && touched.newPassword
                  ? "skp-form-control skp-form-control-error"
                  : "skp-form-control"
              }
            >
              <label
                className="skp-text-light skp-text-input-label"
                htmlFor="newPassword"
              >
                {t('user.config.newPassword')}
              </label>
              <Field type="password" id="newPassword" name="newPassword" />
              <ErrorMessage name="newPassword" component={ErrorLabel} />
            </div>
            {/* New Password */}
            <div
              className={
                errors.confirmPassword && touched.confirmPassword
                  ? "skp-form-control skp-form-control-error"
                  : "skp-form-control"
              }
            >
              <label
                className="skp-text-light skp-text-input-label"
                htmlFor="confirmPassword"
              >
                {t('user.config.confirmNewPassword')}
              </label>
              <Field
                type="password"
                id="confirmPassword"
                name="confirmPassword"
              />
              <ErrorMessage name="confirmPassword" component={ErrorLabel} />
            </div>
            <div className="space-divider"></div>
            <button
              type="submit"
              disabled={!isValid || loading}
              className="skp-btn skp-btn-primary"
            >
              {loading ? <span className="loading"></span> : t('user.config.changePassword')}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordForm;
