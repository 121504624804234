
import { createContext, useContext } from "react";
import IUserDetails from "../Interfaces/UserDetails";

export type UserContent = {
  user: IUserDetails | null;
  setUser: (user: IUserDetails | null) => void;
};
export const UserContext = createContext<UserContent>({
  user: null, // set a default value
  setUser: () => {},
});
export const useGlobalContext = () => useContext(UserContext);
