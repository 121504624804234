import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import TournamentService from "../../../../services/TournamentService";
import ICompetition from "../../../Interfaces/Competition";
import CompetitionTile from "../../../UiLibrary/CompetitionTile";
import { UserContext } from "../../../Context/UserContext";


const CompetitionsTab = ({brandId}) => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [competitions, setCompetitionList] = useState<Array<ICompetition>>([])

  useEffect(() => {
    let queryParam = qs.parse(location.search);
    queryParam.owner = brandId;

    TournamentService.getCompetitions(queryParam).then((Rcompetitions) => {
        setCompetitionList(Rcompetitions);
    });

  }, []);

  return (
    <div className="players-tab container tab-with-table">
        <div className="space-divider-20"></div>
        <div className="columns">
            {/* Tiles */}
            {competitions.length > 0 && competitions.map((competition) => (
                <div className="column col-3 col-xs-12" key={competition.id}>
                    <CompetitionTile competition={competition}></CompetitionTile>
                </div>
            ))}
            {competitions.length === 0 && (
                <div className="column col-12">
                    <h6 className="skp-text-primary">{t('competition.noCompetition')}</h6>
                </div>
            )}
        </div>
        <div className="space-divider"></div>
    </div>
    );
};

export default CompetitionsTab;
