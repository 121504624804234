import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TournamentService from "../../../services/TournamentService";
import TournamentTile from "../../UiLibrary/TournamentTile";
import { Scrollbars } from "react-custom-scrollbars";
import qs from "query-string";
import TournamentFilters from "./TournamentFilters/TournamentFilters";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITournament from "../../Interfaces/Tournament";
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";

export type Queries = {
  sort?: {
    order: 'asc' | 'desc' | ''
    key: string | number
  }
}

const Tournaments = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  const [tournaments, setTournamentList] = useState<Array<ITournament>>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pathName = `/tournaments`;

  useEffect(() => {
    setLoading(true);
    let queryParam = qs.parse(location.search);
    queryParam = {...queryParam, "sort[order]":"asc", "sort[key]":"start_tournament" }
    const isTester = user && user?.roles && user.roles.includes("Tester") ? true : false

    TournamentService.getTournaments(queryParam, isTester, user?.id).then((Rtournaments) => {
      setTournamentList(Rtournaments);
      setLoading(false);
    });
  }, [location]);

  const runQuery = (params: any) => {
    navigate({
      pathname: pathName,
      search: qs.stringify(params),
    });
  };

  return (
    <>
      <div className="skp-full-page">
        <div className="container">
          <div className="columns">
            <div className="column col-12 text-center">
              <h4 className="skp-primary-blue skp-heading ">{t('common.tournaments')}</h4>
            </div>
          </div>

          {/* <RenderOnAuthenticated> */}
          {/* Tournament Filters */}
          <div className="space-divider-20"></div>
          <div className="columns">
            <div className="column col-12 skp-primary-pink text-uppercase skp-text-xlarge">
              <strong>{t('common.filters')}</strong>
            </div>
          </div>

          <TournamentFilters onRunQuery={runQuery} />
          <div className="space-divider-20"></div>
          {loading && (
            <div className="columns columns-slider">
              <LoadingPlaceholders numberOfItems={8}>
                <div className="column col-3 col-xs-9 column-slider">
                  <div className={`tournament-tile tournament-tile-loading`}>
                    <div className={`tournament-tile-image`}>
                      <div className={`default-tile`}></div>
                    </div>

                    <div className={`tournament-tile-content columns`}>
                      <div className="column col-12">&nbsp;</div>
                      <div className="column col-12">&nbsp;</div>
                      <div className="column col-12">&nbsp;</div>
                      <div className="column col-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </LoadingPlaceholders>
            </div>
          )}
          {!loading && (
            <Scrollbars style={{width: "100%", height: "calc(100vh - 320px)"}} renderView={(props:any) => <div {...props} className="viewOverflowXHidden"/>}>
              <div className="columns">
                {/* Tiles */}
                {tournaments.length > 0 &&
                  tournaments
                    .slice(0)
                    .reverse()
                    .map((tournament: ITournament) => (
                      <div
                        className="column col-3 col-xs-12"
                        key={tournament?.id}
                      >
                        <TournamentTile
                          tournament={tournament}
                        ></TournamentTile>
                      </div>
                    ))}
                {tournaments.length === 0 && (
                  <div className="column col-12">
                    <h6 className="skp-text-primary">{t('home.noTournaments')}</h6>
                  </div>
                )}
              </div>
            </Scrollbars>
          )}
          {/* </RenderOnAuthenticated> */}
        </div>
      </div>
    </>
  );
};

export default Tournaments;
