import "./animated-loader.scss";

const AnimatedLoader = () => {
  return (
    <div className="animated-loader">
      <p>l</p>
      <p>o</p>
      <p>a</p>
      <p>d</p>
      <p>i</p>
      <p>n</p>
      <p>g</p>
    </div>
  );
};

export default AnimatedLoader;
