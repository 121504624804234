import React, { useState } from "react";
import { Link } from "react-router-dom";
import OverviewTab from "../TournamentTabs/OverviewTab";
import PlayerTab from "../TournamentTabs/PlayersTab";
import Modal from "../../../UiLibrary/Modal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import AnnouncementsTab from "../TournamentSettingsTabs/AnnouncementsTab";
import LeaderBoardTabs from "../TournamentTabs/ClashRoyaleTabs/LeaderboardTabs";

type Props = {
  tournament: ITournament | undefined;
  userStatus: ITournamentMemberStatus;
  isSettingsPage: boolean;
  urlParams: any;
  loadTournament: (tournament: ITournament | undefined) => void;
};

const tabs = [
  { name: "Overview", url: "overview" },
  { name: "Players", url: "players" },
  { name: "Leaderboard", url: "leaderboard" },
  { name: "Announcements", url: "announcements" },
];

const TournamentWithClashRoyale = (props: Props) => {
  const { tournament, urlParams, userStatus } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <>
      {/* TABS HEADER */}
      <div className="container tabs-header">
        <div className="columns">
          <div className="column col-12">
            <ul className="tab skp-text-light">
              {tabs
                .filter((tab) =>
                  tournament?.announcements?.length === 0
                    ? tab.url !== "announcements"
                    : tab
                )
                .map((tab) => (
                  <li
                    key={tab.name}
                    className={
                      urlParams.tab === tab.url ? "tab-item active" : "tab-item"
                    }
                  >
                    <Link
                      to={`/tournaments/${tournament?.id}/${tab.url}`}
                      className={`${
                        urlParams.tab === tab.url ? "active" : null
                      } ${tab.url === "announcements" && "badge"}`}
                      data-badge={tournament?.announcements?.length}
                    >
                      {tab.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      {/* END TABS HEADER */}
      {/* TABS BODY */}
      <div className="container tabs-body">
        <div className="columns">
          {urlParams.tab === tabs[0].url && (
            <OverviewTab
              tournament={tournament}
              loadTournament={props.loadTournament}
              keyValue={"description"}
              title="Information"
            ></OverviewTab>
          )}
          {urlParams.tab === tabs[1].url && (
            <PlayerTab
              tournament={tournament}
              onLogin={() => setShowLoginModal(true)}
              onReloadTournament={props.loadTournament}
            />
          )}
          {urlParams.tab === tabs[2].url && (
            <LeaderBoardTabs
              tournament={tournament}
              onReloadTournament={props.loadTournament}
            ></LeaderBoardTabs>
          )}
          {urlParams.tab === tabs[3].url && (
            <AnnouncementsTab
              tournament={tournament}
              disableTextEditor={true}
              onReloadTournament={props.loadTournament}
            ></AnnouncementsTab>
          )}
        </div>
      </div>
      {/* END TABS BODY */}

      <Modal
        show={showLoginModal}
        size="small"
        onClose={() => setShowLoginModal(false)}
      >
        <LoginOrRegisterModal
          message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
          onClose={() => setShowLoginModal(false)}
        />
      </Modal>
    </>
  );
};

export default TournamentWithClashRoyale;
