import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ImageService from "../../services/ImageService";
import TournamentService from "../../services/TournamentService";
import IFileBase64Image from "../Interfaces/FileBase64Image";
import ITournament from "../Interfaces/Tournament";
import Modal from "./Modal";
import UploadImageBox from "./UploadImageBox/UploadImageBox";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined;
  updateTournament: (
    data: any,
    imageFile?: any,
    type?: string | undefined
  ) => void;
};

const TournamentImageBannerUpload = (props: Props) => {
  const { tournament } = props;
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
  const [showUploadModal, setShowUploadModal] = useState<{
    show: boolean;
    type?: string | undefined;
  }>({
    show: false,
    type: undefined,
  });

  const uploadImage = (image: any) => {
    setImageToUpload(image);
  };

  const updateBannerImage = (type: string) => {
    if (!imageToUpload) {
      return;
    }
    updateImage(type);
  };

  const updateImage = (type: string) => {
    if (!tournament || !imageToUpload) {
      return;
    }
    setLoading(true);
    ImageService.uploadImage(imageToUpload).then((response) => {
      let data = {} as any;
      data[type] = ImageService.generatePublicUrl(response.data);
      TournamentService.updateTournament(data, tournament?.id)
        .then((tournament) => {
          toast.success(t('toast.imageUploadSuccess'));
          props.updateTournament(tournament);
          setLoading(false);
          setShowUploadModal({ show: false });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
          setShowUploadModal({ show: false });
        });
    });
  };

  useEffect(() => {
    return () => {
      setShowUploadModal({ show: false });
    };
  }, []);

  return (
    <>
      <button
        onClick={() => setShowUploadModal({ show: true, type: "banner" })}
        className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default"
      >
        <i className="las la-camera"></i> {t('component.uploadBanner')}
      </button>

      <Modal
        show={showUploadModal.show}
        title={t('titles.uploadImage')}
        size="large"
        onClose={() => {
          setShowUploadModal({ show: false, type: undefined });
        }}
      >
        <div className="container">
          {showUploadModal.type === "banner" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div
                className="column col-12"
                style={{
                  height: 250,
                  border: "2px dashed var(--gray-1)",
                }}
              >
                <UploadImageBox onUpload={uploadImage} onRemovePreview={() => setImageToUpload(undefined)}/>
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() =>
                    updateBannerImage(showUploadModal.type || "banner")
                  }
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : t('common.upload')}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default TournamentImageBannerUpload;
