import Scrollbars from "react-custom-scrollbars";
import Footer from "../../UiLibrary/Footer";
const TermsConditions = () => {
  return (
    <Scrollbars renderView={(props) => <div {...props} id="scrollableDiv" />}>
      <div className="skp-full-page">
        <div className="containers">
          <div className="columns">
            <div className="column col-12">
              <h2 className="skp-text-primary">Terms and Conditions</h2>
            </div>
          </div>
          <div className="columns skp-text-light">
            <div className="column col-12">
              <dl>
                <dd>
                  <b className="skp-text-primary text-uppercase">Introduzione</b>
                  <br></br>I seguenti termini e condizioni costituiscono
                  l'Accordo con l'utente ("l'Accordo"), tra l'utente (il
                  "Cliente") e Evotech (la "Società"),con sede legale in via
                  della scienza, 50 - 41122 Modena (MO) ITALY, partiva iva n.
                  IT03934490362 Evotech è il proprietario e l'operatore di
                  https://beta.skillpro.it/ (il “sito web”). Il presente Accordo
                  regola l'utilizzo dei servizi offerti dalla Società e
                  l'utilizzo del sito web/piattaforma. Prenditi del tempo per
                  leggere e comprendere la nostra Cookie Policy e la nostra
                  Privacy Policy. Utilizzando i servizi offerti dalla Società e
                  utilizzando il sito Web, confermi di accettare di rispettare e
                  di essere vincolato da tutti i termini e le condizioni
                  contenuti nel presente Accordo e con le pratiche dei dati
                  personali della Società descritte nell'Informativa sulla
                  privacy.
                </dd>
                <dd>
                  <b className="skp-text-primary text-uppercase">Definizioni</b>
                  <br></br>
                  Per l'interpretazione di questi termini e condizioni, i
                  seguenti termini hanno il seguente significato:{" "}
                  <ul>
                    <li>
                      <b>Società</b>: Evotech
                    </li>
                    <li>
                      <b>Cliente/Utente</b>: persona fisica che si registra al
                      sito web;
                    </li>
                    <li>
                      <b>Parti</b>: la Società e il Cliente congiuntamente
                      considerati;
                    </li>
                    <li>
                      <b>Sito web</b>: Sito web/ piattaforma di proprietà e
                      gestito da Evotech;
                    </li>
                    <li>
                      <b>Servizi</b>: i servizi descritti e identificati nei
                      presenti termini e condizioni tra la Società e il Cliente;
                    </li>
                    <li>
                      <b>Accordo con l'utente</b>: l'accordo tra la Società e il
                      Cliente relativo ai Termini di servizio;
                    </li>
                    <li>
                      <b>Termini di servizio</b>: questi termini e condizioni;
                    </li>
                    <li>
                      <b>Condizioni Particolari</b>: qualsiasi disposizione
                      aggiunta alle presenti Condizioni di Vendita;
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl>
                <dd>
                  <b className="skp-text-primary">SCOPO</b>
                  <br></br>
                  Il sito https://beta.skillpro.it/ è una piattaforma online
                  dedicata a giocatori, squadre e organizzazioni di tornei
                  eSport: mira a far crescere i giocatori e diventare giocatori
                  professionisti attraverso gli strumenti che offre. Questi
                  termini di utilizzo definiscono le condizioni alle quali
                  l'utente ("Tu") può utilizzare e consultare il Sito. Di
                  conseguenza, devi accettare detti termini spuntando la casella
                  “Accetto i Termini e Condizioni” al momento della
                  registrazione. La Società si riserva il diritto di modificare
                  i termini di utilizzo in qualsiasi momento, pubblicando una
                  nuova versione sul Sito. I nuovi termini di utilizzo così
                  modificati saranno applicabili non appena inseriti nel Sito.
                  La Società può modificare il presente Accordo per l'utente e
                  l'Informativa sulla privacy in qualsiasi momento a sua
                  esclusiva discrezione e qualsiasi aggiornamento o modifica
                  sarà notificato tempestivamente all'utente tramite e-mail. Il
                  documento modificato sarà pubblicato anche sul presente sito
                  web. Allo stesso modo, se una qualsiasi delle tue informazioni
                  di contatto cambia, sei pregato di avvisare immediatamente la
                  Società inviando un'e-mail a help@skillpro.it o aggiornando i tuoi record
                  direttamente sul sito web. Per fare ciò devi accedere al tuo
                  account utilizzando le tue credenziali.
                </dd>
                <dd>
                  <b className="skp-text-primary">REGISTRAZIONE AL SITO</b>
                  <br></br>
                  Quando ti registri al Sito, devi inserire la tua email, il tuo
                  username e la tua password. Sei l'unico titolare del tuo
                  account e riconosci di essere responsabile per qualsiasi
                  problema che possa derivarne. Inoltre, accetti di fornire solo
                  informazioni accurate. Queste informazioni non devono avere
                  connotazioni sessuali o razziste. Con la presente accetti di
                  aggiornare regolarmente le tue informazioni. In caso di
                  mancato rispetto di queste condizioni, la Società si riserva
                  il diritto di sospendere o cancellare il tuo account utente.
                  Non sarai più in grado di accedere alle tue informazioni, al
                  tuo account e tutti i diritti che hai acquisito sul sito
                  verranno eliminati.
                </dd>
                <dd>
                  <b className="skp-text-primary">PARTECIPAZIONE A TORNEI </b>
                  <br></br>I tornei proposti sul sito sono principalmente
                  gratuiti, ma alcuni potrebbero essere a pagamento. Per queste
                  informazioni ed ulteriori eventuali condizioni particolari
                  relative alla partecipazione ai tornei (es. numero dei
                  vincitori, termine di iscrizione, ecc.), si prega di fare
                  riferimento al regolamento di ciascun torneo nella sezione
                  "Regolamento". L’account è strettamente personale e ne è
                  vietata la condivisione. Evotech si riserva il diritto di
                  escluderti dai tornei se non rispetti i regolamenti ed i
                  termini di partecipazione. Per i minori di 16 anni è richiesta
                  l'autorizzazione dei genitori. Tale autorizzazione deve essere
                  accompagnata da copia del passaporto o della carta d'identità
                  del legale rappresentante e del passaporto o della carta
                  d'identità del minore. Inviare il tutto a help@skillpro.it . La partecipazione di
                  minori di età inferiore ai 18 anni a tornei di eSport con
                  ricompense in denaro è vietata.
                </dd>
                <dd>
                  <b className="skp-text-primary">PREMI MONETARI </b>
                  <br></br>La Società si riserva il diritto di modificare
                  l'importo o la natura del premio in qualsiasi momento e senza
                  previa informazione. Se sei il vincitore del Torneo o sei uno
                  dei vincitori secondo i termini e le condizioni del Torneo,
                  devi recuperare il tuo premio. Inoltre, per le condizioni per
                  il recupero del premio, riceverai una email all'indirizzo
                  email che hai fornito per la tua registrazione, in cui ti
                  chiediamo i documenti da fornire per recuperare il tuo premio:
                  coordinate bancarie, documento di identità valido , ecc. Devi
                  avere un conto PayPal per ricevere il tuo premio. E’ possibile
                  infatti che la Società voglia utilizzare questa piattaforma
                  sicura per consentire il pagamento del premio. L'informativa
                  sulla privacy di PayPal è disponibile a questo indirizzo:
                  hhttps://www.paypal.com/myaccount/privacy/privacyhub Una volta
                  inviati i documenti richiesti, riceverai il tuo premio entro
                  sessanta (60) giorni lavorativi dalla tua richiesta. Se hai un
                  account Premium, questo periodo è ridotto a trenta (30) giorni
                  lavorativi. Se non richiedi la nostra ricompensa durante i 90
                  giorni successivi alla fine del torneo, non potrai
                  recuperarla. Dopo questo ritardo, il premio viene annullato.
                </dd>
                <dd>
                  <b className="skp-text-primary">ACCETTAZIONE DEL RISCHIO</b>
                  <br></br>Comprendi e accetti che
                  esistono rischi associati all'esport, inclusi, ma non limitati
                  a, danni agli occhi, stress ripetitivo e altri danni associati
                  a lunghi periodi trascorsi davanti al computer e console. Con
                  la presente ti assumi espressamente tutti questi rischi. Il
                  Pan European Game Information (PEGI) è un sistema di
                  classificazione europeo per i videogiochi, che classifica i
                  videogiochi in base all'età e mira ad aiutare i consumatori,
                  in particolare i genitori, a informarsi su ogni tipo di
                  videogioco utilizzando un logo diverso. Attieniti
                  scrupolosamente a quanto riportato per singolo gioco.
                </dd>
              </dl>
            </div>
          </div>
          <div className="space-divider"></div>
          <Footer />
          <div className="space-divider-20"></div>
        </div>
      </div>
    </Scrollbars>
  );
};

export default TermsConditions;
