import { useState } from "react"
import ITournament from "../../../../Interfaces/Tournament"
import ITournamentLobby from "../../../../Interfaces/TournamentLobby"
import PlayPlayTeamUser from "../Components/UserComponents/PlayPlayTeamUser"

type Props = {
    tournament: ITournament
    lobby: ITournamentLobby
}
  
const LobbyTeamsTab = ({ lobby, tournament, ...props }:Props) => {
  const [checkedState, setCheckedState] = useState<boolean[]>(new Array(tournament.teams.length).fill(false));

    return (
        <div className="container">
            {lobby && lobby.teams &&
            lobby.teams.length > 0 &&
            lobby.teams.map((team, index) => (
          <div key={`team-${index}`} className={`columns accpointer ${checkedState[index] === true ? '' : 'team-border'}`}
            onClick={(e)=>{
              const newState = [...checkedState]
              newState[index] = !checkedState[index]
              setCheckedState(newState)
            }}>
            <div className="space-divider-20"></div>
            <div className="column col-1 col-xs-3 skp-text-primary">
              <div className="small-player-wrapper">
                {team.partyAvatar && (
                  <img
                    src={team.partyAvatar}
                  />
                )}
                {!team.partyAvatar && (
                  <img
                    src="https://play-play.com/images/default_avatar.jpg"
                  />
                )}
               
              </div>
            </div>
            <div className="column col-10 col-xs-8 skp-text-primary">
              {!team.partyName && <h5>Team {index + 1}</h5>}
              {team.partyName && (
                <h5>
                  <div className="skp-text-label skp-text-light text-uppercase" style={{paddingTop: "18px" }}>
                    Team {index + 1}{" "}
                  </div>{" "}
                  {team.partyName}
                </h5>
              )}
            </div>
            {!checkedState[index] && (
              <div className="column col-1 col-xs-1 skp-text-primary">
                <i className="las la-angle-right" style={{marginTop:"30px"}}></i>
              </div>
            )}
            {checkedState[index] && (
              <div className="column col-1 col-xs-1 skp-text-primary">
                <i className="las la-angle-down" style={{marginTop:"30px"}}></i>
              </div>
            )}
            <div className={`column col-12 ${checkedState[index] === true ? '' : 'team-hidden'}`}>
              <dl className="bordered-list">
                {team.members.map((member, index) => (
                  <dd className="columns" key={member + index.toString()}>
                    <div className="column col-8 col-xs-12">
                      <PlayPlayTeamUser
                        playerId={member.userId} 
                      >
                        {index === 0 && (
                          <span className="skp-chip status-captain skp-text-small">
                            Leader
                          </span>
                        )}
                      </PlayPlayTeamUser>
                    </div>
                    </dd>
                  ))}
                </dl>
              </div>
            </div>
          ))}
     
    </div>
    );
};

export default LobbyTeamsTab;