import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TournamentService from "../../../services/TournamentService";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import ITournament from "../../Interfaces/Tournament";
import TournamentPage from "./TournamentPage";
import ImageService from "../../../services/ImageService";
import TUrlParams from "../../../types/TUrlParams";

const TournamentControl = () => {
  let navigate = useNavigate();
  const [tournament, setTournament] = useState<ITournament>();
  const [loading, setLoading] = useState(false);
  const [loadingTournament, setLoadingTournament] = useState(false);
  const params = useParams<TUrlParams>();
  const tournamentId = params.id;
  const currentTab = params.tab;

  const isSettingsPage = params.path === "settings";

  const loadTournament = useCallback(
    (tournament?: ITournament) => {
      if (tournament) {
        setTournament(tournament);
      } else {
        setLoading(true);
          TournamentService.getTournament(tournamentId!).then((tournament) => {
            setTournament(tournament);
            setLoading(false);
          });
      }
    },
    [tournamentId]
  );


  const updateTournament = async (
    dataToPass: any,
    file?: IFileBase64Image,
    type?: string
  ) => {
    setLoadingTournament(true);
    if (file && type) {
      const imageResponse = await ImageService.uploadImage(file);
      const { data } = await imageResponse;
      dataToPass[type] = ImageService.generatePublicUrl(data);
      if (data) {
        try {
            const tournament = await TournamentService.updateTournament(
              dataToPass,
              tournamentId!
            );
          loadTournament(tournament);
          toast.success("Tournament updated successfully");
          setLoadingTournament(false);
          //   setShowUploadModal({ show: false, type: undefined });
        } catch (error: any) {
          if (error?.response.data.message) {
            toast.error(error?.response.data.message);
          } else {
            toast.error("Tournament update error");
          }
          setLoadingTournament(false);
        }
      }
    } else {
      try {
          const tournament = await TournamentService.updateTournament(
            dataToPass,
            tournamentId!
          );
        if (tournament) {
          loadTournament(tournament);
          toast.success("Tournament updated successfully");
          setLoadingTournament(false);
          //   setShowUploadModal({ show: false, type: undefined });
        }
      } catch (error: any) {
        if (error?.response.data.message) {
          toast.error(error?.response.data.message);
        } else {
          toast.error("Tournament update error");
        }
        setLoadingTournament(false);
      }
    }
  };

  useEffect(() => {
    loadTournament();
  },[loadTournament])

  useEffect(() => {
    if (currentTab === "settings") {
      navigate(`/tournaments/${tournamentId}/settings/general`);
    } else if (!currentTab) {
      navigate(`/tournaments/${tournamentId}/overview`);
    }
    
  }, [currentTab, navigate, tournamentId]);

  return (
    <>
      {loading && (
        <div className="container">
          <div className="space-divider"></div>
          <div className="columns">
            <div className="column col-12 text-center">
              <div className="loading"></div>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <>
          
            <TournamentPage
              tournament={tournament}
              urlParams={params}
              loadingTournament={loadingTournament}
              loadTournament={loadTournament}
              updateTournament={updateTournament}
              isSettingsPage={isSettingsPage}
            />
        </>
      )}
    </>
  );
};

export default TournamentControl;
