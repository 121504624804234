import constant from "../constants";
import HttpService from "./HttpService";
import qs from 'query-string';
import { setupCache } from "axios-cache-adapter";
import ITournament from "../components/Interfaces/Tournament";
import IGameAccount from "../components/Interfaces/GameAccount";
import ITournamentLobbyLeaderboard from "../components/Interfaces/TournamentLobbyLeaderboard";
import ICompetition from "../components/Interfaces/Competition";

const cache = setupCache({
  maxAge: 1 * 60 * 1000,
  exclude: { query: false }
});

const _axios = HttpService.getAxiosClient();

const getSpreadsheet = (spreadsheetId: string, sheetName:string):Promise<Array<any>> => {
  const url = `${constant.API_URL}/tournaments/-/custom?sheetId=${spreadsheetId}&sheetName=${sheetName}&sheetDimension=COLUMNS`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data.values;
  });

}

const getTournaments = (queryParam: any, isTester: boolean, userId: string|undefined):Promise<Array<ITournament>> => {
  let url = `${constant.API_URL}/tournaments`;
  if (queryParam) {
    url += `?${qs.stringify(queryParam)}` + "&isTester=" + isTester + "&userID=" + userId;
  } else {
    url += "?isTester="+isTester + "&userID=" + userId;
  }

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data.tournaments;
  });
};

const getTournamentsByYuniteId = (yuniteId: string):Promise<ITournament> => {
  let url = `${constant.API_URL}/tournaments?yuniteId=${yuniteId}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data.tournaments[0];
  });
};

const getTournament = (tournamentId: string):Promise<ITournament> => {
    let url = `${constant.API_URL}/tournaments/${tournamentId}`;

    return _axios.get(url).then((response) => {
      return response.data.data;
    });
}

const getCompetitions = (queryParam: any):Promise<Array<ICompetition>> => {
  let url = `${constant.API_URL}/tournaments/competitions`;
  if (queryParam) {
    url += `?${qs.stringify(queryParam)}`
  }
  
  return _axios({
    method: "get",
    url: url
  }).then((response) => {
    return response.data.data;
  });
};

const getCompetition = (competitionId: string):Promise<ICompetition> => {
  let url = `${constant.API_URL}/tournaments/competitions/${competitionId}`;

  return _axios.get(url).then((response) => {
    return response.data.data;
  });
}

const getCacheTournament = (tournamentId: string):Promise<ITournament> => {
  let url = `${constant.API_URL}/tournaments/${tournamentId}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data;
  });
}

const createTournament = (data: any):Promise<ITournament> => {
    const url = `${constant.API_URL}/tournaments`;
    return _axios({
      method: "post",
      url: url,
      data: data,
    }).then((response) => {
      return response.data.data;
    });
}

const createCompetition = (data: any): Promise<ICompetition> => {
  const url = `${constant.API_URL}/tournaments/competition`;
  return _axios({
    method: "post",
    url: url,
    data: data,
  }).then((response) => {
    return response.data.data;
  });
}

const updateCompetition = (data: any, competitionId: string):Promise<ICompetition> => {
  const url = `${constant.API_URL}/tournaments/competition/${competitionId}`;
  return _axios({
    method: "put",
    url: url,
    data: data,
  }).then((response) => {
    return response.data.data
  });
}

const updateTournament = (data: any, tournamentId: string):Promise<ITournament> => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}`;
    return _axios({
      method: "put",
      url: url,
      data: data,
    }).then((response) => {
      return response.data.data
    });
}
  

const deleteTournament = (tournamentId: string) => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}`;
    return _axios({
      method: "delete",
      url: url
    }).then((response) => {
      return response;
    });
}

const subscribeTournament = (tournamentId: string, userId: string | Array<string>, data: any, isAdmin?: string) => {
    userId = Array.isArray(userId) ? userId.toString() : userId;
    const url = `${constant.API_URL}/tournaments/${tournamentId}/register?userID=${userId}${isAdmin ? "&isAdmin=true" : ""}`;
    return _axios({
      method: "post",
      url: url,
      data: data
    }).then((response) => {
      return response;
    })
}

const unSubscribeTournament = (tournamentId: string, userId: string, data?: any) => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}/register?userID=${userId}${data && data.isAdmin ? "&isAdmin=true" : ""}`;
    return _axios({
      method: "put",
      url: url
    }).then((response) => {
      return response;
    });
}

const checkinTournament = (tournamentId: string, userId: string | Array<string>, data: any, isAdmin?: string) => {
  userId = Array.isArray(userId) ? userId.toString() : userId;
  const url = `${constant.API_URL}/tournaments/${tournamentId}/register/checkin?userID=${userId}${isAdmin ? "&isAdmin=true" : ""}`;
  return _axios({
    method: "post",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const checkoutTournament = (tournamentId: string, userId: string, data?: any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/register/checkin?userID=${userId}${data && data.isAdmin ? "&isAdmin=true" : ""}`;
    return _axios({
      method: "put",
      url: url
    }).then((response) => {
      return response;
    });
}

const generateTournamentBrackets = (tournamentId: string, data: any) => {
    const url = `${constant.API_URL}/tournaments/${tournamentId}/matches${data.bronze ? "?bronze=true" : ""}`;
    
    return _axios({
      method: "post",
      url: url,
      data: data,
    }).then((response) => {
      return response;
    });
}

const editTeamName = (tournamentId: string, teamId: string, partyName: string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/teams/${teamId}`
  return _axios({
    method: "put",
    url: url,
    data: {partyName: partyName}
  }).then((response) => {
    return response.data.data;
  });
}

const addTeamMember = (tournamentId: string, teamId: string, userId: string, gameAccount:IGameAccount) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/teams/${teamId}/users/${userId}`
  return _axios({
    method: "put",
    url: url,
    data: {gameAccount: gameAccount}
  }).then((response) => {
    return response.data.data;
  });
}

const removeTeamMember = (tournamentId: string, teamId: string, userId: string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/teams/${teamId}/users/${userId}`
  return _axios({
    method: "post",
    url: url,
  }).then((response) => {
    return response.data.data;
  });
}

const changeTeamMemberStatus = (tournamentId: string, teamId: string, userId: string, status: number, gameAccount?:IGameAccount):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/teams/${teamId}/users/${userId}?status=${status}`;
    return _axios({
      method: "patch",
      url: url,
      data: {gameAccount: gameAccount}
    }).then((response) => {
      return response.data.data;
    });

}

const changeTeamMemberRole = (tournamentId:string, teamId: string, userId: string, role: number):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/teams/${teamId}/users/${userId}?role=${role}`;
    return _axios({
      method: "patch",
      url: url,
    }).then((response) => {
      return response.data.data;
    });

}

const addAdmin = (tournamentId: string, userIds:Array<string>):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/admins/${userIds.toString()}`
  return _axios({
    method: "post",
    url: url
  }).then((response) => {
    return response.data.data;
  });
}

const removeAdmin = (tournamentId: string, userIds:Array<string>):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/admins/${userIds.toString()}`
  return _axios({
    method: "delete",
    url: url
  }).then((response) => {
    return response.data.data;
  });
}

const editGroup = (tournamentId: string, groupId: string, group:{name: string, pointSystem: {pointsPerWin: number, pointsPerLose: number, pointsPerDraw: number}}):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/groups/${groupId}`
  return _axios({
    method: "put",
    url: url,
    data: {group: group}
  }).then((response) => {
    return response.data.data;
  });
}

const reloadGroupLeaderboard = (tournamentId: string, groupId: string):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/groups/${groupId}/leaderboard`
  return _axios({
    method: "post",
    url: url
  }).then((response) => {
    return response.data.data;
  });
}

const addLobby = (tournamentId:string, data:any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/lobbies`;
  return _axios({
    method: "post",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const updateLobby = (tournamentId:string, lobbyId:string, data:any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/lobbies/${lobbyId}`
  return _axios({
    method: "put",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const deleteLobby = (tournamentId:string, lobbyId:string) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/lobbies/${lobbyId}`
  return _axios({
    method: "delete",
    url: url,
  }).then((response) => {
    return response;
  });
}

const getLobbyLeaderboard = (tournamentId:string, lobbyId:string):Promise<Array<ITournamentLobbyLeaderboard>> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/lobbies/${lobbyId}/leaderboard`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache.adapter,
  }).then((response) => {
    return response.data.data;
  });
}

const updateGameLeaderboard = (tournamentId:string, lobbyId:string, gameNumber:string, data:any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/lobbies/${lobbyId}/leaderboard/${gameNumber}`;
  return _axios({
    method: "put",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const updateTournamentLeaderboard = (tournamentId:string, data:any) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/leaderboard`;
  return _axios({
    method: "put",
    url: url,
    data: data
  }).then((response) => {
    return response;
  });
}

const seedLobbies = (tournamentId:string, numberOfLobbies:number) => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/matches`;
  return _axios({
    method: "post",
    url: url,
    data: {lobbies: numberOfLobbies}
  }).then((response) => {
    return response;
  });
}

const changeMatchesConfiguration = (tournamentId:string, data:any):Promise<ITournament> => {
  const url = `${constant.API_URL}/tournaments/${tournamentId}/matches/configMatches`;
  return _axios({
    method: "post",
    url: url,
    data: data
  }).then((response) => {
    return response.data.data;
  });
}

const TournamentService = {
  getSpreadsheet,
  getTournaments,
  getTournamentsByYuniteId,
  getTournament,
  getCacheTournament,
  createTournament,
  createCompetition,
  getCompetitions,
  getCompetition,
  updateCompetition,
  updateTournament,
  deleteTournament,
  subscribeTournament,
  unSubscribeTournament,
  checkinTournament,
  checkoutTournament,
  generateTournamentBrackets,
  editTeamName,
  addTeamMember,
  removeTeamMember,
  changeTeamMemberStatus,
  changeTeamMemberRole,
  addAdmin,
  removeAdmin,
  editGroup,
  reloadGroupLeaderboard,
  addLobby,
  updateLobby,
  deleteLobby,
  getLobbyLeaderboard,
  updateGameLeaderboard, 
  updateTournamentLeaderboard,
  seedLobbies,
  changeMatchesConfiguration
};

export default TournamentService;