import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Marquee from "react-text-marquee";
import KeyCloackService from "../../../../../../services/KeyCloackService";
import TournamentService from "../../../../../../services/TournamentService";
import UserByEmail from "../../../../../UiLibrary/User/UserByEmail";

type Props = {
  sheet: string;
};
const KillRaceFortnite = (props: Props) => {
  const { sheet } = props;
  const sheetId = "1Q5e_369AxmVhWqf10KWWTcobURc_jeNB4lgPYL6abNs";
  const [players, setPlayers] = useState<{ username: string; kills: string }[]>(
    []
  );

  useEffect(() => {
    TournamentService.getSpreadsheet(sheetId, sheet).then((Rvalues) => {
      const columns = Rvalues.reduce(
        (
          accumulator: any[],
          currentValue: any,
          currentIndex: number,
          array: any[]
        ) => {
          if (currentIndex % 2 === 0)
            accumulator.push(array.slice(currentIndex, currentIndex + 2));
          return accumulator;
        },
        []
      );

      let players: { username: string; kills: string }[] = [];
      console.log(columns[0]);
      columns[0][0].forEach((player: any, index: number) => {
        console.log(player);
        let playerToAdd: { username: string; kills: string } = {
          username: "",
          kills: "",
        };

        if (index > 0) {
          playerToAdd.username = player;
          playerToAdd.kills = columns[0][1][index];
          players.push(playerToAdd);
        }
      });

      setPlayers(players);
    });
  }, [sheet]);

  return (
    <>
      <div className="columns nav-buttons">
        <div className="space-divider"></div>

        <div className="column col-6">
          <a
            className={`logo-link logo-link-small ${
              sheet === "Giorno1" ? "activeLink" : null
            }`}
            href="/branded-tournaments/milano-comics/killrace/fortnite/Giorno1"
          >
            Day 1
          </a>
        </div>
        <div className="column col-6">
          <a
            className={`logo-link logo-link-small ${
              sheet === "Giorno2" ? "activeLink" : null
            }`}
            href="/branded-tournaments/milano-comics/killrace/fortnite/Giorno2"
          >
            Day 2
          </a>
        </div>
      </div>

      <div className="columns">
        <div className="space-divider-20"></div>
        <div className="column col-12">
          <dl className="box-card">
            <dd className="columns title">
              <span className="column col-6 text-uppercase text-left">
                Username
              </span>
              <span className="column col-6 text-right text-uppercase">
                Kills
              </span>
            </dd>
            {players
              .sort((a, b) =>
                Number(a.kills) &&
                Number(b.kills) &&
                Number(a.kills) < Number(b.kills)
                  ? 1
                  : -1
              )
              .map((player) => (
                <dd className="columns">
                  <span className="column col-6 text-left">
                    {KeyCloackService.isLoggedIn() && (
                      <UserByEmail email={player.username} />
                    )}
                    {!KeyCloackService.isLoggedIn() && (
                      <Marquee text={player.username} />
                    )}
                  </span>
                  <span
                    className="column col-6 text-right flex-centered"
                    style={{ justifyContent: "right" }}
                  >
                    {player.kills}
                  </span>
                </dd>
              ))}
          </dl>
        </div>
      </div>
    </>
  );
};

export default KillRaceFortnite;
