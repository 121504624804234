import Multiselect from "multiselect-react-dropdown";
import { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import TeamService from "../../../services/TeamService";
import { UserContext } from "../../Context/UserContext";
import ITeam from "../../Interfaces/Team";
import ITournament from "../../Interfaces/Tournament";
import IUserDetails from "../../Interfaces/UserDetails";
import TeamRow from "./TeamRow";

type Props = {
  tournament: ITournament;
  members?: string[]
  game: string
  removeCheckbox?: boolean
  onAddMember: (member: string, user: IUserDetails) => void;
};

const TeamColumn = (props: Props) => {
  const { tournament, members, game, removeCheckbox } = props;
  const { user } = useContext(UserContext);
  const [teams, setTeams] = useState<Array<ITeam>>([]);
  const [team, setTeam] = useState<ITeam>();
  const [value, setValue] = useState<Array<ITeam>>([]);
  const [paginatedItems, setPaginatedItems] = useState<Array<string>>([]);

  useEffect(() => {
    TeamService.getAllTeams({ members: user?.id }).then((teams) => {
      setTeams(teams);
      setTeam(teams[0]);
      setValue([teams[0]]);

      let items: Array<string> = [];
      const index =
        teams[0]?.members?.length >= 10 ? 10 : teams[0]?.members?.length;
      for (let i = 0; i < index; i++) {
        items.push(teams[0]?.members[i]);
      }
      setPaginatedItems(items);
    });
    return () => {};
  }, [user]);

  const setTeamList = (teams: Array<ITeam>) => {
    if (teams.length === 0) {
      setPaginatedItems([]);
      setTeam(undefined);
    } else {
      let items: Array<string> = [];
      const index =
        teams[0]?.members?.length >= 10 ? 10 : teams[0]?.members?.length;

      for (let i = 0; i < index; i++) {
        items.push(teams[0].members[i]);
      }

      setPaginatedItems(items);
      setTeam(teams[0]);
    }
  };

  const fetchMoreData = () => {
    if (team) {
      setTimeout(() => {
        setPaginatedItems([
          ...paginatedItems,
          team.members[paginatedItems.length],
        ]);
      }, 100);
    }
  };
  return (
    <>
      <div className="skp-form-control">
        <label className="skp-text-primary skp-text-small">Select a team</label>
        {teams.length !== 0 && (
          <Multiselect
            options={teams} // Options to display in the dropdown
            selectedValues={value} // Preselected value to persist in dropdown
            onSelect={setTeamList} // Function will trigger on select event
            onRemove={setTeamList} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            singleSelect={true}
            emptyRecordMsg="All options selected"
            closeIcon="cancel"
            placeholder="Select a status"
            avoidHighlightFirstOption={true}
          />
        )}

        {teams.length === 0 && (
          <div className="skp-text-light skp-text-large text-center">
            <h1>
              <i className="las la-exclamation-circle"></i>
            </h1>
            You are not part of any teams! Please join o create one.
          </div>
        )}

        {!team && teams.length !== 0 && (
          <div className="skp-text-light skp-text-large text-center">
            <div className="space-divider-20"></div>
            <h1>
              <i className="las la-exclamation-circle"></i>
            </h1>
            Please select a team.
          </div>
        )}
      </div>

      <Scrollbars
        style={{ width: "100%", height: 500 }}
        renderView={(props:any) => <div {...props} id="scrollableTeamDiv" />}
      >
        <dl className="columns bordered-list">
          <InfiniteScroll
            dataLength={paginatedItems.length}
            scrollableTarget="scrollableTeamDiv"
            next={fetchMoreData}
            hasMore={
              team ? team?.members.length > paginatedItems.length : false
            }
            style={{ overflow: "hidden" }}
            loader={
              <dd>
                <div className="loading"></div>
              </dd>
            }
            scrollThreshold={"200px"}
            endMessage={
              <>
                {paginatedItems.length >= 10 && (
                  <>
                    <p
                      className="text-center skp-text-light m-2"
                      style={{ textAlign: "center" }}
                    >
                      END
                    </p>
                  </>
                )}
              </>
            }
          >
            {team?.members &&
              paginatedItems.map((member, index) => (
                <TeamRow
                  key={index}
                  member={member}
                  members={members}
                  game={game}
                  tournament={tournament}
                  onAddMember={props.onAddMember}
                  removeCheckbox={member === user?.id ? true : removeCheckbox}
                />
              ))}
          </InfiniteScroll>
        </dl>
      </Scrollbars>
    </>
  );
};

export default TeamColumn;
