const UserTournamentGameAccount = (props) => {
  const { tournament, userId } = props;
  const findUsername = () => {
    let foundUser;
    const userTeam = tournament?.teams?.filter((team) =>
      team?.members?.find((member) => member.userId === userId)
    );
    if (userTeam) {
      foundUser = userTeam[0]?.members?.find(
        (member) => member.userId === userId
      );
    }
    return foundUser?.gameAccount ? foundUser.gameAccount : undefined;
  };

  return (
    <>
      {findUsername()?.username && (
        <h6 className="flex-centered skp-text-primary m-2">
          <i
            style={{ fontSize: 20 }}
            className={`la-${findUsername()?.console} tooltip`}
            data-tooltip={findUsername()?.console}
          ></i>
          <span className="ml-2">
            {findUsername()?.username}
          </span>
        </h6>
      )}
    </>
  );
};

export default UserTournamentGameAccount;
