import UserSearchDropdown from "../../UiLibrary/User/UserSearchDropdown";
import IUserDetails from "../../Interfaces/UserDetails";
import Modal from "../../UiLibrary/Modal";
import { useRef, useState } from "react";
import FormikControl from "../../Formik/FormikControl";
import constant from "../../../constants";
import { Form, Formik, FormikProps } from "formik";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type FormModel = {
  roles: Array<string> | undefined;
};

const RolesAdmin = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [choosenUser, setChoosenUser] = useState<IUserDetails>()
  const formikRef = useRef<FormikProps<FormModel>>(null);
  const [initialValues, setInitialValues] = useState<FormModel>({roles:[]})
  
  const chooseUser = (user: IUserDetails) => {
      if (user.id ) {
        setInitialValues({roles:user.roles})
        setChoosenUser(user)
        //setLoading(true);
        /*TournamentService.addAdmin(tournament.id, [user.id])
          .then((tournament) => {
            toast.success("New admin added");
            setLoading(false);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
            setLoading(false);
          });*/
      }
    setShowAddAdminModal(false);
    //setShowJoinModal({ show: true, user: user });
   };

  const onSubmit = (values: FormModel) => {

    setLoading(true);
    const userId = choosenUser?.id;
    UserService.updateAdminUserInfo(values, userId)
      .then((user) => {
        setLoading(false);
        toast.success(t('toast.userInfoUpdate'));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
     /*if (values.teamSize) {
       values.teamSize = Number(values.teamSize);
       values.playerType = values.teamSize === 1 ? 0 : 1;
     }
     if (values?.privateCheck && values.privateCheck.length > 0) {
       values.open = false;
     } else if (values?.privateCheck?.length === 0) {
       values.open = true;
     }
     let { privateCheck, ...newValues } = values;
     props.onSubmit(newValues);
     */
  };

  return (
    <div className="skp-full-page">
      <div className="container">
        <div className="columns">
          <div className="column col-12 text-center">
            <h4 className="skp-primary-blue skp-heading ">{t('user.config.rolesAdministration')}</h4>
          </div>
        </div>
        <div className="space-divider-20"></div>
        <div className="columns">
          <div className="column col-12 skp-primary-pink text-uppercase skp-text-xlarge">
            <button 
                style={{ marginLeft: 5, marginTop: 10 }}
                className="skp-btn skp-btn-secondary skp-text-small"
                onClick={() => setShowAddAdminModal(true)}
              >
                {t('user.config.chooseUser')}
            </button>
          </div>
        </div>
        <div className="space-divider-20"></div>
        <h6 className="skp-text-primary">
          {t('user.config.rolesAssignedToUser')}
        </h6>
        <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              innerRef={formikRef}
              enableReinitialize
        >
          <Form>
            <div className="columns">
              {choosenUser && (
                <FormikControl
                  control="checkbox"
                  label="Select multiple platforms"
                  name="roles"
                  options={constant.SKILLPRO_ROLES}
                />
              )}
            </div>
            <div className="space-divider-20"></div>
            <button type="submit" className="skp-btn skp-btn-primary float-left ">
              {t('user.config.saveRoles')}
            </button>
          </Form>
        </Formik>
       
      </div>

      <Modal
        size="small"
        title={t('user.config.searchUser')}
        show={showAddAdminModal}
        onClose={() => setShowAddAdminModal(false)}
      >
        <UserSearchDropdown setUser={chooseUser} />
      </Modal>
    </div>

    
  );
};

export default RolesAdmin;
