import ITournament from "../../../../Interfaces/Tournament";
import SingleLobby from "./SingleLobby";

type Props = {
    tournament: ITournament;
    // onSetMatch: (data: any) => void;
};
  
const LobbiesTab = (props: Props) => {
    const { tournament } = props;

    return (
        <>
            { tournament.lobbies && tournament.lobbies.length === 0 && (
                <>
                    <div className="columns skp-text-light">
                        <div className="column col-12 flex-centered">
                            <h1>
                                <i className="las la-icons"></i>
                            </h1>
                        </div>
                        <div className="column col-12 flex-centered">
                            <h5>No lobbies</h5>
                        </div>
                        <div className="column col-12 flex-centered">
                            There are currently no lobbies for this Tournament.
                        </div>
                        <div className="space-divider-20"></div>
                    </div>
                </>
            )}
            {tournament?.lobbies && tournament.lobbies.length > 0 && (
                <>
                    {tournament?.lobbies?.map((lobby:any) => (
                    <SingleLobby
                        key={lobby.name+lobby.id}
                        lobby={lobby}
                        tournament={tournament}
                    />
                    ))}
                </>
            )}
        </>
    )
};

export default LobbiesTab;