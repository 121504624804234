import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import ErrorLabel from "../../Forms/ErrorLabel";
import React from 'react'
import GAMES from "../../../constants-games";
import IUserDetails from "../../Interfaces/UserDetails";
import ITournamentYunite from "../../Interfaces/TournamentYunite";

type Props = {
  tournament: ITournamentYunite
  loading: boolean
  user: IUserDetails
  onImport: (data: any) => void
}


const initialValues:{check_in: number} = {
  check_in: 45
};

const validationSchema = Yup.object({
  check_in: Yup.number()
    .min(10, "Check-in period must be at least 10 minutes.")
    .required("Required")
});

const ImportYuniteModal = (props: Props) => {
    const { tournament, loading, user } = props;

    const onSubmit = (values:{check_in: number}) => {
        const endRegistration = new Date(new Date(tournament?.startDate).valueOf() - values.check_in * 60000);

        let platforms:Array<string> = [];
        tournament?.matchTemplate[0]?.allowedPlatforms.forEach((el) => {
          if(el === "XB1") {
            el = "XBOX_ONE"
          }
          platforms.push(el)
        })
        
        const data = {
          name: tournament.name,
          start_tournament: tournament.startDate,
          end_tournament: tournament.endDate,
          start_registration: new Date(),
          end_registration: endRegistration,
          description: tournament.description,
          owner: user?.id,
          game: GAMES.FORTNITE.ENUM,
          minPartecipants: Number(tournament.consensusMin),
          maxPartecipants: tournament.matchTemplate[0].maxPlayers,
          type: tournament.matchTemplate[0].type,
          playerType: 0,
          status: 0,
          platform: "YUNITE",
          yuniteId: tournament.id,
          requiredAccount: true,
          console: platforms
        };
        props.onImport(data);
    }
    return (
      <div className="container">
        <div className="columns">
          <div className="space-divider-20"></div>
          <div className="column col-12">
            <h6 className="skp-text-primary">Please add a check-in period</h6>
            <p className="skp-text-small skp-text-light">
              The number of minutes before start that players can confirm
              partecipation in the tournament
            </p>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <div className="columns">
                <div className="column col-12">
                  {/* Check-in */}
                  <div
                    className={
                      errors.check_in && touched.check_in
                        ? "skp-form-control skp-form-control-error"
                        : "skp-form-control"
                    }
                  >
                    <label
                      className="skp-text-primary skp-text-input-label"
                      htmlFor="check_in"
                    >
                      Check-in period (minutes before start time)
                    </label>
                    <Field name="check_in" id="check_in" type="number" />
                    <ErrorMessage name="check_in" component={ErrorLabel} />
                  </div>
                </div>
              </div>
              <div className="space-divider"></div>
              <div className="modal-submit-internal">
                <button
                  type="submit"
                  disabled={!isValid || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Import"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
}

export default ImportYuniteModal
