import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import TeamService from "../../../services/TeamService";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import KeyCloackService from "../../../services/KeyCloackService";
import LoginOrRegisterModal from "../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../constants";
import Modal from "../../UiLibrary/Modal";
import "./team-join-page.scss";
import ITeam from "../../Interfaces/Team";
import TeamBrandLogo from "../../UiLibrary/TeamBrandLogo";

const TeamJoin = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [teamInfo, setTeamInfo] = useState<ITeam>();
  const teamId = useParams<{ id: string }>().id;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
      TeamService.getTeam(teamId!).then((response) => {
        setTeamInfo(response.data);
      });
  }, [teamId]);

  const joinTeam = () => {
    if (!user?.id) {
      return;
    }
    TeamService.inviteMember(teamId!, user.id)
      .then(() => {
        toast.success(
          "Team Joined Successfully! You will become part of the team once one of the team's admins accept the invitation."
        );
        navigate(`/teams/${teamId}/overview`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const acceptInvitation = () => {
    if (!user?.id) {
      return;
    }

    TeamService.acceptMember(teamId!, user.id)
      .then(() => {
        toast.success(
          "Team Joined Successfully!"
        );
        navigate(`/teams/${teamId}/overview`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const isMemberAlready = teamInfo?.members.includes(user?.id || "");
  const isPendingMember =
    teamInfo?.pendings?.findIndex((member) => member.id === user?.id) !== -1;
  
  if (isPendingMember) {
  }

  const memberStatus = teamInfo?.pendings?.find((member) => member.id === user?.id)?.status


  return (
    <>
      <div className="team-join-page container flex-centered">
        <div className="columns text-center">
          <div className="column col-12 profile-picture col-xs-12 text-center-xs">
            <TeamBrandLogo team={teamInfo} />
          </div>
          <div className="space-divider-20"></div>
          <div className="column col-12">
            <h1 className="skp-text-primary"><Link className="skp-link-hover" to={`/teams/${teamId}`}>{teamInfo?.name}</Link></h1>
          </div>
          <div className="column col-12 skp-text-light">
            {teamInfo?.members.length} Member
            {teamInfo && teamInfo?.members.length > 1 && <span>s</span>}{" "}
          </div>
          <div className="space-divider-20"></div>

          {KeyCloackService.isLoggedIn() &&
            !isMemberAlready &&
            !isPendingMember && (
              <div className="column col-12">
                <button onClick={joinTeam} className="skp-btn skp-btn-primary">
                  Join Team
                </button>
              </div>
            )}

          {KeyCloackService.isLoggedIn() &&
            !isMemberAlready &&
            isPendingMember && 
            memberStatus === 1 && (
              <div className="column col-12">
                <button onClick={acceptInvitation} className="skp-btn skp-btn-ternary">
                  Accept Invitation
                </button>
              </div>
            )}

          {(isMemberAlready) && (
            <div className="column col-12 skp-text-primary skp-text-default">
              You are already a member of this group!
            </div>
          )}

          {(isPendingMember && memberStatus === 0) && (
            <div className="column col-12 skp-text-primary skp-text-default">
              Pending under review.
            </div>
          )}

          {!KeyCloackService.isLoggedIn() && (
            <div className="column col-12">
              <button
                onClick={() => setShowModal(true)}
                className="skp-btn skp-btn-primary"
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>

      {!KeyCloackService.isLoggedIn() && (
        <Modal
          show={showModal}
          size="small"
          onClose={() => setShowModal(false)}
        >
          <LoginOrRegisterModal
            message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default TeamJoin;
