import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateDropdown from "../CreateDropdown/CreateDropdown";
import "./action-buttons-wrapper.scss";
import Modal from "../../UiLibrary/Modal";
import NewTournamentModal from "../../Modals/NewTournamentModal/NewTournamentModal";
import NewTeamModal from "../../Modals/NewTeamModal/NewTeamModal";
import NewCompetitionModal from "../../Modals/NewCompetitionModal/NewCompetitionModal";
import { useTranslation } from "react-i18next";
import NewBrandModal from "../../Modals/NewBrandModal/NewBrandModal";

const ActionButtons = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>();

  const callback = (value: string) => {
    setShowModal(true);
    setModalTitle(value);
  };

  const tournamentCreated = (tournamentId: string) => {
    navigate(`/tournaments/${tournamentId}/overview`);
    setShowModal(false);
  };

  const teamCreated = (teamId: string | undefined) => {
    if (!teamId) {
      return;
    }
    navigate(`/teams/${teamId}/overview`);
    setShowModal(false);
  };

  const brandCreated = (brandId: string | undefined) => {
    if (!brandId) {
      return;
    }
    navigate(`/brands/${brandId}/overview`);
    setShowModal(false);
  };

  const competitionCreated = (competitionId: string | undefined) => {
    if (!competitionId) {
      return;
    }

    setShowModal(false);
  }

  return (
    <div className="action-buttons-wrapper">
      <div className="create-dropdown-wrapper">
        <CreateDropdown dropdownCallback={callback} />
      </div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={t(modalTitle!)}
        size={modalTitle !== "newTournament" ? "medium" : ""}
      >
        <>
          {modalTitle === "newCompetition" && (
            <NewCompetitionModal onCreate={competitionCreated} />
          )}
          {modalTitle === "newTournament" && (
            <NewTournamentModal onCreate={tournamentCreated} />
          )}
          {modalTitle === "newTeam" && (
            <NewTeamModal onCreate={teamCreated} />
          )}
          {modalTitle === "newBrand" && (
            <NewBrandModal onCreate={brandCreated} />
          )}
        </>
      </Modal>
    </div>
  );
};

export default ActionButtons;
