import { useContext, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Dropdown from "../../UiLibrary/Dropdown";
import constant from "../../../constants";
import KeyCloackService from "../../../services/KeyCloackService";
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
  dropdownCallback: (action: string) => void
}

const CreateDropdown = (props:Props) => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation()
    const [show, setShow] = useState(false);
    const canCreate = KeyCloackService.hasRole([
      "Tournament Owner"
    ])
    const canCreateTournament = user?.roles ? user.roles.includes("TournamentCreator") : false;
    const canCreateCompetition = user?.roles ? user.roles.includes("CompetitionCreator") : false;
    const canCreateBrand = user?.roles ? user.roles.includes("BrandCreator") : false;

    return (
      <OutsideClickHandler onOutsideClick={() => setShow(false)}>
        <button
          className="skp-btn skp-btn-circle ml-2"
          onClick={() => setShow(true)}
        >
          <i className="las la-plus"></i>
        </button>
        <Dropdown show={show}>
          <div className="close-icon show-xs skp-text-light" onClick={() => setShow(false)}>
            <i className="las la-times"></i>
          </div>
          <h5 className="skp-text-primary">{t('common.create')}</h5>
          <ul className="container">
            {(canCreate || canCreateCompetition) && <li
              className="columns"
              onClick={() =>
                props.dropdownCallback(
                  "newCompetition"
                )
              }
            >
              <span className="column col-3">
                <span className="icon-wrapper">
                  <i className="las la-medal skp-text-xxlarge"></i>
                </span>
              </span>
              <span className="column col-9">
                <div className="skp-text-primary">{t('common.competition')}</div>
                <div className="skp-text-light skp-text-small">
                  {t('header.createCompetition')}
                </div>
              </span>
            </li>}

            {(canCreate || canCreateTournament) && <li
              className="columns"
              onClick={() =>
                props.dropdownCallback(
                  "newTournament"
                )
              }
            >
              <span className="column col-3">
                <span className="icon-wrapper">
                  <i className="skp-icon-tournaments"></i>
                </span>
              </span>
              <span className="column col-9">
                <div className="skp-text-primary">{t('header.tournament')}</div>
                <div className="skp-text-light skp-text-small">
                  {t('header.createTournament')}
                </div>
              </span>
            </li>}
            
            <li
              className="columns"
              onClick={() =>
                props.dropdownCallback(
                  "newTeam"
                )
              }
            >
              <span className="column col-3">
                <span className="icon-wrapper">
                  <i className="skp-icon-teams"></i>
                </span>
              </span>
              <span className="column col-9">
                <div className="skp-text-primary">{t('header.team')}</div>
                <div className="skp-text-light skp-text-small">
                  {t('header.createTeam')}
                </div>
              </span>
            </li>

            {(canCreate || canCreateTournament) && <li
              className="columns"
              onClick={() =>
                props.dropdownCallback(
                  "newBrand"
                )
              }
            >
              <span className="column col-3">
                <span className="icon-wrapper">
                  <i className="skp-icon-teams"></i>
                </span>
              </span>
              <span className="column col-9">
                <div className="skp-text-primary">{t('header.brand')}</div>
                <div className="skp-text-light skp-text-small">
                  {t('header.createBrand')}
                </div>
              </span>
            </li>}
          </ul>
        </Dropdown>
      </OutsideClickHandler>
    );
}

export default CreateDropdown