import { Form, Formik } from "formik";
import GameAccountsControl from "./GameAccountsControl";
import * as Yup from "yup";
import { useState, useContext } from "react";
import UserService from "../../../services/UserService";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
import GameAccount from "../../Interfaces/GameAccount";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  gameAccounts: Yup.array().of(
    Yup.object().shape({
      username: Yup.string().required("Required!"),
      game: Yup.string().required("Required!"),
      console: Yup.string().required("Required!"),
    })
  ),
});

type FormModel = {
  gameAccounts: Array<GameAccount>
}

const GameAccountsModal = () => {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation()
  const [gameAccounts, setGameAccounts] = useState<Array<GameAccount>>(user?.gameAccounts || []);
  const [loading, setLoading] = useState(false);

  const initialValues:FormModel = {
    gameAccounts: gameAccounts,
  };

  const onSubmit = (values:FormModel) => {
    setLoading(true);
    const userId = user?.id;
    UserService.updateUserInfo(values, userId)
      .then((user) => {
        setLoading(false);
        setUser(user);
        toast.success(t('toast.userInfoUpdate'));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const addGameAccount = () => {
    setGameAccounts([
      ...gameAccounts,
      {
        game: undefined,
        console: undefined,
        username: undefined,
        id: undefined,
      },
    ]);
  };

  const removeGameAccount = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.stopPropagation();
    event.preventDefault();
    let newArray = [...gameAccounts];
    newArray.splice(index, 1);
    setGameAccounts(newArray);
  };

  return (
    <>
      <div className="container">
        <div className="columns">
          <div className="column col-12">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ values, isValid }) => (
                <Form>
                  <GameAccountsControl
                    values={values}
                    gameAccounts={gameAccounts}
                    removeGameAccount={removeGameAccount}
                    addGameAccount={addGameAccount}
                  />
                  <div className="space-divider"></div>
                  <button
                    type="submit"
                    disabled={!isValid || loading}
                    className="skp-btn skp-btn-primary float-right"
                  >
                    {loading ? <span className="loading"></span> : t('common.save')}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameAccountsModal;
