import { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import UserService from "../../../services/UserService";
import UserProfile from "../UserProfile";
import { useTranslation } from "react-i18next";

const TournamentUsersTypeahead = (props) => {
  const { tournamentMembers } = props;
  const { t } = useTranslation()
  const [searchText] = useState();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setLoading(true);
    UserService.getUserByName(e.target.value).then((users) => {
      let filtered;
      filtered = users.filter((user) =>
        tournamentMembers.includes(user.id)
      );

      setUsers(filtered);
      setLoading(false);
    });
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <div className="skp-form-control">
            <DebounceInput
              value={searchText}
              type="text"
              minLength={3}
              debounceTimeout={500}
              placeholder={t('modals.searchByUser')}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>

        <div className="column col-12">
          <h1 className="skp-text-label skp-text-light">
            {t('modals.typeToSearchUser')}
          </h1>
          {loading && <div className="loading"></div>}
          {!loading &&
            users?.map((user) => (
              <div onClick={() => props.onSelect(user)}>
                <UserProfile userId={user?.id}></UserProfile>
              </div>
            ))}
          <div className="space-divider-20"></div>
        </div>
      </div>
    </div>
  );
};

export default TournamentUsersTypeahead;
