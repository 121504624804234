import ITournament from "../Interfaces/Tournament";


type Props = {
  tournament: ITournament;
  children?: JSX.Element;
  style?: React.CSSProperties
};

const TournamentImageBanner = (props: Props) => {
  const { tournament, style } = props;
  let defaultGameBanner = tournament?.game;

  return (
      <div
        className={`skp-image-banner tournament-image-banner ${defaultGameBanner}`} style={style}
      >
        {tournament?.banner && <img alt="banner" src={tournament?.banner} />}
        {props.children}

        <div className="transparent-black"></div>
      </div>
  );
};

export default TournamentImageBanner;
