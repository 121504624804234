import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

type Props = {
  label?: string
  name?: string
}

const TextArea = (props:Props) => {
    const { label, name, ...rest} = props

    if(!name) {
      return null
    }

    return (
      <div className="skp-form-control">
        <label className="skp-text-light skp-text-small" htmlFor={name}>
          {label}
        </label>
        <Field as="textarea" id={name} name={name} {...rest} />
        <ErrorMessage name={name} component={TextError} />
      </div>
    );
}

export default TextArea
