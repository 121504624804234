import React from "react";
import { Formik, Form } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import * as Yup from "yup";
import UploadImageBox from "../../../UiLibrary/UploadImageBox/UploadImageBox";
import ITournament from "../../../Interfaces/Tournament";
import IFileBase64Image from "../../../Interfaces/FileBase64Image";
import { useTranslation } from "react-i18next";

type Props = {
  loading?: boolean;
  tournament: ITournament
  onSubmit: (data: any, imageFile?:IFileBase64Image, type?:string) => void
}

const validationSchema = Yup.object({
  banner: Yup.string().url(),
  thumbnail: Yup.string().url(),
});

const Appearance = (props:Props) => {

  const { loading, tournament } = props;
  const { t } = useTranslation()

  const onSubmit = (values:any) => {
    props.onSubmit(values);
  };

  const uploadBannerImage = (image:IFileBase64Image) => {
    props.onSubmit({}, image, 'banner');
  };

  const uploadThumbImage = (image:IFileBase64Image) => {
    props.onSubmit({}, image, "thumbnail");
  };

  const initialValues = {
    banner: tournament?.banner,
    thumbnail: tournament?.thumbnail,
  };

  return (
    <div className="container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ isValid }) => (
          <Form>
            {/* Banner */}
            <div className="column col-12">
              <h5 className="skp-text-primary">Banner</h5>
            </div>
            <div
              className="column col-12"
              style={{ height: 150, border: "2px dashed var(--gray-1)" }}
            >
              <UploadImageBox onUpload={uploadBannerImage} onRemovePreview={() => undefined} />
            </div>
            <div className="space-divider-5"></div>
            <div className="column col-12">
              <FormikControl
                control="input"
                label={t('tournament.config.useImageUrl')}
                name="banner"
              />
            </div>
            <div className="space-divider-20"></div>
            {/* Thumbnail */}
            <div className="column col-12">
              <h5 className="skp-text-primary">Thumbnail</h5>
            </div>
            <div
              className="column col-12"
              style={{ height: 150, border: "2px dashed var(--gray-1)" }}
            >
              <UploadImageBox onUpload={uploadThumbImage} onRemovePreview={() => undefined}/>
            </div>
            <div className="column col-12">
              <FormikControl
                control="input"
                label={t('tournament.config.useImageUrl')}
                name="thumbnail"
              />
            </div>
            <div className="space-divider"></div>
            <div className="column col-12 text-right">
              <button
                type="submit"
                disabled={loading || !isValid}
                className="skp-btn skp-btn-primary"
              >
                {loading ? <span className="loading"></span> : t('common.save')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Appearance;
