import React from 'react'

function YuniteTournamentPlatform(props) {
    const { platform } = props;
    switch (platform) {
      case "PC":
        return <i className="las la-desktop"></i>;
      case "PS4":
        return <i className="lab la-playstation"></i>;
      case "XB1":
        return <i className="lab la-xbox"></i>;
      case "MOBILE":
        return <i className="las la-mobile"></i>;
      case "SWITCH":
        return <i className="switch_icon"></i>;
      default:
        return <span>{platform}</span>;
    }
}

export default YuniteTournamentPlatform
