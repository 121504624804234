import React, { useContext, useEffect, useState } from "react";
import Marquee from "react-text-marquee";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import YuniteOverviewSide from "../YuniteTournamentTabs/YuniteOverviewSide";
import YuniteTournamentService from "../../../../services/YuniteTournamentService";
import UserProfile from "../../../UiLibrary/UserProfile";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentYunite from "../../../Interfaces/TournamentYunite";
import { UserContext } from "../../../Context/UserContext";
import KeyCloackService from "../../../../services/KeyCloackService";
import AddAdminModal from "../../../Modals/AddAdminModal/AddAdminModal";
import GAMES from "../../../../constants-games";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined;
  keyValue: string;
  title: string;
  loadTournament: (tournament: ITournament | undefined) => void;
  isDynamic?: boolean;
};

function OverviewTab(props: Props) {
  const { tournament, keyValue, title, isDynamic = false } = props;
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  let navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const tournamentId = params.id;

  const isTournamentAdmin = tournament?.admins?.includes(user?.id || "");
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

  let text;
  if (tournament && isDynamic) {
    console.log("isDynamic")
    console.log(tournament.genericTabs)
    const dynamicTab = tournament.genericTabs?.find(tab => tab.key === keyValue);
    text = dynamicTab ? dynamicTab.value : "";
  } else if (tournament) {
    // Comportamento originale se isDynamic è false o non passato
    text = Array.isArray(tournament[keyValue])
      ? tournament[keyValue][0]
      : tournament[keyValue];
  }

  const [yuniteTournament, setYuniteTournament] = useState<ITournamentYunite>();
  const goToPlayersTab = () => {
    navigate(`/tournaments/${tournamentId}/players`);
  };
  const check_in = tournament?.check_in
    ? tournament?.check_in : 0
  const check_in_end = tournament?.check_in_end 
    ? tournament?.check_in_end : 0

  useEffect(() => {
    if (tournament?.yuniteId) {
      YuniteTournamentService.getTournament(tournament?.yuniteId).then(
        (response) => {
          let data = response;
          data.game = GAMES.FORTNITE.NAME;
          data.members = tournament?.members;
          data.check_in = tournament?.check_in;
          setYuniteTournament(data);
        }
      );
    }

    return () => {
      setYuniteTournament(undefined);
    };
  }, [tournament]);

  if (!tournament) {
    return null 
  }

  return (
    <div className="column col-12">
      <div className="space-divider-20"></div>
      <div className="columns">
        <div className="column col-8 col-xs-12">
          <h5 className="skp-text-primary">{title ? title : t('titles.information')}</h5>
          <div className="columns">
            <div className="column col-12">
              <div
                className="skp-text-primary"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></div>
            </div>
          </div>
        </div>
        {tournament?.yuniteId && (
          <div className="column col-4 col-xs-12 skp-text-light-primary">
            <YuniteOverviewSide
              tournament={yuniteTournament}
            ></YuniteOverviewSide>
          </div>
        )}
        {!tournament?.yuniteId && (
          <div className="column col-4 col-xs-12 skp-text-light-primary">
            {/* PLATFORM BOX */}
            <h5 className="skp-text-primary">{t('tournament.config.platforms')}</h5>
            {tournament?.console.map((platform) => (
              <span
                className="skp-text-light m-1 tooltip"
                data-tooltip={platform}
                key={`platform-${platform}`}
              >
                <i className={`la-${platform}`} style={{ fontSize: 22 }}></i>
              </span>
            ))}

            {/* Players Box */}
            <div className="space-divider-20"></div>
            <h5 className="skp-text-primary">{t('common.players')}</h5>
            <div
              className="columns border-box players-box p-2"
              onClick={goToPlayersTab}
            >
              <div className="column col-6 text-center">
                <span className="skp-text-xxsmall skp-text-light">
                  <Marquee text={t('sidePanel.registered')} />
                </span>
                <div className="skp-text-primary">
                  {user ? tournament?.teams.length : "#" }
                </div>
              </div>
              {/* <div className="column col-4 text-center">
                  <span className="skp-text-xxsmall skp-text-light">
                    Confirmed
                  </span>
                  <div className="skp-text-primary">0</div>
                </div> */}
              <div className="column col-6 text-center">
                <span className="skp-text-xxsmall skp-text-light">
                  <Marquee text={t('sidePanel.slots')} />
                </span>
                <div className="skp-text-primary">
                  {tournament?.maxPartecipants}
                </div>
              </div>
            </div>
            {/* END Players Box */}
            <div className="space-divider-20"></div>
            <h5 className="skp-text-primary">{t('tournament.format')}</h5>
            <div className="columns">
              <div className="column col-12">
                <div className="skp-text-primary skp-text-small">
                  {t('common.game')}: {GAMES[tournament?.game].NAME}
                </div>
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <div className="skp-text-primary skp-text-small">
                  {t('tournament.config.teamSize')}:{" "}
                  {GAMES[tournament?.game].TEAM_SIZES_MAP?.[tournament?.teamSize]}
                </div>
              </div>
              {tournament?.matchType && (
                <>
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <div className="skp-text-primary skp-text-small">
                  {t('tournament.config.matchType')}:{" "}
                  {GAMES[tournament?.game].TEAM_MATCH_TYPE_MAP?.[tournament?.matchType]}
                </div>
              </div>
                </>
              )}
              {tournament?.tournamentType && tournament.tournamentType === 'lobby' && (
                <>
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <div className="skp-text-primary skp-text-small">
                      {t('tournament.config.battleRoyaleType')}
                    </div>
                  </div>
                </>
              )}
              {(tournament.checkin === undefined || tournament.checkin) && (
                <>
                  <div className="space-divider-20"></div>
                  <div className="column col-12">
                    <div className="skp-text-primary skp-text-small">
                      {t('tournament.config.checkinBegins')}:{" "}
                  <Marquee text={`${check_in} ` + t('tournament.config.minutesBeforeStart')} />
                </div>
              </div>
                  <div className="space-divider-20"></div>
                  <div className="column col-12">
                    <div className="skp-text-primary skp-text-small">
                      {t('tournament.config.checkinEnds')}:{" "}
                      <Marquee text={`${check_in_end} ` + t('tournament.config.minutesBeforeStart')} />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="space-divider-20"></div>
            <h5 className="skp-text-primary">{t('common.admins')}</h5>
            {isAdmin && (
              <AddAdminModal
                tournament={tournament}
                loadTournament={props.loadTournament}
              />
            )}
            <dl>
              <dd>
                <UserProfile userId={tournament?.owner}>
                  <span className="skp-chip skp-text-xxsmall status-captain">
                    {t('common.owner')}
                  </span>
                </UserProfile>
              </dd>
              {tournament?.admins?.map((admin,index) => (
                <dd key={index}>
                  <UserProfile userId={admin}>
                    <span className="skp-chip skp-text-xxsmall status-captain">
                      {t('common.admin')}
                    </span>
                  </UserProfile>
                </dd>
              ))}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}

export default OverviewTab;
