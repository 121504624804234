import { useState } from "react";
import Marquee from "react-text-marquee";
import Modal from "../Modal";
import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import UserProfile from "../UserProfile";

const MatchTeamName = (props) => {
  const { teamId, tournament, reverse, hideTeamLabel, hideNameClick } = props;
  const [showModal, setShowModal] = useState(false);

  const team = tournament?.teams.find((element) => element.partyId === teamId);
  const title = team?.partyName ? team.partyName : `Team`;

  const onTeamNameClick = (e) => {
    if(hideNameClick) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  return (
    <div style={{ width: "100%"}}>
      {team?.partyName && (
        <UserProfile hideName={true} userId={team?.leader} reverse={reverse}>
          <span
            className="skp-link-hover text-truncate p-2"
            onClick={(e) => onTeamNameClick(e)}
          >
            <Marquee text={team.partyName}></Marquee>
          </span>
          {!hideTeamLabel && (
            <span className="skp-chip skp-text-label">Team</span>
          )}
          {props.children}
        </UserProfile>
      )}

      {!team?.partyName && (
        <>
          <UserProfile
            showTeamLabel={true}
            userId={team?.leader}
            reverse={reverse}
            onUserNameClick={(e) => onTeamNameClick(e)}
          >
            <span>{props.children}</span>
          </UserProfile>
        </>
      )}

      <Modal show={showModal} onClose={() => setShowModal(false)} title={title} size="small">
        <div className="container">
          <dl className="columns">
            {team && team?.members.map((member,index) => (
              <dd className="column col-12 m-2" key={index}>
                <UserProfileWithDetailsModal
                  userId={member.userId}
                  tournament={tournament}
                >
                  {team?.leader === member.userId && <span className="skp-chip status-captain">Leader</span>}
                </UserProfileWithDetailsModal>
              </dd>
            ))}
          </dl>
        </div>
      </Modal>
    </div>
  );
};

export default MatchTeamName;
