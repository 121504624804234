import React, { useState, useEffect, useContext } from "react";
import Match from "../../UiLibrary/MatchComponents/Match";
import MatchDetailsModal from "../MatchDetailsModal/MatchDetailsModal";
import Modal from "../../UiLibrary/Modal";
import MatchService from "../../../services/MatchService";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITournament from "../../Interfaces/Tournament";
import IMatch from "../../Interfaces/Match";
import IMatchTournamentSeed from "../../Interfaces/MatchTournamentSeed";
import MatchResultService from "../../../services/MatchResultService";
import { TournamentMatchesContext } from "../../Context/TournamentMatchesContext";
import EMatchStatus from "../../../enums/MatchStatus";
import { UserContext } from "../../Context/UserContext";
import { INotificationElement } from "../../Interfaces/notification.interface";
import { NotificationContext } from "../../Context/NotificationContext";

type Props = {
  tournament: ITournament | undefined;
  match: IMatchTournamentSeed;
  updateNotification?: (theNotification:INotificationElement) => void;
};

const MultiMatchDetailsModal = (props: Props) => {
  const { match, tournament, updateNotification } = props;
  const {tournamentMatches} = useContext(TournamentMatchesContext)
  const { user } = useContext(UserContext);
  const { tournamentNotification, setTournamentNotification } = useContext(NotificationContext);
  const isTournamentAdmin: boolean = tournament?.admins?.includes(user?.id!)!;
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;
  const [showModal, setShowModal] = useState<{
    show: boolean;
    match?: any;
    matchId?: string;
    id?: number;
  }>({
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState<Array<IMatch>>([]);
  const [multipleMatchStatus, setMultipleMatchStatus] = useState(MatchResultService.getMultipleMatchStatus(tournamentMatches?.matches, match))
  const [multipleMatchResult, setMultipleMatchResult] = useState(MatchResultService.getMultipleMatchWinner(tournamentMatches?.matches, match));
  const [results, setResults] = useState<Array<number>>([])

  useEffect(() => {
    MatchService.getMatches(match?.matchesId).then((Rmatches) => {
      setMatches(Rmatches);
      setMultipleMatchStatus(MatchResultService.getMultipleMatchStatusNoSeed(Rmatches))
      setMultipleMatchResult(MatchResultService.getMultipleMatchWinnerNoSeed(Rmatches));
      setResults(MatchResultService.getMatchsReports(Rmatches, tournamentNotification))
      setLoading(false);
    });
    return () => {
      setMatches([]);
    };
  }, [match]);

  useEffect(()=>{
    if( tournamentNotification )
      setResults(MatchResultService.getMatchsReports(matches, tournamentNotification))
  }, [tournamentNotification])

  const reloadMatchList = () => {
    setShowModal({ show: false });
    setLoading(true);
    MatchService.getMatches(match?.matchesId).then((Rmatches) => {
      setMultipleMatchStatus(MatchResultService.getMultipleMatchStatusNoSeed(Rmatches))
      setMultipleMatchResult(MatchResultService.getMultipleMatchWinnerNoSeed(Rmatches));
      setResults(MatchResultService.getMatchsReports(Rmatches, tournamentNotification))
      setMatches(Rmatches);
      setLoading(false);
    }).catch((error) => {
      console.log(error)
    });
    return () => {
      setMatches([]);
    };
  };

  const openSingleMatch = (matchId: string, index: number) => {
    if (!matchId || index === undefined) {
      return;
    }
    setShowModal({
      show: true,
      matchId: matchId,
      match: match,
      id: index,
    });
  };

  return (
    <div className="container">
      <div className="columns">
      <div className="column col-12 text-center">
          <div className={`skp-chip status-${multipleMatchStatus}`}>
            {EMatchStatus[multipleMatchStatus]}
          </div>
        </div>

        <div className="column col-12 text-center skp-text-light">
          Best of {match.matchesId.length}
        </div>

        <div className="column col-12 skp-text-light">
          <div className="columns">
            <div className="column col-5 flex-centered">{multipleMatchResult.teamHomeScores.length}</div>
            <div className="column col-2 flex-centered">:</div>
            <div className="column col-5 flex-centered">{multipleMatchResult.teamAwayScores.length}</div>
          </div>
        </div>

        <div className="column col-12">
          {!showModal.show && (
            <dl className="bordered-list">
              {loading && (
                <LoadingPlaceholders numberOfItems={tournament?.matchType}>
                  <dd className="row-loading" style={{ height: "102px" }}></dd>
                </LoadingPlaceholders>
              )}
              {!loading &&
                tournament &&
                match?.matchesId.map((matchId, index) => (
                  <dd
                    key={matchId}
                    onClick={() => openSingleMatch(matchId, index)}
                    className={
                      (matches[index - 1] && matches[index - 1].status !== 3) ||
                      (multipleMatchResult.winner && matches[index] && matches[index].status !== 3)
                        ? "disabled"
                        : ""
                    }
                  >
                  {(isAdmin || 
                    ( matches[index].result.scores[0]?.playerResults && matches[index].result.scores[0]?.playerResults[0]?.playerId === user?.id ) || 
                    ( matches[index].result.scores[1]?.playerResults && matches[index].result.scores[1]?.playerResults[0]?.playerId === user?.id ) ) && (
                    <a style={{top:'24px', left:'53%'}}
                      className={
                        results[index] > 0 ? "badge column" : ""
                      }
                      data-badge={Number(results[index] || 0)}
                    > </a>
                  )}
                  <Match
                    matchId={matchId}
                    showStatus={true}
                    gameId={index}
                    tournament={tournament}
                  />
                </dd>
                ))}
            </dl>
          )}
        </div>
      </div>

      <Modal
        show={showModal.show}
        onClose={() => reloadMatchList()}
        title={`Game ${showModal?.id ? showModal.id + 1 : ""}`}
      >
        <>
          {/* Single Match */}
          {showModal.matchId && tournament && (
            <MatchDetailsModal
              tournament={tournament}
              matchId={showModal.matchId}
              closeModal={reloadMatchList}
              updateNotification={updateNotification}
            />
          )}
        </>
      </Modal>
    </div>
  );
};

export default MultiMatchDetailsModal;
