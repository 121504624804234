import React from 'react';


const Dropdown = (props) => {
    if(!props.show) {
        return null;
    }

    return (
        <div className="skp-dropdown">
            {props.children}
        </div>   
    )
}

export default Dropdown