import React, { useEffect, useState } from "react";
import UserProfile from "../UserProfile";
import Dropdown from "../Dropdown";
import TournamentUsersTypeahead from "../Typeahead/TournamentUsersTypeahead";
import OutsideClickHandler from "react-outside-click-handler";
import MatchService from "../../../services/MatchService";
import { toast } from "react-toastify";
import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import ITournament from "../../Interfaces/Tournament";
import IMatch from "../../Interfaces/Match";
import IMatchScore from "../../Interfaces/MatchScore";
import { useTranslation } from "react-i18next";

type Props = {
  match: IMatch
  tournament: ITournament
  onSetMatch: (match: IMatch) => void
}

const MatchChangePlayer = (props:Props) => {
  const { match, tournament } = props;
  const { t } = useTranslation()
  const [newData, setData] = useState<Array<IMatchScore>>([]);
  const [loading, setLoading] = useState(false);
  const [showTypeahead, setShowTypeahead] = useState([
    { show: false },
    { show: false },
  ]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (match) {
      let tempState = [...match?.result.scores];
      setData(tempState);
    }
  }, [match]);

  const setPlayer = (player:{id:string}, index:number) => {
    let temp_state = [...newData];
    let temp_element = { ...temp_state[index] };
    temp_element.teamId = player.id ? player.id : "1";
    temp_element.playerResults[0].playerId = player.id ? player.id : "1";
    temp_state[index] = temp_element;

    setData(temp_state);
    setShowTypeahead([{ show: false }, { show: false }]);
    setShow(false);
  };

  const removeUser = (index:number) => {
    let temp_state = [...newData];
    let temp_element = { ...temp_state[index] };

    temp_element.teamId = "1";
    temp_element.playerResults[0].playerId = "1";

    temp_state[index] = temp_element;

    setData(temp_state);
    let typeaheads = [...showTypeahead];
    let typeahead = { ...typeaheads[index] };
    typeahead.show = true;
    typeaheads[index] = typeahead;
    setShowTypeahead(typeaheads);
    setShow(true);
  };

  const savePlayers = () => {
    setLoading(true);
    const data = { result: { scores: newData } };
    MatchService.updateMatch(match?.id, data)
      .then((Rmatch) => {
        props.onSetMatch(Rmatch);
        toast.info(t('toast.playersSavedSuccess'));
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      {newData &&
        newData?.map((score, index) => (
          <div className="container" key={index}>
            <div className="columns">
              <div className="column col-5 col-xs-12">
                <div className="columns">
                  <div className="column col-12 text-right text-left-xs">
                    {!loading &&  (
                      <UserProfileWithDetailsModal
                        key={index}
                        reverse={true}
                        userId={match?.result.scores[index].playerResults[0].playerId}
                        tournament={tournament}
                    />
                    )}
                  </div>
                </div>
              </div>
              <div className="column col-2 flex-centered skp-text-light hide-xs">
                <i className="las la-exchange-alt"></i>
              </div>
              <div className="column col-5 col-xs-12">
                <div className="columns">
                  <div className="column col-12 text-left">
                    {!showTypeahead[index].show &&(
                      <UserProfileWithDetailsModal
                        userId={score.playerResults[0].playerId}
                        tournament={tournament}
                    >
                        {score.teamId !== "1" && (
                            <span
                              className="skp-link-primary skp-text-xlarge"
                              onClick={() => removeUser(index)}
                            >
                              <i className="las la-trash"></i>
                            </span>
                          )}

                        {score.teamId === "1" && (
                          <span
                            className="skp-link-primary"
                            onClick={() => removeUser(index)}
                          >
                            {" "}
                            <i className="las la-plus"></i> {t('matches.addNewPlayer')}
                          </span>
                        )}
                      </UserProfileWithDetailsModal>
                    )}

                    {showTypeahead[index].show && (
                      <>
                        <UserProfile userId="1">
                          <span
                            className="skp-link-primary"
                            onClick={() => setShow(true)}
                          >
                            {" "}
                            <i className="las la-plus"></i> {t('matches.addNewPlayer')}
                          </span>
                        </UserProfile>

                        <Dropdown show={show}>
                          <OutsideClickHandler
                            onOutsideClick={() =>
                              setShowTypeahead([
                                { show: false },
                                { show: false },
                              ])
                            }
                          >
                            <TournamentUsersTypeahead
                              tournamentMembers={tournament?.members}
                              onSelect={(player:{id:string}) => setPlayer(player, index)}
                            />
                          </OutsideClickHandler>
                        </Dropdown>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="space-divider-20"></div>
          </div>
        ))}
      <div className="container">
        <div className="columns">
          <div className="column col-12 text-center">
            {(newData[0]?.teamId !== match?.result?.scores[0].teamId ||
              newData[1]?.teamId !== match?.result?.scores[1].teamId) &&
              match?.result?.scores[0].playerType === 0 && (
                <button
                  className="skp-btn skp-btn-primary"
                  disabled={loading}
                  onClick={savePlayers}
                >
                  {t('matches.savePlayers')}
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchChangePlayer;
