import React from 'react'
import { Field, ErrorMessage, FormikTouched, FormikValues, FormikErrors } from 'formik'
import TextError from './TextError'

type Props = {
  label?: string
  name?: string
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
}

function Input(props:Props) {
    const { label, name, touched, errors,  ...rest } = props
    
    if(!name) {
      return null;
    }

    return (
      <div
        className={
          (errors && errors[name]) && (touched && touched[name])
            ? "skp-form-control skp-form-control-error"
            : "skp-form-control"
        }
      >
        <label className="skp-text-light skp-text-small" htmlFor={name}>
          {label}
        </label>
        <Field id={name} name={name} {...rest}/>
        <ErrorMessage name={name} component={TextError} />
      </div>
    );
}

export default Input
