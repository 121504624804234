import React, { useCallback, useContext, useEffect, useState } from "react";
import IMatchTournamentSeed from "../../Interfaces/MatchTournamentSeed";
import EMatchStatus from "../../../enums/MatchStatus";
import MatchService from "../../../services/MatchService";
import IMatch from "../../Interfaces/Match";
import MatchResultService from "../../../services/MatchResultService";
import { EventsContext } from "../../Context/EventsContext";
import { NotificationContext } from "../../Context/NotificationContext";

type Props = {
  seed: IMatchTournamentSeed;
  seedId: number;
  matchId: string;
  showCompleted: boolean;
  onSetMatch: (matchSeed: IMatchTournamentSeed) => void;
};

const MultiMatchCard = (props: Props) => {
  const { seed, seedId, showCompleted } = props;
  const [singleMatch, setMatch] = useState<IMatch>();
  const [multipleMatchStatus, setMultipleMatchStatus] = useState(0);
  const [multipleMatchReport, setMultipleMatchReport] = useState(0);
  const [matches, setMatches] = useState<Array<IMatch>>([]);

  const { tournamentNotification } = useContext(NotificationContext);
  const { socket, isConnected } = useContext(EventsContext);

  const fetchMatches = useCallback(async () => {
    if (!seed.matchesId[0]) return;

    try {
      const [Rmatch, Rmatches] = await Promise.all([
        MatchService.getMatchDetails(seed.matchesId[0]),
        MatchService.getMatches(seed.matchesId)
      ]);

      setMatch(Rmatch);
      setMatches(Rmatches);
      setMultipleMatchStatus(MatchResultService.getMultipleMatchStatusNoSeed(Rmatches));
      setMultipleMatchReport(MatchResultService.getMultipleMatchReportsNoSeed(Rmatches, tournamentNotification));
    } catch (error) {
      console.error("Errore durante il recupero dei match:", error);
    }
  }, [seed.matchesId, tournamentNotification]);

  useEffect(() => {
    fetchMatches();

    if (isConnected && socket) {
      socket.on(seed.matchesId[0], fetchMatches);

      return () => {
        socket.off(seed.matchesId[0], fetchMatches);
      };
    }
  }, [fetchMatches, isConnected, socket, seed.matchesId]);


  const textStatus = () => {
    switch (singleMatch?.status) {
      case 0:
        return "skp-text-light";
      case 1:
        return "skp-text-accent";
      case 2:
        return "skp-text-secondary";
      case 3:
        return "skp-text-ternary";
      default:
        return "skp-text-light";
    }
  };

  const viewDetails = (seed: IMatchTournamentSeed) => {
    props.onSetMatch(seed);
  };

  return (
    <>
      {(multipleMatchStatus !== 3 || showCompleted) && (
        <div className="admin-match-tile" onClick={() => viewDetails(seed)}>
          <div className="skp-text-default skp-text-primary column col-12 text-center">
            Match {seedId}
            <a
              className={multipleMatchReport > 0 ? "badge column" : ""}
              data-badge={Number(multipleMatchReport || 0)}
            ></a>
          </div>
          <div className="column col-12 text-center">
            <div className={`skp-chip status-${multipleMatchStatus}`}>
              {EMatchStatus[multipleMatchStatus]}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiMatchCard;
