import React, { createContext, useEffect, useState, ReactNode } from "react";
import { io, Socket } from "socket.io-client";
import constant from "../../constants";

// Definisci l'interfaccia per il contesto
interface SocketContextProps {
  socket: Socket | null;
  isConnected: boolean;
}

// Crea il contesto con valori predefiniti
export const EventsContext = createContext<SocketContextProps>({
  socket: null,
  isConnected: false,
});

interface EventsProviderProps {
  children: ReactNode; // Assicura che il provider accetti ReactNode come prop children
}

// Crea il provider del contesto
export const EventsProvider: React.FC<EventsProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    // Crea una nuova connessione socket con riconnessione automatica
    const newSocket = io(`${constant.APP_URL}/events`, {
      reconnection: true, // Assicura che la riconnessione sia abilitata
      reconnectionAttempts: Infinity, // Numero infinito di tentativi di riconnessione
      reconnectionDelay: 1000, // Ritardo iniziale tra i tentativi di riconnessione
      reconnectionDelayMax: 5000, // Ritardo massimo tra i tentativi di riconnessione
      timeout: 20000, // Timeout prima di considerare una connessione fallita
    });

    const handleConnect = () => {
      setIsConnected(true);
      console.log("Socket connected");
    };

    const handleDisconnect = () => {
      setIsConnected(false);
      console.log("Socket disconnected");
    };

    const handleReconnect = (attemptNumber: number) => {
      console.log(`Socket reconnected after ${attemptNumber} attempts`);
    };

    const handleReconnectAttempt = (attemptNumber: number) => {
      console.log(`Attempting to reconnect... Attempt #${attemptNumber}`);
    };

    const handleError = (error: any) => {
      console.error("Socket error:", error);
      setIsConnected(false);
    };

    // Registra i listener per il socket
    newSocket.on("connect", handleConnect);
    newSocket.on("disconnect", handleDisconnect);
    newSocket.on("reconnect", handleReconnect);
    newSocket.on("reconnect_attempt", handleReconnectAttempt);
    newSocket.on("connect_error", handleError);
    newSocket.on("reconnect_failed", handleError);
    newSocket.on("connect_timeout", handleError);

    // Salva il socket nello stato
    setSocket(newSocket);

    // Cleanup quando il componente viene smontato
    return () => {
      newSocket.off("connect", handleConnect);
      newSocket.off("disconnect", handleDisconnect);
      newSocket.off("reconnect", handleReconnect);
      newSocket.off("reconnect_attempt", handleReconnectAttempt);
      newSocket.off("connect_error", handleError);
      newSocket.off("reconnect_failed", handleError);
      newSocket.off("connect_timeout", handleError);
      newSocket.disconnect();
    };
  }, []);

  return (
    <EventsContext.Provider value={{ socket, isConnected }}>
      {children}
    </EventsContext.Provider>
  );
};
