import Scrollbars from "react-custom-scrollbars";
import Footer from "../../UiLibrary/Footer";

const PrivacyPolicy = () => {
  return (
    <Scrollbars renderView={(props) => <div {...props} id="scrollableDiv" />}>
      <div className="skp-full-page">
        <div className="containers">
          <div className="columns">
            <div className="column col-12">
              <h2 className="skp-text-primary">Privacy Policy</h2>
            </div>
          </div>
          <div className="columns skp-text-light">
            <div className="column col-12">
              INFORMATIVA RESA AI SENSI DEGLI ART. 13 DEL GDPR (GENERAL DATA
              PROTECTION REGULATION) 2016/679 ED AI SENSI DELL’ART.13 DEL D.LG.
              N. 196/2003 - CODICE IN MATERIA DI PROTEZIONE DEI DATI PERSONALI.
              <br></br>
              <ol>
                <li>
                  <b className="skp-text-primary">Introduzione</b>
                  <br></br>
                  Questa informativa sulla privacy spiega come Evotech elabora
                  le informazioni che possono essere utilizzate per identificare
                  direttamente o indirettamente un individuo ("Dati personali")
                  raccolti attraverso l'uso del presente sito web / piattaforma.
                  Tutte le informazioni memorizzate sul sito web di Evotech sono
                  considerate riservate. Tutte le informazioni sono memorizzate
                  in modo sicuro e sono accessibili solo al personale
                  autorizzato. Evotech implementa e mantiene appropriate misure
                  tecniche, di sicurezza e organizzative per proteggere i Dati
                  personali da elaborazioni e utilizzi non autorizzati o
                  illeciti e da perdite accidentali, distruzione,
                  danneggiamento, furto o divulgazione. Evotech rispetta la
                  normativa italiana ed europea in materia di protezione dei
                  dati personali, in particolare il Regolamento generale sulla
                  protezione dei dati ("GDPR"), e il D.lgs. 196/2003 vigente (il
                  “Codice Privacy”).
                </li>

                <li>
                  <b className="skp-text-primary">Titolare del trattamento</b>
                  <br></br>
                  Il presente sito web è di proprietà di Evotech, che è il
                  titolare del trattamento dei dati:
                  Evotech Italia Srl - via della scienza, 50 - 41122 Modena (MO) ITALY.
                </li>

                <li>
                  <b className="skp-text-primary">
                    Luogo di elaborazione dei dati
                  </b>
                  <br></br>I Dati sono trattati presso le sedi operative del
                  Titolare ed in ogni altro luogo in cui le parti coinvolte nel
                  trattamento siano localizzate. Il trattamento dei dati
                  raccolti durante la navigazione del sito web e dei dati
                  forniti dagli utenti registrati alle aree riservate viene
                  effettuato presso gli uffici operativi di Evotech.
                </li>

                <li>
                  <b className="skp-text-primary">
                    Tipologia di dati trattati e finalità
                  </b>
                  <br></br>
                  <b>4.1 Dati di navigazione</b>
                  <br></br>I sistemi informatici e le procedure software
                  preposte al funzionamento di questo sito web acquisiscono, nel
                  corso del loro normale esercizio, alcuni dati personali la cui
                  trasmissione è implicita nell'uso dei protocolli di
                  comunicazione di Internet. Si tratta di informazioni che non
                  sono raccolte per essere associate a interessati identificati,
                  ma che per loro stessa natura potrebbero, attraverso
                  elaborazioni ed associazioni con dati detenuti da terzi,
                  permettere di identificare gli utenti. In questa categoria di
                  dati rientrano gli indirizzi IP o i nomi a dominio dei
                  computer utilizzati dagli utenti che si connettono al sito/
                  piattaforma , gli indirizzi in notazione URI (Uniform Resource
                  Identifier) delle risorse richieste, l'orario della richiesta,
                  il metodo utilizzato nel sottoporre la richiesta al server, la
                  dimensione del file ottenuto in risposta, il codice numerico
                  indicante lo stato della risposta data dal server (buon fine,
                  errore, ecc.) ed altri parametri relativi al sistema operativo
                  e all'ambiente informatico dell'utente. I dati potrebbero
                  essere utilizzati per l'accertamento di responsabilità in caso
                  di ipotetici reati informatici ai danni del sito: salva questa
                  eventualità, allo stato i dati sui contatti web non persistono
                  per più di sette giorni. Evotech può raccogliere, registrare e
                  analizzare le informazioni dei visitatori sul proprio sito web
                  / piattaforma . Potremmo registrare il tuo indirizzo IP ed
                  utilizzare i cookie. Evotech può raccogliere ed elaborare
                  qualsiasi dato personale che fornisci volontariamente nei
                  moduli del sito web / piattaforma, ad esempio quando ti
                  registri per eventi o ti iscrivi per ricevere informazioni e
                  newsletter. Se fornisci ad Evotech i tuoi dettagli sui social
                  media, Evotech potrà recuperare informazioni pubblicamente
                  disponibili su di te dai social media. Tali Dati Personali
                  possono comprendere indirizzo IP, nome e cognome,evntuali ID
                  di gioco, indirizzo postale e e-mail, numero di telefono, dati
                  per i social network, aree di interesse, interesse per i
                  prodotti e servizi di Evotech, nonché informazioni sul tipo di
                  relazione esistente tra Evotech e te stesso. Evotech raccoglie
                  dati sulle visite al sito web, inclusi il numero di visitatori
                  e visite, i dati relativi alla posizione geografica, il tempo
                  trascorso sul sito, le pagine su cui si è fatto clic o da dove
                  siano arrivati ​​i visitatori. 4.2 Finalità del trattamento
                  dei dati personali
                  <br></br>
                  <b>4.2 Finalità del trattamento dei dati personali</b>
                  <br></br>
                  Necessità di raccogliere i Dati Personali: l'Utente comunica
                  ad Evotech alcuni Dati Personali necessari per la fornitura
                  del Servizio richiesto. Se l'Utente non desidera comunicare le
                  informazioni richieste, o si oppone al loro utilizzo da parte
                  di Evotech, è possibile che l'Utente non sia in grado di
                  accedere ad alcune parti del Sito o dei Servizi e che Evotech
                  non sia in grado di soddisfare la sua richiesta. I dati
                  raccolti verranno utilizzati per fare statistiche di gioco,
                  per pagare eventuali compensi ai giocatori e per finalità di
                  marketing. Raccogliamo dati personali quali Nome, Cognome,
                  Età, Email, Sesso, Numero di telefono e li utilizziamo al fine
                  di registrare l'utente nella piattaforma , fare statistiche e
                  per invio di comunicazioni di marketing. Raccogliamo gli id
                  utente di alcuni giochi e video ed immagini che vengono
                  utilizzati
                  <br></br>
                  <b>4.3 Condivisione di dati personali</b>
                  <br></br>
                  Evotech può anche condividere tali informazioni con partner
                  esterni, fornitori di servizi o appaltatori al fine di fornire
                  un servizio o una transazione richiesta o allo scopo di
                  analizzare il comportamento dei visitatori sul proprio sito
                  web.
                  <br></br>
                  <b>4.4 Cookies</b>
                  <br></br>I cookie sono piccole informazioni inviate da un sito
                  web all'hard disk di un visitatore. I cookie non possono
                  essere utilizzati per eseguire programmi o inviare virus al
                  tuo computer. Continuando a visitare il sito web, accetti il
                  ​​posizionamento di cookie sul tuo dispositivo. Se si sceglie
                  di non accettare i nostri cookie, non possiamo garantire che
                  la vostra esperienza sarà soddisfacente come sarebbe
                  altrimenti. Possiamo anche inserire cookie di terze parti per
                  scopi funzionali e di marketing. L'uso dei cookie è diffuso ed
                  avvantaggia il visitatore del sito web. Per ulteriori
                  informazioni, consultare la Politica sui cookie di Evotech.
                  <br></br>
                  <b>4.5 Collegamenti ad altri siti</b>
                  <br></br>
                  Si prega di essere consapevoli del fatto che durante la visita
                  al sito web /piattaforma, gli utenti potrebbero accedere a
                  collegamenti ad altri siti esterni che sono al di fuori della
                  nostra sfera di influenza. Evotech non è quindi responsabile
                  per il contenuto o la politica sulla privacy di questi altri
                  siti.
                  <br></br>
                  <b>4.6 Servizi di terze parti</b>
                  <br></br>
                  Non raccogliamo dati di tool di terze parti, utilizziamo
                  Discord (inserire link servizio e policy) per l'iscrizione a
                  tornei, ma non raccogliamo dati di Discord. Per il social
                  login utilizziamo KeyCloak (inserire link servizio e policy),
                  gli unici dati che andiamo a storicizzare sono i token di
                  autenticazione rilasciati. Utilizziamo i server di Google,
                  allocati in Europa. (inserire link servizio e policy)
                  <br></br>
                  <b>4.7 Come sono protetti i tuoi dati?</b>
                  <br></br>
                  Abbiamo implementato misure tecniche e organizzative per
                  garantire un livello di sicurezza adeguato al rischio
                  presentato dal trattamento ai sensi dell'art. 24 e 32 GDPR.
                  per validare i risultati e verranno poi utilizzati per
                  finalità di marketing.
                </li>
                <li>
                  <b className="skp-text-primary">
                    Periodo di conservazione. Ritenzione e cancellazione
                  </b>
                  <br></br>I dati raccolti verranno conservati per un arco di
                  tempo non superiore al conseguimento delle finalità per le
                  quali sono trattati o in base alle scadenze previste dalle
                  norme di legge. Evotech non conserverà i dati più a lungo del
                  necessario per adempiere agli scopi per cui sono stati
                  raccolti o come richiesto dalle leggi o dai regolamenti
                  applicabili. Per quanto concerne le aree riservate del sito,
                  trascorso il termine previsto,quando l'account di un utente
                  viene chiuso od è scaduto, tutti i dati personali raccolti
                  tramite il sito web verranno eliminati, come richiesto dalla
                  legge vigente.
                </li>
                <li>
                  <b className="skp-text-primary">
                    Modalità di trattamento dei dati
                  </b>
                  <br></br>
                  Il trattamento potrà essere effettuato sia con strumenti
                  manuali e/o informatici e telematici con logiche di
                  organizzazione ed elaborazione strettamente correlate alle
                  finalità stesse e comunque in modo da garantire la sicurezza,
                  l'integrità e la riservatezza dei dati stessi nel rispetto
                  delle misure organizzative, fisiche e logiche previste dalle
                  disposizioni vigenti. Evotech adotta specifiche misure di
                  sicurezza,che sono osservate per prevenire la perdita dei
                  dati, usi illeciti o non corretti ed accessi non autorizzati.
                  Il trattamento dei tuoi dati ha come base giuridica il tuo
                  consenso ed è effettuato per la seguente finalità: migliorare
                  l’esperienza di navigazione web e l’interazione tra il
                  visitatore ed il sito web / piattaforma. Nei limiti pertinenti
                  alle finalità di trattamento indicate, i Suoi dati potranno
                  essere comunicati a partner, società di consulenza, aziende
                  private, nominati Responsabili dal Titolare del Trattamento o
                  per obblighi di legge o per adempiere a vostre specifiche
                  richieste.
                </li>
                <li>
                  <b className="skp-text-primary">Diritti degli interessati</b>
                  <br></br>
                  Come utente, ai sensi del GDPR, hai determinati diritti, vale
                  a dire: Diritto all'informazione: hai il diritto di ricevere
                  informazioni in merito al trattamento dei tuoi dati personali
                  da parte nostra, ai sensi dell'art. 13 e 14 GDPR. Diritto di
                  accesso: hai il diritto di ottenere la conferma che stiamo
                  trattando o meno i tuoi dati personali e, in tal caso,
                  l'accesso ai dati personali, ai sensi dell'art. 15 GDPR.
                  Diritto di rettifica: Hai il diritto di ottenere la rettifica
                  dei dati personali inesatti o incompleti che ti riguardano, ai
                  sensi dell'art. 16 GDPR. Diritto alla cancellazione ("diritto
                  all'oblio"): In determinate circostanze hai il diritto di
                  ottenere la cancellazione dei dati personali che ti
                  riguardano, ai sensi dell'art. 17 GDPR. Diritto alla
                  limitazione: in determinate circostanze hai il diritto di
                  ottenere la limitazione del trattamento, ai sensi dell'art. 18
                  GDPR. Diritto alla portabilità dei dati: in determinate
                  circostanze hai il diritto di ricevere i dati personali che ti
                  riguardano, che ci hai fornito, in un formato strutturato, di
                  uso comune e leggibile da dispositivo automatico e il diritto
                  di trasmettere tali dati a un altro titolare del trattamento,
                  in ai sensi dell'art. 20 GDPR. Diritto di opposizione: In
                  determinate circostanze avete il diritto di opporvi al
                  trattamento dei dati personali che vi riguardano, ai sensi
                  dell'art. 21 GDPR. Ciò include ma non si limita al diritto di
                  opporsi nei casi in cui trattiamo i tuoi dati personali sulla
                  base giuridica dell'art. 6 par. 1 lascia. f) GDPR. Diritto di
                  proporre reclamo: Lei ha il diritto di proporre reclamo ad
                  un'autorità di controllo, in particolare nello Stato membro
                  dell'UE in cui risiede abitualmente, luogo di lavoro o luogo
                  della presunta violazione se ritiene che il trattamento dei
                  dati personali i dati che ti riguardano violano il GDPR (art.
                  77 GDPR). Diritto di revocare il consenso: se un trattamento
                  dei dati personali è basato sul consenso di cui all'art. 6
                  par. 1 lascia. a) GDPR o art. 9 par. 2 lascia. a) GDPR, Lei ha
                  il diritto di revocare il consenso in qualsiasi momento, ai
                  sensi dell'art. 7 par. 3 GDPR. Tale revoca non pregiudica la
                  liceità del trattamento basata sul consenso prima della
                  revoca. Diritto di non essere sottoposto a una decisione
                  basata unicamente sul trattamento automatizzato: Hai il
                  diritto di non essere soggetto a una decisione basata
                  esclusivamente sul trattamento automatizzato, compresa la
                  profilazione, che produca effetti giuridici che ti riguardano
                  o che influisca in modo analogo significativamente su di te,
                  come stabilito dall'art. Arte. 22 comma 1 GDPR.
                </li>
                <li>
                  <b className="skp-text-primary">
                    Difesa ed abusi nell’utilizzo del sito web.
                  </b>
                  <br></br>
                  Evotech potrà utilizzare in giudizio, o nelle fasi ad esso
                  propedeutiche, i dati personali del visitatore o dell’utente,
                  per la difesa da abusi nell'utilizzo di questo sito web o dei
                  servizi connessi da parte dell’Utente. Infine le pubbliche
                  autorità potrebbero richiedere tali dati al Titolare, che sarà
                  obbligato a fornire alle autorità competenti.
                </li>
                <li>
                  <b className="skp-text-primary">
                    Accettazione di queste condizioni
                  </b>
                  <br></br>
                  Partiamo dal presupposto che tutti i visitatori del nostro
                  sito web / piattaforma abbiano letto attentamente questo
                  documento e ne accettino i contenuti. Se qualcuno non è
                  d'accordo con questa politica sulla privacy, dovrebbero
                  astenersi dall'utilizzare il nostro sito web. Ci riserviamo il
                  diritto di modificare la nostra politica sulla privacy secondo
                  le necessità. L'uso continuato del sito web, dopo essere stati
                  informati di tali modifiche a queste condizioni, implica
                  l'accettazione della politica sulla privacy rivista. Se un
                  utente si oppone a una qualsiasi delle modifiche alla
                  Politica, deve interrompere l'utilizzo di questo sito web e
                  può richiedere che il Titolare dati rimuova i suoi dati
                  personali. In caso di dubbi sulla presente privacy policy o su
                  come Evotech gestisce i tuoi dati, ti preghiamo di inviarci
                  una email a: <b className="skp-text-primary">help@skillpro.it</b>
                </li>
                <li>
                  <b className="skp-text-primary">Per ulteriori informazioni</b>
                  <br></br>
                  Se hai ulteriori domande sui dati raccolti da Evotech o su
                  come li utilizziamo, non esitare a contattarci via email
                  all'indirizzo <b className="skp-text-primary">help@skillpro.it</b>. Per le questioni relative
                  alla privacy e alla protezione dei dati puoi contattarci al
                  seguente indirizzo:
                  Evotech Italia Srl - via della scienza, 50 - 41122 Modena (MO) ITALY.
                </li>
              </ol>
            </div>
          </div>
          <div className="space-divider"></div>
          <Footer />
          <div className="space-divider-20"></div>
        </div>
      </div>
    </Scrollbars>
  );
};

export default PrivacyPolicy;
