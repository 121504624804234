import { useEffect, useState } from "react";
import YuniteTournamentService from "../../../../../services/YuniteTournamentService";
import InfiniteScroll from "react-infinite-scroll-component";
import ITournament from "../../../../Interfaces/Tournament";
import ITournamentYuniteTeam from "../../../../Interfaces/TournamentYuniteTeam";
import Scrollbars from "react-custom-scrollbars";
import PlayPlayUser from "../Components/UserComponents/PlayPlayUser";
import GAMES from "../../../../../constants-games";

type Props = {
  tournament: ITournament | undefined;
  // onLogin: () => void
};

const PlayersTab = (props: Props) => {
  const { tournament } = props;
  const [teams, setTeams] = useState<Array<ITournamentYuniteTeam>>([]);
  const [paginatedItems, setPaginatedItems] = useState<Array<string>>([]);
  const [showInfo, setShowInfo] = useState(false);

  const [tour, setTour] = useState<ITournament|undefined>(undefined);

  useEffect(() => {
    if (!tournament) {
      return;
    }

    if (tour != undefined && JSON.stringify(tour) == JSON.stringify(tournament) ) {
      return;
    } 
    setTour(tournament)

    if (tournament?.yuniteId) {
      YuniteTournamentService.getCacheTeams(tournament?.yuniteId).then(
        (response) => {
          setTeams(response);
        }
      );
    }

    let items:string[] = [];
    const index =
      tournament?.members.length >= 20 ? 20 : tournament?.members.length;
    for (let i = 0; i < index; i++) {
      items.push(tournament?.members[i]);
    }
    setPaginatedItems(items);

    return () => {
      //setPaginatedItems([]);
      //setTeams([]);
    };
  }, [tournament]);

  const fetchMoreData = () => {
    if (!tournament) {
      return;
    }
    setTimeout(() => {
      const size = 20;
      const items = tournament.members.slice(
        paginatedItems.length,
        paginatedItems.length + size
      );
      setPaginatedItems(paginatedItems.concat(items));
    }, 100);
  };

  return (
    <div>
      {/* <div className="space-divider-20"></div> */}
      <div className="columns">
        {tournament?.game === GAMES.FORTNITE.ENUM && <div className="column col-12 col-xs-12 flex-centered p-2 gray-bg gray-bg-hover" onClick={() => setShowInfo(!showInfo)}>
            <span style={{ fontSize: 24 }} className="float-right">
              <i className="las la-info-circle"></i>
            </span>
          <span className="skp-text-xxsmall">
            Info
          </span>
          {showInfo && <div className="info-hover-wrapper gray-bg">
                <div className="close-btn" onClick={() => setShowInfo(false)}>
                  <i className="las la-times-circle"></i>
                </div>
                <dl>
                  <dd>
                    This section lists all the names of the members of the
                    tournament.{" "}
                  </dd>
                  <dd>
                    To be able to join the tournament, wait for the period of
                Check-In (go to the Overview tab to see when the check-in period
                of the event) and confirm your presence on the Skillpro Discord
                before the event starts.{" "}
                  </dd>
                  <dd>Complete the following steps to Check In: </dd>
                  <ol>
                    <li>
                      Once the Check In period has begun, access the Skillpro
                  Discord and click on the event category (the category has the
                  same name as the tournament).
                    </li>
                    <li>
                      Click on “registrazione-team” and then on the “T” Yunite
                      message.
                    </li>
                    <li>
                  Follow the procedure that comes in DM on Discord (just click
                  the “+” in the message that comes directly from Yunite).
                    </li>
                    <li>
                  If the procedure was successful you will see the green writing
                  “Confirmed” next to your name in the Players tab of the event.
                    </li>
                  </ol>
                </dl>
          </div>}
        </div>}
        <div className="column col-12 col-xs-12">
          <div
            style={{
              position: "relative",
              width: "100%",
              height:
                tournament?.game === GAMES.CLASH_ROYALE.ENUM
                  ? "calc(100vh - 60px)"
                  : "calc(100vh - 125px)",
            }}
          >
            <Scrollbars
              renderView={(props:any) => <div {...props} id="scrollableDiv1" />}
            >
              {tournament && tournament?.members?.length > 0 && (
                  <div className="columns">
                    <div className="column col-12">
                      <InfiniteScroll
                        dataLength={paginatedItems.length}
                        scrollableTarget="scrollableDiv1"
                        next={fetchMoreData}
                        hasMore={
                          tournament?.members.length > paginatedItems.length
                        }
                        style={{ overflow: "hidden" }}
                        loader={
                          <dd>
                            <div className="loading" key={0}></div>
                          </dd>
                        }
                        scrollThreshold={"100px"}
                        endMessage={
                          <>
                            {paginatedItems.length >= 10 && (
                              <>
                                <p
                                  className="text-center skp-text-light m-2"
                                  style={{ textAlign: "center" }}
                                >
                                  END
                                </p>
                              </>
                            )}
                          </>
                        }
                      >
                        <div className="columns m-2">
                          {paginatedItems &&
                            paginatedItems.map((playerId) => (
                              <>
                                <div
                                  key={playerId}
                                  className="column col-3 col-xs-6 col-md-4"
                                  style={{ marginBottom: 40 }}
                                >
                                {tournament.tournamentType !== 'lobby' && (
                                  <PlayPlayUser 
                                    tournament={tournament}
                                    playerId={playerId} 
                                    teams={teams}>
                                  </PlayPlayUser>
                                )}
                                {tournament.tournamentType === 'lobby' && (
                                  <PlayPlayUser 
                                    tournament={tournament}
                                    playerId={playerId} 
                                    teams={teams}
                                    hideCheckin={true}>
                                  </PlayPlayUser>
                                )}
                                </div>
                              </>
                            ))}
                        </div>
                      </InfiniteScroll>
                    </div>
                  </div>
              )}
              {tournament?.members?.length === 0 && (
                <div className="columns skp-text-light text-center">
                  <div className="space-divider"></div>
                  <div className="column col-12">
                    <h1>
                      <i className="las la-user-friends"></i>
                    </h1>
                  </div>
                  <div className="column col-12">
                    <h5>No players</h5>
                  </div>
                  <div className="column col-12">
                    There are currently no Players registered for this
                    Tournament.
                  </div>
                </div>
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayersTab;
