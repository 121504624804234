import PropTypes from "prop-types";
import KeyCloackService from "../../services/KeyCloackService";

type Props = {
  children: JSX.Element
  roles: Array<string>
}


const RenderOnRole = ({ roles, children }:Props) =>
  KeyCloackService.hasRole(roles) ? children : null;

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RenderOnRole;
