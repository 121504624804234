import ITournament from "../../../../Interfaces/Tournament";

type Props = {
  tournament: ITournament | undefined
}
const PointSystemTab = (props:Props) => {
  const { tournament } = props;

  return ( 
    <div className="container">
        {tournament && <>
          <div className="space-divider-20"></div>
          <h5 className="skp-text-primary">Placement Points</h5>
          <h6 className="skp-text-light">Points per kill: {tournament?.pointSystem?.pointsPerKill}</h6>
          <div className="skp-table-flex skp-text-primary">
            <div className="skp-table-flex-row skp-text-light">
              <div className="skp-table-flex-cell"></div>
              <div className="skp-table-flex-cell">Placement</div>
              <div className="skp-table-flex-cell">Points</div> 
            </div>
            
            {tournament?.pointSystem?.pointsPerPlacement && Object.keys(tournament?.pointSystem?.pointsPerPlacement).map(
              (key) => (
                <div className="skp-table-flex-row">
                  <div className="skp-table-flex-cell"></div>
                  <div className="skp-table-flex-cell">{key}</div>
                  <div className="skp-table-flex-cell">
                    {tournament?.pointSystem?.pointsPerPlacement[Number(key)]}
                  </div>
                </div>
              )
            )}
          </div>
        </>}
    </div>
  );
};

export default PointSystemTab;
