import { useState } from "react";
import { toast } from "react-toastify";
import ETeamPlayerRole from "../../enums/TeamPlayerRole";
import TournamentService from "../../services/TournamentService";
import ITournament from "../Interfaces/Tournament";
import ITournamentTeam from "../Interfaces/TournamentTeam";
import ITournamentTeamMember from "../Interfaces/TournamentTeamMember";
import Modal from "./Modal";

type Props = {
  member: ITournamentTeamMember;
  tournament: ITournament;
  team: ITournamentTeam;
  onReloadTournament: (tournament?: ITournament | undefined) => void;
};

const TeamRoleDropdown = (props: Props) => {
  const { member, tournament, team } = props;
  const [showModal, setShowModal] = useState<{
    show: boolean;
    role?: number;
  }>({ show: false });
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(member.role);

  const roleOptions: Array<{ key: string; value: number }> = [
    { key: "Player", value: 0 },
    { key: "Reserve", value: 1 },
    { key: "Coach", value: 2 },
  ];

  const changeRole = (member: ITournamentTeamMember, event: any) => {
    if (!tournament) {
      return;
    }
    setShowModal({ show: true, role: event.target.value });
  };

  const confirmChangeRole = (role?: number) => {
    if (!role) {
      return;
    }
    setLoading(true);
    TournamentService.changeTeamMemberRole(
      tournament.id,
      team.partyId,
      member.userId,
      Number(role)
    )
      .then((Rtournament) => {
          toast.success("Member role changed")
        props.onReloadTournament(Rtournament);
        setLoading(false);
        setDefaultValue(role);
        setShowModal({ show: false });
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const onCloseModal = () => {
    setDefaultValue(member.role);
    setShowModal({ show: false });
  };

  return (
    <>
      <div className="skp-form-control">
        {!showModal.show && <select
          defaultValue={defaultValue}
          onChange={(event) => changeRole(member, event)}
        >
          {roleOptions.map((option, index) => (
            <option key={index} value={option.value}>{option.key}</option>
          ))}
        </select>}
      </div>

      <Modal
        loading={loading}
        size="medium"
        title="Change Role"
        show={showModal.show}
        onClose={onCloseModal}
        confirmation="Change"
        onConfirm={() => confirmChangeRole(showModal.role)}
      >
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns">
            {showModal.role && (
              <div className="column col-12 skp-text-primary flex-centered">
                Do you want to change player role to "
                {ETeamPlayerRole[showModal.role]}" ?
              </div>
            )}
          </div>
          <div className="space-divider"></div>
        </div>
      </Modal>
    </>
  );
};

export default TeamRoleDropdown;
