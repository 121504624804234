import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import YuniteTournamentService from "../../../services/YuniteTournamentService";
import constant from "../../../constants";
import YuniteTournamentTile from "../../UiLibrary/YuniteTournamentTile";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITournament from "../../Interfaces/Tournament";
import GAMES from "../../../constants-games";

const YuniteTournaments = () => {
  const itemsToDisplay = 4;
  const [loading, setLoading] = useState(false);

  const [yuniteTournaments, setYuniteTournaments] = useState<Array<any>>([]);

  useEffect(() => {
    setLoading(true);

    YuniteTournamentService.getCacheTournaments().then((tournaments) => {
      tournaments.forEach((element: any) => {
        element.game = GAMES.FORTNITE.NAME;
        element.start_tournament = element.startDate;
      });
      setYuniteTournaments(tournaments);
      setLoading(false);
    });
  }, []);

  const sortedTournaments = yuniteTournaments
    .sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    })
    .reverse();

  return (
    <div className="columns">
      <div className="column col-12">
        {/* Heading */}
        <div className="columns">
          <div className="column col-12 col-xs-12 flex-centered">
            <h4 className="skp-heading">Yunite Tournaments</h4>
          </div>
          <div className="column col-12 text-right hide-xs">
            {yuniteTournaments?.length === itemsToDisplay && (
              <Link to="/yunite-tournaments" className="skp-link">
                View All
              </Link>
            )}
          </div>
        </div>
        {/* Tiles */}
        {loading && (
          <div className="columns columns-slider">
            <LoadingPlaceholders numberOfItems={4} type="tournament" />
          </div>
        )}
        {!loading && (
          <div className="columns columns-slider">
            <div className="space-divider-20"></div>
            {sortedTournaments?.length > 0 &&
              sortedTournaments?.slice(0, 4).map((tournament) => (
                <div
                  className="column col-3 col-xs-9 column-slider"
                  key={tournament.id}
                >
                  <YuniteTournamentTile
                    tournament={tournament}
                  ></YuniteTournamentTile>
                </div>
              ))}
            {yuniteTournaments?.length === 0 && (
              <div className="column col-12">
                <h6 className="skp-text-primary">No Yunite Tournaments yet!</h6>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default YuniteTournaments;
