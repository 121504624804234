import React, { useContext, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import UserProfile from "../../../UiLibrary/UserProfile";
import ReactTimeAgo from "react-time-ago";
import MatchService from "../../../../services/MatchService";
import { toast } from "react-toastify";
import Moment from 'moment';
import IMatch from "../../../Interfaces/Match";
import ITournament from "../../../Interfaces/Tournament";
import IMatchBody from "../../../Interfaces/MatchBody";
import IMatchReport from "../../../Interfaces/MatchReport";
import { UserContext } from "../../../Context/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
  match: IMatch | undefined
  tournament: ITournament
  matchId: string
  canEditChat: boolean
  canWatchInfo: boolean
  onSetMatch: (match: IMatch) => void
  updateTournament?: () => void
}

type FormModel = {
  matchIssue: string
}

const IssuesTab = ({ match, tournament, matchId, canWatchInfo, canEditChat, onSetMatch, ...props }:Props) => {
  const {user} = useContext(UserContext);
  const { t } = useTranslation()
  const formikRef = useRef<FormikProps<FormModel>>(null);
  const [loading, setLoading] = useState(false)

  const reportBoxInitialValues:FormModel = {
    matchIssue: "",
  };


  const reportIssue = (values:FormModel) => {
    if(!user?.id) {
      return;
    }
    if (values.matchIssue.length === 0) {
      return;
    }
    setLoading(true)
    const report:IMatchReport = {
      messages: values.matchIssue,
      playerId: user?.id,
      timestamp: new Date(),
    }
    const data:IMatchBody = {
      result: {
        reports: report,
      },
    };
    MatchService.updateMatch(matchId, data)
      .then((match) => {
        onSetMatch(match)
        formikRef?.current?.resetForm();
        setLoading(false)
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
        setLoading(false)
      });
      /*if (props.updateTournament)
        props.updateTournament()*/
  };

  return (
    <div className="columns tabs-body">
      {/* Header */}
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h5 className="skp-text-primary">{t('admin.messages')}</h5>
        {canEditChat && (
          <Formik
            onSubmit={reportIssue}
            initialValues={reportBoxInitialValues}
            innerRef={formikRef}
          >
            {({ resetForm }) => (
              <Form>
                <FormikControl
                  control="textarea"
                  label={t('admin.leaveMessage')}
                  name="matchIssue"
                  
                />
                <button
                  disabled={loading}
                  type="submit"
                  className="skp-btn skp-btn-primary float-right"
                >
                  {t('admin.addMessage')}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
      {/* Body */}
      <>
      {canWatchInfo && (
      <div className="column col-12">
        {/* Display issue */}
        <div className="columns">
          <div className="column col-12">
            {(!match?.result.reports || match?.result.reports.length === 0) && (
              <div className="columns skp-text-light text-center">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h1>
                    <i className="las la-angry"></i>
                  </h1>
                </div>
                <div className="column col-12">
                  {t('admin.noIssuesReported')}
                </div>
              </div>
            )}
            {match?.result.reports && match?.result.reports.length > 0 && (
              <dl className="bordered-list">
                {match?.result?.reports.slice(0).reverse().map((report,index) => (
                  <dd className="columns" key={index}>
                    <div className="column col-12">
                      <div className="columns">
                        <div className="column col-12">
                          <UserProfile userId={report.playerId}>
                            {(report.playerId === tournament?.owner || tournament?.admins?.includes(report.playerId)) && (
                              <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                {t('common.admin')}
                              </span>
                            )}{" "}
                            <span className="skp-text-light skp-text-small">
                              <ReactTimeAgo date={new Date(report?.timestamp)} /> {" - "}
                              {Moment(report?.timestamp).format('MMM DD, hh:mm A')}
                            </span>
                            
                            
                          </UserProfile>
                        </div>
                      </div>
                    </div>
                    <div
                      className="column col-12 skp-text-light skp-text-default"
                      style={{ padding: "0 0 0 75px" }}
                    >
                      <span>{report.messages}</span>
                    </div>
                  </dd>
                ))}
              </dl>
            )}
          </div>
        </div>
        
      </div>
      )}
      </>
      {!canWatchInfo && (
        <div className="column col-12">
          <div className="columns">
            <div className="column col-12">
              <div className="columns skp-text-light text-center">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h1>
                    <i className="las la-angry"></i>
                  </h1>
                </div>
                <div className="column col-12">
                  {t('admin.notAllowedSeeMatch')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IssuesTab;
