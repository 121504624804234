import React, { useState, useEffect, useContext } from "react";
import MatchScore from "../../UiLibrary/MatchComponents/MatchScore";
import Match from "../../UiLibrary/MatchComponents/Match";
import MatchService from "../../../services/MatchService";
import { toast } from "react-toastify";
import "./matchdetails-modal.scss";
import IssuesTab from "./MatchDetailsModalTabs/IssuesTab";
import ScreenshotsTab from "./MatchDetailsModalTabs/ScreenshotsTab";
import MatchChangePlayer from "../../UiLibrary/MatchComponents/MatchChangePlayer";
import MatchChangeTeam from "../../UiLibrary/MatchComponents/MatchChangeTeam";
import ITournament from "../../Interfaces/Tournament";
import IMatch from "../../Interfaces/Match";
import EMatchStatus from "../../../enums/MatchStatus";
import { UserContext } from "../../Context/UserContext";
import { EventsContext } from "../../Context/EventsContext";
import { INotificationElement } from "../../Interfaces/notification.interface";
import { NotificationContext } from "../../Context/NotificationContext";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament;
  matchId: string;
  updateTournament?: () => void;
  onReloadTournament?: (tournament?: ITournament | undefined) => void;
  closeModal: () => void;
  updateNotification?: (theNotification: INotificationElement) => void;
};

function MatchDetailsModal({ tournament, matchId, updateNotification, ...props }: Props) {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { tournamentNotification, setTournamentNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [singleMatch, setSingleMatch] = useState<IMatch>();
  const [isPlayerHome, setIsPlayerHome] = useState(false);
  const [isPlayerAway, setIsPlayerAway] = useState(false);
  const [areBothPlayersPresent, setAreBothPlayersPresent] = useState(false);
  const [reports, setReports] = useState(0);
  const [tabsController, setTabController] = useState({ tab: "screenshot" });

  const isTournamentAdmin: boolean = tournament?.admins?.includes(user?.id!);
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || (user?.roles && user.roles.includes("TournamentAdmin"))) ? true : false;

  const matchCompleted: boolean = singleMatch?.status === 3;
  const matchAwaitConfirmation: boolean = singleMatch?.status === 2;
  const matchStarted: boolean = singleMatch?.status === 1;
  const matchToBePlayed: boolean = singleMatch?.status === 0;

  const tournamentCompleted: boolean = tournament?.status === 2;
  const tournamentHasStarted: boolean = tournament?.status === 1;
  const tournamentNotStarted: boolean = tournament?.status === 0;

  const canWatchInfo: boolean = isPlayerAway || isPlayerHome || isAdmin;

  const canEditChat: boolean = canWatchInfo && tournamentHasStarted && !tournamentCompleted;

  const canNotEditMatchWhenCompleted: boolean =
    canEditChat &&
    !matchCompleted &&
    singleMatch?.result?.scores[0].teamId !== null &&
    singleMatch?.result?.scores[1].teamId !== null;

  const canReopenMatch: boolean = isAdmin && matchCompleted;

  const playerType = tournament.custom ? 0 : tournament?.playerType;

  const { socket, isConnected } = useContext(EventsContext);

  const fetchMatchDetails = async () => {
    try {
      const match = await MatchService.getMatchDetails(matchId);
      setSingleMatch(match);

      if (match.result.scores[0]?.teamId && match.result.scores[1]?.teamId) {
        setAreBothPlayersPresent(
          match.result.scores[0].teamId.length >= 24 && 
          match.result.scores[1].teamId.length >= 24
        );
      }

      setIsPlayerHome(
        match.result.scores[0]?.playerResults
          ? match.result.scores[0]?.playerResults[0]?.playerId === user?.id
          : false
      );

      setIsPlayerAway(
        match.result.scores[1]?.playerResults
          ? match.result.scores[1]?.playerResults[0]?.playerId === user?.id
          : false
      );
    } catch (error) {
      console.error("Errore durante il recupero dei dettagli del match:", error);
    }
  };
  

  useEffect(() => {
    fetchMatchDetails();
    
    if (matchId && isConnected && socket) {
      socket.on(matchId, fetchMatchDetails);

      return () => {
        socket.off(matchId);
        setSingleMatch(undefined);
        setIsPlayerHome(false);
        setIsPlayerAway(false);
        setTabController({ tab: "screenshot" });
      };
    }
  }, [matchId, user, socket, isConnected]);

  useEffect(() => {
    if (singleMatch && singleMatch.result && singleMatch.result.reports) {
      let readed = 0;
      if (tournamentNotification) {
        const match = tournamentNotification.matches?.find((m) => m.matchId === matchId);
        readed = match ? match.reports : 0;
      }
      setReports(singleMatch?.result?.reports?.length - readed);
    }
  }, [tournamentNotification, singleMatch, matchId]);

  const reportScore = async (values: any) => {
    if (!singleMatch) {
      return;
    }

    setLoading(true);
    const teamHomeScore = { score: Number(values.teamHomeScore) };
    const teamAwayScore = { score: Number(values.teamAwayScore) };

    try {
      await MatchService.updateMatchScoreMedia(
        matchId,
        singleMatch?.result?.scores[0].teamId,
        singleMatch?.result?.scores[0].playerResults[0].playerId,
        teamHomeScore
      );

      if (singleMatch?.result?.scores[1].teamId && singleMatch?.result?.scores[1].playerResults[0].playerId) {
        await MatchService.updateMatchScoreMedia(
          matchId,
          singleMatch?.result?.scores[1].teamId,
          singleMatch?.result?.scores[1].playerResults[0].playerId,
          teamAwayScore
        );
      }

      const updatedMatch = await MatchService.updateMatch(matchId, { status: 2 });
      setSingleMatch(updatedMatch);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    if (props.updateTournament) props.updateTournament();
  };

  const updateStatus = async (status: number) => {
    if (!singleMatch) {
      return;
    }

    let data = { status: status, winner: "", reopen: false };
    if (status === 4) {
      data.status = 2;
      data.reopen = true;
    }

    if (
      Number(singleMatch?.result.scores[0].score) !==
      Number(singleMatch?.result.scores[1].score)
    ) {
      let winner;
      if (status === 3) {
        if (singleMatch?.result.scores[0].score > singleMatch?.result.scores[1].score) {
          winner = singleMatch?.result.scores[0].teamId;
        } else {
          winner = singleMatch?.result.scores[1].teamId;
        }
        data.winner = winner;
      }
    }

    try {
      const match = await MatchService.updateMatch(matchId, data);
      setSingleMatch(match);
      toast.info(`${t("toast.matchUpdatedTo")} ${t(EMatchStatus[match.status])}`);
    } catch (error) {
      if (error instanceof Error) {
        // Se `error.response?.data?.message` esiste, usalo; altrimenti usa `error.message`
        const errorMessage = (error as any).response?.data?.message || error.message;
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
    

    if (props.updateTournament) props.updateTournament();

    if (status === 3) {
      if (props.onReloadTournament) props.onReloadTournament();
      props.closeModal();
    }
  };

  const setIssueTab = () => {
    if (tournamentNotification) {
      const newTournamentNotification = { ...tournamentNotification };
      const match = newTournamentNotification.matches?.find(match => match.matchId === matchId);
      if (match) {
        match.reports = singleMatch?.result?.reports ? singleMatch?.result?.reports?.length : 0;
        setReports(0);
      }
      setTournamentNotification(newTournamentNotification);
      if (updateNotification) updateNotification(newTournamentNotification);
    }
    setTabController({ tab: "issues" });
  };

  const assignVictory = async (choosenPlayer: number) => {
    const data = choosenPlayer === 1 ? {
      matchid: matchId,
      winTeam: singleMatch?.result?.scores[0].teamId,
      winPlayer: singleMatch?.result?.scores[0].playerResults[0].playerId,
      looseTeam: singleMatch?.result?.scores[1].teamId,
      loosePlayer: singleMatch?.result?.scores[1].playerResults[0].playerId,
      winScore: 3,
      looseScore: 0
    } : {
      matchid: matchId,
      looseTeam: singleMatch?.result?.scores[0].teamId,
      loosePlayer: singleMatch?.result?.scores[0].playerResults[0].playerId,
      winTeam: singleMatch?.result?.scores[1].teamId,
      winPlayer: singleMatch?.result?.scores[1].playerResults[0].playerId,
      winScore: 3,
      looseScore: 0
    };

    try {
      const match = await MatchService.assignVictory(data);
      setSingleMatch(match);
      toast.info(`${t("toast.matchUpdatedTo")} ${t(EMatchStatus[match.status])}`);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
    

    if (props.updateTournament) props.updateTournament();
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12 text-center">
          <div className={`skp-chip status-${singleMatch?.status}`}>
            {singleMatch && t(EMatchStatus[singleMatch.status])}
          </div>
          <div className="space-divider-20"></div>
        </div>
      </div>

      {/* IS NOT ADMIN */}
      {!isAdmin && <Match matchId={matchId} tournament={tournament} />}

      {/* IS ADMIN */}
      {isAdmin && (
        <>
          {/* TOURNAMENT NOT STARTED, MATCH COMPLETED OR AWAIT CONFIRMATION, TOURNAMENT COMPLETED */}
          {(tournamentNotStarted || matchCompleted || tournamentCompleted) && (
            <Match matchId={matchId} tournament={tournament} />
          )}

          {/* TOURNAMENT STARTED */}
          {tournamentHasStarted && singleMatch && (
            <>
              {/* MATCH TO BE PLAYED */}
              {matchToBePlayed && (
                <>
                  {playerType === 0 ? (
                    <MatchChangePlayer
                      match={singleMatch}
                      tournament={tournament}
                      onSetMatch={setSingleMatch}
                    />
                  ) : (
                    <MatchChangeTeam
                      match={singleMatch}
                      tournament={tournament}
                      onSetMatch={setSingleMatch}
                    />
                  )}
                </>
              )}

              {/* MATCH STARTED */}
              {(matchStarted || matchAwaitConfirmation) && (
                <MatchScore
                  match={singleMatch}
                  isAdmin={isAdmin}
                  onReportScore={reportScore}
                  loading={loading}
                  tournament={tournament}
                />
              )}
            </>
          )}
        </>
      )}

      <div className="space-divider-20"></div>

      <div className="columns tabs-header">
        <div className="column col-12">
          <ul className="tab skp-text-light">
            <li
              className={`tab-item ${
                tabsController.tab === "screenshot" && "active"
              }`}
            >
              <a onClick={() => setTabController({ tab: "screenshot" })}>
                {t("tabs.screenshot")}
              </a>
            </li>
            <li
              className={`tab-item ${
                tabsController.tab === "issues" && "active"
              }`}
            >
              {singleMatch && (
                <>
                  {canWatchInfo ? 
                <a
                  onClick={() => setIssueTab()}
                  className={
                    reports > 0 ? "badge" : ""
                  }
                  data-badge={Number(reports || 0)}
                  >
                    <i
                      className="lar la-comment-dots"
                      style={{ fontSize: 20, verticalAlign: "middle" }}
                    ></i>{" "}
                      {t("tabs.chat")}
                  </a> : 
                  <a
                    onClick={() => setIssueTab()}
                >
                  <i
                    className="lar la-comment-dots"
                    style={{ fontSize: 20, verticalAlign: "middle" }}
                  ></i>{" "}
                  {t("tabs.chat")}
                </a>
                }
                </>
              )}
            </li>
            
            {isAdmin && matchToBePlayed && (
            <li
              className={`tab-item ${
                tabsController.tab === "admin" && "active"
              }`}
            >
              <a onClick={() => setTabController({ tab: "admin" })}>
                <i
                  className="las la-cog"
                  style={{ fontSize: 20, verticalAlign: "middle" }}
                ></i>{" "}
                {t("common.admin")}
              </a>
            </li>)}
          </ul>
        </div>
      </div>
      {/* Screenshots TAB */}
      {tabsController.tab === "screenshot" && (
        <ScreenshotsTab
          matchId={matchId}
          tournament={tournament}
          match={singleMatch}
          canWatchInfo={canWatchInfo}
          canEditMatch={canNotEditMatchWhenCompleted}
          onSetMatch={setSingleMatch}
          isPlayerHome={isPlayerHome}
          isPlayerAway={isPlayerAway}
        />
      )}
      {/* END Screenshot tab */}

      {/* Issues TAB */}
      {tabsController.tab === "issues" && (
        <IssuesTab
          matchId={matchId}
          tournament={tournament}
          match={singleMatch}
          canWatchInfo={canWatchInfo}
          canEditChat={canEditChat}
          onSetMatch={setSingleMatch}
          updateTournament={props.updateTournament}
        />
      )}

      {tabsController.tab === "admin" && (
         <div className="columns">
          <div className="column col-12 text-center">
            <button
              onClick={() => assignVictory(1)}
              className="skp-btn skp-btn-secondary m-2"
              disabled={!areBothPlayersPresent}
            >
              {t("admin.assignsVictoryHome")}
            </button>
            <button
              onClick={() => assignVictory(2)}
              className="skp-btn skp-btn-secondary m-2"
              disabled={!areBothPlayersPresent}
            >
              {t("admin.assignsVictoryAway")}
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      {canNotEditMatchWhenCompleted && (
        <div className="columns">
          <div className="column col-12 text-center">
            <div className="space-divider-40"></div>

            {isAdmin && !(singleMatch?.status === 0) && (
              <button
                onClick={() => updateStatus(0)}
                className="skp-btn skp-btn-secondary m-2"
              >
                {t("status.toBePlayed")}
              </button>
            )}
            {canNotEditMatchWhenCompleted &&
              singleMatch?.status !== 1 &&
              singleMatch?.status !== 2 && (
                <button
                  onClick={() => updateStatus(1)}
                  className="skp-btn skp-btn-primary m-2"
                >
                  {t("status.startMatch")}
                </button>
              )}
            {isAdmin && !(singleMatch?.status === 2) && (
              <button
                onClick={() => updateStatus(2)}
                className="skp-btn skp-btn-accent m-2"
              >
                {t("status.waitingModeration")}
              </button>
            )}
            {isAdmin && (
              <button
                onClick={() => updateStatus(3)}
                className="skp-btn skp-btn-ternary m-2"
                disabled={
                  !singleMatch?.result?.scores[0].score &&
                  !singleMatch?.result?.scores[1].score
                }
              >
                {t("status.markAsComplete")}
              </button>
            )}
          </div>
        </div>
      )}
      {canReopenMatch && (
        <div className="columns">
          <div className="column col-12 text-center">
            <div className="space-divider-40"></div>
            <button
              onClick={() => updateStatus(4)}
              className="skp-btn skp-btn-ternary m-2"
              disabled={tournamentCompleted}
            >
              {t("status.reopenMatch")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MatchDetailsModal;
