import React from 'react'

const LoadingPlaceholders = (props) => {
    const { numberOfItems } = props;
    let items = []
    for(let i =0; i <= (numberOfItems - 1); i++) {
        items.push({ id: numberOfItems });
    }

    const list = items.map((item) => {return props.children} )

    return (
      <>
        {list}
      </>
    );
}

export default LoadingPlaceholders

 
