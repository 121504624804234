import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormikControl from "../../Formik/FormikControl";
import DatePicker from "react-datepicker";
import ErrorLabel from "../../Forms/ErrorLabel";
import { CountryDropdown } from 'react-country-region-selector';
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";

type Props = {
    closeModal: () => void;
};

const UserInfoModal = (props: Props) => {
    const { user, setUser } = useContext(UserContext);
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    const matchTypeOptions = [{value:"it", key:"Italiano"}, {value:"en", key:"English"}];

    interface IFormModel {
        nickname: string;
        birth_date?: Date | null;
        country: string;
        mailing_list: Array<string>;
        language: string;
    }
      
    const initialValues: IFormModel = {
        nickname: user?.nickname ? user.nickname : "",
        birth_date: user?.birth_date ? new Date(user?.birth_date) : new Date("01/01/2000"),
        country: "Italy",
        mailing_list: ["ok"],
        language: user?.language ? user.language : "it"
        
    };
      
    const validationSchema = Yup.object({
        nickname: Yup.string(),
        birth_date: Yup.date().required(),
        country: Yup.string().required()
    });

    const onSubmit = (values: IFormModel) => {
        setLoading(true);
        const userId = user?.id;
        
        const userData = {
            birth_date: values.birth_date,
            country: values.country,
            language: values.language,
            nickname: values.nickname
        }
        const consentData = {
            mailingList: values.mailing_list.length > 0,
            privacy: true
        }

        UserService.updateUserInfo(userData, userId)
        .then((user) => {
            setLoading(false);
            setUser(user);
            UserService.addUserConsent(consentData, userId)
            .then((response) => {

            }).catch((error)=>{
                
            })
            toast.success(t('toast.userInfoUpdate'));
        })
        .catch((error) => {
            setLoading(false);
        });

        
        i18n.changeLanguage(values.language)
            
        setLoading(false);
        setStep(2)
    }

    return (
        <div className="column col-12 skp-info-modal">
            {step === 1 && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, isValid, handleChange, handleBlur }) => (
                        <Form className="columns">

                            <div className="column col-12">
                                <h6 className="skp-text-primary" style={{textAlign: "justify", padding:"10px"}}>{t('user.userInfoModal.desc')}</h6>
                                <h6 className="skp-text-primary" style={{textAlign: "justify", padding:"10px"}}>{t('user.userInfoModal.desc2')}</h6>
                            </div>
                            <div className="space-divider-10"></div>
                            <div className="column col-6">
                                <div
                                    className={
                                    errors.birth_date && touched.birth_date
                                        ? "skp-form-control skp-form-control-error padding-left"
                                        : "skp-form-control padding-left"
                                    }
                                >
                                    <label
                                        className="skp-text-light skp-text-input-label"
                                        htmlFor="birthDate"
                                    >
                                        {t('user.userInfoModal.birthDate')}
                                    </label>
                                    <Field name="birth_date">
                                        {({ form, field }:FieldProps) => {
                                            const { setFieldValue } = form;
                                            const { value } = field;
                                            return (
                                                <DatePicker
                                                    wrapperClassName="datePicker"
                                                    id="birth_date"
                                                    name="birth_date"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={value}
                                                    onChange={(date) => setFieldValue("birth_date", date)}
                                                />
                                            );
                                        }}
                                    </Field>
                                    <ErrorMessage name="birth_date" component={ErrorLabel} />
                                </div>
                            </div>
                            <div className="column col-6">
                                <div
                                    className={
                                    errors.country && touched.country
                                        ? "skp-form-control skp-form-control-error padding-right"
                                        : "skp-form-control padding-right"
                                    }
                                >
                                    <label
                                        className="skp-text-light skp-text-input-label"
                                        htmlFor="country"
                                    >
                                        {t('user.userInfoModal.country')}
                                    </label>
                                    <CountryDropdown name="country" value={values.country}
                                        onChange={(_, e) => handleChange(e)} onBlur={handleBlur} />
                                    <ErrorMessage name="country" component={ErrorLabel} />
                                </div>
                            </div>
                            <div className="space-divider-10"></div>
                            <div className="column col-6">
                                <div
                                    className={
                                    errors.birth_date && touched.birth_date
                                        ? "skp-form-control skp-form-control-error padding-left"
                                        : "skp-form-control padding-left"
                                    }
                                >
                                    <label
                                    className="skp-text-light skp-text-input-label"
                                    htmlFor="nickname"
                                    >
                                        {t('user.userInfoModal.nickname')}
                                    </label>
                                    <Field type="text" id="nickname" name="nickname" />
                                    <ErrorMessage name="nickname" component={ErrorLabel} />
                                </div>
                            </div>
                            <div className="column col-6">
                                <div
                                    className={
                                    errors.birth_date && touched.birth_date
                                        ? "skp-form-control skp-form-control-error padding-right"
                                        : "skp-form-control padding-right"
                                    }
                                >
                                    <FormikControl
                                        control="select"
                                        label={t('user.userInfoModal.language')}
                                        name="language"
                                        options={matchTypeOptions}
                                    />
                                </div>
                            </div>
                            <div className="space-divider-15"></div>
                            <div className="column col-12">
                                <div className="padding-left">
                                    <FormikControl
                                        control="checkbox"
                                        name="mailing_list"
                                        options={[{ key: t('user.userInfoModal.joinMailing'), value: "ok" }]}
                                        label={t('user.userInfoModal.mailingDesc')}
                                    />
                                </div>
                            </div>
                            <div className="space-divider"></div>

                            <div className="column col-12 text-right padding-right">
                                <button
                                    type="submit"
                                    disabled={!isValid || loading}
                                    className="skp-btn skp-btn-primary"
                                >
                                    {loading ? <span className="loading"></span> : t('common.save')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {step === 2 && (
                <div className="columns">
                    <div className="space-divider-20"></div>
                    <div className="column col-12 text-center">
                        <h5 className="skp-text-primary">{t('user.userInfoModal.updateSuccessTitle')}</h5>
                        <div className="space-divider-20"></div>
                        <h6 className="skp-text-primary" style={{textAlign: "justify", padding:"10px"}}>{t('user.userInfoModal.updateSuccessDesc')}</h6>
                        <div className="space-divider-20"></div>
                        <img
                            style={{width: "250px"}}
                            alt="space-monkeys-logo"
                            src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/skillpro_ig_qr.png"
                        ></img>
                        <div className="space-divider-20"></div>
                        <div className="column col-12 text-right padding-right">
                            <button
                                className="skp-btn skp-btn-primary"
                                onClick={() => props.closeModal()}
                            >
                                {t('common.close')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UserInfoModal;