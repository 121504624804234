const LoadingButton = (props) => {
  const { loading, label, className, disabled } = props;
  return (
    <button
      type="submit"
      disabled={disabled}
      className={className}
      onClick={props.onBtnClick}
    >
      {loading ? <span className="loading"></span> : <span>{label}</span>}
    </button>
  );
};

export default LoadingButton;
