import { Form, Formik } from "formik";
import FormikControl from "../../Formik/FormikControl";
import ITournamentGroup from "../../Interfaces/TournamentGroup";
import * as Yup from "yup";

type Props = {
  group: ITournamentGroup;
  loading?: boolean,
  onSubmit: (group: {
    name: string;
    pointSystem: {
      pointsPerWin: number;
      pointsPerLose: number;
      pointsPerDraw: number;
    };
  }) => void;
};

const EditTournamentGroupMondal = ({ loading, group, ...props }: Props) => {
  const initialValues = {
    name: group.name,
    pointSystem: {
      pointsPerWin: group.pointSystem.pointsPerWin,
      pointsPerLose: group.pointSystem.pointsPerLose,
      pointsPerDraw: group.pointSystem.pointsPerDraw,
    },
  };

  const onSubmit = (values: any) => {
    console.log(values);
    props.onSubmit(values);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isValid, setFieldValue }) => (
          <Form>
            <div className="container">
              <div className="space-divider-20"></div>
              <div className="columns skp-text-primary">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <FormikControl
                    control="input"
                    label="Name"
                    name="name"
                    placeholder="Group Name"
                  ></FormikControl>
                </div>
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h6 className="skp-text-light">Points System</h6>
                </div>
                <div className="column col-12">
                  <div className="columns">
                    <div className="column col-4">
                      <FormikControl
                        control="input"
                        type="number"
                        label="Points per win"
                        name="pointSystem.pointsPerWin"
                        placeholder=""
                      ></FormikControl>
                    </div>
                    <div className="column col-4">
                      <FormikControl
                        control="input"
                        type="number"
                        label="Points per draw"
                        name="pointSystem.pointsPerDraw"
                        placeholder=""
                      ></FormikControl>
                    </div>
                    <div className="column col-4">
                      <FormikControl
                        control="input"
                        type="number"
                        label="Points per lose"
                        name="pointSystem.pointsPerLose"
                        placeholder=""
                      ></FormikControl>
                    </div>
                  </div>
                </div>

                <div className="space-divider"></div>

                <div className="column col-12 text-right">
                  <button
                    className="skp-btn skp-btn-primary"
                    disabled={loading || !isValid}
                  >
                    Save Group
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditTournamentGroupMondal;
