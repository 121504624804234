import { useEffect, useState } from "react";
import LoadingPlaceholders from "../../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import TeamTile from "../../../UiLibrary/TeamTile";
import TeamService from "../../../../services/TeamService";
import { useParams } from "react-router";
import ITeam from "../../../Interfaces/Team";
import { useTranslation } from "react-i18next";

const MyTeamsTab = () => {
  const ownerId = useParams<{ id: string }>().id;
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Array<ITeam>>([]);

  useEffect(() => {
    setLoading(true);
    TeamService.getAllTeams({ members: ownerId }).then((teams) => {
      setTeams(teams);
      setLoading(false);
    });
    return () => {
      setTeams([]);
      setLoading(false);
    };
  }, [ownerId]);

  return (
    <>
      <div className="space-divider-20"></div>
      {loading && (
        <div className="columns">
          <LoadingPlaceholders numberOfItems={6}>
            <div className="column col-2 col-xs-12">
              <div className={`team-tile team-tile-loading`}>
                <div className={`team-tile-image`}>
                  <div className={`default-tile`}></div>
                </div>
              </div>
            </div>
          </LoadingPlaceholders>
        </div>
      )}
      {!loading && (
        <div className="columns">
          {/* Tiles */}

          <>
            {teams.length > 0 &&
              teams.map((team) => (
                <div className="column col-2 col-xs-12" key={team.id}>
                  <TeamTile team={team}></TeamTile>
                </div>
              ))}
            {teams.length === 0 && (
              <div className="column col-12">
                <h6 className="skp-text-primary">{t('user.config.noTeamsAdd')}</h6>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default MyTeamsTab;
