import { Link } from "react-router-dom";
import "./cookie-banner.scss"

type Props = {
    onAccept: () => void
}
const CookieBanner = (props: Props) => {
  return (
    <div className="skp-cookie-banner skp-text-inverted">
      <div className="container">
        <div className="columns">
          <div className="column col-12 skp-text-primary skp-text-small">
            This website use cookies to improve your user experience. If you
            keep using this website, you accept the use of cookies.
          </div>
          <div className="column col-12">
            <div className="skp-form-control form-group">
            <label htmlFor="necessaryCookie" className="form-checkbox skp-text-primary skp-text-default">
                <input type="checkbox" id="necessaryCookie" name="necessaryCookie" checked disabled />
                <i className="form-icon"></i> Necessary</label>
            </div>
          </div>
          <div className="column col-6 skp-text-primary">
            <Link to="/cookies-policy" className="skp-text-primary skp-text-small">More Information</Link>
          </div>
          <div className="column col-6 skp-text-primary text-right">
            <button className="skp-btn skp-btn-primary" onClick={props.onAccept}>Accept All</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
