import { useState, useRef, useEffect, useContext } from "react";
import Multiselect from "multiselect-react-dropdown";
import GAMES from "../../../constants-games";
import { Link } from "react-router-dom";
import Modal from "../../UiLibrary/Modal";
import AddSingleGameAccountModal from "../AddGameAccountModal/AddSingleGameAccountModal";
import ITournament from "../../Interfaces/Tournament";
import IGameAccount from "../../Interfaces/GameAccount";
import { UserContext } from "../../Context/UserContext";
import IUserDetails from "../../Interfaces/UserDetails";
import UserService from "../../../services/UserService";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import ImageService from "../../../services/ImageService";
import UploadImageBox from "../../UiLibrary/UploadImageBox/UploadImageBox";
import { useTranslation } from "react-i18next";

type Props = {
  loading: boolean;
  tournament: ITournament;
  selectUser?: IUserDetails;
  onConfirm: (data: {
    gameAccounts: Array<{
      userId?: string | undefined;
      gameAccount: IGameAccount | undefined;
    }>;
    password?: string | undefined;
  }) => void;
};

interface IDisplayValues {
  id: string | undefined;
  game: string | undefined;
  console: string | undefined;
  username: string | undefined;
  displayName?: string;
}

interface IScreenshot {
  isNeeded?: boolean,
  label?: string;
}

const JoinTournamentModal = (props: Props) => {
  const { loading, tournament, selectUser } = props;
  let { user, setUser } = useContext(UserContext);
  const { t } = useTranslation()
  const [passwordType, setPasswordType] = useState("password");
  const [gameAccount, setGameAccount] = useState<IGameAccount>();
  const [gameAccountNeeded, setGameAccountNeeded] = useState(true);
  const [screenshotNeeded, setScreenshotNeeded] = useState<IScreenshot>({isNeeded: false, label:""})
  const [screenshot, setScreenshot] = useState<IFileBase64Image>();
  const [imageUploading, setImageUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [displayValues, setDisplayValues] = useState<Array<IDisplayValues>>([]);
  const [selectedValue, setSelectedValue] = useState<Array<IDisplayValues>>([]);
  const [selectedUser, setSelectedUser] = useState(selectUser ? selectUser : user)

  const passwordInputRef = useRef<HTMLInputElement>(null);

  const uploadScreenshot = () => {
    if(!screenshot) {
      return
    }

    setImageUploading(true);
    ImageService.uploadImage(screenshot).then((response) => {
      const data = {
        mediaUrl: ImageService.generatePublicUrl(response.data),
      };

      console.log(data)
      /*MatchService.updateMatchScoreMedia(matchId, teamId, user?.id, data)
        .then((response) => {
          onSetMatch(response.data.data);
          setShowUploadScreenshot(false);
          setImageUploading(false);
          setScreenshot(undefined);
        })
        .catch((error) => {
          setImageUploading(false);
          setShowUploadScreenshot(false);
          toast.error("Error uploading image");
          setScreenshot(undefined);
        });*/
    });
  };

  useEffect(() => {

    setGameAccountNeeded(tournament?.gameAccountNeeded)
    if (tournament.screenshotNeeded) {
      setScreenshotNeeded({isNeeded:tournament.screenshotNeeded, label:tournament.screenshotLabel})
    }
    
    if (selectedUser?.gameAccounts && selectedUser?.gameAccounts?.length > 0) {
      const gameAccount = selectedUser?.gameAccounts?.filter(
        (account: IGameAccount) =>
          tournament?.console.includes(account.console || "") &&
          account.game === tournament?.game
      );
      const displayValues: Array<IDisplayValues> = [...gameAccount];
      displayValues.forEach(
        (account) =>
          (account.displayName = `USERNAME: ${account.username} - ID: ${account.id} - ${t('players.platform')}: ${account.console}`)
      );
      setDisplayValues(displayValues);
      setSelectedValue([displayValues[0]]);

      setGameAccount(gameAccount[0]);

    }
  }, [tournament, selectedUser]);

  const onSelect = (event: any) => {
    let { displayName, ...newData } = event[0];
    setGameAccount(newData);
  };

  const onRemove = () => {
    setGameAccount(undefined);
  };

  const onConfirm = () => {
    const password =
      tournament?.open === false ? passwordInputRef?.current?.value : undefined;

    let data: {
      gameAccounts: Array<{
        userId?: string | undefined;
        gameAccount: IGameAccount | undefined;
      }>;
      password?: string | undefined;
    } = {
      gameAccounts: [{ userId: selectedUser?.id, gameAccount: gameAccount }],
    };
    data.password = password;
    props.onConfirm(data);
  };

  const onCloseModal = () => {
    
    if(selectedUser?.id && selectedUser?.id.length >= 24) {
      UserService.getUser(selectedUser.id, true).then(uResponse => {
        setSelectedUser(uResponse)
        if(uResponse.id === user?.id) {
          setUser(uResponse)
        }
      })
    } 

    setShowModal(false);
    
  }

  return (
    <>
      <div className="container">
        <div className="space-divider-20"></div>

        {gameAccountNeeded && (
        <div className="columns">
          <div className="column col-2 hide-xs"></div>
          {/* User Game Accounts */}
          <div className="column col-8 col-xs-12">
            <h6 className="skp-text-light text-uppercase">
              {t('players.selectGameAccount')}
            </h6>
            {displayValues.length > 0 && (
              <div className="skp-form-control">
                <Multiselect
                  options={displayValues} // Options to display in the dropdown
                  selectedValues={selectedValue} // Preselected value to persist in dropdown
                  displayValue="displayName" // Property name to display in the dropdown options
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  singleSelect={true}
                  closeIcon="cancel"
                  placeholder={t('players.selectGameAccount')}
                  avoidHighlightFirstOption={true}
                />
              </div>
            )}
            {displayValues.length === 0 && (
              <div>
                <div
                  className="skp-link-primary skp-text-default"
                  onClick={() => setShowModal(true)}
                >
                  <i className="las la-plus-circle"></i> {t('players.addAccountFor')}{" "}
                  {GAMES[tournament?.game].NAME}
                </div>
                <div className="space-divider-20"></div>
              </div>
            )}
            <p className="skp-text-label skp-text-light">
              {t('players.toManageGameAccounts')}{" "}
              <Link
                className="skp-link"
                to={`/users/${selectedUser?.id}/settings/usersettings`}
              >
                {t('players.userSettingPage')}
              </Link>
            </p>
          </div>
          <div className="column col-2 hide-xs"></div>
          <div className="space-divider-20"></div>

            {/* Tournament Password */}
            
            {tournament?.open === false && (
              <>
                <div className="column col-2 hide-xs"></div>
                <div className="column col-8 col-xs-12">
                  <h6 className="skp-text-light skp-text-label text-uppercase">
                    {t('players.providePassword')}
                  </h6>
                  <div className="skp-form-control">
                    <input ref={passwordInputRef} type={passwordType} />
                    <div className="icon-wrapper">
                      {passwordType === "password" && (
                        <i
                          className="lar la-eye skp-text-light"
                          onClick={() => setPasswordType("text")}
                        ></i>
                      )}
                      {passwordType === "text" && (
                        <i
                          className="lar la-eye-slash skp-text-light"
                          onClick={() => setPasswordType("password")}
                        ></i>
                      )}
                    </div>
                  </div>
                  <div className="space-divider-20"></div>
                </div>
              </>
            )}

            {screenshotNeeded.isNeeded && (
              <>
                <div className="column col-12">
                  <h6 className="skp-text-light text-uppercase text-center">
                    {screenshotNeeded.label}
                  </h6>
                </div>
                <div className="column col-12 text-center">
                  <div className="file-base64-label-wrapper" style={{margin:"0"}}>
                    <UploadImageBox
                      onUpload={(image) => setScreenshot(image)}
                      onRemovePreview={() => setScreenshot(undefined)}
                    />
                  </div>
                </div>
                <div className="space-divider-20"></div>
                <div className="column col-12 text-center">
                  <button
                    disabled={!screenshot || imageUploading}
                    onClick={uploadScreenshot}
                    className="skp-btn skp-btn-primary"
                  >
                    {imageUploading ? (
                      <span className="loading"></span>
                    ) : (
                      t('titles.uploadImage')
                    )}
                  </button>
                </div>
                <div className="space-divider-20"></div>
              </>
            )}

            <div className="column col-2 hide-xs"></div>
            <div className="space-divider-20"></div>
            <div className="column col-12 text-right">
              <button
                disabled={!gameAccount}
                className="skp-btn skp-btn-primary"
                onClick={onConfirm}
              >
                {loading ? <span className="loading"></span> : t('tournament.join.joinTournament')}
              </button>
            </div>
          </div>
        )}
        {!gameAccountNeeded && (
          <div className="columns">
            <div className="column col-2 hide-xs"></div>
            {/* User Game Accounts */}
            <div className="column col-8 col-xs-12">
              <h6 className="skp-text-light text-uppercase">
                {t('players.welcomeToTournament')}
              </h6>
            </div>
          {/* Tournament Password */}
          <div className="column col-2 hide-xs"></div>
          {tournament?.open === false && (
            <div className="column col-8 col-xs-12">
              <h6 className="skp-text-light skp-text-label text-uppercase">
                {t('players.providePassword')}
              </h6>
              <div className="skp-form-control">
                <input ref={passwordInputRef} type={passwordType} />
                <div className="icon-wrapper">
                  {passwordType === "password" && (
                    <i
                      className="lar la-eye skp-text-light"
                      onClick={() => setPasswordType("text")}
                    ></i>
                  )}
                  {passwordType === "text" && (
                    <i
                      className="lar la-eye-slash skp-text-light"
                      onClick={() => setPasswordType("password")}
                    ></i>
                  )}
                </div>
              </div>
              <div className="space-divider-20"></div>
            </div>
          )}
          <div className="column col-2 hide-xs"></div>
          <div className="space-divider-20"></div>
          <div className="column col-12 text-right">
            <button
              className="skp-btn skp-btn-primary"
              onClick={onConfirm}
            >
              {loading ? <span className="loading"></span> : t('tournament.join.joinTournament')}
            </button>
          </div>
        </div>
        )}
      </div>

      {/* Add account modal */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={t('players.addGameAccount')}
        size="large"
      >
        <>
          {selectedUser && (
            <AddSingleGameAccountModal
              tournament={tournament}
              selectUser={selectedUser}
              closeModal={onCloseModal}
            ></AddSingleGameAccountModal>
          )}
        </>
      </Modal>
    </>
  );
};

export default JoinTournamentModal;
