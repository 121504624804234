import "./message-types.scss"
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import Moment from "moment";
import ChatService from "../../../../services/ChatService";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip'
import UserProfile from "../../UserProfile";
import Modal from "../../Modal";
import { useTranslation } from "react-i18next";

export type EmojiElement = {
    id: string
    count: number
    users: Array<string>
}

export type EmojiTooltipElement = {
    userid: string
    emj: string
}


const TextMessage = ({msg, user, contacts, mustRefresh, replyMessage, isDeleted, tournament}) => {
    const [message, setMessage] = useState(msg);
    const { t } = useTranslation()
    const [emoji, setEmoji] = useState<string|undefined>();
    const [emojiTooltip, setEmojiTooltip] = useState<Array<EmojiTooltipElement>>([])
    const theEmojiList:Array<EmojiElement>=([])
    const [sender, setSender] = useState<string>()

    const isTournamentAdmin: boolean = tournament ? tournament?.admins?.includes(user?.id!) : false 
    const isAdmin = tournament || ( user?.roles && user.roles.includes("TournamentAdmin")) ? true : (tournament?.owner === user?.id || isTournamentAdmin) ? true: false
   
    useEffect(()=>{
        let theEmojiTooltip:EmojiTooltipElement[] = []
        setMessage(msg)
        if (message && message.reactions && message.reactions.length > 0) {
            message.reactions.map((e)=> {
                theEmojiTooltip.push({userid: e.userid, emj: e.reaction})
                const i = theEmojiList.findIndex(emj => emj.id === e.reaction);
                if ( i > -1 ) {
                    theEmojiList[i].count++
                    theEmojiList[i].users.push(e.userid)
                } else {
                    const theEmoji:EmojiElement = {
                        id:e.reaction,
                        count:1,
                        users:[e.userid]
                    }
                    theEmojiList.push(theEmoji)
                }
            })
        }

        let theEmoji:string = ""
         
        theEmojiList.forEach((emj)=>{
            theEmoji += emj.id
            if (emj.count > 1)
                theEmoji += " "+emj.count + " "
        })
        setEmojiTooltip(theEmojiTooltip)
        setEmoji(theEmoji)
    },[message, msg])

    useEffect(()=>{
        if (message.userid === user.id) {
            setSender( "You" ) 
        } else {
            contacts.map((c) => {
                if (c.id === message.userid) {
                    setSender(c.username)
                }
            })
        }
    }, [contacts])
    
    const deleteMessage = () => {
        if (tournament) {
            /* Se sono qui vuol dire che devo eliminare il messaggio dalla chat del torneo */
            ChatService.deleteTournamentMessage(message.id).then((resp) => {
                console.log("cancellato")
            })
            .catch((error) => {
                console.log("errore")
            });
        } else {
            ChatService.deleteMessage(message.id)
            .then((resp) => {
                mustRefresh()
            })
            .catch((error) => {
                console.log("errore")
            });
        }
    }

    const updateReactions = (selecteEmoji:string) => {
        const data = {"reactions":[{
            "reaction":selecteEmoji,
            "userid":user.id
        }]}

        ChatService.updateReactions(message.id, data)
        .then((resp) => {
            setMessage(resp)
        })
        .catch((error) => {
            console.log("errore")
        });
    }

    const translate = (selectedFlag: string) => {
        ChatService.translate(msg, selectedFlag)
        .then((resp) => {
            console.log(resp)
        })
        .catch((error) => {
            console.log("errore")
        });
    }

    const incoming = message.userid !== user.id 
    return (
        <div className={`${incoming ? "skp-box-start" : "skp-box-end"}`}>
            <div className={`${incoming ? "skp-msg-start" : "skp-msg-end"}`}>
                <div className={`skp-msg-balloon skp-text-light skp-msg-text-default ${incoming ? "skg-msg-start-bg" : "skg-msg-end-bg"}`}>
                    <div className="skp-msg-balloon-content">
                        <div className="skp-msg-text-default skp-msg-username">
                            {sender} {(tournament && message.userid === tournament?.owner ||
                                        tournament?.admins?.includes(message.userid)) && (
                                        <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                            {t('common.admin')}
                                        </span>
                                    )}{" "}
                        </div>
                        <div className="skp-msg-text">
                            {message.text} 
                        </div>
                        <div className="skp-msg-time">
                            {Moment(new Date(message.time)).format(
                                "HH:mm"
                            )}
                        </div>
                        {emoji && (
                            <div 
                                style={{width:`${(emoji.length*6)+35}px`}} 
                                className={`skg-reaction-element ${incoming ? "skp-reaction-element-incoming":"skp-reaction-element-outcoming"}`}
                                data-tooltip-id={`tooltip-anchor-hide${msg.id}`}
                            >
                                <div>{emoji}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {!isDeleted && (
                <div className="skp-text-light skp-message-inline-menu-icon">
                    <Menu
                        transition
                        menuButton={<MenuButton className="skp-btn skp-btn-icon"><i className="la la-ellipsis-v"></i></MenuButton>}
                        direction={incoming ? "right" : "left"}
                        align={"center"}
                        position={"anchor"}
                        viewScroll={"close"}
                        arrow={true}
                        theming="dark"
                        >
                        <MenuItem onClick={()=>replyMessage(msg)}><i className="las la-reply skp-menuitem-icon"></i>{t('messages.reply')}</MenuItem>
                        <SubMenu menuClassName="skp-reaction-submenu"
                            label={
                                <>
                                    <i className="las la-smile skp-menuitem-icon"></i>{t('messages.react')}
                                </>
                                }
                        >
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("👍")}>👍</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("💪")}>💪</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("❤️")}>❤️</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("😂")}>😂</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("😮")}>😮</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("😢")}>😢</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("🔥")}>🔥</MenuItem>
                        </SubMenu>
                        {/*{incoming && <MenuItem><i className="las la-exclamation-circle skp-menuitem-icon"></i>Report</MenuItem>}*/}
                        {isAdmin && <MenuItem onClick={deleteMessage}><i className="las la-trash skp-menuitem-icon"></i>{t('messages.delete')}</MenuItem>}
                        {/*<SubMenu menuClassName="skp-reaction-submenu"
                            label={
                                <>
                                    <i className="las la-language skp-menuitem-icon"></i>Translate
                                </>
                            }
                        >
                            <MenuItem onClick={()=>translate("en")}>🇬🇧</MenuItem>
                            <MenuItem onClick={()=>translate("es")}>🇪🇸</MenuItem>
                        </SubMenu>*/}
                    </Menu>
                </div>
            )}
            <Tooltip style={{zIndex:999}} id={`tooltip-anchor-hide${msg.id}`} place="top">
                <div className="skp-emoji-tooltip">
                    {emojiTooltip && (
                        emojiTooltip.map((emj, index)=>{
                            return(
                                <div key={index} style={{display:"flex"}}>
                                    <UserProfile 
                                        user={emj.userid===user.id? user : contacts}
                                        hideName={false}
                                    ></UserProfile>
                                    <div className="reaction">{emj.emj}</div>
                                </div>)
                        })
                    )}
                </div>
            </Tooltip>
            
        </div>
    )
}

const ReplyMessage = ({msg, user, contacts, mustRefresh, replyMessage, isDeleted, tournament}) => {
    const [message, setMessage] = useState(msg);
    const { t } = useTranslation()
    const [emoji, setEmoji] = useState<string|undefined>();
    const [emojiTooltip, setEmojiTooltip] = useState<Array<EmojiTooltipElement>>([])
    const theEmojiList:Array<EmojiElement>=([])
    const [sender, setSender] = useState<string>()
    const [replyUserName, setReplyUserName] = useState<string>()

    const isTournamentAdmin: boolean = tournament ? tournament?.admins?.includes(user?.id!) : false 
    const isAdmin = tournament || ( user?.roles && user.roles.includes("TournamentAdmin")) ? true : (tournament?.owner === user?.id || isTournamentAdmin) ? true: false
   
    useEffect(()=>{
        if (message.userid === user.id) {
            setSender( t('messages.you') )
        } else {
            contacts.map((c) => {
                if (c.id === message.userid) {
                    setSender(c.username)
                }
            })
        }

        if (message.replyid === user.id) {
            setReplyUserName( t('messages.you') )
        } else {
            contacts.map((c) => {
                if (c.id === message.replyid) {
                    setReplyUserName(c.username)
                }
            })
        }
    }, [contacts])
    
    useEffect(()=>{
        let theEmojiTooltip:EmojiTooltipElement[] = []
        setMessage(msg)
        if (message && message.reactions && message.reactions.length > 0) {
            message.reactions.map((e)=> {
                theEmojiTooltip.push({userid: e.userid, emj: e.reaction})
                const i = theEmojiList.findIndex(emj => emj.id === e.reaction);
                if ( i > -1 ) {
                    theEmojiList[i].count++
                    theEmojiList[i].users.push(e.userid)
                } else {
                    const theEmoji:EmojiElement = {
                        id:e.reaction,
                        count:1,
                        users:[e.userid]
                    }
                    theEmojiList.push(theEmoji)
                }
            })
        }

        let theEmoji:string = ""
        theEmojiList.forEach((emj)=>{
            theEmoji += emj.id
            if (emj.count > 1)
                theEmoji += " "+emj.count + " "
        })
        setEmojiTooltip(theEmojiTooltip)
        setEmoji(theEmoji)
    },[message, msg])
    
    const deleteMessage = () => {
        if (tournament) {
            /* Se sono qui vuol dire che devo eliminare il messaggio dalla chat del torneo */
            ChatService.deleteTournamentMessage(message.id).then((resp) => {
                console.log("cancellato")
            })
            .catch((error) => {
                console.log("errore")
            });
        } else {
            ChatService.deleteMessage(message.id)
            .then((resp) => {
                mustRefresh()
            })
            .catch((error) => {
                console.log("errore")
            });
        }
    }

    const updateReactions = (selecteEmoji:string) => {
        const data = {"reactions":[{
            "reaction":selecteEmoji,
            "userid":user.id
        }]}

        ChatService.updateReactions(message.id, data)
        .then((resp) => {
            setMessage(resp)
        })
        .catch((error) => {
            console.log("errore")
        });
    }

    const incoming = message.userid !== user.id
    
    return (
        <div className={`${incoming ? "skp-box-start" : "skp-box-end"}`}>
            <div className={`${incoming ? "skp-msg-start" : "skp-msg-end"}`}>
                <div className={`skp-msg-balloon skp-text-light skp-msg-text-default ${incoming ? "skg-msg-start-bg" : "skg-msg-end-bg"}`}>
                    <div className="skp-msg-balloon-replyed">
                        <div className="skp-msg-text-default skp-msg-username skp-msg-reply-padding">
                            {sender} {(tournament && message.userid === tournament?.owner ||
                                        tournament?.admins?.includes(message.userid)) && (
                                        <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                            {t('common.admin')}
                                        </span>
                                    )}{" "}
                        </div>
                        <div className="skp-msg-balloon-replyed-content">
                            <div className="skp-msg-text-default skp-msg-reply-username">
                                {replyUserName} {(tournament && message.replyid === tournament?.owner ||
                                        tournament?.admins?.includes(message.replyid)) && (
                                        <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                            {t('common.admin')}
                                        </span>
                                    )}{" "}
                            </div>
                            <div className="skp-text-small">
                                {message.replytype !== 'image' && message.reply} 
                                {message.replytype === 'image' && (
                                    <div className="skp-reply-image-root">
                                        <div><i className="las la-camera" style={{fontSize: "16px"}}></i>{t('messages.photo')}</div>
                                        <img className="skp-reply-image" src={message.reply}></img>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="skp-msg-balloon-content">
                            <div className="skp-msg-text">
                                {message.text} 
                            </div>
                            <div className="skp-msg-time">
                                {Moment(new Date(message.time)).format(
                                "HH:mm"
                                )}
                            </div>
                            {emoji && (
                                <div 
                                    style={{width:`${(emoji.length*6)+35}px`}} 
                                    className={`skg-reaction-element ${incoming ? "skp-reaction-element-incoming":"skp-reaction-element-outcoming"}`}
                                    data-tooltip-id={`tooltip-anchor-hide${msg.id}`}
                                >
                                    <div>{emoji}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>
            {!isDeleted && (        
                <div className="skp-text-light skp-message-inline-menu-icon">
                    <Menu
                        transition
                        menuButton={<MenuButton className="skp-btn skp-btn-icon"><i className="la la-ellipsis-v"></i></MenuButton>}
                        direction={incoming ? "right" : "left"}
                        align={"center"}
                        position={"anchor"}
                        viewScroll={"close"}
                        arrow={true}
                        theming="dark"
                        >
                        <MenuItem onClick={()=>replyMessage(msg)}><i className="las la-reply skp-menuitem-icon"></i>{t('messages.reply')}</MenuItem>
                        <SubMenu menuClassName="skp-reaction-submenu"
                            label={
                                <>
                                    <i className="las la-smile skp-menuitem-icon"></i>{t('messages.react')}
                                </>
                                }
                        >
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("👍")}>👍</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("💪")}>💪</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("❤️")}>❤️</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("😂")}>😂</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("😮")}>😮</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("😢")}>😢</MenuItem>
                            <MenuItem className="skp-emoji-menu-item" onClick={()=>updateReactions("🔥")}>🔥</MenuItem>
                        </SubMenu>
                        {/*{incoming && <MenuItem><i className="las la-exclamation-circle skp-menuitem-icon"></i>Report</MenuItem>}*/}
                        {isAdmin && <MenuItem onClick={deleteMessage}><i className="las la-trash skp-menuitem-icon"></i>{t('messages.delete')}</MenuItem>}
                    </Menu>
                </div>
            )}
            <Tooltip style={{zIndex:999}} id={`tooltip-anchor-hide${msg.id}`} place="top">
                <div className="skp-emoji-tooltip">
                    {emojiTooltip && (
                        emojiTooltip.map((emj, index)=>{
                            return(
                                <div key={index} style={{display:"flex"}}>
                                    <UserProfile 
                                        user={emj.userid===user.id? user : contacts}
                                        hideName={false}
                                    ></UserProfile>
                                    <div className="reaction">{emj.emj}</div>
                                </div>)
                        })
                    )}
                </div>
            </Tooltip>
        </div>
    )
}

interface IImageModal {
    show: boolean
    imgSrc?: string | undefined
    playerId?: string | undefined
  }

const MediaMessage = ({msg, user, contacts, mustRefresh, replyMessage, isDeleted}) => {
    const [message, setMessage] = useState(msg);
    const [emoji, setEmoji] = useState<string|undefined>();
    const [emojiTooltip, setEmojiTooltip] = useState<Array<EmojiTooltipElement>>([])
    const theEmojiList:Array<EmojiElement>=([])
    const [showImageModal, setShowImageModal] = useState<IImageModal | undefined>({
        show: false,
        imgSrc: undefined,
        playerId: "1"
      });
   
    useEffect(()=>{
        let theEmojiTooltip:EmojiTooltipElement[] = []
        if (message && message.reactions && message.reactions.length > 0) {
            message.reactions.map((e)=> {
                theEmojiTooltip.push({userid: e.userid, emj: e.reaction})
                const i = theEmojiList.findIndex(emj => emj.id === e.reaction);
                if ( i > -1 ) {
                    theEmojiList[i].count++
                    theEmojiList[i].users.push(e.userid)
                } else {
                    const theEmoji:EmojiElement = {
                        id:e.reaction,
                        count:1,
                        users:[e.userid]
                    }
                    theEmojiList.push(theEmoji)
                }
            })
        }

        let theEmoji:string = ""
        theEmojiList.forEach((emj)=>{
            theEmoji += emj.id
            if (emj.count > 1)
                theEmoji += " "+emj.count + " "
        })
        setEmojiTooltip(theEmojiTooltip)
        setEmoji(theEmoji)
    },[message])
    
    const deleteMessage = () => {
        ChatService.deleteMessage(msg.id)
            .then((resp) => {
                mustRefresh()
            })
            .catch((error) => {
                console.log("errore")
            });
    }

    const updateReactions = (selecteEmoji:string) => {
        const data = {"reactions":[{
            "reaction":selecteEmoji,
            "userid":user.id
        }]}

        ChatService.updateReactions(message.id, data)
        .then((resp) => {
            setMessage(resp)
        })
        .catch((error) => {
            console.log("errore")
        });
    }
    
    const incoming = msg.userid !== user.id
    return (
        <div className={`${incoming ? "skp-box-start" : "skp-box-end"}`}>
            <div className={`${incoming ? "skp-img-start" : "skp-img-end"}`}>
                <div className={`skp-msg-balloon skp-text-light skp-msg-text-default ${incoming ? "skg-msg-start-bg" : "skg-msg-end-bg"}`}>
                    <div style={{position:"relative"}}>
                        <img src={msg.text} className="skp-msg-image" onClick={() =>
                            setShowImageModal({
                                show: true,
                                imgSrc: msg.text,
                                playerId: msg.userid
                            })
                        }/>
                        
                        <div className="skp-msg-time skp-msg-time-image">
                            {Moment(msg.time).format(
                            "HH:mm"
                            )}
                        </div>
                        {emoji && (
                                <div 
                                    style={{width:`${(emoji.length*6)+35}px`}} 
                                    className={`skg-reaction-element ${incoming ? "skp-reaction-element-incoming":"skp-reaction-element-outcoming"}`}
                                    data-tooltip-id={`tooltip-anchor-hide${msg.id}`}
                                >
                                    <div>{emoji}</div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <div className="skp-text-light skp-message-inline-menu-icon">
                <Menu
                    transition
                    menuButton={<MenuButton className="skp-btn skp-btn-icon"><i className="la la-ellipsis-v"></i></MenuButton>}
                    direction={incoming ? "right" : "left"}
                    align={"center"}
                    position={"anchor"}
                    viewScroll={"close"}
                    arrow={true}
                    theming="dark"
                    >
                    <MenuItem onClick={()=>replyMessage(msg)}><i className="las la-reply skp-menuitem-icon"></i>Reply</MenuItem>
                    <SubMenu menuClassName="skp-reaction-submenu"
                            label={
                                <>
                                    <i className="las la-smile skp-menuitem-icon"></i>React
                                </>
                                }
                        >
                            <MenuItem onClick={()=>updateReactions("👍")}>👍</MenuItem>
                            <MenuItem onClick={()=>updateReactions("💪")}>💪</MenuItem>
                            <MenuItem onClick={()=>updateReactions("❤️")}>❤️</MenuItem>
                            <MenuItem onClick={()=>updateReactions("😂")}>😂</MenuItem>
                            <MenuItem onClick={()=>updateReactions("😮")}>😮</MenuItem>
                            <MenuItem onClick={()=>updateReactions("😢")}>😢</MenuItem>
                            <MenuItem onClick={()=>updateReactions("🔥")}>🔥</MenuItem>
                        </SubMenu>
                    {incoming && <MenuItem><i className="las la-exclamation-circle skp-menuitem-icon"></i>Report</MenuItem>}
                    {!incoming && <MenuItem onClick={deleteMessage}><i className="las la-trash skp-menuitem-icon"></i>Delete</MenuItem>}
                </Menu>
            </div>
            <Tooltip style={{zIndex:999}} id={`tooltip-anchor-hide${msg.id}`} place="top">
                <div className="skp-emoji-tooltip">
                    {emojiTooltip && (
                        emojiTooltip.map((emj, ind)=>{
                            return(
                                <div key={ind} style={{display:"flex"}}>
                                    <UserProfile 
                                        user={emj.userid===user.id? user : contacts}
                                        hideName={false}
                                    ></UserProfile>
                                    <div className="reaction">{emj.emj}</div>
                                </div>)
                        })
                    )}
                </div>
            </Tooltip>
            <Modal
                show={showImageModal?.show}
                onClose={() => setShowImageModal({ show: false })}
            >
                <div className="container">
                <div className="columns">
                    <div className="space-divider-20"></div>
                    <div className="column col-12 flex-centered">
                    <div
                        className="screenshot-preview"
                        style={{
                        "background-image": `url(${showImageModal?.imgSrc})`,
                        } as React.CSSProperties}
                    />
                    </div>
                </div>
                <div className="space-divider-20"></div>
                <div className="columns">
                    <div className="column col-7 col-xs-12">
                    <UserProfile userId={showImageModal?.playerId}>
                    </UserProfile>
                    </div>
                </div>
                </div>
            </Modal>
        </div>
    )
}

const Timeline = ({msg}) => {

    let timelineMessage: string = ""
    const { t } = useTranslation()

    const today = new Date();
    const date = new Date(msg.text || "");
    let dayPassed = 0

    if (!isNaN(date.getTime())) {
        dayPassed = Math.round((today.getTime() - date.getTime()) / (1000 * 3600 * 24))
    }

    if ( dayPassed <= 0 ) {
        timelineMessage = t('messages.today')
    } else if ( dayPassed === 1 ) {
        timelineMessage = t('messages.yesterday')
    } else {
        timelineMessage = Moment(new Date(msg.text)).format("DD-MM-YY")
    }

    return (
        <div className="skp-timeline-box">
            <div className="skp-timeline-divider"></div>
            <div className="skp-text-primary skp-text-default">{timelineMessage}</div>
            <div className="skp-timeline-divider"></div>
        </div>
    );
};

const EndMessage = ({scrolldown}) => {

    useEffect(()=>{
        scrolldown()
    },[])

    return (
        <></>
    );
};
  
export { Timeline, TextMessage, MediaMessage, ReplyMessage, EndMessage };