import React from "react";
import Scrollbars from "react-custom-scrollbars";
import {isMobile} from 'react-device-detect';

const DiscordIdTooltip = () => {
  return (
    <Scrollbars style={{ height: isMobile ? 200 : 400 }}>
    <div className="skp-text-small">
      <h6>Locating your Discord ID</h6>
      <ol>
        <li>Go to Discord.</li>
        <li>
          Your Discord ID id is located at the bottom left.
          <img
            src="https://i.ibb.co/sgpJ6bV/discord-id.png"
            alt="discord-id"
            style={{ width: "100%" }}
          />
        </li>
        <li>Hover over your Discord ID and click to copy it.</li>
        <li>Discord ID format is user.name#1234</li>
      </ol>
    </div>
    </Scrollbars>
  );
};

export default DiscordIdTooltip;
