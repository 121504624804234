import { useState } from "react";
import SingleMatchUserTooltip from "../MatchComponents/SingleMatchUserTooltip";
import Modal from "../Modal";
import UserProfile from "../UserProfile";

const UserProfileWithDetailsModal = (props) => {
  const { userId, tournament, reverse, username } = props;
  const [showUserProfileModal, setShowUserProfileModal] = useState({
    show: false,
    userId: undefined,
  });

  const onUserNameClick = (e, userId) => {
    e.preventDefault();
    e.stopPropagation();
    setShowUserProfileModal({
      show: true,
      userId: userId,
    });
  };

  return (
    <>
      <UserProfile
        userId={userId}
        reverse={reverse}
        onUserNameClick={(e) => onUserNameClick(e, userId)}
      >
          {props.children}
      </UserProfile>
      <Modal
        show={showUserProfileModal.show}
        size={"small"}
        onClose={() => setShowUserProfileModal({ show: false })}
      >
        <SingleMatchUserTooltip
          userId={showUserProfileModal.userId}
          tournament={tournament}
          username={username}
        />
      </Modal>
    </>
  );
};
export default UserProfileWithDetailsModal;
