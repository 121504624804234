import { useState, useEffect } from "react";
import TournamentService from "../../services/TournamentService";
import ITournament from "../Interfaces/Tournament";

const useImportedStatus = (tournamentId:string) => {
  const [imported, setImported] = useState<ITournament>();
  useEffect(() => {
    TournamentService.getTournamentsByYuniteId(tournamentId).then(
      (Rtournament) => {
        setImported(Rtournament);
      }
    );
  }, [tournamentId]);
  return imported;
}

export default useImportedStatus
