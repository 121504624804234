import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

type Props = {
  show?: boolean;
  loading?: boolean;
  size?: string;
  title?: string;
  confirmation?: string;
  overflow?: boolean;
  cancel?: string;
  hideClose?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  children?: JSX.Element;
};

const Modal = (props: Props) => {
  const { show, size, title, cancel, confirmation, loading, overflow, hideClose=false } = props;
//   if (!show) {
//     return null;
//   }

  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{enter: 0, exit: 300}}>
      <div
        className="skp-modal-overlay"
        id="skpModalOverlay"
        onClick={props.onClose}
      >
        <div
          className={`skp-modal skp-modal-${size}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={overflow ? "skp-modal-content-no-overflow" : "skp-modal-content"}>
            {!hideClose && (<div
                className="skp-modal-close-icon skp-text-light"
                onClick={props.onClose}
                style={{ zIndex: 9 }}
              >
                <i className="las la-times"></i>
              </div>
            )}
            {/* Modal Header */}
            <div className="skp-modal-header">
              <h4 className="skp-text-primary">{title}</h4>
            </div>
            {/* Modal Body */}
            <div className="skp-modal-body">{props.children}</div>
            {/* Modal Footer */}
            <div className="skp-modal-footer">
              {cancel && (
                <button
                  className="skp-btn skp-btn-secondary"
                  onClick={props.onClose}
                >
                  {cancel}
                </button>
              )}
              {confirmation && (
                <button
                  className="skp-btn skp-btn-primary"
                  disabled={loading}
                  onClick={props.onConfirm}
                >
                  {props.loading ? (
                    <span className="loading"></span>
                  ) : (
                    `${confirmation}`
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,

    document.getElementById("root") as HTMLElement
  );
};

export default Modal;
