import axios from "axios";
import Keycloak from "keycloak-js";
import EventBus from "react-native-event-bus";
import constant from "../constants";
import qs from "query-string";

const _kc = new (Keycloak as any)(constant.KEYCLOAK_JSON_URL);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = () => {
  return _kc
    .init({
      onLoad: "check-sso",
    })
    .then(() => {
      return _kc;
    });
};

const initTokenKeycloack = (token: string, refreshToken: string) => {
  return _kc
    .init({
      checkLoginIframe: false,
      promiseType: "native",
      token: token,
      refreshToken: refreshToken,
    })
    .then(() => {
      return _kc;
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const doRegister = _kc.register;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const loginWithGoogle = () => _kc.createLoginUrl({ idpHint: "google" });

const showLoginModal = (message: string) => {
  EventBus.getInstance().fireEvent("SHOW_LOGIN_MODAL", { message: message });
};

const updateToken = (successCallback: any) =>
  _kc
    .updateToken(5)
    .then(successCallback)
    .catch(() => showLoginModal(constant.MESSAGES.LOGIN.SESSION_EXPIRED));

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getFirstName = () => _kc.tokenParsed?.given_name;
const getLastName = () => _kc.tokenParsed?.family_name;
const getTokenParsed = () => _kc.tokenParsed;

const hasRole = (roles: Array<string>) =>
  roles.some((role: string) => _kc.hasRealmRole(role));

const getAuthToken = async (username: string, password: string) => {
  const data = {
    username: username,
    password: password,
    client_id: "skillpro-fe",
    grant_type: "password",
  };

  const url = `${constant.APP_URL}/auth/realms/SkillPro/protocol/openid-connect/token`;

  const response = await axios({
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify(data),
    url,
  });

  return response;
};

const getAuthUserInfo = (token:string) => {
  const url = `${constant.APP_URL}/auth/realms/SkillPro/protocol/openid-connect/userinfo`;

  return axios({
    method: "get",
    url: url,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    return response;
  });
}

const KeyCloackService = {
  initKeycloak,
  initTokenKeycloack,
  doLogin,
  doLogout,
  loginWithGoogle,
  doRegister,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFirstName,
  getLastName,
  getTokenParsed,
  hasRole,
  showLoginModal,
  getAuthToken,
  getAuthUserInfo
};

export default KeyCloackService;
