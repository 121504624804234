import { Scrollbars } from "react-custom-scrollbars";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ShopService from "../../../services/ShopService";
import ITournament from "../../Interfaces/Tournament";
import TournamentService from "../../../services/TournamentService";
import TournamentImageBanner from "../../UiLibrary/TournamentImageBanner";
import { AnimatedList } from "react-animated-list";

const TournamentInfo = (props: { tournamentId: string }) => {
  const { tournamentId } = props;
  const [tournamentInfo, setTournament] = useState<ITournament>();
  const imgPlaceholder =
    "https://static.bimago.pl/mediacache/catalog/product/cache/4/2/117624/image/750x1120/7c0505584f03c89c005bad1e2b9708a6/117624_1.jpg";
  useEffect(() => {
    TournamentService.getTournament(tournamentId).then((response) => {
      setTournament(response);
    });
  }, [tournamentId]);
  return (
    <>
      <span
        className="column col-1 flex-centered"
        style={{ position: "relative", height: 50 }}
      >
        {tournamentInfo && (
          <TournamentImageBanner
            tournament={tournamentInfo}
            style={{ height: "100%" }}
          />
        )}
        {/* <img
          src={tournamentInfo?.thumbnail || imgPlaceholder}
          style={{ width: "100%" }}
        /> */}
      </span>
      <span className="column col-5 pl-2">
        <Link
          className="skp-link-hover"
          to={`/tournaments/${tournamentInfo?.id}/overview`}
        >
          {tournamentInfo?.name}
        </Link>
      </span>
      <span className="column col-3">{tournamentInfo?.id}</span>
    </>
  );
};

const Payments = () => {
  const ownerId = useParams<{ id: string }>().id;
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setLoading(true);
    if(ownerId){
      ShopService.getOrderByUserId(ownerId).then((response) => {
        setOrders(response.data.data);
        setLoading(false);
      });
    }
    
    return () => {
      setOrders([]);
      setLoading(false);
    };
  }, [ownerId]);

  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-full-page">
          <div className="container">
            <div className="columns">
              <div className="column col-12 text-center">
                <h4 className="skp-heading skp-primary-pink">Payments</h4>
              </div>

              {orders.length === 0 && <>
                <div className="column col-12 flex-centered skp-text-primary" style={{height: 150}}>
                    There are no payments at moment!
                </div>
              </>}

              {orders.length > 0 && <dl className="column col-12 skp-text-primary">
                <dd className="columns">
                  <span className="column col-1"></span>
                  <span className="column col-5 pl-2">Tournament Name</span>
                  <span className="column col-3">Tournament ID</span>
                  <span className="column col-3">Order ID</span>
                </dd>
                <AnimatedList animation={"grow"}>
                  {orders.map((order: any) => (
                    <dd className="columns skp-card-hover flex-centered p-2">
                      <TournamentInfo tournamentId={order.tournamentId} />
                      <span className="column col-3">{order?.id}</span>
                    </dd>
                  ))}
                </AnimatedList>
              </dl>}
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default Payments;
