import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import TeamService from "../../../services/TeamService";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import KeyCloackService from "../../../services/KeyCloackService";
import LoginOrRegisterModal from "../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../constants";
import Modal from "../../UiLibrary/Modal";
import "./brand-join-page.scss";
import TeamBrandLogo from "../../UiLibrary/TeamBrandLogo";
import { useTranslation } from "react-i18next";
import IBrand from "../../Interfaces/Brand";

const BrandJoin = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  const [brandInfo, setBrandInfo] = useState<IBrand>();
  let params = useParams<{ id: string; tab: string; slug: string }>();
  const brandSlug = params.slug;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
      TeamService.getBrand(brandSlug!).then((response) => {
        setBrandInfo(response.data);
      });
  }, [brandSlug]);

  const joinTeam = () => {
    if (!user?.id) {
      return;
    }
    TeamService.inviteMember(brandInfo?.id!, user.id)
      .then(() => {
        toast.success(
          "Team Joined Successfully! You will become part of the team once one of the team's admins accept the invitation."
        );
        navigate(`/brands/${brandSlug}/overview`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const acceptInvitation = () => {
    if (!user?.id) {
      return;
    }

    TeamService.acceptMember(brandInfo?.id!, user.id)
      .then(() => {
        toast.success(
          "Team Joined Successfully!"
        );
        navigate(`/brands/${brandSlug}/overview`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const isMemberAlready = brandInfo?.members.includes(user?.id || "");
  const isPendingMember =
    brandInfo?.pendings?.findIndex((member) => member.id === user?.id) !== -1;
  
  if (isPendingMember) {
  }

  const memberStatus = brandInfo?.pendings?.find((member) => member.id === user?.id)?.status


    return (
        <>
            <div className="brand-join-page container flex-centered">
                <div className="columns text-center">
                    <div className="column col-12 profile-picture col-xs-12 text-center-xs">
                        <TeamBrandLogo brand={brandInfo} />
                    </div>
                    <div className="space-divider-20"></div>
                    <div className="column col-12">
                        <h1 className="skp-text-primary">
                            <Link className="skp-link-hover" to={`/brands/${brandSlug}`}>{brandInfo?.name}</Link>
                        </h1>
                    </div>
                    <div className="column col-12 skp-text-light">
                        {t('brands.config.member',{count:brandInfo?.members.length})}{" "}
                    </div>
                    <div className="space-divider-20"></div>

                    {KeyCloackService.isLoggedIn() && !isMemberAlready && !isPendingMember && (
                        <div className="column col-12">
                            <button onClick={joinTeam} className="skp-btn skp-btn-primary">
                                {t('brands.main.join', {brand:brandInfo?.name})}
                            </button>
                        </div>
                    )}

                    {KeyCloackService.isLoggedIn() && !isMemberAlready && 
                        isPendingMember && memberStatus === 1 && (
                        <div className="column col-12">
                            <button onClick={acceptInvitation} className="skp-btn skp-btn-ternary">
                                {t('brands.main.acceptInvitation')}
                            </button>
                        </div>
                    )}

                    {(isMemberAlready) && (
                        <div className="column col-12 skp-text-primary skp-text-default">
                            {t('brands.main.alreadyMember')}
                        </div>
                    )}

                    {(isPendingMember && memberStatus === 0) && (
                        <div className="column col-12 skp-text-primary skp-text-default">
                            {t('brands.main.underReview')}
                        </div>
                    )}

                    {!KeyCloackService.isLoggedIn() && (
                        <div className="column col-12">
                            <button onClick={() => setShowModal(true)}
                                className="skp-btn skp-btn-primary"
                            >
                                Loginzzz
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {!KeyCloackService.isLoggedIn() && (
                <Modal
                    show={showModal}
                    size="small"
                    onClose={() => setShowModal(false)}
                >
                    <LoginOrRegisterModal
                        message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
                        onClose={() => setShowModal(false)}
                    />
                </Modal>
            )}
        </>
  );
};

export default BrandJoin;
