import { createContext, useContext } from "react";
import IMatch from "../Interfaces/Match";
import ITournament from "../Interfaces/Tournament";

export type ITournamentMatches = {
  matches: Array<IMatch>;
  tournament: ITournament;
};

export type TournamentMatchesContent = {
  tournamentMatches: ITournamentMatches | undefined;
  setTournamentMatches: (user: ITournamentMatches | undefined) => void;
};
export const TournamentMatchesContext = createContext<TournamentMatchesContent>(
  {
    tournamentMatches: undefined, // set a default value
    setTournamentMatches: () => {},
  }
);
export const useGlobalContext = () => useContext(TournamentMatchesContext);
