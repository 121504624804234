import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useNavigate, useParams } from "react-router-dom";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import ICompetition from "../../Interfaces/Competition";
import TournamentService from "../../../services/TournamentService";
import { useTranslation } from "react-i18next";
import UploadButton from "../../UiLibrary/UploadButton/UploadButton";

const CompetitionPage = () => {
    let navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { t } = useTranslation()

    const params = useParams<{ id: string; tab: string }>();
    const competitionId = params.id;
    const currentTab = params.tab;
    const [competition, setCompetition] = useState<ICompetition>();
    const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
    const [showUploadModal, setShowUploadModal] = useState<{
        show: boolean;
        type?: string | undefined;
    }>({
        show: false,
        type: undefined,
    });
    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState(false);

    const tabs = [
        { name: t('tabs.overview'), url: "overview" },
        { name: "Tournaments", url: "tournaments" },
    ];

    useEffect(() => {
        if (!currentTab) {
            navigate(`/competitions/${competitionId}/overview`);
        }
        TournamentService.getCompetition(competitionId!).then((response) => {
            setCompetition(response.data)
        })
    }, [competitionId, currentTab, navigate]);
    
    const uploadPicture = async (image: IFileBase64Image, type: string) => {
    }

    return (
        <>
            <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
                <div className="skp-page-with-image-banner">
                    {user?.id === competition?.owner && (
                        <UploadButton
                            type="banner"
                            loading={loading}
                            onUpdateImage={(image) => uploadPicture(image, "profile_banner")}
                        >
                            <button className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default">
                                <i className="las la-camera"></i> {t('component.uploadBanner')}
                            </button>
                        </UploadButton>
                    )}
                    <div className="skp-image-banner">
                        {competition && competition?.banner && !imageError && (
                        <img
                            alt="profile-banner"
                            src={competition?.banner}
                            onError={() => setImageError(true)}
                        />
                        )}
                        <div className="transparent-black"></div>
                    </div>
                
                    {/* PAGE TOP */}
                    <div className="container page-top">
                        <div className="columns page-top">
                            <div className="column col-8 col-xs-12 text-center-xs">
                                <span className="skp-text-label skp-text-light">
                                    {t('common.tournament')}
                                </span>{" "}
                            </div>
                        </div>
                    </div>
                    {/* END PAGE TOP */}
                    <div className="space-divider-5"></div>
                    {/* TABS HEADER */}
                    <div className="container tabs-header">
                        <div className="columns">
                            <div className="column col-12">
                                <ul className="tab skp-text-light">
                                    {tabs.map((tab) => (
                                        <li
                                            key={tab.name}
                                            className={
                                                currentTab === tab.url ? "tab-item active" : "tab-item"
                                            }
                                        >
                                        <Link
                                            to={`/competitions/${competitionId}/${tab.url}`}
                                            className={`${currentTab === tab.url && "active"}`}
                                        >
                                            {tab.name}{" "}
                                        </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* END TABS HEADER */}
                    {/* TABS BODY */}
                    <div className="container tabs-body">
                        <div className="columns">
                            {currentTab === tabs[0].url && competition && (
                                <div>overview</div>
                            )}
                        </div>
                    </div>
                    {/* END TABS BODY */}
                </div>
            </Scrollbars>
        </>
    )
};

export default CompetitionPage;