import { useContext, useState } from "react";
import UserProfile from "../../../UiLibrary/UserProfile";
import KeyCloackService from "../../../../services/KeyCloackService";
import { Link } from "react-router-dom";
import TeamService from "../../../../services/TeamService";
import { toast } from "react-toastify";
import Modal from "../../../UiLibrary/Modal";
import ITeam from "../../../Interfaces/Team";
import { UserContext } from "../../../Context/UserContext";

type Props = {
  team: ITeam | undefined;
  setTeam: (team: ITeam) => void;
  doLogin: () => void;
};

const MembersTab = (props: Props) => {
  const { team } = props;
  const { user } = useContext(UserContext);
  const [showRemoveModal, setShowRemoveModal] = useState<{
    show: boolean;
    playerId?: string | undefined;
  }>({ show: false, playerId: undefined });
  const [loading, setloading] = useState(false);

  const removeMember = () => {
    if (!team || !showRemoveModal.playerId) {
      return;
    }
    setloading(true);
    const membersId = [showRemoveModal.playerId];
    TeamService.removeMember(team.id, membersId)
      .then((Rteam) => {
        toast.success("Member Removed Successfully");
        props.setTeam(Rteam);
        setloading(false);
        setShowRemoveModal({ show: false });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setShowRemoveModal({ show: false });
        setloading(false);
      });
  };
  return (
    <div className="players-tab container tab-with-table">
      {team && team.members?.length > 0 && (
        <div className="columns">
          <div className="column col-12">
            <div className="space-divider-20"></div>
            <dl>
              {team?.members &&
                team?.members.map((playerId) => (
                  <dd
                    key={playerId}
                    className="columns flex-centered show-on-hover"
                  >
                    <div className="column col-8">
                      <Link to={`/users/${playerId}/overview`}>
                        <UserProfile userId={playerId}>
                          {team?.owner === playerId && (
                            <span className="skp-chip status-captain skp-text-xxsmall">
                              Leader
                            </span>
                          )}
                        </UserProfile>
                      </Link>
                    </div>
                    <div className="column col-4 text-right">
                      {user?.id === team?.owner && team?.owner !== playerId && (
                        <button
                          className="skp-btn skp-btn-alert"
                          onClick={() =>
                            setShowRemoveModal({
                              show: true,
                              playerId: playerId,
                            })
                          }
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </dd>
                ))}
            </dl>
          </div>
          <div className="space-divider-40"></div>
          {/* Login button */}
          {!KeyCloackService.isLoggedIn() && (
            <div className="column col-12 text-center">
              <div className="skp-text-light">
                You must log in to see Players information.
              </div>
              <div className="space-divider-20"></div>
              <button
                className="skp-btn skp-btn-primary"
                onClick={props.doLogin}
              >
                Login
              </button>
            </div>
          )}
        </div>
      )}
      {team?.members?.length === 0 && (
        <div className="columns skp-text-light text-center">
          <div className="space-divider"></div>
          <div className="column col-12">
            <h1>
              <i className="las la-user-friends"></i>
            </h1>
          </div>
          <div className="column col-12">
            <h5>No players</h5>
          </div>
          <div className="column col-12">
            There are currently no Players registered for this Tournament.
          </div>
        </div>
      )}

      <Modal
        show={showRemoveModal.show}
        onClose={() => setShowRemoveModal({ show: false })}
        onConfirm={() => removeMember()}
        confirmation="Remove Player"
        title="Remove Player"
        size="small"
        loading={loading}
      >
        <>
          <div className="space-divider-20"></div>
          <div className="skp-text-primary text-center">
            Do you want to remove selected player?
          </div>
          <div className="space-divider-20"></div>
        </>
      </Modal>
    </div>
  );
};

export default MembersTab;
