import React, { useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import * as Yup from "yup";
import constant from "../../../../constants";
import ITournament from "../../../Interfaces/Tournament";
import GAMES from "../../../../constants-games";
import AddAdminModal from "../../../Modals/AddAdminModal/AddAdminModal";
import UserProfileWithDetailsModal from "../../../UiLibrary/User/UserProfileWithDetailsModal";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  name: Yup.string().required("Field is required").nullable(),
  teamSize: Yup.number(),
  reserves: Yup.number().max(10, "Must be less than 10"),
});

type Props = {
  loading: boolean | undefined;
  tournament: ITournament;
  onSubmit: (dataToPass: FormModel, file?: any, type?: string) => void;
  loadTournament: (tournament: ITournament | undefined) => void;
};

type FormModel = {
  name: string;
  teamSize: number | string;
  type: string;
  reserves: number | undefined;
  privateCheck?: Array<string>;
  password: string | null;
  console: Array<string>;
  matchType?: number;
  playerType?: number;
  open?: boolean;
  price?: number;
  paypalClientId?: string;
  isTestCheck?: Array<string>;
  isTest?: boolean;
  isHideTournamentCheck?: Array<string>;
  hideTournament?: boolean;
  isCheckinCheck?: Array<string>;
  checkin?: boolean;
};

function GeneralSettings(props: Props) {
  const { loading, tournament } = props;
  const { t } = useTranslation()
  const formikRef = useRef<FormikProps<FormModel>>(null);

  const teamSizeOptions = GAMES[tournament?.game].TEAM_SIZES_OPTIONS;
  var array = GAMES[tournament?.game].TEAM_MATCH_TYPE_MAP ? Object.keys(GAMES[tournament?.game].TEAM_MATCH_TYPE_MAP).map((key) => [key, GAMES[tournament?.game].TEAM_MATCH_TYPE_MAP[key]]) : [];
  const matchTypeOptions = Array.from(array, ([value, key]) => ({ value, key }));

  const onSubmit = (values: FormModel) => {
    if (values.teamSize) {
      values.teamSize = Number(values.teamSize);
      values.playerType = values.teamSize === 1 ? 0 : 1;
    }
    if (values?.privateCheck && values.privateCheck.length > 0) {
      values.open = false;
    } else if (values?.privateCheck?.length === 0) {
      values.open = true;
    }

    if (values?.isTestCheck && values.isTestCheck.length > 0) {
      values.isTest = true;
    } else {
      values.isTest = false;
    }

    if (values?.isHideTournamentCheck && values.isHideTournamentCheck.length > 0) {
      values.hideTournament = true;
    } else {
      values.hideTournament = false;
    }

    if (values?.isCheckinCheck && values.isCheckinCheck.length > 0) {
      values.checkin = true;
    } else {
      values.checkin = false;
    }

    if (values.matchType) {
      values.matchType = Number(values.matchType);
    }

    let { privateCheck, ...tmpValues } = values;
    let { isTestCheck, isCheckinCheck, isHideTournamentCheck, ...newValues } = tmpValues;  

    props.onSubmit(newValues);
  };

  const initialValues: FormModel = {
    name: tournament?.name,
    teamSize: tournament?.teamSize,
    matchType: tournament?.matchType,
    type: tournament?.type,
    reserves: tournament?.reserves,
    privateCheck: tournament?.open === false ? ["private"] : [],
    password: null,
    console: tournament?.console || [],
    paypalClientId: tournament?.paypalClientId,
    price: tournament?.price,
    isTestCheck: tournament?.isTest === false ? [] : ["isTest"],
    isHideTournamentCheck: tournament?.hideTournament === false ? [] : ["hideTournament"],
    isCheckinCheck: tournament?.checkin === undefined || tournament.checkin ? ["isCheckinEnable"] : []
  };
  return (
    <>
      <div className="container">
        <div className="columns">
          <div className="column col-8 col-xs-12">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              innerRef={formikRef}
              enableReinitialize={true}
            >
              {({ isValid }) => (
                <Form>
                  <div className="columns">
                    <div className="column col-5 col-xs-12">
                      <h6 className="skp-text-primary">
                        {t('tournament.tournamentInformation')}
                      </h6>
                      <div className="column col-12">
                        {/* Name */}
                        <FormikControl
                          control="input"
                          label={t('tournament.config.name')}
                          name="name"
                          placeholder={t('tournament.config.name')}
                          disabled={tournament?.yuniteId !== undefined}
                        />
                        {!tournament?.yuniteId && (
                          <>
                            <div className="columns">
                              {/* Team Size */}
                              <div className="column col-7 col-xs-12">
                                 {teamSizeOptions && (
                                  <FormikControl
                                    control="select"
                                    label={t('tournament.config.teamSize')}
                                    name="teamSize"
                                    options={teamSizeOptions}
                                    disabled={tournament?.teams.length > 0}
                                  />
                                )}
                              </div>
                              {/* Reserves */}
                              <div className="column col-5 col-xs-12">
                                {tournament?.playerType === 1 && (
                                  <FormikControl
                                    control="input"
                                    type="number"
                                    label={t('tournament.config.reserves')}
                                    name="reserves"
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {/* Team Size */}
                        <div className="col-12 col-xs-12">
                          {matchTypeOptions && (
                            <FormikControl
                              control="select"
                              label={t('tournament.config.matchType')}
                              name="matchType"
                              options={matchTypeOptions}
                              disabled={tournament?.teams.length > 0}
                            />
                          )}
                        </div>

                        {tournament?.yuniteId && (
                          <FormikControl
                            control="input"
                            label={t('tournament.config.type')}
                            name="type"
                            disabled
                          />
                        )}
                        <div className="space-divider-20"></div>
                        <h6 className="skp-text-primary">
                          {t('tournament.tournamentPlatforms')}
                        </h6>
                        {!tournament?.yuniteId && (
                          <FormikControl
                            control="checkbox"
                            label={t('tournament.config.selectMultiplePlatforms')}
                            name="console"
                            options={constant.CONSOLE_OPTIONS}
                          />
                        )}
                      </div>
                    </div>
                    <div className="column col-1 chide-xs"></div>
                    <div className="column col-6 col-xs-12">
                      <div className="space-divider-20 show-xs"></div>
                      <h6 className="skp-text-primary">{t('tournament.tournamentSettings')}</h6>
                      <div className="columns skp-text-light">
                        <div className="column col-4 flex-centered">
                          <FormikControl
                            control="checkbox"
                            name="privateCheck"
                            options={[{ key: t('tournament.config.privateTournament'), value: "private" }]}
                            label={t('tournament.config.privateTournament')}
                          />
                        </div>
                        {formikRef?.current?.values?.privateCheck &&
                          formikRef.current.values.privateCheck.length > 0 && (
                            <div className="column col-8 col-xs-12">
                              <FormikControl
                                control="input"
                                type="password"
                                name="password"
                                label={t('tournament.config.tournamentPassword')}
                                disabled={
                                  formikRef.current?.values?.privateCheck
                                    ?.length === 0
                                }
                              />
                            </div>
                          )}

                        <div className="column col-12">
                          <FormikControl
                            control="input"
                            type="number"
                            name="price"
                            label={t('tournament.config.price')}
                          />
                        </div>
                        <div className="column col-12">
                          <FormikControl
                            control="input"
                            type="string"
                            name="paypalClientId"
                            label="Paypal Client Id"
                          />
                        </div>
                        <div className="column col-6 flex-centered">
                          <FormikControl
                            control="checkbox"
                            name="isHideTournamentCheck"
                            options={[{ key: t('tournament.config.hideTournament'), value: "hideTournament" }]}
                            label={t('tournament.config.hideTournament')}
                          />
                        </div>
                        <div className="column col-6 flex-centered">
                          <FormikControl
                            control="checkbox"
                            name="isCheckinCheck"
                            options={[{ key: t('tournament.config.enableCheckin'), value: "isCheckinEnable" }]}
                            label={t('tournament.config.enableCheckin')}
                          />
                        </div>
                        <div className="column col-6 flex-centered">
                          <FormikControl
                            control="checkbox"
                            name="isTestCheck"
                            options={[{ key: t('tournament.config.testTournament'), value: "isTest" }]}
                            label={t('tournament.config.testTournament')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-divider-20"></div>
                    <div className="column col-12 text-right">
                      <button
                        type="submit"
                        disabled={loading || !isValid}
                        className="skp-btn skp-btn-primary"
                      >
                        {loading ? <span className="loading"></span> : t('common.save')}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="column col-4 col-xs-12">
            <h6 className="skp-text-primary">{t('tournament.config.tournamentAdmins')}</h6>
            <AddAdminModal
              tournament={tournament}
              loadTournament={props.loadTournament}
            ></AddAdminModal>
            <dl>
              {(!tournament?.admins || tournament?.admins?.length === 0) && (
                <dd>
                  <h6 className="skp-text-light">{t('tournament.config.noAdminsAdded')}</h6>
                </dd>
              )}
              {tournament?.admins?.map((admin) => (
                <dd>
                  <UserProfileWithDetailsModal
                    userId={admin}
                  ></UserProfileWithDetailsModal>
                </dd>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralSettings;
