import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { UserContext } from "../Context/UserContext";
import Dropdown from "../UiLibrary/Dropdown";
import { useTranslation } from "react-i18next";

const SiteAdminDropdown = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation()
  const { user } = useContext(UserContext);

  return (
    <OutsideClickHandler onOutsideClick={() => setShow(false)}>
      <div className="profile-dropdown">
      <button
          className="skp-btn skp-btn-circle ml-2 mr-2"
          onClick={() => setShow(true)}
        >
          <i className="las la-cog"></i>
        </button>
        <Dropdown show={show}>
          <div
            className="close-icon show-xs skp-text-light"
            onClick={() => setShow(false)}
          >
            <i className="las la-times"></i>
          </div>
          <ul>
            <li onClick={() => setShow(false)}>
              <Link to={`/roles-admin`}>
                <i className="skp-icon-teams"></i> {t('header.editRoles')}
              </Link>
            </li>
          </ul>
        </Dropdown>
      </div>
    </OutsideClickHandler>
  );
};

export default SiteAdminDropdown;
