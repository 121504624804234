import React, { useContext, useState } from "react";
// import UserProfile from "../../../UiLibrary/UserProfile";
import UploadImageBox from "../../../../../../../UiLibrary/UploadImageBox/UploadImageBox";
import Modal from "../../../Modal";
import ImageService from "../../../../../../../../services/ImageService";
import MatchService from "../../../../../../../../services/MatchService";
import { toast } from "react-toastify";
import ReactTimeAgo from "react-time-ago";
import LoadingButton from "../../../../../../../UiLibrary/LoadingButton/LoadingButton";
import Moment from "moment";
import IMatch from "../../../../../../../Interfaces/Match";
import ITournament from "../../../../../../../Interfaces/Tournament";
// import { UserContext } from "../../../Context/UserContext";
import IFileBase64Image from "../../../../../../../Interfaces/FileBase64Image";
import { UserContext } from "../../../../../../../Context/UserContext";
import PlayPlayUser from "../../../UserComponents/PlayPlayUser";

type Props = {
  match: IMatch | undefined
  tournament: ITournament
  matchId: string
  canEditMatch: boolean
  canWatchInfo: boolean
  onSetMatch: (match: IMatch) => void
  isPlayerHome: boolean
  isPlayerAway: boolean
}

interface IScreenshotModal {
  show: boolean
  imgSrc?: string | undefined
  playerId?: string | undefined
  mediaId?: string | undefined
  timestamp?: string | undefined
}

const ScreenshotsTab = ({ match, canEditMatch, canWatchInfo, matchId, tournament, onSetMatch, isPlayerHome, isPlayerAway }:Props) => {
  const {user} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [screenshot, setScreenshot] = useState<IFileBase64Image>();
  const [showUploadScreenshot, setShowUploadScreenshot] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [showScreenShotModal, setShowScreenShotModal] = useState<IScreenshotModal | undefined>({
    show: false,
    imgSrc: undefined,
    playerId: "1",
    mediaId: undefined,
    timestamp: undefined
  });

  let teamId:string | undefined

  if (isPlayerHome) {
    teamId = match?.result?.scores[0].teamId;
  } else if (isPlayerAway) {
    teamId = match?.result?.scores[1].teamId;
  }

  const canUploadScreenshot =
    (isPlayerAway || isPlayerHome) &&
    (match?.status === 1 || match?.status === 2); 

  const emptyPlayerMedia =
    (!match?.result?.scores[0].playerMedia ||
      match?.result?.scores[0].playerMedia.length === 0) &&
    (!match?.result?.scores[1].playerMedia ||
      match?.result?.scores[1].playerMedia.length === 0);

  const uploadScreenshot = () => {
    if(!screenshot) {
      return
    }

    setImageUploading(true);
    ImageService.uploadImage(screenshot).then((response) => {
      const data = {
        mediaUrl: ImageService.generatePublicUrl(response.data),
      };
      MatchService.updateMatchScoreMedia(matchId, teamId, user?.id, data)
        .then((response) => {
          onSetMatch(response.data.data);
          setShowUploadScreenshot(false);
          setImageUploading(false);
          setScreenshot(undefined);
        })
        .catch((error) => {
          setImageUploading(false);
          setShowUploadScreenshot(false);
          toast.error("Error uploading image");
          setScreenshot(undefined);
        });
    });
  };

  const deleteMedia = () => {
    setLoading(true);
    
    MatchService.deleteMedia(
      matchId,
      showScreenShotModal?.playerId,
      showScreenShotModal?.mediaId
    )
      .then((response) => {
        onSetMatch(response.data.data);
        setLoading(false);
        setShowScreenShotModal({ show: false });
        toast.info("Screenshot removed successfully");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="columns tabs-body">
      {/* Header */}
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h5 className="skp-text-primary">Result Screenshots</h5>
        {canUploadScreenshot && (
          <a
            onClick={() => setShowUploadScreenshot(!showUploadScreenshot)}
            className="skp-text-accent skp-text-default"
          >
            {!showUploadScreenshot ? " Upload Screenshot" : " See screenshots"}
          </a>
        )}
      </div>
      {/* Body */}
      {canWatchInfo && (
        <>
      {/* No screenshots */}
      <div className="column col-12 text-center">
        <div className="space-divider-20"></div>
        {emptyPlayerMedia && !showUploadScreenshot && (
          <div className="skp-text-primary">
            <h2>
              <i className="lar la-image"></i>
            </h2>
            <span>No screenshots uploaded yet! </span>
          </div>
        )}
      </div>
      {/* Screenshots and upload */}
      <div className="column col-12">
        {!showUploadScreenshot &&
          match?.result?.scores.map((score, index) => {
            return (
                  <div key={"ss"+index} className="float-left">
                {score.playerMedia &&
                      score.playerMedia.map((media,index) => (
                        <div className="uploaded-image-wrapper" key={"media"+index}>
                      {media.mediaUrl && (
                            <div>
                          {/* <UserProfile
                            userId={score.playerResults[0].playerId}
                          /> */}
                          <div className="m-2"></div>
                          <div className="file-base64-label-wrapper no-border">
                            <div
                              className="file-image-preview"
                              onClick={() =>
                                setShowScreenShotModal({
                                  show: true,
                                  imgSrc: media.mediaUrl,
                                  playerId: media.playerId,
                                  mediaId: media.id,
                                  timestamp: media.timestamp,
                                })
                              }
                            >
                              <img alt={`s-${index}`} src={media?.mediaUrl} />
                            </div>
                          </div>

                          <div className="skp-text-small skp-text-primary">
                            {Moment(media?.timestamp).format("MMM DD, hh:mm A")}{" "}
                            <br></br>{" "}
                            {media?.timestamp && <span className="skp-text-xxsmall">
                                  <ReactTimeAgo date={new Date(media.timestamp)} />
                            </span>}
                          </div>
                          <div className="m-2"></div>
                        </div>
                      )}
                        </div>
                  ))}
              </div>
            );
          })}

        {showUploadScreenshot && (isPlayerHome || isPlayerAway) && (
          <div className="file-base64-label-wrapper">
            <UploadImageBox
              onUpload={(image) => setScreenshot(image)}
              onRemovePreview={() => setScreenshot(undefined)}
            />
          </div>
        )}
      </div>
        </>
      )}
      {!canWatchInfo && (
        <div className="column col-12">
          <div className="columns">
            <div className="column col-12">
              <div className="columns skp-text-primary text-center">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h1>
                    <i className="las la-angry"></i>
                  </h1>
                </div>
                <div className="column col-12">
                  You are not allowed to see the information from this match.                
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUploadScreenshot && canEditMatch && (
        <div className="column col-12">
          <div className="space-divider-5"></div>
          <button
            disabled={!screenshot || imageUploading}
            onClick={uploadScreenshot}
            className="skp-btn skp-btn-primary"
          >
            {imageUploading ? (
              <span className="loading"></span>
            ) : (
              "Upload Screenshot"
            )}
          </button>
        </div>
      )}

      <Modal
        show={showScreenShotModal?.show}
        onClose={() => setShowScreenShotModal({ show: false })}
        size="large"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 flex-centered">
              <div
                className="screenshot-preview"
                style={{
                  "background-image": `url(${showScreenShotModal?.imgSrc})`,
                } as React.CSSProperties}
              />
            </div>
          </div>
          <div className="space-divider-20"></div>
          <div className="columns">
            <div className="column col-7 col-xs-12 playnplay-match">
              <div className="user-profile">
              <PlayPlayUser playerId={showScreenShotModal?.playerId} hideCheckin={true}>
                <span className="skp-text-primary skp-text-small">
                  {showScreenShotModal?.timestamp && <ReactTimeAgo date={showScreenShotModal.timestamp} />} {" - "}
                  {Moment(showScreenShotModal?.timestamp).format("MMM DD, hh:mm A")}
                </span>
              </PlayPlayUser>
              </div>
            </div>
            {canEditMatch && match?.status !== 3 && (
            <div
              className="column col-5 flex-centered col-xs-12"
              style={{ justifyContent: "right" }}
            >
              {(showScreenShotModal?.playerId === user?.id ||
                tournament?.owner === user?.id) && (
                <LoadingButton
                  className="skp-btn skp-btn-alert"
                  label="Delete Media"
                  onBtnClick={deleteMedia}
                  loading={loading}
                  disabled={loading}
                />
              )}
            </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScreenshotsTab;
