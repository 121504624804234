import { useEffect, useState } from "react";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentLeaderboard from "../../../Interfaces/TournamentLeaderboard";
import LeaderboardRow from "../../../UiLibrary/TournamentComponents/LeaderboardRow";
import TournamentService from "../../../../services/TournamentService";
import { toast } from "react-toastify";

type Props = {
  tournament: ITournament | undefined,
  isAdmin: boolean
}

const LeaderboardTab = (props:Props) => {
  const { tournament, isAdmin } = props;
  const [adminLeaderboard, setAdminLeaderboard] = useState(false);
  
  const [leaderboard, setLeaderboard] = useState<Array<ITournamentLeaderboard>>([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const sortLeaderboard = tournament?.leaderboard.sort((a:ITournamentLeaderboard, b:ITournamentLeaderboard) => (Number(a.score) < Number(b.score) ? 1 : -1));
    setLeaderboard(sortLeaderboard)

  }, [tournament]);

  const reportScore = async (values: any) => {
		const newLeaderboardState = leaderboard?.map(obj => {
			if (obj.playerId === values.playerId) {
				return {...obj, wins: values.wins, score: values.score}
			}
			return obj
		})
		setLeaderboard(newLeaderboardState)
  }; 

  const saveLeaderboard = () => {
    TournamentService.updateTournamentLeaderboard(tournament?.id!, leaderboard)
			.then(() => {
				toast.info("Leaderboard update successfully!");
				setLoading(false);
			})
			.catch((error) => {
				toast.error(error?.response?.data?.message);
				setLoading(false);
		});
    const sortLeaderboard = leaderboard.sort((a:ITournamentLeaderboard, b:ITournamentLeaderboard) => (Number(a.score) < Number(b.score) ? 1 : -1));
    setLeaderboard(leaderboard)
  }

  return (
    <div className="column col-12">
      {/* Leaderboard not initialized */}
      {leaderboard.length === 0 && (
        <div className="columns skp-text-light text-center">
          <div className="space-divider"></div>
          <div className="column col-12">
            <h1>
              <i className="las la-trophy"></i>
            </h1>
          </div>
          <div className="column col-12">
            <h5>No leaderboard</h5>
          </div>
          <div className="column col-12">There are no leaderboard available yet.</div>
        </div>
      )}
      {/* END Leaderboard not initialized */}
    
      {/* TODO: Qui il controllo sulla leaderboard */}
      {leaderboard.length > 0 && (
        <div className="columns">
          <div className="space-divider-20"></div>
          {!isAdmin && (
            <div className="column col-12">
              <h5 className="skp-text-primary">Leaderboard</h5>
            </div>
          )}
          {isAdmin && (
            <>
              <div className="column col-9">
                <h5 className="skp-text-primary">Leaderboard</h5>
              </div>
              <div className="column col-3 text-right">
                <button 
                  className="skp-btn skp-btn-secondary"
                  onClick={() => {
                    setAdminLeaderboard(!adminLeaderboard)
                    if (adminLeaderboard) {
                      saveLeaderboard()
                    }
                  }}
                >
                  {adminLeaderboard ? "Save Changes" : "Admin Leaderboard" }
                </button>
              </div>
            </>
          )}
          <div className="space-divider"></div>
          <div className="column col-12">
            <div style={{padding:10}} className="flex-centered">
              <div className="column col-4 col-xs-2 mb-2 skp-text-primary">Player</div>
              <div className="column col-4 mb-2 col-xs-6 skp-text-primary">Team name</div>
              <div className="column col-2 mb-2 tooltip text-right skp-text-primary"
                data-tooltip="Score">Score</div>
              <div className="column col-2 mb-2 tooltip text-right hide-xs skp-text-primary"
                data-tooltip="Wins">Wins</div>
            </div>
              
            <dl className="bordered-list">
              {leaderboard.map((player:ITournamentLeaderboard, index:number) => (
                <dd key={index} className="columns flex-centered">
                  <LeaderboardRow 
                    player={player} 
                    tournament={tournament} 
                    isEdit={adminLeaderboard}
                    reportScore={reportScore}
                  />
                </dd>
              ))}
            </dl>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaderboardTab;
