const substractDatesAndGetMinutes = (dateA:string, dateB:string) => {
    const ms = new Date(dateA).getTime() - new Date(dateB).getTime();
    const minutes = Math.floor((ms/1000/60) << 0);

    return minutes;
}

const getHoursMinutes = (date:string) => {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = (newDate.getMinutes() < 10 ? "0" : "") + newDate.getMinutes();
    return `${hours}:${minutes}`
}
const DateService = {
  substractDatesAndGetMinutes,
  getHoursMinutes
};

export default DateService;