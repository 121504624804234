import constant from "../constants";
import HttpService from "./HttpService";
import qs from "query-string";
import ITeam from "../components/Interfaces/Team";
import IBrand from "../components/Interfaces/Brand";

const _axios = HttpService.getAxiosClient();

const getAllTeams = (queryParam?:any):Promise<Array<ITeam>> => {
  let url = `${constant.API_URL}/teams`;
  if (queryParam) {
    url += `?${qs.stringify(queryParam)}`;
  }

  return _axios.get(url).then((res) => {
    return res.data.teams;
  });
};

const getTeam = (teamId:string) => {
  let url = `${constant.API_URL}/teams/${teamId}`;
  
  return _axios.get(url).then((res) => {
    return res;
  });
};

const getTeamInvitations = (userId: string):Promise<Array<ITeam>> => {
  let url = `${constant.API_URL}/teams/${userId}/invitations`
  return _axios.get(url).then((res) => {
    return res.data;
  });
}

const createTeam = (data:any) => {
  const url = `${constant.API_URL}/teams`;
  return _axios({
    method: "post",
    url: url,
    data: data,
  }).then((response) => {
    return response;
  });
};

const inviteMember = (teamId:string, userId: string):Promise<ITeam> => {
  let url = `${constant.API_URL}/teams/${teamId}/invite/${userId}`;
  return _axios({
    method: "post",
    url: url,
  }).then((response) => {
    return response.data;
  })  
}

const deletePending = (teamId:string, userId: string):Promise<ITeam> => {
  let url = `${constant.API_URL}/teams/${teamId}/${userId}`;
  return _axios({
    method: "delete",
    url: url,
  }).then((response) => {
    return response.data;
  })  
}

const acceptMember = (teamId:string, userId: string):Promise<ITeam> => {
  let url = `${constant.API_URL}/teams/${teamId}/${userId}`;
  return _axios({
    method: "post",
    url: url,
  }).then((response) => {
    return response.data;
  })
}

const declineMember = (teamId:string, userId: string):Promise<ITeam> => {
  let url = `${constant.API_URL}/teams/${teamId}/${userId}`;
  return _axios({
    method: "delete",
    url: url,
  }).then((response) => {
    return response.data;
  })
}

const removeMember = (teamId:string, membersId:Array<string>):Promise<ITeam> => {
  let url = `${constant.API_URL}/teams/${teamId}`;
  membersId.forEach((memberId, index) => {
    if (index === 0) {
      url += `?ids=${memberId}`;
    } else {
      url += `,${memberId}`;
    }
  });
  return _axios({
    method: "PATCH",
    url: url,
  }).then((response) => {
    return response.data;
  });
};

const updateTeam = (teamId:string, data:any):Promise<ITeam> => {
  const url = `${constant.API_URL}/teams/${teamId}`;
  return _axios({
    method: "put",
    url: url,
    data: data
  }).then((response) => {
    return response.data;
  });
}

const deleteTeam = (teamId:string) => {
  const url = `${constant.API_URL}/teams/${teamId}`;
  return _axios({
    method: "delete",
    url: url
  }).then((response) => {
    return response;
  });
}

const createBrand = (data:any) => {
  const url = `${constant.API_URL}/brands`;
  return _axios({
    method: "post",
    url: url,
    data: data,
  }).then((response) => {
    return response;
  });
};

const updateBrand = (brandId:string, data:any):Promise<IBrand> => {
  const url = `${constant.API_URL}/brands/${brandId}`;
  return _axios({
    method: "put",
    url: url,
    data: data
  }).then((response) => {
    return response.data;
  });
}

const getAllBrands = (queryParam?:any):Promise<Array<IBrand>> => {
  let url = `${constant.API_URL}/brands`;
  if (queryParam) {
    url += `?${qs.stringify(queryParam)}`;
  }

  return _axios.get(url).then((res) => {
    return res.data.brands;
  });
};

const getBrand = (brandSlug:string) => {
  let url = `${constant.API_URL}/brands/${brandSlug}`;
  
  return _axios.get(url).then((res) => {
    return res;
  });
};

const removeMemberFromBrand = (brandSlug:string, membersId:Array<string>):Promise<IBrand> => {
  let url = `${constant.API_URL}/brands/${brandSlug}`;
  membersId.forEach((memberId, index) => {
    if (index === 0) {
      url += `?ids=${memberId}`;
    } else {
      url += `,${memberId}`;
    }
  });
  return _axios({
    method: "PATCH",
    url: url,
  }).then((response) => {
    return response.data;
  });
};

const inviteMemberInBrand = (brandSlug:string, userId: string):Promise<IBrand> => {
  let url = `${constant.API_URL}/brands/${brandSlug}/invite/${userId}`;
  return _axios({
    method: "post",
    url: url,
  }).then((response) => {
    return response.data;
  })  
}

const acceptMemberInBrand = (brandSlug:string, userId: string):Promise<IBrand> => {
  let url = `${constant.API_URL}/brands/${brandSlug}/${userId}`;
  return _axios({
    method: "post",
    url: url,
  }).then((response) => {
    return response.data;
  })
}

const deletePendingInBrand = (brandSlug:string, userId: string):Promise<IBrand> => {
  let url = `${constant.API_URL}/brands/${brandSlug}/${userId}`;
  return _axios({
    method: "delete",
    url: url,
  }).then((response) => {
    return response.data;
  })  
}

const TeamService = {
  createTeam,
  getAllTeams,
  getTeam,
  getTeamInvitations,
  inviteMember,
  acceptMember,
  declineMember,
  deletePending,
  removeMember,
  updateTeam,
  deleteTeam,

  createBrand,
  updateBrand,
  getAllBrands,
  getBrand,
  removeMemberFromBrand,
  inviteMemberInBrand,
  acceptMemberInBrand,
  deletePendingInBrand
};

export default TeamService;
