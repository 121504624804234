import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ITournament from "../../../../Interfaces/Tournament";
import Scrollbars from "react-custom-scrollbars";
import PlayPlayUser from "../Components/UserComponents/PlayPlayUser";
import ISupercellPlayer from "../../../../Interfaces/SupercellPlayer";
import SupercellTournamentService from "../../../../../services/SupercellTournamentService";

type Props = {
  tournament: ITournament | undefined;
  // onLogin: () => void
};

const ClashRoyaleLeaderboardTab = (props: Props) => {
  const { tournament } = props;
  const [loading, setLoading] = useState(false);
  const [allPlayers, setAllPlayers] = useState<ISupercellPlayer[]>([]);
  const [paginatedPlayers, setPaginatedPlayers] = useState<ISupercellPlayer[]>(
    []
  );

  useEffect(() => {
    if (tournament?.id && tournament?.clashRoyaleLobby?.tag)
      SupercellTournamentService.getPlayers(
        tournament?.id,
        tournament?.clashRoyaleLobby?.tag
      ).then((data) => {
        setAllPlayers(data);
        let items: Array<ISupercellPlayer> = [];
        const index = data && data.length >= 50 ? 50 : data?.length;
        if (index) {
          for (let i = 0; i < index; i++) {
            if (data[i]) {
              items.push(data[i]);
            }
          }
        }

        setPaginatedPlayers(items);
      });

    return () => {
      setPaginatedPlayers([]);
      setAllPlayers([]);
    };
  }, [tournament]);

  const fetchMoreData = () => {
    if (tournament) {
      setTimeout(() => {
        setPaginatedPlayers([
          ...paginatedPlayers,
          allPlayers[paginatedPlayers.length],
        ]);
      }, 100);
    }
  };

  return (
    <div>
      {/* <div className="space-divider-20"></div> */}
      <div className="columns">
        <div className="column col-12 col-xs-12">
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "calc(100vh - 65px)",
            }}
          >
            <Scrollbars
              renderView={(props:any) => <div {...props} id="scrollableDiv1" />}
            >
              {tournament && tournament?.members?.length > 0 && (
                <>
                  <div className="columns pt-2 pb-2 column-gray">
                    <div className="column col-1 flex-centered">#</div>
                    <div className="column col-9 col-xs-9">Team</div>
                    <div className="column col-2 flex-centered">Score</div>
                  </div>

                  <div className="columns">
                    <div className="column col-12">
                      <InfiniteScroll
                        dataLength={paginatedPlayers.length}
                        scrollableTarget="scrollableDiv1"
                        next={fetchMoreData}
                        hasMore={allPlayers.length > paginatedPlayers.length}
                        style={{ overflow: "hidden" }}
                        loader={
                          <dd>
                            <div className="loading"></div>
                          </dd>
                        }
                        scrollThreshold={"100px"}
                        endMessage={
                          <>
                            {paginatedPlayers.length >= 10 && (
                              <>
                                <p
                                  className="text-center skp-text-light m-2"
                                  style={{ textAlign: "center" }}
                                >
                                </p>
                              </>
                            )}
                          </>
                        }
                      >
                        {/* <div className="columns m-2"> */}
                        {paginatedPlayers &&
                          paginatedPlayers.map((player) => (
                            <>
                              <div
                                key={player?.skillProUser?.userId}
                                className="columns leaderboard-row bordered-list"
                              >
                                <div className="column col-1 flex-centered">
                                  {player?.rank}
                                </div>
                                <div
                                  className="column col-9 flex-centered"
                                  style={{ justifyContent: "left" }}
                                >
                                  <PlayPlayUser
                                    hideCheckin={true}
                                    playerId={player?.skillProUser?.userId}
                                  >
                                    <div className="text-label">
                                      {/* {!player.skillProUser?.userId && (
                                        <span className="skp-chip skp-text-small status-awaiting ml-1">
                                          NOT PLAY-PLAY
                                        </span>
                                      )} */}
                                      <span className="skp-chip skp-text-small status-1 ml-1 mr-1">
                                        {player.name}
                                      </span>
                                      <span className="skp-chip skp-text-xxsmall status-primary hide-xs">
                                        {player.tag}
                                      </span>
                                    </div>
                                  </PlayPlayUser>
                                </div>
                                <div className="column col-2 flex-centered">
                                  <strong>{player?.score}</strong>
                                </div>
                              </div>
                            </>
                          ))}
                        {/* </div> */}
                      </InfiniteScroll>
                    </div>
                  </div>
                </>
              )}
              {tournament?.members?.length === 0 && (
                <div className="columns skp-text-light text-center">
                  <div className="space-divider"></div>
                  <div className="column col-12">
                    <h1>
                      <i className="las la-user-friends"></i>
                    </h1>
                  </div>
                  <div className="column col-12">
                    <h5>No players</h5>
                  </div>
                  <div className="column col-12">
                    There are currently no Players registered for this
                    Tournament.
                  </div>
                </div>
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClashRoyaleLeaderboardTab;
