import { Form, Formik } from "formik";
import FormikControl from "../../../../../Formik/FormikControl";
import * as Yup from "yup";
import IApexLobbyGameToken from "../../../../../Interfaces/ApexLobbyGameToken";

type Props = {
  initialValues: IApexLobbyGameToken | undefined;
  loading: boolean;
  title: string;
  onAddGame: (data: any) => void;
  onRemoveGame: (event: any, gameNumber: number) => void;
};

const validationSchema = Yup.object({
  gameNumber: Yup.number(),
  api: Yup.string(),
  admin: Yup.string(),
  player: Yup.string(),
  start_game: Yup.date().required().nullable(),
  end_game: Yup.date().required().nullable(),
});

const CreateEditApexGameModal = (props: Props) => {
  const { initialValues, title, loading } = props;

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          {initialValues && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={props.onAddGame}
              enableReinitialize={true}
            >
              {({ errors, touched, isValid, setFieldValue }) => (
                <Form>
                  <FormikControl
                    control="date"
                    label="Start Game Date and Time"
                    name="start_game" 
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeSelect
                  />
                  <FormikControl
                    control="date"
                    label="End Game Date and Time"
                    name="end_game" 
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeSelect
                  />
                  <FormikControl control="input" label="Api Token" name="api" />
                  <FormikControl
                    control="input"
                    label="Player Password"
                    name="player"
                  />
                  <FormikControl
                    control="input"
                    label="Admin Password"
                    name="admin"
                  />
                  
                  <div className="space-divider-20"></div>
                  <button
                    className="skp-btn skp-btn-primary float-right"
                    type="submit"
                    disabled={!isValid || loading}
                  >
                    Save Game
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {initialValues?.gameNumber && title === "Edit Game" && (
            <button
              disabled={loading}
              className="skp-btn skp-btn-alert"
              onClick={(e) => props.onRemoveGame(e, initialValues.gameNumber)}
            >
              <i className="las la-trash"></i> Delete game
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateEditApexGameModal;
