import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import UserService from "../../../services/UserService";
import { UserContext } from "../../Context/UserContext";
import FormikControl from "../../Formik/FormikControl";
import * as Yup from "yup";
import { Link } from "react-router-dom";

interface FormModel {
  discordId?: string;
}

const validationSchema = Yup.object({
  discordId: Yup.string().required("Required!").nullable().matches(/[^#]{2,32}#[0-9]{4}/, "Discord ID is wrong. (e.g. myname#1234)"),
});

const AddDiscordIdModal = () => {
  const [loadingForm, setLoadingForm] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const initialValues: FormModel = {
    discordId: user?.discordId,
  };

  const onSubmit = (values: FormModel) => {
    setLoadingForm(true);
    const userId = user?.id;
    UserService.updateUserInfo(values, userId)
      .then((user) => {
        setLoadingForm(false);
        setUser(user);
        toast.success("User information updated");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoadingForm(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isValid, setFieldValue }) => (
          <Form>
            <div className="container">
              <div className="space-divider-20"></div>
              <div className="columns skp-text-primary">
                <div className="column col-6 col-xs-12">
                  <div className="columns">
                    <div className="column col-12">
                      <h5 className="skp-text-primary text-center">
                        Please add a Discord Id
                      </h5>
                    </div>
                    <div className="column col-12">
                      <h6 className="skp-text-light text-center skp-text-default">
                        Admins require users' Discord ID to communicate
                        with them during the tournament.
                      </h6>
                    </div>
                    <div className="space-divider-20"></div>
                    <div className="column col-12">
                      <FormikControl
                        control="input"
                        label="Discord Id"
                        name="discordId"
                        placeholder="Discord ID (e.g: some.one#1223)"
                      ></FormikControl>
                    </div>
                  </div>
                </div>
                <div className="column col-6 flex-centered hide-xs">
                  <div className="columns">
                    <div className="column col-4 text-right">
                      <i
                        className="las la-headset"
                        style={{ fontSize: 80 }}
                      ></i>
                    </div>
                    <div className="column col-4 flex-centered">
                      <i
                        className="las la-exchange-alt"
                        style={{ fontSize: 80 }}
                      ></i>
                    </div>
                    <div className="column col-4 text-left">
                      <i
                        className="lab la-discord"
                        style={{ fontSize: 80 }}
                      ></i>
                    </div>
                  </div>
                </div>

                <div className="space-divider"></div>

                <div className="column col-12">
                  <div className="columns">
                    <div className="column col-6 col-xs-12 text-center-xs">
                      <p className="skp-text-label skp-text-light">
                        To manage your Discord ID go to the{" "}
                        <Link
                          className="skp-link"
                          to={`/users/${user?.id}/settings/usersettings`}
                        >
                          User Setting's page
                        </Link>
                      </p>
                    </div>
                    <div className="column col-6 text-right col-xs-12 text-center-xs">
                      <button
                        className="skp-btn skp-btn-primary"
                        disabled={loadingForm || !isValid}
                      >
                        Save Discord Id
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddDiscordIdModal;
