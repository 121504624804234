import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { DebounceInput } from "react-debounce-input";
import UserService from "../../../services/UserService";
import TournamentService from "../../../services/TournamentService";
import Modal from "../../UiLibrary/Modal";
import UserProfile from "../../UiLibrary/UserProfile";
import { toast } from "react-toastify";
import ITournament from "../../Interfaces/Tournament";
import IUserDetails from "../../Interfaces/UserDetails";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined
  loadTournament: (tournament: ITournament | undefined) => void
}

const AddAdminModal = (props:Props) => {
  const { tournament } = props;
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [userList, setUserList] = useState<Array<IUserDetails>>([]);
  const [adminList, setAdminList] = useState<Array<string> | undefined>(tournament?.admins);

  const searchUser = (event:any) => {
    setSearchText(event.target.value);
    UserService.getUserByName(event.target.value, 10).then((users) => {
      setUserList(users);
    });
  };

  const updateAdminList = (userId: string | undefined) => {
    let admins = [...adminList as Array<string>]
    if (userId) {
      admins?.push(userId);
    }
    
    setAdminList(admins);
  };

  const removeFromAdminList = (index: number) => {
    if(!tournament || !adminList) {
      return
    }
    let admins = [...adminList as Array<string>];
    admins.splice(index, 1);
    setAdminList(admins);
    
    setLoading(true)
      TournamentService.removeAdmin(tournament?.id, [adminList[index]]).then((Rtournament) => {
          toast.success(t('modals.adminRemoveSuccess'))
          props.loadTournament(Rtournament);
          setLoading(false)
          
      }).catch(error => {
        toast.error(error?.response?.data?.message)
        setLoading(false)
        setAdminList(tournament?.admins);
      })
  };

  const openModal = () => {
    setShowModal(true);
    setAdminList(tournament?.admins);
  }

  const closeModal = () => {
    setShowModal(false);
    setSearchText(undefined);
    setUserList([]);
    setAdminList(undefined);
    setLoading(false);
  };

  const saveList = () => {
    if(!tournament || !adminList) {
      return
    }
      setLoading(true)
      TournamentService.addAdmin(tournament?.id, adminList).then(Rtournament => {
          toast.success(t('modals.adminSaveSuccess'))
          props.loadTournament(Rtournament);
          setLoading(false)
          closeModal();
      }).catch(error => {
        toast.error(error?.response?.data?.message)
        setLoading(false)
      })
  }

  return (
    <>
      <div
        className="skp-link-hover skp-text-default"
        onClick={openModal}
      >
        <h6 className="d-inline-block">
          <i className="las la-pen"></i>
        </h6>
        <span>{t('modals.editAdmins')}</span>
      </div>

      <Modal title={t('modals.editAdmins')} loading={loading} show={showModal} onClose={closeModal} confirmation={t('modals.saveList')} onConfirm={saveList}>
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns">
            <div className="column col-5 col-xs-12">
              <h5 className="skp-text-primary">{t('modals.searchUsersList')}</h5>
              <div className="columns">
                <div className="column col-12 col-xs-12">
                  <div className="skp-form-control">
                    <label className="skp-text-primary skp-text-small">
                      {t('modals.searchUsers')}
                    </label>
                    <DebounceInput
                      value={searchText}
                      type="text"
                      minLength={1}
                      debounceTimeout={500}
                      placeholder={t('modals.searchByUser')}
                      onChange={(e) => searchUser(e)}
                    />
                  </div>
                </div>

                <div className="column col-12">
                  {userList.length === 0 && (
                    <div className="skp-text-small skp-text-light m-2">
                      {t('modals.typingToSearchUsers')}
                    </div>
                  )}
                  <Scrollbars style={{ width: "100%", height: 350 }}>
                    <dl className="bordered-list">
                      {userList.map((user:IUserDetails) => (
                        <dd key={user.id} onClick={() => updateAdminList(user?.id)}>
                          <UserProfile user={user}></UserProfile>
                        </dd>
                      ))}
                    </dl>
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className="column col-1 hide-xs"></div>
            <div className="column col-5 col-xs-12">
              <h5 className="skp-text-primary">{t('modals.adminList')}</h5>
              {adminList && adminList.length === 0 && (
                <div className="skp-text-light">
                  <h6>{t('modals.noAdmin')}</h6>
                  {t('modals.searchDescMessage')}
                </div>
              )}
              <dl>
                {adminList && adminList.map((user, index) => (
                  <dd className="columns" key={user}>
                    <div className="column col-8">
                      <UserProfile userId={user}></UserProfile>
                    </div>
                    <div
                      className="skp-link-hover column col-4 flex-centered"
                      onClick={() => removeFromAdminList(index)}
                    >
                      <i className="las la-trash"></i>
                      <span className="skp-text-small">{t('common.remove')}</span>
                    </div>
                  </dd>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddAdminModal;
