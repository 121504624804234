import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import MatchTeamName from "./MatchTeamName";
import TournamentTeamsTypeahead from "../Typeahead/TournamentTeamsTypeAhead";
import MatchService from "../../../services/MatchService";
import IMatch from "../../Interfaces/Match";
import ITournament from "../../Interfaces/Tournament";
import IMatchScore from "../../Interfaces/MatchScore";
import ITournamentTeam from "../../Interfaces/TournamentTeam";
import { useTranslation } from "react-i18next";

type Props = {
  match: IMatch
  tournament: ITournament
  onSetMatch: (match: IMatch) => void
}

const MatchChangeTeam = (props:Props) => {
  const { match, tournament } = props;
  const { t } = useTranslation()
  const [newData, setData] = useState<Array<IMatchScore>>([]);
  const [loading, setLoading] = useState(false);
  const [showTypeahead, setShowTypeahead] = useState([
    { show: false },
    { show: false },
  ]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setData([]);
    if (match) {
      setData(match?.result.scores);
    }
  }, [match]);

  const setTeam = (team:ITournamentTeam, index:number) => {
    setLoading(true);
    if(team && match) {
      MatchService.changeTeam(
        match?.id || match?._id,
        team?.partyId,
        match?.result?.scores[index].teamId
      ).then((response) => {
        setLoading(false);
        setShowTypeahead([{ show: false }, { show: false }]);
  
        props.onSetMatch(response.data.data);
        toast.info(t('toast.teamSavedSuccess'));
      });
    } else {
      removeTeam(index)
    }
    
  };

  const showTeamTypeahead = (index:number) => {
    let typeaheads = [...showTypeahead];
    let typeahead = { ...typeaheads[index] };
    typeahead.show = true;
    typeaheads[index] = typeahead;
    setShowTypeahead(typeaheads);
    setShow(true);
  };

  const removeTeam = (index:number) => {
    let temp_state = [...newData as Array<IMatchScore>];
    let temp_element = { ...temp_state[index] };
    temp_element.teamId = "1";
    temp_element.playerResults = [{playerId: "1", score: 0}];
    temp_state[index] = temp_element;

    MatchService.updateMatch(match.id, {result: {scores: temp_state}}).then(Rmatch => {
      setLoading(false);
        setShowTypeahead([{ show: false }, { show: false }]);
  
        props.onSetMatch(Rmatch);
        toast.info(t('toast.teamSavedSuccess'));
    })
  }

  return (
    <>
      {newData &&
        newData?.map((score, index) => (
          <div className="container">
            <div className="columns">
              <div className="column col-12 col-xs-12 flex-centered">
                <div className="columns">
                  <div className="column col-12 text-left">
                    <MatchTeamName
                      tournament={tournament}
                      teamId={score.teamId}
                    >
                      {score.teamId !== "1" && (
                        <span
                          className="skp-link-hover skp-text-xlarge"
                          onClick={() => showTeamTypeahead(index)}
                        >
                          <i className="las la-exchange-alt"></i>
                        </span>
                      )}

                      {(!score.teamId || score.teamId === "1") && (
                        <span
                          className="skp-link-hover skp-text-default p-2"
                          onClick={() => showTeamTypeahead(index)}
                        >
                          <i className="las la-plus"></i> {t('teams.addTeam')}
                        </span>
                      )}
                    </MatchTeamName>

                    {/* Type Ahead to show teams */}
                    {showTypeahead[index].show && (
                      <>
                        <Dropdown show={show}>
                          <OutsideClickHandler
                            onOutsideClick={() =>
                              setShowTypeahead([
                                { show: false },
                                { show: false },
                              ])
                            }
                          >
                            <TournamentTeamsTypeahead
                              tournament={tournament}
                              onSelect={(team:ITournamentTeam) => setTeam(team, index)}
                            />
                          </OutsideClickHandler>
                        </Dropdown>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="space-divider-20"></div>
          </div>
        ))}
    </>
  );
};

export default MatchChangeTeam;
