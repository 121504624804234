import HttpService from "./HttpService";
import constant from "../constants";

let _axios = HttpService.getAxiosClient();

const getFollowers = (userId: string) => {
    const url = `${constant.API_URL}/socials/getFollowers/${userId}`;
    return _axios({
        method: "get",
        url: url,
    }).then((response) => {
        console.log(response.data)
        return response.data.data;
    }).catch((error)=>{
        console.log(error)
    });
}

const getFollowing = (userId: string) => {
    const url = `${constant.API_URL}/socials/getFollowing/${userId}`;
    return _axios({
        method: "get",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
}

const followUser = (userId: string, followUserId: string) => {
    const url = `${constant.API_URL}/socials/follow/${userId}/${followUserId}`;
    return _axios({
        method: "post",
        url: url,
    }).then((response) => {
        return response.data.data;
    });
}

const SocialService = {
    getFollowers,
    getFollowing,
    followUser
};
  
export default SocialService;