import DateView from "react-datepicker";
import { Field, ErrorMessage, FieldProps } from "formik";
import TextError from "./TextError";

type Props = {
  label?: string
  name?: string
}

const DatePicker = (props:Props) => {
  const { label, name, ...rest } = props;

  if(!name) {
    return null;
  }

  return (
    <div className="skp-form-control">
      <label className="skp-text-light skp-text-small" htmlFor={label}>
        {label}
      </label>
      <Field name={name}>
        {({ field, form }:FieldProps) => {
          const { setFieldValue } = form;
          if(field.value && !(typeof field.value?.getMonth === 'function')) {
            field.value = new Date(field.value)
          }
          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={field.value}
              onChange={(val: any) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default DatePicker;
