import { useState } from 'react'
import Marquee from 'react-text-marquee';
import { Link } from 'react-router-dom';
import AddToFavourite from './AddToFavourite';
import IBrand from '../Interfaces/Brand';

interface Props {
    brand: IBrand
}

const BrandTile = (props: Props) => {
    const {brand} = props;
    const initials = brand?.name.charAt(0);
    const [error, setError] = useState(false)

    return (
        <div className="team-tile">
            <Link to={`/brands/${brand.slug}/overview`}>
                <div className="team-tile-image">
                    {(!brand?.logo || error) && (
                        <span className="initials flex-centered text-uppercase">
                            {!initials ? <i className="las la-user"></i> : initials}
                        </span>
                    )}
                    {brand?.logo && !error && (
                        <img
                            alt={brand.name}
                            src={brand.logo}
                            onError={() => setError(true)}
                        />
                    )}
                </div>
            </Link>
            <div className="columns">
                <div className="column col-12 text-center">
                    <Link to={`/teams/${brand.slug}/overview`}>
                        <span className="skp-text-default skp-text-primary">
                            <Marquee text={brand.name} />
                        </span>
                    </Link>
                </div>
                <div className="column col-12 text-center">
                    <span className="verified-icon tooltip" data-tooltip="Verified">
                        <i className="la la-check-circle"></i>
                    </span>
                    {/*<AddToFavourite team={team} />*/}
                </div>
            </div>
        </div>
    );
}

export default BrandTile