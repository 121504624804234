import { Field, ErrorMessage } from 'formik'
import IDropdownOption from '../Interfaces/DropdownOption'
import TextError from './TextError'


type Props = {
  label?: string
  name?: string
  options?: Array<IDropdownOption> 
}

const Select = (props:Props) => {
    const { label, name, options, ...rest} = props

    if(!name || !options) {
      return null
    }
    
    return (
      <div className="skp-form-control">
        <label className="skp-text-light skp-text-small" htmlFor={name}>
          {label}
        </label>
        <Field as="select" id={name} name={name} {...rest}>
          {options && options.map((option, id) => {
            return (
              <option key={`${option.value}-${id}`} value={option.value}>
                {option.key}
              </option>
            );
          })}
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </div>
    );
}

export default Select
