import { NavLink, useParams } from "react-router-dom";
import KillRaceFortnite from "./Fortnite/KillRaceFortnite";
import KillRaceWarZone from "./Warzone/KillRaceWarZone";
import "./killrace.scss";
import Scrollbars from "react-custom-scrollbars";

const KillRace = () => {
  let { game, sheet } = useParams<{ game: string; sheet: string }>();

  return (
    <>
      <Scrollbars>
        <div className="main-container">
          <div className="milanocomics-event-wrapper"></div>

          <div
            className="container"
            style={{ position: "relative", zIndex: 1 }}
          >
            <div className="columns">
              <div className="column col-2 col-xs-12">
                <div className="space-divider"></div>
                <div className="logo-placeholder main-logo" style={{ width: "110%", margin: "0px auto" }}>
                  <img
                    alt="milano-comics-game"
                    src="https://storage.googleapis.com/skillpro-bucket/Milano%20Comics%20-%20logo%202.png"
                  ></img>
                </div>
                <div className="hide-xs">
                  <div className="space-divider-20"></div>
                  <div className="logo-placeholder">
                    <img
                      alt="eli-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/eli.png"
                    ></img>
                  </div>
                  <div className="space-divider-20"></div>
                  <div
                    className="logo-placeholder"
                    style={{ width: "50%", margin: "0px auto" }}
                  >
                    <img
                      alt="leone-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/leone.png"
                    ></img>
                  </div>
                  <div className="space-divider-20"></div>
                  <div className="logo-placeholder">
                    <img
                      alt="dsyre-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/dsyre.png"
                    ></img>
                  </div>
                  <div className="space-divider-20"></div>
                  <div className="logo-placeholder">
                    <img
                      alt="ncontrol-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/ncontrol.png"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="column col-8 col-xs-12 text-center text-center-xs bracket-container">
                <div className="space-divider-20 hide-xs"></div>
                <h2 className="skp-text-primary">Kill Race</h2>

                <Scrollbars className="day-scrollbar">
                  {!game && (
                    <>
                      <div className="columns">
                        <div className="space-divider"></div>
                        <div className="column col-6 flex-centered">
                          <NavLink
                            className={`logo-link logo-link-big`}
                            to="/branded-tournaments/milano-comics/killrace/fortnite/Giorno1"
                          >
                            <img src="https://www.cartoni.wiki/images/files/fortnite-logo.png" />
                          </NavLink>
                          <div className="columns">
                            <div className="column col-6"></div>
                          </div>
                        </div>
                        <div className="column col-6 flex-centered">
                          <NavLink
                            className={`logo-link logo-link-big`}
                            to="/branded-tournaments/milano-comics/killrace/warzone/Giorno1"
                          >
                            <img src="https://cdn2.steamgriddb.com/file/sgdb-cdn/logo_thumb/db6d9e1beb13d90e4a67706afb39e4e8.png" />
                          </NavLink>
                        </div>
                      </div>
                    </>
                  )}

                  {game && (
                    <>
                      <div className="columns">
                        <div className="column col-6 flex-centered">
                          <NavLink
                            className={`logo-link logo-link-small ${
                              game === "fortnite" ? "active" : null
                            }`}
                            to="/branded-tournaments/milano-comics/killrace/fortnite/Giorno1"
                          >
                            <img src="https://www.cartoni.wiki/images/files/fortnite-logo.png" />
                          </NavLink>
                        </div>
                        <div className="column col-6 flex-centered">
                          <NavLink
                            className={`logo-link logo-link-small ${
                              game === "warzone" ? "active" : null
                            }`}
                            to="/branded-tournaments/milano-comics/killrace/warzone/Giorno1"
                          >
                            <img src="https://cdn2.steamgriddb.com/file/sgdb-cdn/logo_thumb/db6d9e1beb13d90e4a67706afb39e4e8.png" />
                          </NavLink>
                        </div>
                      </div>
                    </>
                  )}

                  {sheet && game === "fortnite" && (
                    <>
                      <KillRaceFortnite sheet={sheet} />
                    </>
                  )}
                  {sheet && game === "warzone" && (
                    <>
                      <KillRaceWarZone sheet={sheet} />
                    </>
                  )}
                </Scrollbars>
              </div>
              <div className="column col-2 col-xs-12">
                <div className="show-xs">
                  <div className="space-divider-20"></div>
                  <div className="logo-placeholder">
                    <img
                      alt="eli-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/eli.png"
                    ></img>
                  </div>
                  <div className="space-divider-20"></div>
                  <div
                    className="logo-placeholder"
                    style={{ width: "70%", margin: "0px auto" }}
                  >
                    <img
                      alt="leone-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/leone.png"
                    ></img>
                  </div>
                  <div className="space-divider-20"></div>
                  <div className="logo-placeholder">
                    <img
                      alt="dsyre-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/dsyre.png"
                    ></img>
                  </div>
                  <div className="space-divider-20"></div>
                  <div className="logo-placeholder">
                    <img
                      alt="ncontrol-logo"
                      src="https://storage.googleapis.com/skillpro-bucket/ncontrol.png"
                    ></img>
                  </div>
                </div>
                <div className="space-divider"></div>

                <div
                  className="logo-placeholder"
                  style={{ width: "110%", margin: "0px auto" }}
                >
                  <img
                    alt="pchunter-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/Octo-PCH_LOGO-V_C.png"
                  ></img>
                </div>
                <div className="logo-placeholder">
                  <img
                    alt="beebad-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo%20Beebad.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="retrogame-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/retrograme.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="kink-esport-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/king-esport.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="volandia-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/volandia.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="foto-nerd-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/foto-nerd.png"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default KillRace;
