import { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import PlayerTab from "../TournamentTabs/PlayersTab";
import ResultsTab from "../TournamentTabs/ResultsTab";
import MatchesTab from "../TournamentTabs/MatchesTab";
import OverviewTab from "../TournamentTabs/OverviewTab";
import Modal from "../../../UiLibrary/Modal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import TeamsTab from "../TournamentTabs/TeamsTab";
import MatchService from "../../../../services/MatchService";
import { TournamentMatchesContext } from "../../../Context/TournamentMatchesContext";
import MatchDetailsModal from "../../../Modals/MatchDetailsModal/MatchDetailsModal";
import MultiMatchDetailsModal from "../../../Modals/MultiMatchDetailsModal/MultiMatchDetailsModal";
import AnnouncementsTab from "../TournamentSettingsTabs/AnnouncementsTab";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import IMatchTournamentSeed from "../../../Interfaces/MatchTournamentSeed";
import IMatch from "../../../Interfaces/Match";
import { UserContext } from "../../../Context/UserContext";
import TournamentService from "../../../../services/TournamentService";
import LeaderboardTab from "../TournamentTabs/LeaderboardTab";

type Props = {
  tournament: ITournament | undefined;
  userStatus: ITournamentMemberStatus;
  isSettingsPage: boolean;
  urlParams: any;
  loadTournament: (tournament: ITournament | undefined) => void;
};

type ITournamentMatches = {
  matches: Array<IMatch>;
  tournament: ITournament;
};

const tabs = [
  { name: "Overview", url: "overview" },
  { name: "Rules", url: "rules" },
  { name: "Players", url: "players" },
  { name: "Teams", url: "teams" },
  { name: "Matches", url: "matches" },
  { name: "Leaderboard", url: "leaderboard" },
  { name: "Results", url: "results" },
  { name: "Announcements", url: "announcements" },
];

const TournamentCustom = (props: Props) => {
  const { user } = useContext(UserContext);
  const { tournament, urlParams, userStatus } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [matches, setMatches] = useState(tournament?.matches);
  const [loading, setLoading] = useState(false);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [showMyMatches, setShowMyMatches] = useState(false);
  const [filterNumber, setFilterNumber] = useState();
  const [tournamentMatches, setTournamentMatches] =
    useState<ITournamentMatches>();
  const [showMatchModal, setShowMatchModal] = useState<{
    show: boolean;
    match: IMatchTournamentSeed | undefined;
  }>({
    show: false,
    match: undefined,
  });

  const providerTournamentMatches = useMemo(
    () => ({ tournamentMatches, setTournamentMatches }),
    [tournamentMatches, setTournamentMatches]
  );

  useEffect(() => {
    if (!tournament) {
      return;
    }
    if (tournament?.matches.length > 0 && tournament.groups?.length === 0) {
      setLoadingMatches(true);
      MatchService.getTournamentMatches(
        tournament?.id,
        (tournament?.maxPartecipants + 1) * 2 * 9,
        true
      ).then((matches) => {
        setTournamentMatches({ matches: matches, tournament: tournament });
        setLoadingMatches(false);
      });
    }
  }, [tournament]);

  const isAdmin = ((tournament?.owner === user?.id || tournament?.admins?.includes(user?.id!)) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

  const tournamentWithGroups =
    tournament?.groups && tournament.groups.length > 0;

  const changeMatches = () => {
    setLoading(true);
    setMatches([]);

    if (!showMyMatches) {
      MatchService.getMatchsWithQuery({
        userId: user?.id,
        tournamentId: tournament?.id,
      }).then((response) => {
        const myMatches: Array<IMatch> = response.data.data.matches;

        let filtered;
        filtered = tournament?.matches.filter((match: IMatchTournamentSeed) =>
          myMatches.find((myMatch: IMatch) => myMatch.id === match.matchesId[0])
        );

        setMatches(filtered);
        setLoading(false);
      });
    } else {
      setMatches(tournament?.matches);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }

    setShowMyMatches(!showMyMatches);
  };

  const filterMatchNumber = (event: any) => {
    setLoading(true);
    setMatches([]);
    let { value } = event.target;

    const matchNumber = value;
    setFilterNumber(value);

    setTimeout(() => {
      if (matchNumber) {
        const matchesList = tournament?.matches.filter(function (
          match: IMatchTournamentSeed
        ) {
          return match.id === matchNumber; // returns true or false
        });

        setMatches(matchesList);
      } else {
        setMatches(tournament?.matches);
      }

      setLoading(false);
    }, 300);
    setFilterNumber(matchNumber);
  };

  const closeMatchModal = () => {
    if(tournament){
      TournamentService.getTournament(tournament?.id).then((t) => {
        props.loadTournament(t);
        setShowMatchModal({ show: false, match: undefined });
      })
    } else {
      setShowMatchModal({ show: false, match: undefined });
    }
  };

  return (
    <>
      <TournamentMatchesContext.Provider value={providerTournamentMatches}>
        {/* TABS HEADER */}
        <div className="container tabs-header">
          <div className="columns">
            <div className="column col-12">
              <ul className="tab skp-text-light">
                {tabs
                  .filter((tab) =>
                    tournament?.announcements?.length === 0
                      ? tab.url !== "announcements"
                      : tab
                  )
                  .filter((tab) =>
                    tournament?.groups?.length !== 0
                      ? tab.url !== "matches"
                      : tab
                  )
                  .map((tab) => (
                    <li
                      key={tab.name}
                      className={`${
                        urlParams.tab === tab.url
                          ? "tab-item active"
                          : "tab-item"
                      } ${loading && "tab-item-disabled"}`}
                    >
                      <Link
                        to={`/tournaments/${tournament?.id}/${tab.url}`}
                        className={`${
                          urlParams.tab === tab.url ? "active" : null
                        } ${tab.url === "announcements" && "badge"}`}
                        data-badge={tournament?.announcements?.length}
                      >
                        {tab.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        {/* END TABS HEADER */}
        {/* TABS BODY */}
        <div className="container tabs-body">
          <div className="columns">
            {urlParams.tab === tabs[0].url && (
              <OverviewTab
                tournament={tournament}
                loadTournament={props.loadTournament}
                keyValue={"description"}
                title="Information"
              />
            )}
            {urlParams.tab === tabs[1].url && (
              <OverviewTab
                tournament={tournament}
                loadTournament={props.loadTournament}
                keyValue="rules"
                title="Rules"
              />
            )}
            {urlParams.tab === tabs[2].url && (
              <PlayerTab
                tournament={tournament}
                onLogin={() => setShowLoginModal(true)}
                onReloadTournament={props.loadTournament}
              />
            )}
            {urlParams.tab === tabs[3].url && (
              <TeamsTab
                tournament={tournament}
                userStatus={userStatus}
                onReloadTournament={props.loadTournament}
              />
            )}
            {urlParams.tab === tabs[4].url && (
              <>
                {tournament?.members.includes(user?.id!) && (
                  <>
                    <div className="space-divider-20"></div>
                    <div
                      className="column col-12 flex-centered"
                      style={{ justifyContent: "right" }}
                    >
                      <button 
                        className="skp-btn skp-btn-secondary"
                        onClick={() => changeMatches()}
                      >
                        {showMyMatches ? "Show all matches" : "Show only my matches" }
                      </button>
                    </div>
                  </>
                )}

                {!loading && !showMatchModal.show && (
                  <MatchesTab
                    matches={matches}
                    onLogin={() => setShowLoginModal(true)}
                    tournament={tournament}
                    onSetMatch={(match: IMatchTournamentSeed) =>
                      setShowMatchModal({ show: true, match: match })
                    }
                  />
                )}
                {loading && (
                  <div className="column col-12 text-center">
                    <div className="space-divider"></div>
                    <div className="loading"></div>
                  </div>
                )}
              </>
            )}
            
            {urlParams.tab === tabs[5].url && (
              <LeaderboardTab tournament={tournament} isAdmin={isAdmin} />
            )}
            {urlParams.tab === tabs[6].url && (
              <ResultsTab tournament={tournament} />
            )}
            {urlParams.tab === tabs[7].url && (
              <AnnouncementsTab
                tournament={tournament}
                disableTextEditor={true}
                onReloadTournament={props.loadTournament}
              ></AnnouncementsTab>
            )}
          </div>
        </div>
        {/* END TABS BODY */}

        <Modal
          show={showLoginModal}
          size="small"
          onClose={() => setShowLoginModal(false)}
        >
          <LoginOrRegisterModal
            message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
            onClose={() => setShowLoginModal(false)}
          />
        </Modal>

        <Modal
          show={showMatchModal.show}
          onClose={() => closeMatchModal()}
          title={`Match ${showMatchModal.match?.id}`}
        >
          <>
            {/* Single Match */}
            {tournament &&
              showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length === 1 && (
                <MatchDetailsModal
                  tournament={tournament}
                  matchId={showMatchModal.match?.matchesId[0]}
                  closeModal={closeMatchModal}
                  onReloadTournament={props.loadTournament}
                />
              )}
            {/* Multi Match */}
            {showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length > 1 && (
                <MultiMatchDetailsModal
                  match={showMatchModal.match}
                  tournament={tournament}
                />
              )}
          </>
        </Modal>
      </TournamentMatchesContext.Provider>
    </>
  );
};

export default TournamentCustom;
