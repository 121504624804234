import React, { useEffect, useState } from 'react'
import ReactTimeAgo from 'react-time-ago'
import Marquee from 'react-text-marquee';
import { Link } from 'react-router-dom';
import useImportedStatus from '../Hooks/UseImportedStatus';


const YuniteTournamentTile = props => {
    const { tournament } = props;
    const imported = useImportedStatus(tournament.id);

    const [date, setDate] = useState({date: new Date(), hours: undefined, minutes: undefined})
    let game = tournament.game;

    useEffect(() => {
        const date = new Date(tournament.start_tournament);
        const hours = date.getHours();
        const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        setDate({date: date, hours: hours, minutes: minutes});
    },[tournament.start_tournament])


    
    return (
      <Link
        className="no-decoration"
        to={
          !imported
            ? `/yunite-tournaments/${tournament.id}/overview`
            : `/tournaments/${imported?.id}/overview`
        }
      >
        <div
          className={
            imported
              ? "tournament-tile tournament-tile-disabled"
              : "tournament-tile"
          }
        >
          {imported && (
            <span className="skp-chip skp-text-xxsmall text-uppercase yunite imported">
              Imported
            </span>
          )}

          <div className="tournament-tile-image">
            {tournament.bannerUrl && (
              <img src={tournament.bannerUrl} alt={tournament.title} />
            )}
            {!tournament.bannerUrl && (
              <div className={`default-tile ${game}`}></div>
            )}
          </div>

          <div className="tournament-tile-content columns">
            <div className="column col-12">
              <span className="skp-text-label skp-text-uppercase skp-text-light">
                {<ReactTimeAgo date={date?.date} />} - {date?.hours}:
                {date?.minutes}
              </span>
            </div>
            <div className="column col-12">
              <Link
                to={
                  !imported
                    ? `/yunite-tournaments/${tournament.id}/overview`
                    : `/tournaments/${imported?.id}/overview`
                }
              >
                <span className="skp-text-large skp-text-primary">
                  <Marquee text={tournament.name} />
                </span>
              </Link>
            </div>
            <div className="column col-12 skp-text-small skp-text-light">
              <span>{tournament.type}</span>{" "}
              {tournament.numberOfSlots && (
                <span> - {tournament.numberOfSlots} slots</span>
              )}
            </div>
            <div className="column col-12 skp-text-default skp-text-light">
              {tournament.entryFee && (
                <span className="tooltip" data-tooltip="Entry fee">
                  {tournament.entryFee}
                </span>
              )}{" "}
              {tournament.price && (
                <span className="tooltip" data-tooltip="Price">
                  {" "}
                  - <i className="las la-bolt skp-text-yellow"></i>
                  {tournament.price}
                </span>
              )}
            </div>
            <div className="column col-12 space-divider-5"></div>
            <div className="column col-12">
              <span className="skp-chip skp-text-xxsmall text-uppercase">
                {tournament.game}
              </span>
              {tournament.open && <span className="skp-chip open">Open</span>}
            </div>
          </div>
        </div>
      </Link>
    );
}

export default YuniteTournamentTile
