import { useState, useRef } from "react";
import { toast } from "react-toastify";
import TournamentService from "../../../services/TournamentService";
import ITournament from "../../Interfaces/Tournament";
import ITournamentTeam from "../../Interfaces/TournamentTeam";
import ITournamentTeamMember from "../../Interfaces/TournamentTeamMember";
import Modal from "../../UiLibrary/Modal";
import TeamRoleDropdown from "../../UiLibrary/TeamRoleDropdown";
import TeamStatusDropdown from "../../UiLibrary/TeamStatusDropdown";
import UserProfileWithDetailsModal from "../../UiLibrary/User/UserProfileWithDetailsModal";
import AddTournamentTeamPlayerModal from "./AddTournamentTeamPlayerModal";

type Props = {
  team: ITournamentTeam;
  tournament: ITournament;
  onReloadTournament: (tournament?: ITournament | undefined) => void;
};

const EditTournamentTeamModal = (props: Props) => {
  const { team, tournament } = props;
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const teamNameInput = useRef<HTMLInputElement>(null);

  const numberMembersAllowed =
    Number(tournament.teamSize) +
    (tournament.reserves || Number(tournament.teamSize));


  const addMember = (member: ITournamentTeamMember) => {
    TournamentService.addTeamMember(
      tournament.id,
      team.partyId,
      member.userId,
      member.gameAccount
    )
      .then((response) => {
        props.onReloadTournament(response);
        toast.success("Team member added successfully!");
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
      });
  };

  const removeMember = (member: ITournamentTeamMember) => {
    TournamentService.removeTeamMember(
      tournament.id,
      team.partyId,
      member.userId
    )
      .then((response) => {
        props.onReloadTournament(response);
        toast.success("Team member removed successfully!");
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
      });
  };

  const editTeamName = () => {
    if(!teamNameInput?.current?.value) {
      return
    }
    setLoading(true);
    TournamentService.editTeamName(tournament.id, team.partyId, teamNameInput.current.value).then(() => {
      setLoading(false);
      toast.success("Team Updated successfully!")
      props.onReloadTournament()
    }).catch(error => {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    })
  }

  const onCloseModal = () => {
    setShowModal(false);
    props.onReloadTournament();
  };

  return (
    <>
      <div className="skp-link-hover" onClick={() => setShowModal(true)}>
        <i className="las la-pen"></i> Edit Team
      </div>

      <Modal title="Edit Team" show={showModal} onClose={onCloseModal}>
        <>
          <div className="container">
            <div className="columns">
              <div className="column col-6 col-xs-12 skp-text-primary">
                <div className="skp-form-control mb-2">
                  <label
                    htmlFor={`team-name-${team.partyId}`}
                    className="skp-text-small"
                  >
                    Team Name
                  </label>
                  <input
                    ref={teamNameInput}
                    defaultValue={team.partyName}
                    id={`team-name-${team.partyId}`}
                  />
                </div>
              </div>
              <div className="column col-6 col-xs-12 skp-text-primary skp-text-small flex-centered">
                <dl>
                  <dd>
                    <UserProfileWithDetailsModal
                      userId={team.leader}
                      tournament={tournament}
                    >
                      <span className="skp-chip status-captain skp-text-small">
                        Leader
                      </span>
                    </UserProfileWithDetailsModal>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          {team.members.length < numberMembersAllowed && (
            <div className="container">
              <div className="columns">
                <div className="space-divider-20 skp-link-hover ml-2 skp-text-default text-center-xs">
                  <AddTournamentTeamPlayerModal
                    tournament={tournament}
                    team={team}
                    onAddMember={addMember}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="container skp-text-default">
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12 skp-text-primary">
                Players:
                <dl className="bordered-list">
                  {team.members
                    .filter((member) => Number(member.role) === 0)
                    .map((member) => (
                      <dd
                        className="columns"
                        key={`players-list-${member.userId}`}
                      >
                        <div className="column col-6 col-xs-12">
                          <UserProfileWithDetailsModal
                            userId={member.userId}
                            tournament={tournament}
                          >
                            {member.userId === team.leader && (
                              <span className="skp-chip status-captain skp-text-small">
                                Leader
                              </span>
                            )}
                          </UserProfileWithDetailsModal>
                        </div>
                        <div className="column col-2 col-xs-12 mt-2">
                          <TeamRoleDropdown
                            member={member}
                            team={team}
                            tournament={tournament}
                            onReloadTournament={props.onReloadTournament}
                          />
                        </div>
                        <div className="column col-2 col-xs-12 mt-2">
                          {member.userId !== team.leader && (
                            <TeamStatusDropdown
                              member={member}
                              team={team}
                              tournament={tournament}
                              onReloadTournament={props.onReloadTournament}
                            />
                          )}
                        </div>
                        <div className="column col-1"></div>
                        <div className="column col-1 flex-centered col-xs-12 mt-2">
                          {member.userId !== team.leader && (
                            <button className="skp-btn skp-btn-alert" style={{width: "100%"}} onClick={() => removeMember(member)}>
                              <i className="las la-trash"></i>
                            </button>
                          )}
                        </div>
                      </dd>
                    ))}
                </dl>
              </div>
            </div>
          </div>

          <div className="container skp-text-default">
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12 skp-text-primary">
                Reserves:
                <dl className="bordered-list">
                  {team.members
                    .filter((member) => Number(member.role) === 1)
                    .map((member) => (
                      <dd
                        className="columns"
                        key={`reserves-list-${member.userId}`}
                      >
                        <div className="column col-6">
                          <UserProfileWithDetailsModal
                            userId={member.userId}
                            tournament={tournament}
                          >
                            {member.userId === team.leader && (
                              <span className="skp-chip status-captain skp-text-small">
                                Leader
                              </span>
                            )}
                          </UserProfileWithDetailsModal>
                        </div>
                        <div className="column col-2">
                          <TeamRoleDropdown
                            member={member}
                            team={team}
                            tournament={tournament}
                            onReloadTournament={props.onReloadTournament}
                          />
                        </div>
                        <div className="column col-2">
                          {member.userId !== team.leader && (
                            <TeamStatusDropdown
                              member={member}
                              team={team}
                              tournament={tournament}
                              onReloadTournament={props.onReloadTournament}
                            />
                          )}
                        </div>
                        
                        <div className="column col-1"></div>
                        <div className="column col-1 flex-centered col-xs-12 mt-2">
                          {member.userId !== team.leader && (
                            <button className="skp-btn skp-btn-alert" style={{width: "100%"}} onClick={() => removeMember(member)}>
                              <i className="las la-trash"></i>
                            </button>
                          )}
                        </div>
                      </dd>
                    ))}
                </dl>
              </div>
            </div>
          </div>

          <div className="container skp-text-default">
            <div className="columns">
              <div className="column col-12 skp-text-primary">
                Coaches:
                <dl className="bordered-list">
                  {team.members
                    .filter((member) => Number(member.role) === 2)
                    .map((member) => (
                      <dd
                        className="columns"
                        key={`coaches-list-${member.userId}`}
                      >
                        <div className="column col-6">
                          <UserProfileWithDetailsModal
                            userId={member.userId}
                            tournament={tournament}
                          >
                            {member.userId === team.leader && (
                              <span className="skp-chip status-captain skp-text-small">
                                Leader
                              </span>
                            )}
                          </UserProfileWithDetailsModal>
                        </div>
                        <div className="column col-2">
                          <TeamRoleDropdown
                            member={member}
                            team={team}
                            tournament={tournament}
                            onReloadTournament={props.onReloadTournament}
                          />
                        </div>
                        <div className="column col-2">
                          {member.userId !== team.leader && (
                            <TeamStatusDropdown
                              member={member}
                              team={team}
                              tournament={tournament}
                              onReloadTournament={props.onReloadTournament}
                            />
                          )}
                        </div>
                        <div className="column col-1"></div>
                        <div className="column col-1 flex-centered col-xs-12 mt-2">
                          {member.userId !== team.leader && (
                            <button className="skp-btn skp-btn-alert" style={{width: "100%"}} onClick={() => removeMember(member)}>
                              <i className="las la-trash"></i>
                            </button>
                          )}
                        </div>
                      </dd>
                    ))}
                </dl>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="columns">
              <div className="column col-12 skp-text-primary skp-text-small">
                Team Size: {tournament.teamSize}{" "}
                <span className="ml-2">
                  <br></br>
                  Team Size with reserves and coaches: {numberMembersAllowed}
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="columns">
              <div className="space-divider"></div>
              <div className="column col-12 text-right">
                <button className="skp-btn skp-btn-primary" onClick={editTeamName} disabled={loading}>Save</button>
                
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default EditTournamentTeamModal;
