import "./chat-tab.scss"
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import ITournament from "../../../Interfaces/Tournament";
import IMessage from "../../../Interfaces/Chat/Message";
import IUserDetails from "../../../Interfaces/UserDetails";
import { UserContext } from "../../../Context/UserContext";
import ChatService from "../../../../services/ChatService";

type Props = {
    tournament: ITournament | undefined,
    isAdmin: boolean,
    replyMsg: IMessage | undefined,
    replyMessage: (replyMessage: IMessage | undefined) => void,
    chatId: string | undefined,
    setChatId: React.Dispatch<React.SetStateAction<string | undefined>>,
    contacts: Array<IUserDetails | null>,
    setChatHistory: React.Dispatch<React.SetStateAction<IMessage[]>>
}

const ChatTabFooter = (props: Props) => {
    const { tournament, isAdmin, replyMsg, replyMessage, chatId, setChatId, contacts, setChatHistory } = props;
    const { user } = useContext(UserContext);
    const { t } = useTranslation()
    const messageInput = useRef<HTMLInputElement>(null);
    const [openPicker, setOpenPicker] = useState(false)
    const [loading, setLoading] = useState(false)
    const [theMessage, setMessage] = useState("")

    const getReplyUserName = () => {
        let response:string | undefined = ""
        if (replyMsg?.userid == user?.id) {
            response = t('messages.you')
        } else {
            contacts.map((c) => {
                if (c?.id == replyMsg?.userid) {
                    response = c?.username
                }
            })
        }
        return response
    }

    const addEmoji = (e) => {
        setMessage(theMessage+e.native);
        setOpenPicker(!openPicker)
        messageInput.current?.focus()
    }
    
    const sendMessage = () => {
        if (theMessage && theMessage.length > 0) {
            // Crea il messaggio base senza accodarlo subito
            let baseMessage: IMessage = {
                chatId: chatId || '',
                type: "text",
                text: theMessage,
                userid: user?.id!,
            };
    
            if (replyMsg) {
                baseMessage = {
                    ...baseMessage,
                    type: "reply",
                    reply: replyMsg.text,
                    replyid: replyMsg.userid,
                    replytype: replyMsg.type
                };
            }
    
            setLoading(true);
    
            if (!chatId) {
                ChatService.createTournamentChat(tournament?.id!).then((resp) => {
                    setChatId(resp.chatid);
    
                    const newMessage: IMessage = {
                        ...baseMessage,
                        chatId: resp.chatid,
                    };
    
                    // Invia il messaggio e accodalo solo dopo aver ricevuto la risposta
                    ChatService.sendMessage(newMessage).then((resp2) => {
                        setChatHistory(prevHistory => [...prevHistory, resp2]);
                        console.log("Messaggio inviato", resp2);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            } else {
                ChatService.sendMessage(baseMessage).then((resp2) => {
                    setChatHistory(prevHistory => [...prevHistory, resp2]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
            }
        }
    
        replyMessage(undefined);
        setMessage("");
    }
    
    return (
        <>
            {replyMsg && ( 
                <div className="skp-chat-reply-conversation-footer">
                    <div className="skp-msg-balloon-replyed" style={{display:"flex"}}>
                        <div className="chat-tab-msg-reply-balloon-replyed-content">
                            <div className="skp-text-default skp-msg-reply-username-footer">
                                {getReplyUserName()}
                            </div>
                            <div className="skp-text-small skp-text-light skp-reply-text-footer">
                                {replyMsg.type !== 'image' && replyMsg.text}
                                {replyMsg.type === 'image' && (
                                    <div>
                                        <i className="las la-camera" style={{fontSize: "16px"}}></i> 
                                            {t('messages.photo')}
                                        <img className="skp-reply-image" src={replyMsg.text}></img>
                                    </div>
                                )} 
                            </div>
                        </div>
                        <div
                            className="skp-delete-reply-button float-right skp-text-light"
                            onClick={() => replyMessage(undefined)}
                        >
                            <i className="las la-times-circle skp-delete-reply-icon"></i>
                        </div>
                    </div>
                </div>
            )}
            <div className="skp-chat-conversation-footer">
                <div className="skp-form-control" style={{display:"flex"}}>
                    <div className="skp-chat-message-form-control">
                        <input
                            ref={messageInput}
                            name="theMessage"
                            placeholder={t('messages.writeMessage')}
                            value={theMessage}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter")
                                    sendMessage()
                                }}
                        />
                    </div>
                    {openPicker && (
                        <div className="chat-tab-emoji-container">
                            <Picker data={data} onEmojiSelect={(e) => addEmoji(e) } theme="dark"/>
                        </div>  
                    )}
                    <div
                        className="skp-emoji-picker-button float-right"
                        onClick={() => setOpenPicker(!openPicker)}
                    >
                        {String.fromCodePoint(0x1f600)}
                    </div>
                    <button
                        disabled={loading}
                        className="skp-chat-message-form-button skp-btn-primary float-right"
                        onClick={sendMessage}
                    >
                        <i className="las la-paper-plane"></i>
                    </button>
                </div>
            </div>
        </>
    );
};
  
export default ChatTabFooter;