
import { useContext } from "react";
import { RegistrationModalContext } from "../../Context/RegistrationModalContext";
import KeyCloackService from "../../../services/KeyCloackService";

type Props = {
    message: string | null
    onClose: () => void
}

const LoginOrRegisterModal = (props:Props) => {
    const {message} = props
    const {setShowSignUpModal} = useContext(RegistrationModalContext);

    const signUp = () => {
        setShowSignUpModal({show: true, type: "signup"});
        props.onClose();
    }

    return (
        <div className="container">
            <div className="columns">
                <div className="space-divider"></div>
                <div className="column col-1"></div>
                <div className="column col-10">
                    <div className="columns">
                        <div className="column col-12 text-center">
                            <div className="logo-skillpro logo-skillpro-xl"></div> 
                        </div>
                        <div className="space-divider-20"></div>
                        <div className="column col-12">
                            <h2 className="skp-text-primary text-center">{message}</h2>
                        </div>
                        <div className="space-divider-20"></div>
                        <div className="column col-12 text-center">
                            <button className="skp-btn skp-btn-secondary skp-btn-full-width" onClick={() => KeyCloackService.doLogin()}>Log In</button>
                            <div className="space-divider-5"></div>
                            <button className="skp-btn skp-btn-primary skp-btn-full-width" onClick={signUp}>Sign Up</button>
                        </div>
                    </div>
                </div>
                <div className="column col-1"></div>
                <div className="space-divider"></div>
            </div>
        </div>
    )
}

export default LoginOrRegisterModal