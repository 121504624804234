
import constant from "../constants";
import { setupCache } from "axios-cache-adapter";
import HttpService from "./HttpService";
import ISupercellPlayer from "../components/Interfaces/SupercellPlayer";
import ITournament from "../components/Interfaces/Tournament";


const cache = setupCache({
  maxAge: 1 * 60 * 1000,
});

const _axios = HttpService.getAxiosClient();


const generatePlayers = (tournamentId:string, tag:string, password?: string):Promise<ITournament> => {
  console.log(password)
  if(tag[0] === '#') {
    tag = tag.replace('#', '')
  }
  const url = `${constant.API_URL}/tournaments/${tournamentId}/supercell/lobby`;

  return _axios({
    method: "post",
    url: url,
    data: {tag, password}
  }).then((response) => {
    return response.data.data;
  });
}

const getPlayers = (tournamentId:string, lobbyId:string):Promise<Array<ISupercellPlayer>> => {
  console.log(lobbyId)
  if(lobbyId[0] === '#') {
    lobbyId = lobbyId.replace('#', '')
  }
  const url = `${constant.API_URL}/tournaments/${tournamentId}/supercell/lobby/${lobbyId}/players`;

  return _axios({
    method: "get",
    url: url,
  }).then((response) => {
    return response.data.data;
  });
}

const getPlayerStats = (userId:string, playerTag:string):Promise<any> => {
  if(playerTag[0] === '#') {
    playerTag = playerTag.replace('#', '')
  }
  const url = `${constant.API_URL}/users/${userId}/games/clash-royale/${playerTag}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response.data.data;
  });
}




const SupercellTournamentService = {
  getPlayers,
  generatePlayers,
  getPlayerStats
};

export default SupercellTournamentService