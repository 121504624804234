import React, { useEffect, useState } from "react";
import YuniteTournamentService from "../../../../services/YuniteTournamentService";
import YuniteTeam from "../../../UiLibrary/YuniteTeam";
import "../TournamentTabs/tournaments-tab.scss";
import InfiniteScroll from "react-infinite-scroll-component";

function TeamsTab(props) {
  const { tournament } = props;
  const [teams, setTeams] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState([]);

  useEffect(() => {
    let items = [];
    const id = tournament?.yuniteId ? tournament?.yuniteId : tournament?.id;
    if (id) {
      YuniteTournamentService.getCacheTeams(id).then((response) => {
        setTeams(response);
        const index = response.length >= 10 ? 10 : response.length;
        for (let i = 0; i < index; i++) {
          items.push(response[i]);
        }
        setPaginatedItems(items);
      });
    }

    return () => {
      setPaginatedItems();
      setTeams();
    };
  }, [tournament]);

  const onScrollInfinite = (variables) => {};

  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginatedItems([...paginatedItems, teams[paginatedItems.length]]);
    }, 100);
  };

  return (
    <div className="players-tab container">
      <div className="space-divider-20"></div>
      <div className="columns">
      <div className="column col-4 col-xs-12">
          <h5 className="skp-text-primary">Confirmed on Discord</h5>
          <dl className="skp-text-default skp-text-light">
            <dd>
              Se il proprio nome NON compare nella sezione Teams la
              registrazione non è ancora terminata. Per completarla attendi il
              periodo di Check In (vai nella scheda Overview per vedere quando
              inizia il periodo di Check In dell’evento)
            </dd>
            <dd>Completa i seguenti passaggi per effettuare il Check In:</dd>
            <ol>
              <li>
                Una volta iniziato il periodo di Check In, accedi al Discord di
                Skillpro e clicca sulla categoria dell’evento (la categoria ha
                lo stesso nome del torneo presente su Skillpro).
              </li>
              <li>
                Clicca su “registrazione-team” e successivamente sulla “T” del
                messaggio di Yunite.
              </li>
              <li>
                Segui la procedura che arriva in DM su Discord (basta cliccare
                il pulsante “+” nel messaggio che arriva direttamente da
                Yunite).
              </li>
              <li>
                Se la procedura è andata a buon fine vedrai la scritta verde
                “Confirmed” di fianco al tuo nome nella scheda Players
                dell’evento.
              </li>
            </ol>
          </dl>
        </div>
        <div className="column col-8 col-xs-12">
          {teams?.length > 0 && (
            <div className="columns">
              <div className="column col-12">
                <h5 className="skp-text-primary">Teams</h5>
                <div className="columns">
                  {paginatedItems?.length > 0 && (
                    <InfiniteScroll
                      dataLength={paginatedItems?.length}
                      onScroll={onScrollInfinite}
                      scrollableTarget="scrollableDiv"
                      next={fetchMoreData}
                      hasMore={teams.length > paginatedItems?.length}
                      style={{ overflow: "hidden" }}
                      loader={<div className="loading"></div>}
                      scrollThreshold={"100px"}
                      endMessage={
                        <>
                          {paginatedItems.length >= 10 && (
                            <>
                              <p
                                className="text-center skp-text-light m-2"
                                style={{ textAlign: "center" }}
                              >
                                END
                              </p>
                            </>
                          )}
                        </>
                      }
                    >
                      {paginatedItems?.map((team, index) => {
                        return !team.disqualified ? (
                          <div
                            className="column col-12"
                            key={`team-${team.id}`}
                          >
                            <YuniteTeam team={team} index={index} tournament={tournament} />
                          </div>
                        ) : null;
                      })}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* No Teams */}
          {teams?.length === 0 && (
            <div className="columns skp-text-light text-center">
              <div className="space-divider"></div>
              <div className="column col-12">
                <h1>
                  <i className="las la-user-friends"></i>
                </h1>
              </div>
              <div className="column col-12">
                <h5>No Teams</h5>
              </div>
              <div className="column col-12">
                There are currently no Teams registered for this Tournament.
              </div>
            </div>
          )}
        </div>
        
      </div>
    </div>
  );
}

export default TeamsTab;
