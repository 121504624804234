import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import GameTile from "../../UiLibrary/GameTile";
import TournamentControl from "./TournamentsControls/TournamentsControl";
import GAMES from "../../../constants-games";
import IGame from "../../Interfaces/Game";
import { useTranslation } from "react-i18next";

type Props = {
  onCreate: (tournamentId: string) => void;
};

const games = GAMES;

const NewTournamentModal = (props: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation()

  const [gameSelected, selectGame] = useState<IGame[]>([games["FIFA22"]]);
  const [createStep, setCreateStep] = useState(1)

  const gamesList = Object.keys(games).filter((game) => games[game].ENUM !== "CLASH_OF_CLANS").map((game) => (
    <div className="column col-2 col-xs-5 column-slider" key={games[game].NAME} onClick={()=>setGame([games[game]])}>
      <GameTile game={games[game]} disableLink={true} hideFav={true}></GameTile>
    </div>
  ));

  const setGame = (game:IGame[]) => {
    selectGame(game)
    setCreateStep(2)
  }


  return (
    <div className="container new-tournament-modal">
      <div className="columns">
        {createStep===1 && (
          <>
        <div className="column col-12">
          <h6 className="skp-text-primary">{t('tournament.create.step1')}</h6>
        </div>
            <div className="columns columns-slider">
              <div className="space-divider-20"></div>
              {gamesList}
        </div>
          </>
        )}
        <div className="space-divider-20"></div>
        {createStep===2 && (
          <>
        <div className="column col-12">
          <h6 className="skp-text-primary">{t('tournament.create.step2')}</h6>
        </div>
        <div className="column col-12">
          {gameSelected && <TournamentControl
            game={gameSelected[0]}
            user={user}
            onCreate={props.onCreate}
          />}
        </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewTournamentModal;
