import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { NavLink, useParams } from "react-router-dom";
import Marquee from "react-text-marquee";
import TournamentService from "../../../../../services/TournamentService";
import Modal from "../../../../UiLibrary/Modal";
import UserProfile from "../../../../UiLibrary/UserProfile";
import "./boxfight.scss";

const sheetId = "1_AhDAOT3Y_1E8_jpJeuxnW61Cyfc9TbHPKNZDVOqQc8";

type Props = {
  team: string | undefined;
};

const TeamModal = (props: Props) => {
  const { team } = props;
  const [players, setPlayers] = useState<string[]>();

  useEffect(() => {
    if (team) {
      TournamentService.getSpreadsheet(sheetId, team).then((response) => {
        console.log(response);
        setPlayers(response[0]);
      });
    }
  }, [team]);
  return (
    <>
      <dl>
        {players?.map((player) => (
          <dd>{player}</dd>
        ))}
      </dl>
    </>
  );
};

const BoxFight = () => {
  const [classifica, setClassifica] =
    useState<Array<{ team?: string; score?: number }>>();
  const [results, setResults] = useState<
    Array<{
      teamA?: string;
      teamB?: string;
      rTeamA?: string;
      rTeamB?: string;
    }>
  >();
  const [selectedTeam, setSelected] = useState<string | undefined>();

  useEffect(() => {
    const getData = async () => {
      const [incontri, classifica] = await Promise.all([
        TournamentService.getSpreadsheet(sheetId, "Orari"),
        TournamentService.getSpreadsheet(sheetId, "Classifica"),
      ]);

      console.log(incontri);
      console.log(classifica);

      let classificaObjs: Array<{ team?: string; score?: number }> = [];
      let resultsObjs: Array<{
        teamA?: string;
        teamB?: string;
        rTeamA?: string;
        rTeamB?: string;
      }> = [];

      classifica[0].forEach((team: any, index: number) => {
        if (index > 0) {
          let newObj: { team?: string; score?: number } = {};
          newObj.team = classifica[0][index];
          newObj.score = Number(classifica[1][index]);
          classificaObjs.push(newObj);
        }
      });

      incontri[0].forEach((match: any, index: number) => {
        if (index > 0) {
          let newObj: {
            teamA?: string;
            teamB?: string;
            rTeamA?: string;
            rTeamB?: string;
          } = {};
          newObj.teamA = incontri[0][index] || "";
          newObj.teamB = incontri[1][index] || "";
          newObj.rTeamA = incontri[2][index] || "";
          newObj.rTeamB = incontri[3][index] || "";
          resultsObjs.push(newObj);
        }
      });

      console.log(resultsObjs);
      setClassifica(classificaObjs);
      setResults(resultsObjs);
    };

    getData(); // run it, run it
  }, []);

  return (
    <Scrollbars>
      <div className="main-container">
        <div className="milanocomics-event-wrapper"></div>

        <div className="container" style={{ position: "relative", zIndex: 1 }}>
          <div className="columns">
            <div className="column col-2 col-xs-12">
              <div className="space-divider"></div>
              <div className="logo-placeholder main-logo">
                <img
                  alt="milano-comics-game"
                  src="https://storage.googleapis.com/skillpro-bucket/Milano%20Comics%20-%20logo%202.png"
                ></img>
              </div>
              <div className="hide-xs">
                <div className="space-divider-20"></div>
                <div className="logo-placeholder">
                  <img
                    alt="eli-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/eli.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "50%", margin: "0px auto" }}
                >
                  <img
                    alt="leone-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/leone.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div className="logo-placeholder">
                  <img
                    alt="dsyre-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/dsyre.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div className="logo-placeholder">
                  <img
                    alt="ncontrol-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/ncontrol.png"
                  ></img>
                </div>
              </div>
            </div>
            <div className="column col-8 col-xs-12 text-center text-center-xs bracket-container">
              <div className="space-divider-20 hide-xs"></div>
              <h2 className="skp-text-primary">2v2 Boxfight Fortnite</h2>

              <Scrollbars className="day-scrollbar">
                <div
                  className="column col-12"
                  style={{
                    height: "100%",
                  }}
                >
                  {/* TO ADD */}
                  <>
                    <div className="columns skp-text-primary">
                      <div className="column col-8 col-xs-12">
                        <div className="columns">
                          <div className="column col-12 skp-text-xlarge">
                            Incontri
                          </div>
                          <div className="column col-12">
                            <dl className="box-card">
                              {results?.map((obj) => (
                                <>
                                  <dd className="columns">
                                    <span className="column col-5">
                                      <span className="columns">
                                        <span
                                          className="column col-8 text-left"
                                          onClick={() => setSelected(obj.teamA)}
                                        >
                                          <Marquee text={obj.teamA || ""} />
                                        </span>
                                        <span className="column col-4 text-right">
                                          {obj.rTeamA}
                                        </span>
                                      </span>
                                    </span>
                                    <span className="column col-2">:</span>
                                    <span className="column col-5">
                                      <span className="columns">
                                        <span className="column col-4 text-left">
                                          {obj.rTeamB}
                                        </span>
                                        <span
                                          className="column col-8 text-right"
                                          onClick={() => setSelected(obj.teamB)}
                                        >
                                          <Marquee text={obj.teamB || ""} />
                                        </span>
                                      </span>
                                    </span>
                                  </dd>
                                </>
                              ))}
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="column col-4 col-xs-12">
                        <div className="space-divider-20 show-xs"></div>
                        <div className="columns">
                          <div className="column col-12 skp-text-xlarge">
                            Classifica
                          </div>
                          <div className="column col-12">
                            <dl className="box-card">
                              {classifica
                                ?.sort((a, b) =>
                                  a.score && b.score && a.score < b.score
                                    ? 1
                                    : -1
                                )
                                .map((obj) => (
                                  <>
                                    <dd className="columns">
                                      <span className="column col-8 text-left">
                                        <span
                                          onClick={() => setSelected(obj.team)}
                                        >
                                          <Marquee text={obj.team || ""} />
                                        </span>
                                      </span>
                                      <span className="column col-4 text-right">
                                        {obj.score}
                                      </span>
                                    </dd>
                                  </>
                                ))}
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Scrollbars>
            </div>
            <div className="column col-2 col-xs-12">
              <div className="show-xs">
                <div className="space-divider-20"></div>
                <div className="logo-placeholder">
                  <img
                    alt="eli-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/eli.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div
                  className="logo-placeholder"
                  style={{ width: "70%", margin: "0px auto" }}
                >
                  <img
                    alt="leone-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/leone.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div className="logo-placeholder">
                  <img
                    alt="dsyre-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/dsyre.png"
                  ></img>
                </div>
                <div className="space-divider-20"></div>
                <div className="logo-placeholder">
                  <img
                    alt="ncontrol-logo"
                    src="https://storage.googleapis.com/skillpro-bucket/ncontrol.png"
                  ></img>
                </div>
              </div>
              <div className="space-divider"></div>

              <div className="logo-placeholder">
                <img
                  alt="pchunter-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/Octo-PCH_LOGO-V_C.png"
                ></img>
              </div>
              <div className="logo-placeholder">
                <img
                  alt="beebad-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/Logo%20Beebad.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div
                className="logo-placeholder"
                style={{ width: "70%", margin: "0px auto" }}
              >
                <img
                  alt="retrogame-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/retrograme.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div
                className="logo-placeholder"
                style={{ width: "70%", margin: "0px auto" }}
              >
                <img
                  alt="kink-esport-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/king-esport.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div
                className="logo-placeholder"
                style={{ width: "70%", margin: "0px auto" }}
              >
                <img
                  alt="volandia-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/volandia.png"
                ></img>
              </div>
              <div className="space-divider-20"></div>
              <div
                className="logo-placeholder"
                style={{ width: "70%", margin: "0px auto" }}
              >
                <img
                  alt="space-monkeys-logo"
                  src="https://storage.googleapis.com/skillpro-bucket/foto-nerd.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={selectedTeam}
        show={selectedTeam ? true : false}
        onClose={() => setSelected(undefined)}
      >
        <TeamModal team={selectedTeam}></TeamModal>
      </Modal>
    </Scrollbars>
  );
};

export default BoxFight;
