import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import TournamentService from "../../../../services/TournamentService";
import { UserContext } from "../../../Context/UserContext";
import TournamentImageBanner from "../../../UiLibrary/TournamentImageBanner";
import ProfileName from "../../../UiLibrary/ProfileName";
import KeyCloackService from "../../../../services/KeyCloackService";
import ReactTimeAgo from "react-time-ago";
import Moment from "moment";
import Marquee from "react-text-marquee";
import Scrollbars from "react-custom-scrollbars";
import Modal from "../../../UiLibrary/Modal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import { useNavigate, Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import ITournament from "../../../Interfaces/Tournament";
import TournamentJoinButtons from "../TournamentSinglePages/TournamentJoinButtons";

const JoinTournamentPage = () => {
  const { user } = useContext(UserContext);
  const params = useParams<{ id: string }>();
  const tournamentId = params.id;

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tournament, setTournament] = useState<ITournament>();
  const [showModal, setShowModal] = useState(false);
  const [memberStatus, setMemberStatus] = useState<ITournamentMemberStatus>({
    isPending: false,
    team: undefined,
    isLeader: false,
    accepted: false,
    joined: false,
    isRejected: false,
    isAdmin: false,
    isOwner: false,
    checkedIn: false
  });

  const now = new Date();
  let registrationClosed: boolean = false;
  let checkinClosed: boolean = false;
  if (tournament?.end_registration) {
    const registration = new Date(tournament?.end_registration);
    registrationClosed = registration < now || tournament?.status !== 0;
  }
  if (tournament?.end_checkin) {
    const checkin = new Date(tournament?.end_checkin);
    checkinClosed = checkin < now || tournament?.status != 0;
  }
  const startRegistration = new Date(tournament?.start_registration || "")
  const registrationStarted = startRegistration < now || tournament?.status !== 0;

  const goToTournament = () => {
    navigate(`/tournaments/${tournamentId}/overview`);
  };

  const loadTournament = useCallback(() => {
    setLoading(true);
    TournamentService.getTournament(tournamentId!).then((tournament) => {
      const isTournamentAdmin = tournament?.admins?.includes(user?.id!);
      /*const isAdmin =
        KeyCloackService.hasRole(["Tournament Admin"]) &&
        (tournament?.owner === user?.id || isTournamentAdmin);
        */
      const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

      setTournament(tournament);
      let isRejected = false;
      let isLeader = false;
      let myTeam;
      let isPending = false;
      let accepted = false;
      tournament?.teams.forEach((team) => {
        const ismember = team.members.filter(
          (member) => member.userId === user?.id
        );

        if (team.leader === user?.id) {
          isLeader = true;
        }

        if (ismember.length > 0) {
          myTeam = team;
        }

        if (ismember.length > 0 && ismember[0].status === 0) {
          isPending = true;
        }

        if (ismember.length > 0 && Number(ismember[0].status) === 2) {
          isRejected = true;
        }

        if (
          ismember.length > 0 &&
          Number(ismember[0].status) === 1 &&
          !isLeader
        ) {
          accepted = true;
        }
      });

      setMemberStatus({
        isPending: isPending,
        team: myTeam,
        isLeader: isLeader,
        accepted: accepted,
        joined: tournament.members.includes(user?.id!),
        isRejected: isRejected,
        isAdmin: isAdmin,
        isOwner: tournament?.owner === user?.id,
        checkedIn: tournament.confirmedMembers.includes(user?.id!)
      });

      setLoading(false);
    });
  }, [tournamentId, user]);

  useEffect(() => {
    loadTournament();
  }, [tournamentId, loadTournament]);

  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-page-with-image-banner">
          {tournament && <TournamentImageBanner tournament={tournament} />}

          {/* PAGE TOP */}
          <div className="container page-top">
            <div className="columns text-center">
              <div className="column col-12 col-xs-12">
                <span className="skp-text-label skp-text-light">
                  JOIN TOURNAMENT
                </span>
                <h2 className="skp-text-primary skp-heading-no-margin p-2 m-2 show-xs">
                  <Link
                    className="skp-link-hover"
                    to={`/tournaments/${tournament?.id}`}
                  >
                    <Marquee text={tournament?.name || ""} />
                  </Link>
                </h2>
                <h1 className="skp-text-primary skp-heading-no-margin p-2 m-2 hide-xs">
                  <Link
                    className="skp-link-hover"
                    to={`/tournaments/${tournament?.id}`}
                  >
                    <Marquee text={tournament?.name || ""} />
                  </Link>
                </h1>
                {tournament && tournament.start_tournament && (
                  <div className="skp-text-light skp-text-small">
                    <ReactTimeAgo date={new Date(tournament?.start_tournament)} /> -{" "}
                    {Moment(tournament?.start_tournament).format(
                      "MMM DD, hh:mm A"
                    )}{" "}
                  </div>
                )}
                {KeyCloackService.isLoggedIn() && (
                  <div className="skp-text-light mt-2 skp-text-label">
                    Hosted by <ProfileName owner={tournament?.owner} />{" "}
                    {tournament?.yuniteId && (
                      <span className="skp-chip yunite skp-text-default text-uppercase">
                        {" "}
                        Yunite
                      </span>
                    )}
                    {!tournament?.yuniteId && !tournament?.open && (
                      <span className="skp-chip yunite skp-text-default text-uppercase">
                        {" "}
                        Private
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* END PAGE TOP */}

          {/* PAGE CONTENT */}
          {KeyCloackService.isLoggedIn() && (
            <>
              {/* Registration Open */}
              {!registrationClosed && (
                <div className="container">
                  <div className="columns">
                    <div className="space-divider-20"></div>
                    <div className="column col-12 text-center">
                      {tournament && (
                        <TournamentJoinButtons
                          tournament={tournament}
                          memberStatus={memberStatus}
                          registrationStarted={registrationStarted}
                          registrationClosed={registrationClosed}
                          checkinClosed={checkinClosed}
                          onReloadTournament={goToTournament}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* END Registration Open */}

              {/* Registration Closed */}
              {registrationClosed && (
                <>
                  <div className="columns text-center">
                    <div className="space-divider"></div>
                    <div className="column col-12 text-center-xs skp-text-primary">
                      <div className="skp-chip skp-text-default status-closed text-uppercase">
                        Registration Closed
                      </div>
                      {tournament?.status === 2 && (
                        <span
                          className={`skp-chip skp-text-default text-uppercase ${
                            tournament?.status === 2 ? "status-completed" : ""
                          }`}
                        >
                          Completed
                        </span>
                      )}
                      {tournament?.status === 1 && (
                        <span
                          className={`skp-chip skp-text-default text-uppercase ${
                            tournament?.status === 1 ? "status-ongoing" : ""
                          }`}
                        >
                          Ongoing
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* END Registration Closed */}
            </>
          )}

          {!KeyCloackService.isLoggedIn() && (
            <>
              <div className="columns">
                <div className="space-divider"></div>
                <div className="column col-12 text-center">
                  <h6 className="skp-text-light p-2 m-2">
                    You must login or sign up to join the tournament
                  </h6>
                  <button
                    className="skp-btn skp-btn-primary"
                    onClick={() => setShowModal(true)}
                  >
                    Login or Sign Up
                  </button>
                </div>
              </div>

              <Modal
                show={showModal}
                size="small"
                onClose={() => setShowModal(false)}
              >
                <LoginOrRegisterModal
                  message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
                  onClose={() => setShowModal(false)}
                />
              </Modal>
            </>
          )}
          <div className="container">
            <div className="space-divider"></div>
            <div className="columns">
              <div className="column col-12 text-center">
                <div className="skp-text-small m-2 skp-text-light">
                  Share on
                </div>
                <TwitterShareButton
                  title={`Join ${tournament?.name} - ${tournament?.game} @`}
                  url={`${constant.APP_URL}/tournaments/${tournament?.id}/join`}
                  hashtags={[tournament?.game || "", "Skillpro"]}
                >
                  <TwitterIcon round={true} size={35} />
                </TwitterShareButton>

                <FacebookShareButton
                  url={`${constant.APP_URL}/tournaments/${tournament?.id}/join`}
                  quote={`Join ${tournament?.name} - ${tournament?.game} @`}
                  className="Demo__some-network__share-button"
                  style={{ marginLeft: 10 }}
                >
                  <FacebookIcon size={35} round />
                </FacebookShareButton>
              </div>
            </div>
          </div>

          {/* END PAGE CONTENT */}
        </div>
      </Scrollbars>
    </>
  );
};

export default JoinTournamentPage;
