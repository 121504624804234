import constant from "../constants";
import HttpService from "./HttpService";
import { setupCache } from "axios-cache-adapter";
import MatchRound from "../components/Interfaces/MatchRound";
import IMatchBody from "../components/Interfaces/MatchBody";
import IMatch from "../components/Interfaces/Match";
import IMatchRound from "../components/Interfaces/MatchRound";
import { INotificationElement } from "../components/Interfaces/notification.interface";

const cache = setupCache({
  maxAge: 3 * 60 * 1000,
  exclude: { query: false }
});

let _axios = HttpService.getAxiosClient();

const generateMatchesRounds = (numberOfTeams:number) => {
  let matchArray:any = [];

  while (numberOfTeams > 1) {
    numberOfTeams = (numberOfTeams + 1) >> 1;
    let matches:IMatchRound[] = [];
    for (let i = 0; i < numberOfTeams; ++i) {
      const match:MatchRound = {
        id: i,
        date: new Date().toDateString(),
        teams: [
          { id: null, name: null },
          { id: null, name: null },
        ],
      };
      matches.push(match);
    }

    matchArray.push({ title: "", seeds: matches });
  }

  matchArray.map((match, index) => {
    let title;

    if (index === matchArray.length - 1) {
      title = `Final`;
    } else if (index === matchArray.length - 2 && matchArray.length > 2) {
      title = `Semi Final`;
    } else {
      title = `Round ${index + 1}`;
    }

    return (match.title = title);
  });
  return matchArray;
};

const generateLobbiesRounds = (numberOfLobbies:number, playerPerLobby:number) => {
  let matchArray:any = [];

  while (numberOfLobbies > 1) {
    numberOfLobbies = (numberOfLobbies + 1) >> 1;
    let matches:any = [];
    for (let i = 0; i < numberOfLobbies; ++i) {
      let players:any = [];
      for (let index = 0; index < playerPerLobby; index++) {
        players.push({ id: null, name: null })
      }
      const match = {
        id: i,
        date: new Date().toDateString(),
        teams: [{name: "Lobby Name", players: players}, {name: "Lobby Name", players: players}],
        title: `Match ${i + 1}`
      };
      matches.push(match);
    }

    matchArray.push({ title: "", seeds: matches });
  }
  matchArray.map((match, index) => {
    let title;

    if (index === matchArray.length - 1) {
      title = `Final`;
    } else if (index === matchArray.length - 2 && matchArray.length > 2) {
      title = `Semi Final`;
    } else {
      title = `Round ${index + 1}`;
    }

    return (match.title = title);
  });

  matchArray.push()
  return matchArray;
};

const getNotification = (tournamentId:string, userId: string) => {
  const url = `${constant.API_URL}/matches/getNotificationsByTournamentId/${tournamentId}/${userId}`;

  return _axios({
    method: "get",
    url: url,
  }).then((response) => {
    return response;
  });
};

const setNotification = async (theNotification:INotificationElement) => {
  const url = `${constant.API_URL}/matches/setNotifications`;
  return _axios({
    method: "post",
    url: url,
    data: theNotification,
  }).then((response) => {
    return response.data.data;
  });
}

const getMatch = (matchId:string) => {
  const url = `${constant.API_URL}/matches?_id=${matchId}`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
};

const getMatches = (matchesId:Array<string>):Promise<Array<IMatch>> => {
  const url = `${constant.API_URL}/matches?ids=${matchesId.toString()}`;

  return _axios({
    method: "get",
    url: url,
  }).then((response) => {
    return response.data.data.matches;
  });
};

const getMatchesEvents = (matchesId:Array<string>):Promise<{totalReports, countWaitModeration}> => {
  const url = `${constant.API_URL}/matches/matchesEvents?ids=${matchesId.toString()}`;

  return _axios({
    method: "get",
    url: url,
  }).then((response) => {
    return response.data.data;
  });
};

const getMatchsWithQuery = (queryParam:any) => {
  let url = `${constant.API_URL}/matches?userId=${queryParam.userId}&tournamentId=${queryParam.tournamentId}&limit=20`;

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
}

const getTournamentMatches = (tournamentId:string, limit:number, disableCache = false):Promise<Array<IMatch>> => {
  const url = `${constant.API_URL}/matches?tournamentId=${tournamentId}${limit ? "&limit="+limit : ""}`

  return _axios({
    method: "get",
    url: url,
    adapter: !disableCache ? cache?.adapter : undefined,
  }).then((response) => {
    return response.data.data.matches;
  });
}

const getUserMatches = (userId:string) => {
  const url = `${constant.API_URL}/matches?userId=${userId}`

  return _axios({
    method: "get",
    url: url,
    adapter: cache?.adapter,
  }).then((response) => {
    return response;
  });
}

const assignVictory = async (data:any) => {
  const url = `${constant.API_URL}/matches/assignVictory`;
  return _axios({
    method: "post",
    url: url,
    data: data,
  }).then((response) => {
    return response.data.data;
  });
}

const updateMatch = (matchId:string, data:IMatchBody):Promise<IMatch> => {
  const url = `${constant.API_URL}/matches/${matchId}`;
  return _axios({
    method: "put",
    url: url,
    data: data,
  }).then((response) => {
    return response.data.data;
  });
}

const updateMatchScoreMedia = async(matchId:string, teamId:string | undefined, userId:string | undefined, data:any) => {
  const url = `${constant.API_URL}/matches/${matchId}/${teamId}/${userId}`;
  return await _axios({
    method: "put",
    url: url,
    data: data,
  }).then((response) => {
    return response;
  });
}

const updateAdminScreenshot = async(matchId:string, userId:string | undefined, data:any) => {
  const url = `${constant.API_URL}/matches/${matchId}/addAdminMedia/${userId}`;
  return await _axios({
    method: "put",
    url: url,
    data: data,
  }).then((response) => {
    return response;
  });
}

const getSingleMatch = (matchId:string):Promise<IMatch> => {
  const url = `${constant.API_URL}/matches/${matchId}`;
  return _axios({
    method: "get",
    url: url
  }).then((response) => {
    return response.data.data;
  }).catch((error) => {
    // Gestisci l'errore qui
    console.error('Non sono riuscito a reperire il match', error);
  });
}

const getMatchDetails = (matchId:string):Promise<IMatch> => {
  const url = `${constant.API_URL}/matches/getMatchDetails/${matchId}`;
  return _axios({
    method: "get",
    url: url
  }).then((response) => {
    return response.data.data;
  }).catch((error) => {
    // Gestisci l'errore qui
    console.error('Non sono riuscito a reperire il match', error);
  });
}


const deleteMedia = async(tournamentId:string, teamId?:string, mediaId?:string) => {
  const url = `${constant.API_URL}/matches/${tournamentId}/${teamId}?mediaId=${mediaId}`;
  return await _axios({
    method: "put",
    url: url
  }).then((response) => {
    return response;
  });
}

const deleteMatch = async(matchId?:string) => {
  const url = `${constant.API_URL}/matches/${matchId}`;
  return await _axios({
    method: "delete",
    url: url
  }).then((response) => {
    return response;
  });
}

const changeTeam = async(matchId:string | undefined, newTeam:string | undefined, oldTeam:string) => {
  let url;
  if(newTeam) {
    url = `${constant.API_URL}/matches/${matchId}/move/${newTeam}/${oldTeam}`;
  } else {
    url = `${constant.API_URL}/matches/${matchId}/move/${oldTeam}/undefined`;
  }
  

  return await _axios({
    method: "put",
    url: url
  }).then((response) => {
    return response;
  });
  
}

const MatchService = {
  generateMatchesRounds,
  generateLobbiesRounds,
  getNotification,
  getMatch,
  getMatches,
  getMatchesEvents,
  getSingleMatch,
  getMatchDetails,
  getTournamentMatches,
  getUserMatches,
  getMatchsWithQuery,
  updateMatch,
  updateMatchScoreMedia,
  updateAdminScreenshot,
  deleteMedia,
  changeTeam,
  assignVictory,
  deleteMatch,
  setNotification
};

export default MatchService;
