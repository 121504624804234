import TournamentService from "../../../../services/TournamentService";
import TextEditor from "../../../UiLibrary/RichTextEditor";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

const TournamentSettingsInformation = (props) => {
    const { keyValue: initialKeyValue, tournament } = props;
    const [keyValue, setKeyValue] = useState(initialKeyValue || "");  // Se non è passato, inizializza a stringa vuota
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const tournamentId = useParams().id;

    // Funzione per controllare se esiste un valore per il keyValue in genericTabs
    const findExistingValueForKey = (key) => {
        if (tournament.genericTabs && Array.isArray(tournament.genericTabs)) {
            const foundTab = tournament.genericTabs.find(tab => tab.key === key);
            return foundTab ? foundTab.value : "";
        }
        return "";
    };

    // Effetto per aggiornare il testo quando il keyValue cambia o quando c'è un valore passato come initialKeyValue
    useEffect(() => {
        if (initialKeyValue) {
            // Se initialKeyValue è passato, cerca direttamente in tournament se ha già un valore
            const existingValue = tournament[initialKeyValue];
            setText(Array.isArray(existingValue) ? existingValue[0] : existingValue || "");
        } else if (keyValue) {
            // Altrimenti, cerca in genericTabs
            const existingValue = findExistingValueForKey(keyValue);
            setText(existingValue);  // Se esiste, riempie il testo
        }
    }, [keyValue, initialKeyValue]);

    const updateTournament = (value, reset) => {
        setLoading(true);
        let data = {};

        if (initialKeyValue) {
            // Se keyValue è passato come parametro, segui la vecchia logica e aggiorna direttamente quel campo
            data[initialKeyValue] = value;
        } else {
            // Se keyValue non è passato, utilizza genericTabs
            const updatedGenericTabs = tournament.genericTabs ? [...tournament.genericTabs] : [];
            const existingTabIndex = updatedGenericTabs.findIndex(tab => tab.key === keyValue);

            if (existingTabIndex !== -1) {
                // Aggiorna il valore del tab esistente
                updatedGenericTabs[existingTabIndex].value = value;
            } else {
                // Aggiungi un nuovo tab
                updatedGenericTabs.push({ key: keyValue, value });
            }

            data.genericTabs = updatedGenericTabs;
        }

        if (reset) {
            setText("");
        }

        TournamentService.updateTournament(data, tournamentId)
            .then((response) => {
                setLoading(false);
                if (initialKeyValue) {
                    // Aggiorna solo il testo associato a keyValue nel caso retrocompatibile
                    setText(Array.isArray(response.data.data[initialKeyValue]) ? response.data.data[initialKeyValue][0] : response.data.data[initialKeyValue]);
                } else {
                    // Aggiorna il testo per il caso genericTabs
                    setText(value);
                }
                toast.success("Information updated successfully");
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data.message);
            });
    };

    return (
        <div className="column col-12">
            {/* Se il keyValue non è passato come parametro, mostra il campo di input */}
            {!initialKeyValue && (
                <div className="input-group">
                    <label htmlFor="keyValue">Key Value:</label>
                    <input
                        type="text"
                        id="keyValue"
                        value={keyValue}
                        onChange={(e) => setKeyValue(e.target.value)}
                        placeholder="Enter the key value"
                        className="form-input"
                    />
                </div>
            )}
            <TextEditor
                onSave={updateTournament}
                initialValue={text}
                isSaving={loading}
            />
        </div>
    );
};

export default TournamentSettingsInformation;
