import constant from "../constants";
import axios from 'axios';
import IFileBase64Image from "../components/Interfaces/FileBase64Image";

const uploadImage = async(image:IFileBase64Image) => {
    const fileExt = getFileExtension(image.name)!.toLowerCase();
    const fileName = `${makeImageName(15)}.${fileExt}`;
    const renamedImage = new File([image.file], fileName);
    let body = new FormData();

    body.append("file", renamedImage);

    const response = await axios({
      method: "post",
      url: `${constant.API_URL}/media`,
      data: body,
    });

    return response
};

const makeImageName = (length:number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getFileExtension = (fileName:string) => {
  return fileName.split(".").pop();
}

const generatePublicUrl = (data:{bucket:String, name:string}) => {
  const publicUrl = `${constant.GOOGLE_STORAGE_URL}/${data.bucket}/${data.name}`;
  return publicUrl
}

const ImageService = {
  uploadImage,
  makeImageName,
  getFileExtension,
  generatePublicUrl
};

export default ImageService;
