import ChatPageFooter from "./ChatPageFooter";
import ChatPageHeader from "./ChatPageHeader";
import ChatPageMessages from "./ChatPageMessages";

const ChatPageConversation = () => {
   
    return (
        <div className="skp-page-with-sidebar-main-content">
            <div className="skp-chat-conversation-page">
                <ChatPageHeader></ChatPageHeader>
                <ChatPageMessages></ChatPageMessages>
                <ChatPageFooter></ChatPageFooter>
            </div>
        </div>
    );
};
  
export default ChatPageConversation;