import { useContext, useEffect, useState } from "react";
import UserProfile from "../../UserProfile";
import "./chat-card.scss"
import UserService from "../../../../services/UserService";
import IUserDetails from "../../../Interfaces/UserDetails";
import { UserContext } from "../../../Context/UserContext";
import { ChatContext } from "../../../Context/ChatContext";
import Moment from "moment";
import { EventsContext } from "../../../Context/EventsContext";
import ChatService from "../../../../services/ChatService";
import IChat from "../../../Interfaces/Chat/Chat";

type Props = {
    id: string,
    isDeleted: boolean
};

const ChatCard = (props: Props) => {
    
    const { user } = useContext(UserContext)
    const { contacts, setContacts, setChatId } = useContext(ChatContext)
    const { id, isDeleted } = props;
    const [chat, setChat] = useState<IChat>()
    const [uunread, setUnread] = useState()
    const [loading, setLoading] = useState(false);
    const [userProfile, setUserProfile] = useState<IUserDetails>();
    const [theDate, setTheDate] = useState<string>()

    const { isConnected, socket } = useContext(EventsContext);

    const fetchChatData = async () => {
        try {
            const resp = await ChatService.getChatById(id, user?.id!);
            console.log(resp);
            setChat(resp);
        } catch (error) {
            console.log(error);
            setChat(undefined);
        }
    };

    useEffect(() => {
        fetchChatData();

        if (isConnected && socket) {
            socket.on(id, fetchChatData);

            return () => {
                socket.off(id, fetchChatData);
            };
        }
    }, [id, user?.id, isConnected, socket]);

    useEffect(()=>{
        const today = new Date();
        const date = new Date(chat?.time || "");
        let dayPassed = 0
    
        if (!isNaN(date.getTime())) {
            dayPassed = Math.round((today.getTime() - date.getTime()) / (1000 * 3600 * 24))
        }
    
        if ( dayPassed <= 0 ) {
            setTheDate(Moment(chat?.time).format("HH:mm"))
        } else if ( dayPassed === 1 ) {
            setTheDate("Yesterday")
        } else {
            setTheDate(Moment(chat?.time).format("DD-MM-YY"))
        }
    },[chat])

    /*
     * Qui devo avere la lista degli utenti della chat, per capire quale icona mettere
     */

    useEffect(() => {
        setLoading(true);

        const attendee = chat?.attendees.find((element) => element !== user?.id)

        if (attendee) {
            UserService.getUser(attendee)
            .then((Ruser) => {
                setUserProfile(Ruser);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
        }
    
        return () => {
            setUserProfile(undefined);
            setLoading(false);
        };
    }, [chat?.senderid]);

    const selectChat = () => {
        //setUnread(0);
        const newContactList:IUserDetails[] = []
        if (chat && chat.attendees) {
            for (let index = 0; index < chat?.attendees.length; index++ ) {
                UserService.getUser(chat?.attendees[index]).then((Ruser) => {
                    newContactList.push(Ruser)
                    setContacts(newContactList);
                }).catch(error => {
                    console.log("error")
                })
            }
        }
        setChatId(id)
    }

    return (
        <div className="skp-chat-card" onClick={selectChat}>
            <div className="container">
                <div className="columns">
                    <div className="column col-3 skp-chat-card-user-avatar">
                        <UserProfile 
                            user={userProfile}
                            hideName={true}
                        ></UserProfile>
                    </div>
                    <div className="column col-6 skp-chat-message-box">
                        <div className="skp-text-primary skp-text-small skp-chat-message-name" >
                            {isDeleted ? "🚫 " : ""}
                            {chat?.name ? chat?.name : userProfile?.username}
                        </div>
                        <div className="skp-text-light skp-text-small skp-chat-message-text">
                            {chat?.text}
                        </div>
                    </div>
                    <div className="column col-3 skp-text-primary skp-text-small">
                        <div className="skp-chat-card-time">
                            {theDate}
                        </div>
                            {chat && chat?.unread > 0 && (
                                <div className="skp-chat-unread-circle">
                                    <sup className="flex-centered">{chat?.unread}</sup>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};
  
export default ChatCard;