import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import TeamService from "../../../services/TeamService";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import OverviewTab from "./TeamTabs/OverviewTab";
import MembersTab from "./TeamTabs/MembersTab";
import Modal from "../../UiLibrary/Modal";
import UploadImageBox from "../../UiLibrary/UploadImageBox/UploadImageBox";
import ImageService from "../../../services/ImageService";
import { toast } from "react-toastify";
import UploadImageButton from "../../UiLibrary/UploadImageButton";
import { UserContext } from "../../Context/UserContext";
import LoginOrRegisterModal from "../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../constants";
import KeyCloackService from "../../../services/KeyCloackService";
import ITeam from "../../Interfaces/Team";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import TeamBrandLogo from "../../UiLibrary/TeamBrandLogo";

const TeamsSingle = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [teamInfo, setTeamInfo] = useState<ITeam>();
  const [showUploadModal, setShowUploadModal] = useState<{
    show: boolean;
    type?: string | undefined;
  }>({
    show: false,
    type: undefined,
  });
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const params = useParams<{ id: string; tab: string }>();
  const teamId = params.id;
  const currentTab = params.tab;

  const tabs = [
    { name: "Overview", url: "overview" },
    { name: "Members", url: "members" },
  ];

  const [imageError, setImageError] = useState(false);

  const isMemberAlready = teamInfo?.members.includes(user?.id || "");
  const isPendingMember =
    teamInfo?.pendings?.findIndex((member) => member.id === user?.id) !== -1;

  if (isPendingMember) {
  }

  const memberStatus = teamInfo?.pendings?.find(
    (member) => member.id === user?.id
  )?.status;

  useEffect(() => {
    if (!currentTab) {
      navigate(`/teams/${teamId}/overview`);
    }
      TeamService.getTeam(teamId!).then((response) => {
        setTeamInfo(response.data);
      });
   }, [teamId, currentTab, navigate]);

  const joinTeamUrl = `/teams/join-team/${teamId}`;

  const uploadImage = (image: any) => {
    setImageToUpload(image);
  };

  const updateImage = (type: string | null | undefined) => {
    if (type === null || type === undefined || !teamInfo || !imageToUpload) {
      return;
    }
    setLoading(true);
    ImageService.uploadImage(imageToUpload).then((response) => {
      let data = {} as any;
      data[type] = ImageService.generatePublicUrl(response.data);
      TeamService.updateTeam(teamInfo.id, data)
        .then((Rteam) => {
          toast.success("Team updated successfully");
          setLoading(false);
          setTeamInfo(Rteam);
          setShowUploadModal({ show: false });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
          setShowUploadModal({ show: false });
        });
    });
  };

  const leaveTeam = () => {
    if (!user?.id) {
      return;
    }
    setLoading(true);
      TeamService.removeMember(teamId!, [user.id])
      .then((Rteam) => {
        setLoading(false);
        setTeamInfo(Rteam);
        toast.success("You left the team successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const joinTeam = () => {
    if (!user?.id) {
      return;
    }
    setLoading(true);
    TeamService.inviteMember(teamId!, user.id)
      .then((Rteam) => {
        toast.success(
          "Team Joined Successfully! You will become part of the team once one of the team's admins accept the invitation."
        );
        setTeamInfo(Rteam);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const acceptTeam = () => {
    if (!user?.id) {
      return;
    }
    setLoading(true);
    TeamService.acceptMember(teamId!, user.id)
      .then((Rteam) => {
        toast.success("Team Joined Successfully!");
        setTeamInfo(Rteam);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Scrollbars renderView={(props:any) => <div {...props} id="scrollableDiv" />}>
        <div className="skp-page-with-image-banner">
          {/* IMAGE BANNER */}
          {user?.id === teamInfo?.owner && (
            <button
              onClick={() => setShowUploadModal({ show: true, type: "banner" })}
              className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default"
            >
              <i className="las la-camera"></i> Upload Banner
            </button>
          )}
          <div className="skp-image-banner">
            {teamInfo && teamInfo?.banner && !imageError && (
              <img
                alt="profile-banner"
                src={teamInfo?.banner}
                onError={() => setImageError(true)}
              />
            )}
            <div className="transparent-black"></div>
          </div>
          {/* END IMAGE BANNER */}
          {/* PAGE TOP */}
          <div className="container page-top">
            {/* Page Top */}
            <div className="columns page-top">
              <div className="column col-3 profile-picture text-center col-xs-12 text-center-xs">
                {teamInfo && (
                  <TeamBrandLogo team={teamInfo}>
                    <>
                      {user?.id === teamInfo?.owner && (
                        <UploadImageButton
                          onClick={() =>
                            setShowUploadModal({ show: true, type: "logo" })
                          }
                        />
                      )}
                    </>
                  </TeamBrandLogo>
                )}
              </div>
              <div className="column col-9 col-xs-12 text-center-xs">
                <div className="columns">
                  <div className="column col-12">
                    <span className="skp-text-label skp-text-light">TEAM</span>{" "}
                    <br></br>
                    <h1 className="skp-text-primary skp-heading-no-margin">
                      {teamInfo?.name}
                    </h1>
                    {KeyCloackService.isLoggedIn() &&
                      user?.id === teamInfo?.owner && (
                        <Link
                          to={`/teams/${teamInfo?.id}/settings/about`}
                          className="skp-link skp-text-small"
                        >
                          Settings <i className="las la-cog"></i>
                        </Link>
                      )}
                  </div>
                  <div className="column col-12">
                    <div className="space-divider-20"></div>
                    <div className="columns skp-text-primary">
                      <div className="column col-12">
                        {teamInfo?.members && (
                          <>
                            {teamInfo?.members.length} Member
                            {teamInfo?.members.length > 1 && <span>s</span>}
                          </>
                        )}
                        {" - "}
                        <Link className="skp-link-hover" to={joinTeamUrl}>
                          Join Team
                        </Link>
                      </div>
                      <div className="space-divider-20"></div>
                      <div className="column col-12 text-right text-center-xs">
                        {isMemberAlready && teamInfo?.owner !== user?.id && (
                          <button
                            disabled={loading}
                            className="skp-btn skp-btn-alert"
                            onClick={() => leaveTeam()}
                          >
                            Leave Team
                          </button>
                        )}
                        {!isMemberAlready &&
                          !isPendingMember &&
                          teamInfo?.owner !== user?.id && (
                            <button
                              disabled={loading}
                              className="skp-btn skp-btn-primary"
                              onClick={() => joinTeam()}
                            >
                              Join Team
                            </button>
                          )}
                        {!isMemberAlready &&
                          isPendingMember &&
                          memberStatus === 1 &&
                          teamInfo?.owner !== user?.id && (
                            <button
                              disabled={loading}
                              className="skp-btn skp-btn-ternary"
                              onClick={() => acceptTeam()}
                            >
                              Accept Invitation
                            </button>
                          )}

                        {teamInfo?.owner === user?.id && (
                          <Link to={`/teams/${teamId}/settings/pendings`} className="skp-chip status-Pending skp-text-large">
                            <span>
                              {teamInfo?.pendings?.length} Pending Invitation{teamInfo?.pendings && teamInfo.pendings.length > 1 && "s"}
                            </span>
                          </Link>
                        )}

                        {isPendingMember && memberStatus === 0 && (
                          <span className="skp-chip status-Pending skp-text-large">
                            Pending under review.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END PAGE TOP */}
          <div className="space-divider-5"></div>
          {/* TABS HEADER */}
          <div className="container tabs-header">
            <div className="columns">
              <div className="column col-12">
                <ul className="tab skp-text-light">
                  {tabs.map((tab) => (
                    <li
                      key={tab.name}
                      className={
                        currentTab === tab.url ? "tab-item active" : "tab-item"
                      }
                    >
                      <Link
                        to={`/teams/${teamId}/${tab.url}`}
                        className={`${currentTab === tab.url && "active"}`}
                      >
                        {tab.name}{" "}
                        {tab.url === tabs[1].url && (
                          <>({teamInfo?.members?.length})</>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* END TABS HEADER */}
          {/* TABS BODY */}
          <div className="container tabs-body">
            <div className="columns">
              {currentTab === tabs[0].url && teamInfo && (
                <OverviewTab team={teamInfo} />
              )}

              {currentTab === tabs[1].url && (
                <MembersTab
                  team={teamInfo}
                  setTeam={(team: ITeam) => setTeamInfo(team)}
                  doLogin={() => setShowLoginModal(true)}
                />
              )}
            </div>
          </div>
          {/* END TABS BODY */}
        </div>
      </Scrollbars>
      <Modal
        show={showUploadModal.show}
        title="Upload Image"
        size="large"
        onClose={() => {
          setShowUploadModal({ show: false });
        }}
      >
        <div className="container">
          {showUploadModal.type === "banner" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div
                className="column col-12"
                style={{ height: 250, border: "2px dashed var(--gray-1)" }}
              >
                <UploadImageBox
                  onUpload={uploadImage}
                  onRemovePreview={() => setImageToUpload(undefined)}
                />
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage(showUploadModal.type)}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Upload"}
                </button>
              </div>
            </div>
          )}

          {showUploadModal.type === "logo" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12 text-center">
                <div className="upload-logo-wrapper">
                  <UploadImageBox
                    onUpload={uploadImage}
                    onRemovePreview={() => setImageToUpload(undefined)}
                  />
                </div>
              </div>

              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage(showUploadModal.type)}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Upload"}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        show={showLoginModal}
        size="small"
        onClose={() => setShowLoginModal(false)}
      >
        <LoginOrRegisterModal
          message={constant.MESSAGES.LOGIN.TEAM_PLAYERS}
          onClose={() => setShowLoginModal(false)}
        />
      </Modal>
    </>
  );
};

export default TeamsSingle;
