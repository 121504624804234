import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TeamTile from "../../UiLibrary/TeamTile";
import TeamService from "../../../services/TeamService";
import LoadingPlaceholders from "../../UiLibrary/LoadingPlaceholders/LoadingPlaceholders";
import ITeam from "../../Interfaces/Team";
import { useTranslation } from "react-i18next";

const PopularTeams = () => {
  const itemsToDisplay = 6;
  const { t } = useTranslation()
  const [teams, setTeamsList] = useState<Array<ITeam>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TeamService.getAllTeams({
      limit: itemsToDisplay,
    }).then((teams) => {
      setLoading(false);
      setTeamsList(teams);
    });
  }, []);

  return (
    <div className="columns">
      <div className="column col-12">
        {/* Heading */}
        <div className="columns">
          <div className="column col-12 text-center">
            <h4 className="skp-heading skp-primary-pink">{t('home.popularTeams')}</h4>
          </div>
          <div className="column col-12 text-right hide-xs" style={{ marginTop: -30}}>
            <Link to="/teams" className="skp-link-home">
              {t('home.viewAll')}
            </Link>
          </div>
        </div>
        {/* Tiles */}
        {loading && (
          <div className="columns columns-slider">
            <LoadingPlaceholders numberOfItems={6}>
              <div className="column col-2 col-xs-5 column-slider">
                <div className={`team-tile team-tile-loading`}>
                  <div className={`team-tile-image`}>
                    <div className={`default-tile`}></div>
                  </div>
                </div>
              </div>
            </LoadingPlaceholders>
          </div>
        )}
        {!loading && (
          <div className="columns columns-slider">
            <div className="space-divider-20"></div>
            {teams &&
              teams.map((team) => (
                <div
                  className="column col-2 col-xs-5 column-slider"
                  key={team.id}
                >
                  <TeamTile team={team}></TeamTile>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopularTeams;
