import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Scrollbars } from "react-custom-scrollbars";
import ITournament from "../../../Interfaces/Tournament";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament
  updateStatus: (status: number) => void
  deleteTournament: () => void
  children?: JSX.Element
}

const ControlPanel = (props:Props) => {
  let navigate = useNavigate();
  const { t } = useTranslation()
  const {tournament} = props;
  const tournamentId = useParams<{id:string}>().id;
  const goToSchedule = () => {
    navigate(`/tournaments/${tournamentId}/settings/schedule`);
  };

  let status = tournament?.status;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;


  return (
    <div className="skp-right-sidebar">
      <Scrollbars>
        <div className="container p-2">
          <div className="columns p-2">
            <div className="column col-12">
              <h4 className="skp-text-primary">{t('sidePanel.controlPanel')}</h4>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.runControlPanel')}
              </div>
            </div>
          </div>

          <div className="columns p-2">
            <div className="column col-12">
              <h5
                className={
                  onGoingTournament || completedTournament
                    ? "column col-12 skp-text-light"
                    : "column col-12 skp-text-primary"
                }
              >
                {t('sidePanel.scheduleTournament')}
              </h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.scheduleTournamentDesc')}
              </div>
              <div className="space-divider-10"></div>
              <button
                disabled={onGoingTournament || completedTournament}
                className="skp-btn skp-btn-round skp-btn-primary"
                onClick={goToSchedule}
              >
                {t('sidePanel.schedule')}
              </button>
            </div>
            
            <div className="column col-12">
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">2. {t('sidePanel.startTournament')}</h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.tournamentOngoing')}
              </div>
              <div className="space-divider-10"></div>
              <button
                disabled={onGoingTournament || completedTournament}
                className="skp-btn skp-btn-round skp-btn-primary"
                onClick={() => props.updateStatus(1)}
              >
                {t('status.start')}
              </button>
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">3. {t('sidePanel.completeTournament')}</h5>
              <div className="skp-text-label skp-text-light">
                {t('sidePanel.tournamentCompleted')}
              </div>
              <div className="space-divider-10"></div>
              <button
                disabled={!onGoingTournament || completedTournament}
                className="skp-btn skp-btn-round skp-btn-primary"
                onClick={() => props.updateStatus(2)}
              >
                {t('status.complete')}
              </button>
            </div>
            

            <div className="space-divider"></div>
            {props.children}

            <div className="column col-12">
              {
                <button
                  className="skp-btn skp-btn-alert-border"
                  onClick={props.deleteTournament}
                >
                  {t('sidePanel.deleteTournament')}
                </button>
              }
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default ControlPanel;
