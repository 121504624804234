import React from 'react'
import constant from '../../../constants';
import useMatchStatus from '../../Hooks/UseMatchStatus'

const MatchStatus = (props) => {
    const { matchId } = props;
    const status = useMatchStatus(matchId);
    return (
      <div className="skp-brackets-seed-status text-left mb-1">
        <span className={`skp-chip status-${status}`}>
          {constant.MATCH_STATUS[status]}
        </span>
      </div>
    );
}

export default MatchStatus
