import { useContext, useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import Marquee from "react-text-marquee";
import { Link } from "react-router-dom";
import AddToFavourite from "./AddToFavourite";
import Moment from "moment";
import Tournament from "../Interfaces/Tournament";
import GAMES from "../../constants-games";
import constant from "../../constants"
import { UserContext } from "../Context/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: Tournament
}

const TournamentTile = ({tournament}:Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  let status:number = tournament?.status;
  let upcomingTournament = status === 0;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;
  const [imageError, setImageError] = useState(false);
  const today = new Date();
  const startRegistration = new Date(tournament?.start_registration)
  const registrationStarted = startRegistration < today
  const registration = new Date(tournament?.end_registration);
  const registrationClosed = registration < today || !upcomingTournament;
  const checkin = new Date(tournament?.end_checkin || "");
  const checkinClosed = checkin < today || !upcomingTournament;

  const isTournamentAdmin = tournament?.admins?.includes(user?.id!);
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

  const games = (GAMES as any);

  const [date, setDate] = useState({
    date: new Date(),
    hours: 0 ,
    minutes: "0",
  });

  useEffect(() => {
    const date = new Date(tournament.start_tournament);
    const hours = date.getHours();
    const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    setDate({ date: date, hours: hours, minutes: minutes });
  }, [tournament.start_tournament]);

  return (
    <div className="tournament-tile">
      <Link to={`/tournaments/${tournament.id}/overview`}>
        <div className="tournament-tile-image">
          {tournament.thumbnail && !imageError && (
            <img
              src={tournament.thumbnail}
              alt={tournament.title}
              onError={() => setImageError(true)}
            />
          )}
          {tournament.banner && !tournament.thumbnail && (
            <img
              src={tournament.banner}
              alt={tournament.title}
              onError={() => setImageError(true)}
            />
          )}
          {!tournament.banner && !tournament.thumbnail && (
            <img
              src={constant.BANNER_URL[tournament.game]}
              alt={tournament.title}
              onError={() => setImageError(true)}
            />
          )}
        </div>
      </Link>
      <div className="tournament-tile-content columns">
        <div className="column col-12">
          <span className="skp-text-label skp-text-uppercase skp-text-light">
            {Moment(date?.date).format("DD/MM/yyyy")} -{" "}
            {<ReactTimeAgo date={date?.date} />} - {date?.hours}:{date?.minutes}
          </span>
        </div>
        <div className="column col-12">
          <Link to={`/tournaments/${tournament.id}`}>
            <span className="skp-text-large skp-text-primary">
              <Marquee text={tournament.name} />
            </span>
          </Link>
        </div>

        <div className="column col-12 skp-text-default skp-text-light">
          {tournament.yuniteId && <span>{tournament.type}</span>}{" "}
          {!tournament.yuniteId && games[tournament?.game] && (
            <>
              <span>{games[tournament?.game].TEAM_SIZES_MAP[tournament.teamSize]}</span>
              {!tournament.yuniteId &&
                tournament?.console.map((platform) => (
                  <span
                    key={`platform-${platform}`}
                    className="tooltip"
                    data-tooltip={platform}
                    style={{ marginLeft: 5 }}
                  >
                    <i
                      className={`la-${platform}`}
                      style={{ fontSize: 22 }}
                    ></i>
                  </span>
                ))}
              
            </>
          )}
        </div>
        <div className="column col-12 skp-text-default skp-text-light">
          {tournament.entryFee && (
            <span className="tooltip" data-tooltip="Entry fee">
              {tournament.entryFee}
            </span>
          )}{" "}
          {tournament.price && (
            <span className="tooltip" data-tooltip="Price">
              {" "}
              - <i className="las la-bolt skp-text-yellow"></i>
              {tournament.price}
            </span>
          )}
        </div>
        <div className="column col-12 space-divider-5"></div>
        <div className="column col-12">
          <span className="skp-chip skp-text-xxsmall text-uppercase">
            {games[tournament.game]?.NAME} 
          </span>
          {tournament.yuniteId && (
            <span className="skp-chip imported yunite skp-text-default text-uppercase">
              {t('tournament.yunite')}
            </span>
          )}
          {!tournament.yuniteId && tournament.open === false && (
            <span className="skp-chip imported yunite skp-text-default text-uppercase">
              {t('tournament.private')}
            </span>
          )}
          {completedTournament && (
            <span className="skp-chip skp-text-xxsmall text-uppercase status-completed">
              {t('status.completed')}
            </span>
          )}

          {onGoingTournament && (
            <span className="skp-chip  skp-text-xxsmall text-uppercase status-ongoing">
              {t('status.ongoing')}
            </span>
          )}

          {!onGoingTournament && !completedTournament && !registrationStarted && (
            <div
              className={`skp-chip skp-text-xxsmall text-uppercase ${
                "status-awaiting"
              }`}
            >
              {t('tournament.comingSoon')}
            </div>
          )}



          {!onGoingTournament && !completedTournament && registrationStarted && !registrationClosed && (

            <div
              className={`skp-chip skp-text-xxsmall text-uppercase ${
                registrationClosed ? "status-closed" : "status-1"
              }`}
            >
              {registrationClosed ? t('tournament.registrationClosed') : t('tournament.registrationOpen')}
            </div>
          )}
          {!onGoingTournament && !completedTournament && registrationClosed && (
            <div
              className={`skp-chip skp-text-xxsmall text-uppercase ${
                checkinClosed ? "status-closed" : "status-1"
              }`}
            >
              {checkinClosed ? t('tournament.checkInClosed') : t('tournament.checkInOpen')}
            </div>
          )}

          {isAdmin && (
            <Link to={`/tournament-admin/${tournament.id}`}>
              <div className="float-right">
                <span style={{cursor: "pointer"}}>
                <i className={"skp-icon-settings skp-text-light"}></i>
                </span>
              </div>
            </Link>)}

          <div className="float-right">
            <AddToFavourite tournament={tournament} />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default TournamentTile;
