import React, { useState } from "react";
import { Link } from "react-router-dom";
import OverviewTab from "../TournamentTabs/OverviewTab";
import PlayerTab from "../YuniteTournamentTabs/PlayersTab";
import LeaderboardTab from "../YuniteTournamentTabs/LeaderboardTab";
import YuniteMatches from "../YuniteTournamentTabs/YuniteMatches";
import TeamsTab from "../YuniteTournamentTabs/TeamsTab";
import Modal from "../../../UiLibrary/Modal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";

type Props  = {
  tournament: ITournament | undefined
  userStatus: ITournamentMemberStatus
  isSettingsPage: boolean
  urlParams: any
  loadTournament: (tournament: ITournament | undefined) => void
}

const tabs = [
  { name: "Overview", url: "overview" },
  { name: "Players", url: "players" },
  { name: "Teams", url: "teams" },
  { name: "Leaderboard", url: "leaderboard" },
  { name: "Matches", url: "matches" },
];

const TournamentWithYunite = (props:Props) => {
  const { tournament, urlParams } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <>
      {/* TABS HEADER */}
      <div className="container tabs-header">
        <div className="columns">
          <div className="column col-12">
            <ul className="tab skp-text-light">
              {tabs.map((tab) => (
                <li
                  key={tab.name}
                  className={
                    urlParams.tab === tab.url ? "tab-item active" : "tab-item"
                  }
                >
                  <Link
                    to={`/tournaments/${tournament?.id}/${tab.url}`}
                    className={urlParams.tab === tab.url ? "active" : ""}
                  >
                    {tab.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* END TABS HEADER */}
      {/* TABS BODY */}
      <div className="container tabs-body">
        <div className="columns">
          {urlParams.tab === tabs[0].url && (
            <OverviewTab tournament={tournament} loadTournament={props.loadTournament} keyValue={"description"} title="Information"></OverviewTab>
          )}
          {urlParams.tab === tabs[1].url && (
            <PlayerTab
              tournament={tournament}
              onLogin={() => setShowLoginModal(true)}
            />
          )}
          {urlParams.tab === tabs[2].url && <TeamsTab tournament={tournament} />}
          {urlParams.tab === tabs[3].url && (
            <LeaderboardTab yuniteId={tournament?.yuniteId} tournament={tournament} />
          )}
          {urlParams.tab === tabs[4].url && (
            <YuniteMatches tournament={tournament} />
          )}
        </div>
      </div>
      {/* END TABS BODY */}

      <Modal
        show={showLoginModal}
        size="small"
        onClose={() => setShowLoginModal(false)}
      >
        <LoginOrRegisterModal
          message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
          onClose={() => setShowLoginModal(false)}
        />
      </Modal>
    </>
  );
};

export default TournamentWithYunite;
