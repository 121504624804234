
import { NavLink } from "react-router-dom"
import HelpSupportModal from "../Modals/HelpSupportModal/HelpSupportModal";
import { UserContext } from "../Context/UserContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const LeftNav = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation()

  let canCreateCompetition = false
  if ( user && user?.roles && user.roles.includes("CompetitionCreator") )
    canCreateCompetition = true

  return (
      <aside className="left-nav">
        {/* Home */}
        <div
          className="icon-placeholder tooltip tooltip-right text-center-xs"
          data-tooltip="Home"
        >
          <NavLink to="/home" className={({isActive}) => isActive ? "active" : ""}>
            <span><i className="skp-icon-home"></i></span>
          </NavLink>
          
        </div>
        {/* Competition */}
        {canCreateCompetition && (
          <div
            className="icon-placeholder tooltip tooltip-right text-center-xs"
            data-tooltip={t("common.competitions")}
          >
            <NavLink to="/competitions" className={({isActive}) => isActive ? "active" : ""}>
              <i className="las la-medal skp-navbar-icon"></i>
            </NavLink>
          </div>
        )}
        {/* Tournaments */}
        <div
          className="icon-placeholder tooltip tooltip-right text-center-xs"
          data-tooltip={t("common.tournaments")}
        >
          <NavLink to="/tournaments" className={({isActive}) => isActive ? "active" : ""}>
            <i className="skp-icon-tournaments"></i>
          </NavLink>
        </div>
        {/* Users */}
        {canCreateCompetition && (
          <div
            className="icon-placeholder tooltip tooltip-right text-center-xs"
            data-tooltip={t("common.users")}
          >
            <NavLink to="/users" className={({isActive}) => isActive ? "active" : ""}>
              <i className="las la-user skp-navbar-icon"></i>
            </NavLink>
          </div>
        )}
        {/* Brands */}
        {canCreateCompetition && (
          <div
            className="icon-placeholder tooltip tooltip-right text-center-xs"
            data-tooltip={t("common.brands")}
          >
            <NavLink to="/brands" className={({isActive}) => isActive ? "active" : ""}>
              <i className="skp-icon-teams"></i>
            </NavLink>
          </div>
        )}
        {/* Teams */}
        <div
          className="icon-placeholder tooltip tooltip-right text-center-xs"
          data-tooltip={t("common.teams")}
        >
          <NavLink to="/teams" className={({isActive}) => isActive ? "active" : ""}>
            <i className="skp-icon-teams"></i>
          </NavLink>
        </div>
        {/* Games */}
        <div
          className="icon-placeholder tooltip tooltip-right text-center-xs"
          data-tooltip={t("common.games")}
        >
          <NavLink to="/games" className={({isActive}) => isActive ? "active" : ""}>
            <i className="skp-icon-games"></i>
          </NavLink>
        </div>
        {/* Favourites */}
        <div
          className="icon-placeholder tooltip tooltip-right text-center-xs"
          data-tooltip={t("common.favourites")}
        >
          <NavLink to="/favourites" className={({isActive}) => isActive ? "active" : ""}>
            <i className="skp-icon-like"></i>
          </NavLink>
        </div>

        <HelpSupportModal>
        <div
          className="icon-placeholder tooltip tooltip-right text-center-xs skp-text-primary"
          data-tooltip={t("common.helpAndSupport")}
        >
          <i className="skp-icon-support"></i>
          </div>
        </HelpSupportModal>
      </aside>
    );
}

export default LeftNav