import { useState } from "react";
import IUserDetails from "../../Interfaces/UserDetails";
import UserProfile from "../UserProfile";
import UserSearchInput from "./UserSearchInput";
import { AnimatedList } from "react-animated-list";
import UserService from "../../../services/UserService";
import { useTranslation } from "react-i18next";

type Props = {
  setUser: (user: IUserDetails) => void;
  hideStatus?: boolean
};

const UserSearchDropdown = (props: Props) => {
  const {hideStatus} = props
  const { t } = useTranslation()
  const [userList, setUserList] = useState<IUserDetails[]>();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(0)

  const onSetUserList = (users: IUserDetails[]) => {
    setUserList(users);
  };

  
  const onSearchUser = (event: any) => {
    setLoading(true);
    UserService.getUserByName(event.target.value, 10).then((users) => {
      setLoading(false);
      setUserList(users);
    });
  };

  const eventSetUser = (user:IUserDetails) => {
    setReset(reset+1)
    setUserList([])
    props.setUser(user)
  }

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <UserSearchInput
            searchUser={onSearchUser}
            setUserList={onSetUserList}
            reset={reset}
          />
        </div>
      </div>

      {!userList && (
        <div className="columns">
          <div className="space-divider-20"></div>
          <div className="column col-12 skp-text-primary skp-text-small flex-centered">
            <span>{t('modals.typeToSearchUser')}</span>
          </div>
        </div>
      )}

      {!hideStatus && userList && userList.length === 0 && (
        <div className="colums">
          <div className="space-divider-20"></div>
          <div className="column col-12 skp-text-primary skp-text-small flex-centered">
            <span>{t('modals.noUsersFound')}</span>
          </div>
        </div>
      )}

      <>
        {loading && (
          <div className="columns">
            <div className="space-divider"></div>
            <div className="column col-12 flex-centered">
              <div className="loading"></div>
            </div>
          </div>
        )}
        {!loading && userList && userList.length > 0 && (
          <div className="columns bordered-map">
            <AnimatedList animation={"zoom"}>
              {userList.map((user, index) => (
                <div
                  key={`list-${user.id}`}
                  className="column col-12"
                  onClick={() => eventSetUser(user)}
                >
                  <UserProfile user={user}></UserProfile>
                </div>
              ))}
            </AnimatedList>
          </div>
        )}
      </>
    </div>
  );
};

export default UserSearchDropdown;
