import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import ApexTournamentService from "../../../../../services/ApexTournamentService";
import { UserContext } from "../../../../Context/UserContext";
import ITournament from "../../../../Interfaces/Tournament";
import ITournamentApexLobby from "../../../../Interfaces/TournamentApexLobby";
import ITournamentTeam from "../../../../Interfaces/TournamentTeam";
import Modal from "../../../../UiLibrary/Modal";
import UserProfileWithDetailsModal from "../../../../UiLibrary/User/UserProfileWithDetailsModal";
import ApexGameTab from "../ApexTabs/ApexGameTab";
import GAMES from "../../../../../constants-games";
import TournamentService from "../../../../../services/TournamentService";
import LobbyGameTab from "./LobbyGameTab";
import ApexLeaderboardTab from "../ApexTabs/ApexLeaderboardTab";
import LobbyLeaderboardTab from "./LobbyLeaderboardTab";
import ApexLobbyTeams from "../ApexTabs/ApexLobbyTeams";
import LobbyTeams from "./LobbyTeams";
import { Form, Formik } from "formik";
import FormikControl from "../../../../Formik/FormikControl";
import * as Yup from "yup";

type Props = {
  tournament: ITournament;
  lobby: any;
  onReloadTournament: (tournament?: ITournament) => void;
};

const SingleLobby = ({ lobby, tournament, ...props }: Props) => {
  const { user } = useContext(UserContext);
  const isTournamentAdmin = tournament?.admins?.includes(user?.id || "");
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;
  const isApex = tournament?.game === GAMES.APEX_LEGENDS.ENUM;

  const isMember = lobby?.teams?.some((team: ITournamentTeam) =>
    team.members.find((member) => member.userId === user?.id)
  );
  const memberTeam = lobby?.teams?.filter((team: ITournamentTeam) =>
    team.members.find((member) => member.userId === user?.id)
  );
  const [loading, setLoading] = useState(false);
  const [tabsController, setTabController] = useState({ tab: "games" });
  const [showDeleteModal, setShowDeleteModal] = useState<{
    show: boolean;
    lobby?: any | undefined;
  }>({
    show: false,
    lobby: undefined,
  });
  const [showEditModal, setShowEditModal] = useState<{
    show: boolean;
    lobby?: any | undefined;
  }>({
    show: false,
    lobby: undefined,
  });

  const initialValues = {
    name: lobby.name
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Lobby name is required"),
  });
  
  const deleteLobby = (lobby: ITournamentApexLobby) => {
    setLoading(true);
    if (isApex) {
      ApexTournamentService.deleteLobby(tournament?.id, lobby.id)
        .then((response) => {
          toast.success("Lobby deleted successfully!");
          setLoading(false);
          setShowDeleteModal({ show: false });
          props.onReloadTournament();
      })
      .catch((error) => {
        setLoading(false);
        toast.success(error?.response?.data?.message);
      });
    } else {
      TournamentService.deleteLobby(tournament?.id, lobby.id)
        .then((response) => {
          toast.success("Lobby deleted successfully!");
          setLoading(false);
          setShowDeleteModal({ show: false });
          props.onReloadTournament();
      })
      .catch((error) => {
        setLoading(false);
        toast.success(error?.response?.data?.message);
      });
    }
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    if (isApex) {
      ApexTournamentService.updateLobby(tournament?.id, lobby.id, {
        name: values.name
      })
        .then((response) => {
          toast.success("Lobby updated successfully!");
          setLoading(false);
          setShowDeleteModal({ show: false });
          props.onReloadTournament();
      })
      .catch((error) => {
        setLoading(false);
        toast.success(error?.response?.data?.message);
      });
    } else {
      const data = {
        name: values.name
      }
      TournamentService.updateLobby(tournament?.id, lobby.id, data)
        .then((response) => {
          toast.success("Lobby updated successfully!");
          setLoading(false);
          setShowDeleteModal({ show: false });
          props.onReloadTournament();
      })
      .catch((error) => {
        setLoading(false);
        toast.success(error?.response?.data?.message);
      });
    }
    /*let lobby = {}
    
    if (isApex) {
      let tokens = [];
      for (let index = 0; index < values.gameNumber; index++) {
        tokens.push({ admin: "", player: "", api: "", gameNumber: index + 1 });
      }
      lobby = {
        name: values.name,
        tokens: tokens,
        matches: [],
        leaderboards: [],
        teams: [],
        winner: null,
      };
    } else {
      let games = [];
      for (let index = 0; index < values.gameNumber; index++) {
        games.push({ 
          room_id: "", 
          room_name: "", 
          player_password: "", 
          gameNumber: index + 1, 
          leaderboards: []
         });
      }
      lobby = {
        name: values.name,
        leaderboards: [],
        teams: [],
        games: games,
        winner: null,
      };
    } */
  };

  return (
    <>
      {/* Single Lobby */}
      <div className="skp-card skp-card-hover p-2">
        <div className="accordion m-2">
          <input
            type="checkbox"
            id={lobby.name + lobby.id}
            name="accordion-lobbies"
            hidden
          />
          <label className="accordion-header" htmlFor={lobby.name + lobby.id}>
            <div className="columns skp-text-primary">
              <div className="column col-11">
                <h6 className="text-uppercase mb-2">{lobby.name}</h6>{lobby.map_name && lobby.map_name !== "" ? 
                    "Map: " + lobby.map_name : " "}
                {isMember && (
                  <>
                    <UserProfileWithDetailsModal
                      tournament={tournament}
                      userId={user?.id}
                    >
                      <span className="skp-chip status-captain skp-text-small">
                        {memberTeam && memberTeam[0]?.partyName}
                      </span>
                    </UserProfileWithDetailsModal>
                  </>
                )}
              </div>
              {/* <div className="column col-3 text-uppercase"></div> */}
              <div className="column col-1 skp-text-primary text-right">
                <i className="las la-angle-right"></i>
              </div>
            </div>
          </label>
          <div className="accordion-body columns m-1">
            {isAdmin && (
              <div className="column col-12 text-right">
                <span
                  className="skp-link-hover skp-text-label text-uppercase mr-2"
                  onClick={() => setShowEditModal({ show: true, lobby: lobby })}
                >
                  Edit Lobby
                </span>
                <br></br>
                <span
                  className="skp-link-hover skp-text-label text-uppercase"
                  onClick={() =>
                    setShowDeleteModal({ show: true, lobby: lobby })
                  }
                >
                  Delete Lobby
                </span>
              </div>
            )}
            <div className="column col-12">
              <ul className="tab skp-text-light">
                <li
                  className={`tab-item ${
                    tabsController.tab === "games" && "active"
                  }`}
                >
                  <a onClick={() => setTabController({ tab: "games" })}>
                    Games
                  </a>
                </li>
                <li
                  className={`tab-item ${
                    tabsController.tab === "leaderboard" && "active"
                  }`}
                >
                  <a onClick={() => setTabController({ tab: "leaderboard" })}>
                    Leaderboard
                  </a>
                </li>
                <li
                  className={`tab-item ${
                    tabsController.tab === "teams" && "active"
                  }`}
                >
                  <a onClick={() => setTabController({ tab: "teams" })}>
                    Teams
                  </a>
                </li>
              </ul>
            </div>
            <div className="space-divider-20"></div>
            {tabsController.tab === "leaderboard" && isApex && (
              <ApexLeaderboardTab tournament={tournament} lobby={lobby} />
            )}
            {tabsController.tab === "leaderboard" && !isApex && (
              <LobbyLeaderboardTab tournament={tournament} lobby={lobby} />
            )}

            {tabsController.tab === "games" && isApex && (
              <ApexGameTab
                lobby={lobby}
                tournament={tournament}
                onReloadTournament={props.onReloadTournament}
              />
            )}
            {tabsController.tab === "games" && !isApex && (
              <LobbyGameTab
                lobby={lobby}
                tournament={tournament}
                onReloadTournament={props.onReloadTournament}
              />
            )}

            {tabsController.tab === "teams" && isApex && (
              <ApexLobbyTeams
                lobby={lobby}
                tournament={tournament}
                onReloadTournament={props.onReloadTournament}
              />
            )}
            {tabsController.tab === "teams" && !isApex && (
              <LobbyTeams
                lobby={lobby}
                tournament={tournament}
                onReloadTournament={props.onReloadTournament}
              />
            )}
          </div>
        </div>
      </div>
      {/* END Single Lobby */}
      <Modal
        title="Edit Lobby"
        show={showEditModal.show}
        onClose={() => setShowEditModal({ show: false })}
        size="small"
        loading={loading}
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 flex-centered skp-text-primary">
              <div className="skp-form-control">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                > 
                  <Form>
                    <FormikControl
                      control="input"
                      label="Lobby name"
                      name="name"
                    ></FormikControl>
                    <div className="space-divider-20"></div>
                    <button type="submit" className="skp-btn skp-btn-primary float-right">
                      Save Lobby
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Delete Lobby"
        show={showDeleteModal.show}
        onClose={() => setShowDeleteModal({ show: false })}
        size="small"
        confirmation="Delete Lobby"
        onConfirm={() => deleteLobby(showDeleteModal.lobby)}
        loading={loading}
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 skp-text-primary text-center">
              Are you sure you want to delete{" "}
              <strong>{showDeleteModal.lobby?.name}</strong> ?
            </div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SingleLobby;
