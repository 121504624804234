import { useState, useContext, useEffect, MouseEvent } from "react";
import { UserContext } from "../../../Context/UserContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UserService from "../../../../services/UserService";
import * as Yup from "yup";
import ErrorLabel from "../../../Forms/ErrorLabel";
import { toast } from "react-toastify";
import constant from "../../../../constants";
import SocialIcon from "../../../UiLibrary/SocialIcon";
import GameAccountsControl from "../../../Modals/GameAccountsModal/GameAccountsControl";
import GameAccount from "../../../Interfaces/GameAccount";
import SocialNetwork from "../../../Interfaces/SocialNetwork";
import FormikControl from "../../../Formik/FormikControl";
import { useTranslation } from "react-i18next";

type FormModel = {
  username?: string;
  nickname?: string;
  email?: string;
  biography?: string;
  discordId?: string;
  epicId?: string;
  language: string;
  gameAccounts?: Array<GameAccount>;
  social_networks?: Array<SocialNetwork>;
}

const validationSchema = Yup.object({
  username: Yup.string().required("Required").nullable(),
  nickname: Yup.string().required("Required").nullable(),
  language: Yup.string().required("Required").nullable(),
  biography: Yup.string().nullable(),
  discordId: Yup.string().nullable(),
  epicId: Yup.string().nullable(),
  gameAccounts: Yup.array().of(
    Yup.object().shape({
      username: Yup.string().required("Required!"),
      game: Yup.string().required("Required!"),
      console: Yup.string().required("Required!"),
    })
  ),
  social_networks: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().url("Must be a valid url").required("Required!"),
      platform: Yup.string().required("Required!"),
    })
  ),
});

const platformOptions = constant.SOCIAL_PLATFORMS;

const matchTypeOptions = [{value:"it", key:"Italiano"}, {value:"en", key:"English"}];


const UserSettingsForm = () => {
  const { user, setUser } = useContext(UserContext);
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [gameAccounts, setGameAccounts] = useState<Array<GameAccount>>(user?.gameAccounts || []);
  const [socialNetworks, setSocialNetworks] = useState<Array<SocialNetwork>>(
    user?.social_networks || []
  );

  const initialValues:FormModel = {
    username: user?.username,
    nickname: user?.nickname,
    email: user?.email,
    biography: user?.biography || "",
    discordId: user?.discordId,
    epicId: user?.epicId,
    gameAccounts: gameAccounts,
    social_networks: socialNetworks,
    language: user?.language ? user.language : "it"
  };

  useEffect(() => {
    setGameAccounts(user?.gameAccounts || []);

    return () => {
      setGameAccounts([]);
      setLoading(false);
    };
  }, [user]);

  const onSubmit = (values:FormModel) => {
    setLoading(true);
    const userId = user?.id;
    UserService.updateUserInfo(values, userId)
      .then((user) => {
        setLoading(false);
        setUser(user);
        toast.success(t('toast.userInfoUpdate'));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
    
    i18n.changeLanguage(values.language)
  };

  const addGameAccount = () => {
    
    setGameAccounts([
      ...gameAccounts || [],
      {
        game: undefined,
        console: undefined,
        username: undefined,
        id: undefined,
      },
    ]);
  };

  const removeGameAccount = (clickEvent:MouseEvent, index:number) => {
    clickEvent.stopPropagation();
    clickEvent.preventDefault();
    let newArray = [...gameAccounts || []];
    newArray.splice(index, 1);
    setGameAccounts(newArray);
  };

  const addSocialAccount = () => {
    setSocialNetworks([
      ...socialNetworks,
      {
        url: "",
        platform: "FACEBOOK",
      },
    ]);
  };

  const removeSocialAccount = (clickEvent:MouseEvent, index:number) => {
    clickEvent.stopPropagation();
    clickEvent.preventDefault();
    let newArray = [...socialNetworks];
    newArray.splice(index, 1);
    setSocialNetworks(newArray);
  };

  return (
    <div className="column col-12">
      <div className="space-divider-20"></div>
      {user && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, isValid, values }) => (
            <Form>
              <h5 className="skp-text-primary">{t('user.config.myAccount')}</h5>
              {/* Username */}
              <FormikControl control="input" label={t('user.config.username')} name="username" disabled/>

              {/* Nickname */}
              <FormikControl control="input" label={t('user.config.nickname')} name="nickname"/>
              
              {/* Email */}
              <FormikControl control="input" type="email" label={t('user.config.email')} name="email" />

              <FormikControl
                control="select"
                label={t('user.userInfoModal.language')}
                name="language"
                options={matchTypeOptions}
              />

              <div className="space-divider-20"></div>
              
              <h5 className="skp-text-primary">{t('user.config.profile')}</h5>

              {/* Biography */}
              <div
                className={
                  errors.biography && touched.biography
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="biography"
                >
                  {t('user.config.biography')}
                </label>
                <Field as="textarea" id="biography" name="biography" />
                <ErrorMessage name="biography" component={ErrorLabel} />
              </div>

              <div className="space-divider-20"></div>
              {/* <h6 className="skp-text-light text-bold">Social</h6> */}
              <h5 className="skp-text-primary">{t('user.config.yuniteVerification')}</h5>
              {/* Discord Id */}
              <div
                className={
                  errors.discordId && touched.discordId
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="discordId"
                >
                  {t('user.config.discordId')}
                </label>
                <Field type="text" id="discordId" name="discordId" />
                <ErrorMessage name="discordId" component={ErrorLabel} />
              </div>
              <div className="space-divider-20"></div>
              {/* Epic Id */}
              <div
                className={
                  errors.epicId && touched.epicId
                    ? "skp-form-control skp-form-control-error"
                    : "skp-form-control"
                }
              >
                <label
                  className="skp-text-light skp-text-input-label"
                  htmlFor="epicId"
                >
                  {t('user.config.epicId')}
                </label>
                <Field type="text" id="epicId" name="epicId" />
                <ErrorMessage name="epicId" component={ErrorLabel} />
              </div>

              {/* Game Accouns */}
              <div className="space-divider-20"></div>
              <GameAccountsControl
                values={values}
                gameAccounts={gameAccounts}
                addGameAccount={addGameAccount}
                removeGameAccount={removeGameAccount}
              />
              {/* END GAME ACCOUNTS */}

              {/* SOCIAL NETWORKS */}
              <div className="space-divider-20"></div>
              <h5 className="skp-text-primary">{t('user.config.socialNetworks')}</h5>
              <div className="column col-12">
                <div className="space-divider-20"></div>
                <h6 className="skp-link-primary" onClick={addSocialAccount}>
                  {" "}
                  <i className="las la-plus-circle skp-text-xxlarge"></i> {t('user.config.addSocialNetwork')}{" "}
                </h6>
              </div>

              {socialNetworks.map((account:SocialNetwork, index) => (
                <div
                  className="columns"
                  key={account.platform+index}
                  style={{ height: 102 }}
                >
                  <div className="column col-auto flex-centered skp-text-primary">
                    <h3>
                      {values.social_networks && values?.social_networks[index]?.platform && (
                        <SocialIcon
                          platform={values.social_networks[index].platform}
                        />
                      )}
                    </h3>
                  </div>
                  <div className="column col-2">
                    <div className="skp-form-control">
                      <label className="skp-text-light skp-text-small">
                        {t('user.config.platform')}
                      </label>
                      <Field
                        as="select"
                        name={`social_networks[${index}].platform`}
                      >
                        {platformOptions.map((option, id) => {
                          return (
                            <option
                              key={`${option.value}-${id}`}
                              value={option.value}
                            >
                              {option.key}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name={`gameAccounts[${index}].console`}
                        component={ErrorLabel}
                      />
                    </div>
                  </div>

                  <div className="column col-4">
                    <div className="skp-form-control">
                      <label className="skp-text-light skp-text-small">
                        {t('user.config.url')}
                      </label>
                      <Field
                        type="text"
                        name={`social_networks[${index}].url`}
                        placeholder="https://facebook.com"
                      />
                      <ErrorMessage
                        name={`social_networks[${index}].url`}
                        component={ErrorLabel}
                      />
                    </div>
                  </div>

                  <div className="column col-1 flex-centered">
                    <button
                      className="skp-btn skp-btn-transparent skp-text-xlarge"
                      onClick={(event) => removeSocialAccount(event, index)}
                    >
                      <i className="las la-trash"></i>
                    </button>
                  </div>
                </div>
              ))}
              {/* END SOCIAL NETWORKS */}

              <div className="space-divider"></div>
              <button
                type="submit"
                disabled={!isValid || loading}
                className="skp-btn skp-btn-primary float-right"
              >
                {loading ? <span className="loading"></span> : t('common.save')}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UserSettingsForm;
