import { useRef, useState } from "react";
import { toast } from "react-toastify";
import TournamentService from "../../../services/TournamentService";
import ITournament from "../../Interfaces/Tournament";
import Modal from "../../UiLibrary/Modal";
import TournamentTeamIssues from "../TournamentTeamIssues/TournamentTeamIssues";

type Props = {
  tournament: ITournament;
  userStatus: any
  onReloadTournament: (tournament?: ITournament) => void;
};

interface SeedingData {
  type: number
  bronze: boolean
  resetGrandFinal: boolean
  seedType: string
  groupInfo?: {groupNumbers?: number, pointsPerWin?:number, pointsPerDraw?:number, pointsPerLose?: number}
}
const SeedTournamentModal = (props: Props) => {
  const { tournament, userStatus } = props;
  const [showSeedModal, setShowSeedModal] = useState({show1:false, show2:false});
  const [loading, setLoading] = useState(false);
  const [bronze, setBronze] = useState(false);
  const [resetgf, setResetgf] = useState(false);
  const [seedType, setSeedType] = useState("SINGLE_ELIMINATION");
  const nOfGroupsInput = useRef<HTMLInputElement>(null);

  let status = tournament?.status;
  let onGoingTournament = status === 1;
  let completedTournament = status === 2;

  const hasMatches = tournament?.matches?.length > 0;

  const today = new Date();
  const registration = new Date(tournament?.end_registration);
  const registrationClosed = registration < today;
  const checkin = new Date(tournament?.end_checkin);
  const checkinClosed = checkin < today

  const setTournament = (type:string, bronze:boolean, reset:boolean) => {
    setSeedType(type)
    setBronze(bronze);
    setResetgf(reset);
  }

  const seedTournament = () => {
    setLoading(true);
    const data:SeedingData = {
      type: tournament?.matchType,
      bronze: bronze,
      resetGrandFinal: resetgf,
      seedType: seedType 
    };
    if(seedType === "GROUPS") {
      data.groupInfo = {
        groupNumbers: Number(nOfGroupsInput.current?.value) || 4,
        pointsPerWin: 3,
        pointsPerDraw: 1,
        pointsPerLose: 0,
      }
    }
    
    TournamentService.generateTournamentBrackets(tournament.id, data)
      .then((response) => {
        let tournamentObject = tournament;
        tournamentObject.matches = response.data.data;
        props.onReloadTournament();
        toast.success("Tournament seeded successfully");
        setLoading(false);
        setShowSeedModal({show1:false, show2:false});
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <>
      <button
        className="skp-btn skp-btn-round skp-btn-primary"
        onClick={() => setShowSeedModal({show1:true, show2:false})}
        disabled={
          !registrationClosed ||
          !checkinClosed ||
          onGoingTournament ||
          completedTournament ||
          hasMatches
        }
      >
        Seed
      </button>
      <Modal
        show={showSeedModal.show1}
        onClose={() => setShowSeedModal({show1:false, show2:false})}
        onConfirm={() => setShowSeedModal({show1:false, show2:true})}
        size="medium"
        title="Seed Tournament"
        confirmation="next"
        loading={loading}
      >
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns skp-text-primary text-center">
            <div className="column col-12">
              Do you want to seed the tournament{" "}
              <span className="skp-text-accent">{tournament?.name}</span> ?
            </div>
          </div>
          <div className="space-divider-20"></div>

          <div className="columns skp-text-primary m-2">
            <div
              onClick={()=>setTournament("SINGLE_ELIMINATION", false, false)}
              className={`column col-12 skp-card card skp-card-hover flex-centered text-center ${
                seedType === "SINGLE_ELIMINATION" ? "selected" : ""
              }`}
            >
              <div className="card-header">
                <h5>Single Elimination</h5>
              </div>
            </div>
            <div
              onClick={()=>setTournament("DOUBLE_ELIMINATION", false, false)}
              className={`column col-12 skp-card card skp-card-hover flex-centered text-center ${
                seedType === "DOUBLE_ELIMINATION" ? "selected" : ""
              }`}
            >
              <div className="card-header">
                <h5>Double Elimination</h5>
              </div>
              <div className="card-body">
                  <p className="skp-text-default">Winners and Loosers brackets will be generated.</p>
              </div>
            </div>
            <div
              onClick={()=>setTournament("DOUBLE_BRACKETS", false, false)}
              className={`column col-12 skp-card card skp-card-hover flex-centered text-center ${
                seedType === "DOUBLE_BRACKETS" ? "selected" : ""
              }`}
            >
              <div className="card-header">
                <h5>Double Bracket</h5>
              </div>
              <div className="card-body">
                  <p className="skp-text-default">Players are split into 2 parallel brackets.</p>
              </div>
            </div>
            <div
              onClick={()=>setTournament("GROUPS", false, false)}
              className={`column col-12 skp-card card skp-card-hover flex-centered text-center ${
                seedType === "GROUPS" ? "selected" : ""
              }`}
            >
              <div className="card-header">
                <h5>Rounds</h5>
              </div>
              <div className="card-body">
                  <p className="skp-text-default">Rounds and matches.</p>
              </div>
            </div>
          </div>
          <div className="columns">
              <div className="column col-12">
              {tournament && <TournamentTeamIssues userStatus={userStatus} tournament={tournament} />}
              </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showSeedModal.show2}
        onClose={() => setShowSeedModal({show1:true, show2:false})}
        onConfirm={seedTournament}
        size="medium"
        title="Seed Tournament"
        confirmation="Seed"
        loading={loading}
      >
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns skp-text-primary text-center">
            {seedType === "SINGLE_ELIMINATION" && (
              <>
                <div className="column col-12">
                  <h5>Single Elimination</h5>
                </div>
                <div className="skp-form-control form-group pl-2 ml-2">
                  <label
                    htmlFor="bronze"
                    className="skp-text-primary skp-text-small"
                  >
                    Do you want to generate the match for the third and fourth places?
                  </label>
                  <label
                    htmlFor="bronze"
                    className="form-checkbox skp-text-default skp-text-light"
                  >
                    <input
                      type="checkbox"
                      id="bronze"
                      name="bronze"
                      checked={bronze}
                      onChange={() => setBronze(!bronze)}
                    />
                    <i className="form-icon"></i> Generate Match
                  </label>
                </div>
              </>
            )}
            {seedType === "DOUBLE_ELIMINATION" && (
              <>
                <div className="column col-12">
                  <h5>Double Elimination</h5>
                </div>
                <div className="skp-form-control form-group pl-2 ml-2">
                  <label
                    htmlFor="resetgf"
                    className="skp-text-primary skp-text-small"
                  >
                    Do you want to create the Grand Final Reset match?
                  </label>
                  <label
                    htmlFor="resetgf"
                    className="form-checkbox skp-text-default skp-text-light"
                  >
                    <input
                      type="checkbox"
                      id="resetgf"
                      name="resetgf"
                      checked={resetgf}
                      onChange={() => setResetgf(!resetgf)}
                    />
                    <i className="form-icon"></i> Generate Match
                  </label>
                </div>
              </>
            )}
            {seedType === "DOUBLE_BRACKETS" && (
              <>
                <div className="column col-12">
                  <h5>Double Bracket</h5>
                </div>
              </>
            )}
            {seedType === "GROUPS" && (
              <>
                <div className="column col-12">
                  <h5>Rounds</h5>
                </div>
                <div className="skp-form-control form-group pl-2 ml-2">
                <p className="skp-text-default">Rounds and matches.</p>
                  <div className="skp-form-control">
                    <label>Number of groups</label>
                    <input type="number" defaultValue={4} ref={nOfGroupsInput}  />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="space-divider-20"></div>
        </div>
      </Modal>
    </>
  );
};

export default SeedTournamentModal;
