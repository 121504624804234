import React, { useEffect, useState } from "react";
import MatchService from "../../../services/MatchService";
import Match from "../../Interfaces/Match";
import MatchTournamentSeed from "../../Interfaces/MatchTournamentSeed";
import ITournament from "../../Interfaces/Tournament";
import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import EMatchStatus from "../../../enums/MatchStatus";
import MatchResultService from "../../../services/MatchResultService";
import IMatch from "../../Interfaces/Match";

type Props = {
  match: MatchTournamentSeed
  matchId: string
  showStatus?: boolean;
  tournament: ITournament
} 

const MultiMatch = ({match, matchId, showStatus, tournament}:Props) => {
  const [singleMatch, setMatch] = useState<Match>();
  let mainPlayerHomeId: string | undefined;
  let mainPlayerAwayId: string | undefined;

  const [multipleMatchStatus, setMultipleMatchStatus] = useState(MatchResultService.getMultipleMatchStatus(tournament?.matches, match!))
  const [multipleMatchResult, setMultipleMatchResult] = useState(MatchResultService.getMultipleMatchWinner(tournament?.matches, match!));

  useEffect(() => {
    if (matchId) {
      MatchService.getSingleMatch(matchId).then((match) => {
        setMatch(match);
      });
    }

    MatchService.getMatches(match?.matchesId).then((Rmatches) => {
      setMultipleMatchStatus(MatchResultService.getMultipleMatchStatusNoSeed(Rmatches))
      setMultipleMatchResult(MatchResultService.getMultipleMatchWinnerNoSeed(Rmatches));
    });
    return () => {
    };
  }, [matchId]);


  if(singleMatch && singleMatch.result.scores) {
    mainPlayerHomeId = singleMatch?.result?.scores[0].playerResults[0].playerId;
    mainPlayerAwayId = singleMatch?.result?.scores[1].playerResults[0].playerId;
  }
  
  const textStatus = () => {
    switch (multipleMatchStatus) {
      case 0:
        return "skp-text-light";
      case 1:
        return "skp-text-accent";
      case 2:
        return "skp-text-secondary";
      case 3:
        return "skp-text-ternary";

      default:
        return "skp-text-light";
    }
  };

  return (
    <div className="columns">
      <div
        className={`column col-12 text-center skp-text-xxsmall ${textStatus()}`}
      >
        {showStatus && <div>{EMatchStatus[multipleMatchStatus]}</div>}
      </div>
      <div className="column col-5 text-right text-left-xs">
        <div className="columns">
          <div className="column col-11">
            <UserProfileWithDetailsModal userId={mainPlayerHomeId} tournament={tournament} reverse >
              {multipleMatchStatus === 3 && multipleMatchResult.teamHomeScores.length > multipleMatchResult.teamAwayScores.length && (
                <img
                  alt="logo-winner"
                  style={{ width: 30 }}
                  src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                />
              )}
            </UserProfileWithDetailsModal>
          </div>
          <div className="column col-1 flex-centered skp-text-primary skp-text-default">
            {multipleMatchResult.teamHomeScores.length
              ? multipleMatchResult.teamHomeScores.length
              : 0}
          </div>
          
        </div>
      </div>
      <div className="column col-2 flex-centered skp-text-light">vs</div>

      <div className="column col-5">
        <div className="columns">
          <div className="column col-1 flex-centered skp-text-primary skp-text-default">
            {multipleMatchResult.teamAwayScores.length
              ? multipleMatchResult.teamAwayScores.length
              : 0}
          </div>
          <div className="column col-11 text-left">
          <UserProfileWithDetailsModal userId={mainPlayerAwayId} tournament={tournament}>
            {multipleMatchStatus === 3 && multipleMatchResult.teamHomeScores.length < multipleMatchResult.teamAwayScores.length &&  (
              <img
                alt="logo-winner"
                style={{ width: 30 }}
                src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
              />
            )}  
          </UserProfileWithDetailsModal>

          </div>
          
        </div>
      </div>
    </div>
  );
}

export default MultiMatch;
