import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../Formik/FormikControl";
import { UserContext } from "../../Context/UserContext";
import TeamService from "../../../services/TeamService";
import { toast } from "react-toastify";
import UploadImageBox from "../../UiLibrary/UploadImageBox/UploadImageBox";
import ImageService from "../../../services/ImageService";
import ITeam from "../../Interfaces/Team";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import { useTranslation } from "react-i18next";

type Props = {
  onCreate: (teamId?: string) => void;
};

interface IFormModel {
  name: string;
  description?: string;
  website: string;
  contact_mail: string;
  owner?: string
  verified: boolean
  members: Array<string>
}

const initialValues: IFormModel = {
  name: "",
  description: "",
  website: "",
  contact_mail: "",
  verified: false,
  members: []
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  description: Yup.string(),
  website: Yup.string().url(),
  contact_mail: Yup.string()
    .email("Must be a valid email")
    .required("Required"),
});

const NewTeamModal = (props: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
  const [team, setTeam] = useState<ITeam>();

  const onSubmit = (values: IFormModel) => {
    setLoading(true);
    if(user?.id) {
      values.owner = user?.id;
      values.members = [user?.id];
    }
    
    TeamService.createTeam(values)
      .then((response) => {
        toast.success(t('toast.teamCreatedSuccess'));
        setLoading(false);

        setStep(2);
        setTeam(response.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const uploadImage = (image:any) => {
    setImageToUpload(image);
  };

  const updateLogo = () => {
    if(!imageToUpload) {
      return;
    }
    
    setLoading(true);

    ImageService.uploadImage(imageToUpload).then((response) => {
      if(!team) {
        return
      }
      const data = {
        logo: ImageService.generatePublicUrl(response.data),
      };

      TeamService.updateTeam(team?.id, data).then((updateResponse) => {
        props.onCreate(team?.id);
        toast.success(t('toast.teamUpdatedSuccess'));
        setLoading(false);
      });
    });
  };

  return (
    <div className="column col-12">
      {step === 1 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, isValid }) => (
            <Form className="columns">
              <div className="column col-6"></div>

              <div className="column col-12">
                <h6 className="skp-text-primary">{t('teams.create.teamInfo')}</h6>
              </div>
              <div className="column col-6">
                <FormikControl
                  control="input"
                  label={t('teams.create.teamName')}
                  name="name"
                  errors={errors}
                  touched={touched}
                />
              </div>

              <div className="column col-6">
                <FormikControl
                  control="input"
                  label={t('teams.create.email')}
                  name="contact_mail"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="column col-12">
                <FormikControl
                  control="textarea"
                  label={t('teams.create.teamDesc')}
                  name="description"
                />
              </div>
              <div className="column col-12">
                <FormikControl
                  control="input"
                  label={t('teams.create.website')}
                  name="website"
                />
              </div>

              <div className="space-divider"></div>

              <div className="column col-12 text-right">
                <button
                  type="submit"
                  disabled={!isValid || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : t('common.create')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {step === 2 && (
        <div className="columns">
          <div className="space-divider-20"></div>
          <div className="column col-12 text-center">
            <h5 className="skp-text-primary">{t('toast.teamCreatedSuccess')}</h5>
            <div className="space-divider-20"></div>
            <h6 className="skp-text-light">{t('teams.create.uploadLogo')} {team?.name}</h6>
          </div>
          <div className="space-divider-20"></div>
          <div className="column col-12 text-center">
            <div className="upload-logo-wrapper">
              <UploadImageBox onUpload={uploadImage} onRemovePreview={() => setImageToUpload(undefined)} />
            </div>
          </div>
          <div className="space-divider-20"></div>
          <div className="column col-12 text-center">
            <button
              onClick={updateLogo}
              disabled={!imageToUpload?.file || loading}
              className="skp-btn skp-btn-primary"
            >
              {loading ? <span className="loading"></span> : t("common.upload")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewTeamModal;
