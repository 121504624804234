import "./tournaments-tab.scss";
import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { Bracket } from "react-brackets";
import CustomSeed from "../Components/BracketComponents/CustomSeed";
import EventBus from "react-native-event-bus";
import IMatchTournamentSeed from "../../../../Interfaces/MatchTournamentSeed";
import ITournament from "../../../../Interfaces/Tournament";
import GenerateBracketRounds from "../../../../Hooks/GenerateBracketRounds";
import { TournamentMatchesContext } from "../../../../Context/TournamentMatchesContext";
import MatchDetailsModal from "../Components/BracketComponents/MatchDetailsModal/MatchDetailsModal";
// import MultiMatchDetailsModal from "../../../../Modals/MultiMatchDetailsModal/MultiMatchDetailsModal";
import Modal from "../Components/Modal";
import IMatch from "../../../../Interfaces/Match";
import MatchService from "../../../../../services/MatchService";
import MultiMatchDetailsModal from "../Components/BracketComponents/MultiMatchDetailsModal/MultiMatchDetailsModal";
import { UserContext } from "../../../../Context/UserContext";

type ITournamentMatches = {
  matches: Array<IMatch>;
  tournament: ITournament;
};

type Props = {
  tournament: ITournament;
  // onSetMatch: (data: any) => void;
};

const BracketsTab = (props: Props) => {
  const { tournament } = props;
  const [matches, setMatches] = useState(tournament?.matches);
  const { user, setUser } = useContext(UserContext);
  const [tournamentMatches, setTournamentMatches] =
    useState<ITournamentMatches>();
  const [showMatchModal, setShowMatchModal] = useState<{
    show: boolean;
    match: IMatchTournamentSeed | undefined;
  }>({
    show: false,
    match: undefined,
  });
  const rounds = GenerateBracketRounds.generate(matches);

  const [activeRounds, setActiveRounds] = useState("winners");

  const viewDetails = useCallback(
    (data:any) => {
      // props.onSetMatch(data);
      setShowMatchModal({ show: true, match: data })
    },
    []
  );

  const providerTournamentMatches = useMemo(
    () => ({ tournamentMatches, setTournamentMatches }),
    [tournamentMatches, setTournamentMatches]
  );

  useEffect(() => {
    const viewDetailsListener = EventBus.getInstance().addListener(
      "SHOW_MATCH_DETAILS",
      (data: any) => {
        viewDetails(data);
      }
    );

    if (!tournament) {
      return;
    }
    if (tournament?.matches.length > 0 && tournament.groups?.length === 0) {
      // setLoadingMatches(true);
      MatchService.getTournamentMatches(
        tournament?.id,
        (tournament?.maxPartecipants + 1) * 2 * tournament?.matchType,
        true
      ).then((matches) => {
        setTournamentMatches({ matches: matches, tournament: tournament });
        // setLoadingMatches(false);
      });
    }

    return () => {
      setTournamentMatches({ matches: [], tournament: tournament });
      EventBus.getInstance().removeListener(viewDetailsListener);
    };
  }, [viewDetails, tournament]);

  const changeRounds = (value: string) => {
    setActiveRounds(value)
  };


  const [tabIndex, setTabIndex] = useState(0);

  const handleSwipeChange = (index: number) => {
    setTabIndex(index);
  };

  const closeMatchModal = () => {
    setShowMatchModal({ show: false, match: undefined });
  };

  const showMyMatch = () => {
    if ( user && user?.id && tournamentMatches ) {
      const filtered = tournamentMatches?.matches.filter(function (
        match: IMatch
      ) {
        return (match.result.scores[0].teamId === user?.id || match.result.scores[1].teamId === user?.id )
      });

      if (filtered && filtered?.length > 0) {
        let matchId = filtered[filtered?.length-1].id
        
        for (let i = 0; i < filtered.length; i++ ) {
          if (filtered[i].status !== 3 ) {
            matchId = filtered[i].id
            break
          }
        }
        const myMatches = tournament?.matches.filter((match: IMatchTournamentSeed) => match.matchesId.includes(matchId))
        if( myMatches && myMatches.length > 0 ) {
          setShowMatchModal({ show: true, match: myMatches[0] });
        }
      }
    }
  }

  return (
    <>
    <TournamentMatchesContext.Provider value={providerTournamentMatches}>
      {(tournament?.matches?.length === 0 ) && (
        <div className="container">
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-stream"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>No matches</h5>
            </div>
            <div className="column col-12">
              The tournament hasn't been seeded yet!
            </div>
          </div>
        </div>
      )}

      {tournament?.matches.length > 0  && (
        <div className="brackets-tab">
          <div className="columns">
            <div className="column col-12 text-center">
              <div className="space-divider-20"></div>
              <button
                onClick={() => showMyMatch()}
                className="skp-btn skp-btn-secondary m-2"
              >
                Show My Active Match
              </button>
              <div className="space-divider-20"></div>
            </div>
          </div>
          {rounds.loosers.length > 0 && <div className="columns tabs-header">
            <div className="column col-12">
              <ul className="tab skp-text-light">
                <li
                  className={`tab-item ${
                    activeRounds === "winners" ? "active" : ""
                  }`}
                >
                  <a onClick={() => changeRounds("winners")}>
                    Winners
                  </a>
                </li>
                <li
                  className={`tab-item ${
                    activeRounds === "loosers" ? "active" : ""
                  }`}
                >
                  
                  <a onClick={() => changeRounds("loosers")}>
                    Losers
                  </a>
                  
                </li>
              </ul>
            </div>
          </div>}
          {/* Winners brackets */}
          <div className="columns">
            <div className="space-divider"></div>
            <div className="column col-12">
              {rounds.winners.length > 0 && activeRounds === "winners" && (
                <Bracket
                  rounds={rounds.winners}
                  renderSeedComponent={CustomSeed}
                  swipeableProps={{
                    enableMouseEvents: true,
                    animateHeight: true,
                    index: tabIndex,
                    onChangeIndex: handleSwipeChange,
                  }}
                  roundTitleComponent={(title: string, roundIndex: number) => {
                    return (
                      <div>
                        <h5 className="skp-text-primary text-center">
                          {title}
                        </h5>
                        <div className="skp-text-small skp-text-light text-center">
                          Best of {tournament?.matchType}
                        </div>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="space-divider-40"></div>
          {/* Looser brackets */}
          <div className="columns">
            <div className="column col-12">
              {rounds.loosers && rounds.loosers.length > 0 && activeRounds === "loosers" && (
                
                <Bracket
                  rounds={rounds.loosers}
                  renderSeedComponent={CustomSeed}
                  swipeableProps={{
                    enableMouseEvents: true,
                    animateHeight: true,
                    index: tabIndex,
                    onChangeIndex: handleSwipeChange
                  }}
                  roundTitleComponent={(title:string, roundIndex:number) => {
                    return (
                      <div>
                        <h5 className="skp-text-primary text-center">
                          {title}
                        </h5>
                        <div className="skp-text-small skp-text-light text-center">
                          Best of {tournament?.matchType}
                        </div>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="space-divider-40"></div>
          
        </div>
      )}

        <Modal
          show={showMatchModal.show}
          onClose={() => closeMatchModal()}
          title={`Match ${showMatchModal.match?.id}`}
        >
          <>
            {/* Single Match */}
            {tournament &&
              showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length === 1 && (
                <MatchDetailsModal
                  tournament={tournament}
                  matchId={showMatchModal.match?.matchesId[0]}
                />
              )}
            {/* Multi Match */}
            {showMatchModal.match?.matchesId &&
              showMatchModal.match?.matchesId?.length > 1 && (
                // <span>multi matching</span>
                <MultiMatchDetailsModal
                  match={showMatchModal.match}
                  tournament={tournament}
                />
              )}
          </>
        </Modal>
        </TournamentMatchesContext.Provider>
    </>
  );
};

export default BracketsTab;
