import IGame from "./components/Interfaces/Game";

type KStatus = {
  [key: string]: IGame;
};

const FORTNITE: IGame = {
  id: "001",
  LABEL: "fortnite",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/skp-website-images/fortnite-cover.jpg",
  NAME: "Fortnite",
  ENUM: "FORTNITE",
  CREATE_INSTANCE: "fortnite-create.instance.json",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [
    { key: "1vs1", value: 1 },
    { key: "2vs2", value: 2 },
    { key: "3vs3", value: 3 },
    { key: "4vs4", value: 4 },
    { key: "5vs5", value: 5 },
  ],
  TEAM_SIZES_MAP: {
    1: "1vs1",
    2: "2vs2",
    3: "3vs3",
    4: "4vs4",
    5: "5vs5",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const FIFA22: IGame = {
  id: "002",
  LABEL: "fifa22",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/skp-website-images/FIFA_22_Cover.jpg",
  NAME: "Fifa22",
  CREATE_INSTANCE: "fifa-22-create.instance.json",
  PLATFORM: "SKILLPRO",
  ENUM: "FIFA22",
  TEAM_SIZES_OPTIONS: [
    { key: "1vs1", value: 1 },
    { key: "2vs2", value: 2 },
    { key: "3vs3", value: 3 },
    { key: "4vs4", value: 4 },
    { key: "5vs5", value: 5 },
    { key: "11 Pro", value: 11 },
  ],
  TEAM_SIZES_MAP: {
    1: "1vs1",
    2: "2vs2",
    3: "3vs3",
    4: "4vs4",
    5: "5vs5",
    11: "11 Pro",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const EAFC24: IGame = {
  id: "002",
  LABEL: "fc24",
  IMAGE_URL:
    "https://s25.q4cdn.com/103200307/files/doc_multimedia/FC24_standardkeyart_16x9.jpg",
  NAME: "EA Sports FC 24",
  CREATE_INSTANCE: "eafc24-create.instance.json",
  PLATFORM: "SKILLPRO",
  ENUM: "EAFC24",
  TEAM_SIZES_OPTIONS: [
    { key: "1vs1", value: 1 },
    { key: "2vs2", value: 2 },
    { key: "3vs3", value: 3 },
    { key: "4vs4", value: 4 },
    { key: "5vs5", value: 5 },
    { key: "11 Pro", value: 11 },
  ],
  TEAM_SIZES_MAP: {
    1: "1vs1",
    2: "2vs2",
    3: "3vs3",
    4: "4vs4",
    5: "5vs5",
    11: "11 Pro",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const APEX_LEGENDS: IGame = {
  id: "003",
  LABEL: "apexlegends",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/skp-website-images/Apex_legends_cover.jpg",
  NAME: "Apex Legends",
  CREATE_INSTANCE: "apex-legends-create.instance.json",
  PLATFORM: "RESPAWN",
  ENUM: "APEX_LEGENDS",
  TEAM_SIZES_OPTIONS: [
    { key: "Duos", value: 2 },
    { key: "Trios", value: 3 },
    { key: "Arena", value: 1 },
  ],
  TEAM_SIZES_MAP: {
    1: "Arena",
    2: "Duos",
    3: "Trios",
  },
  CONTROL_TYPE: "lobby",
  REQUIRE_DISCORD: true,
};

const POKEMON_UNITE: IGame = {
  id: "004",
  LABEL: "pokemonunite",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/skp-website-images/pokemon-unite-cover.webp",
  NAME: "Pokemon Unite",
  CREATE_INSTANCE: "pokemon-yunite-create.instance.json",
  PLATFORM: "SKILLPRO",
  ENUM: "POKEMON_UNITE",
  TEAM_SIZES_OPTIONS: [{ key: "5vs5", value: 5 }],
  TEAM_SIZES_MAP: {
    5: "5vs5",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const CLASH_OF_CLANS: IGame = {
  id: "005",
  LABEL: "clashofclans",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/skp-website-images/clash-of-clans-cover.jpg",
  NAME: "Clash of clans",
  CREATE_INSTANCE: "clash-of-clans-create.instance.json",
  ENUM: "CLASH_OF_CLANS",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [{ key: "5vs5", value: 5 }],
  TEAM_SIZES_MAP: {
    5: "5vs5",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const YUGIOH_MASTER_DUEL: IGame = {
  id: "005",
  LABEL: "yugioh",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/skp-website-images/yugioh-cover.jpg",
  NAME: "Yu-gi-oh! Master Duel",
  CREATE_INSTANCE: "yugioh-master-duel.instance.json",
  ENUM: "YUGIOH_MASTER_DUEL",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [{ key: "1vs1", value: 1 }],
  TEAM_SIZES_MAP: {
    1: "1vs1",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const VALORANT: IGame = {
  id: "006",
  LABEL: "valorant",
  IMAGE_URL:
    "https://interfaceingame.com/wp-content/uploads/valorant/valorant-cover-375x500.jpg",
  NAME: "Valorant",
  CREATE_INSTANCE: "valorant-create.instance.json",
  ENUM: "VALORANT",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [{ key: "5vs5", value: 5 }],
  TEAM_SIZES_MAP: {
    5: "5vs5",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const CLASH_ROYALE: IGame = {
  id: "007",
  LABEL: "clashroyale",
  IMAGE_URL:
    "https://i.pinimg.com/1200x/b6/ff/08/b6ff08b3acbdbaf5c1132525e2a08ec0.jpg",
  NAME: "Clash Royale",
  CREATE_INSTANCE: "clash-royale-create.instance.json",
  ENUM: "CLASH_ROYALE",
  PLATFORM: "SUPERCELL",
  TEAM_SIZES_OPTIONS: [{ key: "1vs1", value: 1 }],
  TEAM_SIZES_MAP: {
    1: "1vs1",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Battaglia Normale",
    2: "Battaglia Doppio Elisir",
    3: "Battaglia Triplo Elisir",
    4: "Battaglia Sudden Death",
    5: "Battaglia Strategica",
    6: "Battaglia Strategica Doppio Elisir",
    7: "Battaglia Strategica Triplo Elisir",
    8: "Rapina Strategica",
    9: "Carica Suina",
    10: "Carica Dei Boscaioli",
    11: "Festa Degli Spaccamuro",
    12: "Marcia Dei Fantasmi",
    13: "Acchiappaelisir",
    14: "Caccia Dei Draghi",
    15: "Duello",
  },
  CONTROL_TYPE: "lobby",
  REQUIRE_PASSWORD: true,
};

const CLASH_ROYALE_WITH_BRACKET: IGame = {
  id: "008",
  LABEL: "clashroyalebracket",
  IMAGE_URL:
    "https://i.pinimg.com/originals/4e/ee/a2/4eeea21f0b4dbd10793eeb9b001738b9.jpg",
  NAME: "Clash Royale Bracket",
  CREATE_INSTANCE: "clash-royale-with-bracket-create.instance.json",
  PLATFORM: "SKILLPRO",
  ENUM: "CLASH_ROYALE_WITH_BRACKET",
  TEAM_SIZES_OPTIONS: [
    { key: "1vs1", value: 1 },
    { key: "2vs2", value: 2 },
    { key: "3vs3", value: 3 },
    { key: "4vs4", value: 4 },
    { key: "5vs5", value: 5 },
    { key: "11 Pro", value: 11 },
  ],
  TEAM_SIZES_MAP: {
    1: "1vs1",
    2: "2vs2",
    3: "3vs3",
    4: "4vs4",
    5: "5vs5",
    11: "11 Pro",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const LEAGUE_OF_LEGENDS: IGame = {
  id: "009",
  LABEL: "leagueoflegends",
  IMAGE_URL:
    "https://file.booster.gearupportal.com/file/643f5d389781de56bea80ec62ZrIkLvw03.png",
  NAME: "League of legends",
  CREATE_INSTANCE: "league-of-legends-create.instance.json",
  PLATFORM: "SKILLPRO",
  ENUM: "LEAGUE_OF_LEGENDS",
  TEAM_SIZES_OPTIONS: [
    { key: "1vs1", value: 1 },
    { key: "2vs2", value: 2 },
    { key: "3vs3", value: 3 },
    { key: "4vs4", value: 4 },
    { key: "5vs5", value: 5 },
  ],
  TEAM_SIZES_MAP: {
    1: "1vs1",
    2: "2vs2",
    3: "3vs3",
    4: "4vs4",
    5: "5vs5",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const MARVEL_SNAP: IGame = {
  id: "010",
  LABEL: "marvelsnap",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/marvel-snap-1200x675.jpg",
  NAME: "Marvel Snap",
  CREATE_INSTANCE: "marvel-snap-create.instance.json",
  ENUM: "MARVEL_SNAP",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [{ key: "1vs1", value: 1 }],
  TEAM_SIZES_MAP: {
    1: "1vs1",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket"
};

const PUBG_MOBILE: IGame = {
  id: "011",
  LABEL: "pubgmobile",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/Cw_urQ21N7Lgd_1_g58fB73ic_XuEWcA0pFiazjd7Io_350x200_3x-0.jpg",
  NAME: "PUBG MOBILE",
  LOGO_URL: "https://storage.googleapis.com/skillpro-bucket/PUBG%20Mobile.png",
  CREATE_INSTANCE: "pubg-mobile-create.instance.json",
  ENUM: "PUBG_MOBILE",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [
    { key: "Team Solo", value: 1 },
    { key: "Team Duo", value: 2 },
    { key: "Team Squad", value: 4 },
  ],
  TEAM_SIZES_MAP: {
    1: "Team Solo",
    2: "Team Duo",
    4: "Team Squad",
  },
  CONTROL_TYPE: "lobby",
  MAPS: [
    { key: "Select a map", value: "" },
    { key: "Erangle", value: "Erangle" },
    { key: "Miramar", value: "Miramar" },
    { key: "Sanhok", value: "Sanhok" },
    { key: "Vikendi", value: "Vikendi" },
    { key: "Karakin", value: "Karakin" },
    { key: "Livik", value: "Livik" },
    { key: "Nusa", value: "Nusa" },
  ]
};

const GENERIC_GAME: IGame = {
  id: "012",
  LABEL: "genericgame",
  IMAGE_URL:
    "https://storage.googleapis.com/skillpro-bucket/generic.png",
  NAME: "Generic Games",
  CREATE_INSTANCE: "generic-game-create.instance.json",
  ENUM: "GENERIC_GAME",
  PLATFORM: "SKILLPRO",
  TEAM_SIZES_OPTIONS: [{ key: "1vs1", value: 1 }],
  TEAM_SIZES_MAP: {
    1: "1vs1",
  },
  TEAM_MATCH_TYPE_MAP: {
    1: "Regular",
    3: "Best of 3",
    5: "Best of 5",
    7: "Best of 7",
    9: "Best of 9",
  },
  CONTROL_TYPE: "bracket",
};

const GAMES: KStatus = {
  FORTNITE: FORTNITE,
  FIFA22: FIFA22,
  EAFC24: EAFC24,
  APEX_LEGENDS: APEX_LEGENDS,
  POKEMON_UNITE: POKEMON_UNITE,
  CLASH_OF_CLANS: CLASH_OF_CLANS,
  YUGIOH_MASTER_DUEL: YUGIOH_MASTER_DUEL,
  VALORANT: VALORANT,
  CLASH_ROYALE: CLASH_ROYALE,
  CLASH_ROYALE_WITH_BRACKET: CLASH_ROYALE_WITH_BRACKET,
  LEAGUE_OF_LEGENDS: LEAGUE_OF_LEGENDS,
  MARVEL_SNAP:MARVEL_SNAP,
  PUBG_MOBILE:PUBG_MOBILE,
  GENERIC_GAME:GENERIC_GAME
};

export default GAMES;
