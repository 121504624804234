import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentPointSystem from "../../../Interfaces/TournamentPointSystem";

type DrawInputProps = {
  element: any,
  disabled?: boolean
  onInputchange: (event: any, key:string, index:any) => void
  onDeleteRow: (index:any) => void
}

const DrawInput = (props:DrawInputProps) => {
  const { element, disabled } = props;
  return (
    <>
      <div className="column col-5">
        <div className="skp-form-control">
          <input
            disabled={disabled}
            name="position"
            type="number"
            value={element.key}
            onChange={(e) => props.onInputchange(e, "key", element.index)}
          />
        </div>
      </div>
      <div className="column col-5">
        <div className="skp-form-control">
          <input
            disabled={disabled}
            name="points"
            type="number"
            value={element.value}
            onChange={(e) => props.onInputchange(e, "value", element.index)}
          />
        </div>
      </div>
      <div className="column col-2 flex-centered">
        {!disabled && (
          <button
            className="skp-btn skp-btn-secondary skp-text-xlarge"
            style={{ width: "100%" }}
            onClick={() => props.onDeleteRow(element.index)}
          >
            <i className="las la-trash"></i>
          </button>
        )}
      </div>
    </>
  );
};

type Props = {
  tournament: ITournament
  onSubmit: (pointSystem:{pointSystem:ITournamentPointSystem}) => void
}

const PointSystemTab = ({ tournament, ...props }:Props) => {
  const {user} = useContext(UserContext)
  const [newList, setNewList] = useState<Array<{key:number | undefined, value: number | undefined, index:number}>>([]);
  
  const pointsPerKill = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let array:Array<{key:number | undefined, value: number | undefined, index:number}> = [];
    let i = 0;
    for (let item in tournament?.pointSystem?.pointsPerPlacement) {
      const objToPush = {
        key: Number(item),
        value: tournament?.pointSystem?.pointsPerPlacement[Number(item)],
        index: i,
      };
      array.push(objToPush);
      i++;
    }
    setNewList(array);
    
    if(tournament?.owner === user?.id && pointsPerKill?.current?.value ) {
        pointsPerKill.current.value = tournament?.pointSystem?.pointsPerKill.toString() || "";
    }
  }, [tournament, user]);

  const savePointSystem = () => {
    let pointSystem:{pointSystem: ITournamentPointSystem} = { pointSystem: {pointsPerKill: 0, pointsPerPlacement: {}} };
    const obj:{[key: number]:number} = {};
    newList.forEach((item:{key:number | undefined, value: number | undefined, index:number}) => {
      if (item.value !== undefined && item.key !== undefined) {
        obj[item.key] = Number(item.value);
      }
    });
    pointSystem.pointSystem.pointsPerPlacement = obj;
    pointSystem.pointSystem.pointsPerKill = Number(pointsPerKill?.current?.value);
    props.onSubmit(pointSystem);
  };

  const inputChange = (event:any, key:string, index:number) => {
    let newArray:Array<any> = [...newList];
    newArray[index][key] = event.target.value;
    setNewList(newArray);
  };

  const deleteRow = (index:number) => {
    let newArray = [...newList];
    newArray.splice(index, 1);
    setNewList(newArray);
  };

  const addPlacement = () => {
    setNewList([
      ...newList,
      { key: undefined, value: undefined, index: newList.length },
    ]);
  };


  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <div className="space-divider-20"></div>
          <h5 className="skp-text-primary float-left">Placement Points</h5>

          <button
            onClick={savePointSystem}
            className="skp-btn skp-btn-primary float-right"
          >
            Save
          </button>

          <div className="space-divider-20"></div>
          <div className="skp-card card">
            <div className="columns">
              <div className="column col-12 card-header">
                <h6 className="skp-text-primary card-title">
                  Points per elimination
                </h6>
              </div>
              <div className="column col-5">
                <div className="skp-form-control">
                  <input
                    ref={pointsPerKill}
                    type="number"
                    value={tournament?.pointSystem?.pointsPerKill}
                  />
                </div>
              </div>
            </div>
            <div className="space-divider-20"></div>
          </div>

          <div className="space-divider-20"></div>
          <div className="skp-card card">
            <div className="columns">
              <div className="column col-5 card-header">
                <h6 className="skp-text-primary">Placement</h6>
              </div>
              <div className="column col-5 card-header">
                <h6 className="skp-text-primary">Points</h6>
              </div>
              <div className="column col-2"></div>
              <>
                {newList.map((element) => (
                  <DrawInput
                    key={element.index}
                    element={element}
                    onInputchange={inputChange}
                    onDeleteRow={deleteRow}
                  />
                ))}

                <div className="space-divider-20"></div>

                <button
                  className="skp-btn skp-btn-primary"
                  style={{ width: "100%" }}
                  onClick={addPlacement}
                >
                  <i className="las la-plus"></i> Add placement
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointSystemTab;
