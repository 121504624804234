import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import constant from "../../../../constants";
import KeyCloackService from "../../../../services/KeyCloackService";
import ShopService from "../../../../services/ShopService";
import TournamentService from "../../../../services/TournamentService";
import { UserContext } from "../../../Context/UserContext";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentMemberStatus from "../../../Interfaces/TournamentMemberStatus";
import AddDiscordIdModal from "../../../Modals/AddDiscordIdModal/AddDiscordIdModal";
import JoinTournamentModal from "../../../Modals/JoinTournamentModal/JoinTournamentModal";
import JoinTournamentWithTeam from "../../../Modals/JoinTournamentModal/JoinTournamentWithTeam";
import JoinYuniteTournamentModal from "../../../Modals/JoinTournamentModal/JoinYuniteTournamentModal";
import CheckinTournamentModal from "../../../Modals/CheckinTournamentModal/CheckinTournamentModal";
import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import ReviewInvitationModal from "../../../Modals/ReviewInvitationModal/ReviewInvitationModal";
import Modal from "../../../UiLibrary/Modal";
import GAMES from "../../../../constants-games";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import UserService from "../../../../services/UserService";

type Props = {
  tournament: ITournament;
  memberStatus: ITournamentMemberStatus;
  registrationStarted: boolean;
  registrationClosed: boolean;
  checkinClosed: boolean;
  showCheckin?: boolean;
  onReloadTournament: (tournament?: ITournament) => void;
};


const TournamentJoinButtons = (props: Props) => {
  const { tournament, memberStatus, registrationStarted, registrationClosed, checkinClosed, showCheckin } = props;
  const { user } = useContext(UserContext);
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [showMaxPartecipantsModal, setShowMaxPartecipantsModal] = useState(false)
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showLeaveTeamModal, setShowLeaveTeamModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState("");
  const [paypalCompleted, setPaypalCompleted] = useState(false);

  const [hasEmail, setHasEmail] = useState(false)

  const Msg = () => (
    <div>
      <span>{t('tournament.join.joinDiscordMessage')}</span> <br></br>
      <a
        className="skp-link"
        target="_blank"
        rel="noreferrer"
        href="https://discord.com/invite/agPSsaDyTj"
      >
        {t('tournament.join.joinDiscord')}
      </a>
    </div>
  );

  let modalSize = tournament?.yuniteId ? "large" : "small";
  if (tournament?.type !== "1vs1") {
    modalSize = "";
  }

  const checkinTournament = (
    members: string | Array<string> | undefined,
    data: any
  ) => {
    if (!tournament) {
      return;
    }
    let userIds: string | Array<string> = [];

    if (members && user !== null) {
      if (members.length > 0) {
        userIds = members;
      } else if (user?.id) {
        userIds = user?.id;
      }
    }
    setLoading(true);
    TournamentService.checkinTournament(tournament?.id, userIds, data)
      .then((response) => {
        toast.success(t('tournament.join.checkinSuccess'));

        props.onReloadTournament();
        setLoading(false);
      })
      .catch((error) => {
        
        if (
          error?.response?.data?.message ===
          "Member not verified with Yunite Bot"
        ) {
          error.response.data.message = t('tournament.join.memberToVerifiedYunite')
        }
        toast.error(error?.response.data.message);
        if (error?.response?.data?.message === "Member not found on discord") {
          toast.warning(<Msg />);
        }
      });
  };

  const checkoutTournament = () => {
    if (!tournament || user?.id === undefined) {
      return;
    }
    setLoading(true);
    TournamentService.checkoutTournament(tournament?.id, user.id)
      .then(() => {
        toast.success(t('tournament.join.checkoutSuccess'));
        setShowCheckoutModal(false);
        setLoading(false);
        props.onReloadTournament();
        
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const subscribeTournament = (
    members: string | Array<string> | undefined,
    data: any
  ) => {

    if (!tournament) {
      return;
    }
    let userIds: string | Array<string> = [];

    if (members && user !== null) {
      if (members.length > 0) {
        userIds = members;
      } else if (user?.id) {
        userIds = user?.id;
      }
    }
    setLoading(true);
    TournamentService.subscribeTournament(tournament?.id, userIds, data)
      .then((response) => {
        toast.success(t('tournament.join.tournamentJoinSuccess'));

        props.onReloadTournament();
        setShowModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error "  + error)
        setLoading(false);
        if (error?.response?.data?.message === "Member not verified with Yunite Bot") {
          error.response.data.message = t('tournament.join.memberToVerifiedYunite') 
        } else if ( error?.response?.data?.message === "Max partecipants reached" ) {
          error.response.data.message = t('tournament.join.maxPartecipants')
          setShowMaxPartecipantsModal(true)
        }
        
        toast.error(error?.response.data.message);
        if (error?.response?.data?.message === "Member not found on discord") {
          toast.warning(<Msg />);
        }
      });
  };

  const leaveTournament = () => {
    if (!tournament || user?.id === undefined) {
      return;
    }
    setLoading(true);
    TournamentService.unSubscribeTournament(tournament?.id, user.id)
      .then(() => {
        toast.success(t('tournament.join.tournamentUnsubscribeSuccess'));
        props.onReloadTournament();
        setShowLeaveModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const subscribeTournamentAsTeam = (members: Array<string>, data: any) => {
    subscribeTournament(members, data);
  };

  const subscribeTournamentAsSingle = (data: any) => {
    subscribeTournament(user?.id, data);
  };

  const checkinTournamentAsTeam = (members: Array<string>, data: any) => {
    checkinTournament(members, data);
  };

  const checkinTournamentAsSingle = (data: any) => {
    checkinTournament(user?.id, data);
  };

  const subscribeYuniteTournament = (data: any) => {
    subscribeTournament(user?.id, data);
  };

  const leaveTeam = () => {
    if (!user?.id || !memberStatus.team) {
      return;
    }
    setLoading(true);
    TournamentService.removeTeamMember(
      tournament.id,
      memberStatus.team?.partyId,
      user.id
    )
      .then(() => {
        toast.success(t('tournament.join.tournamentUnsubscribeSuccess'));
        props.onReloadTournament();
        setShowLeaveTeamModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const joinTournament = (show: boolean) => {
    if (user?.id) {
      setLoadingOrders(true);
      ShopService.getOrderByUserId(user?.id)
        .then((response) => {
          setLoadingOrders(false);
          setPaypalCompleted(
            response.data.data.findIndex(
              (order: any) => order.tournamentId === tournament.id
            ) !== -1
          );
        })
        .catch((error) => {
          setLoadingOrders(false);
        });
    }

    setShowModal(true);
  };

  const doCheckinTournament = (show: boolean) => {
    if (user?.id) {
      setLoadingOrders(true);
      ShopService.getOrderByUserId(user?.id)
        .then((response) => {
          setLoadingOrders(false);
          setPaypalCompleted(
            response.data.data.findIndex(
              (order: any) => order.tournamentId === tournament.id
            ) !== -1
          );
        })
        .catch((error) => {
          setLoadingOrders(false);
        });
    }

    setShowCheckinModal(true);
  };

  const createOrder = (data: any, actions: any) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: `SKILLPRO - Iscrizione Torneo "${tournament?.name}" - USERID: ${user?.id} USERNAME: ${user?.username}`,
            amount: {
              currency_code: "EUR",
              value: tournament.price,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID: string) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then(function (details: any) {
      const { payer } = details;
      setSuccess(true);
      toast.success("Order Completed!");

      if (!user?.id) {
        return;
      }
      ShopService.createOrder(tournament?.id, details.id, user?.id)
        .then((response) => {
          setPaypalCompleted(true);
        })
        .catch((error) => {
          setPaypalCompleted(true);
          toast.error(
            "Your oder went through Paypal successfully, but we couldn't register it on Skillpro unfortunately! Please contact the admin to check the payment! If you leave the tournament, you will ask to pay again!"
          );
        });
    });
  };
  //capture likely error
  const onError = (data: any, actions: any) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(()=>{
    if (user) {
      UserService.getUserConsent(user.id).then((response) => {
        if (response.data) {
          if (response.data.mailingList) {
            setHasEmail(true)
          } else {
            setHasEmail(false)
          }
        } else {
          setHasEmail(false)
        }
      })
    }
  }, [])

  const showTournamentPassword = () => {
    setShowPasswordModal(true);
  };

  interface IFormModel {
      mailing_list: Array<string>;
  }
    
  const initialValues: IFormModel = {
      mailing_list: ["ok"],
  };
    

  const onSubmitMailing = (values: IFormModel) => {
    if (user) {
      const consentData = {
          mailingList: values.mailing_list.length > 0,
          privacy: true
      }
      UserService.addUserConsent(consentData, user?.id)
      .then((response) => {
      }).catch((error)=>{
      })
    }
  }

  return (
    <>
      {KeyCloackService.isLoggedIn() && (
        <>
          {/* YUNITE TOURNAMENT */}
          {tournament?.yuniteId && (
            <>
              {!memberStatus.joined &&
                !tournament?.winner &&
                !registrationClosed && (
                  <button
                    className="skp-btn skp-btn-primary skp-text-small"
                    onClick={() => joinTournament(true)}
                    disabled={registrationClosed}
                  >
                    {t('tournament.join.joinTournament')}
                  </button>
                )}

              {memberStatus.joined &&
                !tournament?.winner &&
                !registrationClosed && (
                  <>
                    <button
                      disabled={registrationClosed}
                      className="skp-btn skp-btn-alert skp-text-small"
                      onClick={() => setShowLeaveModal(true)}
                    >
                      {t('tournament.join.leaveTournament')}
                    </button>
                  </>
                )}
            </>
          )}
          {/* YUNITE TOURNAMENT END */}

          {/* CLASH ROYALE */}
          {user?.id &&
            tournament.members?.includes(user?.id) &&
            GAMES[tournament?.game].REQUIRE_PASSWORD && (
              <>
                <button
                  onClick={showTournamentPassword}
                  className="skp-btn skp-btn-ternary skp-text-small"
                >
                  {GAMES[tournament?.game].NAME} Password
                </button>
                <div className="space-divider-5"></div>
              </>
            )}
          {/* CLASH ROYALE */}

          {!tournament?.yuniteId && (
            <>
              {!memberStatus.isPending &&
                !memberStatus.isLeader &&
                !memberStatus.accepted &&
                !tournament?.winner &&
                !registrationClosed && 
                registrationStarted &&(
                  <button
                    className="skp-btn skp-btn-primary skp-text-small"
                    onClick={() => joinTournament(true)}
                    disabled={registrationClosed}
                  >
                    {t('tournament.join.joinTournament')}
                  </button>
                )}

              {memberStatus.isLeader &&
                !tournament?.winner &&
                !registrationClosed && 
                registrationStarted && (
                  <>
                    <button
                      disabled={registrationClosed}
                      className="skp-btn skp-btn-alert skp-text-small"
                      onClick={() => setShowLeaveModal(true)}
                    >
                      {t('tournament.join.leaveTournament')}{" "}
                      {memberStatus.isLeader &&
                        tournament?.playerType === 1 &&
                        t('tournament.join.asLeader')}
                    </button>
                  </>
                )}

              { memberStatus.joined &&
                memberStatus.isLeader &&
                registrationClosed &&
                !memberStatus.checkedIn && 
                !checkinClosed && 
                (showCheckin === undefined || showCheckin ) && (
                  <button
                    className="skp-btn skp-btn-primary skp-text-small"
                    onClick={() => doCheckinTournament(true)}
                    disabled={checkinClosed}
                  >
                    {t('tournament.join.checkinTournament')}
                  </button>
                )}

                {memberStatus.isLeader &&
                !tournament?.winner &&
                registrationClosed && 
                !checkinClosed && 
                memberStatus.checkedIn &&(
                  <>
                    <button
                      disabled={checkinClosed}
                      className="skp-btn skp-btn-alert skp-text-small"
                      onClick={() => setShowCheckoutModal(true)}
                    >
                      {t('tournament.join.checkoutTournament')}{" "}
                      {memberStatus.isLeader &&
                        tournament?.playerType === 1 &&
                        t('tournament.join.asLeader')}
                    </button>
                  </>
                )}
              

              {!memberStatus.isLeader &&
                memberStatus.joined &&
                !memberStatus.isPending &&
                !tournament?.winner &&
                !registrationClosed && (
                  <button
                    disabled={registrationClosed}
                    className="skp-btn skp-btn-alert skp-text-small"
                    onClick={() => setShowLeaveTeamModal(true)}
                  >
                    {t('tournament.join.declineInvitation')}
                  </button>
                )}

              {memberStatus.isPending &&
                !tournament?.winner &&
                !registrationClosed && (
                  <>
                    <ReviewInvitationModal
                      tournament={tournament}
                      memberStatus={memberStatus}
                      onReloadTournament={props.onReloadTournament}
                    />
                  </>
                )}
            </>
          )}

          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
            size={modalSize}
            title={t('tournament.join.joinTournament')}
          >
            <>
              {loadingOrders && (
                <div className="loading" style={{ height: 300 }}></div>
              )}
              {/* PAY WITH PAYPAL */}
              {!loadingOrders &&
                !paypalCompleted &&
                tournament?.price &&
                tournament?.paypalClientId && (
                  <>
                    <div className="container">
                      <div className="space-divider"></div>
                      <div className="columns">
                        <div className="column col-12">
                          <div className="columns">
                            <div className="column col-2"></div>
                            <div className="column col-8">
                              <div className="columns">
                                <div className="column col-12 skp-text-primary">
                                  <h5>
                                    Tournament Fees: {tournament?.price} Euro
                                  </h5>
                                </div>
                                <div className="space-divider-20"></div>
                                <div className="column col-12">
                                  <h6 className="skp-text-light text-uppercase">
                                    Pay with Paypal
                                  </h6>
                                </div>
                                <div className="column col-12">
                                  <PayPalScriptProvider
                                    options={{
                                      currency: "EUR",
                                      "client-id": tournament?.paypalClientId,
                                    }}
                                  >
                                    <PayPalButtons
                                      style={{ layout: "vertical" }}
                                      createOrder={createOrder}
                                      onApprove={onApprove}
                                    />
                                  </PayPalScriptProvider>
                                </div>
                              </div>
                            </div>
                            <div className="column col-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {/* PAY WITH PAYPAL END */}
              {!loadingOrders &&
                (paypalCompleted ||
                  (!tournament?.price && !tournament.paypalClientId)) && (
                  <>
                    {user &&
                      !user?.discordId &&
                      GAMES[tournament.game].REQUIRE_DISCORD && (
                        <>
                          <AddDiscordIdModal />
                        </>
                      )}
                    {user &&
                      (!GAMES[tournament.game].REQUIRE_DISCORD ||
                        user?.discordId) && (
                        <>
                          {!tournament?.yuniteId &&
                            tournament?.playerType === 0 && (
                              <JoinTournamentModal
                                onConfirm={subscribeTournamentAsSingle}
                                loading={loading}
                                tournament={tournament}
                              />
                            )}

                          {!tournament?.yuniteId &&
                            tournament?.playerType === 1 &&
                            user && (
                              <JoinTournamentWithTeam
                                tournament={tournament}
                                loading={loading}
                                user={user}
                                onConfirm={subscribeTournamentAsTeam}
                              />
                            )}
                          {tournament?.yuniteId && (
                            <JoinYuniteTournamentModal
                              onConfirm={subscribeYuniteTournament}
                              loading={loading}
                              tournament={tournament}
                            />
                          )}
                        </>
                      )}
                  </>
                )}
            </>
          </Modal>

          <Modal
            show={showCheckinModal}
            onClose={() => setShowCheckinModal(false)}
            size={modalSize}
            title={t('tournament.join.checkinTournament')}
          >
            <>
              {loadingOrders && (
                <div className="loading" style={{ height: 300 }}></div>
              )}
              {!loadingOrders &&
                (paypalCompleted ||
                  <>
                    {user && (
                        <>
                          { tournament?.playerType === 0 && (
                              <CheckinTournamentModal
                                onConfirm={checkinTournamentAsSingle}
                                loading={loading}
                                tournament={tournament}
                              />
                            )}

                          { tournament?.playerType === 1 &&
                            user && (
                              <JoinTournamentWithTeam
                                tournament={tournament}
                                loading={loading}
                                user={user}
                                onConfirm={checkinTournamentAsTeam}
                              />
                            )}
                        </>
                      )}
                  </>
                )}
            </>
          </Modal>

          <Modal
            show={showCheckoutModal}
            onClose={() => setShowCheckoutModal(false)}
            onConfirm={checkoutTournament}
            loading={loading}
            confirmation={t('tournament.join.checkoutTournament')}
            size="small"
            title={t('tournament.join.checkoutTournament')}
          >
            <div className="container">
              <div className="columns">
                <div className="space-divider"></div>
                <div className="column col-12 text-center skp-text-primary">
                  <span>
                    {t('tournament.join.checkoutConfirmation')}{" "}
                    <strong>{tournament?.name}</strong> ?
                  </span>
                </div>
                <div className="space-divider"></div>
              </div>
            </div>
          </Modal>

          <Modal
            show={showLeaveModal}
            onClose={() => setShowLeaveModal(false)}
            onConfirm={leaveTournament}
            loading={loading}
            confirmation={t('tournament.join.leaveTournament')}
            size="small"
            title={t('tournament.join.leaveTournament')}
          >
            <div className="container">
              <div className="columns">
                <div className="space-divider"></div>
                <div className="column col-12 text-center skp-text-primary">
                  <span>
                    {t('tournament.join.leaveConfirmation')}{" "}
                    <strong>{tournament?.name}</strong> ?
                  </span>
                </div>
                <div className="space-divider"></div>
              </div>
            </div>
          </Modal>

          <Modal
            show={showLeaveTeamModal}
            onClose={() => setShowLeaveTeamModal(false)}
            onConfirm={leaveTeam}
            loading={loading}
            confirmation={t('tournament.join.leaveTeam')}
            size="small"
            title={t('tournament.join.declineInvitation')}
          >
            <div className="container">
              <div className="columns">
                <div className="space-divider"></div>
                <div className="column col-12 text-center skp-text-primary">
                  <span>
                    {t('tournament.join.leaveTeamConfirmation')}{" "}
                    <strong>{memberStatus?.team?.partyName}</strong> ?
                  </span>
                </div>
                <div className="space-divider"></div>
              </div>
            </div>
          </Modal>

          <Modal
            show={showMaxPartecipantsModal}
            onClose={() => setShowMaxPartecipantsModal(false)}
            loading={loading}
            size="large"
            title={t('tournament.join.maxPartecipantTitle')}
          >
            <div className="container">
                <div className="columns">
                    <div className="space-divider-20"></div>
                    <div className="column col-12 text-center">
                        <div className="space-divider-20"></div>
                        <h6 className="skp-text-primary" style={{textAlign: "justify", padding:"10px"}}>{t('tournament.join.maxPartecipantDesc')}</h6>
                        <div className="space-divider-20"></div>
                        <img
                            style={{width: "250px"}}
                            alt="space-monkeys-logo"
                            src="https://storage.googleapis.com/skillpro-bucket/skp-website-images/skillpro_ig_qr.png"
                        ></img>
                        {hasEmail === false && (
                          <>
                            <div className="space-divider-15"></div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmitMailing}
                            >
                              {({ values, errors, touched, isValid, handleChange, handleBlur }) => (
                                <Form className="columns">
                                  <div className="column col-12">
                                    <div className="padding-left">
                                      <FormikControl
                                        control="checkbox"
                                        name="mailing_list"
                                        options={[{ key: t('user.userInfoModal.joinMailing'), value: "ok" }]}
                                        label={t('user.userInfoModal.mailingDesc')}
                                      />
                                    </div>
                                  </div>
                                  <div className="space-divider-20"></div>
                                    <div className="column col-12 text-right padding-right">
                                      <button
                                        type="submit"
                                        className="skp-btn skp-btn-primary"
                                        onClick={() => setShowMaxPartecipantsModal(false)}
                                      >
                                        {t('common.save')}
                                      </button>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </>
                          )}
                          {hasEmail && (
                            <>
                              <div className="space-divider-20"></div>
                              <div className="column col-12 text-right padding-right">
                                <button
                                  className="skp-btn skp-btn-primary"
                                  onClick={() => setShowMaxPartecipantsModal(false)}
                                >
                                  {t('common.close')}
                                </button>
                              </div>
                            </>
                          )}
                    </div>
                </div>
            </div>
          </Modal>
        </>
      )}
      {!KeyCloackService.isLoggedIn() && (
        <Modal
          show={showModal}
          size="small"
          onClose={() => setShowModal(false)}
        >
          <LoginOrRegisterModal
            message={constant.MESSAGES.LOGIN.JOIN_TOURNAMENT}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )}

      {KeyCloackService.isLoggedIn() &&
        user?.id &&
        tournament.members?.includes(user?.id) &&
        GAMES[tournament?.game].REQUIRE_PASSWORD && (
          <Modal
            show={showPasswordModal}
            size="large"
            title={`${GAMES[tournament?.game].NAME} Password`}
            onClose={() => setShowPasswordModal(false)}
          >
            <>
              <div className="columns">
                <div className="space-divider-20"></div>
                <div className="column col-12 flex-centered">
                  <h4 className="skp-text-light">{t('common.tournament')}</h4>
                </div>
                <div className="column col-12 flex-centered">
                  <h3 className="skp-text-primary">
                    {tournament?.clashRoyaleLobby?.tag}
                  </h3>
                </div>
                <div className="space-divider-20"></div>
                <div className="column col-12 flex-centered">
                  <h4 className="skp-text-light">{t('common.password')}</h4>
                </div>
                <div className="column col-12 flex-centered">
                  <h3 className="skp-text-primary">
                    {tournament?.clashRoyaleLobby?.password && (
                      <span>{tournament?.clashRoyaleLobby?.password}</span>
                    )}
                    {!tournament?.clashRoyaleLobby?.password && (
                      <span>{t('common.noPassword')}</span>
                    )}
                  </h3>
                </div>
              </div>
            </>
          </Modal>
        )}
    </>
  );
};

export default TournamentJoinButtons;
