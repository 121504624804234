import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import FormikControl from "../../../../Formik/FormikControl";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SocialIcon from "../../../../UiLibrary/SocialIcon";
import constant from "../../../../../constants";
import ErrorLabel from "../../../../Forms/ErrorLabel";
import Brand from "../../../../Interfaces/Brand";
import SocialNetwork from "../../../../Interfaces/SocialNetwork";

const validationSchema = Yup.object({
  name: Yup.string().required("Field is required").nullable(),
  slug: Yup.string().required("Field is required"),
  description: Yup.string(),
  videoUrl: Yup.string().url(),
  website: Yup.string().url(),
  contactMail: Yup.string().email(),
  location: Yup.string(),
  socialNetworks: Yup.array().of(
    Yup.object({
      platform: Yup.string().required("Field is required"),
      url: Yup.string().url().required("Field is required"),
    })
  ),
  
});

interface Props {
  loading: boolean;
  brand?: Brand;
  onSave: (values: any) => void;
}

const AboutTab = (props: Props) => {
  const { loading, brand } = props;
  const { t } = useTranslation()
  const [ regionOptions, setRegionOptions ] = useState<{ key: string; value: string }[]>([]);
  
  useEffect(() => {
      axios.get("/countries.json").then((response) => {
        const data = response.data;
        const options = [{key: t('brands.config.selectCountry'), value: ""}];
        data.forEach((country: { name: { common: string } }) => {
          options.push({
            key: country.name.common,
            value: country.name.common,
          });
        });
        setRegionOptions(options);
      });
      
      return () => {
          setRegionOptions([]);
      }
  }, [brand])

  const platformOptions = constant.SOCIAL_PLATFORMS;    

  const onSubmit = (values:any) => {
    props.onSave(values);
  }

  const initialValues = {
    name: brand?.name,
    slug: brand?.slug,
    contactMail: brand?.contactMail,
    location: brand?.location,
    videoUrl: brand?.videoUrl,
    website: brand?.website,
    socialNetworks: brand?.socialNetworks || [],
  };
  return (
    <div className="container">
      <div className="space-divider-20"></div>
      <div className="columns">
        <div className="column col-12">
          <h5 className="skp-text-primary">{t('tabs.about')}</h5>
        </div>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, isValid, values, setFieldValue }) => (
            <Form className="columns">
              <div className="column col-12">
                {/* Name */}
                <FormikControl
                  control="input"
                  label={t('brands.create.brandName')}
                  name="name"
                  placeholder={t('brands.create.brandName')}
                />
              </div>
              <div className="column col-12">
                {/* Website */}
                <FormikControl
                  control="input"
                  label={t('brands.create.website')}
                  name="website"
                  placeholder={t('brands.create.website')}
                />
              </div>
              <div className="column col-12">
                {/* Video Url */}
                <FormikControl
                  control="input"
                  label={t('brands.create.videoUrl')}
                  name="videoUrl"
                  placeholder={t('brands.create.videoUrlPlaceholder')}
                />
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <h5 className="skp-text-primary">{t('brands.config.contact')}</h5>
              </div>
              <div className="column col-12">
                {/* Email */}
                <FormikControl
                  control="input"
                  type="email"
                  label={t('brands.create.email')}
                  name="contactMail"
                  placeholder={t('brands.create.emailPlaceholder')}
                />
              </div>
              <div className="column col-12">
                {/* Region */}
                {regionOptions?.length > 0 && (
                  <FormikControl
                    control="select"
                    label={t('brands.config.location')}
                    name="location"
                    options={regionOptions}
                  />
                )}
              </div>

              {/* SOCIAL NETWORKS */}
              <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h5 className="skp-text-primary">{t('user.config.socialNetworks')}</h5>
                </div>
                <div className="column col-12">
                  <h6 className="skp-link-primary" onClick={() =>
                    setFieldValue("socialNetworks", [
                      ...values.socialNetworks,
                      { platform: "FACEBOOK", url: "" },
                    ])
                  }>
                      <i className="las la-plus-circle skp-text-xxlarge"></i> {t('user.config.addSocialNetwork')}{" "}
                  </h6>
                </div>

                {values.socialNetworks.map((account, index) => (
                  <div
                    className="columns"
                    key={index}
                    style={{ height: 102 }}
                  >
                    <div className="column col-auto flex-centered skp-text-primary">
                    <h3>
                      {account.platform && (
                        <SocialIcon
                          platform={account.platform}
                        />
                      )}
                    </h3>
                  </div>
                  <div className="column col-3">
                    <div className="skp-form-control">
                      <label className="skp-text-light skp-text-small">
                        {t('user.config.platform')}
                      </label>
                      <Field
                        as="select"
                        name={`socialNetworks[${index}].platform`}
                      >
                        {platformOptions.map((option, id) => {
                          return (
                            <option
                              key={id}
                              value={option.value}
                            >
                              {option.key}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name={`socialNetworks[${index}].console`}
                        component={ErrorLabel}
                      />
                    </div>
                  </div>

                  <div className="column col-5">
                    <div className="skp-form-control">
                      <label className="skp-text-light skp-text-small">
                        {t('user.config.url')}
                      </label>
                      <Field
                        type="text"
                        name={`socialNetworks[${index}].url`}
                        placeholder="https://facebook.com"
                      />
                      <ErrorMessage
                        name={`socialNetworks[${index}].url`}
                        component={ErrorLabel}
                      />
                    </div>
                  </div>

                  <div className="column col-1 flex-centered">
                    <button
                      className="skp-btn skp-btn-transparent skp-text-xlarge"
                      onClick={(event) => {
                        event.preventDefault();
                        setFieldValue(
                          "socialNetworks",
                          values.socialNetworks.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <i className="las la-trash"></i>
                    </button>
                  </div>
                </div>
              ))}
              {/* END SOCIAL NETWORKS */}

              <div className="space-divider-20"></div>
              <div className="column col-12">
                <h5 className="skp-text-primary">{t('header.settings')}</h5>
              </div>
              <div className="column col-12">
                {/* Slug */}
                <FormikControl
                  control="input"
                  label={t('brands.config.brandSlug')}
                  name="slug"
                  placeholder={t('brands.config.brandSlug')}
                />
              </div>
              <div className="space-divider-40"></div>
              <div className="column col-12 text-right">
                <button
                  type="submit"
                  disabled={loading || !isValid}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : t('common.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AboutTab;
