import { Form, Formik } from "formik";
import { useState, useRef, useContext } from "react";
import { toast } from "react-toastify";
import FormikControl from "../../../../Formik/FormikControl";
import Modal from "../../../../UiLibrary/Modal";
import SingleLobby from "./SingleLobby";
import * as Yup from "yup";
import ApexTournamentService from "../../../../../services/ApexTournamentService";
import ITournament from "../../../../Interfaces/Tournament";
import { UserContext } from "../../../../Context/UserContext";
import GAMES from "../../../../../constants-games";
import TournamentService from "../../../../../services/TournamentService";

type Props = {
  tournament: ITournament | undefined
  onReloadTournament: (tournament?:ITournament | undefined) => void
}

const LobbiesTab = (props:Props) => {
  const { tournament } = props;
  const {user} = useContext(UserContext)
  const [loading, setLoading] = useState(false);
  const [showAddLobbyModal, setShowAddLobbyModal] = useState({
    show: false,
  });
  const [showSeedModal, setShowSeedModal] = useState(false);

  const numberOfLobbiesRef = useRef<HTMLInputElement>(null);

  const isTournamentAdmin = tournament?.admins?.includes(user?.id || "");
  const isAdmin = ((tournament?.owner === user?.id || isTournamentAdmin) || ( user?.roles && user.roles.includes("TournamentAdmin")))? true: false;

  const isApex = tournament?.game === GAMES.APEX_LEGENDS.ENUM;

  const initialValues = {
    name: "",
    gameNumber: 1
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Lobby name is required"),
    gameNumber: Yup.number(),
  });

  const onSubmit = (values: any) => {
    let lobby = {}
    
    if (isApex) {
      let tokens:any = [];
      for (let index = 0; index < values.gameNumber; index++) {
        tokens.push({ admin: "", player: "", api: "", gameNumber: index + 1 });
      }
      lobby = {
        name: values.name,
        tokens: tokens,
        matches: [],
        leaderboards: [],
        teams: [],
        winner: null,
      };
    } else {
      let games:any = [];
      for (let index = 0; index < values.gameNumber; index++) {
        games.push({ 
          room_id: "", 
          room_name: "", 
          player_password: "", 
          gameNumber: index + 1, 
          leaderboards: [],
          map_name: ""
         });
      }
      lobby = {
        name: values.name,
        leaderboards: [],
        teams: [],
        games: games,
        winner: null,
      };
    } 

    addLobby(lobby);
  };

  const addLobby = (lobby: any) => {
    if(!tournament) {
      return
    }
    if (isApex) {
      ApexTournamentService.addLobby(tournament.id, lobby).then((response) => {
        toast.success("Lobby added successfully!");
        setShowAddLobbyModal({ show: false });
        props.onReloadTournament();
      });
    } else {
      TournamentService.addLobby(tournament.id, lobby).then((response) => {
        toast.success("Lobby added successfully!");
        setShowAddLobbyModal({ show: false });
        props.onReloadTournament();
      });
    }
  };

  const seedTournament = () => {
    if(!tournament){
      return
    }
    if (isApex) {
      setLoading(true)
      const numberOfLobbies = Number(numberOfLobbiesRef?.current?.value) || 1;
      ApexTournamentService.seedLobbies(tournament.id, numberOfLobbies).then(
        (response) => {
          setLoading(false);
          setShowSeedModal(false);
          toast.success("Lobbies created successfully!")
          props.onReloadTournament();
        }
      ).catch(error => {
        toast.error(error?.response?.data?.message);
        setShowSeedModal(false);
        setLoading(false);
      })
    } else {
      setLoading(true)
      const numberOfLobbies = Number(numberOfLobbiesRef?.current?.value) || 1;
      TournamentService.seedLobbies(tournament.id, numberOfLobbies).then(
        (response) => {
          setLoading(false);
          setShowSeedModal(false);
          toast.success("Lobbies created successfully!")
          props.onReloadTournament();
        }
      ).catch(error => {
        toast.error(error?.response?.data?.message);
        setShowSeedModal(false);
        setLoading(false);
      })
    }
  };

  return (
    <>
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h5 className="skp-text-primary">
          Lobbies{" "}
          {isAdmin && (
            <span
              className="skp-link-hover float-right"
              onClick={() => setShowAddLobbyModal({ show: true })}
            >
              <i className="las la-plus-circle"></i>{" "}
              <span className="text-uppercase skp-text-small">Add lobby</span>
            </span>
          )}
        </h5>

        <div className="space-divider-20"></div>
        {/* INIZIO: LOBBY DI APEX */}
        {tournament?.apexLobbies && tournament.apexLobbies.length > 0 && (
          <>
            {tournament?.apexLobbies?.map((lobby:any) => (
              <SingleLobby
                key={lobby.name+lobby.id}
                lobby={lobby}
                tournament={tournament}
                onReloadTournament={props.onReloadTournament}
              />
            ))}
          </>
        )}
        {/* FINE: LOBBY DI APEX */}
        {tournament?.lobbies && tournament.lobbies.length > 0 && (
          <>
            {tournament?.lobbies?.map((lobby:any) => (
              <SingleLobby
                key={lobby.name+lobby.id}
                lobby={lobby}
                tournament={tournament}
                onReloadTournament={props.onReloadTournament}
              />
            ))}
          </>
        )}
        {tournament?.apexLobbies && tournament.apexLobbies.length === 0 
        && tournament.lobbies && tournament.lobbies.length === 0 && (
          <>
            <div className="columns skp-text-light">
              <div className="column col-12 flex-centered">
                <h1>
                  <i className="las la-icons"></i>
                </h1>
              </div>
              <div className="column col-12 flex-centered">
                <h5>No lobbies</h5>
              </div>
              <div className="column col-12 flex-centered">
                There are currently no lobbies for this Tournament.
              </div>
              <div className="space-divider-20"></div>
              {isAdmin && (
                <div className="column col-12 flex-centered">
                  <button
                    className="skp-btn skp-btn-primary"
                    onClick={() => setShowSeedModal(true)}
                  >
                    Create lobbies
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Modal
        title="Add Lobby"
        show={showAddLobbyModal.show}
        onClose={() => setShowAddLobbyModal({ show: false })}
        size="small"
      >
        <div className="container">
          <div className="columns">
            <div className="space-divider-20"></div>
            <div className="column col-12 skp-text-primary">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              > 
                <Form>
                  <FormikControl
                    control="input"
                    label="Lobby name"
                    name="name"
                  ></FormikControl>
                  <FormikControl
                    control="input"
                    type="number"
                    label="Number of games"
                    name="gameNumber"
                  ></FormikControl>
                  <div className="space-divider"></div>
                  <button type="submit" className="skp-btn skp-btn-primary float-right">
                    Add Lobby
                  </button>
                </Form>
              </Formik>
            </div>
            <div className="space-divider-20"></div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showSeedModal}
        onClose={() => setShowSeedModal(false)}
        title="Create Lobbies"
        size="small"
        confirmation="Create lobbies"
        onConfirm={seedTournament}
        loading={loading}
      >
        <div className="container">
          <div className="columns skp-text-light">
            <div className="column col-2"></div>
            <div className="column col-8 border-box">
              <div className="space-divider-20"></div>
              <div className="column col-12 flex-centered">
                <h1>
                  <i className="las la-random"></i>
                </h1>
              </div>
              <div className="column col-12 text-center">
                <h5>Random Lobbies</h5>
              </div>
              <div className="column col-12 skp-text-small text-center">
                Generate random lobbies based on the number of teams.
                The admin can edit the teams later.
              </div>
              <div className="space-divider-20"></div>
              <div className="skp-form-control">
                <label className="skp-text-light skp-text-small">Number of lobbies</label>
                <input type="number" ref={numberOfLobbiesRef} />
              </div>
            </div>
            <div className="column col-2"></div>
            <div className="space-divider"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LobbiesTab;
