import { useState } from 'react'
import Marquee from 'react-text-marquee';
import { Link } from 'react-router-dom';
import AddToFavourite from './AddToFavourite';

const TeamTile = (props) => {
    const {team} = props;
    const initials = team?.name.charAt(0);
    const [error, setError] = useState(false)

    return (
      <div className="team-tile">
        <Link to={`/teams/${team.id}/overview`}>
          <div className="team-tile-image">
            {(!team?.logo || error) && (
              <span className="initials flex-centered text-uppercase">
                {!initials ? <i className="las la-user"></i> : initials}
              </span>
            )}
            {team?.logo && !error && (
              <img
                alt={team.name}
                src={team.logo}
                onError={() => setError(true)}
              />
            )}
          </div>
        </Link>
        <div className="columns">
          <div className="column col-12 text-center">
            <Link to={`/teams/${team.id}/overview`}>
              <span className="skp-text-default skp-text-primary">
                <Marquee text={team.name} />
              </span>
            </Link>
          </div>
          <div className="column col-12 text-center">
            {team.verified && (
              <span className="verified-icon tooltip" data-tooltip="Verified">
                <i className="la la-check-circle"></i>
              </span>
            )}
            <AddToFavourite team={team} />
          </div>
        </div>
      </div>
    );
}

export default TeamTile