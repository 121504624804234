import { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";

type props = {
  initialValue: any;
  hideDeleteAll: boolean;
  isSaving: boolean;
  onSave: (text: string) => void;
};

const TextEditor = (props: props) => {
  const { initialValue, hideDeleteAll, isSaving } = props;
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState<any>();

  const onEditorStateChange = (state: any) => {
    setEditorState(state);
  };

  useEffect(() => {
    let initialEditorState;

    if (initialValue) {
      const blocksFromHtml = htmlToDraft(initialValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      initialEditorState = EditorState.createWithContent(contentState);
    } else {
      initialEditorState = EditorState.createEmpty();
    }
    setEditorState(initialEditorState);
  }, [initialValue]);

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="skp-rich-text-wrapper"
        editorClassName="skp-rich-editor"
        onEditorStateChange={onEditorStateChange}
      />
      <div className="space-divider-40"></div>
      <button
        className="skp-btn skp-btn-primary float-right"
        disabled={isSaving}
        onClick={() => {
          if (editorState) {
            const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            props.onSave(text); // Call the parent's onSave function
          }
        }}
      >
        {isSaving ? <span className="loading"></span> : t("common.save")}
      </button>
      {!hideDeleteAll && (
        <button
          className="skp-btn skp-btn-alert float-left"
          disabled={isSaving}
          onClick={() => props.onSave("&nbsp;")}
        >
          <i className="las la-trash"></i> {t("tournament.config.deleteAll")}
        </button>
      )}
    </div>
  );
};

export default TextEditor;
