import React, { useCallback, useContext, useEffect, useState } from "react";
import IMatchTournamentSeed from "../../Interfaces/MatchTournamentSeed";
import EMatchStatus from "../../../enums/MatchStatus";
import MatchService from "../../../services/MatchService";
import IMatch from "../../Interfaces/Match";
import { EventsContext } from "../../Context/EventsContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { useTranslation } from "react-i18next";

type Props = {
  seed: IMatchTournamentSeed;
  seedId: number;
  matchId: string;
  showCompleted: boolean;
  onSetMatch: (matchSeed: IMatchTournamentSeed) => void;
};

const MatchCard = (props: Props) => {
  const { seed, seedId, showCompleted } = props;
  const { t } = useTranslation();
  const [reports, setReports] = useState(0);
  const [matchStatus, setMatchStatus] = useState<number>(0)
  const [messageCount, setMessageCount] = useState<number>(0)

  const { tournamentNotification } = useContext(NotificationContext);
  const { socket, isConnected } = useContext(EventsContext);

  useEffect(() => {
    const matchId = seed.matchesId[0];
    if (isConnected && socket && matchId) {
      
      socket.onAny((event, ...args) => {
        if (event === matchId && args.toString() !== 'update' && args.toString() !== 'refresh' ) {
          const matchElement = args[0]
          setMatchStatus(matchElement.status)
          setMessageCount(matchElement.reports)
        }
      });

      return () => {
        socket.offAny();
      };
    }
  }, [seed.matchesId[0], isConnected, socket]);

  const fetchMatch = useCallback(async () => {
    const matchId = seed.matchesId[0];
    if (!matchId) return;

    try {
      const Rmatch = await MatchService.getMatchDetails(matchId);
      setMatchStatus(Rmatch.status)
      if (Rmatch && Rmatch.result && Rmatch.result.reports ) {
        setMessageCount(Rmatch.result.reports.length)
      } else {
        setMessageCount(0)
      }
    } catch (error) {
      console.error("Errore durante il recupero del match:", error);
    }
  }, [seed.matchesId]);

  useEffect(() => {
    fetchMatch();

  }, [fetchMatch, seed.matchesId]);

  useEffect(() => {
    if (seed.matchesId[0]) {
      let readed = 0;
      if (tournamentNotification) {
        const match = tournamentNotification.matches?.find(
          (m) => m.matchId === seed.matchesId[0]
        );
        readed = match ? match.reports : 0;
      }
      setReports(messageCount - readed);
    }
  }, [seed.matchesId[0], tournamentNotification, messageCount]);

  const textStatus = () => {
    switch (matchStatus) {
      case 0:
        return "skp-text-light";
      case 1:
        return "skp-text-accent";
      case 2:
        return "skp-text-secondary";
      case 3:
        return "skp-text-ternary";
      default:
        return "skp-text-light";
    }
  };

  const viewDetails = (seed: IMatchTournamentSeed) => {
    props.onSetMatch(seed);
  };

  return (
    <>
      {seed.matchesId[0] && (matchStatus !== 3 || showCompleted) && (
        <div className="admin-match-tile" onClick={() => viewDetails(seed)}>
          <div className="skp-text-default skp-text-primary column col-12 text-center">
            Match {seedId}
            <a className={reports > 0 ? "badge column" : ""} data-badge={Number(reports || 0)}></a>
          </div>
          <div className="column col-12 text-center">
            <div className={`skp-chip status-${matchStatus}`}>
              {seed.matchesId[0] && t(EMatchStatus[matchStatus!])}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MatchCard;
