import React, { useEffect, useState } from "react";
import YuniteTournamentService from "../../../../../services/YuniteTournamentService";
import InfiniteScroll from "react-infinite-scroll-component";
import ITournament from "../../../../Interfaces/Tournament";
import Scrollbars from "react-custom-scrollbars";
import PlayPlayUser from "../Components/UserComponents/PlayPlayUser";
import UserProfileWithDetailsModal from "../../../../UiLibrary/User/UserProfileWithDetailsModal";
import PlayPlayTeamUser from "../Components/UserComponents/PlayPlayTeamUser";

type Props = {
  tournament: ITournament;
};

const TeamsTab = (props: Props) => {
  const { tournament } = props;
  const [teams, setTeams] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState<any[]>([]);
  const [showInfo, setShowInfo] = useState(false);
  const [checkedState, setCheckedState] = useState<boolean[]>([]);

  useEffect(() => {
    let items: any[] = [];
    if( tournament.tournamentType !== 'lobby') {
      const id = tournament?.yuniteId ? tournament?.yuniteId : tournament?.id;
      if (id) {
        YuniteTournamentService.getCacheTeams(id).then((response) => {
          setTeams(response);
          const index = response.length >= 20 ? 20 : response.length;
          for (let i = 0; i < index; i++) {
            items.push(response[i]);
          }
          setPaginatedItems(items);
        });
      }
    }

    setCheckedState(new Array(tournament.teams.length).fill(false))

    return () => {
      setPaginatedItems([]);
      setTeams([]);
    };
  }, [tournament]);

  const onScrollInfinite = (variables: any) => {};

  const fetchMoreData = () => {
    setTimeout(() => {
      const size = 20;
      const items = teams.slice(
        paginatedItems.length,
        paginatedItems.length + size
      );
      setPaginatedItems(paginatedItems.concat(items));
    }, 100);
  };

  return (
    <div className="container">
      {tournament.tournamentType !== 'lobby' && (<div className="columns">
        <div
          className="column col-12 col-xs-12 flex-centered p-2 gray-bg gray-bg-hover"
          onClick={() => setShowInfo(!showInfo)}
        >
          <span style={{ fontSize: 24 }} className="float-right">
            <i className="las la-info-circle"></i>
          </span>
          <span className="skp-text-xxsmall">Info</span>
          {showInfo && (
            <div className="info-hover-wrapper gray-bg">
              <div className="close-btn" onClick={() => setShowInfo(false)}>
                <i className="las la-times-circle"></i>
              </div>
              <dl>
                <dd>
                  If your name does NOT appear in the Teams section, your
                  registration has not yet finished. To complete it, wait for
                  the Check In period (go to the Overview tab to see when the
                  Event Check In period begins)
                </dd>
                <dd>Complete the following steps to check in:</dd>
                <ol>
                  <li>
                    Once the Check In period has begun, access the Skillpro
                    Discord and click on the event category (the category has
                    the same name as the tournament).
                  </li>
                  <li>
                    Click on “registrazione-team” and then on the “T” Yunite
                    message.
                  </li>
                  <li>
                    Follow the procedure that comes in DM on Discord (just click
                    the “+” in the message that comes directly from Yunite).
                  </li>
                  <li>
                    If the procedure was successful you will see the green
                    writing “Confirmed” next to your name in the Players tab of
                    the event.
                  </li>
                </ol>
              </dl>
            </div>
          )}
          </div>
          <div className="column col-12 col-xs-12">
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "calc(100vh - 125px)",
              }}
            >
              <Scrollbars
                renderView={(props:any) => <div {...props} id="scrollableDiv1" />}
              >
                {teams?.length > 0 && (
                  <div className="columns">
                    <div className="column col-12">
                      <div className="columns">
                        {paginatedItems?.length > 0 && (
                          <InfiniteScroll
                            dataLength={paginatedItems?.length}
                            onScroll={onScrollInfinite}
                            scrollableTarget="scrollableDiv1"
                            next={fetchMoreData}
                            hasMore={teams.length > paginatedItems?.length}
                            style={{ overflow: "hidden" }}
                            loader={<div className="loading"></div>}
                            scrollThreshold={"100px"}
                            endMessage={
                              <>
                                {paginatedItems.length >= 10 && (
                                  <>
                                    <p
                                      className="text-center skp-text-light m-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      END
                                    </p>
                                  </>
                                )}
                              </>
                            }
                          >
                            <div className="columns">
                              {paginatedItems?.map((team, index) => {
                                return !team.disqualified ? (
                                  <div
                                    className="column col-3 col-xs-6 col-md-4"
                                    key={`team-${team.id}`}
                                    style={{ marginBottom: 40 }}
                                  >
                                    {team?.players.map(
                                      (player: any, index: number) => (
                                        <div
                                          key={`player-${player.epicId}${index}`}
                                        >
                                          <PlayPlayUser
                                            epicId={player.epicId}
                                            teams={teams}
                                            hideLabel
                                          ></PlayPlayUser>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : null;
                              })}
                            </div>
                          </InfiniteScroll>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* No Teams */}
                {teams?.length === 0 && (
                  <div className="columns skp-text-light text-center">
                    <div className="space-divider"></div>
                    <div className="column col-12">
                      <h1>
                        <i className="las la-user-friends"></i>
                      </h1>
                    </div>
                    <div className="column col-12">
                      <h5>No Teams</h5>
                    </div>
                    <div className="column col-12">
                      There are currently no Teams registered for this Tournament.
                    </div>
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      )}
      {tournament.tournamentType === 'lobby' && 
        tournament.teams.length > 0 &&
        tournament?.teams.map((team, index) => (
          <div key={`team-${index}`} className={`columns accpointer ${checkedState[index] === true ? '' : 'team-border'}`}
            style={{backgroundColor: "#f1f0f0"}}
             onClick={(e)=>{
              const newState = [...checkedState]
              newState[index] = !checkedState[index]
              setCheckedState(newState)
            }}>
            <div className="space-divider-20"></div>
            <div className="column col-1 col-xs-3 skp-text-primary">
              <div className="small-player-wrapper">
                {team.partyAvatar && (
                  <img
                    src={team.partyAvatar}
                  />
                )}
                {!team.partyAvatar && (
                  <img
                    src="https://play-play.com/images/default_avatar.jpg"
                  />
                )}               
              </div>
            </div>
            <div className="column col-10 col-xs-8 skp-text-primary">
              {!team.partyName && <h5>Team {index + 1}</h5>}
              {team.partyName && (
                <>
                  <h5>
                    <div className="skp-text-label skp-text-light text-uppercase">
                      Team {index + 1}{" "}
                    </div>{"  "}
                    {team.partyName}
                    {" "}
                  </h5>
                  <h5>
                    {!team.checkin && (
                      <div className="skp-chip status-Rejected skp-text-small">
                        Check-in: <i className="las la-times-circle"></i>
                      </div>
                    )}
                    {team.checkin && team.checkin === true && (
                      <div className="skp-chip status-completed skp-text-small">
                        Check-in: <i className="las la-check-circle"></i>
                      </div>
                    )}
                  </h5>
                </>
              )}
            </div>
            {!checkedState[index] && (
              <div className="column col-1 col-xs-1 skp-text-primary">
                <i className="las la-angle-right" style={{marginTop:"30px"}}></i>
              </div>
            )}
            {checkedState[index] && (
              <div className="column col-1 col-xs-1 skp-text-primary">
                <i className="las la-angle-down" style={{marginTop:"30px"}}></i>
              </div>
            )}
            <div className={`column col-12 ${checkedState[index] === true ? '' : 'team-hidden'}`}>
              <dl className="bordered-list">
                {team.members.map((member, index) => (
                  <dd className="columns" key={member + index.toString()}>
                    <div className="column col-8 col-xs-10">
                      <PlayPlayTeamUser
                        playerId={member.userId} 
                      >
                        {index === 0 && (
                          <span className="skp-chip status-captain skp-text-small">
                            Leader
                          </span>
                        )}
                      </PlayPlayTeamUser>
                    </div>
                    </dd>
                  ))}
                </dl>
              </div>
            </div>
          ))}
     
    </div>
  );
};

export default TeamsTab;
