import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import FormikControl from "../../../../Formik/FormikControl";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
  name: Yup.string().required("Field is required").nullable(),
  description: Yup.string(),
  video_url: Yup.string().url(),
  website: Yup.string().url(),
  contact_mail: Yup.string().email(),
  location: Yup.string(),
  social_networks: Yup.array().of(Yup.string()),
});

const AboutTab = (props) => {
  const { loading, team } = props;
  const [ regionOptions, setRegionOptions ] = useState([]);

  useEffect(() => {
      axios.get("/countries.json").then((response) => {
        const data = response.data;
        let options = [{key: "Select a country", value: ""}];
        data.forEach((country) => {
          options.push({
            key: country.name.common,
            value: country.name.common,
          });
        });
        setRegionOptions(options);
      });
      return () => {
          setRegionOptions();
      }
  }, [])

    

  const onSubmit = (values) => props.onSave(values);

  const initialValues = {
    name: team?.name,
    type: team?.type,
    contact_mail: team?.contact_mail,
    location: team?.location,
    video_url: team?.video_url,
    website: team?.website
  };
  return (
    <div className="container">
      <div className="space-divider-20"></div>
      <div className="columns">
        <div className="column col-12">
          <h5 className="skp-text-primary">About</h5>
        </div>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ isValid }) => (
            <Form className="columns">
              <div className="column col-12">
                {/* Name */}
                <FormikControl
                  control="input"
                  label="Name"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="column col-12">
                {/* Website */}
                <FormikControl
                  control="input"
                  label="Website"
                  name="website"
                  placeholder="Website url"
                />
              </div>
              <div className="column col-12">
                {/* Video Url */}
                <FormikControl
                  control="input"
                  label="Video Url"
                  name="video_url"
                  placeholder="Insert video link"
                />
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12">
                <h5 className="skp-text-primary">Contact</h5>
              </div>
              <div className="column col-12">
                {/* Email */}
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="contact_mail"
                  placeholder="Email address"
                />
              </div>
              <div className="column col-12">
                {/* Region */}
                {regionOptions?.length > 0 && (
                  <FormikControl
                    control="select"
                    label="Location"
                    name="location"
                    options={regionOptions}
                  />
                )}
              </div>
              <div className="space-divider-40"></div>
              <div className="column col-12 text-right">
                <button
                  type="submit"
                  disabled={loading || !isValid}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? <span className="loading"></span> : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AboutTab;
