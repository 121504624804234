import GAMES from "../../../../../../constants-games";
import ITournament from "../../../../../Interfaces/Tournament";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import FormikControl from "../../../../../Formik/FormikControl";
import ITournamentBracketRound from "../../../../../Interfaces/TournamentBracketRound";
import TournamentService from "../../../../../../services/TournamentService";

type Props = {
    roundIndex: number;
    rounds: ITournamentBracketRound[];
    tournament: ITournament;
    refreshBracket: () => void
    closeModal: () => void;
};


const ConfigBracketRoundModal = (props: Props) => {
    const { roundIndex, rounds, tournament } = props;

    var array = Object.keys(GAMES[tournament?.game].TEAM_MATCH_TYPE_MAP).map((key) => [key, GAMES[tournament?.game].TEAM_MATCH_TYPE_MAP[key]]);
    const mType = Array.from(array, ([value, key]) => ({ value, key }));
    
    const onSelect = (event: any) => {
        const data = {
            matches: rounds[roundIndex].seeds.map(a=>a.id),
            matchLevel: rounds[roundIndex].seeds.map(a=>a.matchLevel),
            matchType: event.matchType
        }

        TournamentService.changeMatchesConfiguration(tournament.id, data)
        .then((response) => {
            props.refreshBracket()
            props.closeModal()
            /*setSingleMatch(match);
            toast.info(`Match updated to ${EMatchStatus[match.status]}`);*/
        })
        .catch((error) => {
            toast.error(error.response?.data?.message);
        });
    };

   
    const initialValues = {
        matchType: tournament.matchType
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSelect}
            >
                {({ errors, touched, isValid, setFieldValue }) => (
                <Form>
                    <div className="skp-form-control">
                        <label className="skp-text-light skp-text-small">
                            Match Type
                        </label>
                        <FormikControl
                            control="select"
                            label="Game"
                            name="matchType"
                            options={mType}
                        ></FormikControl>
                    </div>
                    <div className="space-divider-40"></div>
                    <div className="column col-12 text-right">
                        <button
                            type="submit"
                            className="skp-btn skp-btn-primary"
                        >Save
                        </button>
                    </div>
                </Form>
            )}
            </Formik>

            
        </>
    )
};

export default ConfigBracketRoundModal;