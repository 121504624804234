import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Marquee from "react-text-marquee";
import YuniteTournamentService from "../../../../../services/YuniteTournamentService";
import ITournament from "../../../../Interfaces/Tournament";
import YuniteMatchLeaderboard from "../Components/YuniteMatchLeaderboard";

type Props = {
  tournament: ITournament;
};
const YuniteMatchesTab = (props: Props) => {
  const [showLeaderboardModal, setShowLeaderboardModal] = useState<{
    show: boolean;
    sessionId?: string;
  }>({ show: false, sessionId: undefined });
  const { tournament } = props;
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const id = tournament?.yuniteId ? tournament?.yuniteId : tournament?.id;
    YuniteTournamentService.getCacheMatches(id)
      .then((response) => {
        if (response) {
          if (Object.keys(response).length === 0) {
            setMatches([]);
          } else {
            setMatches(response);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        setMatches([]);
        setLoading(false);
      });

    return () => {
      setMatches([]);
    };
  }, [tournament]);
  return (
    <div className="container">
      {!loading && matches?.length === 0 && (
        <div className="columns skp-text-light text-center">
          <div className="space-divider"></div>
          <div className="column col-12">
            <h1>
              <i className="las la-chess"></i>
            </h1>
          </div>
          <div className="column col-12">
            <h5>No matches</h5>
          </div>
          <div className="column col-12">
            There are no matches available yet.
          </div>
        </div>
      )}
      {!loading && matches?.length > 0 && (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "calc(100vh - 60px)",
            }}
          >
            <Scrollbars
              renderView={(props:any) => <div {...props} id="scrollableDiv1" />}
            >
              {matches.map((match: any) => (
                <div className="columns skp-text-primary bordered-list p-2">
                  <div className="column col-3">
                    <Marquee
                      text={new Date(match?.timestamp).toLocaleString()}
                    />
                  </div>
                  <div className="column col-3">
                    <Marquee text={match?.status} />
                  </div>
                  <div className="column col-3">
                    <Marquee text={`Players: ${match?.players}`} />
                  </div>
                  <div className="column col-3 text-right">
                    <button
                      onClick={() =>
                        setShowLeaderboardModal({
                          show: true,
                          sessionId: match?.sessionId,
                        })
                      }
                      className="skp-btn skp-btn-secondary skp-text-small"
                    >
                      Leaderboard
                    </button>
                  </div>
                </div>
              ))}
            </Scrollbars>
          </div>
        </>
      )}

      {showLeaderboardModal.show && (
        <div className="info-hover-wrapper top-0 gray-bg" id="hover-scroll">
          <div
            className="close-btn"
            onClick={() => setShowLeaderboardModal({ show: false })}
          >
            <i className="las la-times-circle"></i>
          </div>
          <div className="space-divider-20"></div>
          {showLeaderboardModal.sessionId && (
            <YuniteMatchLeaderboard
              tournament={tournament}
              matchId={showLeaderboardModal.sessionId}
            ></YuniteMatchLeaderboard>
          )}
        </div>
      )}
    </div>
  );
};

export default YuniteMatchesTab;
