import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Context/UserContext";
import ITournament from "../../../../Interfaces/Tournament";
import Modal from "../../../../UiLibrary/Modal";
import ILobbyGame from "../../../../Interfaces/LobbyGame";
import ITournamentLobby from "../../../../Interfaces/TournamentLobby";
import TournamentService from "../../../../../services/TournamentService";
import CreateEditLobbyGameModal from "./Modals/CreateEditLobbyGameModal";
import LobbyGameCard from "./Components/LobbyGameCard";
import AdminLeaderboardModal from "./Modals/AdminLeaderboardModal";

type Props = {
  tournament: ITournament
  lobby: ITournamentLobby
  onReloadTournament: (tournament?:ITournament) => void
}

const LobbyGameTab = ({ lobby, tournament, ...props }:Props) => {
  const {user} = useContext(UserContext)
  const [games, setGames] = useState<Array<ILobbyGame>>([]);
  const [loading, setLoading] = useState(false);

  let lobbyGames = lobby.games;

  let initialValuesEmpty:ILobbyGame = {
    gameNumber: lobbyGames ? lobbyGames?.length + 1 : 1,
    player_password: "",
    start_game: new Date(),
    end_game: new Date(),
    room_id: "",
    room_name: "",
    map_name: "",
    leaderboards:[]
  };

  const [showAddGameModal, setShowAddGameModal] = useState<{show: boolean, initialValues?: ILobbyGame, title?: string}>({
    show: false,
    initialValues: initialValuesEmpty,
    title: "Add Game",
  });

  const isAdmin =
    tournament?.owner === user?.id ||
    tournament?.admins?.includes(user?.id!);
  const isMember = lobby?.teams && lobby.teams.some((team) => team.members.find((member) => member.userId === user?.id));


  useEffect(() => {
    setGames(lobbyGames ? lobbyGames : []);

    return () => {
      setGames([]);
    }
  }, [lobbyGames]);

  const editGame = (e:any, index:number) => {
    e.stopPropagation();
    e.preventDefault();
    if(!lobbyGames) {
      return 
    }

    setShowAddGameModal({
      show: true,
      initialValues: lobbyGames[index],
      title: "Edit Game",
    });
  };

  const onRemoveGame = (e:any, index:number) => {
    setLoading(true);
    let lobbyGamesCopy:Array<ILobbyGame>;
    e.stopPropagation();
    e.preventDefault();
    const gameIndex = lobbyGames?.findIndex(
      (element) => element.gameNumber === index
    );
    if (gameIndex && gameIndex > -1) {
      lobbyGamesCopy = [...lobbyGames as Array<ILobbyGame>];
      lobbyGamesCopy.splice(gameIndex, 1);
      lobbyGamesCopy.forEach((element, index) => {
        element.gameNumber = index + 1;
      });

      let { id, ...newLobby } = lobby;
      newLobby.games = lobbyGamesCopy;
      const dataToPass = newLobby;

      TournamentService.updateLobby(tournament?.id, lobby.id, dataToPass)
        .then(() => {
          toast.info("Game removed successfully!");
          setShowAddGameModal({ show: false });
          setLoading(false);
          props.onReloadTournament();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const onAddGame = (data:any) => {
    setLoading(true);
    let lobbyGamesCopy:any = [];

    if(lobbyGames) {
      const gameIndex = lobbyGames.findIndex(
        (element) => element.gameNumber === data.gameNumber
      );
  
      if (gameIndex > -1) {
        lobbyGamesCopy = [...lobbyGames];
        lobbyGamesCopy[gameIndex] = data;
      } else {
        lobbyGamesCopy = [...lobbyGames];
        lobbyGamesCopy.push(data);
      }
    } else {
      lobbyGamesCopy.push(data);
    }
    

    let { id, ...newLobby } = lobby;
    newLobby.games = lobbyGamesCopy;
    const dataToPass = newLobby;

    TournamentService.updateLobby(tournament?.id, lobby.id, dataToPass)
      .then((response) => {
        toast.info("Game saved successfully!");
        setShowAddGameModal({ show: false });
        setLoading(false);
        props.onReloadTournament();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      {/* <div className="column col-12">
        <h5 className="skp-text-primary">Games</h5>
      </div> */}

      {!loading && (
        <>
          {games?.length === 0 && (
            <>
              <div className="column col-12 skp-text-light text-center">
                <h1>
                  <i className="las la-chess"></i>
                </h1>
              </div>
              <div className="column col-12 skp-text-light text-center">
                <h5>No games</h5>
              </div>
              <div className="column col-12 skp-text-light text-center">
                There are no games available yet.
              </div>

              {isAdmin && (
                <>
                  <div className="space-divider-20"></div>
                  <div className="column col-12 flex-centered">
                    <span
                      onClick={() =>
                        setShowAddGameModal({
                          show: true,
                          initialValues: initialValuesEmpty,
                          title: "Add Game",
                        })
                      }
                      className="skp-link-primary"
                    >
                      <i className="las la-plus-circle"></i> Add new game
                    </span>
                  </div>
                  <div className="space-divider"></div>
                </>
              )}
            </>
          )}

          {games?.length > 0 && (
            <>
              {isAdmin && (
                <div className="column col-4 col-xs-12">
                  <div
                    className="card skp-card skp-card-hover flex-centered"
                    style={{ height: 250 }}
                    onClick={() =>
                      setShowAddGameModal({
                        show: true,
                        initialValues: initialValuesEmpty,
                        title: "Add Game",
                      })
                    }
                  >
                    <h2 className="skp-link-primary">
                      <i className="las la-plus-circle"></i>
                    </h2>
                    <span className="skp-link-primary">Add new game</span>
                  </div>
                </div>
              )}
              {games.map((game, index) => (
                <LobbyGameCard
                  key={index}
                  tournament={tournament}
                  game={game}
                  index={index}
                  isAdmin={isAdmin}
                  isMember={isMember}
                  editGame={editGame}
                  lobby={lobby}
                  onReloadTournament={props.onReloadTournament}
                />
              ))}
            </>
          )}
        </>
      )}

      {/* Modal to Edit or Add Game */}
      <Modal
        title={showAddGameModal.title}
        show={showAddGameModal.show}
        onClose={() =>
          setShowAddGameModal({
            show: false,
            initialValues: initialValuesEmpty,
          })
        }
        size="medium"
      >
        <CreateEditLobbyGameModal
          initialValues={showAddGameModal.initialValues}
          loading={loading}
          title={showAddGameModal.title || ""}
          tournament={tournament}
          onRemoveGame={onRemoveGame}
          onAddGame={onAddGame}
        />
      </Modal>
    </>
  );
};

export default LobbyGameTab;
