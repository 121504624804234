import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "../../Formik/FormikControl";
import TournamentService from "../../../services/TournamentService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {
    onCreate: (competitionId?: string) => void;
};

interface IFormModel {
    name: string;
    admins: Array<string>,
    actions: Array<string>,
    tournaments: Array<string>,
}
  
const initialValues: IFormModel = {
    name: "",
    admins: [],
    actions: [],
    tournaments: []
};
  
const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
});

const NewCompetitionModal = (props: Props) => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    const onSubmit = (values: IFormModel) => {
        setLoading(true);
        if(user?.id) {
            values.admins = [user?.id];
        }
        values.actions = ["single"]
            
        TournamentService.createCompetition(values)
        .then((response) => {
            toast.success(t('toast.competitionCreatedSuccess'));
            props.onCreate(response?.id);
            setLoading(false);
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message);
            setLoading(false);
        });
    }

    return (
        <div className="column col-12">
            {step === 1 && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, isValid }) => (
                        <Form className="columns">
                            <div className="column col-6"></div>

                            <div className="column col-12">
                                <h6 className="skp-text-primary">{t('competition.competitionInfo')}</h6>
                            </div>
              
                            <div className="column col-12">
                                <FormikControl
                                    control="input"
                                    label={t('competition.competitionName')}
                                    name="name"
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>

                            <div className="space-divider"></div>

                            <div className="column col-12 text-right">
                                <button
                                type="submit"
                                disabled={!isValid || loading}
                                className="skp-btn skp-btn-primary"
                                >
                                {loading ? <span className="loading"></span> : t('common.create')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    )
}

export default NewCompetitionModal;