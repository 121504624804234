import { createContext, useContext } from "react";

interface IModal {
  type?: string;
  show: boolean;
}
export type RegistrationModalContent = {
  showSignUpModal: IModal;
  setShowSignUpModal: (modal: IModal) => void;
};
export const RegistrationModalContext = createContext<RegistrationModalContent>(
  {
    showSignUpModal: { show: false }, // set a default value
    setShowSignUpModal: () => {},
  }
);
export const useGlobalContext = () => useContext(RegistrationModalContext);
